@import "../../Global/Components/quiz.less";

.social-video.quiz {
  .iframe-wrapper {
    position: relative;
    padding-bottom: 147.25%;
    padding-top: 25px;
    height: 0;

    iframe {
      border: 0 !important;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .two-col-spot &, .three-col-spot & {
      padding-bottom: 145.25%;
    }
  }
}
