﻿@import "../Global/buttons.less";

.reset-button-to-ft {
  border-radius: 0;
  min-width: 165px;
  padding: 10px 50px 10px 50px;
  .font-alt();
  .font-size(1.8);
  .ft-button-color();

  &:before {
    display: none;
  }

  &:hover {
    text-decoration: none;
  }
}

.ft-button-color {
  color: #fff;
  background-color: @color-primary-function-bg;

  &:hover {
    color: #fff;
    background-color: @color-primary-function-hover;
  }

  &:focus {
    color: #fff;
    background-color: @color-primary-function-hover;
		outline: 5px auto -webkit-focus-ring-color;
  }

  &:active {
    color: #fff;
    background-color: @color-primary-function-hover;
  }
}

.btn-nav-left {
  //.btn-with-icon-left-v2( @color: @btn-flownav-text-color; @color-hover: @btn-flownav-text-color-hover; @background: @btn-flownav-bg; @background-hover: @btn-flownav-bg-hover; @font-size: @btn-flownav-font-size; @line-height: @btn-flownav-line-height; @icon-color: @btn-flownav-icon-color; @icon-color-hover: @btn-flownav-icon-color-hover; @icon-bg: @btn-flownav-icon-bg; @icon-bg-hover: @btn-flownav-icon-bg-hover; @icon-size: @btn-flownav-icon-font-size; );
  .reset-button-to-ft();
}

.btn-nav-right {
  //.btn-with-icon-right-v2( @color: @btn-flownav-text-color; @color-hover: @btn-flownav-text-color-hover; @background: @btn-flownav-bg; @background-hover: @btn-flownav-bg-hover; @font-size: @btn-flownav-font-size; @line-height: @btn-flownav-line-height; @icon-color: @btn-flownav-icon-color; @icon-color-hover: @btn-flownav-icon-color-hover; @icon-bg: @btn-flownav-icon-bg; @icon-bg-hover: @btn-flownav-icon-bg-hover; @icon-size: @btn-flownav-icon-font-size; );
  .reset-button-to-ft();

  &:focus {
    outline: thin dotted !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: -2px !important;
  }
}

.btn-action {
  //.btn-with-icon-right( @color: @btn-action-text-color; @color-hover: @btn-action-text-color-hover; @background: @btn-action-bg; @background-hover: @btn-action-bg-hover; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @btn-action-icon-color; @icon-color-hover: @btn-action-icon-color-hover; @icon-bg: @btn-action-icon-bg; @icon-bg-hover: @btn-action-icon-bg-hover; @icon-size: @btn-action-icon-font-size; );
  .reset-button-to-ft();
}

.btn-standard {
  .reset-button-to-ft();
  color: #fff !important;
}

.btn-taxonomy {
  .btn-with-icon-right-v2( @color: @btn-taxonomy-text-color; @color-hover: @btn-taxonomy-text-color-hover; @background: @btn-taxonomy-bg; @background-hover: @btn-taxonomy-bg-hover; @font-size: @btn-taxonomy-font-size; @line-height: @btn-taxonomy-line-height; @icon-color: @btn-taxonomy-icon-color; @icon-color-hover: @btn-taxonomy-icon-color-hover; @icon-bg: @btn-taxonomy-icon-bg; @icon-bg-hover: @btn-taxonomy-icon-bg-hover; @icon-size: @btn-taxonomy-icon-font-size; @icon-v-offset: 4 );

  &.selected {
    //.btn-with-icon-right-v2( @color: @btn-taxonomy-text-color-hover; @color-hover: @btn-taxonomy-text-color; @background: @btn-taxonomy-bg-hover; @background-hover: @btn-taxonomy-bg; @font-size: @btn-taxonomy-font-size; @line-height: @btn-taxonomy-line-height; @icon-color: @btn-taxonomy-icon-color-hover; @icon-color-hover: @btn-taxonomy-icon-color; @icon-bg: @btn-taxonomy-icon-bg-hover; @icon-bg-hover: @btn-taxonomy-icon-bg; @icon-size: @btn-taxonomy-icon-font-size; @icon-v-offset: 4 );
    .btn-with-icon-right-v2( @color: @btn-taxonomy-text-color; @color-hover: @btn-taxonomy-text-color-hover; @background: @btn-taxonomy-bg-selected; @background-hover: @btn-taxonomy-bg-hover; @font-size: @btn-taxonomy-font-size; @line-height: @btn-taxonomy-line-height; @icon-color: @btn-taxonomy-icon-color; @icon-color-hover: @btn-taxonomy-icon-color-hover; @icon-bg: @btn-taxonomy-icon-bg-selected; @icon-bg-hover: @btn-taxonomy-icon-bg-hover; @icon-size: @btn-taxonomy-icon-font-size; @icon-v-offset: 4 );
  }
}

.btn-left, .btn-right {
  @media (max-width: @screen-sm-max) {
    display: block;
    float: none !important;
    margin: 0 0 5px 0;
    white-space: normal;
  }
}

.btn-left {
  //.btn-with-icon-left-v2(@color: @btn-action-text-color; @color-hover: @btn-action-text-color-hover; @background: @btn-action-bg; @background-hover: @btn-action-bg-hover; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @btn-action-icon-color; @icon-color-hover: @btn-action-icon-color-hover; @icon-bg: @btn-action-icon-bg; @icon-bg-hover: @btn-action-icon-bg-hover; @icon-size: @btn-action-icon-font-size;);
  .reset-button-to-ft();

  &.btn-brand {
    //.btn-with-icon-right-v2(@color: @color-text-light; @color-hover: @color-text-light; @background: @color-brand-200; @background-hover: @color-brand-100; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @color-text-light; @icon-color-hover: @color-text-light; @icon-bg: @color-brand-100; @icon-bg-hover: darken(@color-brand-100, 5%); @icon-size: @btn-action-icon-font-size;);
    .reset-button-to-ft();
  }
}

.btn-right {
  //.btn-with-icon-right-v2(@color: @btn-action-text-color; @color-hover: @btn-action-text-color-hover; @background: @btn-action-bg; @background-hover: @btn-action-bg-hover; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @btn-action-icon-color; @icon-color-hover: @btn-action-icon-color-hover; @icon-bg: @btn-action-icon-bg; @icon-bg-hover: @btn-action-icon-bg-hover; @icon-size: @btn-action-icon-font-size;);
  .reset-button-to-ft();

  &.btn-brand {
    //.btn-with-icon-right-v2(@color: @color-text-light; @color-hover: @color-text-light; @background: @color-brand-200; @background-hover: @color-brand-100; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @color-text-light; @icon-color-hover: @color-text-light; @icon-bg: @color-brand-100; @icon-bg-hover: darken(@color-brand-100, 5%); @icon-size: @btn-action-icon-font-size;);
    .reset-button-to-ft();
  }

  &.icon-pil-hoejre {
    &:before {
      display: none;
      /*&:extend(.icon-ft_bullit_right:before);
      font-size: 15px;
      text-indent: 4px;*/
    }
  }

  &:before {
    //display: none;
  }
  /*border-radius: 0;
  padding: 10px 50px;
  .font-alt();
  .font-size(2);*/
  .left-menu-wrapper & {
    .font-size(1.8);
  }

  &:hover {
    text-decoration: none;
  }
}

.btn-hero {
  .btn-with-icon-right-v2(@color: #3c98c1; @color-hover: darken(#3c98c1, 10%); @background: @color-text-light; @background-hover: @color-text-light; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: #3c98c1; @icon-color-hover: darken(#3c98c1, 10%); @icon-bg: #cae8f5; @icon-bg-hover: #cae8f5; @icon-size: @btn-action-icon-font-size;);
  margin-right: 0;
  border-radius: 3px 3px 0 0;
  padding: 8px 38px 8px 13px;
  .font-bold();
  .font-size(1.5);
  color: #007eb5; // setting color manually because of contrast issues relative to WCAG demands
  &:before {
    border-radius: 0 3px 0 0;
    .font-size(1);
    width: 27px;
    padding-left: 8px;
    color: #007eb5; // setting color manually because of contrast issues relative to WCAG demands
  }
}

.btn-add-all-documents-to-basket {
  .make-row();

  &__container {
    .make-sm-column(12);
    margin: 0;

    &__link {
      .reset-button-to-ft();
      color: #fff;
      background-color: @color-primary-function-bg;
      float: right;

      &:hover {
        color: #fff;
        background-color: @color-primary-link;
      }
    }
  }

  .popover-content & {
    margin-left: 0;
    margin-right: 0;

    &__container {
      float: none;
      min-height: 0;
      padding: 0;
      width: auto;

      &__link {
        background-color: initial;
        float: none;
        font-family: Arial, sans-serif;
        min-width: 0;
        padding: 0;
        .font-size(1.4);
        background-color: transparent;

        &:hover {
          background-color: transparent;
          color: @color-brand-200;
          text-decoration: underline;
        }
      }
    }
  }
}

.btn-icon-button {
  color: @color-tertiary-result-absent !important;
  display: inline-block;
  width: 27px;
  height: 27px;

  &:hover {
    color: @color-primary-function-bg !important;
  }

  &:before {
    font-size: 27px;
  }

  span {
    display: inline-block;
    text-indent: -1000px;
    overflow: hidden;
    width: 0;
  }

  &.icon-ft_basketplus {
    &.in-basket {
      &:before {
        &:extend(.icon-ft_basketminus:before);
      }
    }
  }

  .popover-content & {
    color: @color-brand-200 !important;
    display: inline;
    height: auto;
    width: auto;

    &:before {
      display: none;
    }

    span {
      display: inline;
      overflow: visible;
      text-indent: 0;
      width: auto;
    }
  }

  .listespot-wrapper & {
    color: @color-primary-function !important;

    &:hover {
      text-decoration: underline!important;
    }
  }
}

.btn-icon-button-list {
  list-style: none;
  margin: 10px 10px;
  padding: 0;
  width: 188px;
  margin-bottom: 10px;
  .clearfix();

  li {
    width: 27px;
    height: 27px;
    margin: 0 10px;
    float: left;
  }
}

.btn-smaller {
  .reset-button-to-ft();
  .btn-right();
  min-width: auto;
  margin-top: 10px;
  .font-size(1.8);
  padding: 10px 20px;
}
