﻿@import "../../Global/Components/case-info-configurable-spot.less";

.case-info-configurable-spot {
   &:first-of-type{
      //border-top: 1px solid #ddd!important;
    }
     &:last-of-type{
      //border-bottom: 1px solid #ddd!important; 
    }
  &.panel-group {
    margin-bottom: 0px;
   
    .case-document {
      margin-top: 0!important;
      margin-bottom: 0!important;
    }
  }


  &__show-more__container {
    padding-bottom: 1px;
  }

  &__show-more-btn {
    display: block;
    margin: 10px 0 30px;
    text-align: center;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    .font-size(1.6);

    .more {
      display: block;

      &:after {
        margin-top: 10px;
        content: "\e660";
        font-family: "ft-icons";
        font-size: 35px;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: block;
        text-decoration: none;
      }
    }

    .less {
      display: none;

      &:after {
        margin-top: 10px;
        content: "\e65f";
        font-family: "ft-icons";
        font-size: 35px;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        display: block;
        text-decoration: none;
      }
    }

    &.is-active {
      .more {
        display: none;
      }

      .less {
        display: block;
      }
    }
  }

  div:last-of-type {
    margin-bottom: 0;
  }

  .tingdok-normal {
    color: @color-primary-dark;   
  }
  .case-document{
    font-family: Arial,Helvetica,sans-serif!important;
  }

  .tingdok & .tingdok-normal:first-child {
    margin-top: 0;
  }
}
