﻿@import "../../styles.less";

[data-style="servicemenu"] + .popover {
  border: 0;
  border-radius: 0;
  .box-shadow(none);
  background-color: @color-contrast-300;
  
  .popover-content {
    padding: 15px;
  }

	.popover-title {
  	display: none;
	}

  &.top > .arrow {
    border-top-color: @color-contrast-300; // IE8 fallback

    &:after {
      border-top-color: @color-contrast-300;
    }
  }
  &.right > .arrow {
    border-right-color: @color-contrast-300; // IE8 fallback

    &:after {
      border-right-color: @color-contrast-300;
    }
  }
  &.bottom > .arrow {
    border-bottom-color: @color-contrast-300; // IE8 fallback

    &:after {
      border-bottom-color: @color-contrast-300;
    }
  }

  &.left > .arrow {
    border-left-color: @color-contrast-300; // IE8 fallback

    &:after {
      border-left-color: @color-contrast-300;
    }
  }
}