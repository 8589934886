.subsite-wide {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: @sw-font-semi-bold;
    color: @sw-color-text-dark;
    line-height: 1.1 !important;
  }

  h1:not(.sub-footer-layout-b__header),
  .h1:not(.sub-footer-layout-b__header),
  h2:not(.panel-title),
  .h2 {
    margin-top: 0 !important;
    margin-bottom: 20px !important;
    padding: 0;
  }

  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin-top: 30px !important;
    margin-bottom: 10px !important;
  }

  h1:not(.sub-footer-layout-b__header),
  .h1:not(.sub-footer-layout-b__header) {
    font-size: 34px;

    @media (min-width: @screen-md-min) {
      font-size: 48px;
    }
  }

  h2:not(.primary-text),
  .h2 {
    font-size: @sw-font-xl;

    @media (min-width: @screen-md-min) {
      font-size: 34px;
    }
  }

  h3,
  .h3,
  .two-col-spot .col-sm-6 .factbox h3,
  .three-col-spot .col-sm-4 .factbox h3,
  .factbox h3,
  .two-col-spot .col-sm-6 > div h3,
  .three-col-spot .col-sm-4 > div h3 {
    font-size: 22px;
    font-weight: @sw-font-semi-bold;
    line-height: 1.1 !important;
    margin-bottom: 10px !important;

    @media (min-width: @screen-md-min) {
      font-size: @sw-font-xl;
    }
  }

  h4,
  .h4,
  .two-col-spot .col-sm-6 .factbox h4,
  .three-col-spot .col-sm-4 .factbox h4,
  .factbox h4,
  .factbox .h4,
  .two-col-spot .col-sm-6 > div h4,
  .three-col-spot .col-sm-4 > div h4 {
    font-size: @sw-font-md;
    font-weight: @sw-font-semi-bold;
    line-height: 1.1 !important;
    margin-bottom: 10px !important;

    @media (min-width: @screen-md-min) {
      font-size: 22px;
    }
  }

  h5,
  .h5,
  .two-col-spot .col-sm-6 .factbox h5,
  .three-col-spot .col-sm-4 .factbox h5,
  .factbox h5,
  .factbox .h5,
  .two-col-spot .col-sm-6 > div h5,
  .three-col-spot .col-sm-4 > div h5 {
    font-size: @sw-font-base;
    font-weight: @sw-font-semi-bold;
    line-height: 1.1 !important;
    margin-bottom: 10px !important;

    @media (min-width: @screen-md-min) {
      font-size: @sw-font-md;
    }
  }

  h6,
  .h6,
  .two-col-spot .col-sm-6 .factbox h6,
  .three-col-spot .col-sm-4 .factbox h6,
  .factbox h6,
  .factbox .h6,
  .two-col-spot .col-sm-6 > div h6,
  .three-col-spot .col-sm-4 > div h6 {
    font-size: @sw-font-base;
    font-weight: @sw-font-semi-bold;
    line-height: 1.1 !important;
    margin-bottom: 10px !important;

    @media (min-width: @screen-md-min) {
      font-size: @sw-font-base;
    }
  }

  .header--medium {
    padding-left: 0;
    font-size: @sw-font-xl;
    line-height: 1.2;
    font-weight: @sw-font-semi-bold;
    font-family: "Founders Grotesk";

    @media (min-width: @screen-md-min) {
      font-size: 48px !important;
    }
  }

  .header--large {
    padding-left: 0;
    padding-right: 0;
    font-size: 34px;
    line-height: 40px;
    font-weight: @sw-font-bold;
    font-family: "Founders Grotesk";

    @media (min-width: @screen-md-min) {
      font-size: 60px;
      line-height: 66px;
    }
  }

  .panel-body {
    h1 {
      font-size: @sw-font-xl !important;
    }
    h2 {
      font-size: @sw-font-lg !important;
    }
    h3 {
      font-size: @sw-font-md !important;
    }
    h4,
    h5,
    h6 {
      font-size: @sw-font-base !important;
    }

    h1,
    .h1,
    h2,
    .h2 {
      margin-top: 0 !important;
      margin-bottom: 20px !important;
      padding: 0 !important;
    }

    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5,
    h6,
    .h6 {
      margin-top: 30px !important;
      margin-bottom: 10px !important;
    }
  }
}
