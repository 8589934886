﻿@import "../styles.less";

@color-vote-against: @color-brand-200;
@color-vote-for: @color-brand-info-100;
@color-vote-abstain: @color-brand-info-500;

.flertalsberegner {
  position: relative;
  float: left;
  width: 100%;

  * {
    -ms-user-select: none; /* IE 10+ */
    -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  .flertalsberegner-tooltip {
    display: none;
    position: fixed;
    background: @color-contrast-400;
    border: 1px solid @color-contrast-200;
    z-index: 100;
    padding: 0 5px;
    font-size: @font-size-small;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.3);

    &.active {
      display: block;
    }
  }

  .stemmeseddel {
    .clearfix();

    @media only screen and (max-width: @screen-sm) {
      padding: 0;
    }

    .title {
      float: left;
    }

    .icon-spinner {
      text-align: right;
      float: right;
      cursor: pointer;
    }

    .icon-checkmark {
      font-size: 10px;
      margin-left: 3px;
      cursor: pointer;
    }

    .icon-close {
      font-size: 8px;
      margin-left: 4px;
      cursor: pointer;
    }

    .icon-minus {
      font-size: 8px;
      margin-left: 4px;
      cursor: pointer;
    }

    .vote {
      width: 14px;
      height: 13px;

      @media only screen and (max-width: @screen-sm) {
        width: 24px;
        height: 23px;
      }

      border: 1px solid @color-contrast-100;
      background: @color-background-main;
      margin: 0 3px 0 0;
      border-radius: 2px;
      cursor: pointer;
      -moz-transition: 0.5s ease;
      -o-transition: 0.5s ease;
      -webkit-transition: 0.5s ease;
      transition: 0.5s ease;

      &.selected {

        &.vote-for {
          background-color: @color-vote-for;
        }

        &.vote-against {
          background-color: @color-vote-against;
        }

        &.vote-abstain {
          background-color: @color-vote-abstain;
        }
      }
    }

    .country-list {
      width: 100%;

      thead {
        td {
          text-align: left;

          &.vote-cell {
            width: 17px;
          }
        }
      }

      tbody {
        tr {
          &:nth-child(even) {
            background: @color-contrast-400;

            td {
              &:first-child {
                background: @color-contrast-400;
              }
            }
          }
        }

        td {
          .font-size(1);
          padding: 2px 0;

          @media only screen and (max-width: @screen-sm) {
            .font-size(1.8);
          }
        }
      }
    }

    .kvalificeret-flertal {
      margin-top: 10px;

      .votes-summary-table {
        width: 100%;

        .votes-summary-header {
          text-align: left;
          .font-bold();
          .font-size(1.1);

          @media only screen and (max-width: @screen-sm) {
            .font-size(1.6);
          }

          padding: 3px 0;

          &.active {
            &.flertal-table-header {
              background: @color-vote-for;
              color: @color-text-light;
            }

            &.mindretal-table-header {
              background: @color-vote-against;
              color: @color-text-light;
            }
          }
        }

        td {
          .font-size(1);
          padding: 2px 0;

          @media only screen and (max-width: @screen-sm) {
            .font-size(1.4);
          }

          .icon-checkmark {
            display: none;
          }

          &.active {
            .icon-checkmark {
              display: inline;
            }
          }
        }
      }
    }
  }

  svg {
    width: 100%;
    height: 500px;
    position: relative;
    z-index: 1;

    g {
      fill: @color-contrast-200;

      &:hover {
        fill: @color-contrast-300;
      }


      &.members {
        fill: @color-contrast-100;

        &:hover {
          fill: @color-brand-info-100;
        }

        -moz-transition: 0.5s ease;
        -o-transition: 0.5s ease;
        -webkit-transition: 0.5s ease;
        transition: 0.5s ease;

        &.vote-for {
          fill: @color-vote-for;
        }

        &.vote-against {
          fill: @color-vote-against;
        }

        &.vote-abstain {
          fill: @color-vote-abstain;
        }
      }
    }

    .borders {
      g {
        fill: @color-text-light;
      }
    }

    .country-box {
      stroke: @color-brand-info-300;
    }
  }
}
