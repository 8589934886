@import "../../styles.less";

// Description Lists
dl {
  margin-top: 0; // Remove browser default
  margin-bottom: 10px;

  &.list-inline {
		dt, dd {
			&[class*="col-"] {
				padding-left: 5px;
			}
		}  	
  }
}