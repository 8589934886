@import "../styles.less";

@-webkit-keyframes animate-opacity {
  .animation-opacity;
}

@-moz-keyframes animate-opacity {
  .animation-opacity;
}

@-ms-keyframes animate-opacity {
  .animation-opacity;
}

@-o-keyframes animate-opacity {
  .animation-opacity;
}

@keyframes animate-opacity {
  .animation-opacity;
}

.animation-opacity () {
  0% {
    display: none;
    opacity: 0;
  }

  1% {
    display: block;
    opacity: 0;
  }

  100% {
    display: block;
    opacity: 1;
  }
}

.text-spot-a-wrapper {
  margin-bottom: 20px;

  .two-col-spot &, .three-col-spot & {
    padding: 20px 23px;
    margin-bottom: 0 !important;
  }

  &.content-rte {
    img {
      width: 100%!important;
      height: auto!important;

      @media only screen and (min-width: @screen-sm) {
        width: auto!important;
        max-width: 100%!important;
      }
    }

    blockquote {
      font-size: 16px;
    }

    .read-more {
      overflow-wrap: anywhere;
    }
  }

  .manchet {
    display: block;
    opacity: 1;
  }

  .read-more { // each div-node with .read-more
    .font-regular();
    margin: 0 0 (@line-height-computed / 2);
    opacity: 0;
    display: none;

    .pageedit & {
      display: block;
      opacity: 1;
    }

    &:first-child {
      display: block;
      opacity: 1;
    }

    &.expand {
      display: block;
      -webkit-animation-duration: 1s;
      -webkit-animation-name: animate-opacity;
      -webkit-animation-easing: ease-in;
      -ms-animation-duration: 1s;
      -ms-animation-name: animate-opacity;
      -ms-animation-easing: ease-in;
      -o-animation-duration: 1s;
      -o-animation-name: animate-opacity;
      -o-animation-easing: ease-in;
      animation-duration: 1s;
      animation-name: animate-opacity;
      animation-easing: ease-in;
    }

    &.reveal {
      opacity: 1;
    }

    .rte-quote {
      .font-headline();
      .font-size(2.2);
      color: @color-brand-200;
      display: block;
      margin: 20px 0;
      padding-left: 25px;

      .site-ft & {
        position: relative;
        top: 3px;
      }

      &:before {
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }
  }

  .read-more-expander {
    background-color: @color-background-main;

    &.read-less {
      .caption-more {
        display: none;
      }

      .caption-less {
        display: block;
      }
    }

    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
    }

    .font-bold();
    color: @color-brand-200;
    display: block;
    text-align: center;
    border: 1px solid @border-color;
    padding: 10px;

    .caption-less {
      display: none;
    }

    .caption-more {
      display: block;
    }

    i {
      font-size: 0.8em;
      margin-left: 10px;
    }
  }
  //VOD
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  h2, h3, h4 {
    .site-euo .two-col-spot & {
      margin-top: 0;
    }
  }

  .text-spot-a-content ul:not(.link-list) li {
    list-style-type: disc;
  }
  
  .text-spot-a-content a {
    border-bottom: 1px solid #cacaca;
  }
  
  .text-spot-a-content a:hover {
    text-decoration: none;
    border-bottom: 1px solid #00755a;
  }
}
