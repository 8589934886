.subsite-wide {
  .forum-post {
    margin-bottom: @section-spacing-small;

      @media (min-width: @screen-md-min) {
        margin-bottom: @section-spacing;;
      }
    &__top {
      &:extend(.subsite-wide-container);
      &:extend(.spot--margin-bottom);

      @media (max-width: @screen-md-min) {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .kaltura-video-thumb {
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }

    &__top-image {
      width: 100%;
      max-width: 100%;
      height: auto;
    }

    &__content-container {
      padding-left: @spacing-small;
      padding-right: @spacing-small;
      width: 100%;
      margin: 0 auto;

      @media (min-width: @screen-md-min) {
        max-width: 780px;
        padding-left: @spacing-medium;
        padding-right: @spacing-medium;
      }
    }

    &__contributor {
      padding-right: @padding-medium;
      font-size: @sw-font-md;
      line-height: @sw-font-md + 8;

      @media (min-width: @screen-md-min) {
        font-size: @sw-font-lg;
        line-height: @sw-font-lg + 8;
        padding-right: 0;
      }
    }

    .content-rte {
      font-size: @sw-font-md;
      line-height: @sw-font-md + 8;
      margin-bottom: @section-spacing;
    }

    .content-pre-wrap {
      white-space: pre-wrap;
    }

    &__content-top {
      position: relative;
      margin-bottom: @padding-small;

      .pinned {
        width: 24px;
        height: 24px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
      }

      @media (min-width: @screen-md-min) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;

        .pinned {
          position: static;
          align-self: flex-end;
          transform: none;
        }
      }
    }

    h1 {
      margin-bottom: 48px !important;
    }

    &__cta {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (min-width: @screen-md-min) {
        align-items: flex-end;
      }

      .cta-links {
        padding-left: 0 !important;
        margin-bottom: 0 !important;

        @media (min-width: @screen-md-min) {
          padding-bottom: 0;
        }

        &-container {
          @media (max-width: @screen-lg-min) {
            align-items: flex-start;

            .cta-links-item {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    + .link-card-carousel {
      background-color: @sw-color-primary-light;
      background-color: var(--sw-color-primary-light, @sw-color-primary-light);
    }
  }
}
