﻿@import "../../Global/Components/phone-book.less";

.listespot-wrapper.ftMembersAddresses td.cellPic {
  @media (max-width: @container-small-desktop) {
    padding: 10px 38px 10px 38px !important;
    text-align: left;
    vertical-align: middle;
  }
}

.ftMembersAddresses {
  &__link {
    border-bottom: 1px solid @color-menu-selected-tab !important;

    &:hover {
      text-decoration: none !important;
      border-bottom: 1px solid @color-primary-function !important;
    }   
  }

  &__member-phone-folketinget, &__member-minister-phone, &__member-mobile-phone, &__member-private-phone {
    border-bottom: 0 !important;
    color: @color-primary-function !important;

    &:before {
      display: inline-block;
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);
      font-size: 11px;
      position: relative;
      margin-right: 5px;
      margin-top: 3px;
      @media (min-width: @screen-md) {
        content: '';
      }
    }

    &:hover {
      text-decoration: none !important;
      border-bottom: 1px solid @color-primary-function !important;
    }

    @media (min-width: @screen-md) {
      border-bottom: none !important;
      pointer-events: none;
      color: @color-primary-darker !important;
    }
  }
}