﻿@import "../../Global/Components/documentbasketspot-a.less";

.documentbasketspot-a {
  // Avoiding load jump. Fades in in this file: \Components\Project\Design\Dev\Scripts\Global\Components\search-facet.js
  display: none;
  &__link {

    &:before {
      color: @color-primary-function-bg;
    }
  }
}
