﻿@import "../styles.less";

.pageedit {
  .table-striped {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      padding: 10px;

      &:nth-child(odd) {
        background-color: @table-bg-accent;
      }

      .text {

      }

      .date {
        float: right;
      }
    }
  }
}

.link-list-spot-d {
	a.link-primary {
    &[href] {
      &:before {
          display: inline-block;
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_bullit_right:before);
          font-size: 11px;
          position: relative;
          margin-right: 10px;
          text-decoration: none!important;
      }
      &:hover {
        text-decoration: underline !important;
      }
    }
		&:hover {
			text-decoration: none!important;
		}
	}
}