﻿@import "../styles.less";

@menu-bar-color: @color-brand-info-200;


.snap-content {
  background: @color-background-main;
}

.sidebar-offcanvas {
  .snap-drawer {
    width: auto;
    overflow: scroll;
  }

  .bg-fix {
    position: fixed;
    top: 0;
    bottom: 0;
    .striped-col-brand(@color: @left-menu-wrapper-stripe-color-1; @backgroundColor: @left-menu-wrapper-stripe-color-2; @height: auto; 2px);
    content: '';
    background-color: @color-background-main;
    background-size: 2px;
    /*background-image: -webkit-linear-gradient(0, @color-background-main 50%, @color-contrast-300 50%);
    background-image: -moz-linear-gradient(0px 50%, @color-background-main 50%, @color-contrast-300 50%);
    background-image: -ms-linear-gradient(0, @color-background-main 50%, @color-contrast-300 50%);
    background-image: -o-linear-gradient(0, @color-background-main 50%, @color-contrast-300 50%);
    background-image: linear-gradient(90deg, @color-background-main 50%, @color-contrast-300 50%);*/
    background-image: @sekundaer-menu-a-stripes;
    height: auto;
    width: 100%;
  }
}

.offcanvas-menu-container {
  margin: 20px 0 0 0;
  position: relative;

  #quick-lookup {
    margin: 0 15px;
    width: 236px;

    .input-group {
      margin-top: 10px;
    }
  }

  > ul {
    width: 265px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      &:first-child {
        border-top: 1px solid @color-contrast-200;
      }
    }

    ul li {
      &:last-child {
        border-bottom: 0;
      }
    }

    li {
      border-bottom: 1px solid @color-contrast-200;

      a {
        -ms-word-break: break-word;
        word-break: break-word;
        display: inline-block;
        width: 100%;
        color: @color-text;

        &:hover, &:focus {
          text-decoration: none;
          color: @color-brand-200;
        }
      }
      span {
        i {
          display: none;
        }
      }
      div {
        display: table;
        width: 100%;
        position: relative;

        &:before {
          width: 5px;
          height: 100%;
          position: absolute;
        }

        i.toggle {
          display: table-cell;
          vertical-align: middle;
          width: 15%;
          position: relative;

          &:before {
            &:extend(.less-icon-extend);
            .font-size(2.2);
            padding: 10px;
          }
        }
      }

      ul {
        display: none;
      }

      &.has-children {
        > div i.toggle {
          &:before {
            &:extend(.icon-pil-cirkel-hoejre:before);
          }

          &:hover, &:focus {
            color: @color-brand-200;
            cursor: pointer;
          }
        }
      }

      &.active {
        padding-bottom: 0;
        padding-top: 0;

        > ul {
          display: block;
        }

        > div a {
          color: @color-brand-info-200;
          .font-bold();
        }

        &.has-children {
          > div i.toggle {
            &:before {
              color: @color-brand-info-200;
              &:extend(.icon-pil-cirkel-ned:before);
            }
          }
        }
      }

      &.level1 {
        div {
        }

        a {
          .font-bold();
          .font-size(2.2);
          padding: 10px 0px 10px 20px;
        }

        &.active {
          > div {
            background: fade(@color-brand-info-300, 20%);
          }
        }
      }

      &.level2 {
        div {
          &:before {
            content: "";
            background: fade(@color-brand-info-300, 20%);
          }
        }

        a {
          .font-regular();
          .font-size(2.2);
          padding: 10px 0px 10px 20px;
        }
      }

      &.level3 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 35%);
          }
        }

        a {
          .font-regular();
          .font-size(@type-size-xxl);
          padding: 10px 0px 10px 30px;
        }
      }

      &.level4 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 50%);
          }
        }

        a {
          .font-regular();
          .font-size(@type-size-xl);
          padding: 10px 0px 10px 40px;
        }
      }

      &.level5 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 65%);
          }
        }

        a {
          .font-regular();
          .font-size(1.6);
          padding: 10px 0px 10px 50px;
        }
      }

      &.level6 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 80%);
          }
        }

        a {
          .font-regular();
          .font-size(@type-size-base);
          padding: 10px 0px 10px 60px;
        }
      }

      &.level7 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 95%);
          }
        }

        a {
          .font-regular();
          .font-size(@type-size-base);
          padding: 10px 0px 10px 70px;
        }
      }
    }

    &.servicemenu {
      margin: 15px 0 0 0;

      li {
        a {
          color: @color-brand-300;
          padding: 10px 0px 10px 20px;
          .font-size(2.2);

          &:hover, &:focus {
            color: @color-text;
          }

          i {
            display: inline-block;
          }
           i.icon-ft-logo {
            background: url('../../Images/EUO/touch-icon/favicon-192x192.png')no-repeat center;
            display: inline-block;
            width: 23px;
            height: auto;
            position: relative;           
            background-size: 100%;

            &:before {
              opacity: 0;
            }
          }
          div {
            display: inline-block;
            width: 85%;
            margin-left: 6px;
          }
        }
      }
    }
  }
}

.top-searchbar {
  background: @color-brand-300;
  margin-top: -46px;

  .navbar-form {
    padding: 0 15px;
    margin: 10px 0 0;
    box-shadow: none;

    .input-group button {
      z-index: 2;
      background: @color-brand-100;
      border-color: @color-brand-100;
      color: @color-background-main;
    }
  }
}
