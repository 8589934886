﻿@import "../styles.less";

/*.tingdok {*/

.case-info-configurable-spot {
  .panel-heading{
    margin-bottom: 0;
  }

  .panel-middle .panel-heading .panel-title a.disabled {
    pointer-events: none;
  }

  .panel-middle .panel-heading .panel-title a.disabled:after {
    display: none;
  }

  .panel-middle .panel-heading + .panel-collapse .panel-body {
    padding: 0 40px 40px;
  }

  .heading {
    margin-bottom: 20px;
  }

  .normal {
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  div {
    margin-bottom: 5px;
  }

  div:last-of-type {
    margin-bottom: 20px;
  }

  .space {
    border-top: 1px solid grey;
    margin: 30px 0;
  }

  &__show-more {
    display: none;

    &.is-active {
      display: block;
    }
  }

  &__show-more-btn {
    display: block;
    margin: 10px 0 30px;

    .more {
      display: block;
    }

    .less {
      display: none;
    }

    &.is-active {
      .more {
        display: none;
      }

      .less {
        display: block;
      }
    }
  }
}

