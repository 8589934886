.subsite-wide {
  .intro--centered + .cta-links {
    text-align: center;

    .cta-links-container {
      justify-content: center;
    }
  }

  .cta-links {
    &:extend(.subsite-wide-container);

    &-container {
      display: flex;

      @media (max-width: @screen-lg-min) {
        flex-direction: column;
        align-items: center;
      }
    }

    &-item {
      + .cta-links-item {
        margin-bottom: 10px;
      }

      &:hover {
        text-decoration: none;
      }

      @media (min-width: @screen-lg-min) {
        + .cta-links-item {
          margin-left: 10px;
          margin-bottom: 0;
        }
      }

      @media (max-width: @screen-lg-min) {
        margin-bottom: 10px;
      }
    }

    &.cta-links--fixed {
      padding: 0;
      margin-bottom: 0 !important;

      .cta-links-item:first-of-type {
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 1031;
        border-radius: 0;
        width: 100%;
        margin-bottom: 0;

        @media (min-width: @screen-lg-min) {
          height: 55px;
          top: 33px;
          right: 30px;
          bottom: auto;
          border-radius: 60px;
          font-size: 20px !important;
          width: auto;
        }

        @media (min-width: 1530px) {
          right: 55px;
        }
      }
    }
  }
}
