﻿.panel {
  &.panel-form {
    background-color: transparent;
    box-shadow: none;
  }
}

.panel-group {
  margin-top: 0;
  margin-bottom: 30px;
  background-color: #fff;
  //generic tag style
  h1 {
    padding: 0;
  }

  h2:not(.panel-title) {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  h5 {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  h6 {
    margin-top: 10px;
    margin-bottom: 5px;
  }


  a.link-list {
    display: block;
    padding-left: 15px;

    &:before {
      display: inline-block;
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);
      font-size: 11px;
      float: left;
      margin: 4px 0 0 -15px;
    }
  }

.panel-middle {
    .panel-heading {
      .panel-title {
        padding-left: 38px;
        padding-right: 0;
        margin-top: 0;
        margin-bottom: 0;

        a {
          .font-alt();
          .font-size(2);
          // line-height: 50px;
          color: @color-text;
          position: relative;

          span {
            .font-size(2);
          }

          @media only screen and (max-width: @screen-xs-max) {
            .font-size(1.5);
            position: relative;
            padding-right: 55px;
            // line-height: 50px;
            height: auto;
            min-height: 50px;

            span {
              .font-size(1.5);
            }
          }

          &:after {
            margin-right: 18px;
            background-color: #fff;
            &:extend(.icon-ft_accordionminus:before);

            @media only screen and (max-width: @screen-sm) {
              position: absolute;
              background-color: transparent;
              top: 50%;
              right: 0;
            }
          }

          &.collapsed {
            &:after {
              &:extend(.icon-ft_accordionplus:before);
            }
          }
        }
      }
    }

    .panel-body {
      padding: 20px 0 30px;
      .font-size(1.6);

      p {
        margin: 0 38px 10px;
      }

      .list-spot {
        margin-left: 0;
        margin-right: 0;
      }

      table {
        thead, tbody {
          tr {
            th, td {
              &:first-child {
                padding-left: 38px;

                img {
                  max-width: fit-content;
                }
              }

              &:last-child {
                padding-right: 38px;
              }
            }
          }
        }
      }
    }
  }

  .panel-middle .panel-heading--no-accordion {
    .panel-title a:after {
      display: none;
    }
  }

  .tingdok__vote__total_results {
    display: table;
    width: 100%;
  }

  &.tingdok__vote {
    #tingdok_accordion_vote-2 .listespot-wrapper {
      table {
        td:before {
          padding-left: 30px;
          font-weight: normal;
        }
      }
    }

    .panel-middle .panel-body p {
      margin: 0 15px 10px;
      color: @color-primary-dark;
    }
  }

  .panel + .panel {
    margin-top: 0;
    border-top: 0;
  }
}

.panel-group >.panel-middle {
  &:first-child {
    border-top: 1px solid @color-secondary-light-3;
  }
  &:last-child {
    border-bottom: 1px solid @color-secondary-light-3;
  }
  .panel-middle:last-child {
    border-bottom: none;
  }
}

.panel-group  + .panel-group .panel-middle:first-child {
  border-top: none;
}
.panel-group  + .panel-group[data-has-nested-accordion="true"] >.panel-middle:first-child {
  border-top: 1px solid @color-secondary-light-3;
}
.panel-group[data-has-nested-accordion="false"][data-is-accordion="true"]:first-child .panel-middle {
  border-top: 1px solid @color-secondary-light-3;
}
.panel-group[data-has-nested-accordion="false"][data-is-accordion="false"] .panel-middle {
  border-top: none;
  border-bottom: none;
}

.accordion-row + .accordion-row .panel-group .panel-middle  {
  border-top: none;
}
.accordion-row > .col-sm-12 {
  padding-left: 0;
  padding-right: 0;
}

// Half accordion
.two-col-spot .panel-group a, .three-col-spot .panel-group a {
  padding-right: 27px;
}

/**
 * Actual accordion-specific styling are located as part of the spot and Bootstrap styles
 * This file is created to accomodate the use for disabling accordion using a css class
 */
.remove-accordion {
  .panel-title {
    display: none;
  }

  .panel-collapse {
    display: block !important;
    height: auto !important;
    padding-left: 38px;
    padding-right: 38px;
  }
}
