﻿.font-face(@name: "font-name", @file-path-name: "OpenSans", @font-weight: normal, @font-style: normal) {
  @font-face {
    font-family: '@{name}';
    src: url('@{file-path-name}.eot');
    src: url('@{file-path-name}.eot?#iefix') format('embedded-opentype'), url('@{file-path-name}.woff') format('woff'), url('@{file-path-name}.ttf') format('truetype');
    font-weight: @font-weight;
    font-style: @font-style;
    font-variant: normal;
  }
}

.font-size(@sizeValue: 1.55, @lineModifier: 1.2, @important: "") {
  @remValue: @sizeValue;
  @pxValue: (@sizeValue * 10);
  font-size: ~"@{pxValue}px" e(@important);
  font-size: ~"@{remValue}rem" e(@important);

  @pxLineHeight: (@pxValue * @lineModifier);
  @remLineHeight: @remValue * @lineModifier;
  line-height: ~"@{pxLineHeight}px" e(@important);
  line-height: ~"@{remLineHeight}rem" e(@important);
}
