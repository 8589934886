/**** _Mixins ****/
.clearfix() {
  &:before,
  &:after {
    content: "";
    display: table;
  }

  &:after {
    clear: both;
  }
}

.transition(@style: color, @speed: 0.5s) {
  -webkit-transition: @style @speed ease;
  -moz-transition: @style @speed ease;
  -o-transition: @style @speed ease;
  transition: @style @speed ease;
}

.border-radius(@radius: 5px) {
  -webkit-border-radius: @radius;
  -moz-border-radius: @radius;
  border-radius: @radius;
}

.gradient-linear(@colorTop: @button-gradient-start, @colorBottom: @button-gradient-end) {
  background-color: @colorBottom;
  background-image: -webkit-gradient(linear, 0% 0%, 0% 100%, from(@colorTop), to(@colorBottom));
  background-image: -webkit-linear-gradient(top, @colorTop, @colorBottom);
  background-image: -moz-linear-gradient(top, @colorTop, @colorBottom);
  background-image: -ms-linear-gradient(top, @colorTop, @colorBottom);
  background-image: -o-linear-gradient(top, @colorTop, @colorBottom);
}

/************** Quizpedia **************/
.social-video.quiz {
  .iframe-wrapper {
    position: relative;
    padding-bottom: 147.25%;
    padding-top: 25px;
    height: 0;

    iframe {
      border: 0 !important;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    .two-col-spot &, .three-col-spot & {
      padding-bottom: 145.25%;
    }
  }
}

// overlay quiz
/* lightbox styles */
.close-iframe {
  position: absolute;
  top: 0;
  right: 0;
  width: 42px;
  height: 42px;
  padding: 0;
  cursor: pointer;
  z-index: 8040;
  font-weight: 100;
  transition: all 0.5s ease;
  background-color: transparent;
  color: white;

  @media only screen and (min-width: @screen-sm) {
    width: 52.5px;
    height: 52.5px;
    top: 10px;
    right: 10px;
    font-size: 20px;
    font-weight: normal;
  }

  &:after {
    content: "\2716";
    display: table;
    margin: 24% auto;
    color: @text-color;

    .site-ft & {
      margin: 32% auto;
    }
  }
}

.close-iframe:hover {
  color: white;
  text-decoration: none;
}

#lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1031;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA9JREFUeNpiYGBg2AwQYAAAuAC01qHx9QAAAABJRU5ErkJggg==) repeat;
}

/* slideshow styles */
#slideshow {
  position: relative;
  z-index: 900000;
  margin: 0;
  width: 100%;
  padding: 0;

  @media only screen and (min-width: @screen-sm) {
    max-width: 1000px;
    margin: 0 auto;
    padding: 10px;
  }

  .iframe-items .iframe-wrapper {
    position: relative;
    padding-bottom: 145.5%;
    padding-top: 25px;
    height: 0;

    @media only screen and (min-width: @screen-sm) {
      padding-bottom: 100%;
      // padding-bottom: 138.25%;
    }

    iframe {
      border: 0 !important;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }
  }
}

#slideshow .iframe-items > .iframe-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  list-style: none;

  @media only screen and (min-width: @screen-sm) {
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
  }
}

.search-overlay--wrapper {
  svg {
    margin: 28% auto;
  }
}

/************** END Quizpedia **************/

/**** Styling ****/

.quiz-presentation {
  img {
    display: block;
    margin: 40px auto;
  }
}

.pt.quiz {
  .font-size(@type-size-base);
  padding: 25px;
  background: @quiz-options-bg;

  * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .quiz-statusbar {
    display: table;
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    left: 27%;
    top: 51px;

    .quiz-status-text {
      .font-size(@type-size-lg);
      .font-bold();
      float: left;
      width: 125px;
      line-height: 15px;
      display: inline-block;
    }

    .quiz-status-graphic {
      display: none;
      height: 15px;
      margin-left: 130px;
      background: @quiz-statusbar-track-bg;

      .quiz-status-graphic-inner {
        display: inline-block;
        height: 15px;
        background: @quiz-statusbar-progress-bg;
      }
    }
  }

  .quiz-intro-headline,
  .quiz-question-headline,
  .quiz-answer-headline,
  .quiz-result-headline {
    .font-size(@type-size-lg);
    .font-bold();
    margin: 0 0 .5em 0;

    &.quiz-answer-correct {
      color: @color-correct;
    }

    &.quiz-answer-wrong {
      .font-size(@type-size-lg);
      .font-bold();
      color: @color-incorrect;
    }
  }

  .quiz-question-headline {
    display: none;
  }

  .quiz-result-text + p,
  .quiz-question-text + p,
  .quiz-answer-text + p {
    .font-size(@type-size-base);
    margin-bottom: 25px;
  }

  .quiz-question {
    position: relative;
    left: 27%;
    top: -97px;
    line-height: 17px;
    width: 72%;
  }

  .quiz-answer {
  }


  .quiz-result {
    .clearfix();

    .status-icon {
      float: right;
      margin-right: 10px;
    }
  }

  .quiz-question-text,
  .quiz-answer-text,
  .quiz-result-text {
  }

  .quiz-result-text {
    .quiz-result-first-line {
      display: block;
      padding-right: 55px;
      margin-bottom: 1.5em;
    }
  }

  .quiz-social-wrapper {
    .clearfix();
    padding-top: 15px;
    margin-bottom: 20px;
  }

  .quiz-image-wrapper,
  .quiz-answer {
    //can be used to center the image and hide overflow on smaller devices instead of resizing the image
    display: table;
    width: 130px;
    height: 130px;

    img {
      width: 130px;
      height: 130px !important;
      max-width: 130px;
      max-height: 130px;
      border-radius: 65px;
    }
  }

  .quiz-answer {
    width: auto;
    height: auto;
    display: block;

    .quiz-image-wrapper {
      width: auto;
      height: auto;
      display: block;
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }

  .quiz-options {
    background: @quiz-options-bg;
    padding: 18px 8px;
    margin-bottom: 16px;

    .quiz-option {
      display: block;
      position: relative;
      padding: 10px 5px;
      // background: #fff;
      // border:2px solid #e5e4e4;
      margin-bottom: 15px;
      cursor: pointer;
      .transition(border-color);

      &:after {
        background-repeat: no-repeat;
        position: absolute;
        content: "";
        width: 50px;
        height: 50px;
        max-height: 100%;
        right: 0;
        top: 0;
      }

      &:hover {
        border-color: #fff;
      }

      input[type="radio"] {
        position: absolute;
        margin: 0;
        padding: 0;
        top: 11px;
        left: 10px;
      }

      .quiz-option-text {
        .font-size(@type-size-base);
        display: block;
        margin-left: 25px;
        margin-right: 40px;
      }

      &.quiz-option-correct {
        border: 2px solid #42ad02;
        margin-left: 2px;
        margin-right: 2px;
        padding-left: 8px;

        input[type="radio"] {
          left: 8px;
        }

        &:after {
          &:extend(.less-icon-extender:before);
          &:extend(.icon-checkmark:before);
          right: 10px;
          width: 20px;
          top: 50%;
          margin-top: -10px;
          color: #42ad02;
          font-size: 2rem;
        }
      }

      &.quiz-option-wrong {
        background-color: #fff;

        &:after {
          &:extend(.less-icon-extender:before);
          &:extend(.icon-close:before);
          right: 10px;
          width: 20px;
          top: 50%;
          margin-top: -10px;
          color: #9e0a1d;
          font-size: 2rem;
        }
      }
    }

    &.quiz-options-locked {
      .quiz-option {
        .quiz-option-wrong {
          background-color: #fff;
        }

        cursor: default;
      }

      .quiz-option-text {
      }
    }
  }

  .quiz-button-wrapper {
    .clearfix();

    .quiz-button {
      float: right;
    }

    padding-right: 2px;
  }

  .quiz-footer {
    .clearfix();
    padding-top: 20px;

    .quiz-button {
      float: right;
    }
  }

  .quiz-button {
    .btn-with-icon-right-v2( @color: @btn-flownav-text-color; @color-hover: @btn-flownav-text-color-hover; @background: @btn-flownav-bg; @background-hover: @btn-flownav-bg-hover; @font-size: @btn-flownav-font-size; @line-height: @btn-flownav-line-height; @icon-color: @btn-flownav-icon-color; @icon-color-hover: @btn-flownav-icon-color-hover; @icon-bg: @btn-flownav-icon-bg; @icon-bg-hover: @btn-flownav-icon-bg-hover; @icon-size: @btn-flownav-icon-font-size; );
    padding: 10px 16px 10px 16px;
  }

  .quiz-intro-button {
    text-align: center;
    padding: 20px 0;

    .quiz-button {
      .font-size(@type-size-md * 2);
      padding: 20px 30px;
      .border-radius(10px);

      &:hover {
        background: @color-correct;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .pt {
    &.quiz {
      padding-top: 25px;

      .quiz-question {
        position: static;
        width: 100%;
      }

      .quiz-image-wrapper {
        margin: 0 auto;
      }

      .quiz-button-wrapper, .quiz-footer {
        margin: 0 auto;
        display: table;
      }

      .quiz-answer {
        display: block;
        width: auto !important;
        height: auto !important;

        div {
          img {
            display: block;
            margin: 0 auto;
          }
        }
      }

      .quiz-statusbar {
        margin: 0 auto;
        display: table;
        position: static;
        margin-bottom: 20px;

        .quiz-status-text {
          margin: 0 auto;
          display: table;
          float: none;
        }
      }

      .quiz-options {
        margin-top: 0;

        .quiz-option {
          padding-left: 0;

          .quiz-option-text {
            margin-left: 30px;
            line-height: 17px;

            @media screen and (orientation:landscape) {
              line-height: 27px;
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}
