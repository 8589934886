﻿@import "../styles.less";

.telbogTable {
  width: 100%;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  thead {
    tr {
      th {
        text-align: left;
        .font-alt();
        font-weight: 400;
        color: @color-text;
        background-color: #fff;
        padding: 10px 38px;

        &:first-child {
          border-right: 1px solid @color-tertiary-result-absent;
        }

        span {
          font-weight: 400;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        background-color: #fff;
        padding: 10px 38px;
        text-align: left;
        position: relative;

        .cellRow {
          .make-row();
          margin: 0;
          position: relative;
        }
        .cellPic {
          .make-sm-column(4);
          padding-left: 0;
          text-align: center;

          @media only screen and (min-width: @screen-sm-min) {
            text-align: left;
          }

          img {
            border-radius: 50%;
            width: 100%;
            max-width: 100px;
            height: auto;
          }
        }

        & + td {
          @media only screen and (min-width: @screen-md) {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAIAAAAW4yFwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABNJREFUeNpiuHz5MhMDAwNAgAEADuYCfDXdYB4AAAAASUVORK5CYII=');
            background-repeat: no-repeat;
            background-size: 1px 80%;
            background-position: 0px 50%;
          }
        }

        .cellInfo {
          .make-sm-column(8);
          text-align: center;

          @media only screen and (min-width: @screen-sm) {
            text-align: left;
            position: absolute;
            top: 50%;
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            margin-left: 33%;
          }
					a {
						&:after {
							display: inline-block;
							&:extend(.less-icon-extend);
							&:extend(.icon-ft_bullit_right:before);
							font-size: 11px;
							position: relative;
							margin-left: 5px;
							vertical-align: middle;
						}
					}
        }

        .seperator {
          width: 22px;
          height: 42px;
          max-width: 22px;
          position: absolute;
          top: 50%;
          left: 100%;
          margin-left: -11px;
          margin-top: -21px;
          z-index: 100;
        }
      }

      &:nth-child(odd) {
        //background: @color-table-alternate-row;
        background-color: @color-table-alternate-row;

        .seperator {
          background-color: @color-table-alternate-row;
        }

        td {
          background-color: @color-table-alternate-row;

          &:first-child {
            //background: @color-table-alternate-row;
            background-color: @color-table-alternate-row;
          }
        }
      }
    }
  }

  tfoot {
    tr {
      td {
        background-color: @color-secondary-lighter;
        padding: 10px 38px;

        &:first-child {
          //border-right: 1px solid @color-tertiary-result-absent;
        }
        & + td {
          @media only screen and (min-width: @screen-md) {
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAIAAAAW4yFwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABNJREFUeNpiuHz5MhMDAwNAgAEADuYCfDXdYB4AAAAASUVORK5CYII=');
            background-repeat: no-repeat;
            background-size: 1px 80%;
            background-position: 0px 50%;
          }
        }
      }
    }
  }
  //members group table
  &.members-groups {

    thead tr {
      .digits {
        padding-left: 10px;
      }

      .title {
        border-right: 0;
      }

      th {
        padding: 14px 38px;
        .font-size(1.2);

        // @media only screen and (max-width: @screen-md) {
        //   .font-size(1.4);
        // }
      }
    }

    tbody tr {
      .digits {
        padding-left: 0;
      }

      td {
        padding: 14px 38px;
        .font-size(1.6);
				a {
					&:before {
						display: inline-block;
						&:extend(.less-icon-extend);
						&:extend(.icon-ft_bullit_right:before);
						font-size: 11px;
						float: left;
						margin: 4px 6px 0 0;
					}
				}
      }
    }

    tfoot tr {
      .digits {
        padding-left: 0;
      }

      td {
        padding: 14px 38px;
        .font-size(1.6);

        @media only screen and (max-width: @screen-md) {
          .font-size(1.4);
        }
      }
    }

    .member-container {
      display: table;
      text-align: right;
      width: 37px;
      font-size: 1.6rem;

      @media screen and (min-width: @screen-md) {
        font-size: 1.6rem;
      }
    }
  }
}
