
.result-list-spot-accordion {

  &__manchet {
    margin-bottom: 25px;
    padding: 0 38px;
  }

  &__table-wrapper {
    margin: 0 !important;
    padding: 0 !important;

    .listespot-wrapper {
      margin: 0 !important;
    }

    .ankiro .ankiro-results tr td.righty p {
      @media (max-width: @screen-md) {
        padding-left: 20px !important;
      }
    }

    .listespot-wrapper.ankiro {
      table tbody tr {
        &:hover {
          cursor: auto;
        }

        &.has-url:hover {
          cursor: pointer;
        }

        th {
          font-weight: normal;
          font-family: SceneAlt,serif !important;
          font-size: 12px;

          @media (max-width: @screen-md) {
            display: none;
          }
          /*a {
            font-family: SceneAlt,serif !important;
            pointer-events: none;
            color: #454545 !important;
          }*/
        }

        td {
          &:first-child {
            width: auto !important;
            font-size: 14px;
          }
          /*a {
            color: #454545 !important;
          }*/
        }
      }
    }

    table {
      width: 100%;

      tr {

        td {
          padding: 20px 25px 20px 38px;
          vertical-align: top;

          @media only screen and (max-width: 992px) {
            display: block;
            padding: 10px 38px !important;
          }

          &:first-child {
            font-size: 16px;

            @media only screen and (max-width: 992px) {
              display: block;
              padding-bottom: 0;
            }
          }

          &:last-child {
            padding-left: 10px;
            font-size: 14px;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAIAAAAW4yFwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABNJREFUeNpiuHz5MhMDAwNAgAEADuYCfDXdYB4AAAAASUVORK5CYII=');
            background-repeat: no-repeat;
            background-size: 1px 80%;
            background-position: 0 50%;

            .result-list-spot-accordion.singelcolumn & {
              background-image: none !important;
            }
          }

          .search-result-link {
            color: @color-primary-function !important;
            margin-top: 0;
            margin-bottom: 3px;
            position: relative;
            font-size: 1.6rem !important;
            line-height: 1.8rem !important;
          }


          div {
            a {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  table {
    width: 100%;

    tr {

      td {
        padding: 20px 25px 20px 38px;
        vertical-align: top;

        @media only screen and (max-width: 992px) {
          display: block;
          padding: 10px 38px !important;
        }

        &:first-child {
          font-size: 16px;

          @media only screen and (max-width: 992px) {
            display: block;
            padding-bottom: 0;
          }
        }

        &:last-child {
          padding-left: 10px;
          font-size: 14px;
          background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAIAAAAW4yFwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABNJREFUeNpiuHz5MhMDAwNAgAEADuYCfDXdYB4AAAAASUVORK5CYII=');
          background-repeat: no-repeat;
          background-size: 1px 80%;
          background-position: 0 50%;

          .result-list-spot-accordion.singelcolumn & {
            background-image: none !important;
          }
        }

        .search-result-link {
          color: @color-primary-function !important;
          margin-top: 0;
          margin-bottom: 3px;
          position: relative;
          font-size: 1.6rem;
        }


        div {
          a {
            font-size: 14px;
          }
        }
      }
    }
  }

  div.row.two-col-spot &, div.row.three-col-spot & {
    padding: 0 !important;

    .listespot-wrapper {
      .search-result-link {
        font-family: arial;
      }

      td.righty {
        background-image: none;

        @media screen and (min-width: @screen-sm) {
          padding-left: 58px !important;
        }
      }
    }
  }

  &__panel-link {
    float: left;
    margin-left: 38px;
    position: relative;
    top: -15px;

    .singelcolumn & {
      display: block;
    }

    @media screen and (min-width: @screen-sm) {
      margin-right: 38px;
    }

    &:after {
      display: inline-block;
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);
      font-size: 11px;
      position: relative;
      margin-left: 5px;
    }
  }
}
.content-show-wrapper__document {
  .content-show-wrapper__link-rows {
    > .search-result-link:not(.has-link-related-documents) {
      &:before {
        &:extend(.less-icon-extender:before);
        &:extend(.icon-ft_document:before);
        position: absolute;
        left: -2rem;
      }
    }
  }
}
.content-show-wrapper {
  &__link-rows {
    position: relative;
    margin-top: 0;
    margin-bottom: 3px;
    font-size: 16px;
    font-size: 1.6rem;
    line-height: 19.2px;
    line-height: 1.92rem;
  }

  td {
    padding: 20px 25px 20px 38px;
    vertical-align: top;

    @media only screen and (max-width: 992px) {
      display: block;
      padding: 10px 38px !important;
    }

    &:first-child {
      font-size: 16px;

      @media only screen and (max-width: 992px) {
        display: block;
        padding-bottom: 0;
      }
    }

    &:last-child {
      padding-left: 10px;
      font-size: 14px;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAIAAAAW4yFwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABNJREFUeNpiuHz5MhMDAwNAgAEADuYCfDXdYB4AAAAASUVORK5CYII=');
      background-repeat: no-repeat;
      background-size: 1px 80%;
      background-position: 0 50%;

      .result-list-spot-accordion.singelcolumn & {
        background-image: none !important;
      }
    }

    .search-result-link {
      display: block;
      margin-left: 20px;
      color: @color-primary-function !important;
      margin-top: 0;
      margin-bottom: 3px;
      position: relative;
      font-size: 1.6rem;
    }


    div {
      a {
        font-size: 14px;
      }
    }
  }
}

