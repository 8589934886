﻿@import "../Variables/_colors.less";
@import "../Variables/_typography.less";

@media only screen and (max-width: 768px) {
  .left-menu-wrapper {
    display: none;
  }

  .bg-fix.hidden-xs {
    display: none;
  }
}

// ha
.left-menu-wrapper .site-identity-container .site-identity .site-title {
  margin-top: 20px !important;
}

.secondary-menu-b {
  margin: 0 -15px;

  &__menu {
    list-style: none;
    margin: 0;
    padding: 0;

    .secondary-menu-b__menu {
      background: @color-secondary-light;
      //padding: 15px 0;
    }

    &__item {
      &.active {
        a {
          padding-left: 50px;
        }
        /*ul + ul {
          li {
            a {
              padding-left: 50px;
            }
          }
        }*/
      }

      a {
        border: 2px solid @color-secondary-light;
        border-width: 0 0 2px 0;
      }

      &:first-child {
        a {
          border-width: 0 0 2px 0;
        }
      }

      &--level1 {
      }

      &__link {
        display: block;
        .font-alt();
        position: relative;
        margin: 0px 0 0 0;

        span {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;

          i {
            &:extend(.less-icon-extend);
            content: "";
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
            -webkit-transform: translateY(-50%);
            transform: translate(-50%, -50%);
          }
        }

        &:before {
        }

        &:hover {
          color: @color-primary-link-hover;
          text-decoration: none;

          span {
            width: 10px;
            background-color: @color-menu-hover;
          }
        }

        &--level1 {
          .font-size(2);
          color: @color-menu-level1;
          padding: 16px 0 16px 37px;


          &.selected {
            background-color: @color-menu-selected-bg;
            color: @color-text-dark;
            border-color: @color-secondary-lighter;

            span {
              width: 25px;
              background-color: @color-secondary-accessibility;

              i {
                &:before {
                  &:extend(.icon-ft_bullit_down:before);
                  font-size: 10px;
                  top: -3px;
                  position: relative;
                }
              }
            }
          }

          &.active {
            background-color: @color-secondary-light;
            color: @link-color;
            border-color: @color-secondary-lighter;

            span {
              width: 25px;
              background-color: @color-menu-hover;

              i {
                &:before {
                  &:extend(.icon-ft_bullit_down:before);
                  font-size: 10px;
                  top: -3px;
                  position: relative;
                }
              }
            }
          }
        }

        &--level2, &--level3, &--level4, &--level5, &--level6, &--level7, &--level8 {
          .font-size(1.5);
          color: @color-menu-level2;
          padding: 15px 0 15px 50px;
          background: @color-background-main;

          &.has-children {
            span {
              i {
                &:before {
                  &:extend(.icon-ft_menu:before);
                  color: #999;
                  font-size: 8px;
                  position: relative;
                  top: -4px;
                  left: 30px;
                }
              }
            }

            &:hover {
              span {
                i {
                  &:before {
                    left: 25px;
                  }
                }
              }
            }

            &.selected {
              span {
                i {
                  &:before {
                    &:extend(.icon-ft_bullit_down:before);
                  }
                }
              }
            }

            &.active {
              span {
                i {
                  &:before {
                    &:extend(.icon-ft_bullit_right:before);
                    left: 100%;
                  }
                }
              }
            }
          }

          &.selected {
            background-color: @color-menu-selected-bg;
            color: @color-text-dark;
            border-color: @color-secondary-lighter;
            padding-left: 37px;

            span {
              width: 25px;
              background-color: @color-secondary-accessibility;

              i {
                &:before {
                  &:extend(.icon-ft_bullit_down:before);
                  color: #fff;
                  font-size: 10px;
                  top: -3px;
                  left: 0;
                  position: relative;
                }
              }
            }

            &:hover {
              span {
                i {
                  &:before {
                    left: 0;
                  }
                }
              }
            }
          }

          &.active {
            background-color: @color-secondary-light;
            color: @color-primary-link;
            border-color: @color-secondary-lighter;
            //padding-left: 47px;
            span {
              width: 36px;
              background-color: @color-menu-hover;

              i {
                &:before {
                  &:extend(.icon-ft_bullit_right:before);
                  color: #fff;
                  font-size: 10px;
                  top: -3px;
                  left: 100%;
                  -moz-transform: translate(-50%, -100%);
                  -ms-transform: translate(-50%, -100%);
                  -o-transform: translate(-50%, -100%);
                  -webkit-transform: translate(-50%, -100%);
                  transform: translate(-50%, -100%);
                  position: relative;
                }
              }
            }

            &:hover {
              span {
                i {
                  &:before {
                    left: 100%;
                  }
                }
              }
            }
          }
        }
      }
    }

    &__service {
      list-style: none;
      margin: 40px 0 0 0;
      padding: 0;

      &__item {
        &__link {
          .font-size(1.5);
          color: @color-menu-level1;
          padding: 0 0 15px 37px;
          display: block;

          &:before {
            &:extend(.less-icon-extend);
            &:extend(.icon-ft_bullit_right:before);
            display: inline-block;
            color: @color-primary-function-bg;
            font-size: 11px;
            position: relative;
            top: -1px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
