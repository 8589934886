﻿.page {
  margin: 0 auto;
  max-width: 1370px;
}

.content-area {
  margin-bottom: 2em;

  .manchet + div > a {
    color: @link-secondary-color;
  }

  @media(min-width:@screen-md-min) {
    padding-left: 30px;
  }
}

.container {
  @media(min-width:@screen-sm-min) {
    width: 100%;
  }

  @media(min-width:@screen-md-min) {
    max-width: @container-lg;
  }

  .row {
    &.ribbon-spot {
      //margin-bottom: 1em;
      //padding: 0 0 40px;
      /*float: left;
      margin: 15px 0;

      @media (min-width: @screen-sm) {
        //padding: 40px 0;
      }*/
      .category-label {
        .icon, [class^="icon-"], [class*=" icon-"] {
          font-size: 5.5rem;
          color: @color-brand-200;
        }
      }
    }

    &:first-child {
      .ribbon-spot {
        //padding: 0 0 40px;
      }
    }
  }
}

.wrapper {
  .clearfix();

  &.stripe {
    padding: 25px 0;

    &:nth-child(even) {
      background: @color-contrast-300;

      .list-secondary {
        border-color: @color-contrast-200;
      }
      /*&.subpage {
        background: @color-background-main;
      }*/
    }

    &:nth-child(odd) {
    }
  }

  .middle-row {
    position: relative;
    min-height: 490px;

    .content-container, .content-area, .js-text-area {
      margin-bottom: 2em;

      p {
      }

      img, figure {
        &.pull-left {
          margin: 0 10px 10px 0;
        }

        &.pull-right {
          margin: 0 0 10px 10px;
        }
      }

      figure {
        .clearfix();

        img {
          float: none;
        }

        figcaption {
          display: inline-block;
          background: @color-contrast-300;
          color: @color-text;
          .font-size(@type-size-md);
          padding: 10px;
          width: 100%;
        }
      }
    }
  }
}

.load-map {
  display: none;
}

.push-25 {
  margin-left: 25px;
}

@media(max-width: @screen-xs-min) {
}

@media(min-width: @screen-sm-min) {
}
/* or @screen-sm */
@media(min-width:@screen-md-min) {
}
/* or @screen-md */
@media(min-width: @screen-lg) {
}


@media (max-width: @screen-xs-max) {
  .content-action-print {
    display: none;
  }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .content-action-print {
    display: none;
  }
}

/* or @screen-lg */
.content-action-links .content-action a {
  i {
    &.icon-facebook {
      color: @color-social-facebook;
    }

    &.icon-twitter {
      color: @color-social-twitter;
    }
  }

  &:hover {
    color: @color-brand-200;

    i {
      &.icon-facebook {
        color: @color-social-facebook;
      }

      &.icon-twitter {
        color: @color-social-twitter;
      }
    }
  }
}

.stylefree-list {
  list-style: none;
  padding: 0;
}

.display-table {
  display: table;
}

.display-row {
  display: table-row;
}

.display-cell {
  display: table-cell;
  vertical-align: middle;
}

.question-form {
  &.form-horizontal {
    label {
      text-align: left;
    }
  }

  input[type="text"], textarea {
    width: 100%;
  }

  textarea {
    min-height: 5em;
  }

  .form-control {
    background: @color-contrast-400;
    resize: none;
  }

  input[type="radio"].form-control {
    height: auto;
    width: auto;
    display: inline-block;
  }
}

.margin-bottom {
    margin-bottom: 25px;
}


/* Hack to frontendediting to prevent insert button overlap */
[sc-part-of="placeholder rendering"] {
  min-height: 50px;
  padding-bottom: 10px;

  &.wrapper {
    padding-bottom: 0px;
  }

  &.panel-group {
    padding-bottom: 20px;
  }
}



.pointer--hover {
    cursor: pointer !important;
    td {
      cursor: pointer !important;
    }
}

.is-new {
  box-shadow: 0 0 0;
  -webkit-animation: pulse 0.5s;
  -webkit-animation-iteration-count: 1;
  -moz-animation: pulse 0.5s;
  -o-animation: pulse 0.5s;
  animation: pulse 0.5s;
  -moz-animation-iteration-count: 1;
  -o-animation-iteration-count: 1;
  animation-iteration-count: 1;
}


@-webkit-keyframes pulse {
  from { box-shadow: 0 0 0; }
  50% { box-shadow: 0 0 10px; }
  to { box-shadow: 0 0 0; }
}
@-moz-keyframes pulse {
  from { box-shadow: 0 0 0; }
  50% { box-shadow: 0 0 10px; }
  to { box-shadow: 0 0 0; }
}

@keyframes pulse {
  from { box-shadow: 0 0 0; }
  50% { box-shadow: 0 0 10px; }
  to { box-shadow: 0 0 0; }
}

.bot-detect {
  &__content {
    margin-bottom: 10px;
    margin-left: 1px;
  }

  &__input {
    @media(min-width: @screen-sm-min) {
      margin-left: 25%;
    }
  }
}