﻿@import "../styles.less";

.hero-content {
  .map-container {
    float: left;
    height: 394px;
    width: 100%;
    padding: 1em;
  }

  svg {
    width: 100%;
    float: left;
    height: 394px;
    padding: 1em;

    g {
      &.members {
        fill: @color-brand-info-700;

        &:hover, &.selected {
          fill: @color-brand-300;

          .country-box {
            stroke: @color-brand-info-200;
          }
        }
      }
      
      &.non-members {
        cursor: default;
        fill: @color-brand-info-450;
      }

      &.borders {
        fill: @color-brand-info-300;
      }

      .country-box {
        stroke: @color-brand-info-300;
      }
    }
  }



  .country-info {
    display: none;
    text-align: right;

    &.selected {
      display: block;
    }
  }
}
