﻿@import "../styles.less";

.swirl {
  //background: transparent url(../../../images/Global/EUO/swirl3.png) no-repeat 254px top;
  background: transparent url(../../../Dist/images/EUO/swirl4.png) no-repeat -18px -1px;
  height: 394px;
}

.page-hero {
  height: 394px;

  .gradient {
    #gradient > .vertical(@start-color: @hero-bg-gradient-start; @end-color: @hero-bg-gradient-end);
    background-repeat: repeat-x;
    height: 394px;
  }

  &.collapsed {
    height: 69px;
    overflow: hidden;

    .container {
      height: 69px;

      .input-group {
        &.expand {
          display: block;
        }

        &.collapse {
          display: none;
        }
      }

      .hero-tabs,
      .hero-content,
      .striped-col {
        display: none;
      }
    }
  }

  .container {
    height: auto;
    position: relative;

    .input-group {
      position: absolute;
      right: 15px;
      bottom: 0;
      //z-index: 1000;

      &.expand {
        display: none;
      }
    }
  }

  .striped-col {
    padding: 20px;
    .striped-col-brand(lighten(@hero-bg-gradient-start, 5%), transparent, 394px, 2px);
  }

  .hero-tabs {
    .title {
      .font-regular();
      .font-size(@type-size-lg);
      color: @hero-tabs-title-color;
      margin: 25px 0;
    }

    .nav-tabs {
      border-top: 1px solid darken(@hero-bg-gradient-start, 5%);

      li {
        position: relative;
        width: 100%;
        border-bottom: 1px solid darken(@hero-bg-gradient-start, 5%);

        &.active {
          &:after {
            color: @hero-tabs-active-color;
          }

          a {
            background-color: transparent;
            border: 0;
            color: @hero-tabs-active-color;
          }
        }

        a {
          background-color: transparent;
          cursor: pointer;
          .font-regular();
          .font-size(@type-size-xxl);
          color: @hero-tabs-color;
          padding: 20px 0;
          max-width: 180px;
          border: 0;
        }

        &:after {
          .font-size(@type-size-xs);
          margin-top: -5px;
          position: absolute;
          right: 0;
          top: 50%;
          &:extend(.less-icon-extend);
          &:extend(.icon-pil-hoejre:before);
          float: right;
          color: @hero-tabs-color;
        }
      }
    }
  }

  .tab-content {
    > .active {
      background-color: transparent;
      padding: 0;
    }
  }

  .hero-content {
    height: 394px;

    h4 {
      .font-size(2.1);
    }

    .col-sm-7 {
      padding-left: 4em;
    }

    .col-sm-5 {
      padding-left: 4em;

      .display-cell {
        text-align: right;
      }
    }

    .display-table {
      height: 394px;
      width: 100%;
      vertical-align: middle;
    }

    .display-cell {
      vertical-align: middle;

      a {
        .btn-with-icon-right-v2(@color: @color-text-light; @color-hover: @color-text-light; @background: @color-brand-200; @background-hover: @color-brand-100; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @color-text-light; @icon-color-hover: @color-text-light; @icon-bg: @color-brand-100; @icon-bg-hover: darken(@color-brand-100, 5%); @icon-size: @btn-action-icon-font-size;);
        white-space: normal;

        &:before {
          font-family: "ft-icons";
          content: "\e60e";
          color: @color-background-main;
        }
      }
    }
  }
}

.btn-hero {
  .btn-with-icon-right-v2(@color: @hero-button-text-color; @color-hover: @hero-button-text-color-hover; @background: @hero-button-bg; @background-hover: @hero-button-bg-hover; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @hero-button-icon-color; @icon-color-hover: @hero-button-icon-color-hover; @icon-bg: @hero-button-icon-bg; @icon-bg-hover: @hero-button-icon-bg-hover; @icon-size: @btn-action-icon-font-size;);
  margin-right: 0;
  border-radius: 3px 3px 0 0;
  padding: 8px 38px 8px 13px;
  .font-bold();
  .font-size(@type-size-lg);

  &:before {
    border-radius: 0 3px 0 0;
    .font-size(@type-size-xs);
    width: 27px;
    padding-left: 8px;
  }
}

.pageedit {
  .page-hero {
    .tab-content {
      display: block;
    }
  }
}

@media (max-width: 720px) {
  .page-hero {
    height: auto;

    .gradient {
      height: auto;
    }

    .container {
      height: auto;
    }

    .swirl {
      height: auto;
    }

    .hero-tabs {
      .title {
        margin: 0 0 15px 0;
      }

      .nav-tabs {
        li {
          &.active {
            &:after {
              color: @hero-tabs-color;
            }

            a {
              color: @hero-tabs-color;
            }
          }

          a {
            max-width: 95%;
          }
        }
      }
    }

    .hero-content {
      display: none;

      * {
        display: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .page-hero {
    .hero-content {
      display: none;
    }
  }
}

@media (max-width: @screen-sm) {
  .page-hero {
    height: auto;

    .gradient {
      height: auto;
    }

    .container {
      height: auto;
    }

    .swirl {
      height: auto;
    }

    .hero-tabs {
      .title {
        margin: 0 0 15px 0;
      }
    }

    .hero-content {
      //display: none;
      .col-sm-7 {
        padding-left: 15px;
      }

      .col-sm-5 {
        padding-left: 15px;

        img {
          height: auto !important;
          width: 100% !important;
        }
      }
    }
  }
}

@media (min-width: @screen-sm) and (max-width: 930px) {
  .page-hero {



    .gradient {
    }

    .container {
    }

    .swirl {
    }

    .hero-tabs {
      .title {
      }

      .nav-tabs {
        li {
          &.active {
            &:after {
            }

            a {
            }
          }

          a {
            padding: 15px 10px 15px 0;
            max-width: 150px;
            .font-size(1.8);
          }

          &:after {
          }
        }
      }
    }

    .hero-content {
      .col-sm-7 {
        padding-left: 15px;
      }

      .col-sm-5 {
        padding-left: 15px;

        img {
          height: auto !important;
          width: 100% !important;
        }
      }
    }
  }
}
