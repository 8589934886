/*------------------------------------*\
		booking-item
    Created by kvr on 08-08-2016
\*------------------------------------*/

.booking-item {
  margin-top: 22px;
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 2px solid @color-secondary-lighter;
  }
}

.booking-item__stats {
  width: ~"calc(100% - 120px)";
  margin: 0;
  padding: 0;
  font-weight: bold;

  @media(max-width:@screen-xs) {
    width: 100%;
  }

  li {
    padding-bottom: 5px;
    list-style-type: none;
		font-weight: normal;
    &:last-child {
      padding-bottom: 0;
    }
  }

  span:first-child {
    font-weight: normal;
  }
}

.show-all-document {
  .font-size(1.5);
  color: @color-menu-level1;
  padding: 0 0 15px 37px;
  display: block;

  &:hover {
    color: @link-hover-color!important;
  }

  &:before {
    display: inline-block;
    color: @color-primary-function-bg;
    font-size: 11px;
    position: relative;
    top: -1px;
    padding-right: 10px;
    content: "\e631";
    font-family: "ft-icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .secondary-menu-container & {
    margin: 0!important;
  }
}

.editRedBtn {
  .glyphicon-pencil {
    -ms-transform: rotate(-44deg); /* IE 9 */
    -webkit-transform: rotate(-44deg); /* Chrome, Safari, Opera */
    transform: rotate(-44deg);
    position: relative;
    top: 1px;
    left: 3px;
  }

  .glyphicon-pencil:before {
    content: "\270f";
  }
}

.booking-item__actions {
  position: absolute;
  top: 0;
  right: 0;
  margin-bottom: 15px;

  @media(max-width:@screen-xs) {
    margin-bottom: 10px;
    position: relative;
  }
}

.booking-item__action {
  display: block;
  text-align: left;

  @media(min-width:@screen-sm) {
    text-align: right;
  }

  i {
    padding-left: 5px;
    display: inline-block;
    font-size: 13px;
  }
}
