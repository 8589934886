﻿.rss {
  &__action {
    &:hover {
      text-decoration: none;

      span, i {
        color: @color-primary-function;
      }
    }

    i {
      display: inline-block;

      margin-right: 5px;
    }

    span {

    }
  }

  &__popup-content {
    display: none;
    padding: 15px;

    &__list {
      list-style: none;
      margin: 0;
      padding: 0;

      &__item {

        margin-top: 5px;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  }

  .popover {
    background-color: #fff;

    &.bottom {
      > .arrow {
        border-bottom-color: #fff;

        &:after {
          border-bottom-color: #fff;
        }
      }
    }

    .popover-content {
      i {
        font-size: 15px;
      }
    }
  }
}
