﻿.image-text-spot {
  margin-bottom: 30px;
  position: relative;


  &.has-no-content {
    margin-bottom: 0;
  }

  .two-col-spot .col-sm-6 > &, .three-col-spot .col-sm-4 > & {
    padding: 0 !important;
  }

  &__manchet {
    display: table;
    padding-left: 38px;
    padding-right: 38px;

    .two-col-spot &, .three-col-spot & {
      padding-left: 0;
      padding-right: 0;
    }    
  }

  h2&__title {
    display: inline-block;
    font-family: "SceneAlt", serif !important;
    .font-size(2)!important;
    margin-top: 0 !important;
    margin-bottom: 15px !important;
    padding-left: 38px;
    padding-right: 38px;

    @media (max-width: @screen-sm) {
      margin-bottom: 5px !important;
    }

    .two-col-spot &, .three-col-spot & {
      .font-size(2)!important;
      padding-left: 0;
      padding-right: 0;
    }

    .has-no-image & {
      font-weight: bold;
    }

    @media (min-width: @screen-sm) {
      .font-size(2.5)!important;
    }
  }

  &__main-link {
    display: table;
    width: 100%;
    min-height: 118px;

    .two-col-spot .has-no-image &, .three-col-spot .has-no-image & {
      min-height: 118px;
    }

    @media (min-width: @screen-sm) {
      min-height: 128px;
    }
  }

  &__link {
    display: inline-block !important;
    padding-left: 15px;
    border-bottom: none !important;
    margin-top: 15px;
    padding-left: 53px;

    &:hover {
      text-decoration: underline !important;
      background-color: transparent;
    }

    &:before {
      display: inline-block;
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);
      font-size: 11px;
      float: left;
      margin: 4px 0 0 -15px;
    }
  }

  &__mask {
    min-height: 100%;
    padding: 15% 0.75rem 0.75rem 0.75rem;
    display: block;
    float: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    width: 100%;
    height: auto;
    background: linear-gradient(transparent 45%, rgba(0, 0, 0, 0.8));
    background-color: transparent;  
    bottom: -1px;
    transition: all 0.2s ease-in-out;
    &:hover{
      background: linear-gradient(transparent 45%, rgba(0, 0, 0, 0.75))
    }
  }

  &__image-container {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;

    &__image {
      width: 100% !important;
      height: 100% !important;
      display: block;
    }
  }

  &__text-container {
    position: absolute;
    bottom: 0;
    width: auto;
    background: transparent;
    padding-bottom: 1px;
    background-position-y: -1px;
    bottom: -1px;
    z-index: 9;

    .text-spot-a-wrapper {
      padding: 0;
    }

    .two-col-spot &, .three-col-spot & {
      padding: 0 !important;
    }

    img {
      width: 100%;
      height: auto;
    }

    a:not(.front-arrow):not(.link-list):not(.factbox__link) {
      color: @color-brand-200;
      border-bottom: 1px solid @color-menu-selected-tab;
      padding-right: 0 !important;

      &:hover {
        text-decoration: none;
        border-bottom: 1px solid @color-brand-200;
      }
    }

    .rte-quote {
      .font-headline();
      .font-size(2.2);
      position: relative;
      top: 3px;
      color: @color-brand-200;
      display: block;
      margin: 20px 0;
      padding-left: 25px;

      &:before {
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }
  }
}
