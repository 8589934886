@import "../../Global/Components/factbox.less";
@import "../../Blue/Components/factbox.less";

.large.tooltip-inner {
  max-width: 350px;
  width: auto;
}

.factbox {
  color: @color-primary-dark;

  &__title {
    font-family: "SceneAlt", serif;
    .font-size(2.5);
    .two-col-spot &{
      .font-size(2);
    }
  }

  &__link {
    display: inline-block;
    padding-left: 15px;
    border-bottom: none;

    &:hover {
      text-decoration: underline;
      background-color: transparent;
    }

    &:before {
      display: inline-block;
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);
      font-size: 11px;
      float: left;
      margin: 4px 0 0 -15px;
    }
  }

  &__text-container {
    img {
      width: 100%;
      height: auto;
    }

    .rte-quote {
      .font-headline();
      .font-size(2.2);
      color: @color-brand-200;
      display: block;
      margin: 20px 0;
      padding-left: 25px;

      &:before {
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }
  }
}
