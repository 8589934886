﻿@import "../styles.less";

.agenda-info {
	margin: 0 24px 20px;

	&__meta {
		margin-top: 30px;
	}

	&__att {
	}

	&__ver {
		&:after {
			content: "|";
			margin: 0 10px;
			display: inline-block;
		}
	}

	.agenda-date-title {
		// font-weight: bold;
		// width: 100%;
		display: block;

		@media(min-width:@screen-sm) {
			// width: 25%;
			display: inline-block;
		}
	}

	.agenda-date-value {
		// width: 100%;
		display: block;

		@media(min-width:@screen-sm) {
			// width: 70%;
			display: inline-block;
		}
	}
}

.download--agenda {
	margin-bottom: 30px;

	.popover {
		background: #ffffff;

		&.bottom {
			> .arrow {
				border-bottom-color: #ffffff;

				&:after {
					border-bottom-color: #ffffff;
				}
			}
		}
	}

	.download__container__docBtns {
		margin: 0;
		padding: 0;
	}
}
