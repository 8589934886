﻿@import "../../Global/Components/offcanvas.less";


@menu-bar-color: @color-brand-info-200;


.snap-content {
  background: @color-background-main;
}

.sidebar-offcanvas {
  .snap-drawer {
    width: auto;
    overflow: scroll;
  }

  .bg-fix {
    position: fixed;
    top: 0;
    bottom: 0;
    //.striped-col-brand(@color: @left-menu-wrapper-stripe-color-1; @backgroundColor: @left-menu-wrapper-stripe-color-2; @height: auto; 2px);
    content: '';
    background-color: @color-background-main;
    background-size: 2px;
    /*background-image: -webkit-linear-gradient(0, @color-background-main 50%, @color-contrast-300 50%);
    background-image: -moz-linear-gradient(0px 50%, @color-background-main 50%, @color-contrast-300 50%);
    background-image: -ms-linear-gradient(0, @color-background-main 50%, @color-contrast-300 50%);
    background-image: -o-linear-gradient(0, @color-background-main 50%, @color-contrast-300 50%);
    background-image: linear-gradient(90deg, @color-background-main 50%, @color-contrast-300 50%);*/
    background-image: @sekundaer-menu-a-stripes;
    height: auto;
    width: 100%;
  }

  .secondary-menu-b__menu__item__link {
    span {
      background-color: @color-primary-function;

      i {
        display: none !important;
      }
    }
  }

  .subscribe-menu {
    margin-top: -10px !important;
  }
}

/*.subscribe-menu {
  margin-top: -10px !important;

  ul li.active span i {
    display: none;
  }

  ul li.level1 > div {
    border-bottom: 0 !important;
  }

  ul li.active li {
    border-color: transparent !important;
  }
  ul li.active li a.active span{
    background-color: transparent;
  }

  ul li.active span {
    background-color: transparent;
  }
}*/

.subscribe-menu {
  margin: 0;
  position: relative;

  #quick-lookup {
    margin: 0 15px;
    width: 236px;

    .input-group {
      margin-top: 10px;
    }
  }

  > ul {
    //width: 265px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      &:first-child {
        border-top: 2px solid @color-background-main;
      }
    }

    &.primary {
      > li {
        border-top: 0;
      }
    }

    ul li {
      &:last-child {
        border-bottom: 0;
      }
    }

    li {
      border-bottom: 2px solid @color-background-main;

      a {
        -ms-word-break: break-word;
        word-break: break-word;
        display: inline-block;
        width: 100%;
        color: @color-primary-darker;

        &:hover, &:focus {
          text-decoration: none;
          color: initial;
        }
      }

      span {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
      }

      div {
        display: table;
        width: 100%;
        position: relative;
        background-color: @primaer-menu-a-background;

        &:before {
          width: 5px;
          height: 100%;
          position: absolute;
        }

        i.toggle {
          display: table-cell;
          vertical-align: middle;
          width: 15%;
          position: relative;

          &:before {
            &:extend(.less-icon-extend);
            .font-size(2.2);
            padding: 10px;
          }
        }
      }

      ul {
        display: none;
      }

      &.has-children {
        > div i.toggle {
          &:before {
            &:extend(.icon-pil-cirkel-hoejre:before);
          }

          &:hover, &:focus {
            color: initial;
            cursor: pointer;
          }
        }
      }

      &.active {
        padding-bottom: 0;
        padding-top: 0;
        border-color: @primaer-menu-a-background;

        li {
          border-color: transparent;
        }

        > ul {
          display: block;
        }

        span {
          width: 10px;
        }

        > div a {
          background-color: @color-menu-selected-bg;
          color: @link-color;
          border-color: @color-tertiary-result-absent;
          .font-alt();
        }

        > div {
          border-bottom: 0 !important;

          i {
            background-color: @color-menu-selected-bg;

            &:before {
              color: @color-primary-link;
            }
          }
        }

        &.active-page {
          > div a {
            background-color: @color-background-main;
          }

          > div {
            i {
              background-color: @color-background-main;

              &:before {
                color: @color-primary-link;
              }
            }
          }
        }

        &.has-children {
          > div i.toggle {
            &:before {
              color: @color-primary-link;
              &:extend(.icon-pil-cirkel-ned:before);
            }
          }
        }

        li div {
          background-color: @color-menu-selected-bg;
        }
      }

      &.level1 {
        &.has-children.active {
          > div a {
            color: @color-text-dark;
          }

          &.active-page {
            > div a {
              color: @link-color;
            }
          }
        }

        div {
          border-bottom: 0 !important;
        }

        a {
          .font-alt();
          .font-size(2);
          font-weight: 400;
          padding: 10px 0px 10px 20px;
        }

        &.active {
          a span i {
            display: inherit;
          }

          > div {
            background: fade(@color-menu-selected-bg, 20%);
          }
        }
      }

      &.level2 {
        &.has-children.active {
          > div a {
            color: @color-primary-darker;
          }

          &.active-page {
            > div a {
              color: @link-color;
            }
          }
        }

        div {
          &:before {
            content: "";
            background: fade(@color-menu-selected-bg, 20%);
          }
        }

        a {
          .font-alt();
          .font-size(1.5);
          font-weight: 400;
          padding: 10px 0px 10px 30px;
        }
      }

      &.level3 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 35%);
          }
        }

        a {
          .font-alt();
          .font-size(1.5);
          font-weight: 400;
          padding: 10px 0px 10px 40px;
        }
      }

      &.level4 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 50%);
          }
        }

        a {
          .font-alt();
          .font-size(1.5);
          font-weight: 400;
          padding: 10px 0px 10px 50px;
        }
      }

      &.level5 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 65%);
          }
        }

        a {
          .font-alt();
          .font-size(1.5);
          font-weight: 400;
          padding: 10px 0px 10px 60px;
        }
      }

      &.level6 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 80%);
          }
        }

        a {
          .font-alt();
          .font-size(1.5);
          font-weight: 400;
          padding: 10px 0px 10px 70px;
        }
      }

      &.level7 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 95%);
          }
        }

        a {
          .font-alt();
          .font-size(1.5);
          font-weight: 400;
          padding: 10px 0px 10px 80px;
        }
      }
    }

    &.servicemenu {
      margin: 15px 0 0 0;

      li {
        a {
          color: @color-brand-300;
          font-size: 15px;
          font-size: 1.5rem;
          color: #252525;
          padding: 0 0 15px 23px;
          display: block;

          &:before {
            content: "\e631";
            font-family: "ft-icons";
            display: inline-block;
            color: @color-primary-function-bg;
            font-size: 11px;
            position: relative;
            top: -1px;
            padding-right: 5px;
          }

          &:hover, &:focus {
            color: @color-text;
          }

          i {
            display: inline-block;
          }

          div {
            display: inline-block;
            width: 85%;
            margin-left: 0;
            background-color: transparent;

            &:hover {
              color: @color-primary-function-bg;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.offcanvas-menu-container {
  margin: 0;
  position: relative;

  #quick-lookup {
    margin: 0 15px;
    width: 236px;

    .input-group {
      margin-top: 10px;
    }
  }

  > ul {
    //width: 265px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
      &:first-child {
        border-top: 2px solid @color-background-main;
      }
    }

    &.primary {
      > li {
        border-top: 0;
      }
    }

    ul li {
      &:last-child {
        border-bottom: 0;
      }
    }

    li {
      border-bottom: 2px solid @color-background-main;

      a {
        -ms-word-break: break-word;
        word-break: break-word;
        display: inline-block;
        width: 100%;
        color: @color-primary-darker;

        &:hover, &:focus {
          text-decoration: none;
          color: initial;
        }
      }

      span {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
      }

      div {
        display: table;
        width: 100%;
        position: relative;
        background-color: @primaer-menu-a-background;

        &:before {
          width: 5px;
          height: 100%;
          position: absolute;
        }

        i.toggle {
          display: table-cell;
          vertical-align: middle;
          width: 15%;
          position: relative;

          &:before {
            &:extend(.less-icon-extend);
            .font-size(2.2);
            padding: 10px;
          }
        }
      }

      ul {
        display: none;
      }

      &.has-children {
        > div i.toggle {
          &:before {
            &:extend(.icon-pil-cirkel-hoejre:before);
          }

          &:hover, &:focus {
            color: initial;
            cursor: pointer;
          }
        }
      }

      &.active {
        padding-bottom: 0;
        padding-top: 0;
        border-color: @primaer-menu-a-background;

        li {
          border-color: @primaer-menu-a-background;
        }

        > ul {
          display: block;
        }

        span {
          width: 10px;
          background-color: @color-menu-hover;
        }

        > div a {
          background-color: @color-menu-selected-bg;
          color: @link-color;
          border-color: @color-tertiary-result-absent;
          .font-alt();
        }

        > div {
          i {
            background-color: @color-menu-selected-bg;

            &:before {
              color: @color-primary-link;
            }
          }
        }

        &.active-page {
          > div a {
            background-color: @color-background-main;
          }

          > div {
            i {
              background-color: @color-background-main;

              &:before {
                color: @color-primary-link;
              }
            }
          }
        }

        &.has-children {
          > div i.toggle {
            &:before {
              color: @color-primary-link;
              &:extend(.icon-pil-cirkel-ned:before);
            }
          }
        }

        li div {
          background-color: @color-menu-selected-bg;
        }
      }

      &.level1 {
        &.has-children.active {
          > div a {
            color: @color-text-dark;
          }

          &.active-page {
            > div a {
              color: @link-color;
            }
          }
        }

        div {
        }

        a {
          .font-alt();
          .font-size(2);
          font-weight: 400;
          padding: 10px 0px 10px 20px;
        }

        &.active {
          > div {
            background: fade(@color-menu-selected-bg, 20%);
          }
        }
      }

      &.level2 {
        &.has-children.active {
          > div a {
            color: @color-primary-darker;
          }

          &.active-page {
            > div a {
              color: @link-color;
            }
          }
        }

        div {
          &:before {
            content: "";
            background: fade(@color-menu-selected-bg, 20%);
          }
        }

        a {
          .font-alt();
          .font-size(1.5);
          font-weight: 400;
          padding: 10px 0px 10px 30px;
        }
      }

      &.level3 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 35%);
          }
        }

        a {
          .font-alt();
          .font-size(1.5);
          font-weight: 400;
          padding: 10px 0px 10px 40px;
        }
      }

      &.level4 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 50%);
          }
        }

        a {
          .font-alt();
          .font-size(1.5);
          font-weight: 400;
          padding: 10px 0px 10px 50px;
        }
      }

      &.level5 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 65%);
          }
        }

        a {
          .font-alt();
          .font-size(1.5);
          font-weight: 400;
          padding: 10px 0px 10px 60px;
        }
      }

      &.level6 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 80%);
          }
        }

        a {
          .font-alt();
          .font-size(1.5);
          font-weight: 400;
          padding: 10px 0px 10px 70px;
        }
      }

      &.level7 {
        div {
          &:before {
            content: "";
            background: fade(@menu-bar-color, 95%);
          }
        }

        a {
          .font-alt();
          .font-size(1.5);
          font-weight: 400;
          padding: 10px 0px 10px 80px;
        }
      }
    }

    &.servicemenu {
      margin: 15px 0 0 0;

      li {
        a {
          color: @color-brand-300;
          font-size: 15px;
          font-size: 1.5rem;
          color: #252525;
          padding: 0 0 15px 23px;
          display: block;

          &:before {
            content: "\e631";
            font-family: "ft-icons";
            display: inline-block;
            color: @color-primary-function-bg;
            font-size: 11px;
            position: relative;
            top: -1px;
            padding-right: 5px;
          }

          &:hover, &:focus {
            color: @color-text;
          }

          i {
            display: inline-block;
          }

          div {
            display: inline-block;
            width: 85%;
            margin-left: 0;
            background-color: transparent;

            &:hover {
              color: @color-primary-function-bg;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.top-searchbar {
  background-color: transparent;
  margin: 0 -15px;
  height: 0;
  overflow: hidden;

  &.is-open {
    overflow: visible;
  }

  .navbar-form {
    background: @color-secondary-lightest;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: none;

    .input-group button {
      z-index: 2;
      background: @color-secondary-lightest;
      border-color: @color-secondary-lightest;
      border-radius: 0;
      color: @color-brand-300;
      height: 60px;

      span:before {
        font-size: 24px;
      }
    }
  }

  input {
    height: 60px;
  }

  .input-group .twitter-typeahead:first-child .tt-input, .input-group .twitter-typeahead:first-child .tt-hint {
    padding: 30px 20px;
    font-size: 30px;

    @media(max-width: 768px) {
      font-size: 14px;
    }
  }
  /*.tt-dropdown-menu {
    display: none !important;
  }*/
}

.snap-drawer .filter-left-menu .panel-group {
  margin: 0;
}


// PBI: Bug-26170. Fixing the width of the offcanvas navigation for ipad
.sidebar-offcanvas.sidebar-offcanvas--alignment {
  .snap-drawer {
    #cases-filter {
      .input-group {
        display: block;
        margin: 0 0 15px;

        &.pull-right {
          padding: 0 10px;
          width: 100%;

          .btn-right {
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 768px) {
      #cases-filter {
        padding: 0 30px 0 10px;

        .search-field {
          width: 95%;
        }

        .input-group.pull-right {
          display: block;
          margin: 0 10px;
          width: auto;

          .btn-right {
            margin: 0;
            min-width: initial;
            text-align: center;
            padding-left: 0;
            padding-right: 0;
            width: 95%;
          }
        }
      }
    }
  }
}
