/*------------------------------------*\
		send-document
    Created by kvr on 25-07-2016
\*------------------------------------*/

.form--send-doc {

  fieldset {
    margin: 20px 0;
    padding: 20px;
    border: 1px solid @color-secondary-dark;
  }
}

.form--send-doc__subtitle {
  margin: 0;
  .font-alt();
}

.form--send-doc__list {
  margin: 0;
  padding: 0;
}
.form--send-doc__list-item {
  margin-top: 10px;
  list-style-type: none;
  font-weight: 400;

  &:first-child {
    margin-top: 0;
  }
}