﻿.btn-with-icon-right( @color: @btn-default-color; @color-hover: @btn-default-color; @background: @btn-default-bg; @background-hover: darken(@background, 10%); @font-size: 1.3; @tab-width: 40px; @line-height: 35px; @icon-color: lighten(@background, 60%); @icon-color-hover: lighten(@background, 60%); @icon-bg: darken(@background, 10%); @icon-bg-hover: darken(darken(@background, 10%), 8%); @icon-size: inherit; @icon-h-offset: 0; @icon-v-offset: 0;) {
  &:extend(.btn);
  &:extend(.btn-md);
  .button-variant(@color; @background; darken(@background, 55%));
  display: table;
  padding: 0;
  border: 0;

  > span:first-child {
    display: table-cell;
    padding: 0 15px;
    vertical-align: middle;
    .font-bold();
    .font-size(@font-size);
  }

  .btn-push-icon {
    background: @icon-bg;
    color: @icon-color;
    display: table-cell;
    height: 100%;
    width: @tab-width;
    line-height: @line-height;
    border-radius: 0 4px 4px 0;
    padding-top: 2px;

    &:before {
      top: @icon-v-offset;
      left: @icon-h-offset;
      position: relative;
      font-size: @icon-size;
    }
  }

  &:hover {
    background: @background-hover;
    color: @color-hover;
    text-decoration: none;

    .btn-push-icon {
      color: @icon-color-hover;
      background: @icon-bg-hover;
    }
  }
}

.btn-with-icon-left( @color: @btn-default-color; @color-hover: @btn-default-color; @background: @btn-default-bg; @background-hover: darken(@background, 10%); @font-size: 1.3; @tab-width: 40px; @line-height: 35px; @icon-color: lighten(@background, 60%); @icon-color-hover: lighten(@background, 60%); @icon-bg: darken(@background, 10%); @icon-bg-hover: darken(darken(@background, 10%), 8%); @icon-size: inherit; @icon-h-offset: 0; @icon-v-offset: 0;) {
  .btn-with-icon-right(@color; @color-hover; @background; @background-hover; @font-size; @tab-width; @line-height; @icon-color; @icon-color-hover; @icon-bg; @icon-bg-hover; @icon-size; @icon-h-offset; @icon-v-offset);
  .button-variant(@color; @background; darken(@background, 5%));

  > span:first-child {
    float: right;
    line-height: @line-height;
  }

  .btn-push-icon {
    float: left;
    background: darken(@background, 10%);
    color: @icon-color;
    border-radius: 4px 0 0 4px;
    text-indent: 0;
  }

  &:hover {
    .btn-push-icon {
      background: darken(darken(@background, 10%), 8%);
    }
  }
}
/*test*/

.btn-with-icon-left-v2( @color: @btn-default-color; @color-hover: @btn-default-color; @background: @btn-default-bg; @background-hover: darken(@background, 10%); @font-size: 1.3; @tab-width: 40px; @line-height: 35px; @icon-color: lighten(@background, 60%); @icon-color-hover: lighten(@background, 60%); @icon-bg: darken(@background, 10%); @icon-bg-hover: darken(darken(@background, 10%), 8%); @icon-size: inherit; @icon-h-offset: 0; @icon-v-offset: 0;) {
  &:extend(.btn);
  &:extend(.btn-md);
  .button-variant(@color; @background; darken(@background, 5%));
  .font-regular();
  .font-size(@font-size);
  color: @color;
  cursor: pointer;
  display: inline-block;
  letter-spacing: 1px;
  font-weight: 700;
  outline: none;
  position: relative;
  border: 0;
  //-webkit-transition: all linear 0.2s;
  //-moz-transition: all linear 0.2s;
  //-o-transition: all linear 0.2s;
  //transition: all linear 0.2s;
  border-radius: 3px;
  padding: 10px 16px 10px (@tab-width+10);
  text-decoration: none;

  &:before {
    position: absolute;
    height: 100%;
    left: 0;
    top: 0;
    width: @tab-width;
    background: @icon-bg;
    color: @icon-color !important;
    z-index: 2;
    border-radius: 2px 0 0 2px;
    text-align: left;
    padding-left: 14px;
    line-height: (@line-height + @icon-v-offset) !important;
    //-webkit-transition: all linear 0.2s;
    //-moz-transition: all linear 0.2s;
    //-o-transition: all linear 0.2s;
    //transition: all linear 0.2s;
    /*-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;*/
  }

  .no-touch & {
    &:hover {
      color: @color-hover;
      background: @background-hover;
      text-decoration: none;

      &:before {
        color: @icon-color-hover;
        background: @icon-bg-hover;
      }
    }
  }
}



.btn-with-icon-right-v2( @color: @btn-default-color; @color-hover: @btn-default-color; @background: @btn-default-bg; @background-hover: darken(@background, 10%); @font-size: 1.3; @tab-width: 40px; @line-height: 35px; @icon-color: lighten(@background, 60%); @icon-color-hover: lighten(@background, 60%); @icon-bg: darken(@background, 10%); @icon-bg-hover: darken(darken(@background, 10%), 8%); @icon-size: inherit; @icon-h-offset: 0; @icon-v-offset: 0;) {
  .btn-with-icon-left-v2(@color; @color-hover; @background; @background-hover; @font-size; @tab-width; @line-height; @icon-color; @icon-color-hover; @icon-bg; @icon-bg-hover; @icon-size; @icon-h-offset; @icon-v-offset);
  padding: 10px (@tab-width+10) 10px 16px;
  margin-right: 5px;

  &:before {
    position: absolute;
    height: 100%;
    left: auto;
    right: 0;
    top: 0;
    border-radius: 0 2px 2px 0;
  }
}
