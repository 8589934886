﻿@import "../styles.less";


.nested-article__wrapper {
  > .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.nested-article__title {
  &__container {
    //background-color: #fff;
    padding: 20px 38px;

    @media only screen and (max-width: @screen-xs-max) {
      padding: 10px;
    }
  }

  &__image {
    padding-left: 0;
    margin-top: -20px;

    @media only screen and (max-width: @screen-xs-max) {
      text-align: center;
      padding-right: 0;
      padding-top: 0;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__text {
    h1 {
      margin-top: 0;
      padding: 0;
    }

    .manchet {
      padding: 0;
      margin-bottom: 0;
    }
  }
}

.nested-article__child-list-with-images {
  > .row {
    margin-left: 0;
    margin-right: 0;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 0 0;
    &:hover {
      .nested-title {
        text-decoration: underline;
      }
    }
  }

  &__image {
    padding-left: 0;
    padding-top: 0;

    @media only screen and (max-width: @screen-xs-max) {
      text-align: center;
      padding-right: 0;
      padding-top: 0;
    }

    img {
      max-width: 100%;
      height: auto;
    }
  }

  &__text {
    h2 {
      .font-alt();
      .font-size(2);
    }
    .nested-title {
        &:after {
          display: inline-block;
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_bullit_right:before);
          font-size: 11px;
          position: relative;
          margin-left: 5px;
          vertical-align: middle;
        }
    }

    .manchet {
      .font-regular();
      .font-size(1.5);
      padding: 0;
    }
  }
}

.nested-article__childandgrandchildren {
  padding: 30px 23px;
  background-color: #fff;
  margin-bottom: 10px;

  > .row {
    margin-left: 0;
    margin-right: 0;
    margin-top: 30px;

    &:first-child {
      margin-top: 0;
    }
  }

  h2 {
    .font-size(1.8);
    font-weight: 400;
    margin: 0 0 10px;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin: 0 0 5px;
      padding: 0;
    }
  }
}

.nested-article__childlist {
  padding: 30px 23px 10px;
  background-color: #fff;
  margin-bottom: 10px;
  > .row {
    margin-left: 0;
    margin-right: 0;
  }

  dl {
    dt {
      font-weight: 400;
    }

    dd {
      margin-bottom: 20px;
    }
  }
}

.nested-article {
  padding: 0 38px;

  &.col-sm-12 {
    padding: 30px 38px;
    background-color: #fff;
    margin-bottom: 10px;

    h2 {
      .font-size(1.8);
      font-weight: 400;
      margin: 0 0 10px;
    }
  }
}

.nested-article__image--bottom {
  margin-bottom: 10px;
  
  img {
    margin-top: -10px;
  }
}
