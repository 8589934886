﻿.booking-my-page {

  &-section {
    &__title {
      margin: 20px 0 10px;
      font-family: "SceneAlt", serif;
      font-size: 25px;
      line-height: 40px;
    }
  }




  &__welcome-wrap {
    padding: 37px;
    font-size: 0;
  }

  &__welcome-text {
    display: inline-block;
    vertical-align: middle;
    width: ~"calc(100% - 165px)";

    .font-size(2);

    @media (max-width: @screen-xs) {
      display: block;
      width: 100%;
    }
  }

  &__c2a--logout {
    display: inline-block;
    vertical-align: middle;
    width: 165px;

    .font-size(1.8);

    @media (max-width: @screen-xs) {
      display: block;
      width: auto;
      margin-top: 20px;
    }
  }

  &__bookings {
    &__title {
      margin: 50px 0 10px;
      font-family: "SceneAlt", serif;
      font-size: 30px;
      line-height: 40px;
    }
  }

  .booking-form__text {
    margin: 0;
    .font-regular();
    .font-size(1.6);
    line-height: 1.25;
  }
}

.booking-tab-three {
  &__login {
    display: none;
  }
}
