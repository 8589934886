﻿.filter {
  padding: 38px;

  .category-class {
    font-weight: bold;
  }

  .normal-class {
    padding-left: 20px;
  }

  &__member {

    &__label {
      display: block;
    }

    &__name-input {
      display: block;
    }

    &__search-link {
      min-width: auto;
      font-size: 12px;
      font-size: 1.2rem;
      line-height: 14.399999999999999px;
      line-height: 1.44rem;
      background-color: #d97490;
      color: #fff;
      display: inline-block;
      padding: 10px 20px;
      margin-top: 10px;
      transition: .3s background-color;

      &:hover {
        color: #fff;
        background-color: #ac4360;
        text-decoration: none;
      }
    }
  }
}

.listespot-wrapper.ftMembersAddresses .cellPic {
  padding-right: 10px;
  text-align: center;
  vertical-align: middle;

  @media only screen and (min-width: @screen-md) {
    width: 125px;
  }
}
