﻿.scrollable.has-scroll {
  position: relative;
  overflow: hidden;

  div {
    overflow-x: auto;
    @media only screen and (min-width: @screen-desktop) {
      overflow-x: hidden;
    }
  }

  &:after {
    position: absolute;
    top: 0;
    left: 100%;
    width: 50px;
    height: 100%;
    content: '';
  }

  @media print {
    table {
      td, th {
        border: 1px solid @color-secondary-light;
      }
    }
  }
}

table.table-white {
  width: 100%;
  font-family: arial,sans-serif;

  @media screen and (min-width: @container-small-desktop) {
    width: auto;
  }

  th {
    padding: 15px;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
  }

  td {
    padding: 15px;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
  }

  tbody {
    width: 100%;
  }

  tr {
    margin: 0.5rem 0;
    padding: 0;
  }

  table, td, th {
    border: 1px solid @color-secondary-light;
    background-color: @color-secondary-lighter;
    color: @color-primary-darker;
  }

  table, td {
    border: 1px solid @color-secondary-light;
    background-color: @color-secondary-lightest;
    color: @color-primary-dark;
  }
}

table.table-white-expand {
  width: 100%;
  font-family: arial,sans-serif;

  th {
    padding: 15px;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
  }

  td {
    padding: 15px;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
  }

  tbody {
    width: 100%;
  }

  tr {
    margin: 0.5rem 0;
    padding: 0;
  }

  table, td, th {
    border: 1px solid @color-secondary-light;
    background-color: @color-secondary-lighter;
    color: @color-primary-darker;
  }

  table, td {
    border: 1px solid @color-secondary-light;
    background-color: @color-secondary-lightest;
    color: @color-primary-dark;
  }
}

table.table-grey {
  width: 100%;
  font-family: arial,sans-serif;

  @media screen and (min-width: @container-small-desktop) {
    width: auto;
  }

  th {
    padding: 15px;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
  }

  td {
    padding: 15px;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
  }

  tbody {
    width: 100%;
  }

  tr {
    margin: 0.5rem 0;
    padding: 0;
  }

  table, td, th {
    border: 1px solid @color-secondary-lightest;
    background-color: @color-secondary-light;
    color: @color-primary-darker;
  }

  table, td {
    border: 1px solid @color-secondary-lightest;
    background-color: @color-contrast-400;
    color: @color-primary-darker;
  }
}

table.table-grey-expand {
  width: 100%;
  font-family: arial,sans-serif;

  th {
    padding: 15px;
    font-weight: normal;
    font-size: 16px;
    text-align: left;
  }

  td {
    padding: 15px;
    font-weight: normal;
    font-size: 14px;
    text-align: left;
  }

  tbody {
    width: 100%;
  }

  tr {
    margin: 0.5rem 0;
    padding: 0;
  }

  table, td, th {
    border: 1px solid @color-secondary-lightest;
    background-color: @color-secondary-light;
    color: @color-primary-darker;
  }

  table, td {
    border: 1px solid @color-secondary-lightest;
    background-color: @color-contrast-400;
    color: @color-primary-darker;
  }
}
