/****************************************
Shapes
****************************************/
.triangle-base() {
    content: '';
    width: 0;
    height: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
}
.triangle(@direction, @size, @color) when (@direction = up) {
    .triangle-base();
    border-left: @size solid transparent;
    border-right: @size solid transparent;
    border-bottom: @size solid @color;
}
.triangle(@direction, @size, @color) when (@direction = down) {
    .triangle-base();
    border-left: @size solid transparent;
    border-right: @size solid transparent;
    border-top: @size solid @color;
}
.triangle(@direction, @size, @color) when (@direction = left) {
    .triangle-base();
    border-top: @size solid transparent;
    border-bottom: @size solid transparent;
    border-right: @size solid @color;
}
.triangle(@direction, @size, @color) when (@direction = right) {
    .triangle-base();
    border-top: @size solid transparent;
    border-bottom: @size solid transparent;
    border-left: @size solid @color;
}

.circle(@width: 100px, @height: 100px) {
  width: @width;
  height: @height;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50px;
}