﻿@import "../../Global/Components/committeemembers.less";

.committeemembers {
  &__info {
    &__highlighted-text {
      font-size: 20px;
      margin: 10px 0;
    }

    h2 {
      .font-size(2);
      .font-alt();
    }

    a {
      border-bottom: 1px solid @color-menu-selected-tab;

      &:hover {
        border-bottom: 1px solid @color-brand-200;
        text-decoration: none;
      }
    }

    & > div {
      margin-bottom: 3px;
    }
  }

  &__list {

    &__title {
      .module-headline();
      margin-top: 20px;

      @media screen and (max-width: @screen-sm) {
        margin-left: 22px;
      }
    }
  }
}
