﻿@import "../../Global/Components/booking-form.less";
@import "../../Global/Components/booking-categories.less";

/* Login */
.login-wrapper {
  .login {
    .clearfix();

    .login-link {
      float: right;
      font-family: SceneAlt, serif;
      color: @color-brand-200;
      position: relative;
      margin-right: 38px;
      margin-bottom: 17px;

      @media (max-width: @screen-sm) {
        float: none;
        margin-left: 38px;
        display: inline-block;
      }

      &:hover {
        cursor: pointer;
      }

      &:after {
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        .font-size(@type-size-xs);
        margin-left: 5px;
        display: inline-block;
      }
    }
  }

  .my-page {
    .clearfix();

    .my-page-link {
      float: right;
      font-family: SceneAlt, serif;
      color: @color-brand-200;
      position: relative;
      margin-bottom: 17px;
      border-right: 1px solid @color-secondary-darker;
      padding-right: 15px;
      padding-left: 0;

      @media (max-width: @screen-sm) {
        float: none;
        margin-left: 38px;
        display: block;
        border-right: none;
      }

      &:hover {
        cursor: pointer;
      }

      &:after {
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        .font-size(@type-size-xs);
        margin-left: 5px;
        display: inline-block;
      }
    }

    .logout-link {
      float: right;
      font-family: SceneAlt, serif;
      color: @color-brand-200;
      position: relative;
      margin-bottom: 17px;
      padding-left: 15px;
      margin-right: 38px;

      @media (max-width: @screen-sm) {
        padding-right: 15px;
        padding-left: 0;
        float: none;
        margin-left: 38px;
        display: block;
      }

      &:hover {
        cursor: pointer;
      }

      &:after {
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        .font-size(@type-size-xs);
        margin-left: 5px;
        display: inline-block;
      }
    }
  }
}

.booking-login {
  .booking-form__text {
    display: none;
  }

  .panel-form {
    margin-bottom: 0;
  }
}

/* Booking enterance page */
.booking-entrance {
  margin-bottom: 30px;
  display: table;

  &__groups {
    background: white;
    padding: 30px 38px;
    margin-bottom: 2px;

    @media (min-width: @screen-sm) {
      width: 33%;
      margin-bottom: 15px;
      float: left;
      margin-right: 2px;
      margin-bottom: 0;
    }

    &:last-of-type {
      @media (min-width: @screen-sm) {
        margin-right: 0;
      }
    }
  }

  &__title {
    margin-top: 0;
    height: 50px;
    overflow: hidden;
    color: @color-primary-dark;
    .font-alt();
    .font-size(2);
    font-weight: 400;
    margin-bottom: 30px;
  }

  &__text {
    color: @color-primary-dark;
    height: 78px;
    overflow: hidden;
  }

  &__link {
    display: inline-block;
    margin-bottom: 27px;

    &:hover {
      cursor: pointer;
    }

    &:after {
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);
      .font-size(@type-size-xs);
      margin-left: 5px;
      display: inline-block;
    }
  }

  &__cta {
    color: #fff;
    border: 0;
    width: auto;
    text-align: center;
    display: inline-block;
    vertical-align: middle;
    background-color: @color-brand-300;
    .font-alt();
    .font-size(1.8);
    padding: 10px 50px 10px 50px;
    cursor: pointer;

    &:hover {
      background-color: @color-primary-function-hover;
      color: white;
      text-decoration: none;
    }

    @media (max-width: @screen-md) {
      width: 100%;
    }
  }
}

.booking-categories {
  &__offer {
    margin-bottom: 10px !important;

    @media only screen and (min-width: 768px) {
      height: 171px;
      overflow: hidden;

      &__description__text {
        margin-bottom: 10px !important;
      }
    }
  }

  &__teaser__description__text {
    margin-bottom: 10px !important;
  }
}

/* Booking iframe*/
.booking-system {
  margin-bottom: 30px;

  div.two-col-spot & {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  div.two-col-spot .col-sm-6 & {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &__iframe {
    width: 100%;
    border: 0;
    background: #fff;
    padding: 30px 23px 30px 23px;  
    margin-bottom: -4px;
    div.two-col-spot .col-sm-6 &{
      padding-top: 0;
    }
  }

  &__title {
    padding: 0 38px;
    .font-alt();
    .font-size(2.5);
    line-height: 50px;
    color: @color-primary-darker;
    margin-bottom: 0;

    .two-col-spot & {
      .font-size(2);
      color: @color-primary-dark;
      margin-bottom: 20px !important;
      padding-top: 23px !important;
    }
  }

  &__content-rte {
    padding-top: 30px;
    padding-bottom: 30px;
    background: white;
    color: @color-primary-dark;

    .two-col-spot & {
      padding: 20px 38px;
    }
  }
}


/* Autocomplete function */

.autocomplete-w1 {
  position: absolute;
  top: 1px;
  left: 0;
  width: 100%;
}

.autocomplete {
  position: relative;
  border: 1px solid #999;
  background: #FFF;
  cursor: default;
  text-align: left;
  max-height: 350px;
  overflow: auto;
}

.autocomplete .selected {
  background: #F0F0F0;
}

.autocomplete div {
  padding: 2px 1px;
  white-space: nowrap;
  overflow: hidden;
  font-family: Arial,Helvetica,sans-serif;
  font-size: 100%;
}

.autocomplete strong {
  font-weight: normal;
  color: #b5014d;
}

.booking-ft,
.booking-login,
.booking-my-page {
  .panel-body {
    padding: 15px 37px 37px;
    background: white;
  }
}

.booking-my-page-section {
  margin-bottom: 30px;
}

.booking-forgot-password {
  margin-bottom: 30px;
}

.booking-placeholder {
  display: table;
  width: 100%;

  &.top-margin {
    margin-top: 10px;
  }
}

.threeColumns {
  margin-bottom: 30px;
}

.info-label-extra {
  font-family: SceneAlt, serif;
}

.booking_steps_bottom_btn .button {
  width: 100%;

  @media (min-width: @screen-md) {
    width: auto;
  }
}


#billingInformation {
  margin-top: 25px;
}

.booking-form.billing {
  hr {
    display: none;
  }

  legend {
    font-family: SceneAlt, serif;
  }
}

.booking-ft {
  color: @color-primary-dark;
  .clearfix();

  .heading--sub {
    .font-size(2);

    &.heading--border {
      border-bottom: 2px solid @color-secondary-light;
    }
  }

  .button {
    background: @color-primary-function-bg;

    &:hover {
      background-color: @color-primary-function-hover;
    }
  }
}

.comment-error {
  font-size: 1.4rem !important;
}

.booking_substep_section_review_rest_descr_cnt_right {
  font-weight: normal;
  //Hack but had to
  b {
    font-weight: normal;
  }
}

.booking-form__reset-link {
  a {
    color: @link-color;
    font-family: Arial, sans-serif;

    &:hover {
      color: @link-color-hover;
    }
  }
}

#changeAddressInfo {
  .heading--sub {
    font-family: Arial, sans-serif;
    font-size: 1.6rem;
    color: @link-color;
    margin-bottom: 0;
    padding-left: 0;
  }
}

#bookingInfomation table {
  margin-bottom: 40px;
}

.booking_steps_reviewform {
  padding: 0;

  .web_dialog h2 {
    margin-bottom: 0;
  }

  #numberOfParticipants {
    border: 0;
    background: @color-secondary-lighter;
    padding: 3px;
    text-align: center;
  }

  .update-booking, .cancel-booking, .email-booking {
    color: #fff;
    border: 0;
    width: 120px;
    display: inline-block;
    vertical-align: middle;
    background-color: @color-brand-300;
    font-family: SceneAlt, serif;
    font-weight: 400;
    font-style: normal;
    line-height: 2.16rem;
    font-size: 1.8rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    border-radius: 0;
    min-width: 165px;
    padding: 10px 50px 10px 50px;
    -webkit-appearance: button;
    cursor: pointer;

    &:hover {
      background-color: @color-primary-function-hover;
    }

    @media (max-width: @screen-md) {
      width: 100%;
    }
  }

  .email-booking {
    width: auto;

    @media (max-width: @screen-md) {
      margin: 15px 0;
    }
  }

  .cancel-booking {
    @media (max-width: @screen-md) {
      margin-bottom: 5px;
    }
  }

  .update-booking {
    margin-right: 10px;

    @media (max-width: @screen-md) {
      margin-right: 0;
    }
  }

  #dialogChange, #dialogCancel {
    @media (min-width: @screen-md) and (max-width: @screen-lg) {
      width: 56%;
    }
  }


  .my-contact-info {
    margin-bottom: 50px;
  }

  &.final-review {
    fieldset {
      display: none;
    }
  }

  .booking-number {
    display: none;
  }

  &.no-padding {

    fieldset {
      padding-bottom: 0;
    }
  }

  &.booking_steps--confirmation {
    tr > td:last-child {
      //Remove any bold value, find them and destroy them
      /*font-weight: bold;*/
    }
  }

  .padding-bottom {
    padding-bottom: 10px;
  }

  textarea {
    width: 66.66%;
    padding: 10px;
    background: @color-secondary-lighter;
    border: none;
    .font-size(1.5);
    .placeholder(@color-secondary-dark);

    @media (max-width: @screen-sm) {
      width: 100%;
    }
  }
}

.booking_substep_section {

  &.no-padding {
    padding: 0;
  }

  &.padding-bottom {
    padding-bottom: 20px !important;
  }

  .booking_remarks a {
    color: @link-color;
  }
}

.booking_remarks {
  margin-left: 0;
  margin-right: 0;
}

// Fixes: BUG-36740
.booking-type__details a,
.booking_remarks a {
  &:after {
    font-family: "ft-icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    font-size: 10px;
    font-size: 1rem;
    margin-left: 4px;
    line-height: 1;
    font-smoothing: antialiased;
    content: "\e631";
  }

  &:hover:after {
    display: inline-block;
    text-decoration: none;
  }
}
// End: bugfix BUG-36740

.booking-confirmation-details {
  margin-top: 20px;

  fieldset td {
    padding-bottom: 5px;
  }
}

.booking-confirmation-info-msg {
  margin-bottom: 20px;

  strong {
    font-weight: normal;
  }
}

.booking_steps_filter_date_cnt label,
.booking_steps_filter_periode label {
  font-weight: normal;
  margin-bottom: 10px;
}

.booking-person-error {
  position: relative;
  top: -35px;
}

#errorNoMenuSelected {
  POSITION: relative;
  top: -20px;
}

#personNrError, #errorNoMenuSelected, #dateError, #dateValidateError, #calendarError, #globalcontent_0_pagelayoutbcontent_1_tabthree_confirmBookingError {
  margin-left: 0 !important;
  font-size: 1.4rem;
  font-family: Arial, sans-serif;
  color: @link-color;
}

.booking-form {
  .info-label-extra {
    .font-alt();
  }

  &__error--serverside.has-general-error {
    margin-top: 0;
  }

  .brow {
    display: table;

    @media (max-width: @screen-md) {
      width: 100%;
    }

    .inputBox {
      @media (max-width: @screen-md) {
        width: 100%;
      }
    }
  }

  .manchet-error.has-manchet {
    margin-top: 92px;
  }

  textarea {
    @media (max-width: @screen-md) {
      margin-bottom: 0;
    }
  }
}

#btime_popup {
  //Sorry but necessary
  b {
    font-weight: normal;
  }

  font-size: 1.4rem;
  color: #454545;
}

.tabs-hide {
  display: none;
}

// Fixes: BUG-36741
.booking-form__field,
.booking-form__action {
  &.login {
    padding-bottom: 8px;
  }

  .booking-forgot-password & {
    margin: 0 40px;
  }
}

.booking-form__field__textbox,
.booking-form__field__textarea {
  font-family: arial, sans-serif;
}

.booking-form__error--ean {
  float: left;
}

#loadingCalendar img {
  top: 20px !important;

  @media (max-width: @screen-sm) {
    width: 10% !important;
  }
}

// Fixes BUG-35671
.ean-number__error {
  .brow .booking-form__error & {
    margin-top: 10px;
    margin-left: 2px;

    @media (min-width: @screen-md) {
      margin-top: 0;
    }
  }
}

// Fixes BUG-37947
.datepicker table tr td.day.focused {
  background: @color-secondary-dark;
}

.fieldset .chooseDate input.form-control {
  background-color: @color-contrast-400;
}
