.subsite-wide {
  .link-cards {
    width: 100%;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: 1242px;
    &:extend(.section--margin-bottom);
    margin-top: @section-spacing-small;

    @media (min-width: @screen-md-min) {
      margin-top: @section-spacing;
    }

    &__container {
      padding-top: @spacing-medium;
      padding-bottom: @padding-large;

      @media (min-width: @screen-md-min) {
        padding-top: @section-spacing;
      }
    }

    &__filter {
      &:extend(.subsite-wide-container);
      padding-bottom: 40px;

      [class^="col-"] {
        @media (max-width: @screen-sm-min) {
          margin-bottom: @spacing-small;
        }
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      input[type="number"],
      input[type="password"],
      input[type="date"],
      input[type="search"],
      input[type="submit"],
      select,
      textarea {
        border: 2px solid @sw-color-grey-darker;
        background-color: @sw-color-grey-lighter;
        background-color: var(--sw-color-grey, @sw-color-grey-lighter);

        &:hover {
          background-color: @sw-color-grey-lighter;
          background-color: var(--sw-color-grey, @sw-color-grey-lighter);
        }

        &:focus {
          outline: @focus-style;
          outline-offset: 2px;
        }
      }

      .custom-input-search,
      .style-select {
        @media (min-width: @screen-md-min) {
          max-width: 320px;
        }
      }
    }

    &__overview {
      &:extend(.subsite-wide-container);

      > .row {
        display: flex;
        flex-wrap: wrap;
      }

      [class^="col-"] {
        margin-bottom: 30px;

        @media (max-width: @screen-sm-min - 1) {
          width: 100%;
        }
      }

      .link-card {
        @media (min-width: @screen-md-min) {
          max-width: 320px;
        }
      }
    }

    .link-card {
      min-height: 425px;

      @media (min-width: @screen-md-min) {
        min-height: 390px;
      }
    }

    &__pagination {
      display: flex;
      justify-content: center;
      margin-top: calc(@padding-large - 30px);
    }

    &__show-more {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      .show-more-count {
        font-size: @sw-font-md;
        line-height: @sw-font-md + 4;
        font-weight: @sw-font-semi-bold;
      }

      .show-more-action {
        font-size: @sw-font-lg !important;
        font-weight: @sw-font-bold;
        background-color: transparent !important;
        border: 0 !important;
        padding: 10px 10px 10px 10px !important;
      }

      .show-more-chevron {
        box-sizing: border-box;
        position: relative;
        display: block;
        transform: scale(var(--ggs, 1));
        width: 22px;
        height: 22px;
        border: 2px solid transparent;
        border-radius: 100px;
      }
      .show-more-chevron::after {
        content: "";
        display: block;
        color: @sw-color-red-dark;
        box-sizing: border-box;
        position: absolute;
        width: 12px;
        height: 12px;
        border-bottom: 2px solid;
        border-right: 2px solid;
        transform: rotate(45deg);
        left: 4px;
        top: 2px;
        transition: all 250ms ease-in-out;
      }

      &:hover {
        .show-more-chevron::after {
          top: 8px;
        }
      }
    }
  }

  div.link-cards__show-more:focus {
    outline: 1px dashed;
  }

  input[type="button"],
  button {
    width: 100%;
  }
}
