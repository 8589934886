﻿@import "../../Global/Components/quick-lookup-d.less";

.quick-lookup-d {
  margin-bottom: 30px;

  input[type="text"], input[type="password"], textarea, select {
    background-color: @color-secondary-lighter;
  }

  &__container {
    azimuth: behind;
  }

  &__header {
    azimuth: behind;

    h2 {
      margin-top: 0;
      .font-alt();
      .font-size(2.5);
      color: @color-primary-darker;
      margin: 0 0 10px;
      padding-left: 38px;
      margin-top: 20px;
      display: inline-block;
      width: auto !important;

      .two-col-spot &, .three-col-spot & {
        padding-left: 0;
        color: @color-primary-dark;
      }
    }

    .quick-lookup-d__help {
      padding-right: 34px;
      .font-size(2.0);
      position: relative;
      float: right;
      top: 25px;

      .two-col-spot &, .three-col-spot & {
        top: 5px;
        padding-right: 0;
      }


      .tab-spot-a__wrapper & {
        @media (min-width: @container-tablet) {
          top: 10px;
          right: 33%;
        }
      }

      i {
        display: block;

        &:before {
          content: "\e648";
        }
      }
    }
  }

  &__form {
    width: 100%;

    @media (min-width: @screen-sm) {
      width: 66%;
    }

    .two-col-spot &, .three-col-spot & {
      width: 100%;
    }
  }

  &__form-container {
    padding: 0 38px 25px;
    background-color: #fff;

    .tab-spot-a__wrapper & {
      padding-left: 0;
    }

    .two-col-spot &, .three-col-spot & {
      padding: 0;
    }
  }

  .header {
    position: relative;
  }



  .quick-lookup__subheader {
    margin: 30px 0;

    p {
      color: @color-primary-dark;
    }
  }

  .form-group {
    margin-bottom: 20px;

    label {
      .font-alt();
      .font-size(1.6);
      color: @color-primary-dark;
    }

    select,
    input {
      padding: 10px;
      min-height: 40px;
      .font-regular;
      .font-size(1.4);
      color: @color-secondary-darker;
      .placeholder(@color-secondary-dark);
    }
  }

  .input-group__c2a {
    width: 100%;
  }

  .quick-lookup-d__c2a {
    margin: auto;
  }
}
