﻿@import "../../Global/Components/tingdok.less";

.tingdok {
  .font-size(1.4);

  .tingdok-heading {
    .font-regular();
    .font-size(3);
    margin: 3px 0 12px;
  }

  .tingdok-normal {
    .font-size(1.4);
    margin-bottom: 3px;

    &:first-child {
      margin-top: 21px;
    }

    a:not(.tingdok-backarrow):not(.tingdok-frontarrow) {
      color: @color-brand-200;
      text-decoration: none;
      border-bottom: 1px solid @color-menu-selected-tab;

      &:hover {
        text-decoration: none;
        border-bottom: 1px solid @color-brand-200;
      }
    }
    p {
      padding-left: 0;
    }
  }

  [class*="col"] {
    h1,
    h3,
    p,
    .manchet,
    .date,
    .list-inline,
    .simple-list__title {
      padding-left: 23px;
      padding-right: 23px;
    }
  }

  .tingdok-manchet {
    padding: 0;
  }

  dt {
    font-weight: 400;
  }

  .case-info-configurable-spot {
    .row {
      margin-left: 0;
      margin-right: 0;
    }

    ul li {
      list-style-type: disc;
    }

    .tingdok-normal p {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .panel-group .panel-middle .panel-body,
  .panel-group .panel-middle .panel-body p {
    .font-size(1.4);
  }
  .panel-group.tingdok__vote {
    border-top: 1px solid @color-secondary-light-3;

    > .panel-middle:first-child {
      border-top: 0;
    }
  }
  //Case info accordion PBI: 26222
  .panel.case-accordion {
    border-top: 0;

    .panel-collapse {
      margin-bottom: 0 !important;
    }

    .panel-middle:last-of-type {
      margin-bottom: 0;
    }

    .panel-body {
      padding-bottom: 20px !important;
    }

    .case-info-configurable-spot .panel-middle .panel-collapse .panel-body {
      margin: 0;
    }
  }

  .panel-collapse {
    margin-bottom: 0 !important;
  }
  //END PBI: 26222
  .case-document-wrapper {
    .case-document {
      margin: 0 !important;
    }
  }

  .case-document {
    margin: 30px 38px 0 !important;

    .tingdok-download-link {
      .gallery-image__download__icon {
        width: auto;
        height: auto;
      }

      .icon-ft_download {
        font-size: 16px;
        left: 5px;
        top: 0;
      }
    }
  }

  .case-info-configurable-spot .panel-middle .panel-collapse .panel-body {
    padding: 0;
    margin: 0;

    &.tingdok-space-small,
    &.tingdok-space,
    &.tingdok-space-big {
      margin: 0;
    }
  }

  .case-info-configurable-spot
    .panel-middle
    .panel-heading
    + .panel-collapse
    .panel-body {
    padding: 0;
    margin: 0 38px;
  }

  table.table-striped {
    td {
      &:first-child {
        padding-left: 38px;

        @media screen {
        }
      }

      &:last-child {
        padding-right: 38px;
      }
    }
  }

  .listespot-wrapper {
    margin: auto !important;

    tr.hover {
      background-color: @color-menu-selected-bg !important;

      td {
        background-color: @color-menu-selected-bg;
        cursor: pointer;

        &:first-child {
          background-color: @color-menu-selected-bg;
        }
      }
    }
    tr:nth-child(odd) {
      &.hover {
        background-color: @color-menu-selected-bg !important;

        td {
          background-color: @color-menu-selected-bg !important;
        }
      }
    }

    .first-level-table {
      tbody tr td {
        @media (max-width: @container-tablet) {
          display: table;
          padding-left: 38px;
          width: 100%;
        }

        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }

      .second-level-table {
        @media (max-width: @container-tablet) {
          margin-top: 25px;

          &.tingdok__documentdownload {
            margin-top: 0;
          }
        }

        tbody tr td {
          @media (max-width: @container-tablet) {
            margin-bottom: 18px;
            padding-left: 0 !important;
          }

          ul {
            @media (max-width: @container-tablet) {
              margin: 0;
            }

            li {
              @media (max-width: @container-tablet) {
                margin: 0;
              }
            }
          }
        }
      }
    }

    th {
      color: @color-primary-dark;
    }

    tbody tr {
      td {
        color: @color-primary-dark;
        cursor: auto;

        .tingdok__spokesmenspot-a__container__name {
        }

        .tingdok__spokesmenspot-a__container__email {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__votinglistspot-a .listespot-wrapper {
    td:first-child {
      color: @tingdok-tabel-first-td;
    }
  }
  .voting-table tr td {
    cursor: pointer !important;
  }

  .dl-horizontal {
    margin: 0 23px;
  }
  /*#region Spots */
  &__contactspot {
    .make-row();
    margin: 0 0 10px;
    background-color: @tooltip-bg;
    padding: 30px 0;
    color: @color-primary-dark;

    &__container {
      .make-sm-column(12);
      margin: 0 23px;
      .font-size(1.4);
    }
  }

  &__caseinfospot-a {
    margin: 0 0 10px;

    &__container {
      background-color: #fff;
      color: @color-primary-dark;
      padding: 30px 0;
      .font-size(1.4);

      div {
        padding: 0 38px;

        > div {
          padding: 0;
        }

        &.heading {
          background-color: @color-background-main;
          padding: 30px 38px;
          margin-bottom: 30px;
          .font-size(2.5);
        }

        strong {
          .font-size(1.6);
          font-weight: 400;
          display: inline-block;
          margin-bottom: 5px;
        }
      }

      .tingdok-heading {
        .font-regular();
        .font-size(3);
        margin-bottom: 12px;
      }

      .tingdok-normal {
        .font-size(1.4);
        margin-bottom: 3px;
      }

      a {
        color: @color-brand-200;
        text-decoration: none;
        border-bottom: 1px solid @color-menu-selected-tab;

        &:hover {
          text-decoration: none;
          color: #963651;
          border-bottom: 1px solid #963651;
        }
      }
    }
  }

  &__caseinfotopspot-a {
    .make-row();
    margin: 0 0 30px;

    &__container {
      .make-sm-column(12);
      margin: 0 23px;
      width: auto !important;

      .tingdok-heading {
        .font-regular();
        .font-size(3);
        line-height: 33px;
        margin-bottom: 12px;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }

      .tingdok-normal {
        font-size: 1.4rem;
        margin-bottom: 3px;
      }

      .tingdok & {
        .space {
          margin: 5px 0;
        }

        .normal {
          .font-size(1.4);
        }
      }

      a {
        color: @color-brand-200;
        text-decoration: none;
        border-bottom: 1px solid @color-menu-selected-tab;

        &:hover {
          text-decoration: none;
          color: #963651;
          border-bottom: 1px solid #963651;
        }
      }
    }
  }

  &__timeplanspot-a {
    .make-row();
    margin: 0 0 10px;
    background-color: #fff;
    color: @color-primary-dark;
    padding: 30px 0;

    &__container {
      .make-sm-column(12);
      margin: 0 23px;
      .font-size(1.4);

      div {
        margin-bottom: 3px;
      }
    }
  }

  &__pagereferencespot-a {
    .make-row();
    margin: 0 0 30px;

    &__container {
      .make-sm-column(12);
      margin: 0;
      padding: 0;
    }
  }

  &__spokesmenspot-a {
    .make-row();
    margin: 0 0 10px;

    &__container {
      margin: 0;

      .listespot-wrapper {
        tbody {
          tr {
            td {
              .font-size(1.4);

              &[data-title="Navn"] {
                a:hover {
                  text-decoration: underline;
                }
              }

              a,
              a.tingdok__spokesmenspot-a__container__name {
                color: @color-brand-200 !important;
              }
            }
          }
        }
      }

      &__image {
        max-width: 133px;
        border-radius: 50%;
      }

      &__email {
        color: @link-primary-color;
      }

      .display-cell + .display-cell {
        padding-left: 20px;
      }
    }
  }

  &__vote {
    &__total_results {
      &__table {
        width: 100%;

        &__label {
          width: 210px;
          padding-bottom: 20px;
          .font-alt();
          .font-size(1.6);
          color: @color-primary-darker;
        }

        &__result {
          width: 50px;
          padding-bottom: 20px;
          .font-alt();
          .font-size(1.6);
          font-weight: 400;
          color: @color-primary-darker;
          //opacity: 1;
        }

        &__progressbar {
          padding-bottom: 20px;

          .progress {
            margin-bottom: 0;
            border-radius: 0;
            box-shadow: none;
          }

          .progress-bar {
            box-shadow: none;

            &--for {
              background-color: @color-tertiary-result-for;
            }

            &--against {
              background-color: @color-tertiary-result-against;
            }

            &--neutral {
              background-color: @color-tertiary-result-neutral;
            }

            &--absent {
              background-color: @color-tertiary-result-absent;
            }
          }
        }
      }
    }

    &__vote-info {
      &__title {
        padding: 0 38px;
        .font-size(1.6);
        font-weight: 400;
        margin-bottom: 10px;
      }

      &__text {
        p {
          padding: 0 38px;
          background-color: transparent !important;
          .font-size(1.4);
        }
      }
    }

    h3,
    h4 {
      font-weight: 400;
      .font-size(1.8);
    }
  }

  &__votedescriptionspot-a {
    .make-row();
    margin: 0 0 -30px;
    margin-bottom: 0;
    padding: 20px 0;
    background-color: #fff;
    color: @color-primary-dark;

    &__container {
      .make-sm-column(12);
      padding-left: 38px;

      &__title {
        .font-size(1.4);
        .font-regular();
        font-weight: 400;
        margin-bottom: 3px;
      }

      &__text {
        .font-size(1.4);
        margin-bottom: 3px;
      }
    }
  }

  &__votinglistspot-a {
    .make-row();
    margin: 0 0 10px;

    &__container {
      margin: 0;
    }
  }

  &__casemeetingquestionlistspot-a {
    .make-row();
    margin: 0;

    &__container {
      margin: 0;
    }
  }

  &__finalreportfollowupgovernmentaccountsspot-a {
    .make-row();
    margin: 0 0 30px;

    &__container {
      .make-sm-column(12);
      margin: 0;
    }
  }

  &__auditorremarksspot-a {
    .make-row();
    margin: 0 0 30px;

    &__container {
      .make-sm-column(12);
      margin: 0;
      padding: 0;
    }
  }

  &__caseattachmentspot-a {
    //.make-row(); - causes margin issues
    &__container {
      .make-sm-column(12);
      margin: 0;
      padding: 0;
    }
  }

  &__followupreportspot-a {
    .make-row();
    margin: 0;

    &__total-report {
      background-color: #f7f7f7;
      border-collapse: collapse;
      min-width: 100%;
      min-height: 50px;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 16.8px;
      line-height: 1.68rem;
      color: #454545;

      &__wrapper {
        float: left;
        width: 48%;
        padding-left: 38px;
        position: relative;
        top: 10px;

        &__link {
          position: relative;
          top: -5px;
        }

        &__icon {
          &:before {
            color: #d3d3d3 !important;
            display: inline-block;
            width: 27px;
            height: 27px;
            font-size: 27px;
          }
        }
      }

      &__basket {
        float: right;
        width: 30%;
        border-left: 1px solid #c3bebe;

        li a {
          text-decoration: none;
        }
      }
    }

    &__container {
      .make-sm-column(12);
      padding-left: 0;
      padding-right: 0;
      margin: 0;

      &__table {
        border-collapse: collapse;
        min-width: 100%;
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 16.8px;
        line-height: 1.68rem;
        color: #454545;

        tbody {
          font-family: Asap;
          font-family: Arial, sans-serif;
          font-weight: 400;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;

          tr {
            vertical-align: top;
            text-align: left;

            &:nth-child(odd) {
              background-color: #f7f7f7;
            }

            td {
              color: #454545;
              cursor: auto;

              &:first-child {
                background-color: #f7f7f7;

                a {
                  display: inline-block;
                  min-width: 160px;
                }
              }

              a {
                color: #454545;
              }
            }
          }

          th {
            vertical-align: top;
            text-align: left;
          }
        }
      }

      strong {
        margin-left: 23px;
      }

      .btn-add-all-documents-to-basket {
        margin-right: -30px;
      }
    }
  }

  &__questionandanswerlistspot-a {
    .make-row();
    margin: 0;

    table {
      .highlighted {
        a:hover {
          text-decoration: underline;
        }
      }

      tbody tr td a {
        color: @link-primary-color;
      }
    }

    &__container {
      margin: 0;
    }
    //PBI: 24225
    &--secondary {
      margin: 0 -15px 10px -15px;

      .standard-table {
        .info {
          width: 100%;
          padding-left: 38px;

          @media (min-width: @screen-xsm) {
            width: 28%;
          }
        }

        .content {
          width: 100%;
          padding-left: 38px !important;

          @media (min-width: @screen-xsm) {
            padding-left: 30px !important;
            width: 71%;
          }

          p {
            padding-left: 0;

            @media (min-width: @screen-xsm) {
              padding-left: 10px;
            }
          }
        }
      }

      .tingdok__questionandanswerlistspot-a {
        &__title {
          font-size: 1.6rem;
          font-size: 16px;
          font-family: SceneAlt, serif;
          display: inline-block;
          width: 100%;
          line-height: 22px;
          color: @color-primary-dark;
          background: white;
          padding: 10px;
          padding-left: 38px !important;
          margin: 0;
        }

        &__documentdownload {
          margin-top: 0;

          td {
            margin-bottom: 15px;

            @media (min-width: @screen-xsm) {
              margin-bottom: 0;
            }
          }
        }
      }

      .dl-horizontal {
        margin-left: 0;

        dt,
        dd {
          width: auto;
          margin: 0;
        }

        dt {
          margin-right: 5px;
        }
      }
    }
  }
  .questionanswer-secondary {
    .dl-horizontal-left-aligned {
      dt {
        width: auto;
        padding-right: 5px;
      }

      dd {
        margin-left: 0;
      }
    }
  }
  /*#endregion */
  &__documentdownload {
    tbody {
      tr,
      td {
        padding: 0 !important;

        &:first-child {
          padding: 0 0 0 10px !important;
        }

        vertical-align: middle;
        background-color: transparent !important;

        &:hover {
          background-color: transparent;
        }

        &:nth-child(odd),
        &:nth-child(even) {
          background-color: transparent;
        }
      }
    }

    width: 100%;
  }
  /*#region Tingdok service styles */
  .space {
    .clearfix();
    margin: 15px 0;
  }

  .heading {
    &:extend(h1);
    .font-regular();
    .font-size(3);
    line-height: 40px;
    padding: 0;
  }

  h1 {
    .font-regular();
    .font-size(3);
    line-height: 40px;
  }

  .list-unstyled {
    margin-left: 23px;
  }

  .list-spot {
    margin: 30px -17px 0;
  }

  table.tingdok__documentdownload {
    position: relative;

    tbody {
      tr.tingdok__documentdownload-title {
        a {
          padding-left: 18px;
          padding-right: 38px;
          color: #454545;

          @media (max-width: @screen-xsm) {
            padding-left: 15px;
            padding-bottom: 15px !important;
          }
        }
        color: @link-color;
        td:first-child {
          padding-right: 90px !important;

          @media (max-width: @screen-xsm) {
            width: 100%;
            padding-left: 15px !important;
            padding-right: 15px !important;
          }

          @media (min-width: @screen-sm) {
            padding-left: 36px !important;
          }
        }
        td.tingdok__documentdownload-isnew {
          font-family: SceneAlt, serif;
          position: absolute;
          top: -7px;
          right: 0;
          padding: 0 !important;
          background-image: none !important;

          @media screen and (max-width: 991px) and (min-width: 769px) {
            top: 0;
          }

          .tingdok__documentdownload-isnew-badge-wrapper {
            width: 50px;
            height: 50px;
            top: 0;
            right: 0;
            font-size: 1.2rem;
            border: 0 !important;
            overflow: hidden;
          }
          .tingdok__documentdownload-isnew-badge {
            position: relative;
            top: -7px;
            left: 7px;
            padding: 0 11px 4px 0 !important;
            display: flex;
            align-items: flex-end !important;
            justify-content: center !important;
            transform: rotate(45deg) !important;
            width: 80px;
            height: 40px;
            background-color: @color-primary-function-bg!important;
            color: white !important;
          }
        }
      }
    }
  }

  &__documentdownload {
    tbody {
      tr {
        border: 16px solid transparent;
        border-width: 16px 0;

        td {
          vertical-align: top;
          background-size: 1px 100% !important;
          &.tingdok__documentdownload-file-name {
            padding-left: 18px !important;

            @media (min-width: @screen-xsm) {
              padding-left: 0 !important;
            }
          }
          &:first-child {
            @media (min-width: @screen-xsm) {
              width: 45px;
            }

            i {
              color: @color-tertiary-result-absent !important;
              display: inline-block;
              width: 27px;
              height: 27px;
              font-size: 27px;
            }
          }

          &.no-border {
            background-image: none;
          }

          a {
            color: @color-brand-200;
            font-size: 1.4rem !important;
            border-bottom: 0 !important;
            &:hover {
              text-decoration: underline !important;
            }

            &.btn-icon-button {
              color: @color-primary-function !important;
              min-width: inherit;
            }
          }

          br {
            height: 30px;
            display: block;
            line-height: 25px;
          }

          .btn-icon-button-list {
            margin-top: 0;

            .btn-icon-button:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
  /*#endregion */
  &__breadcrumb-b {
    .make-row();
    margin: 0 0 30px;

    &__container {
      //.make-sm-column(12);
      margin: 0 38px 0 38px;

      .tingdok__breadcrumb-b__item {
        &:last-child {
          &:after {
            display: none;
          }
        }
      }

      span {
        .font-size(1.3);
        display: inline-block;

        &:after {
          display: inline-block;
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_bullit_right:before);
          font-size: 7px;
          float: right;
          position: relative;
          top: 4px;
          margin-left: 5px;
        }
      }

      a {
        .font-size(1.3);
        display: inline-block;

        &:after {
          display: inline-block;
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_bullit_right:before);
          font-size: 7px;
          float: right;
          position: relative;
          top: 4px;
          margin-left: 5px;
          color: @color-primary-darker;
        }
      }
    }
  }

  .TingDokInformationSpotA {
    background-color: #fff;
    color: @color-primary-dark;
    padding: 20px 0 10px;
    margin: 0 0 10px 0;

    .manchet {
      margin-bottom: 5px;
    }
  }

  .TingDokDateSpotA {
    background-color: #fff;
    color: @color-primary-dark;
    padding: 0 0 20px;
    margin: -10px 0 10px 0;

    p {
      margin: 0;
    }

    dt {
      width: auto;
      display: inline-block;
      clear: left;
      padding-right: 0;
    }

    dd {
      clear: right;
      display: inline-block;
    }

    @media (max-width: @screen-sm-min) {
      dt,
      dd {
        float: left;
      }
    }
  }

  .TingDokDocumentHeaderSpotA {
    &__heading {
      .font-alt();
      .font-size(2.5);
      padding: 0 23px;
      margin: 0 0 15px;
    }
  }

  .TingDokBrowserSpotA {
    label {
      .font-regular();
      position: relative;
      top: 2px;
    }
  }

  .TingDokBrowserSpotC {
    padding: 0;
    margin: 10px 0;

    label {
      .font-regular();
      position: relative;
      top: 2px;
    }

    a {
      position: relative;
      top: 3px;
    }

    .back-btn {
      @media screen and (max-width: @screen-xs) {
        margin-top: 11px;
        display: inline-block;
      }

      &:before {
        content: "\e631";
        font-family: "ft-icons";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        -ms-transform: rotate(180deg); /* IE 9 */
        -webkit-transform: rotate(180deg); /* Chrome, Safari, Opera */
        transform: rotate(180deg);
        display: inline-block;
        position: absolute;
        top: 2px;
        left: -10px;
        .font-size(1);
      }

      @media (min-width: @screen-xsm) {
        margin-right: 1rem;
      }
    }

    .prev,
    .next,
    .previous {
      a {
        top: 1px;
      }
    }
  }

  .topicspot-d {
    background-color: #fff;
    color: @color-primary-dark;
    margin: -10px 0 0;

    .filter-title {
      font-family: Arial, sans-serif;
      font-size: 14px;
      font-size: 1.4rem;
      margin-left: 0;
      font-weight: 400;
    }
  }

  .dayplanmetadata {
    .clearfix();

    .pdflinkcontainer {
      float: right;
    }
  }

  .tingdok-filter-spot-a {
    background-color: @color-contrast-400;
    color: @color-primary-dark;
    margin: 0 0 30px;
    padding: 10px 38px 30px;

    label {
      font-weight: 400;
      display: block;
      color: @color-text;
      .font-alt();
      .font-size(1.6);
      margin-bottom: 5px;
    }

    select {
      .font-size(1.4);
      padding: 0 15px;
      font-weight: 400;
      background-color: #fff;
      border: 0;
      @media only screen and (min-width: @screen-sm-max) {
        width: 50%;
      }
    }
  }

  .listespot-wrapper--in-tabs {
    table {
      tbody {
        tr {
          &::nth-child(odd) {
            background-color: #fff;
          }

          td {
            padding: 0 0 30px;
            background-color: #fff;

            &:first-child {
              background-color: #fff;
            }
          }
        }
      }
    }
  }

  .tingdok__committee-agenda__list__item__wrapper {
    margin-top: 10px;
    margin-bottom: 30px;
  }

  li.tingdok__committee-agenda__list__item {
    p {
      display: inline-table;
    }

    div.tingdok__committee-agenda__list__item {
      p {
        display: block;
      }

      .tingdok__agenda-title {
        display: inline;
        // HACK: To not change the DOM and use display: inline for <p>, I had to do margin using :after
        &:after {
          content: "";
          display: block;
          height: 10px;
        }
      }
    }
  }

  &__committee-agenda {
    &__list {
      padding: 0 0 0 2px;
      list-style-position: inside;
      padding: 0 0 0 2px;

      .tingdok__documentdownload__title {
        color: @color-primary-dark;
        font-family: SceneAlt, serif;
      }

      .tingdok__documentdownload {
        background-color: @color-table-alternate-row;
        margin-bottom: 10px;
        padding: 30px 0;
        word-break: break-word;

        tbody {
          tr:first-child {
            a {
              position: relative;
              padding-left: 0;
              font-size: 1.4rem !important;
              border-bottom: 0 !important;

              @media (max-width: 991px) {
                padding-bottom: inherit !important;
              }

              &:before {
                position: absolute;
                display: inline-block;
                &:extend(.less-icon-extend);
                &:extend(.icon-ft_bullit_right:before);
                font-size: 11px;
                margin: 4px 0 0 -15px;
              }

              &:hover {
                color: @color-primary-link-hover;
              }
            }

            td {
              @media (min-width: @screen-sm) {
                padding: 0 10px 20px 30px !important;
              }
            }
          }

          td {
            padding: 0 15px !important;

            @media (min-width: @screen-xsm) {
              padding: 0 30px !important;
            }

            .icon-ft_document {
              margin-bottom: 10px;

              @media (min-width: @screen-sm) {
                margin-bottom: inherit;
              }
            }

            &:first-child {
              padding: 0 10px 0px 18px !important;

              @media (min-width: @screen-xsm) {
                padding: 0 10px 15px 38px !important;
              }
            }

            &:last-child {
              @media (min-width: @screen-sm) {
                padding: 0 15px 18px 0 !important;
              }
            }
          }
        }
      }

      &__list {
        margin-bottom: 20px;
      }
    }
  }

  .tingdok-space-small {
    min-height: 10px;
    margin: 0;
    padding: 0;
  }

  .tingdok-space {
    min-height: 20px;
    margin: 0;
    padding: 0;
  }

  .tingdok-space-big {
    min-height: 30px;
    margin: 0;
    padding: 0;
  }
}

//Breadcrumb outside of tingdok
.breadcrumb {
  padding: 0 0 0 23px;

  li:before {
    .fourColumns & {
      padding: 0 5px !important;
    }
  }

  li a {
    border-bottom: 0;

    &:hover {
      text-decoration: underline;
      border-bottom: 0;
    }
  }

  .fourColumns & {
    padding-left: 38px;
  }
}

.tingdok__votinglistspot-a__container {
  .listespot-wrapper.has-data-item-url {
    tr[data-item-url] {
      td[data-title="Titel"] {
        color: @color-primary-link !important;
        &:before {
          display: inline-block;
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_bullit_right:before);
          font-size: 11px;
          position: relative;
          margin-right: 5px;
        }
      }
    }
  }
}
.tingdok-document-list-spot-a {
  .listespot-wrapper__data-item {
    td[data-title="Nr."] {
      a {
        font-size: 1.6rem;
        color: @color-primary-link !important;
        &:before {
          display: inline-block;
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_bullit_right:before);
          font-size: 11px;
          position: relative;
          margin-right: 5px;
        }
      }
    }
  }
}
