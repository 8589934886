﻿.informations-spot-e {
  &--compact {
    margin-bottom: 70px;
  }

  .pageedit & {
    max-height: none !important;
    overflow: visible !important;
    visibility: visible !important;
  }

  position: relative;

  &:after {
    z-index: 1;
    content: '';
    display: block;
    background: @color-primary-function-bg;
    height: 5px;
    width: 90%;
    position: absolute;
    bottom: 0;
    left: 50%;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  &__header {
    .make-row();
    margin: 0 38px 10px;

    &__title {
      .make-sm-column(8);
      .font-alt();
      .font-size(2.5);
      padding-left: 0;
    }

    &__link {
      .make-sm-column(4);
      padding-left: 0;

      @media only screen and (min-width: @screen-sm) {
        text-align: right;
        padding-left: 15px;
      }

      padding-right: 0;

      a {
        color: @color-brand-200;
        display: block;
        position: relative;

        &:after {
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_bullit_right:before);
          .font-size(@type-size-xs);
          margin-left: 5px;
          display: inline-block;
        }
      }
    }
  }

  &__images {
    background-color: #fff;
    .clearfix();

    &__image-group {

      &__bottom-container {
        .make-row();
        margin: 0;
        background-color: #fff;
      }

      &__info {
        padding: 20px 0 30px;
        .make-xs-column(12);
        .make-sm-column(9);
      }

      &__image {
        position: relative;

        img {
          width: 100%;
          height: auto;
        }
      }

      &__blob {
        background-color: @color-primary-function-bg;
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: absolute;
        right: 35px;
        bottom: -20px;

        .site-ft & {
          z-index: 9;
        }

        @media only screen and (min-width: @screen-sm) {
          bottom: -50px;
          width: 112px;
          height: 112px;
        }

        span {
          .font-headline();
          font-style: italic;
          font-weight: 400;
          .font-size(1.9);

          @media only screen and (min-width: @screen-sm) {
            .font-size(2.2);
          }

          color: #fff;
          display: block;
          width: 100%;
          text-align: center;
          position: absolute;
          top: 50%;
          z-index: 1;
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);

          .site-ft & {
            top: 53%;
          }
        }
      }

      &__popup {
        cursor: pointer;
        position: absolute;
        top: 15px;
        right: 15px;

        @media only screen and (min-width: @screen-sm) {
          top: 28px;
          right: 38px;
        }

        color: #fff;
        display: none;
        opacity: 0.3;

        &:hover {
          opacity: 1;
        }

        &.show {
          display: block;
        }

        &:before {
          display: inline-block;
          font-size: 30px;
          &:extend(.less-icon-extender:before);
          &:extend(.icon-ft_read-more:before);
        }

        span {
          display: block;
          width: 0;
          height: 0;
          text-indent: -1000px;
          overflow: hidden;
        }
      }

      &__headline {
        .font-headline();
        .font-size(3);
        margin: 0 23px;

        .site-ft & {
          position: relative;
          margin-top: 5px;
        }
      }

      &__text {
        margin: 0 23px;
        margin-top: 10px;
      }

      &__link {
        margin: 0 23px;
        margin-top: 15px;

        a {
          color: @color-brand-200;

          &:after {
            display: inline-block;
            .font-size(1);
            margin-left: 5px;
            &:extend(.less-icon-extender:before);
            &:extend(.icon-pil-spinkel-hoejre:before);

            .site-ft & {
              &:extend(.icon-ft_bullit_right:before);
            }
          }
        }
      }
    }
  }

  &__swiper-pagination {
    display: none;
    
    @media only screen and (max-width: @screen-xs) {
      display: block;
    }

    .swiper-pagination-bullet {
      background: #b5b6b6;
      width: 9px;
      height: 9px;
      border: 1px solid @color-secondary-darker;
      opacity: 1 !important;

      &.swiper-pagination-bullet-active {
        background: @color-secondary-darker;
      }
    }

    &.swiper-pagination-bullets.swiper-pagination-horizontal {
      bottom: -25px;
    }
  }

  &__navigation {
    position: absolute;
    background-color: transparent;

    @media only screen and (max-width: @screen-xs) {
      display: none !important;
    }

    @media only screen and (min-width: @screen-sm) {
      display: table-row;
    }

    .has-bottom & {
      bottom: 30px;
      right: 38px;
    }

    .no-bottom & {
      @media only screen and (min-width: @screen-xs) {
        bottom: -45px;
        right: 0;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-left: 30px;
        padding-right: 30px;
      }
    }

    &__prev {
      display: table-cell;
      vertical-align: middle;

      a {
        color: @color-secondary-dark;

        &:hover {
          color: @color-primary-function;
        }

        &:before {
          display: inline-block;
          font-size: 30px;
          &:extend(.less-icon-extender:before);
          &:extend(.icon-ft_leftcircle:before);
        }

        span {
          display: block;
          width: 0;
          height: 0;
          text-indent: -1000px;
          overflow: hidden;
        }
      }
    }

    &__next {
      display: table-cell;
      vertical-align: middle;

      a {
        color: @color-secondary-dark;

        &:hover {
          color: @color-primary-function;
        }

        &:before {
          display: inline-block;
          font-size: 30px;
          &:extend(.less-icon-extender:before);
          &:extend(.icon-ft_rightcircle:before);
        }

        span {
          display: block;
          width: 0;
          height: 0;
          text-indent: -1000px;
          overflow: hidden;
        }
      }
    }

    &__steps {
      display: table-cell;
      vertical-align: middle;
      border: 10px solid transparent;
      border-width: 0 10px;
      color: @color-secondary-dark;
    }
  }

  .bx-viewport {
    left: 0;
    /*&:after {
      content: '';
      display: block;
      background: @color-primary-function-bg;
      height: 5px;
      margin: 0 38px;
      position: absolute;
      top: -10px;
    }*/
  }

  .bx-wrapper {
    padding: 0;

    .bx-controls {
      @media only screen and (max-width: @screen-xs) {
        display: block !important;
      }

      @media only screen and (min-width: @screen-sm) {
        display: none !important;
      }
    }
  }
  /*lightbox*/
}

.lightbox-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1004; /*This is appended before the actual lightbox is faded in so that elements with
                    high z-index values (e.g. navbar) don't cover lightbox during fadein*/
  .lightbox-photo:hover {
    cursor: pointer;
  }

  .expanded {
    position: fixed;
    //height: 95%;
    top: 50%;
    left: 50%;
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1002;
    max-width: 90%;
  }

  .caption {
    position: fixed;
    background: black;
    color: white;
    padding: 8px;
    border-radius: 5px;
    font-size: 14px;
    bottom: 3%;
    left: 50%;
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: 1003;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: black;
    opacity: 0.85;
    z-index: 1001;
  }

  .thumb {
    width: 150px;
    border: 1px solid black;
    border-radius: 2px;
  }
}
