﻿.subfrontpage-a {
  text-align: center;

  h1 {
    display: inline-block;

    &:after {
      content: '';
      display: block;
      background: @color-primary-function-bg;
      height: 5px;
      width: 60%;
      margin: 30px auto 20px;
    }
  }

  .text-spot-a-wrapper {
    .read-more {
      .font-alt();
      .font-size(2);
    }
  }
}
