﻿.info-graph {
  background: #fff;
  display: block;
  box-sizing: border-box;
  padding: 23px 38px;
  margin-bottom: 30px;

  .two-col-spot &, .three-col-spot & {
    margin-bottom: 2px;
  }

  &__title {
    .font-alt();
    .font-size(2.5);
    color: @color-primary-dark;
    margin-bottom: 20px;

    .two-col-spot &, .three-col-spot & {
      .font-size(2);
    }
  }

  &__text {
    padding: 0 !important;
    margin: 20px 0 0 0 !important;
    color: @color-primary-dark;
    div.row.three-col-spot .col-sm-4 &{
      padding: 0!important;
    }
  }  
}
