﻿@import "../../Global/Components/direktivsoegeformular-a.less";

.filter-left-menu .panel-group {
  /*margin-top: 15px;
  margin: 15px -15px;*/
  margin: 0 10px;
  padding: 0 15px;
  background-color: transparent;

  .panel {
    &.reset-button {
      text-align: center;

      .pull-right {
        float: none !important;
      }

      .input-group {
        display: block;

        a {
          background-color: transparent;
          color: @link-color;
          //padding: 0;
          .font-size(1.8);

          &:hover {
            color: @link-color-hover;
            text-decoration: underline;
            //border-bottom: 1px solid @link-color-hover;
          }
        }
      }
    }
  }
}

.active-filters li .active-filter, .case-selections li .active-filter, .active-filters li .case-selection, .case-selections li .case-selection {
  background-color: transparent;
}

.filter-left-menu .panel-group .panel-collapse {
  background-color: transparent;
  height: auto !important;
  border-color: transparent;

  .form-group {
    select {
      width: 95%;

      @media only screen and (min-width: @screen-sm) {
        width: 100%;
      }
    }
  }

  &.collapse {
    display: block !important;
  }
}

.panel-left-side .panel-heading + .panel-collapse .panel-body {
  border: 0;
  padding-top: 0;
}

.active-filters, .case-selections {
  margin: 0;
  padding: 0 20px 0 38px;
  list-style: none;
}

.filter-left-menu .panel-group .panel-collapse .form-group {
  margin: 0 10px 15px;

  &.period_filter_daterange_section {
    margin: 0 0 15px;
  }

  .form-control {
    border-color: @color-contrast-200;
    color: @color-secondary-darker;

    &.datepicker {
      .font-size(1.4);
    }
  }
}

.filter-left-menu .panel-left-side > .panel-heading {
  background-color: transparent;
}

.filter-left-menu .panel-group .panel-collapse .form-group-date {
  margin: 0 0 15px 10px;
}

.filter-left-menu .panel-left-side .panel-heading .panel-title {
  margin: 10px 0 0;
}

.filter-left-menu .panel-left-side .panel-heading .panel-title a,
.filter-left-menu .panel-left-side .panel-heading label {
  .font-size(1.5);
  .font-alt();
  font-weight: 400;

  &:before {
    margin-right: 5px;
    min-width: 16px;
    display: inline-block;
  }

  &:after {
    content: "";
    display: none;
  }

  &:hover {
    color: @color-primary-link;

    &:before {
      color: @color-primary-link;
    }
  }
}
.filter-left-menu__sub-link:focus{
  outline: -webkit-focus-ring-color auto 1px;
}

.ankiro-facets .panel-left-side .panel-heading .panel-title.selected {
  color: @color-primary-link;
}

.ankiro-facets .panel-left-side .list-facets li.selected {
  a {
    color: @color-primary-link;
  }
}

.filter-left-menu .list-facets {
  margin: 0 0 0 50px;

  &.search-check-facet {
    .list-facets__items {
      &:before {
        display: none;
      }

      &.is-checked {
        label {
          color: @color-primary-function !important;
        }
      }
    }

    .list-facets__checkbox {
      position: relative;
      top: 2px;

      label:hover {
        color: @color-primary-link;
        cursor: pointer !important;
      }
    }
  }

  li {
    margin: 8px 0;

    &:first-child {
      margin-top: 0;
    }

    &:before {
      &:extend(.less-icon-extender:before);
      &:extend(.icon-ft_bullit_right:before);
      font-size: 10px;
      color: @text-color;
    }
  }

  a {
    color: @color-contrast-150;



    &:hover {
      color: @color-primary-link;
    }
  }
}

.filter-left-menu .link-filters-navigation i {
  &:before {
    &:extend(.icon-ft_bullit_left:before);
  }
}


.filter-left-menu {
  &.calendar-search {
    .panel-left-side .panel-heading .panel-title a,
    .panel-left-side .panel-heading label.panel-title {
      &:hover {
        cursor: text;
        color: @color-primary-darker;
      }
    }

    .form-group-search {
      position: relative;

      @media (min-width: @screen-sm) and (max-width: @screen-md) {
        margin: 0 !important;

        .search-field {
          width: 100%;
        }
      }

      .input-group {
        position: absolute;
        top: 3px;
        right: 5px;
        width: auto !important;        

        .icon-ft_search {
          color: @color-primary-link;

          &:hover {
            color: @color-primary-function-hover;
          }
        }

        a.calendar-btn {
          border: 0;
          background-color: transparent;
          padding: 5px;
          display: inline-block;
          width: auto;
          min-width: inherit;
        }
      }
    }
  }

  .panel-heading {
    .panel-title {
      a {
        color: @color-contrast-150;
        font-size: 1.6rem;
      }
    }
  }
}

.content-area {
  .filter-left-menu {
    background: @color-contrast-400;
    margin-bottom: 40px;
    //Search field and button will have a new Design and styling in the fututre. New style needed in time
    &.calendar-search {
      margin-top: 30px;
      margin-bottom: 10px;
      position: relative;
      padding: 20px 0;

      @media (min-width: @screen-sm) {
        padding: 0;
      }
      //Needs to be replaced by the new search fields design
      .input-group.pull-right {
        width: 100%;

        @media (min-width: @screen-sm) {
          width: auto;
          float: right !important;
          left: inherit;
          right: 7px;
        }

        .btn-right {
          width: 100%;
        }
      }

      .panel-heading {
        .panel-title {
          margin-left: 0;

          a {
            float: none;
            font-weight: 400;
            font-size: 1.6rem;
            display: block;
            position: relative;
            width: 100%;
            min-height: 1px;
            padding-left: 0;
            padding-right: 15px;
            padding: 0;
          }
        }
      }

      .form-group {
        display: table;
        width: 96%;
        margin-bottom: 0;

        input.search-field {
          width: 100% !important;
        }


        &.period_filter_daterange_section {
          width: 100%;
          margin-bottom: 0px;
          margin-top: 12px;

          .icon-kalender {
            margin-bottom: 0;
          }
        }

        .form-group-date {
          display: table;
          width: 100%;
          margin-bottom: 5px;

          @media (min-width: @screen-xsm) {
            width: 46%;

            &.primary {
              float: left;
            }

            &.secondary {
              float: right;
            }
          }

          .datepicker {
            width: 87%;

            @media (min-width: @screen-sm) {
              width: 79%;
            }

            @media (min-width: @container-small-desktop) {
              width: 85%;
            }
          }
        }
        //Avoiding !important
        select.form-control {
          width: 100%;
          color: #696969;
        }

        select, input {
          color: #696969;
          background: @color-textinput;
          border: none;
        }
      }

      .panel-group {
        margin: 0;
        padding: 0 15px 20px 27px;

        @media (max-width: @screen-sm) {
          padding: 0 28px;
        }

        .panel-left-side {
          width: 100%;
          display: inline-table;
          overflow: visible;
          @media (min-width: @screen-sm) {
            width: 49%;
          }

          .panel-body {
            padding-bottom: 0;

            @media (min-width: @screen-sm) {
              padding-bottom: 10px;
            }
          }
        }

        .panel-collapse {
          border: 0;
        }

        .panel.panel-left-side.reset-button {
          width: auto;
          position: absolute;
          right: 5%;
          top: -30px;

          @media (max-width: @screen-xsm) {
            right: 38px;

            .input-group {
              left: auto;
            }
          }

          .input-group.pull-right {
            right: 0;
            padding-top: inherit;
          }

          @media (min-width: @screen-sm) {
            right: 4%;
          }

          .btn-right {
            background: transparent;
            font-weight: 400;
            font-style: normal;
            color: @color-menu-hover;
            font-size: 1.8rem;
            cursor: pointer;
            display: inline;
            outline: none;
            border: 0;
            text-decoration: none;
            padding: 0;
            margin-right: 0;

            &:hover {
              color: @color-primary-link-hover;
            }
          }
        }
      }
    }

    .panel-group {
      margin: 0;
    }

    .panel-heading {
      .panel-title {
        margin-left: 0;

        a {
          float: none;
          font-weight: 400;
          display: block;
          position: relative;
          width: 100%;
          min-height: 1px;
          padding-left: 0;
          padding-right: 15px;
          padding: 0;
        }
      }
    }

    .form-group {
      select, input {
        background: #f0f2f2;
        border: none;
      }
    }

    .panel-group {
      .panel-collapse {
        border: 0;
      }

      .panel.panel-left-side.reset-button {
        .pull-right {
          float: right !important;
        }

        .btn-right {
          background-color: @color-primary-function-bg;
          border-color: #d46080;
          font-weight: 400;
          font-style: normal;
          color: #ffffff;
          cursor: pointer;
          display: inline-block;
          outline: none;
          border: 0;
          text-decoration: none;
          padding: 10px 16px 10px 16px;
          margin-right: 0;
        }
      }
    }
  }

  .panel-group {
    .two-col-spot &, .three-col-spot & {
      padding: 0 !important;
    }
  }
}
