﻿@import "../styles.less";

[class*="col"] {
  &.not-maintained {
    margin-bottom: 1em;

    div {
      .font-bold();
      .font-size(@type-size-xl);
      padding: 10px 0;
    }
  }
}
