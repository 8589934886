﻿.committee {
  &__selected-list {
    margin-left: 38px;

    dt {
      font-weight: 400;
      margin-top: 15px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
