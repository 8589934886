@import "./components/MemberDetailedVoteResult/MemberDetailedVoteResult.styles.less";
@red: #a6192e;
@red_light: #bb4752;
@red_dark: #901628;
@gray_light: #eee;
@gray_lighter: #f7f7f7;
@text-color-light: #696969;
@text-color-dark: #252525;
@background-color: #e8eaea;
@white: #fff;
@default-spacing: 20px;
@border-radius: 5px;
@border-radius-circle: 50%;

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;

}

.chamber-overview-page {
  .burger-icon .icon-menu {
    position: unset;
  }

  .top-searchbar {
    display: block;
  }

  .chamberOverview-full {
    &__identity {
      .content-action-wrapper {
        margin-left: 0 !important;
        margin-right: 0 !important;
        .content-action-links {
          @media only screen and (min-width: 900px) {
            margin-top: 30px;
          }
        }
      }
      .breadcrumb-wrapper {
        margin-right: auto !important;
      }
    }
  }

  .chamberOverview-logo__wrapper {
    margin: 30px 0;

    @media only screen and (max-width: 900px) {
      display: none;
    }

    @media only screen and (min-width: 1024px) {
      padding-left: 30px;
    }
    @media only screen and (min-width: 1300px) {
      padding-left: 40px;
    }
  }

  .breadcrumb-wrapper {
    .breadcrumb {
      padding-left: 0;
    }
  }
}

.container-overview,
.data-visualization {
  position: relative;
  display: flex;
  flex-direction: column;
}

.data-visualization,
.chamber-overview__voting-container {
  .member.mobile {
    @media only screen and (max-width: 900px) {
      margin-left: -@default-spacing + 5 !important;
      margin-right: -@default-spacing + 5 !important;
    }
  }
}

/* width */
.chosen-results::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.chosen-results::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px @gray_lighter;
  border-radius: 3px;
  background-color: @gray_lighter;
}

/* Handle */
.chosen-results::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 8px;
}

body {
  background-color: @background-color;
  @media only screen and (max-width: 900px) {
    overflow: visible; //solve overflow: scroll; on mobile
  }
}
#content {
  &.noScroll {
    overflow: hidden;
    height: 100%;
  }
}
.chamber-overview__voting-container {
  background: #eee;

  #chamber-overview {
    &.no-data {
      display: none;
    }
  }
}

#chamber-overview {
  position: relative;
  margin: 0 0 @default-spacing*2.5;

  @media only screen and (min-width: 901px) {
    min-height: 400px;
  }

  @media only screen and (max-width: 900px) {
    margin: 0;
  }

  .contentProps {
    animation: transitionMe 0.5s;
    @keyframes transitionMe {
      from {
        margin-top: 200px;
      }
      to {
        margin-top: 0;
      }
    }
  }

  .chamber-overview {
    &__filters-btn {
      background: @red_light;
      border: none;
      border-radius: @border-radius-circle;
      color: @white;
      font-size: 20px;
      width: 45px;
      height: 45px;
      text-align: center;
      > i {
        display: block;
        margin-top: 12px;
      }

      &:hover {
        text-decoration: none;
      }

      &--container {
        display: none;
        flex-direction: column;
        align-items: center;
        padding: @default-spacing 0 0;

        > p {
          color: @red;
          margin-top: @default-spacing / 4;
          font-size: 15px;
          font-family: SceneAlt, serif;
        }

        @media only screen and (max-width: 900px) {
          display: flex;
        }
      }
    }

    &__filters {
      font-family: SceneAlt, Serif;
    }

    .date-picker-wrapper {
      position: relative;
      background-color: @gray_light;

      &:before {
        content: "\e60c";
        font-family: "ft-icons";
        position: absolute;
        right: 12px;
        top: 6px;
        color: @red;
        font-size: 14px;
      }

      &.close:before {
        content: "\e60d";
      }

      .arrow-icon {
        display: inline-block;
        position: absolute;
        color: @red_light;
        transform: rotate(-90deg);
        right: 15px;
        font-size: 14px;
        top: 6px;
        &.close {
          transform: rotate(90deg);
        }
      }
    }

    .mobile-view {
      display: block;
      .chamber-overview__filters-btn {
        cursor: pointer;
      }
      .chamber-overview__filters--close-btn {
        display: none;

        &:hover {
          text-decoration: none;
        }

        @media only screen and (max-width: 900px) {
          display: block;
          align-self: flex-end;
          transform: rotate(45deg);
          font-size: 25px;
          background: @gray_lighter;
          border-radius: @border-radius-circle;
          padding: 12px;
          border: none;
          float: right;
          margin-right: @default-spacing*.75;
        }
      }

      .page-reference__label {
        position: relative;
        @media only screen and (max-width: 900px) {
          color: @white;
        }
      }

      .chamber-overview__filters--filter {
        display: none;

        &:hover {
          text-decoration: none;
        }

        @media only screen and (max-width: 900px) {
          display: block;
          border-radius: @border-radius;
          padding: @default-spacing / 2 @default-spacing*2.5 @default-spacing /
            2 @default-spacing*2.5;
          font-family: SceneAlt, serif;
          font-weight: 400;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: @white;
          background-color: inherit;
          text-align: center;
          border: 1px solid @white;
          margin: @default-spacing*2 auto 0;
          font-size: 18px;
        }

        &__container {
          display: flex;
        }
      }

      @media only screen and (max-width: 900px) {
        display: none;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: @red_light;
        z-index: 99;
        padding: @default-spacing*1.5;
        color: @red;
        overflow: scroll;
        height: 100%;
        padding-bottom: @default-spacing*3;
      }

      .faceted-filter {
        margin-top: @default-spacing*1.5;

        &__item {
          color: @white;
          border: 1px solid @white;
          padding: @default-spacing / 5 @default-spacing*.75;
          border-radius: @border-radius;
          background: inherit;
          font-family: SceneAlt, serif;
          font-size: 15px;
          &:hover {
            text-decoration: none;
          }

          .faceted-filter__item--remove {
            color: @white;
          }
        }
      }

      .faceted-filters-overlay {
        .faceted-filter {
          padding: 0;
        }
      }

      .mobile-hidden {
        display: block;
        @media only screen and (max-width: 900px) {
          display: none;
        }
      }
    }

    .mobile-view-date-picker {
      display: none;
      @media only screen and (max-width: 900px) {
        display: flex;
        justify-content: center;
        margin-bottom: 25px;
      }
    }
  }

  .chosen-container-single .chosen-single div {
    right: 10px;
  }

  .chosen-drop {
    background-color: @gray_lighter;
    border-color: @gray_lighter;
    border: 1px;
    font-size: 14px;

    .search-input {
      // color: @text-color-dark; 
      font-size: 14px;
      padding-left: @default-spacing - 5;

      @media only screen and (max-width: 900px) {
        border: 1px solid #ccc;
      }
    }

    .search-input::placeholder {
      color: #696969;
    }

    .chosen-results {
      li {
        padding: @default-spacing / 2 @default-spacing - 5;
        border-bottom: 1px solid #ddd;
      }

      .highlighted {
        background-color: @gray_light;
        color: #696969;
      }
    }

    @media only screen and (max-width: 900px) {
      background-color: @gray_lighter;
    }
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */

    .form-control {
      @media only screen and (max-width: 900px) {
        font-size: 16px; //solve zoom on IOS
      }
    }
  }

  .form-control {
    background-color: @gray_light;

    @media only screen and (max-width: 900px) {
      background-color: @gray_lighter;
      border-color: @gray_lighter;
      color: @text-color-light;
    }
  }

  // Routes
  .routes {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3em;

    @media only screen and (max-width: 900px) {
      padding: 0;
      margin-top: 1.5em;
    }

    li {
      a {
        border-radius: @border-radius;
        min-width: 250px;
        padding: @default-spacing / 2 @default-spacing*2.5 @default-spacing / 2
          @default-spacing*2.5;
        font-family: Asap;
        font-family: SceneAlt, serif;
        font-weight: 400;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        font-size: 1.8rem;
        line-height: 21.599999999999998px;
        line-height: 2.16rem;
        color: @red;
        background-color: transparent;
        display: inline;
        text-align: center;
        border: 1px solid @red;

        @media only screen and (max-width: 900px) {
          font-size: 15px;
          padding: @default-spacing / 2 @default-spacing*2;
          min-width: auto;
        }
        @media only screen and (max-width: 600px) {
          padding: @default-spacing / 2;
          min-width: auto;
          display: inline;
        }

        @media only screen and (max-width: 320px) {
          font-size: 13px;
          padding: @default-spacing / 4;
          min-width: auto;
        }

        &:hover {
          color: @white;
          background-color: @red_light;
          text-decoration: none;
          border-color: @red_light;
        }

        &.active {
          color: @white;
          background-color: @red_light;
          border-color: @red_light;
        }
      }

      &:not(:last-of-type) {
        margin-right: @default-spacing;

        @media only screen and (max-width: 900px) {
          margin-right: @default-spacing / 2;
        }
      }

      &.external-link {
        .icon-pil-spinkel-hoejre {
          display: inline-block;
          font-size: 14px;
          margin-left: @default-spacing / 2;

          @media only screen and (max-width: 900px) {
            font-size: 10px;
            margin-left: 2px;
          }
        }
      }
    }

    &__link:before {
      display: inline-block;
      font-size: 18px;
      position: relative;
      margin-left: 12px;
      float: right;

      @media only screen and (max-width: 900px) {
        font-size: 12px;
      }
    }

    &.routes--small {
      padding-left: 0px;
      li:not(:last-of-type) {
        margin-right: @default-spacing / 2;
      }

      li:first-of-type {
        a {
          margin-left: @default-spacing / 4;
        }
      }

      li:last-of-type {
        a {
          margin-right: @default-spacing / 4;
        }
      }

      .route-disabled {
        pointer-events: none;
        cursor: default;
        color: #696969;
        border-color: #696969;
        opacity: unset;

        @media only screen and (max-width: 600px) {
          color: rgba(#696969, 0.5);
          opacity: 0.5;
        }
      }

      a {
        font-size: 15px;
        min-width: 0;
        padding-left: @default-spacing*.75;
        padding-right: @default-spacing*.75;
      }

      @media only screen and (max-width: 900px) {
        margin-top: 3em;
      }

      @media only screen and (max-width: 600px) {
        display: -webkit-box !important;
        overflow-x: scroll;
        justify-content: start;
        margin-left: 15px;
        margin-right: 15px;

        &::-webkit-scrollbar {
          display: none;
        }

        a {
          min-width: max-content;
          color: @text-color-light;
          border: none;
          padding: @default-spacing / 4;
          border-radius: 0;

          &:after {
            display: block;
            content: "";
            border-bottom: solid 2px @red;
            transform: scaleX(0);
            transition: transform 250ms ease-in-out;
          }

          &:hover {
            color: @red_light;
            background-color: inherit;

            &:after {
              transform: scaleX(1);
              transform-origin: 0% 50%;
            }
          }

          &.active {
            color: @red;
            background-color: inherit;

            &:after {
              transform: scaleX(1);
              transform-origin: 0% 50%;
            }
          }
        }
      }
    }
  }

  .intro-container {
    display: flex;
    justify-content: center;

    .intro {
      text-align: center;
      font-family: "adobe-caslon-pro", serif;

      h1 {
        position: relative;
        margin: 1.2em 0;
        margin-bottom: 90px;

        @media only screen and (max-width: 400px) {
          padding: 0;
        }

        &:after {
          content: "";
          position: absolute;
          bottom: -23px;
          left: 35%;
          width: 30%;
          border-top: @default-spacing / 4 solid @red_light;
        }
      }

      p {
        font-size: 22px;
        line-height: 27px;
        @media only screen and (max-width: 900px) {
          font-size: 18px;
          line-height: 23px;
        }

        @media only screen and (max-width: 500px) {
          margin: 0 23px;
        }
      }
    }
  }

  .search-field {
    display: table;
    width: 100%;
    margin-bottom: 0;
  }

  label {
    color: @text-color-light;
    font-size: 15px;
    margin-top: @default-spacing*1.5;
  }

  input,
  .datepicker,
  .chosen-container-single .chosen-single {
    width: 100%;
    background-color: @gray_light;
    border: none;
    color: @text-color-light;
    font-size: 14px;
    font-family: SceneAlt, serif;
    cursor: pointer;

    @media only screen and (max-width: 900px) {
      background-color: @gray_lighter;
    }
    b {
      color: @red;
      background: none;
      &::before {
        transform: rotate(90deg);
        display: inline-block;
      }
    }
  }

  .chosen-container-single.chosen-disabled .chosen-single {
    background-color: rgba(247, 247, 247, 0.5);
    color: @red;
  }
  .datepicker {
    outline: 0;
    background-color: transparent;
    position: relative;
    z-index: 1;
  }

  .datepicker::placeholder {
    color: @text-color-dark;
  }

  .chosen-container-active.chosen-with-drop .chosen-single div b {
    &::before {
      transform: rotate(-90deg);
      display: inline-block;
    }
  }

  .search {
    padding: 8px;
    width: calc(100% - 1px);
  }

  .search-btn {
    background-color: #f0f2f2;
    border: none;
    font-size: 19px;
    padding: 4px 12px;
    color: @red;
  }

  .render-seats-parties {
    margin-top: @default-spacing*2.5;

    @media only screen and (max-width: 900px) {
      margin-top: 0px;
      display: flex;
      flex-direction: column-reverse;

      .no-space {
        padding: 0;
      }
    }
  }
  // parties
  .parties {
    margin: @default-spacing*2 0 @default-spacing;
    cursor: pointer;
    position: relative;

    @media only screen and (max-width: 900px) {
      display: flex;
      flex-wrap: wrap;
      margin: 40px -15px 20px;
    }
    @media only screen and (max-width: 600px) {
      margin: 40px -15px 20px;
    }

    &__dot {
      width: @default-spacing*.60;
      height: @default-spacing*.60;
      display: inline-block;
      border-radius: @border-radius-circle;
      border: 1px solid;
      margin-right: @default-spacing / 2;
      pointer-events: none;

      @media only screen and (max-width: 600px) {
        margin-right: 0;
      }
    }

    &__item {
      color: @text-color-light;
      font-size: 15px;
      position: relative;
      font-family: SceneAlt, serif;

      @media only screen and (max-width: 900px) {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
      }

      .parties__short-name {
        padding-bottom: 4px;

        @media only screen and (min-width: 600px) {
          margin-right: 10px;
        }
      }

      &:hover {
        @media only screen and (min-width: 1400px) {
          .parties__full-name {
            display: inline-block;
            transition: all 0.2s ease-in-out;
            text-decoration-line: underline;
            text-underline-offset: 3px;
          }

          .parties__short-name {
            display: none;
            transition: all 0.2s ease-in-out;
          }

          .parties__dot {
            width: @default-spacing*.75;
            height: @default-spacing*.75;
            transition: all 0.1s ease-in-out;
          }
        }
      }

      &.set-highlight {
        position: relative;
        z-index: 5;
        opacity: 1;

        .parties__full-name {
          display: inline-block;
          transition: all 0.2s ease-in-out;
          text-decoration-line: underline;
          text-underline-offset: 3px;
          color: #252525;

          @media only screen and (max-width: 992px) {
            display: none;
          }
        }

        .parties__short-name {
          display: none;
          text-decoration-line: underline;
          text-underline-offset: 3px;
          transition: all 0.2s ease-in-out;

          @media only screen and (max-width: 900px) {
            display: block;
          }

          @media only screen and (min-width: 901px) and (max-width: 992px) {
            display: inline-block;
          }
        }

        .parties__dot {
          width: @default-spacing*.75;
          height: @default-spacing*.75;
          transition: all 0.1s ease-in-out;
        }

        &:focus {
          @media only screen and (max-width: 1400px) {
            outline: none;
          }
        }
      }

      &--container {
        height: @default-spacing*2;
        padding-left: 0;
        @media only screen and (max-width: 900px) {
          padding-left: @default-spacing*.75;
          margin-bottom: @default-spacing*.75;
        }

        &:focus {
          @media only screen and (max-width: 900px) {
            outline: 0;
          }
        }
      }
    }

    &__full-name {
      display: none;
      position: absolute;
      width: max-content;
      pointer-events: none;
    }

    &__overlay {
      &.active {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: @background-color;
        opacity: 0.2;
        z-index: 2;
      }
    }
  }

  .member-overview__container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 69.79%;
  }

  .data-visualization {
    padding-bottom: @default-spacing*2;
    @media only screen and (max-width: 900px) {
      padding-bottom: 0;
    }
    .member-popup {
      position: relative;
    }

    .data-visualization-filters {
      display: flex;
      justify-content: center;

      @media only screen and (min-width: 901px) {
        .desktop-no-padding {
          padding-left: 0px;
          padding-right: 0px;
        }
      }
    }
    .member-popup__information-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
    }
    .desktop-no-padding {
      &.col-xs-12 {
        @media only screen and (min-width: 667px) and (max-width: 900px) {
          width: 60%;
        }
      }
    }

    .no-date-label {
      pointer-events: none;
      .date-picker-wrapper {
        opacity: 0.6;

        &:before {
          content: none;
        }

        .datepicker-info-message {
          display: none;
        }
      }
    }

    .intro {
      p {
        @media only screen and (max-width: 500px) {
          margin: 0 38px;
        }
      }
    }

    .col-md-1 {
      padding-right: 30px;
    }
    .parties__item--container {
      padding-left: @default-spacing*.75;
    }
  }
  .date-picker-wrapper {
    .input-group-addon {
      border: none !important;
      position: absolute;
      right: 195px;
    }
  }
  .overview-input-mobile {
    &.col-xs-12 {
      @media only screen and (min-width: 667px) {
        width: 60%;
      }
    }
  }

  .datepicker-info-message {
    color: #a6192e !important;
    font-family: SceneAlt, serif !important;
    margin-top: 3px !important;
  }
  // member-popup
  .member-popup {
    cursor: pointer;
    position: absolute;
    line-height: 0;
    outline: 0;

    .member-popup__information {
      line-height: 1.2;
      display: none;
      background-color: @white;
      position: absolute;
      padding: @default-spacing;
      left: 0;
      top: @default-spacing / 4;
      width: 200px;
      transform: translate3d(36px, -50%, 0);
      z-index: 7;
      border-radius: 8px;
      right: 70px;
      left: unset;
      font-family: SceneAlt, serif;
      outline: 0;

      p {
        font-size: 15px;
        color: @text-color-light;
      }

      p.member-popup__name {
        font-size: 18px;
        color: @text-color-dark;
        margin-top: 10px;
      }

      &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -@default-spacing;
        border-top: @default-spacing solid transparent;
        border-right: @default-spacing*1.25 solid @white;
        border-bottom: @default-spacing solid transparent;
        right: -@default-spacing;
        left: unset;
        border-left: @default-spacing*1.25 solid @white;
        border-right: unset;
      }

      @media only screen and (max-width: 900px) {
        display: none !important;
      }

      &:focus {
        @media only screen and (max-width: 900px) {
          outline: 0;
        }
      }
    }

    .member-popup__information-content {
      display: flex;
      align-items: center;
    }

    .member-popup__img-wrapper {
      height: @default-spacing*5;
      width: @default-spacing*5;
      margin-right: @default-spacing;
    }

    .member-popup__img {
      height: @default-spacing*5;
      width: @default-spacing*5;
      border-radius: @border-radius-circle;
    }

    .member-popup__info {
    }

    .member-popup__dot {
      display: inline-block;
      min-width: 8px;
      width: 1.5vw;
      max-width: 12px;
      min-height: 8px;
      height: 1.5vw;
      max-height: 12px;
      border-radius: @border-radius-circle;
      border: 1px solid;
      z-index: 0;

      @media only screen and (max-width: 667px) {
        min-width: 7px;
        width: 1.3vw;
        max-width: 7px;
        min-height: 7px;
        height: 1.3vw;
        max-height: 7px;
      }
    }

    &.member-popup--is-active {
      .member-popup__information {
        display: block;
      }

      .member-popup__dot {
        max-width: 14px;
        max-height: 14px;
      }
    }

    &:hover,
    &:focus {
      .member-popup__information {
        @media only screen and (min-width: 992px) {
          display: block;
        }
      }
    }

    &.member-popup--is-active, &:focus {
      .member-popup__dot {
        @media only screen and (max-width: 900px) {
          width: 2.4vw;
          height: 2.4vw;
        }
      }
    }

    &.member-popup--is-active .member-popup__information {
      background-color: #f7f7f7 !important;
      z-index: 2 !important;
      &::before {
        border-right: 25px solid #f7f7f7 !important;
      }
      &.right-aligh:before {
        border-right: unset !important;
        border-left: 25px solid #f7f7f7 !important;
      }
      &-content {
        position: relative;
      }
      .member-popup__close-btn {
        transform: rotate(45deg);
        font-size: 17px;
        background: @background-color;
        border-radius: @border-radius-circle;
        padding: 6px;
        border: none;
        position: absolute;
        right: -12px;
        top: -12px;
        width: 30px;
        height: 30px;
      }
    }

    &.member-popup--disabled {
      opacity: 0.3;
      cursor: initial;
      pointer-events: none;
    }
    &:focus {
      @media only screen and (max-width: 900px) {
        outline: 0;
      }
    }
  }

  .member-popup {
    &.prevent-hover {
      &:hover {
        max-width: 12px;
        max-height: 12px;
        cursor: default;
      }
    }
  }

  .member-popup {
    &.prevent-hover {
      &:hover {
        .member-popup__dot {
          max-width: 12px;
          max-height: 12px;
          cursor: default;
        }
      }
    }
  }

  // for align and vote page
  .member-overview__container {
    .member-popup {
      cursor: pointer;
      position: absolute;
      line-height: 0;

      .member-popup__information {
        line-height: 1.2;
        background-color: @white;
        position: absolute;
        padding: @default-spacing;
        left: 0;
        top: @default-spacing / 4;
        width: 410px;
        transform: translate3d(36px, -50%, 0);
        z-index: 5;
        border-radius: 8px;
        right: 0;
        font-family: SceneAlt, serif;

        &:before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          border-top: @default-spacing solid transparent;
          border-right: @default-spacing*1.25 solid @white;
          border-bottom: @default-spacing solid transparent;
          left: -@default-spacing;
          right: unset;
          border-left: unset;
        }

        &.right-aligh {
          line-height: 1.2;
          background-color: @white;
          position: absolute;
          padding: @default-spacing;
          left: 0;
          top: @default-spacing / 4;
          width: 410px;
          transform: translate3d(36px, -50%, 0);
          z-index: 5;
          border-radius: 8px;
          right: 75px;
          left: unset;
          font-family: SceneAlt, serif;

          &:before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -@default-spacing;
            border-top: @default-spacing solid transparent;
            border-bottom: @default-spacing solid transparent;
            right: -@default-spacing;
            left: unset;
            border-left: @default-spacing*1.25 solid @white;
            border-right: unset;
          }
        }
      }
    }
  }

  .data-visualization {
    .member-popup--is-active .member-popup__information {
      background-color: #f7f7f7 !important;
      z-index: 5 !important;
      &::before {
        border-right: unset !important;
        border-left: 25px solid #f7f7f7 !important;
      }
      &-content {
        position: relative;
      }
    }

    .member {
      @media only screen and (max-width: 900px) {
        margin-left: -@default-spacing + 5;
        margin-right: -@default-spacing + 5;
      }
    }
  }

  .seats {
    margin: @default-spacing 0;
    cursor: pointer;
    display: flex;

    &.hide-all-else {
      .set-overlay {
        z-index: 99;
      }

      .seats__overlay {
        z-index: 10;
      }
    }

    &__dot {
      display: inline-block;
      width: @default-spacing*.75;
      height: @default-spacing*.75;
      border-radius: @border-radius-circle;
      border: 1px solid;
    }

    &__item {
      color: @text-color-light;
      position: absolute;
      display: none;
      z-index: 6;

      &.active {
        display: block;
      }

      &.active-dot {
        display: block;

        .seats__member {
          display: none;
        }

        &.active {
          .seats__member {
            display: block;
          }
        }
      }

      &.set-overlay {
        z-index: 4;

        .seats__member {
          display: block;
        }

        .seats__dot {
          width: @default-spacing*.75;
          height: @default-spacing*.75;
          transition: all 0.1s ease-in-out;
        }

        & + .seats__overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: @background-color;
          opacity: 0.8;
          z-index: 2;
        }
      }

      &.set-highlight {
        z-index: 3;

        .seats__dot {
          width: 12px;
          height: 12px;
          transition: all 0.1s ease-in-out;
        }

        & + .seats__overlay {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: @background-color;
          opacity: 0.8;
          z-index: 2;
        }

        &:hover {
          z-index: 5;
          outline: 0;
        }
      }
    }

    &__member {
      background-color: @white;
      position: absolute;
      z-index: 99;
      top: -@default-spacing*3;
      left: @default-spacing*2;
      border-radius: @border-radius;
      pointer-events: none;
    }

    &__content {
      position: relative;
      display: flex;
      padding: @default-spacing;

      &:before {
        content: "";
        position: absolute;
        top: calc(50% - 20px);
        left: -@default-spacing;
        border-top: @default-spacing solid transparent;
        border-right: @default-spacing*1.25 solid @white;
        border-bottom: @default-spacing solid transparent;
      }
    }

    &__img {
      width: @default-spacing*5;
      height: @default-spacing*5;
      border-radius: @border-radius-circle;
    }

    &__info {
      margin-left: @default-spacing;
      width: max-content;

      > h4 {
        color: @text-color-dark;
      }
    }

    &__overlay {
      &.active {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: @background-color;
        opacity: 0.8;
        z-index: 2;
      }
    }
  }
  // members
  .member {
    background: @gray_lighter;
    padding: @default-spacing*2.5;
    padding-left: 0;
    display: flex;
    margin: 0;
    font-family: SceneAlt, serif;

    span {
      color: @text-color-light;

      @media only screen and (max-width: 900px) {
        font-size: 15px;
      }
    }

    &.desktop {
      display: flex;

      @media only screen and (max-width: 900px) {
        display: none;
      }
      .infos {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-right: 0;
        padding-left: 0;
      }
    }

    &.mobile {
      display: none;

      @media only screen and (max-width: 900px) {
        display: block;
        padding: @default-spacing*1.25;
        margin-left: -@default-spacing*1.5;
        margin-right: -@default-spacing*1.5;
        margin-bottom: @default-spacing / 10;
      }

      .mobile__container {
        display: flex;
        justify-content: space-between;
        .col-sm-9 {
          width: 75%;
        }
        &.mobile__container--data-visualization {
          // padding-left: 15px;
          // padding-right: 15px;

          .member__info--detailes {
            color: #696969;
            font-size: 15px;
          }
        }
      }
    }

    h4 {
      color: @text-color-dark;
    }

    &__img-container {
      display: flex;
      @media only screen and (max-width: 900px) {
        display: block;
      }

      img {
        align-self: center;
        width: @default-spacing*7.5;
        height: @default-spacing*7.5;
        border-radius: @border-radius-circle;
        margin: 0 auto;
        border: none;

        @media only screen and (max-width: 900px) {
          width: @default-spacing*4;
          height: @default-spacing*4;
        }
      }

      @media only screen and (max-width: 900px) {
        padding: 0;
      }
    }

    &__last-column {
      display: flex;
      justify-content: space-between;

      &--content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &__close-btn {
      align-self: flex-start;
      transform: rotate(45deg);
      font-size: 25px;
      background: @background-color;
      border-radius: @border-radius-circle;
      padding: 12px;
      border: none;
      margin-top: -@default-spacing;
      margin-right: -@default-spacing*.75;
    }

    &__info {
      margin-bottom: @default-spacing*1.25;
      width: calc(42% - 20px);
      @media only screen and (max-width: 900px) {
        margin-bottom: 0;
        width: auto;
      }
      &.hide-column {
        visibility: hidden;
        @media only screen and (max-width: 900px) {
          display: none;
        }
      }
      &.space-top {
        @media only screen and (max-width: 900px) {
          margin-top: @default-spacing*1.5;
        }
      }
      &.no-m-space {
        margin-bottom: 0;
      }
      &.not-full-width {
        width: calc(32% - 20px);
      }

      p {
        font-size: 18px;
      }
      span.fixed-width {
        width: 85%;
        &.mobile-non-function {
          display: none;
          @media only screen and (max-width: 901px) {
            display: block;
          }
        }
      }
      a.fixed-width {
        width: 85%;
        &.desktop-function {
          display: none;
          @media only screen and (min-width: 900px) {
            display: block;
            color: #a6192e;
            display: table-header-group;
            cursor: pointer;
          }
          &:before {
            display: inline-block;
            font-size: 10px;
            position: relative;
            margin-left: 5px;
            float: right;
            margin-top: 6px;
          }
        }
      }

      &--dot {
        border-radius: @border-radius-circle;
        width: @default-spacing / 2;
        height: @default-spacing / 2;
        display: inline-block;
        margin-left: @default-spacing / 4;
        border: 1px solid;
      }

      span:not(.member__info--dot) {
        display: inline-block;
      }

      &--btn {
        margin-bottom: @default-spacing / 2;
        align-self: flex-end;
      }

      &-container {
        @media only screen and (max-width: 900px) {
          padding: @default-spacing*1.25 0 0;
        }
      }
      &--content {
        @media only screen and (max-width: 900px) {
          align-self: center;
        }
      }
    }

    &__so-me {
      display: flex;
      &--headline {
        font-size: 18px;
        margin-top: @default-spacing*1.5;
      }
      &-link {
        margin-right: @default-spacing*.75;
        @media only screen and (max-width: 900px) {
          margin-top: @default-spacing*1.75;
        }
      }
    }

    &__read-more {
      font-size: 18px;
      padding-top: @default-spacing*2;
      color: @red;
      display: table-header-group;

      &:before {
        display: inline-block;
        font-size: 16px;
        position: relative;
        margin-left: 12px;
        float: right;
      }

      @media only screen and (max-width: 900px) {
        margin-bottom: @default-spacing / 4;
      }
    }

    &__render-info {
      text-decoration: none;
      cursor: pointer;
      color: @red;
      display: inline-block;

      &::before {
        font-size: 18px;
        transform: rotate(90deg);
        display: block;
      }

      &.open {
        &::before {
          transform: rotate(-90deg);
          display: block;
        }
      }
    }

    &__info-container {
      &.hide {
        display: none;
      }

      &.open {
        display: block;
      }
    }
  }
  .members > div:last-of-type {
    .member {
      @media only screen and (max-width: 900px) {
        margin-bottom: 0;
      }
    }
  }
  .members {
    &.target {
      animation: fadeMe 0.5s;
    }
    @keyframes fadeMe {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    .member__so-me {
      a {
        transform: scale(1,1,1);
        &:hover {
          transition: scale(1.05, 1.05, 1)
        }
      }
    }
  }
  .row__content {
    display: flex;
    @media only screen and (max-width: 991px) {
      flex-direction: column-reverse;
    }
  }

  .members > div:nth-of-type(even) {
    .member {
      background-color: @gray_light;

      @media only screen and (max-width: 900px) {
        background-color: #f7f7f7;
      }
    }
  }
  .members-info {
    font-size: 15px;
    font-family: SceneAlt, serif;
    color: @text-color-light;
    margin: 15px 0;

    .party-full-name {
      display: table;
      color: @text-color-dark;
      font-weight: 700;
      font-size: 15px;
      margin-bottom: 0;
    }
  }
  .members-count {
    &__container {
      display: flex;
      flex-wrap: wrap;
      .separator {
        display: inline-block;
        margin-right: 5px;
        font-weight: normal;
      }
    }
  }
  // reset btn
  .reset-btn {
    background: none;
    border: 1px solid @red;
    color: @red;
    padding: @default-spacing / 2 @default-spacing;
    border-radius: @border-radius;
    margin-top: @default-spacing*2;
    float: right;
    font-family: SceneAlt, serif;
    cursor: pointer;

    @media only screen and (max-width: 1200px) {
      margin-left: -15px;
    }
    @media only screen and (max-width: 900px) {
      display: none;
    }

    @media only screen and (max-width: 1024px) {
      padding: 5px 10px;
      font-size: 15px;
    }

    &:hover {
      color: @white;
      background-color: @red_light;
      text-decoration: none;
      border-color: @red_light;
    }
  }
  // voting
  .voting {
    &__results {
      display: flex;
      align-items: center;
      margin: 0;
      margin-top: @default-spacing + 10;

      &--number {
        display: flex;
        padding: 0;
        align-items: center;
        font-family: "adobe-caslon-pro", serif;
        margin-top: 10px;
        margin-right: @default-spacing*3;
        @media only screen and (max-width: 900px) {
          margin-right: 0;
        }
      }
    }

    &__types {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      font-family: SceneAlt, serif;
      @media only screen and (max-width: 900px) {
        margin-top: @default-spacing;
        justify-content: center;
      }

      &--item {
        flex-basis: 30%;
        margin-bottom: @default-spacing / 4;
      }

      &.disktop-view {
        flex-basis: 35%;
        @media only screen and (min-width: 1150px) {
          flex-basis: 33%;
        }

        @media only screen and (max-width: 900px) {
          display: none;
        }
      }

      &.mobile-view {
        display: none;

        @media only screen and (max-width: 900px) {
          display: flex;
          margin-left: 23px;
          width: 270px;
          margin-bottom: 30px;
        }
      }
    }

    &__for,
    &__against {
      font-size: 40px;
      font-weight: bold;
      margin: 0;
    }

    &__for {
      margin-right: 0px;
      padding-right: 0px;
      padding-left: 23px;
    }

    &__against {
      padding-left: 15px;
    }

    &__dot {
      width: @default-spacing / 2;
      height: @default-spacing / 2;
      display: inline-block;
      border-radius: @border-radius-circle;
      border: 1px solid;
      margin-right: @default-spacing / 2;
      display: inline-block;
    }

    .seats {
      margin: @default-spacing 0;
      cursor: pointer;
      display: flex;
      position: relative;

      &.hide-all-else {
        .set-overlay {
          z-index: 99;
        }

        .seats__overlay {
          z-index: 10;
        }
      }

      &__dot {
        display: inline-block;
        width: @default-spacing*.75;
        height: @default-spacing*.75;
        border-radius: @border-radius-circle;
        border: 1px solid;
      }

      &__item {
        color: @text-color-light;
        position: absolute;
        display: none;
        z-index: 6;

        &.active {
          display: block;
        }
      }

      &__member {
        background-color: @white;
        position: absolute;
        z-index: 99;
        top: -@default-spacing*3;
        left: @default-spacing*2;
        border-radius: @border-radius;
        pointer-events: none;
      }

      &__content {
        position: relative;
        display: flex;
        padding: @default-spacing;

        &:before {
          content: "";
          position: absolute;
          top: calc(50% - 20px);
          left: -@default-spacing;
          border-top: @default-spacing solid transparent;
          border-right: @default-spacing*1.5 solid @white;
          border-bottom: @default-spacing solid transparent;
        }
      }

      &__img {
        width: @default-spacing*5;
        height: @default-spacing*5;
        border-radius: @border-radius-circle;
      }

      &__info {
        margin-left: @default-spacing;
        width: max-content;

        > h4 {
          color: @text-color-dark;
        }
      }

      &__overlay {
        &.active {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: @background-color;
          opacity: 0.8;
          z-index: 2;
        }
      }
    }
  }
  div.voting__types--item:nth-of-type(even) {
    flex-basis: 70%;
  }

  .charts {
    display: flex;
    min-width: 100%;

    @media only screen and (max-width: 900px) {
      overflow-x: scroll;

      &::-webkit-scrollbar {
        height: 2px;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px @gray_light;
        border-radius: 3px;
        background-color: @gray_light;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #5f5f5f;
        border-radius: 8px;
      }
    }
  }

  .charts__chart {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    min-width: @default-spacing*4;
    margin-top: @default-spacing*5;

    @media only screen and (max-width: 900px) {
      min-width: 55%;
      padding-bottom: 30px;
    }

    @media only screen and (max-width: 500px) {
      min-width: 90%;
    }
  }

  .charts__chart-group {
    display: flex;
  }

  .chart__label {
    width: calc(100% - 20px);
    padding-top: @default-spacing / 2;
    border-top: 1px solid #b7b9b9;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    color: @text-color-light;
    font-size: 15px;
    font-family: SceneAlt, serif;
  }

  .chart__count {
    width: @default-spacing*1.75;
    height: @default-spacing*1.75;
    border: 1.5px solid #5f5f5f;
    color: #5f5f5f;
    border-radius: @border-radius-circle;
    display: inline-block;
    font-weight: bold;
    margin-left: @default-spacing / 2;
    text-align: center;
    font-size: 15px;
    font-family: SceneAlt, serif;

    .chart__count-number {
      position: relative;
      top: 6px;
    }
  }

  .chart__party-group {
    display: flex;
    align-items: flex-end;
    flex-direction: column-reverse;
    max-height: 350px;
    flex-wrap: wrap;

    .member-popup {
      margin-bottom: @default-spacing / 2;
      margin-left: @default-spacing / 4;
      margin-right: @default-spacing / 4;
      width: @default-spacing / 2;
      height: @default-spacing / 2;
      @media only screen and (max-width: 900px) {
        width: 8px;
        height: 8px;
      }
    }
  }

  .charts-constituency,
  .charts-years {
    flex-wrap: wrap;

    .charts__chart {
      min-width: 280px;
      margin-right: 20px;
      flex-grow: 0;
    }

    @media only screen and (max-width: 900px) {
      flex-wrap: nowrap;

      .charts__chart {
        min-width: 35%;
      }
    }
    @media only screen and (max-width: 500px) {
      flex-wrap: nowrap;
      .charts__chart {
        min-width: 75%;
      }
    }
  }
  .charts-years {
    @media only screen and (max-width: 900px) {
      flex-wrap: nowrap;

      .charts__chart {
        min-width: 40%;
      }
    }
    @media only screen and (max-width: 500px) {
      flex-wrap: nowrap;

      .charts__chart {
        min-width: 75%;
      }
    }
  }

  .charts-age {
    flex-wrap: wrap;

    .charts__chart {
      margin-top: @default-spacing*5;
      min-width: 33%;
    }

    @media only screen and (max-width: 900px) {
      flex-wrap: nowrap;

      .charts__chart {
        min-width: 40%;
      }
    }
    @media only screen and (max-width: 500px) {
      flex-wrap: nowrap;

      .charts__chart {
        min-width: 75%;
      }
    }
  }

  .charts-mandates {
    flex-wrap: wrap;

    .charts__chart {
      margin-top: @default-spacing*5;
      min-width: 114px;
      flex-grow: 0;
    }

    @media only screen and (max-width: 900px) {
      flex-wrap: nowrap;

      .charts__chart {
        min-width: 30%;
      }
    }
    @media only screen and (max-width: 500px) {
      flex-wrap: nowrap;

      .charts__chart {
        min-width: 75%;
      }
    }
  }
  // loading
  .loading {
    .container {
      width: 128px;
      height: 128px;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      margin: auto;
    }

    .container:nth-child(1) {
      transform: rotate(0deg);
    }

    .container:nth-child(2) {
      transform: rotate(18deg);
    }

    .container:nth-child(3) {
      transform: rotate(36deg);
    }

    .container:nth-child(4) {
      transform: rotate(54deg);
    }

    .container:nth-child(5) {
      transform: rotate(72deg);
    }

    .container:nth-child(6) {
      transform: rotate(90deg);
    }

    .container:nth-child(7) {
      transform: rotate(108deg);
    }

    .container:nth-child(8) {
      transform: rotate(126deg);
    }

    .container:nth-child(9) {
      transform: rotate(144deg);
    }

    .container:nth-child(10) {
      transform: rotate(162deg);
    }

    .container:nth-child(11) {
      transform: rotate(180deg);
    }

    .container:nth-child(12) {
      transform: rotate(198deg);
    }

    .container:nth-child(13) {
      transform: rotate(216deg);
    }

    .container:nth-child(14) {
      transform: rotate(234deg);
    }

    .container:nth-child(15) {
      transform: rotate(252deg);
    }

    .container:nth-child(16) {
      transform: rotate(270deg);
    }

    .container:nth-child(17) {
      transform: rotate(288deg);
    }

    .container:nth-child(18) {
      transform: rotate(306deg);
    }

    .container:nth-child(19) {
      transform: rotate(324deg);
    }

    .container:nth-child(20) {
      transform: rotate(342deg);
    }

    .container .object {
      position: absolute;
      top: 56px;
      left: 112px;
      background: @red;
      border-radius: 16px;
      animation: rotate 1s linear infinite;
    }

    @keyframes rotate {
      0% {
        width: 16px;
        height: 16px;
      }

      100% {
        width: 0px;
        height: 0px;
      }
    }

    .container:nth-child(1) .object {
      animation-delay: 0s;
    }

    .container:nth-child(2) .object {
      animation-delay: 0.05s;
    }

    .container:nth-child(3) .object {
      animation-delay: 0.1s;
    }

    .container:nth-child(4) .object {
      animation-delay: 0.15s;
    }

    .container:nth-child(5) .object {
      animation-delay: 0.2s;
    }

    .container:nth-child(6) .object {
      animation-delay: 0.25s;
    }

    .container:nth-child(7) .object {
      animation-delay: 0.3s;
    }

    .container:nth-child(8) .object {
      animation-delay: 0.35s;
    }

    .container:nth-child(9) .object {
      animation-delay: 0.4s;
    }

    .container:nth-child(10) .object {
      animation-delay: 0.45s;
    }

    .container:nth-child(11) .object {
      animation-delay: 0.5s;
    }

    .container:nth-child(12) .object {
      animation-delay: 0.55s;
    }

    .container:nth-child(13) .object {
      animation-delay: 0.6s;
    }

    .container:nth-child(14) .object {
      animation-delay: 0.65s;
    }

    .container:nth-child(15) .object {
      animation-delay: 0.7s;
    }

    .container:nth-child(16) .object {
      animation-delay: 0.75s;
    }

    .container:nth-child(17) .object {
      animation-delay: 0.8s;
    }

    .container:nth-child(18) .object {
      animation-delay: 0.85s;
    }

    .container:nth-child(19) .object {
      animation-delay: 0.9s;
    }

    .container:nth-child(20) .object {
      animation-delay: 0.95s;
    }
  }

  .faceted-filter {
    display: none;
    flex-wrap: wrap;
    padding: 0 15px;

    &__item {
      color: @red_light;
      border: 1px solid @red_light;
      padding: @default-spacing / 5 @default-spacing*.75;
      border-radius: @border-radius;
      background: inherit;
      margin: 2px;
      display: flex;
      font-family: SceneAlt, serif;

      &:hover {
        text-decoration: none;
      }

      .icon-ft_accordionplus {
        transform: rotate(45deg);
        display: inline-block;
        margin-left: @default-spacing / 4;
        align-self: center;
      }
    }
    .faceted-filter__item--remove {
      cursor: pointer;
      color: @red;
      &:hover {
        text-decoration: none!important;
        transition: transform 200ms;
        transform: scale3d(1.1,1.1,1) rotate(45deg);
      }
    }

    @media only screen and (max-width: 900px) {
      display: flex;
    }
  }

  .chosen-container-multi .chosen-drop .result-selected:before,
  .chosen-container-single .chosen-drop .result-selected:before {
    @media only screen and (max-width: 900px) {
      content: "";
      display: none;
    }
  }
  .chosen-disabled {
    pointer-events: none;
    opacity: 0.9 !important;
  }

  .chosen-container-multi .chosen-drop .result-selected:after,
  .chosen-container-single .chosen-drop .result-selected:after {
    @media only screen and (max-width: 900px) {
      content: "\e372";
      display: inline-block;
      font-family: "ft-icons";
      position: relative;
      float: right;
    }
  }

  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */

    .datepicker {
      @media only screen and (max-width: 900px) {
        font-size: 16px; //solve zoom on IOS
      }
    }
  }

  .datepicker {
    padding: 6px 12px;

    .day.disabled {
      opacity: 0.5;
    }
    @media only screen and (max-width: 900px) {
      border-color: @gray_lighter;
    }
    table {
      tr {
        td {
          border: none !important;
        }
      }
    }
  }

  //Modal styling
  .date-modal {
    transition: 1.1s ease-out;
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    position: absolute;
    z-index: 999;
    padding: 30px;
    background-color: #f7f7f7;
    font-family: SceneAlt, serif;
    border-radius: 3px;
    text-align: center;
    align-self: center;
    top: -80px;

    @media only screen and (max-width: 900px) {
      top: -200px;
    }

    .date-modal-content {
      h1 {
        color: #252525;
        font-family: "SceneAlt";
        font-size: 18px;
        line-height: 20px;
        margin-bottom: 10px;
        padding: 0;
        margin-top: 0;
      }
      p:last-of-type {
        color: #696969;
        font-size: 16px;
        margin-bottom: 10px;
      }
    }

    &.off {
      opacity: 0;
      visibility: hidden;
      filter: blur(8px);
      transform: scale(0.33);
      box-shadow: 1rem 0 0 rgba(black, 0.2);
    }
    .date-modal-actions {
      display: flex;
      justify-content: center;
    }
    .date-modal-button {
      border-radius: 5px;
      padding: 10px 50px 10px 50px;
      font-family: SceneAlt, serif;
      font-weight: 400;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 18px;
      font-size: 1.8rem;
      line-height: 21.6px;
      line-height: 2.16rem;
      color: #a6192e;
      background-color: #f7f7f7;
      display: inline-block;
      text-align: center;
      border: 1px solid #a6192e;
      margin-top: 30px;
      &:hover {
        color: #fff;
        background-color: #a6192e;
        text-decoration: none;
        border-color: #a6192e;
      }

      &:focus,
      &:focus-visible {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: -2px;
      }
    }
  }
  .page-overlay {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 10; /* Specify a stack order in case you're using a different order for other elements */
    cursor: pointer; /* Add a pointer on hover */
    &.show-overlay {
      display: block;
    }
  }
}
.chamberOverview-full__content-wrapper {
  #chamber-overview {
    position: unset !important;
  }
}

.chamber-overview__voting {
  .member-overview__container {
    .member-popup {
      .member-popup__information {
        @media only screen and (max-width: 1170px) {
          width: 350px !important;
        }
      }
    }
  }
}

// Layout for print
#chamber-overview {
  .member-overview__container.printlayout {
    display: none;

    @media print {
      display: block;
    }
  }

  .chamber-overview-print-date {
    font-family: Georgia, serif;
    display: none;
    position: absolute;
    top: -55px;
    right: 35px;
    font-size: 17px;
  }

  .data-visualization-intro {
    display: none;
  }
}
.print-version__show {
  display: none;
}

@media print {
  * {
    cursor: initial;
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
    color-adjust: exact !important; /* Firefox */
  }

  @supports (-moz-appearance: none) {
    html.chamber-overview {
      //Width and height entries were added as a work-around to allow Firefox to present the user with a prettier print-preview. Firefox does not yet support the @page size functionality of CSS3, and therefore opens with A4 portrait as standard. Although Firefox users will still have to swith to (A3) landscape manually in order to achieve the best printing result. For reference, see: https://bugzilla.mozilla.org/show_bug.cgi?id=851441
      width: 42cm;
      height: calc(
        29.7cm - 1in
      ); //Chrome, Firefox, Edge: none use more than .5 in default margin. For reference, see: https://stackoverflow.com/questions/25387866/what-are-the-default-margins-in-browser-print-settings
    }
  }

  .print-version__show {
    display: block;
  }
  .print-version__hide {
    display: none;
  }
  .member-popup--disabled {
    opacity: 1 !important;
  }
  .member-popup.member-popup--is-active .member-popup__dot {
    max-width: 12px !important;
    max-height: 12px !important;
  }

  body.chamber-overview-page {
    margin: 1cm 0 0 0.5cm;
  }

  // only for data-visualization-route
  body.data-visualization-route {
    padding-top: 15px;
  }

  // only for chamber-overview-page
  body.chamber-overview-page:not(.data-visualization-route) {
    width: calc(100% - 40px);

    .contentProps {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .chamber-overview-page {
    .chamberOverview-logo__wrapper {
      margin: 15px 0 30px 15px;
      padding: 0;
    }

    &.data-visualization-route .chamberOverview-logo__wrapper {
      margin-bottom: 100px;
    }
  }

  .chamber-overview-page .chamberOverview-full__content-wrapper .container {
    max-width: 1754px !important;
  }

  #chamber-overview.chamber-overview__voting {
    .member-overview__container.printlayout {
      display: none;
    }
  }

  #chamber-overview:not(.chamber-overview__voting) {
    margin: 0;

    .members,
    .members-info,
    .member-popup__information,
    .content-action-wrapper,
    .chamber-overview-page .chamberOverview-full__identity .breadcrumb-wrapper,
    .routes,
    .member-overview__container:not(.printlayout),
    .intro-container,
    .intro-container.overview,
    .chamber-overview__filters-btn--container,
    .mobile-view,
    .data-visualization .data-visualization-filters {
      display: none !important;
    }

    .chamber-overview-print-date {
      display: block;
    }

    // datavisualisering
    .data-visualization {
      padding-bottom: 0;
    }

    #download-pdf {
      margin: 0;

      &.render-seats-parties {
        margin-top: @default-spacing;
      }

      .parties__item--container {
        height: auto;
        padding: @default-spacing / 2 0;
        width: 100%;

        &:first-of-type {
          padding-top: 0;
        }

        &:last-of-type {
          padding-bottom: 0;
        }
      }

      .parties-container {
        width: 200px;
        padding: 0;
      }

      .seats-container,
      .data-visualization-container {
        width: calc(100% - 200px);
      }

      .render-seats-reset {
        display: none;
      }

      .parties {
        margin: 20px 0 0;
      }

      .parties__item {
        font-size: 12px;
        display: flex;
        align-items: center;
        font-family: Arial, sans-serif;

        .parties__dot {
          width: 15px;
          height: 15px;
          flex-grow: 0;
          flex-shrink: 0;
          -webkit-print-color-adjust: exact;
        }
        .parties__short-name {
          padding-bottom: 4px;
          display: none;
        }

        .parties__full-name {
          display: inline-block;
          text-decoration-line: none;
          position: static;

          .set-highlight & {
            text-decoration-line: none;
          }
        }
      }

      .member-overview__container {
        .member-popup {
          line-height: initial;
          height: 15px;

          .member-popup__dot,
          &:hover .member-popup__dot,
          &:focus .member-popup__dot {
            width: 15px;
            height: 15px;
            max-width: 15px;
            max-height: 15px;
          }

          .member-popup__data {
            font-size: 9px;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%);

            &.member-data-below {
              top: @default-spacing;
              transform: translate(0);
            }

            span {
              display: block;
            }

            &.content-left-align {
              text-align: left;

              &.member-data-center {
                left: @default-spacing;
                word-break: break-all;
                width: 68px;

                &.seat-195 {
                  width: 100px;
                }

                &.seat-197 {
                  top: 0;
                }

                &.seat-198 {
                  top: 30px;
                }

                &.seat-199 {
                  top: -10px;
                }
              }

              &.member-data-off-center {
                left: @default-spacing;
                top: 0;
                transform: translate(0);
              }

              &.member-data-below {
                left: 3px;
              }

              &.member-data-right {
                top: 0;
                transform: translate(20px, 10px);
              }

              &.member-data-below-right {
                top: 0;
                transform: translate(10px, 18px);
              }
            }

            &.content-right-align {
              text-align: right;

              &.member-data-center {
                right: @default-spacing;
                width: 80px;
              }

              &.member-data-below {
                right: 3px;
              }

              &.member-data-left {
                top: 0;
                right: 0px;
                transform: translate(-20px, 8px);
              }

              &.member-data-below-left {
                top: 0;
                right: 0;
                transform: translate(-14px, 20px);
              }
            }

            .member_id {
              font-weight: 800;
            }
          }
        }
      }

      // data visualization
      .data-visualization-content {
        margin-left: 0;
        margin-right: 0;
        flex-wrap: wrap;
      }

      .data-visualization-intro {
        display: block;
        font-family: Georgia, serif;
        font-size: 17px;
        line-height: 20px;
        width: 100%;
        padding: 0;
      }

      .data-visualization-intro-title {
        font-weight: 600;
      }

      .data-visualization-intro-selected {
        font-weight: 600;
        color: @red !important;
      }

      .data-visualization-container {
        padding: 0;
        align-items: flex-start !important;
      }

      .chart__label,
      .chart__count {
        font-family: Arial, Helvetica, sans-serif;
      }

      .charts {
        display: table;
      }

      .charts__chart {
        margin-top: 50px !important;
        display: inline-block;
        -webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
        page-break-inside: avoid; /* Firefox */
        break-inside: avoid; /* IE 10+ */
      }

      .charts-sex .charts__chart {
        width: 50%;
      }

      .data-visualization-content .parties__item {
        font-size: 10px;
      }

      .charts-mandates {
        .charts__chart {
          width: 10%;
          min-width: 10%;
        }
      }

      .charts__chart-group {
        justify-content: center;
      }
    }
  }

  // Salsoversigten set fra talerstolen (roteret 180grader)
  .chamber-overview-page.chamber-overview-page--rotate {
    #chamber-overview:not(.chamber-overview__voting) #download-pdf {
      .member-overview__container.printlayout {
        transform: rotate(180deg);

        .member-popup__data {
          font-size: 9px;
          display: flex;
          flex-direction: column-reverse;
          position: absolute;
          top: auto;
          bottom: -3px;
          transform: translate(0, 100%) rotate(180deg);

          &.member-data-center {
            flex-direction: column;
            bottom: auto;
            top: 50%;
            transform: translate(0, -50%) rotate(180deg);

            .member_info {
              margin-bottom: 0;
            }
          }

          &.member-data-off-center {
            top: 0;
            bottom: auto;
            transform: translate(0, 0) rotate(180deg);
          }

          &.member-data-off-center:not(.member-data-center) {
            top: auto;
            bottom: -15px;
          }

          &.member-data-below {
            top: auto;
            transform: translate(0) rotate(180deg);
          }

          &.content-left-align {
            text-align: right;

            &.member-data-center {
              left: @default-spacing;
              word-break: break-all;
              width: 70px;
              top: 0px;

              &.seat-199 {
                top: -10px;
              }

              &.seat-198 {
                top: 30px;
              }

               &.seat-196 {
                 top: 10px;
               }

              &.seat-195 {
                top: 10px;
                width: 90px;
              }

              &.seat-191 {
                top: -15px;
              }

              &.seat-190 {
                top: -10px;
              }

              &.seat-189 {
                top: 10px;
              }
            }

            &.member-data-below {
              bottom: -3px;
              transform: translate(2px, 100%) rotate(180deg);
            }

            &.member-data-right {
              bottom: 10px;
              transform: translate(20px, 100%) rotate(180deg);
            }

            &.member-data-below-right {
              top: 0;
              transform: translate(18px, 12px) rotate(180deg);
              bottom: auto;
            }
          }

          &.content-right-align {
            text-align: left;

            &.member-data-center {
              right: @default-spacing;
            }

            &.member-data-below {
              transform: translate(3px, 100%) rotate(180deg);
            }

            &.member-data-left {
              top: auto;
              right: 15px;
              bottom: 0;
            }

            &.member-data-below-left {
              top: auto;
              right: 0;
            }
          }
        }
      }
    }
  }
}
