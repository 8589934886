﻿/*
 * Day Items
 */
h2.day {
  line-height: 40px;
  padding: 10px 38px 0;
  margin: 0;
  .font-size(2);
  font-weight: 400;
}

.video-item-agenda {
  padding: 10px 20px 10px 20px;
  font-size: 14px;
}

#nextAgenda {
  background: #fff;
  padding-bottom: 0;
}

.video-item {
  margin-bottom: 1px !important;
  position: relative;
  width: 100%;
}

.video-item h3 {
  .font-size(1.6);
  margin: 0;
  overflow: hidden;
  padding: 5px 40px 5px 20px;
}

.video-item h3 b {
  color: #464646;
  text-decoration: none;
  font-weight: 400;
  font-size: 16px;
}

.video-item:hover h3 b {
  color: #464646;
  font-size: 16px;
  cursor: pointer;

  .text-selection-inactive & {
    text-decoration: none;
    cursor: auto;
  }
}

.video-item p {
  padding: 0 !important;
  margin: 8px 0;
}

.video-item:hover p {
  padding: 0 !important;
  margin: 8px 0;
  cursor: pointer;
  text-decoration: underline;

  .text-selection-inactive & {
    text-decoration: none;
    cursor: auto;
  }
}

.video-item-content {
  padding: 10px 59px;
}

.video-item-active {
  margin-bottom: 7px;
}

.video-item-active h3 b {
  text-decoration: none;
  font-weight: 700;
}

.video-item-active h3 b:hover {
  text-decoration: none !important;
}

.video-item-active .video-item-agenda {
  display: block;
}
/* Share Link */
a.share-link {
  display: block;
  width: 29px;
  height: 29px;
  text-indent: -9999px;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
}

a.play {
  display: block;
  width: 40px;
  height: 42px;
  text-indent: -9999px;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
/*
 * New Video Box Item
 */
.video-boxes .scroll-container {
  height: 675px;
}

.video-box {
  padding: 18px 38px;
  position: relative;

  h3 {
    .font-size(1.6);
    margin: 0;
    padding: 0;
    font-weight: 400;
    color: #cc4a86;
  }

  a {
    color: @color-brand-200;
    text-decoration: none;
			&:before {
				display: inline-block;
				&:extend(.less-icon-extend);
				&:extend(.icon-ft_bullit_right:before);
				font-size: 11px;
				float: left;
				margin: 4px 5px 0 0;
			}

    &:hover {
      text-decoration: underline;
    }
  }

  p {
    padding: 0;
    margin-bottom: 2px;
    .font-size(1.4);
  }

  &.video-box-active {
    h3 {
      a:hover {
        text-decoration: none;
        cursor: default;
      }
    }
  }
}

.video-box-active .play {
  display: none;
}

/*
 * Referat Video
 */
.video-item-referat,
.video-referat-active {
  position: relative;
  margin-bottom: 0 !important;

  .sharelink {
    top: 5px;
  }
}

.video-item-referat strong {
  font-weight: bold;
}

.video-item-referat .video-item-content {
  padding: 0 17px;
  display: none;
}

.video-item-referat p {
  padding: 0 !important;
}

.video-referat-active {
  background: #dddddd;
  margin-bottom: 0 !important;
  position: relative;
}

.video-referat-active .video-item-content {
  background: #fff;
  color: #454545;
  display: block;
  padding-bottom: 10px;
}

.video-referat-active .video-item-play-wrap {
  margin-bottom: 10px;
}

.video-referat-active .video-bio {
  background-position: 100% -29px;
  color: #ffffff !important;
  position: relative;
  padding: 0 38px;
}

.video-referat-active .video-bio .number {
  background-position: 100% -29px;
  color: #454545 !important;
}

.video-referat-active .video-bio .bio {
  float: left;
  width: 33px;
  height: 29px;
}

.video-referat-active .video-item-play-wrap {
  display: block;
}

.video-referat-active .video-bio .name a {
  color: #454545 !important;
  font-weight: normal !important;
}
/* Bio Title */
.video-bio {
  display: inline-block;
  height: 29px;
  line-height: 29px;
  overflow: hidden;
  font-weight: bold;
  position: relative;
  width: 100% !important;
}

.video-bio {
  display: block;
}

.video-bio .number {
  float: left;
  width: 21px;
  text-align: right;
  .font-size(1.2);
  position: relative;
  top: 7px;
}

.video-bio .bio {
  float: left;
  width: 33px;
  height: 29px;

  a {
    &:before {
      position: relative;
      left: 10px;
    }
  }
}

.video-bio .bio a {
  text-decoration: none;
  width: 30px;
  display: block;
  height: 29px;
}

.video-bio .name {
  float: left;
}

.video-bio .name a {
  text-decoration: none;
}

.video-bio .name a:hover {
  text-decoration: underline;
}
/* Play Wrap */
.video-item-play-wrap {
  display: inline-block;
  overflow: hidden;
  line-height: 34px;
}

.video-item-play-wrap .tt {
  float: left;
  width: 53px;
}

.video-item-play-wrap .short {
  float: left;
}

.video-item-play-wrap .video-item-play {
  display: block;
  float: right;
}

/* Play Button */
.video-item-play {
  display: none;
  .font-size(1.1);
  margin: 0 0 9px 0;
  text-transform: uppercase;
  background-color: #a5a5a5;
  color: #fff;
  padding: 7px 20px;
  border-radius: 0;
  margin: 5px 0 0 0;
}

.video-item-active .video-item-play, .video-box-active .video-item-play {
  display: inline-block;

  .text-selection-inactive & {
    display: none;
  }
}
/*
 * Scroll
 */
.scroll-wrap {
  position: relative;
}

.scroll-up, .scroll-down {
  display: block;
  background: #f6f7f7 url(../../images/global/legacy/scroll_up.png) no-repeat 50% 0;
  height: 28px;
  margin: 2px 0;
  text-indent: -9999px;
  overflow: hidden;
}

.scroll-down {
  background-image: url(../../images/global/legacy/scroll_down.png);
}

.scroll-container {
  height: 452px;
  position: relative;
  width: 100% !important;
  overflow: hidden;
}

.videoTalksTab .scroll-container {
  height: 332px;
  position: relative;
  width: 100%;
}

.videoSearchResult .scroll-container {
  height: 895px;
  position: relative;
  width: 100%;
}
/*
 * TV Filters
 */
.tv-select {
  margin-bottom: 10px;
}

.tv-select label {
  display: inline !important;
}

.tv-sort label {
  margin-top: 0 !important;
}

.tv-sort select {
  float: left;
  width: 80% !important;
}
/*
 * Mediatek
 */

/* Video Intro */
.video-intro {
  border-left: 1px solid @color-tertiary-result-absent;
  margin-bottom: 8px;
  padding: 0;
  display: block;
  background-color: #fff;
  .make-row();
  margin: 0 0 20px 0;

  #videoouter {
    .make-md-column(6);
    padding: 0;
  }

  &.is-expanded {
    .video-about {
      width: 100% !important;
    }

    .video-description {
      width: 100%;
    }
  }
}


.video-intro:after {
  content: '.';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.video-description {
  display: block;
  .make-md-column(6);
  padding: 15px;
  overflow: hidden;
  line-height: 1.3333em;

  @media (min-width: @screen-lg-min) {
    padding: 25px;
  }

  @media only screen and (max-width: @screen-md-min) {
    padding: 38px 38px 0;
  }

  h2 {
    padding: 0 !important;
    margin: 0 0 1em 0;
    .font-size(2);
    .font-alt();
  }

  p {
    margin-bottom: 1.3333em;
    .font-size(1.6);
  }
}

/* Latest Video Slideshow */
.latest-video-wrap {
  position: static!important;
  padding: 10px 0;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.latest-video-scroll {
  height: 72px;
  overflow: auto;
  width: 9999px;
  position: relative;
}

.latest-video-scroll-inner {
  display: inline-block;
  overflow: hidden;
  position: absolute;
  left: 60px;
  top: 0;
}

.latest-video-scroll-inner {
  display: block;
}

.latest-video {
  float: left;
  display: inline;
  width: 347px;
  height: 72px;
  margin-right: 12px;
  background: url(../../images/global/legacy/latest-video-item.png) repeat-x 0 0;
  color: #fff;
  line-height: 1.25em;
}

.latest-video .image {
  float: left;
  height: 72px;
  width: 128px;
  overflow: hidden;
}

.latest-video .text {
  float: right;
  width: 209px;
  padding-top: 7px;
  .font-size(1.4);

  a {
    .font-size(1.6);
    color: #aeaeae;
  }
}

.latest-video a {
  color: #fff;
  text-decoration: none;
}

.vod-container {
  h2.trigger {
    margin: 20px 10px 18px;
    .font-size(2);
    .font-alt();
    color: @color-primary-dark;
  }

  .chooseDate__fieldset,
  .sortField {
    margin: 20px 38px;
  }

  a:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: 2px !important;
  }
}


.dark_player .video_top_panel {
  padding: 9px 11px !important;
  background: @color-primary-dark !important;
  .clearfix();
}

.dark_player .video_top_panel a:focus {
  outline-offset: 2px;
}


.dark_player .vclose {
  background: none !important;
  text-indent: initial !important;
  position: relative;
  top: -8px;
  right: 10px;

  svg {
    fill: @color-brand-300;
  }
}

.social-video {
  .video-list-spot{
    margin-bottom: 0!important;
  }
  &__field-edit {
    display: block;
    padding-left: 38px;
    padding-right: 38px;
    margin-bottom: 10px;

    &:first-of-type {
      padding-top: 30px;
    }
  }
}

.dark_player_tingdok-wrapper, .agenda-commitee {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;

  .social-video & {
    padding-top: 38px;
  }

  .tab-spot-a__wrapper.social-video & {
    @media (min-width: @screen-sm) {
      padding-top: 38px;
    }

    @media (min-width: @screen-md) {
      padding-top: 70px;
    }
  }

  @media (min-width: @screen-sm) {
    padding-top: 38px;
  }

  @media (min-width: @screen-md) {
    padding-top: 70px;
  }

  .device-mobile & {
    @media (min-width: @screen-md) {
      padding-top: 38px;
    }
  }
}

.dark_player .title_prev_lnk {
  background: url(../../images/global/legacy/mini-button-expand-left.png) no-repeat left center;
  padding-left: 20px;
  display: block;
  color: #fff !important;
  text-decoration: none;
}

.dark_player .title_prev_lnk:hover {
  background: url(../../images/global/legacy/mini-button-expand-left-hover.png) no-repeat left center;
  text-decoration: underline;
  color: #fff !important;
}

.dark_player .title_headline {
  padding-left: 24px;
  font-weight: normal;
  font-family: SceneAlt, serif;
  .font-size(2.5);
  margin-top: 20px;
  color: @color-primary-darker;
}

.dark_player .title_panel.committee-frontpage {
  display: table;
  width: 100%;
}

.dark_player .title_se_lnk {
  font-family: SceneAlt, serif;
  padding-right: 24px;
  padding-left: 25px;
  display: inline-table;
  margin-bottom: 10px;


  @media screen and (min-width: @container-tablet) {
    margin-top: 25px;
    float: right;
  }

  &:after {
    display: inline-block;
    font-size: 11px;
    margin-left: 5px;
    &:extend(.less-icon-extend);
    &:extend(.icon-ft_bullit_right:before);
  }
}

.dark_player .title_se_lnk:hover {
  text-decoration: underline;
}

.dark_player .video_top_panel {
  color: #fff;
  .font-size(1.4);
  padding: 10px 11px;
  background: #343433 url(../../images/global/legacy/video-top-panel.png) repeat-x 0 0;

  .top-info {
    display: inline-block;
    height: 15px;
    overflow: hidden;
  }
}

.dark_player .video_top_panel .video_date {
  color: #fff;
  display: inline-block;
  font-size: 13px;
}

.dark_player .video_top_panel .video_title {
  margin-right: 10px;
  font-size: 13px;
  display: none;
}

.dark_player .vclose {
  display: block;
  float: right;
  width: 17px;
  height: 17px;
  text-indent: -2000px;
  background: url(../../images/global/legacy/mini-button-close.png) no-repeat 0 0;
}

.dark_player .vclose:hover {
  background: url(../../images/global/legacy/mini-button-close-hover.png) no-repeat 0 0;
}

.dark_player_cntr {
  background: @color-primary-dark;
  font-size: 0; // We're adding font-size 0, because child elements have display: inline-block and that leaves a bit of margin, so childrens comibned width of 100% will overflow the container. Font-size 0 will fix that
  text-align: center;
  display: flex;
  justify-content: space-between;

  .social-video & {
    margin-top: -1px !important;
  }

  a:focus {
    outline-offset: 2px;
  }
}

.dark_player_cntr_prev,
.dark_player_cntr_next {
  width: 36%;
  padding: 10px;
  text-align: left;
}

.dark_player_cntr_prev {
  .icon-container {
    display: inline-block;
    position: relative;
    top: 1px;

    &:after {
      content: "\e631";
      display: inline-block;
      color: white;
      font-family: "ft-icons";
      padding-left: 5px;
      transform: rotate(180deg);
    }
  }
}

.dark_player_cntr_share,
.dark_player_cntr_cut {
  width: 25%;
  padding: 10px;

  .icon-container {
    display: inline-block;
    position: relative;
    top: 1px;
    right: 5px;
  }

  a {
    @media (max-width: @screen-xs) {
      display: inline-block;
      text-align: center;

      &.is-hidden {
        display: none;
      }
    }
  }
}

.dark_player_cntr_share {
  .icon-container {
    &:before {
      content: "\e643";
      display: inline-block;
      font-family: "ft-icons";
      color: white;
    }
  }
}

.dark_player_cntr_cut {
  .icon-container--slicetool svg {
    height: 13px;
  }

}

.dark_player_cntr_next {
  text-align: right;

  .icon-container {
    display: inline-block;
    text-align: center;
    position: relative;
    top: 1px;
    float: right;

    &:after {
      content: "\e631";
      color: white;
      font-family: "ft-icons";
      padding-left: 5px;
    }
  }
}

.dark_player_cntr_prev,
.dark_player_cntr_next,
.dark_player_cntr_share,
.dark_player_cntr_cut {
  display: inline-block;
  .font-size(1.3);
  font-family: SceneAlt, serif;

  @media (max-width: 380px) {
    vertical-align: top;
  }
}

.dark_player_cntr_share,
.dark_player_cntr_cut {
  text-align: center;
}

.dark_player_cntr_prev_lnk,
.dark_player_cntr_next_lnk,
.dark_player_cntr_share_lnk,
.dark_player_cntr_cut_lnk {
  text-decoration: none;
  color: #fff !important;
}

.dark_player_cntr_share_lnk:before,
.dark_player_cntr_cut_lnk:before {
  content: none;
  display: none;
}

.dark_player_cntr_prev_lnk:hover,
.dark_player_cntr_next_lnk:hover,
.dark_player_cntr_share_lnk:hover,
.dark_player_cntr_cut_lnk:hover {
  text-decoration: underline !important;
  color: #fff !important;
}

.dark_player_cntr_share_lnk:hover {
  //background: url(../../images/global/legacy/mini-button-share-hover.png) no-repeat left center;
}

a.embedPlayerBottomJumpButton:focus {
  outline-offset: 2px;
}

/* Arrows */
.go-left, .go-right {
  display: block;
  width: 60px;
  height: 92px;
  position: absolute;
  z-index: 1000;
  top: 32%;
  margin-top: -21px;
  cursor: default;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  &:extend(.less-icon-extend);

  &:before {
    font-size: 40px;
    color: #252525;
    opacity: 0.5;
    position: absolute;
    top: 50%;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  span {
    text-indent: -9999px;
    display: block;
    overflow: hidden;
  }
}

.go-left-active, .go-right-active {
  cursor: pointer;

  &:before {
    opacity: 1;
  }
}

.go-left {
  left: 0px;

  &:before {
    &:extend(.icon-ft_bullit_left:before);
    left: 20px;
  }
}

.go-right {
  right: 0px;

  &:before {
    &:extend(.icon-ft_bullit_right:before);
    right: 20px;
  }
}

/*
 * Latest movies
 */
.latestMovies {
  margin-bottom: 10px;
  .panel-body{
    position: relative;
  }
}

h2.trigger {
  margin: 0;
  padding: 0;
  background-position: right 0;
  font-size: 1.166em;
  line-height: 27px;
  color: @color-text-dark;

  &.closed {
    background-position: right -27px;
  }
}

h2.trigger span {
  display: block;
  padding: 0 10px 0 10px;
}

/*
 * All Videos
 */
.all-videos .tvSort {
  display: inline-block;
  margin-bottom: 16px;
}

.all-videos .tvSort {
  display: block;
}

.all-videos .tvSort:after {
  content: '.';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.all-videos .tvSort label {
  display: block;
  margin-bottom: 6px;
}

/* Video List */
.videos {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.videos {
  display: block;
  font-size: 0;
}

/* Video Item */
.video {
  display: inline-block;
  width: 50%;
  margin-bottom: 40px;
  .font-size(1.6);
  vertical-align: top;
  .clearfix();

  @media (max-width: @screen-md) {
    display: block;
    width: 100%;
  }
}

.video .image {
  float: left;
  width: 128px;
  height: 72px;
  overflow: hidden;
  margin-right: 14px;
}

.video .text {
  display: block;
  overflow: hidden;
  padding: 0 12px 0 0;
  .font-size(1.4);

  a {
    .font-size(1.6);
  }
}

.video a {
  color: @color-brand-200;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.video {
  .text {
    span {
      .font-size(1.6);
      color: @color-primary-dark !important;
    }
  }
}

.videos .active {
  color: #fff;
}

.videos .active a {
  color: #fff !important;
}

/* webtv banner */
.bannerContent {
  margin: 0 0 30px;
  background: #fff;
  overflow: hidden;
}

.minTvBanner {
  position: relative;
  height: 38px;
  width: 100%;
  min-width: 780px;
  background-image: url("/dist/Images/Global/legacy/vod/tvteaser_small.jpg");
  background-repeat: no-repeat;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: none !important;
}

.maxTvBanner {
  position: relative;
  width: 100%;
  min-width: 780px;
  background-color: #fff;
  background-repeat: no-repeat;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: none;

  @media screen and (max-width: 480px) {
    background: #e8eaea;
  }
}

.videoSlider {
  position: relative;
  left: 0;
  top: 0;
  color: #000000;
}

.videoItem {
  position: relative;
  float: left;
  background-repeat: repeat-x;
  margin: 0 !important;
  padding: 0 14px 0 0 !important;

  &.videoItem--no-float {
    float: none;
  }

  @media screen and (max-width: 768px) {
    .not-active & {
      float: none;
      width: 100% !important;
      .clearfix();
    }
  }

  @media screen and (max-width: 480px) {
    .not-active & {
      background: #ffffff;
    }

    .not-active & + & {
      margin-top: 10px !important;
    }
  }
}

.videoItem.active:hover {
  cursor: pointer;
}

.videoItemBg {
  left: 0px;
  top: 0px;
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  display: none;
  background: url("/dist/Images/Global/legacy/vod/videoItemOver.png") no-repeat 166px 0;
}

.videoTextOverlay {
  left: 5px;
  top: 71px;
  position: absolute;
  height: 22px;
  width: 158px;
  margin: 0;
}

.cancelled {
  background: url("/dist/Images/Global/legacy/vod/videoOverlayCancelled.png");
  background-repeat: no-repeat;
}

.direct {
  background: url("/dist/Images/Global/legacy/vod/videoOverlayDirect.png");
  background-repeat: no-repeat;
}

.later {
  background: url("/dist/Images/Global/legacy/vod/videoOverlayLater.png");
  background-repeat: no-repeat;
}

.videoTextOverlay span {
  padding: 0 10px 0 6px;
  font-family: Arial;
  font-weight: bolder;
  font-size: 12px;
  line-height: 22px;
  color: #000000;
}

.videoArea {
  position: relative;
  width: 198px;
  height: 110px;
  background-repeat: no-repeat;
  margin: 0;
  float: left;
  padding: 0;

  @media screen and (max-width: 768px) {
    .not-active & {
      display: inline-block;
      float: none;
      vertical-align: top;
    }
  }
}

.videoArea img {
  position: absolute;
  left: 0;
  top: 0;
  width: 198px;
  height: 110px;
}

.videoArea video {
  position: absolute;
  left: 0;
  top: 0;
  width: 198px;
  height: 110px;
  margin: 0;
  padding: 0;
  background-color: #333333;
}

.videoArea object {
  position: absolute;
  left: 0;
  top: 0;
  width: 198px;
  height: 110px;
  margin: 0;
  padding: 0;
  background-color: #333333;
}

.safari .videoArea video object {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 198px;
  height: 110px;
  margin: 0;
  padding: 0;
  background-color: #333333;
}

.textArea {
  position: absolute;
  left: 210px;
  top: 10px;
  width: 100%;
  margin: 0;
  padding: 0;
  display: inline;

  @media screen and (max-width: 768px) {
    .not-active & {
      display: inline-block;
      left: 0;
      padding: 10px;
      position: relative;
      top: 0;
      vertical-align: top;
      width: ~"calc(100% - 198px)" !important;
    }
  }

  @media screen and (max-width: 480px) {
    .not-active & {
      width: 100% !important;
    }
  }
}

.videoTitle {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  color: @color-primary-dark;
  margin: 0;
  .font-alt();
  .font-size(2.5);
  word-wrap: break-word;
}

.videoText {
  position: relative;
  width: 100%;
  height: 60px;
  margin: 0;
  padding: 0;
}

.teaserText, .teaserRollover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50px;
  margin: 5px 5px 5px 0;
  color: @color-primary-dark;
  padding: 0;
  .font-size(1.4);
  word-wrap: break-word;
  font-family: Arial;
}

.teaserRollover {
  display: none;
}


/* webtv banner */
