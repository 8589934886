@import "../Global/_PreventCompile/bootstrap/variables.less";
// default skin colors
@sw-color-primary: #0062ae; // Menu, links, sideværktøjer, knapper (100%)
@sw-color-primary-light: #8caad9; // Teaser på video og billeder, samt brugergenereret indhold (50%)
@sw-color-primary-lighter: #e9edf9; // Kategori- og indholdsside med top, herunder karrusel på siden (10%)

// general colors
@sw-color-light: #fff;
@sw-color-dark: #000;
@sw-color-red: #bb4752;
@sw-color-red-dark: #a6192e;

@sw-color-grey-light: #e5e5e5;
@sw-color-grey-lighter: #fefefe;
@sw-color-grey-lightest: #f0f0f0;

@sw-color-grey-dark: #adadad;
@sw-color-grey-darker: #2b2b2b;

@sw-color-background: @sw-color-grey-lighter;
@sw-color-border-light: @sw-color-grey-light;

@sw-color-text-light: #fff;
@sw-color-text-dark: @sw-color-grey-darker;
@sw-color-link: @sw-color-primary;

// for select-deropdown.jsx file
:root {
  --primary-color: @sw-color-primary;
  --grey-color: @sw-color-grey-lightest;
  --white-color: @sw-color-light;
  --dark-color: @sw-color-grey-darker;
  --error-color: @sw-color-red;
}

// font weight
@sw-font-regular: 400;
@sw-font-semi-bold: 500;
@sw-font-bold: 600;
@sw-font-black: 700;

// font sizes
@sw-font-base: 18px;

@sw-font-sm: @sw-font-base - 2; // 16
@sw-font-md: @sw-font-base + 2; // 20
@sw-font-lg: @sw-font-base + 6; // 24
@sw-font-xl: @sw-font-base + 10; // 28

// spacing
@spacing-xsmall: 12px;
@spacing-small: 15px;
@spacing-medium: 40px;

@section-spacing: 100px;
@section-spacing-small: 60px;

@spot-spacing: 60px;
@spot-spacing-small: 40px;

@padding-small: 25px;
@padding-medium: 40px;
@padding-large: 56px;

@focus-style: 2px dashed @sw-color-grey-darker;
@focus-offset: 2px;
