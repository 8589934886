.subsite-wide {
  .slider {
    width: 100%;
    position: relative;

    .slider__wrapper {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      overflow: hidden;
    }
    .slider__track {
      -webkit-overflow-scrolling: touch;
      scroll-snap-type: x mandatory; /* Chrome Canary */
      overflow-y: hidden;
      overflow-x: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      scroll-padding: 20px;

      &::-webkit-scrollbar {
        display: none;
      }

      display: flex;
      flex-wrap: nowrap;
    }
    .slider__slide {
      position: relative;
      scroll-snap-align: start;

      img,
      figure,
      video {
        padding: 0;
        margin: 0;
        width: 100%;
        height: auto;
      }
      figure {
        height: 100%;
      }
      .video {
        align-items: flex-start;
        height: auto;
      }
      > :first-child,
      &.slider__slide-offset + .slider__slide {
        margin-left: 0;
      }
      > :last-child {
        margin-right: 0;
      }
    }
    figcaption {
      font-size: 12px;
      text-align: center;
    }
    &.slider--no-controls {
      .slider__track {
        justify-content: center;
      }
      .slider__controls {
        display: none !important;
      }
    }
    .slider-button--disabled {
      opacity: 0;
    }

    @media (max-width: @screen-md-min) {
      .slider__btn svg {
        width: 25px;
      }
    }
    .slider__next-btn {
      border-radius: 0;
      text-align: right;
      width: auto;
      padding-left: 20px;
    }
    .slider__prev-btn {
      border-radius: 0;
      text-align: left;
      width: auto;
      padding-right: 20px;

      svg {
        transform: rotate(180deg);
      }
    }

    &.slider--has-grab-events {
      .slider__track {
        cursor: grab;
        &.slider--grabbing {
          cursor: grabbing;
          scroll-behavior: initial;
          scroll-snap-type: none;
        }
      }
    }
    .slider__controls {
      padding: 4px 20px 4px 40px;
      margin-top: 40px;
      min-height: 40px;
      display: flex;
      justify-content: space-between;

      @media (min-width: @screen-lg-min) {
        padding: 4px 20px;
      }
    }

    .slider__category-link {
      font-size: @sw-font-lg;
      font-weight: @sw-font-bold;
      color: @sw-color-dark;
      height: auto;
      margin-top: 40px;

      span {
        display: inline-block;
        height: 28px;
      }

      &:after {
        content: none;
      }

      svg {
        height: 15px;
        width: 10px;

        path {
          stroke: @sw-color-dark;
        }
      }
    }

    &.slider--offset {
      .slider__slide-offset {
        @media (min-width: @screen-md-min) {
          width: calc((100vw - 1095px) / 2) !important;
          margin: 0 10px !important;
          min-width: 20px;
        }
        margin: 0 7px !important;
        width: 1px !important;
        max-width: none !important;
        background-color: transparent !important;

        &:focus-visible {
          outline: 0;
        }
      }
      .slider__controls {
        position: relative;
        display: block;
        padding-top: 0;
        margin-top: 0;
        min-height: 0;

        @media (min-width: @screen-lg-min) {
          margin-left: calc(((100vw) - 1090px) / 2) !important;
        }

        &-buttons {
          float: right;
          margin-top: 20px;
        }
      }
      .slider__slide {
        max-width: 80vw;
        width: 80vw;
        flex-shrink: 0;
        margin: 0 10px;

        @media (min-width: @screen-sm-min) {
          max-width: 50vw;
          width: 50vw;
        }

        @media (min-width: @screen-md-min) {
          max-width: 320px;
          width: 320px;
          max-height: 455px;
          padding: 4px;
        }

        &:focus-visible {
          border: 0;
          outline: @focus-style;
        }
      }
    }

    &-category {
      &__header {
        display: flex;
        align-items: center;
        margin-bottom: 30px;

        h2 {
          margin: 0;
        }
      }

      &__icon {
        margin-right: 15px;
      }
    }

    &-mixed-card {
      @media (max-width: @screen-md-min) {
        .slider__track {
          padding-top: 140px;
        }

        .slider__slide:nth-child(2) {
          display: none;
        }
      }
    }
  }
}
