﻿.ftWebTvAboutFolketinget {
  color: @color-primary-dark;

  .video-wrapper-fluid {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    background-size: cover;

    @media (min-width: @screen-sm) {
      padding-top: 38px;
    }

    &.is-expanded {
      width: 100% !important;
    }
  }

  .panel-group {
    background: none;
  }

  .panel {
    border: none;
  }

  .panel-heading {
    margin: 20px 0 10px;
    background: none;

    .panel-title {
      .font-size(2.5);
      .font-alt();
      color: @color-primary-darker;
    }
  }

  .panel-body {
    padding: 20px 0 0 !important;
    background: white;

    .go-left,
    .go-right {
      &:before {
        color: @color-contrast-200;
        font-size: 25px;
      }
    }

    .go-left-active,
    .go-right-active {
      &:before {
        color: @color-secondary-dark;
      }
    }
  }

  .latestMovies .panel-body {
    padding: 20px 0 !important;
  }

  .all-videos {
    margin: 0;

    .boxColumn {
      margin: 0 38px;
    }

    .tvSort {
      label[for="meeting"] {
        margin: 0;
      }

      input[type="button"] {
        .btn-smaller();
        .font-size(1.8);
        margin: 0;
      }

      select {
        font-size: 14px;
        font-size: 1.4rem;
        font-family: Arial, sans-serif;
      }
    }

    label {
      color: @color-text;
      .font-size(1.6);
      .font-alt();
    }
  }



  select {
    padding: 10px;
    font-weight: 400;
    background-color: @color-secondary-lighter;
    border: 0;
    color: @color-secondary-darker;
    .font-size(1.4);
  }
}
