﻿@import "../Blue/Variables/_colors.less";

.print-qr {
  display: none;
}

body:not(.chamber-overview-page) {
  &:before {
    visibility: hidden;
    position: absolute;
    height: 0;
    width: 0;
    content: url("/images/folketinget-horizontal-red-2x.png");
  }
}

.subsite-wide body:before {
  content: "";
}

@media print {
  @page {
    size: auto;
    margin: 13mm 16mm 20mm 10mm;
  }
  
  /* Top margin on first page 13mm */
  @page :first {
    margin-top: 25mm    
  }

  * {
    cursor: initial;
    -webkit-print-color-adjust: exact !important;
    /* Chrome, Safari, Edge */
    color-adjust: exact !important;
    /* Firefox */
  }

  body:not(.chamber-overview-page) {
    padding: 0;
    overflow: auto;

    &:before {
      visibility: visible;
      content: "";
      background-image: url(/images/folketinget-horizontal-red-2x.png);
      width: 250px;
      height: 60px;
      background-repeat: no-repeat;
      background-position: 0 0;
      background-size: contain;
    }
  }

  #content {
    padding-top: 80px;
  }

  .chamber-overview-page #content {
    padding-top: 0;
  }

  .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .custom-radio input:checked ~ .icon:before {
    visibility: visible !important;
    color: @color-primary-function-bg !important;
    border: 5px solid @color-primary-function-bg !important;
  }

  .snap-content {
    position: relative;
  }

  .container {
    width: 100%;
    padding: 0;
    margin: 0;
  }

  /* added multisite printlayout */
  h1,
  .manchet,
  .paragraph-title,
  .paragraph-title,
  .tingdok .heading {
    padding-left: 0 !important;
  }

  img {
    width: auto !important;
    max-width: 100% !important;
  }

  .search-facet-print {
    display: table !important;
    width: 100%;
    margin-top: 20px;
    padding: 0 38px 10px 38px;

    h2 {
      padding-left: 0 !important;
      font-size: 14px !important;
      font-family: Arial, sans-serif !important;
      color: #000 !important;

      a {
        font-family: Arial, sans-serif !important;
        height: auto !important;
        color: #000 !important;

        &:after {
          display: none !important;
        }
      }

      span {
        width: 100% !important;

        &:after {
          content: ":" !important;
        }
      }
    }

    .panel-middle > .panel-heading {
      background-color: transparent !important;
      padding-right: 0;

      .facet-menu__show-more {
        padding-left: 10px;
      }
    }

    .panel-group {
      background-color: transparent;
      margin-bottom: 0 !important;

      .panel-middle {
        display: flex !important;
        border: 0 !important;
        padding: 10px 0;
        align-items: center;
      }
    }

    .result-list-spot-accordion__heading {
      float: left !important;
    }

    .result-list-spot-accordion__content {
      display: inline-block !important;
    }

    .list-facets__items {
      list-style-type: none !important;
    }

    .custom-checkbox {
      display: none;
    }

    .primary-search-print {
      margin-bottom: 10px;
    }

    label, a {
      font-family: Arial, sans-serif !important;
      margin-right: 10px;
      margin-bottom: 0;
      display: inline-table;
      color: @color-primary-function !important;
    }

    span {
      display: inline-table;
    }

    span.list-facets__label-count {
      display: none !important;
    }

    .list-facets__items.not-checked {
      display: none;
    }

    .list-facets__items.is-checked {
      float: left;
      margin: 0 !important;
    }

    .panel-group .panel-middle .panel-heading .panel-title a {
      line-height: 17px !important;

      span {
        padding: 0;
      }
    }

    .facet-menu__show-more,
    .facet-menu__show-less {
      margin-bottom: 0;
      top: 0 !important;
      padding-left: 10px;

      a:after {
        display: none !important;
      }
    }

    .panel-group .panel-middle .collapse .panel-body,
    .informationspot-d .panel-body {
      margin-bottom: 0 !important;
    }
  }

  .primary-menu,
  .site-wide-message-spot-b,
  .nav-pills.nav-wizard.wizard-steps,
  #globalcontent_0_pagelayoutbcontent_1_tabthree_confirmBookingError,
  #confirmBookingButtons,
  .header-tabs-wrapper {
    display: none;
  }

  .print-12,
  .content-container {
    width: 100%;
  }

  table {
    border-collapse: collapse !important;
    border: 1px solid #333 !important;
    border-spacing: 0 !important;

    th,
    td {
      border: 1px solid #333;
      padding: 10px !important;
      .font-size(1.2, @important: "!important");
      vertical-align: top;

      a {
        .font-size(1.2, @important: "!important");
      }
    }

    td {
      .font-regular("!important");
      .font-size(1.2, @important: "!important");

      a {
        .font-regular("!important");
        .font-size(1.2, @important: "!important");
      }
    }
  }

  .listespot-wrapper table tbody tr td a {
    .font-regular("!important");
    .font-size(1.2, @important: "!important");
  }

  .tingdok__caseinfospot-a__container,
  .tingdok .tingdok-normal,
  .tingdok__caseinfospot-a__container .tingdok-normal,
  .tingdok__caseinfospot-a__container .tingdok .tingdok-normal {
    font-size: 18px !important;
    font-size: 1.8rem !important;
    line-height: 20px !important;
    line-height: 2rem !important;

    a {
      line-height: 22px !important;
      line-height: 2.2rem !important;
    }
  }

  .tingdok__caseinfospot-a__container .tingdok .tingdok-normal .item_label,
  .tingdok__caseinfospot-a__container div.item_label strong,
  .tingdok__caseinfotopspot-a .tingdok-h4 {
    font-size: 20px !important;
    font-size: 2rem !important;
    line-height: 22px !important;
    line-height: 2.2rem !important;
  }

  .tingdok__caseinfotopspot-a__container {
    .tingdok-space + .tingdok-normal,
    h1 + .tingdok-normal,
    h4 + .tingdok-normal {
      font-size: 20px !important;
      font-size: 2rem !important;
      line-height: 22px !important;
      line-height: 2.2rem !important;
    }
  }

  .asc,
  .desc {
    &:after {
      display: none !important;
    }
  }

  .panel-group {
    .panel-heading {
      h4 {
        a {
          &:after {
            display: none !important;
          }
        }
      }
    }

    .panel-middle {
      .collapse {
        display: block;

        .panel-body {
          padding: 0;

          table {
            margin: 0;
          }
        }
      }

      .panel-title a.collapsed:after {
        content: "" !important;
      }
    }
  }

  .page-hero,
  .slide-in,
  .breadcrumb,
  .content-action-links,
  .information-page-spot-b {
    display: none;
  }

  a[href]:after {
    content: " " !important;
  }

  abbr[title]:after {
    content: "";
  }

  .btn-taxonomy {
    padding: 0 16px 10px 0;

    &:before {
      display: none;
    }
  }

  .print-qr {
    display: block;
    margin-top: 1em;
    height: 150px;
    overflow: hidden;
    position: relative;
    page-break-inside: avoid;

    img {
      max-width: inherit !important;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  .link-list-spot-a,
  .information-spot-a,
  .info-graph {
    padding: 0 !important;
  }

  .text-spot-a-wrapper {
    margin-bottom: 0 !important;
  }

  .content-area {
    padding-left: 15px;
    width: 100% !important;
  }

  .text-spot-a-wrapper .read-more {
    display: block !important;
    opacity: 1;
  }

  .wrapper {
    &.header-container {
      height: auto;
      margin-bottom: 2em;
      border-top: 0;

      .site-identity {
        margin-top: 0;
        padding-top: 0;

        .site-title {
          a {
            margin-top: 0;
          }
        }
      }

      .xs-menu {
        display: none !important;
      }
    }

    .middle-row {
      min-height: initial;
    }

    &.footer-container {
      .contact-spot {
        padding: 0;
      }
    }
  }

  /* Spot specifikke styles */
  .lexicon-spot {
    padding: 0 !important;
    margin: 0 !important;

    a.link:first-of-type {
      display: none;
    }
  }

  /* multisite printlayout */

  // Sets max width to full width
  .container,
  .factbox__text-container,
  .informations-spot-e__images__image-group__info {
    max-width: 100% !important;
  }

  // Remove padding left and right
  .text-spot-a-wrapper,
  .informations-spot-e__images__image-group__info,
  .two-col-spot .social-video__title,
  .three-col-spot .social-video__title,
  .site-ft h2.image-text-spot__title,
  .image-text-spot__manchet,
  .information-spot-a .links a {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  // Remove margin left and right
  .informations-spot-e__header,
  .informations-spot-e__images__image-group__headline,
  .informations-spot-e__images__image-group__text,
  .informations-spot-e__images__image-group__link {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  // Display none
  #CybotCookiebotDialog,
  .informations-spot-e:after,
  .informations-spot-e__navigation,
  .informations-spot-e__images__image-group__blob,
  .informations-spot-e__images__image-group.bx-clone,
  .topicspot-search,
  [class^="fancybox-"],
  .service-buttons,
  .download {
    display: none !important;
  }

  // avoid page break
  .factbox,
  .social-video,
  .link-list-spot-a-link,
  .question-answer-spot,
  .read-more > p {
    page-break-inside: avoid !important;
  }

  // adding padding to seperate link lists
  .factbox__link,
  .link-list-spot-a-link,
  .link-list-spot-a ul .link-list-spot-a-link {
    padding-bottom: 10px !important;
  }

  // Image carrousel
  .swiper-wrapper {
    flex-direction: column;
  }

  .informations-spot-e__images,
  .informations-spot-e__images__image-group__image {
    transform: none !important;
    width: 100% !important;

    img {
      width: 100% !important;
    }
    a {
      display: block;
    }
  }

  // hides auto generated link lists
  .informations-spot-e + .grid-link-list-a {
    display: none;
  }

  .informations-spot-e__images__image-group {
    width: 100% !important;
    border-bottom: 1px solid #ddd;
    margin-bottom: 30px;
  }

  .informations-spot-e .bx-wrapper .bx-viewport {
    height: auto !important;
  }

  .panel-group > .panel-middle:last-child {
    border-bottom: 0 !important;
  }

  .panel-group .panel-middle .collapse .panel-body,
  .informationspot-d .panel-body {
    margin: 0 0 20px !important;
  }

  .panel-group .panel-middle .panel-heading .panel-title {
    padding-left: 0 !important;

    a:after {
      content: "" !important;
    }
  }

  .bx-wrapper {
    margin: 0 auto !important;
  }

  div#ContentArea > * {
    > *,
    .lexicon-spot .header,
    .link-list-spot-a,
    .information-spot-a {
      padding: 0 !important;
      padding-left: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .two-col-spot,
  .three-col-spot {
    .col-sm-1,
    .col-sm-2,
    .col-sm-3,
    .col-sm-4,
    .col-sm-5,
    .col-sm-6,
    .col-sm-7,
    .col-sm-8,
    .col-sm-9,
    .col-sm-10,
    .col-sm-11,
    .col-sm-12 {
      border: 1px solid #ddd !important;
      padding: 5px !important;

      > * {
        padding: 0 !important;
      }

      .lexicon-spot,
      .link-list-spot-a,
      .lexicon-spot .header,
      .information-spot-a {
        padding: 0 !important;
      }
    }

    .col-sm-6 .social-video,
    .col-sm-4 .social-video {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    .link-list-spot-a {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .information-spot-a > *,
  .information-spot-a > div,
  .information-spot-a > p,
  .information-spot-a > * > *,
  .information-spot-a > * > div,
  .information-spot-a > * > p,
  .question-answer-spot .question-answer-spot__header,
  .question-answer-spot .question-answer-spot__body,
  .lexicon-spot > * {
    padding-left: 0 !important;
  }

  // video spot
  .video_top_panel,
  .video-tools,
  .dark_player_cntr {
    display: none;
  }

  .social-video .social-video__title .video-popup,
  .mwPlayerContainer .largePlayBtn {
    display: none !important;
  }

  // image-text-spot
  .image-text-spot__text-container {
    position: static !important;
  }

  .site-ft .two-col-spot,
  .site-ft .three-col-spot {
    .image-text-spot__main-link .image-text-spot__text-container {
      padding: 10px 0 !important;
    }
  }

  .information-spot-a .links a {
    margin: 0 !important;
    text-align: left !important;
  }

  // member overview
  .case-document > div > div > div > div {
    width: 100px !important;
    font-size: 1.2rem;
  }

  .case-document > div > div > div > div > div {
    padding: 5px 12.5px !important;
  }

  // accordion with link lists
  [id^="accordion"] {
    .collapse {
      display: block;
      height: auto !important;
    }

    .topicspot-result__icon-link .open,
    .topicspot-result__icon-link .closed {
      display: none !important;
    }
  }

  table.table-striped tr td {
    padding: 10px !important;

    a {
      border-bottom: 0 !important;
    }
  }

  [id^="fancybox-"] {
    display: none;
  }

  .link-list-spot-d a.link-primary[href]:before {
    content: "" !important;
    margin-right: 0 !important;
  }

  // hide url after links within rte content

  .scrollable.has-scroll div {
    overflow-x: inherit !important;
  }

  p.refer-template,
  table td,
  table th {
    border: 1px solid @color-secondary-light !important;
  }

  #sw-supchatwidget-btn {
    display: none;
  }

  .listespot-wrapper.ankiro .ankiro-searchform-header-title {
    padding-left: 35px;
  }

  .listespot-wrapper.ankiro .ankiro-search-options-row {
    padding: 15px 30px 0 !important;
    min-height: auto;
  }

  .listespot-wrapper.ankiro .ankiro-searchform .search-in-label {
    margin-bottom: 0;
  }

  .ankiro .ankiro-searchform .search-in-label:first-of-type {
    margin-left: 0;
  }

  .listespot-wrapper  .ankiro-results td.cellPic {
    padding: 5px 0 5px 11px !important;
  }

  .listespot-wrapper .ankiro-results td.cellPic img {
    max-width: 60px !important;
  }

  .ankiro .ankiro-searchform-form .advanced-fields-panel,
  .ankiro .ankiro-searchform-form .searchoptions .searchoptions-buttons {
    display: none !important;
  }

  .panel-collapse.collapse.searchoptions {
    display: block !important;
  }

  .ankiro .ankiro-searchform-header {
    padding: 0;
  }

  .ankiro .ankiro-searchform-header-title {
    padding: 0 38px;
    padding-left: 38px !important;
  }

  .listespot-wrapper tbody tr td:first-child a.search-result-link {
    color: @color-primary-function !important;
    padding-right: 30px;
  }

  .listespot-wrapper {
    margin: 0 0 !important;
  }

  .listespot-wrapper .listespot-wrapper {
    margin: 0 0 !important;
}

  .panel-middle .panel-body {
    .facet-menu__show-more,
    .facet-menu__show-less {
      a.btn-searchoptions {
        display: none !important;
      }
    }

    .btn-searchoptions--print {
      font-size: 14px !important;
      line-height: 16px !important;
      padding: 0 !important;
      display: inline;
    }
  }
}
