.simple-list {
  padding: 1em;
  margin: 5px 0;

  &--last {
    @media screen and (min-width: 768px) {
      border-left: 1px solid @color-tertiary-result-absent;
    }
  }

  &__title {
    font-size: inherit;
    font-weight: bold;
    padding-top: 0;
    padding-bottom: 0;
    margin: 0 0 .3em 0 !important;
  }

  &__item {
    padding-top: .3em;
    padding-bottom: .3em;
    margin: 0 !important;
  }
}
