﻿.quick-lookup-c {
  .header {
    position: relative;
  }

  &__error {
    color: red;
    .font-size(1.2);
  }

  &__help {
    font-size: 12px;
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      text-decoration: none;
    }
  }
}
