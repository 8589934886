﻿@import "../../Global/Components/nested-article.less";

.article-section {
  color: @color-primary-dark;
}

.nested-article {
  &__manchet {
    color: @color-primary-dark;
  }

  a {
    color: @color-primary-link;
    text-decoration: none;
    border-bottom: 1px solid @color-menu-selected-tab;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid @color-primary-link;
    }
  }

  a.front-arrow {
    border-bottom: 0;

    &:hover {
      border-bottom: 0;
      text-decoration: underline;
    }
  }

  .nested-title {
    color: @color-primary-dark;
    font-size: 3rem !important;
    line-height: 3.6rem !important;
    margin-bottom: 5px !important;
    margin-top: 20px !important;
  }

  .nested-article__manchet {
    padding-left: 0;
  }

  &__image img,
  img {
    max-width: 100%;
    height: auto;
  }

  &.col-sm-12 {
    h2 {
      .font-size(2);
      margin: 0 0 10px;
    }
  }

  h1 {
    padding: 0;
  }

  h2 {
    .font-size(2);
    margin-top: 20px !important;
    margin-bottom: 5px !important;
  }

  h3 {
    .font-size(1.8);
    margin-top: 20px;
    margin-bottom: 5px;
  }

  h4 {
    .font-size(1.4);
    margin-top: 10px;
    margin-bottom: 5px;
  }

  h5 {
    .font-size(1.3);
    margin-top: 10px;
    margin-bottom: 5px;
  }

  h6 {
    .font-size(1.2);
    margin-top: 10px;
    margin-bottom: 5px;
  }
  //List styling
  ul {
    li {
      list-style-type: disc;
    }
  }
}

.nested-article__title__container {
  @media only screen and (max-width: @screen-xs-max) {
    padding: 20px 38px;
  }
}

.nested-article.local-search {
  margin: 0 -25px;
  padding: 0;

  h1 {
    padding-left: 38px;
  }
}
