﻿// TODO: refactor - make it @color-1 and @color-2, and darken @color-1 by default if no @color-2 is passed in.

.striped-col-brand(@color: @color-brand-200, @backgroundColor: @color-brand-100, @height: 320px, @stripeSize: 1px) {
  @media(min-width:@screen-sm-min) {
    @brand-stripe: @backgroundColor;
    background-color: @brand-stripe;
    background-size: @stripeSize;
    background-image: -webkit-linear-gradient(0,@brand-stripe 50%, @color 50%);
    background-image: -moz-linear-gradient(0px 50%, @brand-stripe 50%, @color 50%);
    background-image: -ms-linear-gradient(0, @brand-stripe 50%, @color 50%);
    background-image: -o-linear-gradient(0, @brand-stripe 50%, @color 50%);
    background-image: linear-gradient(90deg, @brand-stripe 50%, @color 50%);
    height: @height;

    // ie9 fix
    html.ie9 & {
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAIAAAABCAYAAAD0In+KAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4NEVCRTA3OEVBMkQxMUUzQTZBQUI4MjY4NzlENjMyQiIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4NEVCRTA3OUVBMkQxMUUzQTZBQUI4MjY4NzlENjMyQiI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjg0RUJFMDc2RUEyRDExRTNBNkFBQjgyNjg3OUQ2MzJCIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjg0RUJFMDc3RUEyRDExRTNBNkFBQjgyNjg3OUQ2MzJCIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+dUdujwAAABRJREFUeNpiYGBg4P3//z8DQIABAAlBAwum2O8uAAAAAElFTkSuQmCC);
    }
  }
}