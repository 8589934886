﻿@import "../Global/filters.less";

.filter {
  background-color: @color-contrast-400;
  padding: 20px 0;
  margin: 0 0 10px;
  border: 1px solid @color-tertiary-result-absent;
  border-width: 1px 0 0;

  .site-ft & {
    padding: 0;

    .datepicker {
      border: 0;
      background-color: #fff;
      color: #696969;
      font-size: 1.4rem;
      margin-bottom: 10px;

      &:focus {
        outline: thin dotted !important;
        outline: 5px auto -webkit-focus-ring-color !important;
        outline-offset: -2px !important;
      }
    }

    .form-group-inline input {
      background-color: #fff;
      font-size: 16px;
      font-size: 1.4rem;

      &::placeholder {
        color: @color-secondary-darker;
      }

      &:focus {
        outline: thin dotted !important;
        outline: 5px auto -webkit-focus-ring-color !important;
        outline-offset: -2px !important;
      }
    }
  }

  &.standalone {
    border: 0;

    .filter-title {
      padding: 0 20px;
    }

    .filter-tags {
      padding: 0 20px;

      .input-group {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .content-area & {
    margin: 0 0 10px;
    padding: 20px 23px;
  }

  &.members {
    display: table;
    width: 100%;

    .member-item {
      padding-top: 15px;

      input {
        display: inline-block;
        border-radius: 0;
        width: 100%;
        height: 34px;
        line-height: 16.8px;
        line-height: 1.68rem;
        color: #696969;
        padding: 10px;
        min-height: 40px;
        font-family: Asap;
        font-family: Arial,sans-serif;
        font-weight: 400;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 14px;
        font-size: 1.4rem;
        box-shadow: 0 0 0 0;
        border: 1px solid #f0f2f2;
        background-color: #fff;
        background-image: none;

        &:focus {
          border-color: #66afe9;
          outline: 0;
        }
      }

      select {
        width: 100%;
        padding: 10px;
        min-height: 40px;
        font-family: Asap;
        font-family: Arial,sans-serif;
        font-weight: 400;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 14px;
        font-size: 1.4rem;
        line-height: 16.8px;
        line-height: 1.68rem;
        color: #696969;
        border: none;
      }

      label {
        font-family: Asap;
        font-family: SceneAlt,serif;
        font-weight: 400;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
        font-size: 1.6rem;
        line-height: 19.2px;
        line-height: 1.92rem;
        color: #252525;
      }

      .btn-action {
        border-radius: 0;
        min-width: 165px;
        padding: 10px 50px 10px 50px;
        font-family: Asap;
        font-family: SceneAlt,serif;
        font-weight: 400;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        font-size: 1.8rem;
        line-height: 21.599999999999998px;
        line-height: 2.16rem;
        color: #fff;
        background-color: @color-primary-function-bg;
        display: table;
        border: 0;
        cursor: pointer;
        margin: auto;

        &:hover {
          background-color: #ce4c70;
        }
      }
    }
  }

  &.text-spot-filter {
    background-color: transparent;
    padding: 0;

    .filter-title {
      margin-bottom: 10px;
    }

    .filter-tag {
      margin-right: 0;
    }
  }

  &.filter-adjacent {
    margin: 2px 0;
  }

  &.filter-adjacent--last {
    margin-bottom: 10px;
  }

  .date-picker {
    background-color: #fff;
  }

  .filter-title {
    .font-alt();
    .font-size(@type-size-base);
    margin-bottom: 15px;
  }

  .filter-tag {
    display: inline-block;
    margin: 0;

    .btn-taxonomy {
      background: #fff;
      color: @color-primary-dark;
      padding-top: 10px;
      padding-bottom: 10px;
      .font-alt();
      border-radius: 0;
      margin-right: 0;
      letter-spacing: 1px;

      &:before {
        border-radius: 0;
        color: white !important;
      }

      &:hover {
        //color: @color-primary-function;
        text-decoration: underline;

        &:before {
          //color: @color-primary-function !important;
          background: @color-menu-hover;
        }
      }


      &.selected {
        &:before {
          background-color: @color-primary-function-bg;
          color: #fff !important;
        }
      }
      /*&:before {
        display: none;
      }

      &:after {
        display: inline-block;
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        font-size: 11px;
        float: left;
        margin: 2px 0 0 -15px;
      }*/
    }

    &.hide {
      //display: inline-block !important;
    }

    &.filter-tag-all {
      margin: 0;
      display: inline-block;

      .btn-taxonomy {
        > span:first-child {
          .font-bold();
        }
      }
    }
  }

  .filter-show-more,
  .filter-show-less {
    &:extend(a.link-with-arrow);
    //display: none;
    .font-alt();
    .font-size(1.2);
    letter-spacing: 1px;
    color: @color-primary-function;

    &:hover {
      border-bottom: 1px solid @color-primary-link-hover;
    }

    .icon-pil-op {
      -ms-transform: rotate(270deg); /* IE 9 */
      -webkit-transform: rotate(270deg); /* Chrome, Safari, Opera */
      transform: rotate(270deg);
      display: inline-block;

      &:before {
        content: "\e631";
      }
    }

    .icon-pil-ned {
      -ms-transform: rotate(90deg); /* IE 9 */
      -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
      transform: rotate(90deg);
      display: inline-block;

      &:before {
        content: "\e631";
      }
    }

    > span {
      .font-size(@type-size-xs);
      margin-left: 5px;

      :hover & {
        text-decoration: none;
      }
    }
  }
}
