﻿.parliamentaryparties {
  padding: 35px;
  background-color: #fff;
	a {
			&:before {
				display: inline-block;
				&:extend(.less-icon-extend);
				&:extend(.icon-ft_bullit_right:before);
				font-size: 11px;
				margin: 4px 5px 0px 5px;
    			vertical-align: top;
			}
	}
}
