﻿@import "../../Global/Components/ftMembersSearch.less";

.ftMembersSearch {
  &__maps {

    &__denmark, &__grfr {

      h2 {
        .font-size(2.5);
        .font-alt();
      }
    }
  }
}

.ftMembersSearch__searchfields {
  padding: 30px 23px;

  h2 {
    display: none;
  }

  .boxContent {
    .pic {
      @media (min-width: @container-tablet) {
        min-height: 251px;
        overflow: hidden;
      }

      padding: 30px 0 26px;
    }
  }

  fieldset {
    .button {
      @media (max-width: @container-md) {
        display: inline-block !important;
      }

      &:focus {
        .tab-focus();
      }
    }

    select {
      background: @color-secondary-lighter;
    }
  }
}
