@font-face {
  font-family: "Founders Grotesk";
  src: url("../../Fonts/SubsiteWide/founders-grotesk-medium.woff2")
      format("woff2"),
    url("../../Fonts/SubsiteWide/founders-grotesk-medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("../../Fonts/SubsiteWide/founders-grotesk-regular.woff2")
      format("woff2"),
    url("../../Fonts/SubsiteWide/founders-grotesk-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Founders Grotesk";
  src: url("../../Fonts/SubsiteWide/founders-grotesk-semibold.woff2")
      format("woff2"),
    url("../../Fonts/SubsiteWide/founders-grotesk-semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
