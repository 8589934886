﻿@import "../styles.less";

.list-documents {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    border-top: 1px solid @color-contrast-300;
    padding: 10px 0;

    &.title {
      .font-size(@type-size-lg);
      border: 0;
    }

    a {
      display: inline-block;
      height: 30px;
      .font-bold();
      color: @color-brand-200;
      .font-size(@type-size-md);      
      line-height: 30px !important;

      span {
        position: static;
        top: 0;
      }

      &:hover {
        text-decoration: none;
        > span {
          text-decoration: underline;
        }
      }

      &:before {
        &:extend(.less-icon-extend);

        float: left;
        display: inline-block;
        color: @color-brand-200;
        font-size: 25px;
        height: 25px;
        line-height: 25px !important;
        margin: 0 10px 0 5px;
      }

      &.pdf {        
        &:before {
          text-decoration: none;
          &:extend(.icon-pdf:before);
        }
      }

      &.video {
        &:before {
          &:extend(.icon-video:before);
        }
      }
    }
  } 
}