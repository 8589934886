.subsite-wide {
  .intro {
    &:extend(.subsite-wide-container);
    &:extend(.spot--margin-bottom);

    .intro__button {
      margin-top: 40px;
    }
    .intro__header img {
      display: none;
    }
    .intro__subheader {
      max-width: 715px;
      font-size: @sw-font-md;
      line-height: 1.2;
      padding: 0;

      @media (min-width: @screen-md) {
        font-size: @sw-font-lg;
        line-height: 1.4;
      }
    }
  }
  .intro--centered {
    text-align: center;
    .intro__subheader {
      margin: 0 auto;
    }
  }
}
