.subsite-wide {
  .file-modal {
    background-color: @sw-color-light;
    width: 100%;
    max-width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2000;
    overflow-y: auto;

    @media (min-width: @screen-md-min) {
      max-width: 642px;
      max-height: 600px;
      min-height: 580px;
      height: auto;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 6px 10px @sw-color-grey-dark;
    }

    &__header {
      background-color: @sw-color-primary-lighter;
      background-color: var(--sw-color-primary-lighter, @sw-color-primary-lighter);
      padding-bottom: @padding-medium + 8;
      padding-left: @padding-large;
      height: 143px;
      display: flex;
      align-items: center;

      h2 {
        margin-bottom: 0 !important;
      }
    }

    &__content {
      margin-top: -(@padding-medium + 8);
      position: relative;
      padding-top: @padding-medium + 8;
      text-align: center;

      p:not(.dropzone-text) {
        font-size: @sw-font-lg;
      }
    }

    &__gallery-tab-content,
    &__upload-tab-content {
      color: @sw-color-text-dark;
      background-color: @sw-color-grey-lighter;

      &.tab--active {
        color: @sw-color-primary;
        color: var(--sw-color-primary, @sw-color-primary);

        .file-modal__gallery-tab,
        .file-modal__upload-tab {
          border: none;
          border-bottom: 1px solid @sw-color-grey-lighter;
        }
      }
    }

    &__gallery-tab,
    &__upload-tab {
      background-color: @sw-color-grey-lighter;
      border: none;
      border-bottom: 1px solid @sw-color-primary-lighter;
      border-bottom: 1px solid var(--sw-color-primary-lighter, @sw-color-primary-lighter);
      height: 48px;
      width: 240px;
      position: absolute;
      top: 2px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px 5px 0 0;
      cursor: pointer;

      @media (max-width: @screen-md-min) {
        width: calc(50% - 1px);
        padding: 0px @padding-small;
      }

      &:focus-visible {
        outline-offset: -4px;
      }
    }

    &__upload-tab {
      left: 242px;

      @media (max-width: @screen-md-min) {
        left: calc(50% + 1px);
        border-left: 1px solid @sw-color-primary-lighter;
        border-left: 1px solid var(--sw-color-primary-lighter, @sw-color-primary-lighter);
      }
    }

    &__dropzone {
      padding: @padding-medium;

      .dropzone-button {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .dropzone-button-svg {
        width: 77px;
        height: 77px;
        background-size: 77px 77px;
        background-position: center;

        svg path {
          stroke: @sw-color-primary;
          stroke: var(--sw-color-primary, @sw-color-primary);
          fill: none !important;
        }
      }

      .dropzone-text {
        color: @sw-color-primary;
        color: var(--sw-color-primary, @sw-color-primary);
        margin-bottom: 30px;
      }
    }

    .close-modal {
      position: absolute;
      right: @padding-large;
      top: 32px;
      width: 32px;
      height: 32px;
      opacity: 1;
      cursor: pointer;
      border: none;
      background: transparent;
    }
    .close-modal:hover {
      opacity: 0.3;
    }
    .close-modal:before,
    .close-modal:after {
      position: absolute;
      left: 15px;
      top: 6px;
      content: " ";
      height: 20px;
      width: 2px;
      background-color: @sw-color-grey-darker;
    }
    .close-modal:before {
      transform: rotate(45deg);
    }
    .close-modal:after {
      transform: rotate(-45deg);
    }

    &__container {
      overflow: auto;
      /* width */
      &::-webkit-scrollbar {
        width: 6px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 3px #f7f7f7;
        border-radius: 3px;
        background-color: #f7f7f7;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 8px;
      }

      @media (min-width: @screen-md-min) {
      }
    }

    &__file-gallery {
      padding: @padding-small;
      border-top: 1px solid @sw-color-primary-lighter;
      border-top: 1px solid var(--sw-color-primary-lighter, @sw-color-primary-lighter);

      &-images {
        display: flex;
        flex-wrap: wrap;
      }

      .gallery-image {
        width: 50%;
        padding: 0;
        border: 1px solid white;
        position: relative;
        padding-bottom: 28.125%;

        @media (min-width: @screen-sm-min) {
          width: 33.3333%;
          padding-bottom: 21.125%;
        }

        img {
          max-width: 100%;
          width: 100%;
          height: 100%;
          object-fit: cover;
          position: absolute;
          top: 0;
          left: 0;
        }

        &.inactive-gallery-image {
          opacity: 0.5;
        }
      }
    }

    &__file-upload {
      display: flex;
      justify-content: center;
      position: relative;
      margin-bottom: @spacing-medium;

      label {
        &:focus-within {
          outline: @focus-style;
          outline-offset: @focus-offset;
        }

        * {
          cursor: pointer;
        }
      }

      input[type="file"] {
        opacity: 0;
        position: absolute;
        height: 100%;
        width: 100%;
      }

      &.input--error {
        margin-bottom: 0;

        + .input-error-message {
          margin-top: 10px;
          margin-bottom: @spacing-medium;
          padding-left: @spacing-small;
          font-weight: @sw-font-regular;
          color: @sw-color-red;
          font-size: @sw-font-md;
        }
      }
    }

    // IMAGE CROP
    &__image-crop {
      padding: @padding-large;

      @media (max-width: @screen-md-min) {
        padding: @padding-large @spacing-small;
      }
    }

    .ReactCrop {
      margin-top: @spacing-medium;

      @media (max-width: @screen-md-min) {
        .ReactCrop__drag-handle {
          width: 44px;
          height: 44px;
        }

        .ReactCrop__drag-handle::after {
          width: 14px;
          height: 14px;
        }

        .ReactCrop__drag-handle.ord-se::after {
          width: 35px;
          height: 35px;
        }
      }
    }

    &__image-crop-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding-top: @padding-large;

      button {
        max-width: 200px;
        padding-left: @padding-small;
        padding-right: @padding-small;
      }
    }

    &__image-zoom {
      padding: @spacing-small 0 0;

      @media (min-width: @screen-sm-min) {
        padding: @spacing-small @padding-large 0;
      }

      label {
        margin-bottom: @spacing-small;
        font-size: @sw-font-base;
      }

      .image-zoom-input {
        -webkit-appearance: none;
        width: 100%;
        height: 2px;
        border-radius: 1px;
        border: 0 !important;
        padding: 0 !important;
        background-color: rgba(0, 0, 0, 0.3) !important;
        outline: none;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 27px;
          height: 27px;
          border-radius: 50%;
          background: @sw-color-dark;
          cursor: pointer;
        }

        &::-moz-range-thumb {
          width: 27px; /* Set a specific slider handle width */
          height: 27px; /* Slider handle height */
          background: @sw-color-dark; /* Green background */
          cursor: pointer; /* Cursor on hover */
        }
      }
    }

    &__overlay {
      width: 100vw;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1999;
    }
  }
  /* width */
  .file-modal::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  .file-modal::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px #f7f7f7;
    border-radius: 3px;
    background-color: #f7f7f7;
  }

  /* Handle */
  .file-modal::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 8px;
  }
}
