﻿.ftMembersAddresses {
  margin: 0;

  .emailHolder {
    .email1, .email2, .email3 {
      float: left;
      cursor: pointer;
      color: @color-primary-link;
    }
  }

  &.listespot-wrapper tbody tr:hover {
    cursor: default;
  }

  table {
    margin: 15px 0;

    th .desc:hover {
      color: #ce4c70;
    }

    tbody {
      tr {
        td {
          a:not(.ftMembersAddresses__link) {
            color: @color-primary-link !important;
          }

          &.cellPic {
            padding: 10px 20px !important;
          }

          .ministerweb ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }
          &:nth-of-type(2) {
            a {
              &:before {
                display: inline-block;
                &:extend(.less-icon-extend);
                &:extend(.icon-ft_bullit_right:before);
                font-size: 11px;
                position: relative;
                margin-right: 5px;
                margin-top: 3px;
                @media (min-width: @screen-md) {
                  content: '';
                }
              }
            }
          }
        }
      }
    }
  }

  .inline-icon-text {
    display: inline-block;
  }

  .inline-icon-text:hover {
    color: #bb506d;
    text-decoration: underline;
  }

  .ftDocumentBasket__container__docBtns__docWork {
    margin: 0 15px 0 0;
  }
}

.ftMember{
  margin-bottom: 20px;
}

.member-search-party{
  tbody tr:hover {
    cursor: pointer;
  }
}