.factbox {
  position: relative;

  .factbox__image-container:not(.scExperienceEditor) {
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }

  .scExperienceEditor {
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .factbox__link {
    color: @sw-color-primary;
    color: var(--sw-color-primary, @sw-color-primary);

    &.stretched-link {
      padding-left: 0 !important;

      &:before {
        position: absolute !important;
        top: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        z-index: 1 !important;
        pointer-events: auto;
        content: "";
        background-color: rgba(0, 0, 0, 0);
      }

      &:after {
        font-family: "ft-icons";
        display: inline-block;
        font-size: 11px;
        margin: 0px 6px 0 6px;
        content: "\e631";
      }
    }
  }

  &.factbox--golden-ratio {
    .factbox__image-container__sideimage {
      display: none;
    }

    @media (min-width: @screen-md) {
      display: flex;

      .factbox__image-container {
        flex-basis: 33.3333%;
        min-height: 340px;

        img {
          object-fit: cover;
          object-position: center;
          height: 100%;
        }
      }

      .factbox__text-container {
        flex-basis: 66.6666%;
        padding-top: 0;

        .content-rte {
          max-width: 715px;
          margin: 0;
          padding: 32px;
          margin-bottom: 0 !important;
          display: flex;
          flex-direction: column;
          height: 100%;
          line-height: 1.2;

          @media (min-width: @screen-md) {
            padding: 72px 60px;
          }

          a {
            font-weight: @sw-font-regular;
            color: @sw-color-primary;
            color: var(--sw-color-primary, @sw-color-primary);

            &:hover {
              border-bottom-color: @sw-color-light;
              border-bottom-color: var(--sw-color-primary-light, @sw-color-light);
            }
          }
        }

        .read-more {
          flex-grow: 1;
        }
      }

      .factbox__image-container__topimage {
        display: none;
      }

      .factbox__image-container__sideimage {
        display: block;
      }
    }
  }
}

.subsite-wide {
  .factbox {
    &:extend(.subsite-wide-container);
    &:extend(.spot--margin-bottom);

    .factbox__text-container {
      width: 100%;
      max-width: 100%;
      padding-top: 0;

      .content-rte {
        margin: 0;
        padding: 32px;
        margin-bottom: 0 !important;
        font-size: @sw-font-base;
        display: flex;
        flex-direction: column;
        height: 100%;
        line-height: 1.2;

        @media (min-width: @screen-md) {
          padding: 72px 60px;
        }

        a {
          font-weight: @sw-font-regular;
          color: @sw-color-primary;
          color: var(--sw-color-primary, @sw-color-primary);

          &:hover {
            border-bottom-color: @sw-color-light;
            border-bottom-color: var(--sw-color-primary-light, @sw-color-light);
          }
        }
      }

      .read-more {
        flex-grow: 1;
      }

      .text-spot-a-wrapper ul.link-list li a:before {
        margin: 6px 0 0 -15px;
      }

      .text-spot-a-wrapper {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: inherit;
        }
      }
    }

    .factbox__title {
      font-weight: @sw-font-semi-bold;
      line-height: 1.2 !important;
      font-size: 28px !important;
      margin-bottom: 24px !important;

      @media (min-width: @screen-md) {
        font-size: 48px !important;
        margin-bottom: 40px !important;
      }
    }

    .factbox__link {
      padding-left: 0 !important;

      &:not(.stretched-link):before {
        content: none !important;
        width: 0;
        margin: 0 !important;
      }

      &.stretched-link {
        &:before {
          position: absolute !important;
          top: 0 !important;
          right: 0 !important;
          bottom: 0 !important;
          left: 0 !important;
          z-index: 1 !important;
          pointer-events: auto;
          content: "";
          background-color: rgba(0, 0, 0, 0);
        }
      }

      &:after {
        font-family: "ft-icons";
        display: inline-block;
        font-size: 11px;
        margin: 0px 6px 0 6px;
        content: "\e631";
      }
    }

    &.factbox--golden-ratio {
      .factbox__text-container {
        padding-top: 0;
        .content-rte {
          font-size: @sw-font-base;
        }

        .text-spot-a-wrapper ul.link-list li a:before {
          margin: 6px 0 0 -15px;
        }
      }
    }
  }

  .two-col-spot,
  .three-col-spot {
    div.factbox .factbox__text-container .content-rte {
      padding: 32px !important;

      @media (min-width: @screen-md) {
        padding: @spacing-medium !important;
      }
    }
  }

  .forum-post {
    .factbox {
      .factbox__title {
        font-weight: @sw-font-semi-bold;
        line-height: 1.2 !important;
        font-size: 28px !important;
        margin-bottom: 24px !important;

        @media (min-width: @screen-md) {
          font-size: 28px !important;
          margin-bottom: @spacing-medium !important;
        }
      }
    }
  }

  .forum-category-content .factbox {
    padding-right: 0;
    padding-left: 0;
  }
}
