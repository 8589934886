﻿/* EuMap Body */
@import "../styles.less";

@color-015: #f3f3f3;
@color-005: #dedfd9;

@color-013: #666;
@color-012: #393939;

@color-legend-1: #5a5935;
@color-legend-2: #afad00;
@color-legend-3: #b5014d;
@color-legend-4: #640024;


.eu-map-app {
  position: relative;
  background-color: @color-015;
  transition: all .5s cubic-bezier(0.175, 0.885, 0.32, 1.275);


  .tab-pane.active canvas {
    width: 100% !important;
    height: auto !important;
  }


  .btn,
  .eu-map-subheader .dropdown-button,
  .eu-compare-subheader .dropdown-button,
  .eu-country-subheader .dropdown-button {
    border: 0;
    padding: 3px 9px;
    color: #868781;
    text-decoration: none;
    .font-size(@type-size-md);
    background: @color-015;
    #gradient .vertical(#f4f4f4, #e2e2e2);
    border-radius: 2px;
    box-shadow: 1px 1px 1px rgba(0,0,0,.4);

    &:hover {
      #gradient .vertical(darken(#f4f4f4, 2%), darken(#e2e2e2, 2%));
    }
  }

  .dropdown-button-country {
    width: 90px;
    max-width: 90px;
    min-width: 90px;
  }

  .eu-map-subheader .dropdown-button,
  .eu-compare-subheader .dropdown-button,
  .eu-country-subheader .dropdown-button {
    max-width: 100px;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .explanation-container {
    float: right;
    margin: 10px 0;
    padding-right: 38px;
  }

  .eu-map-body {
    .explanation-container {
      margin: 20px 0;
    }
  }

  .graph-sorting,
  .currency-sorting {
    float: right;
    margin-bottom: 10px;

    .btn-sort {
      padding: 3px 6px;
      color: @color-013;
      .font-size(@type-size-sm);
      border-radius: 3px;
      box-shadow: none;
      background: transparent;

      &.btn-sort-active {
        border: 1px solid #a7a7a0;
        background-color: #e7e7de;
        box-shadow: 1px 1px 1px rgba(0,0,0,.1);
      }
    }
  }

  .currency-sorting {
    line-height: 35px;
    margin-left: 40px;

    .btn-sort {
      &.btn-sort-active {
        box-shadow: none;
      }
    }
  }

  .timeline-slider-wrapper {
    display: table;
    width: 100%;
    padding: 0 38px;
  }

  .map-app-header {
    height: 38px;
    position: relative;
    background-color: @color-012;
    border-top: 3px solid @color-013;
    overflow: hidden;
  }

  .site-name {
    float: left;
    .font-size(@type-size-xxl);
    line-height: 38px;
    color: @color-text-light;
    font-weight: 100;
    margin-left: 10px;
  }

  .main-menu {
    float: right;
    text-align: right;
    margin-right: 20px;
  }

  .menu-group {
    display: inline-block;

    .dropdown-button {
      text-transform: uppercase;

      .indicator-caret {
        display: inline-block;
        margin-left: 5px;
        margin-bottom: 2px;
        .triangle(down, 5px, #ffffff);
      }

      &.active {
        .indicator-caret {
          display: inline-block;
          margin-left: 5px;
          margin-bottom: 2px;
          .triangle(up, 5px, #ffffff);
        }
      }
    }
  }

  .eu-map-subheader {
    height: 35px;
    margin-bottom: 30px;
    background-color: @color-005;
    border-bottom: 1px solid @color-005;
    overflow: hidden;
    padding: 0 38px;

    @media only screen and (min-width: @screen-sm) {
      margin-bottom: 0;
    }
  }

  .eu-map-subheader > .subheadline {
    line-height: 35px;
    float: left;
  }

  .eu-country-subheader > .subheadline {
    line-height: 35px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    float: left;
  }

  .eu-map-body {
    position: relative;
    padding-bottom: 50px;  
  }

  .map-description-headline {
    font-family: SceneAlt, serif;
    font-size: 20px;
    font-weight: 500;
    padding: 0 38px;
    margin-bottom: 20px;
    color: @color-013;

    @media only screen and (min-width: @screen-sm) {
      position: absolute;
      top: 60px;
      left: 0;
    }
  }

  .map-description-text {
    width: 100%;
    max-width: 400px;
    padding: 0 38px;
    margin-bottom: 20px;
    color: @color-013;

    @media only screen and (min-width: @screen-sm) {
      position: absolute;
      top: 95px;
      left: 0;
    }
  }
  /* SVG Map Container */
  .eu-map-svg-box {
    display: block;
    width: 100%;
    height: 500px;
    position: relative;
    float: right;
    top: 0;
    right: 0;

    @media only screen and (min-width: @screen-sm) {
      width: 50%;
    }

    g {
      fill: #fff;

      &.non-members,
      &.legend-id-0 {
        fill: #bbbbb4;
        cursor: default;
      }
    }
  }
  /* EuMap Legend */
  .eu-map-legend-box {
    background-color: @color-text-light;
    padding: 25px 15px 15px 15px;
    margin-bottom: 20px;
    border-radius: 2px;
    color: @color-013;
    box-shadow: 1px 1px 1px rgba(0,0,0,.1);
    width: 100%;

    @media only screen and (min-width: @screen-sm) {
      margin-left: 38px;
      position: absolute;
      width: 300px;
      top: 45%;
      left: 0;
    }

    .btn-compare {
      display: block;
      font-family: SceneAlt, serif;
      margin: 20px auto 0 auto;
      width: 180px;
    }
  }

  .legend-list {
    padding: 0;
    list-style: none;
  }

  .legend-list-item {
    .font-size(@type-size-md);
    line-height: 2rem;
    margin-bottom: 10px;
  }

  .legend-color {
    width: 20px;
    height: 20px;
    border-radius: 2px;
    margin: 0px 15px 0 0;
    float: left;
    cursor: pointer;
    transition: fill 1s ease;
  }

  .legend-color.legend-id-1 {
    background-color: @color-legend-1;
    fill: @color-legend-1;
  }

  .legend-color.legend-id-2 {
    background-color: @color-legend-2;
    fill: @color-legend-2;
  }

  .legend-color.legend-id-3 {
    background-color: @color-legend-3;
    fill: @color-legend-3;
  }

  .legend-color.legend-id-4 {
    background-color: @color-legend-4;
    fill: @color-legend-4;
  }
  /* Years Slider */
  .years-slider-container {
    clear: both;
    width: 100%;
    position: relative;
    background: @color-005;
    border-radius: 3px;
    margin: 0 auto 20px auto;
    #gradient .vertical(#f4f4f4, #e2e2e2);
    border-radius: 2px;
    top: 20px;
    box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.4);
    padding: 40px 20px 20px 20px;

    .ui-slider-tick-mark {
      display: inline-block;
      width: 1px;
      background: #fff;
      height: 4px;
      position: absolute;
      cursor: pointer;
    }

    .ui-slider-tick-legend {
      color: rgba(88,88,83,1);
      display: inline-block;
      position: absolute;
      top: 20px;
      .font-size(@type-size-sm);
      cursor: pointer;
    }

    .ui-slider {
      position: relative;
      text-align: left;
      background-color: rgba(88,88,83,1);
      width: 95%;
      margin-left: 15px;
      cursor: pointer;
      margin-top: 5px;

      .ui-slider-handle {
        position: absolute;
        z-index: 2;
        cursor: default;
        -ms-touch-action: none;
        touch-action: none;
      }

      .ui-slider-range {
        position: absolute;
        z-index: 1;
        font-size: .7em;
        display: block;
        border: 0;
        background-position: 0 0;
      }

      .ui-slide-custom-handle {
        width: 50px;
        line-height: 16px;
        .font-size(@type-size-xs);
        text-align: center;
        margin-left: -25px;
        bottom: -6px;
        background: rgb(88,88,83); /* Old browsers */
        background: -moz-linear-gradient(left, rgba(88,88,83,1) 0%, lighten(rgba(123,124,118,1), 5%) 50%, rgba(88,88,83,1) 100%); /* FF3.6+ */
        background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(88,88,83,1)), color-stop(50%,lighten(rgba(123,124,118,1), 5%)), color-stop(100%,rgba(88,88,83,1))); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(left, rgba(88,88,83,1) 0%,lighten(rgba(123,124,118,1), 5%) 50%,rgba(88,88,83,1) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(left, rgba(88,88,83,1) 0%,lighten(rgba(123,124,118,1), 5%) 50%,rgba(88,88,83,1) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(left, rgba(88,88,83,1) 0%,lighten(rgba(123,124,118,1), 5%) 50%,rgba(88,88,83,1) 100%); /* IE10+ */
        background: linear-gradient(to right, rgba(88,88,83,1) 0%,lighten(rgba(123,124,118,1), 5%) 50%,rgba(88,88,83,1) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#585853', endColorstr='#585853',GradientType=1 ); /* IE6-9 */
        text-shadow: #000 1px 0px 1px;
        box-shadow: 1px 1px 1px rgba(0,0,0,.3);
        letter-spacing: 2px;
        color: @color-005;
        display: block;
        position: absolute;
        border-radius: 3px;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        cursor: grab;

        &:after {
          content: '';
          width: 44px;
          line-height: 15px;
          height: 15px;
          position: absolute;
          display: block;
          top: 0px;
          left: 3px;
          border: 2px solid #848480;
          border-radius: 3px;
          opacity: 0.6;
        }
      }

      .ui-slide-custom-handle:active {
        cursor: -moz-grabbing;
        cursor: -webkit-grabbing;
        cursor: grabbing;
      }

      &.ui-slider-horizontal {
        height: .3em;

        .ui-slider-range {
          top: 0;
          height: 100%;
        }

        .ui-slider-range-min {
          left: 0;
        }

        .ui-slider-range-max {
          right: 0;
        }
      }
    }
  }

  .country-value-popup {
    position: fixed;
    background-color: @color-text-light;
    border: 1px solid @color-text-dark;
    padding: 0 5px;
    display: none;
  }

  .country-value-popup.active {
    display: block;
  }

  .app-global-button {
    background-color: @color-015;
    .font-size(@type-size-sm);
    line-height: 20px;
    padding: 5px 15px;
    border-radius: 3px;
    border: 1px solid @color-text-dark;
  }

  .back-button {
    position: absolute;
    top: 45px;
    right: 38px;
  }

  .eu-map-country-body,
  .eu-map-compare-body {
    position: relative;
    color: @color-012;
    clear: both;
    overflow: hidden;
  }

  .eu-map-compare-body {
    padding-bottom: 10px;
  }

  .eu-country-subheader,
  .eu-compare-subheader {
    height: 35px;
    padding: 0 38px;
    background-color: @color-005;
    border-bottom: 1px solid @color-005;
    overflow: hidden;
  }

  .eu-country-subheader > .subheadline,
  .eu-compare-subheader > .subheadline {
    line-height: 35px;
    float: left;
  }

  .graph-headline {
    margin-top: 25px;
    margin-bottom: 10px;
    padding-left: 38px;
    font-size: 25px;
    font-weight: 100;
  }

  .graph-subheadline {
    margin-bottom: 20px;
    padding-left: 38px;
  }

  .graph-country-value {
    color: @color-legend-3;
    font-weight: bold;
  }

  .graph-tab-title {
    display: block;
    float: left;
    line-height: 25px;
    padding: 0 15px;
    margin-top: 10px;
    overflow: hidden;
    background-color: @color-text-light;
  }

  .tab-content canvas,
  .graph-overlay-wrapper {
    width: 640px;
    height: 300px;
    clear: both;
    background-color: @color-text-light;
    position: relative;
    padding: 10px;
  }

  .graph-overlay-wrapper {
    margin: 0 auto;
    width: 100%;

    .graph-overlay {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }

  #graph-container > svg {
    z-index: 1;
  }

  .graph-background {
    height: 160px;
    width: 600px;
    top: 30px;
    left: 31px;
    position: absolute;
  }

  .graph-background-row {
    height: 20%;
    border-top: 1px solid @color-005;
  }

  .graph-background-row.grey {
    background-color: @color-015;
  }

  #Medlemmer > .members, #NonMembers {
    fill: @color-005;
  }

  .popup {
    background-color: rgba(0,0,0,.6);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#33000000,endColorstr=#33000000)";
    -webkit-transition: background-color 1s ease-in;
    -moz-transition: background-color .5s ease-in;
    -o-transition: background-color .5s ease-in;
    transition: background-color .5s ease-in;
    zoom: 1;
  }

  .map-app-header .dropdown-button {
    .font-size(@type-size-sm);
    font-family: SceneAlt, serif;
    line-height: 38px;
    color: @color-005;
    display: block;
    cursor: pointer;
    padding: 0 10px;
    text-align: left;
  }

  .dropdown-button.active {
    background-color: @color-013;
  }

  .presentation-dropdown, .country-dropdown {
    float: right;
  }

  .country-dropdown,
  .year-dropdown {
    margin-right: 10px;
  }

  .dropdown-button-year {
    width: 80px;
  }

  .presentation-dropdown > .dropdown-button, .country-dropdown > .dropdown-button {
    background-color: @color-015;
    min-width: initial;
    top: -1px;
    margin-top: 9px;
    color: @color-013;
    border-radius: 3px;
    position: relative;
    display: inline-block;
    text-align: left;
    cursor: pointer;
    box-shadow: 1px 1px 1px rgba(0,0,0,.1);
  }

  .main-menu > .menu-group > .dropdown-button {
    min-width: 145px;
  }

  .country-dropdown > .dropdown-legend,
  .presentation-dropdown > .dropdown-legend {
    color: @color-013;
    .font-size(@type-size-sm);
    text-transform: uppercase;
    margin-right: 10px;
    top: -7px;
    position: relative;
  }

  .hidden {
    display: none;
  }
}

// Container that the modal scrolls within
.modal {
  display: none;
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: @zindex-modal;
  -webkit-overflow-scrolling: touch;
  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  @media only screen and (min-width: @screen-sm) {
    width: 500px;
    top: 100px;
    bottom: 0;
    left: 50%;
    margin-left: -250px;
  }
  // When fading in the modal, animate it to slide down
  &.fade .modal-dialog {
    .translate(0, -25%);
    .transition-transform(~"0.3s ease-out");
  }

  &.in .modal-dialog {
    .translate(0, 0)
  }
}

// Shell div to position the modal with bottom padding
.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

// Actual modal
.modal-content {
  position: relative;
  background-color: @modal-content-bg;
  background-clip: padding-box;
  // Remove focus outline from opened modal
  outline: none;
}

// Modal background
.modal-backdrop,
.loading-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-modal-background;
  background-color: @modal-backdrop-bg;
  // Fade for backdrop
  &.fade { .opacity(0); }
  &.in { .opacity(@modal-backdrop-opacity); }
}

.loading-indicator {
  display: none;
  position: absolute;
  width: 180px;
  height: 40px;
   #gradient .vertical(#f4f4f4, #e2e2e2);
  border-radius: 2px;
  box-shadow: -1px 1px 1px rgba(0,0,0,.2);
  top: 50%;
  left:50%;
  margin-left: -90px;
  margin-top: -40px;
  text-align: center;
  padding: 10px;
  z-index: (@zindex-modal-background + 1);
  font-weight: bold;
  color: @color-013;
  opacity: 1;

  z-index: @zindex-modal;
  -webkit-overflow-scrolling: touch;

  // Prevent Chrome on Windows from adding a focus outline. For details, see
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;

  // When fading in the modal, animate it to slide down
  &.fade {
    .translate(0, -25%);
    .transition-transform(~"0.3s ease-out");
  }
  &.in { .translate(0, 0)}
}

// Modal header
// Top section of the modal w/ title and dismiss
.modal-header {
  padding: @modal-title-padding;
  min-height: (@modal-title-padding + @modal-title-line-height);
}
// Close icon
.modal-header .close {
  margin-top: -2px;
}

// Title text within header
.modal-title {
  margin: 0;
  line-height: @modal-title-line-height;
}

// Modal body
// Where all modal content resides (sibling of .modal-header and .modal-footer)
.modal-body {
  position: relative;
  padding: 15px;
}

// Footer (for actions)
.modal-footer {
  margin-top: 15px;
  padding: 15px;
  text-align: center; // right align buttons
  clear: both;
  &:extend(.clearfix all); // clear it in case folks use .pull-* classes on buttons

  // Properly space out buttons
  .btn + .btn {
    margin-left: 5px;
    margin-bottom: 0; // account for input[type="submit"] which gets the bottom margin like all other inputs
  }
  // but override that for button groups
  .btn-group .btn + .btn {
    margin-left: -1px;
  }
  // and override it for block buttons as well
  .btn-block + .btn-block {
    margin-left: 0;
  }
}

.modal-overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
  /* Dropdown */
  .dropdown-content {
    display: none;
    position: absolute;
    color: @color-text-light;
    background-color: @color-013;
    top: 0;
    z-index: 1;

    &.dropdown-content-presentation {
      background-color: #fff;
      box-shadow: 1px 1px 1px rgba(0,0,0,.1);

      .dropdown-item {
        color: @color-013;

        &:hover {
          color: darken(@color-013, 10%);
        }
      }
    }
  }

  .dropdown-content.active {
    max-height: 470px;
    overflow-y: auto;
    display: block;
    position: fixed;
    margin-top: -3px;
  }

  .dropdown-item {
    .font-size(@type-size-sm);
    padding: 8px;
    cursor: pointer;
    color: @color-015;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }

  .dropdown-item:hover {
    color: @color-005;
  }

  .dropdown-label {
    display: inline-block;
    .font-size(@type-size-sm);
    color: @color-013;
    margin-right: 5px;
  }
}

.eu-map-compare-body .nav-tabs > li {
  display: none;
}

.eu-map-app {
  .nav-tabs > li > a,
  .nav-tabs > li.active > a {
    padding: 5px 10px;
    background-color: #fff;
    font-weight: normal;
    .font-size(@type-size-xs);
    border-color: #fff;
  }

  .nav-tabs > li > a {
    background-color: #666662;
    color: #fff;
  }

  .tab-content > .active {
    padding: 5px;
    background-color:#fff;
  }

  .nav-tabs > li.active > a,
  .nav-tabs > li.active > a:focus {
    color: #666662;
    background-color:#fff; 
  }

  .nav-tabs > li.active > a:hover {
    color: #666662;
    background-color:#fff;  
    border-color: #fff;
    cursor: default;
  }

  .tab-container {
    .tab-scroller {
      display: none;
    }
  }

  .tab-container.tab-container-scroller {
    position:relative;
    margin:0 auto;
    overflow:hidden;
    padding:5px;
    height:50px;

    .tab-scroller {
    text-align:center;
    cursor:pointer;
    display:none;
    padding:7px;
    padding-top:11px;
    white-space: nowrap;
    vertical-align:middle;
    background-color:#fff;
  }

    .tab-scroller-right{
      float:right;
    }

    .tab-scroller-left {
      float:left;
    }

    .nav-tabs {
      position:absolute;
      left:0px;
      top:0px;
      min-width:3000px;
      margin-left:12px;
      margin-top:0px;

      > li {
        display:table-cell;
        position:relative;
        text-align:center;
        cursor:grab;
        cursor:-webkit-grab;
        color:#efefef;
        vertical-align:middle;
      }
    }
  }
}

.btn-country-selection {
  float: right;
}

.country-selection-button-wrapper {
  height: 30px;
  padding-right: 38px;
}

.country-selection-items {
  margin: 40px 0 0 0;
  padding: 0;
  list-style: none;
  clear: both;

  li {
    width: 100px;
    float: left;
    cursor: pointer;

    &.selected {
      color: #b2385a;
    }
  }
}

.country-selection-instruction {
  float: left;
}

.country-selection-total {
  float: right;
}

.line-legend {
  list-style: none;
  padding-bottom: 10px;
  background-color: #fff;

  li {
    float: left;
    padding-right: 15px;
    cursor: pointer;
    .font-size(@type-size-xs*1.1);

    span {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 5px;
    }
  }
}

.line-legend + .line-legend {
  display: none;
}

