#leseweb-container {
  background-color: @color-primary-function-bg;
  width: 49px;
  height: 49px;
  border-radius: 50%;

  @media (max-width: @screen-xs) {
    width: 39px;
    height: 39px;
  }

  &:hover {
    cursor: pointer;
    background-color: @color-primary-function-hover;
  }
  .leseweb-icon {
    padding: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      width: 30px;
      fill: white;
      color: white;
    }
    &.leseweb-speak-icon {
      display: flex;
    }
    &.leseweb-mute-icon {
      display: none;
      svg {
        width: 25px;
        margin-left: -4px;
      }
    }
  }
  &.leseweb-container--show {
    .leseweb-speak-icon {
      display: none;
    }
    .leseweb-mute-icon {
      display: flex;
    }
  }
}

.paf-toolbar {
  display: none;
  @media (max-width: @screen-md) {
    top: 171px !important;
  }
  top: 110px !important;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  width: auto !important;
  &.paf-toolbar--show {
    display: block;
  }
  .paf-buttons {
    width: auto;
  }
}

.vFact_highlighted_to_read {
  border-bottom: 1px dotted @color-primary-darker !important;
}
ul.theme_bulletlist span {
}
.vFact_sentence_highlighted {
  background-color: @color-brand-200 !important;
  color: @color-text-light !important;
}
.vFact_highlighted_to_read .word {
}
.vFact_highlighted_to_read .word.vFact_highlighted {
  background-color: @color-primary-darker !important;
  color: @color-text-light !important;
}
