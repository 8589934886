﻿@layout-switch: @screen-sm;

.booking-type {
  position: relative;

  @media (min-width: @layout-switch) {
    margin-bottom: 80px;
  }

  &__row {
    margin: 0 0 10px;
    background-color: rgb(255,255,255);

    &--filter {
      display: none;
    }
  }

  &__category {
    padding: 30px 40px 0;
  }

  &__name {
    padding-left: 0;
  }

  &__title {
    display: flex;
    .font-alt();
    .font-size(1.6);
    color: @color-primary-dark;
    
    .booking-type__name & {
      margin-left: 10px;
      line-height: 22px;
    }
    
    &.large {

      .booking-type__details & {
        margin-top: 0;
        margin-bottom: 10px;

        color: @color-brand-200;
      }
    }
  }

  &__details {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;

    display: none;
    width: 50%;
    font-size: 0;

    .booking-type__row.selected & {
      display: block;

      @media (max-width: @layout-switch) {
        display: none;
      }
    }
  }

  &__details-text {
    display: inline-block;
    width: 66.66%;
    padding-right: 20px;

    font-size: 16px;
    font-size: 1.6rem;
    vertical-align: top;
  }

  &__image-wrap {
    display: inline-block;
    width: 33.33%;
    vertical-align: top;

    text-align: right;
  }

  &__image {
    max-width: 100%;
  }
}