.subsite-wide {
  /****************************************
  NAVIGATION
  ****************************************/
  .subsite-wide__navigation {
    @media (max-width: @screen-lg-min) {
      width: 100%;
      height: calc(100vh - 157px); // 157px is the height of the header + the height of the search bar
      position: absolute;
      top: 110px;
      left: 0;
      padding: 0;
      transform: translate(-100%, 0);
      transition: linear 0.3s;
      background-color: @sw-color-light;

      &.main-menu-open {
        transform: translate(0, 0);
      }
    }

    .navigation-arrow {
      position: absolute;
      top: 0;
      right: @spacing-small;
      height: 100%;
      width: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;

      &.navigation-arrow--reverse {
        transform: rotate(180deg);
        right: auto;
        left: @spacing-small;
      }
    }
  }
}