﻿@import "../styles.less";

.wrapper {
  &.q-and-a {
    background: @spoergsmaal-og-svar-liste-spot-a-background;

    .bubble {
      //.striped-col-brand(@color-brand-info-500, @color-brand-info-600, 253px, 2px);
      background-image: @spoergsmaal-og-svar-liste-spot-a-bubble-stripes;
      background-color: @spoergsmaal-og-svar-liste-spot-a-bubble-background;
      height: 253px;
      margin-bottom: 24px;
      position: relative;
      margin-left: -15px;
      margin-right: -15px;
      padding-top: 50px;

      &:after, &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        width: 50%;
        border-bottom: 27px solid @spoergsmaal-og-svar-liste-spot-a-background;
      }

      @media only screen and (max-width: @screen-xs-max) {
        &:after, &:before {
          display: none;
        }
      }

      &:after {
        left: 0;
        width: 80%;
        border-right: 27px solid transparent;
      }

      &:before {
        right: 0;
        width: 20%;
        border-left: 0px solid transparent;
      }

      .icon {
        text-align: center;
        .font-size(5.1);
        color: @spoergsmaal-og-svar-liste-spot-a-icon-color;
      }

      .text {
        text-align: center;

        strong {
          .font-bold();
          .font-size(@type-size-xxl);
          color: @spoergsmaal-og-svar-liste-spot-a-bubble-header-color;
        }

        a {
          color: @spoergsmaal-og-svar-liste-spot-a-link-color;
        }
      }
    }

    fieldset {
      margin-top: 50px;

      h4, p {
        a {
          color: #000;
        }
      }


      .btn-right {
        .btn-with-icon-right-v2( @color: @spoergsmaal-og-svar-liste-spot-a-fieldset-color; @color-hover: @spoergsmaal-og-svar-liste-spot-a-fieldset-color-hover; @background: @spoergsmaal-og-svar-liste-spot-a-fieldset-background; @background-hover: @spoergsmaal-og-svar-liste-spot-a-fieldset-background-hover; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @spoergsmaal-og-svar-liste-spot-a-fieldset-icon-color; @icon-color-hover: @spoergsmaal-og-svar-liste-spot-a-fieldset-icon-color-hover; @icon-bg: @spoergsmaal-og-svar-liste-spot-a-fieldset-icon-bg; @icon-bg-hover: @spoergsmaal-og-svar-liste-spot-a-fieldset-icon-bg-hover; @icon-size: @btn-action-icon-font-size );

        &.btn-brand {
          .btn-with-icon-right-v2(@color: @color-text-light; @color-hover: @color-text-light; @background: @color-brand-200; @background-hover: @color-brand-100; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @color-text-light; @icon-color-hover: @color-text-light; @icon-bg: @color-brand-100; @icon-bg-hover: darken(@color-brand-100, 5%); @icon-size: @btn-action-icon-font-size;);
        }
      }

      &.bordered {
        border-bottom: 1px solid @spoergsmaal-og-svar-liste-spot-a-fieldset-bordered;
        padding-bottom: 10px;

        p {
          margin-bottom: 5px;
          color: @spoergsmaal-og-svar-liste-spot-a-fieldset-text;
        }
      }

      legend {
        margin: 0 0 10px 0;
        padding: 0 0 10px 0;
        .font-bold();
        .font-size(@type-size-xl);
        border-color: @spoergsmaal-og-svar-liste-spot-a-fieldset-bordered;
        color: @spoergsmaal-og-svar-liste-spot-a-fieldset-header-color;
      }

      p {
        font-weight: 400;
      }

      a {
        h4 {
          display: block;
          color: red;
          .font-regular();
          .font-size(@type-size-xxl);
          padding: 0 0 0 0;
        }

        &:hover {
          text-decoration: none;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        &:first-child {

          a {
            .font-size(@type-size-xxl);
            padding: 0 0 10px 0;
          }
        }

        a {
          display: block;
          color: @spoergsmaal-og-svar-liste-spot-a-fieldset-link;
          border: 1px solid @spoergsmaal-og-svar-liste-spot-a-fieldset-border;
          border-width: 0 0 1px 0;
          .font-regular();
          .font-size(@type-size-base);
          padding: 10px 0;
        }
      }
    }
  }
}
