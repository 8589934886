﻿.ftWebTvFrontPage {
  .make-row();
  margin: 0;
  background: white;

  .tvcol {
    .make-sm-column(3);

    .pic {
      img {
        width: 100%;
        height: auto;
      }
    }

    h2 {
      .font-alt();
      .font-size(2);
    }

    h3,
    h6 {
      .font-size(1.6);
      margin: 0;
    }

    .toVideoBox {
      border-top: 1px solid @color-tertiary-result-absent;
      padding: 10px 0 0;
    }
  }

  .webtvFrontPageColLink {
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid @color-tertiary-result-absent;

    li {
      padding: 10px 0;
    }

    a {
      .font-size(1.2);
    }
  }

}
