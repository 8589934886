.subsite-wide {
	.list-filter-dropdown {
		.list-filter-dropdown__category {
			// extends sibsite-wide-container to get max-width but removed padding as spots within should also have the padding
			&:extend(.subsite-wide-container);
			background-color: transparent;
			padding-left: 0;
			padding-right: 0;

			> div {
				background-color: transparent;
			}
		}

		.list-filter-dropdown__select-wrapper {
			.list-filter-dropdown__select {
				padding-left: 0;
				@media (max-width: @screen-xs-max) {
					padding-right: 0;
				}
			}

			.list-filter-dropdown__reset-btn.button.button--outline {
				padding: 5px 25px 6px;
				height: 46px;
				font-size: 22px !important;
				cursor: pointer;

				&:hover {
					transform: none;
					background-color: @sw-color-primary;
					background-color: var(--sw-color-primary, @sw-color-primary);
					color: @sw-color-light;
				}

				&:active,
				&:focus {
					border: 2px solid @sw-color-primary;
					border: 2px solid var(--sw-color-primary, @sw-color-primary);
				}

				@media (max-width: @screen-xs-max)  {
					margin-top: @padding-small;
				}
			}
		}

		.list-filter-dropdown__headline {
			&:extend(.subsite-wide-container);
			display: flex;
			align-items: center;
			padding-bottom: @padding-small;

			@media (max-width: @screen-md-min) {
				flex-wrap: wrap;
			}

			.list-filter-dropdown__headline-icon {
				margin-right: 10px;

				@media (max-width: @screen-md-min) {
					width: 100%;
					margin-bottom: @spacing-small - 5;
				}

				svg, img {
					width: 50px;
					height: 50px;
					margin-right: 12px;
					flex-shrink: 0;

					@media (max-width: @screen-md-min) {
						width: 40px;
						height: 40px;
					}
				}
			}

			h2.list-filter-dropdown__headline-title {
				margin-bottom: 0 !important;
			}
		}

		.list-filter-dropdown__category {
			.two-col-spot {
				padding-left: @spacing-small;
				padding-right: @spacing-small;
			}
		}

		// styled select inherits from custom-select.less
		.style-select {
			.ss-selected-option {
				border: 0;
				background-color: @sw-color-grey-lightest;
    			background-color: var(--sw-color-grey, @sw-color-grey-lightest);
			}

			.ss-dropdown {
				.ss-option {
				.ss-value {
					pointer-events: none;
					padding-bottom: 4px;
					border-bottom: 1px solid transparent;
				}
		
				&.highlighted,
				&.ticked.highlighted,
				&.ticked {
					background-color: @sw-color-light;
					background-color: var(--sw-color-grey, @sw-color-light);
					color: @sw-color-grey-darker;
		
					.ss-value {
						border-color: @sw-color-primary;
						border-color: var(--sw-color-primary, @sw-color-primary);
					}
				}
		
				&.ticked {
					font-weight: 500;
		
					&:before {
					content: "";
					border-left: 0;
					}
				}

				// for the icon before the text in options
				.ss-icon {
					pointer-events: none;
					display: inline-flex;
					line-height: 0;
					max-height: 40px;
					max-width: 40px;
					
					// not sure what format so for now:
					svg, img {
					width: 30px;
					height: 30px;
					margin-right: 15px;
					}
				}
				}
			}

		}
	}
}