﻿.bx-wrapper {
  padding: 30px 0 0;
  -ms-touch-action: pan-y;
  touch-action: pan-y;

  img {
    display: block !important;
  }

  .bx-viewport {
    -moz-box-shadow: 0 0 0;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
    border: 0;
    background: transparent;
  }

  .bx-loading {
    display: none;
    background-image: none;
    background: none;
  }

  .bx-controls {
    &:extend(.hidden-xs);
  }

  .bx-controls-direction {
    a {
      position: absolute;
      &:extend(.less-icon-extend);
      top: 80px;
      margin-top: -5px;
      text-indent: 0;
      color: @color-brand-200;
      .font-size(@type-size-xl);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }

    .bx-prev {
      left: -25px;

      &:before {
        &:extend(.icon-pil-spinkel-venstre:before);
      }
    }

    .bx-next {
      right: -30px;

      &:before {
        &:extend(.icon-pil-spinkel-hoejre:before);
      }
    }
  }

  .slide {
    cursor: pointer;



    .slide-title {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: @color-brand-200;
      margin-top: 15px;
      .font-size(@type-size-xl);
      font-weight: bold;
    }

    .slide-description {
      display: block;
      margin: 10px 0 0 0;
      .font-size(@type-size-base);
    }
  }

  .information-page-spot-a & {
    .slide {
      img {
        max-width: 245px;
        max-height: 123px;
      }

      .slide-title {
        max-width: 245px;
      }
    }
  }

  .information-page-spot-b &,
  .information-page-spot-c & {
    .slide {
      img {
        max-width: 370px;
        max-height: 186px;
      }

      .slide-title {
        max-width: 370px;
      }
    }
  }
}

.ribbon-spot {
  margin-bottom: 20px;
  
  .bx-wrapper {
    .bx-viewport {
      left: 0;
    }

    .bx-controls-direction {
      a {
        top: 30px;
        display: inline-block;
        height: 120px;
        background: fade(@color-text-light, 30%);
        margin: 0;
        width: 28px;

        &:hover {
          background: fade(@color-text-light, 50%);
        }

        &.bx-prev {
          left: 0;
        }

        &.bx-next {
          right: 0;
        }

        &:before {
          display: table-cell;
          width: 28px;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }

  .information-page-spot-a {
    @media only screen and (min-width: @screen-md) {
      padding-right: 0;
    }

    .bx-controls-direction {
      a {
        height: 123px;

        &:before {
          height: 123px;
        }
      }
    }
  }

  .information-page-spot-b {


    @media (min-width: @screen-md) {
      margin-bottom: -15px;
    }

    @media only screen and (min-width: @screen-md) {
      padding: 0;
    }

    &.slider {
      margin-bottom: 20px;
    }

    .bx-wrapper {
    }

    .bx-controls-direction {
      a {
        height: 186px;

        &:before {
          height: 186px;
        }
      }
    }

    .category-label {
      margin: 0;
      //height: 55px;
      //display: table-cell;
      text-align: right;

      @media (max-width: @screen-sm) {
        text-align: left;
      }

      vertical-align: middle;

      div[class^="icon-"] {
        display: table-cell;
        vertical-align: middle;
        line-height: 0;

        &:before {
          margin-right: 20px;
          top: -5px;
          position: relative;
        }
      }

      a {
        position: relative;
        top: 19px;

        @media (max-width: @screen-sm) {
          position: static;
        }

        &:after {
          display: inline-block;
        }
      }

      .category-title {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        text-align: left;
        margin: 0;
      }
    }
  }

  .information-page-spot-c {

    @media (min-width: @screen-md) {
      margin-bottom: -15px;
    }

    @media only screen and (min-width: @screen-md) {
      padding: 0;
    }

    .bx-wrapper {
      .bx-viewport {
        width: 90% !important;
        margin: 0 auto !important;

        .slide {
          a {
            margin-bottom: 10px;
          }
        }
      }
    }

    .bx-controls-direction {
      a {
        height: 30px;
        top: 50%;
        margin-top: -15px;

        &:before {
          height: 30px;
        }

        &.bx-prev {
          &:before {
            text-align: left;
          }
        }

        &.bx-next {
          &:before {
            text-align: right;
          }
        }
      }
    }

    .category-label {
      margin: 0;
      //height: 55px;
      //display: table-cell;
      text-align: right;

      @media (max-width: @screen-sm) {
        text-align: left;
      }

      vertical-align: middle;

      div[class^="icon-"] {
        display: table-cell;
        vertical-align: middle;
        line-height: 0;

        &:before {
          margin-right: 20px;
          top: -5px;
          position: relative;
        }
      }

      a {
        position: relative;
        top: 19px;

        @media (max-width: @screen-sm) {
          position: static;
        }

        &:after {
          display: inline-block;
        }
      }

      .category-title {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        text-align: left;
        margin: 0;
      }
    }
  }

  .medlemmersidespot-a {  
    margin-bottom: 20px;
    .prevent-fouc{
      display: none;
    }
    .category-label {
      margin: 0;
      text-align: right;

      @media (max-width: @screen-sm) {
        text-align: left;
      }

      a {
        position: relative;
        top: 19px;

        @media (max-width: @screen-sm) {
          position: static;
        }

        &:after {
          display: inline-block;
        }
      }

      .category-title {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        text-align: left;
        margin: 0;
      }
    }

    .bx-wrapper {
      padding: 10px 0 0;

      .slide {

        .img-wrapper {
          width: 245px;
          background-color: @color-contrast-200;     

          @media (max-width: @screen-sm) {
            width: auto;
          }
               
          img {
            width: auto !important;
            display: block;
            margin: 0 auto;
            width: 133px;
            height: 133px;
          }
        }
        .slide-title {
          margin-top: 10px;
        }
      }
    }

    .bx-controls-direction {
      a {
        height: 133px;
        top: 10px;

        &:before {
          height: 133px;
        }
      }
    }
  }

  .instagram-feed & {
    @media only screen and (min-width: @screen-md) {
      padding-right: 0;
    }

    .bx-controls-direction {
      a {
        height: 150px;

        &:before {
          height: 150px;
        }
      }
    }
  }
}
