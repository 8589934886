﻿.icon-link-wrapper {
  margin-top: 0;

  @media(min-width: @screen-sm) {
    margin-top: 20px;
  }

  &__graphic {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-right: 7px;   
    img{
      width: 100%;
    }
  }

  &__link {
    .font-size(1.5);
    color: @color-menu-level1;
    padding: 0 0 15px 20px;
    display: inline-block;   
    &:hover{
      cursor: pointer;
    }
  }
}
