@import "../typography.less";
.subsite-wide {
  .information-spot-a,
  .information-spot-forum {
    &:extend(.subsite-wide-container);
    &:extend(.spot--margin-bottom);

    > *,
    .primary-text {
      max-width: 700px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .top {
      .primary-text {
        font-weight: @sw-font-bold !important;
        line-height: 1 !important;
      }
    }
    .secondary-text {
      font-size: @sw-font-base !important;
      line-height: 1.4;
    }
  }

  .text-spot-a-content ul:not(.link-list) li {
      list-style-type: disc;
  }

  .text-spot-a-content ul.link-list li a,
  .text-spot-a-wrapper .text-spot-a-content a:not(.booking-order-button):not(.read-more-expander) {                                                             
    color: @sw-color-link;
    color: var(--sw-color-primary, @sw-color-link);
  }

  .text-spot-a-wrapper .text-spot-a-content a:not(.link-list):not(.booking-order-button):not(.read-more-expander):not(.btn-standard) {
    border-bottom: 1px solid #cacaca;
  }

  .text-spot-a-wrapper.content-rte a:not(.link-list):not(.booking-order-button):not(.read-more-expander):hover,
  .text-spot-a-wrapper .text-spot-a-content a:not(.link-list):not(.booking-order-button):not(.read-more-expander):not(.btn-standard):hover,
  .text-spot-a-content a:hover {
    text-decoration: none;
    color: @sw-color-link;
    color: var(--sw-color-primary, @sw-color-link);
    border-bottom: 1px solid @sw-color-link;
    border-bottom: 1px solid var(--sw-color-primary, @sw-color-link);
  }
}
