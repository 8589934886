﻿//Trigger
.dark_player_cntr_share_lnk.has-disclaimer.is-hidden {
  display: none !important;
}

.video_top_panel {
  background: @color-primary-dark;
  color: white;
  height: 35px;
  padding: 9px 11px !important;
  color: #fff;
  .font-size(1.4);
  .clearfix();

  &.is-expanded {
    height: auto;

    .top-info {
      height: auto;
      width: 75%;

      .video_date {
        margin-bottom: 4px;
      }

      .video_title {
        display: block;
        margin-bottom: 0;
      }
    }
  }

  .top-info {
    display: inline-block;
    height: 15px;
    overflow: hidden;
  }

  .video_date {
    color: #fff;
    display: inline-block;
    font-size: 13px;
    font-family: SceneAlt, serif;
    padding-left: 0 !important;
    //font-weight: bold;
  }

  .video_title {
    margin-right: 10px;
    font-family: SceneAlt, serif;
    font-size: 13px;
    margin: 0 !important;
    padding: 0 !important;
    display: none;
  }

  .video-wrapper-fluid-expander {
    cursor: pointer;
  }

  a {
    color: #fff;

    @media (max-width: @screen-sm) {
      display: none;
    }

    &:focus {
      outline-offset: 2px;
    }
  }
}

a.embedPlayerBottomJumpButton:focus {
  outline-offset: 2px;
}

.video-tools {
  background: #252525;
  max-height: 0;
  transition: max-height 0.25s ease-out;
  position: relative;
  font-family: Arial, sans-serif;
  z-index: 2;
  &.is-open, &.is-open-tools {
    max-height: 500px;
  }

  &.is-toolbox {
    max-height: 200px;
  }

  &.has-email-popup {
    height: 205px;
  }

  &__accept,
  &-edit__accept {
    display: inline-table;
    color: white;
    margin-bottom: 20px;
    margin-top: 0;
    font-size: 14px;
    margin-left: 5px;
    padding: 0 !important;
    position: relative;
    top: 3px;
    width: auto;

    @media (min-width: @screen-sm) {
      font-size: 16px;
      position: relative;
      top: 3px;
    }
  }

  &__disclaimer,
  &-edit__disclaimer {
    width: 100%;
    background: #252525;
    color: white;
    display: none;
    padding: 20px 15px 20px 15px;

    &.is-shown {
      display: block!important;
    }

    &.is-hidden {
      display: none;
      /*visibility: hidden;
      opacity: 0;
      height: 0;*/
    }

    .custom-checkbox input ~ .icon:before,
    .custom-radio input ~ .icon:before {
      visibility: hidden;
      vertical-align: middle;
    }

    .custom-checkbox {
      border: 2px solid white;
      background: none;
      display: table-cell;

      .icon:before {
        color: #fff !important;
        font-size: 13px;
        position: relative;
        top: -3px;
      }

      input {
        &:checked ~ .icon:before {
          visibility: visible;
          color: @color-primary-function-bg;
        }
      }
    }

    p {
      color: #fff;
      font-size: 12px;
      margin: 0 !important;
      padding: 0 !important;

      @media (min-width: @screen-sm) {
        font-size: 14px;
      }
    }

    a {
      color: white;

      &:after {
        display: inline-block;
        font-size: 11px;
        margin-left: 5px;
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
      }
    }
  }

  &__disclaimer-link,
  &-edit__disclaimer-link {
    display: inline-table;

    &:after {
      display: none!important;
    }
  }

  &__box {
    display: none;

    .is-open-tools & {
      .clearfix();
      display: block;
      //position: absolute;
      width: 100%;
      top: 0;
      background: #252525;
    }

    &.is-visible {
      visibility: visible;
    }
  }

  &__edit {
    display: none;
    /*@media (min-width: @screen-md) {
      display: block;
      margin-bottom: 16px;
      background-color: #252525;
      color: white;
      transition: background-color .2s linear;
    }*/
  }

  &__social {

    &:before, &:after {
      content: " ";
      display: table;
    }

    &:after {
      clear: both;
    }

    width: 100%;
    padding: 20px 13px 10px 13px;

    &.is-disabled {
      pointer-events: none;
      opacity: 0.3;

      svg {
        -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
        filter: grayscale(100%);
      }
    }

    div {

      &:nth-child(even) {
        margin-right: 0;
      }

      a {
        font-size: 14px;
        width: 77%;
        height: 31px;
        display: inline-block;
        padding: 5px;
        border-radius: 2px;
        //background: #313131;
        color: white;
        position: relative;
        top: 4px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }


  &__slice {
    display: block;
    margin: 0 auto;
    height: 0px;
    overflow: hidden;
    width: 100%;
    transition: height 0.25s ease-in;
    text-align: center;

    &.is-open {
      height: 185px;
      padding-top: 40px;
      background-color: #181818;
      border-radius: 4px;
      position: relative;

      .video-tools__slice__gauge-close {
        display: block;
        visibility: visible;
        position: absolute;
        right: 12px;
        top: 10px;
        transition: color .2s linear;
        font-size: 12px;
        color: #979797;

        &:hover {
          text-decoration: none;
          color: white;
        }

        p {
          float: left;
        }

        svg {
          fill: @color-primary-function-bg;
          position: relative;
          top: -8px;
          margin-right: -12px;
        }
      }

      .video-tools__slice__edit-clip {
        display: none;
      }
    }

    &__tooltip-icon {
      width: 17px;
      vertical-align: top;
      float: left;
      margin-right: 6px;
      margin-left: 9px;
      position: relative;
      top: 3px;
      float: left;
      line-height: 13px;
      margin-bottom: 35px;
    }

    &__tooltip {
      font-size: 12px;
      text-align: left;
      width: 77%;
      color: #979797;
      font-size: 12px;
      float: left;
    }

    &__gauge-close {
      visibility: hidden;
    }

    &__top {
      padding-bottom: 15px;
      border-top: 1px solid #403f3f;
    }

    &__bottom {
      padding: 10px;
      border-top: 1px solid #403f3f;
      border-bottom: 1px solid #403f3f;
      background: #181818;

      .icon-container {
        position: relative;
        top: 2px;
        left: -5px;
        vertical-align: top;

        svg {
          fill: white;
        }
      }
    }

    &__bottom-cta {
      display: table;
      margin: auto;
    }

    &__edit-clip {
      color: white;
      vertical-align: top;
      position: relative;
      top: 5px;
      margin-right: 27px;

      &:hover {
        color: white;
      }
    }

    &__submit {
      display: none;
      color: #979797;
      text-transform: uppercase;
      vertical-align: top;
      position: relative;
      top: 5px;
      margin-right: 27px;
      transition: color .2s linear;
      font-size: 14px;

      &:hover {
        color: white;
      }

      .video-tools__slice.is-open & {
        display: block;
      }
    }

    &__success {
      display: none;

      .icon-container {
        position: relative;
        top: -2px;
        left: -7px;
        display: inline-table;
        vertical-align: top;

        svg {
          fill: #9b9b9b;
        }
      }
    }

    &__success-message {
      display: inline-table;
      color: white;
      vertical-align: top;
      position: relative;
      top: 5px;
    }

    &__container {
      display: table;
      width: 100%;
      margin: 0 auto;
    }

    &__start, &__end {
      display: inline-block;
      width: 50%;
      color: #979797;
      vertical-align: top;
      padding: 25px 0 8px 0;

      input {
        border: 1px solid @color-primary-function-bg;
        background: #181818;
        color: white;
        padding: 5px 15px 5px 15px;
        width: 100px;
        margin-bottom: 7px;
        text-align: center;
      }

      span {
        display: inline-block;
        padding: 5px 15px 5px 15px;
      }

      label {
        display: block;
        font-size: 12px;
        text-transform: uppercase;
        vertical-align: top;
      }

      img {
        width: 25px;
        background: white;
        border-radius: 12px;
        float: left;
      }
    }

    &__start {
      float: left;
      border-right: 1px solid #403f3f;
    }

    &__end {
      float: right;
    }

    &__start-inner, &__end-inner {
      display: inline-block;
      margin: 0 5%;
    }

    &__lock-icon {
      vertical-align: top;
      display: inline-block;
    }

    &__info-icon {
      vertical-align: top;
      display: inline-block;
      position: relative;
      top: 4px;
    }

    &__info {
      display: table;
      margin: 0 auto;
      color: #979797;
      width: 100%;
      border-top: 1px solid #403f3f;
      padding: 16px 10px 6px 10px;

      label {
        font-size: 12px;
        text-transform: uppercase;
        vertical-align: top;
      }

      span {
        font-size: 19px;
        margin: 0 78px 0 17px;
      }
    }

    &__gauge-link {
      display: inline-block;
      color: white;
      width: auto;
      padding: 10px;
      position: relative;
      top: 2px;

      &:hover {
        color: white;
      }

      .video-tools__slice.is-open & {
        display: none;
      }
    }



    .video-tools__slice.is-open & {
      display: inline-block;
      color: white;
      width: auto;
      padding: 10px;
      /*background: url("/dist/images/global/legacy/share/images/tab-rediger.gif") no-repeat 0 0;
      background-position-x: 141px;
      background-position-y: 3px;*/
      position: relative;
      top: 2px;
    }
  }

  &__facebook {
    width: 28px;
    height: 28px;
    /*background: url("/dist/images/global/legacy/share/images/share-icons.png") no-repeat 0 0;
    background-position: -28px 0;*/
    svg {
      position: relative;
      left: -1px;
    }
  }

  &__podcast {
    width: 28px;
    height: 28px;
    /*background: url("/dist/images/global/legacy/share/images/share-icons.png") no-repeat 0 0;
    background-position: -28px 0;*/
    svg {
      position: relative;
      left: -1px;
    }
  }

  &__twitter {
    width: 28px;
    height: 28px;
    /*background: url("/dist/images/global/legacy/share/images/share-icons.png") no-repeat 0 0;
    background-position: -28px -28px;*/
  }

  #copy, #copyz {
    &:after {
      content: none;
    }
  }

  &__copy-link {
    width: 28px;
    height: 28px;
    /*background: url("/dist/images/global/legacy/share/images/share-icons.png") no-repeat 0 0;
    background-position: -28px -56px;*/
    -webkit-transition: -webkit-transform .33s;
    transition: transform .33s; /* Animate the transform properties */
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;

    svg {
      position: relative;
      left: -2px;
    }

    .child-is-loading {
      &:hover {
        text-decoration: none;
      }
    }

    .copy-success {
      margin-bottom: 0;
    }
  }

  &__embed {
    width: 28px;
    height: 28px;
    /*background: url("/dist/images/global/legacy/share/images/share-icons.png") no-repeat 0 0;
    background-position: -28px -84px;*/
    svg {
      position: relative;
      left: -2px;
    }
  }

  &__email {
    width: 28px;
    height: 28px;

    svg {
      position: relative;
      left: -2px;
    }

    ol {
      padding: 0 10px;

      li {
        list-style-type: none;
      }
    }

    &__popup {
      display: none;
      transition: all 0.3s ease;
      opacity: 0;

      &.is-active {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 220px;
        padding-top: 15px;
        background: #252525;
        z-index: 2;
        opacity: 1;
      }
    }

    &__button-cancel {
      width: 48% !important;
      margin-right: 2%;
      position: static !important;
      font-family: SceneAlt, serif;
      font-weight: 400;
      font-size: 14px;
      line-height: inherit !important;
      transition: background .2s linear;
      border: 1px solid #565454;

      &:after {
        content: none;
      }

      &:hover {
        background: #5d5d5d;
        color: white;
        opacity: 1 !important;
      }

      .social-video & {
        width: 46% !important;
        float: left;
      }
    }

    &__button-submit {
      width: 48% !important;
      margin-left: 2%;
      position: static !important;
      font-family: SceneAlt, serif;
      font-weight: 400;
      font-size: 14px;
      line-height: inherit !important;
      transition: background .2s linear;
      border: 1px solid #565454;
      float: right;

      &:after {
        content: none;
      }

      &:hover {
        background: #5d5d5d;
        color: white;
        opacity: 1 !important;
      }

      .two-col-spot .col-sm-6 .social-video &, .three-col-spot .col-sm-4 .social-video & {
        width: 50% !important;
      }
    }

    &__adress {
      width: 100%;
      margin-bottom: 7px;
      border: 1px solid #545050;
      padding: 5px;
      font-family: Arial;
      font-size: 12px;
      font-weight: 100;
    }

    &__message {
      width: 100%;
      height: 90px;
      overflow: auto;
      resize: none;
      border: 1px solid #545050;
      padding: 5px;
      font-family: Arial;
      font-size: 12px;
      font-weight: 100;
    }
  }

  &__fullscreen {
    width: 28px;
    height: 28px;
    background: url("/dist/images/global/legacy/share/images/share-icons.png") no-repeat 0 0;
    background-position: -28px -112px;
  }

  &__copy-link, &__embed, &__facebook, &__twitter, &__email, &__fullscreen, &__podcast {
    width: 100%;
    float: left;
    margin-bottom: 15px;

    @media (min-width: @screen-xs) {
      width: 50%;
    }

    .two-col-spot &, .three-col-spot & {
      width: 100% !important;

      @media (min-width: @screen-xs) {
        width: 100% !important;
      }
    }

    svg {
      float: left;
      overflow: visible !important;
      width: 37px;
      height: 37px;
    }
  }
}

.video-player-iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#iframevideo {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;

  @media (min-width: @screen-sm) {
    padding-top: 38px;
  }

  @media (min-width: @screen-md) {
    padding-top: 70px;
  }

  .device-mobile & {
    @media (min-width: @screen-md) {
      padding-top: 38px;
    }
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}
