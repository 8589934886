.subsite-wide {
  .button {
    padding: 5px @padding-small 8px;
    height: 60px;
    background-color: @sw-color-primary;
    background-color: var(--sw-color-primary, @sw-color-primary);
    color: @sw-color-text-light;
    border: 2px solid transparent;
    border-radius: 80px;
    font-size: @sw-font-md;
    line-height: 1;
    font-weight: @sw-font-semi-bold;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.5px;
    transition: scale 250ms ease;

    &:hover {
      text-decoration: none;
      transform: scale(1.01);
    }

    @media (min-width: @screen-sm-min) {
      padding: 5px @padding-medium 6px;
      height: 69px;
      font-size: @sw-font-lg !important;
      width: auto;
    }
  }

  .sw-button-text--black {
    .button {
      color: @sw-color-dark;
    }   
  }

  .sw-button-text--white {
    .button {
      color: @sw-color-light;
    }   
  }

  .sw-button-text--custom {
    .button {
      color: var(--sw-color-background, @sw-color-text-light);
    }   
  }

  .button--cta {
    &:extend(.button);
    font-weight: @sw-font-bold;
  }

  .button--arrow,
  .button--chevron {
    span {
      margin-right: @spacing-small;
    }

    svg {
      top: 2px;
      position: relative;
      transition: all 250ms ease;

      path {
        stroke: @sw-color-light;
        fill: none !important;
      }
    }

    &:hover {
      text-decoration: none;
      transform: scale(1);

      svg,
      &:after {
        transform: translateX(10px);
      }
    }
  }

  .button--arrow {
    &.pull-right {
      flex-direction: row-reverse;
    }

    .svg-arrow {
      height: 27px;
      width: 38px;
    }
  }

  .button--chevron {
    &:after {
      content: "";
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjE3MTcgMTIuNDk5N0w4LjIyMTY4IDcuNTQ5NzRMOS42MzU2OCA2LjEzNTc0TDE1Ljk5OTcgMTIuNDk5N0w5LjYzNTY4IDE4Ljg2MzdMOC4yMjE2OCAxNy40NDk3TDEzLjE3MTcgMTIuNDk5N1oiIGZpbGw9IiNGRUZFRkUiLz4KPC9zdmc+Cg=="); // white
      height: 27px;
      width: 38px;
      background-repeat: no-repeat;
      transition: all 250ms ease;
    }
  }

  .button--outline {
    &:extend(.button);
    background-color: @sw-color-text-light;
    background-color: var(--sw-color-background, @sw-color-text-light);
    border-color: @sw-color-primary;
    border-color: var(--sw-color-primary, @sw-color-primary);
    color: @sw-color-primary;
    color: var(--sw-color-primary, @sw-color-primary);

    &.button--arrow {
      svg path {
        stroke: @sw-color-primary;
        stroke: var(--sw-color-primary, @sw-color-primary);
        fill: none !important;
      }
    }

    &.button--chevron {
      &:after {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjE3MTcgMTIuNDk5N0w4LjIyMTY4IDcuNTQ5NzRMOS42MzU2OCA2LjEzNTc0TDE1Ljk5OTcgMTIuNDk5N0w5LjYzNTY4IDE4Ljg2MzdMOC4yMjE2OCAxNy40NDk3TDEzLjE3MTcgMTIuNDk5N1oiIGZpbGw9IiMwMDYyQUUiLz4KPC9zdmc+Cg=="); // blue
      }

      svg path {
        fill: @sw-color-primary;
        fill: var(--sw-color-primary)
      }
    }
  }

  .button[disabled="disabled"],
  .button[disabled],
  .button[disabled="true"],
  button[disabled],
  button[disabled="disabled"],
  button[disabled="true"] {
    background-color: @sw-color-text-light;
    background-color: var(--sw-color-background, @sw-color-background);
    border: 2px solid @sw-color-primary;
    border: 2px solid var(--sw-color-primary, @sw-color-primary);
    color: @sw-color-primary;
    color: var(--sw-color-primary, @sw-color-primary);
    opacity: 0.5;

    &:active {
      background-color: @sw-color-text-light;
      border: 2px solid @sw-color-primary;
      border: 2px solid var(--sw-color-primary, @sw-color-primary);
      color: @sw-color-primary;
      color: var(--sw-color-primary, @sw-color-primary);
      opacity: 0.5;
    }

    &.button--arrow {
      svg path {
        stroke: @sw-color-primary;
        stroke: var(--sw-color-primary, @sw-color-primary);
        fill: none !important;
      }
    }

    &.button--chevron {
      &:after {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjE3MTcgMTIuNDk5N0w4LjIyMTY4IDcuNTQ5NzRMOS42MzU2OCA2LjEzNTc0TDE1Ljk5OTcgMTIuNDk5N0w5LjYzNTY4IDE4Ljg2MzdMOC4yMjE2OCAxNy40NDk3TDEzLjE3MTcgMTIuNDk5N1oiIGZpbGw9IiMwMDYyQUUiLz4KPC9zdmc+Cg=="); // blue
        background-repeat: no-repeat;
      }

      svg path {
        fill: @sw-color-primary;
        fill: var(--sw-color-primary, @sw-color-primary);
      }
    }

    &:hover {
      transform: none !important;

      svg,
      &:after {
        transform: none !important;
      }
    }
  }

  .button--back {
    position: relative;
    color: @sw-color-primary;
    color: var(--sw-color-primary, @sw-color-primary);

    span {
      margin-left: @spacing-small / 2;
    }

    &:hover {
      text-decoration: none;
      transform: none !important;

      svg,
      &:before {
        transform: translateX(-10px);
      }
    }
  }

  .button--nostyling {
    border: 0;
    border-radius: 0;
    padding: 0;
    background: transparent;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;

    &[disabled],
    &[disabled="disabled"],
    &[disabled="true"] { 
      border: 0;
    }

    &.button--arrow,
    &.button--chevron {
      svg path {
      stroke: @sw-color-primary;
      stroke: var(--sw-color-primary, @sw-color-primary);
      fill: none !important;
    }
    }
  }

  .background-primary-light {
    background-color: @sw-color-primary-light;
    background-color: var(--sw-color-primary-light, @sw-color-primary-light);
  }
  .background-primary-lighter {
    background-color: @sw-color-primary-lighter;
    background-color: var(--sw-color-primary-lighter, @sw-color-primary-lighter);
  }

  .visually-hidden {
    display: none;
  }

  :focus,
  :active,
  a:active {
    outline: 0;
    -moz-outline-style: none;
    border: none;
  }

  :focus-visible,
  a:focus-visible,
  a.button:focus-visible {
    outline: @focus-style;
    outline-offset: 2px;
  }

  // SKINS
  // red
  @chevron-red: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjE3MTY4IDYuNDk5NzRMMC4yMjE2OCAxLjU0OTc0TDEuNjM1NjggMC4xMzU3NDJMNy45OTk2OCA2LjQ5OTc0TDEuNjM1NjggMTIuODYzN0wwLjIyMTY4IDExLjQ0OTdMNS4xNzE2OCA2LjQ5OTc0WiIgZmlsbD0iI0E2MTkyRSIvPgo8L3N2Zz4K"); // red
  
  // green
  @chevron-green: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjE3MTY4IDYuNDk5NzRMMC4yMjE2OCAxLjU0OTc0TDEuNjM1NjggMC4xMzU3NDJMNy45OTk2OCA2LjQ5OTc0TDEuNjM1NjggMTIuODYzN0wwLjIyMTY4IDExLjQ0OTdMNS4xNzE2OCA2LjQ5OTc0WiIgZmlsbD0iIzAwNzU1QSIvPgo8L3N2Zz4K"); // green

  // gray
  @chevron-gray: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxMyIgdmlld0JveD0iMCAwIDggMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjE3MTY4IDYuNDk5NzRMMC4yMjE2OCAxLjU0OTc0TDEuNjM1NjggMC4xMzU3NDJMNy45OTk2OCA2LjQ5OTc0TDEuNjM1NjggMTIuODYzN0wwLjIyMTY4IDExLjQ0OTdMNS4xNzE2OCA2LjQ5OTc0WiIgZmlsbD0iIzVGNUY1RiIvPgo8L3N2Zz4K"); // gray

  .subsite-wide--red {
    .button[disabled="disabled"],
    .button[disabled],
    .button[disabled="true"],
    button[disabled],
    button[disabled="disabled"],
    button[disabled="true"] {
      &.button--chevron {
        &:after {
          background-image: @chevron-red; // red
        }
      }
    }

    .button--outline {
      &.button--chevron {
        &:after {
          background-image: @chevron-red; // red
        }
      }
    }
  }

  .subsite-wide--green {
    .button[disabled="disabled"],
    .button[disabled],
    .button[disabled="true"],
    button[disabled],
    button[disabled="disabled"],
    button[disabled="true"] {
      &.button--chevron {
        &:after {
          background-image: @chevron-green; // green
        }
      }
    }

    .button--outline {
      &.button--chevron {
        &:after {
          background-image: @chevron-green; // green
        }
      }
    }
  }

  .subsite-wide--gray {
    .button[disabled="disabled"],
    .button[disabled],
    .button[disabled="true"],
    button[disabled],
    button[disabled="disabled"],
    button[disabled="true"] {
      &.button--chevron {
        &:after {
          background-image: @chevron-gray; // gray
        }
      }
    }

    .button--outline {
      &.button--chevron {
        &:after {
          background-image: @chevron-gray; // gray
        }
      }
    }
  }
}
