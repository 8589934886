﻿@import "../../Global/Components/youtubevideospot.less";

.panel-group {
  &.youtube-spot {
    margin-top: 0;
    margin-bottom: 30px;
    margin-left: -15px;
    margin-right: -15px;

    .panel-heading h2.panel-title {
      .font-size(2);

      a {
        font-size: inherit;
      }
    }

    .videoWrapper {
      margin-bottom: 0;
      margin-top: 20px;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

div.video-wrapper {
  margin-bottom: 30px;

  .two-col-spot &, .three-col-spot & {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  div.two-col-spot .col-sm-6 &, div.three-col-spot .col-sm-4 & {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.video-wrapper__title {
  padding: 0 38px;
  .font-alt();
  .font-size(2.5);
  line-height: 50px;
  color: @color-primary-darker;
  margin-bottom: 0;

  .two-col-spot &, .three-col-spot & {
    .font-size(2);
    color: @color-primary-dark;
    margin-bottom: 20px !important;
    padding-top: 23px !important;
  }
}

.video-wrapper__content-rte {
  padding-top: 30px;
  padding-bottom: 30px;
  background: white;
  color: @color-primary-dark;

  .two-col-spot &, .three-col-spot & {
    padding: 20px 38px;
  }
}

.video-wrapper__embed-wrapper {
  position: relative;
  padding-bottom: 56.0%; /* changed from: 56.25% due to 1px on top and bottom */
  height: 0;
}

.video-wrapper__youtube-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
