﻿@import "../../Global/Components/not-maintained.less";


.row {
  [class*="col"] {
    &.not-maintained {
      margin-top: -50px;

      div {
        padding: 10px 23px;
        .font-alt();
      }
    }
  }
}
