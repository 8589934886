.subsite-wide {
  .forum-category-page {
    position: relative;
    &:extend(.section--margin-bottom);

    .forum-category {
      &:extend(.subsite-wide-container);
      padding-bottom: @section-spacing-small;
      padding-top: @section-spacing-small;

      @media (min-width: @screen-md-min) {
        padding-top: @section-spacing;
      }

      &:before {
        content: "";
        background-color: @sw-color-primary-lighter;
        background-color: var(--sw-color-primary-lighter, @sw-color-primary-lighter);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;

        @media (min-width: @screen-md-min) {
          width: 75%;
        }
      }

      .intro {
        margin-bottom: 0;
        padding: 0;

        @media (min-width: @screen-sm-min) {
          margin-bottom: 50px;
        }

        @media (min-width: @screen-md-min) {
          margin-bottom: 0;
        }

        .intro__header {
          position: relative;
          padding-left: 60px;

          img {
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 45px;
            height: 45px;
          }

          @media (min-width: @screen-md-min) {
            font-size: 50px;
            line-height: 56px;
            padding-left: 75px;

            img {
              width: 60px;
              height: 60px;
            }
          }

          @media (min-width: @screen-lg-min) {
            padding-left: 85px;
            img {
              width: 75px;
              height: 75px;
            }
          }

          @media (min-width: 1320px) {
            padding-left: 0;
            img {
              left: -115px;
              width: 80px;
              height: 80px;
              margin-top: 0;
            }
          }
        }
      }

      .cta-links {
        padding-left: 0;
        padding-right: 0;
      }

      .media-carousel {
        margin-top: 0;
        padding-left: 0;
        padding-right: 0;

        @media (min-width: @screen-lg-min) {
          width: calc(50vw - @padding-small);
          margin-top: 0;
          margin-bottom: 50px;
        }

        .kaltura-video-container {
          margin-top: 0 !important;
        }
      }

      .forum-category-content {
        &__show-more {
          display: flex;
          justify-content: center;
        }

        .show-more__button {
          display: inline-flex;
          flex-direction: column;
          align-items: center;
          font-size: 20px;
          line-height: 1.2;
          cursor: pointer;
          z-index: 1;
          position: relative;
          width: auto;
          border: 0;
          background: transparent;
          appearance: none;

          @media (min-width: @screen-md-min) {
            font-size: 24px;
            line-height: 32px;
          }

          &:hover .show-more__arrow {
            svg {
              top: 20px;
            }
          }
        }

        .show-more__arrow {
          position: relative;
          height: 30px;
          width: 15px;

          svg {
            position: absolute;
            left: 0;
            top: 10px;
            transition: all 0.25s linear;
          }
        }

        &.content--open {
          .show-more__button {
            flex-direction: column-reverse;

            &:hover .show-more__arrow {
              svg {
                top: auto;
                bottom: 10px;
              }
            }
          }

          .show-more__arrow {
            svg {
              top: auto;
              bottom: 0;
              transform: rotate(180deg);
            }
          }
        }

        h2.primary-text {
          font-size: 22px;
          margin-top: 20px !important;

          @media (min-width: @screen-md-min) {
            font-size: @sw-font-xl;
          }
        }
      }

      .forum-category-content-container {
        height: 0;
        opacity: 0;
        transition: all 0.25s linear;
        position: relative;

        @media (max-width: @screen-md-min) {
          > div {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }

      &.content--visible {
        &:before {
          width: 100%;
        }
        .forum-category-content-container {
          height: auto;
          opacity: 1;
        }
      }

      .information-spot-a {
        background-color: @sw-color-primary-lighter;
        background-color: var(--sw-color-primary-lighter, @sw-color-primary-lighter);

        @media (max-width: @screen-md) {
          padding-right: 0;
          padding-left: 0;
        }
      }
    }

    @media (max-width: @screen-md-min) {
      .row {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }

  .link-card-carousel .link-card-carousel-category {
    display: none;
  }
}
