﻿.list-spot {
  margin: 30px 0 0;

  table {
    thead, tbody {
      tr {
        th, td {
          &:first-child {
            padding-left: 38px;
          }

          &:last-child {
            padding-right: 38px;
          }
        }
      }
    }
  }
}
