.topicspot-search {
  &__title {
    .font-alt();
    font-size: 25px;
    line-height: 1.2;
    margin: 50px 0 15px 38px;
    padding: 0;
  }

  &__inner {
    background-color: @color-contrast-400;
    padding-bottom: 1px;
  }

  &__result-text {
    margin: 0;
    margin-bottom: 20px;
  }

  &__submit {
    position: relative;

    .input-group-btn {
      position: absolute;
      right: 0;
      top: 16px;
      width: 80px;

      .btn-search {
        z-index: 2;
        background: transparent;
        border-color: transparent;
        color: @link-color;
        font-size: 0;
        top: 3px;

        &:active {
          box-shadow: none;
        }

        &:hover {
          color: @link-color-hover;

          &:before {
            color: @link-color-hover;
          }

          &:after {
            color: @link-color-hover;
          }
        }

        span[class^="icon-"] {
          &:before {
            color: @color-primary-function;
            font-size: 25px;
            line-height: 1px;
            font-weight: 400;
            top: 3px;
            position: relative;
          }

          &:hover {
            color: @color-primary-function-hover;

            &:before {
              color: @color-primary-function-hover;
            }

            &:after {
              color: @color-primary-function-hover;
            }
          }
        }
      }
    }
  }

  &__input {
    width: 100%;
    height: 60px;
    font-size: 16px;
    padding: 0 38px;
    border-radius: 0;
    border: 0;

    &::-ms-clear {
      display: none;
    }
  }

  &__content {
    margin: 0 38px;
    padding-top: 30px;
    padding-bottom: 10px;
  }  

  &__list {
    padding: 0;
    padding-bottom: 9px;
    margin-bottom: 0;
  }
  
  &__list-item {
    .icon-arrow{      
      display: table;
      float: left;
      position: relative;
      top: 4px;
      &:before {
        font-family: "ft-icons";
        content: "\e631"!important;
        color: @color-primary-function;
        margin-right: 7px;
        display: table;
        font-size: 11px;    
        left: -17px;
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
      }
    }
   
    a {
      display: table;      
      width: 93%;
      float: left;
      @media (min-width: @container-tablet) {
        width: auto!important;
      }
      // &:before {
      //   display: inline-block;
      //   margin-right: 7px;
      //   position: relative;
      //   top: -1px;
      //   font-size: 11px;
      //   &:extend(.less-icon-extend);
      //   &:extend(.icon-ft_bullit_right:before);
      // }
    }

    p {
      display: inline-table;
      margin-left: 10px;
    }
  }
}

.topicspot-result {
  margin-left: 38px;
  padding-top: 30px;
  .clearfix();

  #accordion{
    padding-right: 38px;
    @media (min-width: @container-tablet) {
      padding-right: 20px;
    }
  }
  
  &__wrapper {
    padding-left: 0;
    float: left;
    width: 50%;
    margin-bottom: 0;

    @media (max-width: @container-tablet) {
      float: none;
      width: 100%;
    }
  }

  &__icon {
    display: inline-block;
    position: relative;
    top: -2px;
    width: 100%;
  }

  &__icon-link {
    color: @color-brand-300 !important;
    font-size: 10px;
    text-decoration: none;
    width: 100%;
    &:hover{
      text-decoration: none!important;
    }
    [class*="icon-"] {
      margin-right: 5px;   
      float: left;  
      position: relative;
      top: 5px;
    }

    .closed {
      display: none;
    }

    .open {
      display: inline-block;
    }

    &.collapsed {
      .closed {
        display: inline-block;
      }

      .open {
        display: none;
      }
    }
  }

  &__title {
    display: inline-block;
    color: @color-primary-darker;   
    font-size: 16px;
    margin-bottom: 6px !important;
    margin-top: 6px;
    width: 90%;
   
    &:hover{
      color: @color-primary-function-hover;
    }
  }

  &__items {
    border: 0;
    margin: 0;
  }

  &__info {
    display: none;
    font-size: 16px;
    position: relative;
    top: 1px;  
    vertical-align: top;
    height: 17px;
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
      cursor: pointer;
    }

    .has-note.is-open & {
      display: none;
    }

    .has-note & {
      display: inline-block;
    }
    i{
      vertical-align: top;
    }
    i:before {
      content: "\e648";
      vertical-align: top;
    }
  }

  &__first-level-wrapper {
    padding: 0 0 0 35px !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &__first-level-item {
    position: relative;
    margin-bottom: 8px;
    
    @media (max-width: @container-desktop) {
      min-height: 25px;
    }
    &:before {
      color: @color-primary-function;
      display: table;     
      margin-right: 7px;
      position: absolute;
      top: 5px;
      font-size: 11px;
      float: left;
      width: auto;
      left: -17px;
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);
    }
    
  }

  &__first-level-link {
    margin-right: 7px;
    vertical-align: middle;
    text-decoration: none !important;
    
      .has-note & {
      vertical-align: top;
    }
  }

  &__note-wrapper {
    display: none;
    width: 85%;
    padding: 10px;
    border: 1px solid white;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  &__note-body {
    font-size: 14px;
    width: 85%;
  }

  &__note-close-container {
    float: right;
  }

  &__close-link {
    font-size: 14px;

    &:hover {
      text-decoration: none;
      cursor: pointer;
    }
  }

  &__close-icon {
    margin-left: 5px;
  }

  &__documents-body {
    /*&.collapsing {
      position: absolute;
      background: gray;
      width: 41%;
      z-index: 9;
      padding: 20px 0;
    }
    &.collapse.in {
      position: absolute;
      background: gray;
      width: 41%;
      z-index: 9;
      padding: 20px 0;
    }*/
  }
}
// Revering hover on mobile device
.device-mobile {
  .topicspot-result__title {
    &:hover{
      color: @color-primary-darker;
    }
  }
}

.topicspot-result__first-level-link:hover {
  text-decoration: underline !important;
}

.topicspot-result__info:hover, .topicspot-result__close-link:hover {
  text-decoration: none !important;
}

.topicspot-result__first-level-link, .topicspot-result__close-link {
  border-bottom: 0 !important;
}
