﻿@import "../../styles.less";

.form-control {
  border: 0;

  display: inline-block;
  border-radius: 0;
  border: 1px solid @color-contrast-300;

  &:focus {
    box-shadow: none;
  }
}

fieldset {
  .font-bold();
}

legend {
  border-color: @color-brand-info-500;
  .font-size(@type-size-sm);
}

.form-group-date {
  > input {
    width: 85%;

    @media (max-width: 850px) {
      width: 80%;
    }

    float: left;
  }

  .icon-kalender {
    color: @color-brand-200;
    .font-size(@type-size-lg);
    cursor: pointer;
    float: left;
    margin: 0 0 10px 10px;
    line-height: 34px !important;
  }
}

.form-group-date-inline {
  width: 250px;
  float: left;
  margin-right: 20px;

  .icon-kalender {
    .font-size(@type-size-xxl * 1.1);
    color: @color-brand-200;
  }
}

.form-group-inline {  
  width: 500px;
  float: left;

  @media (max-width: 850px) {
    width: 100%;
    margin-bottom: 10px;
  }
}

.validation-error {
  color: @validation-error-label-text-color;
}

.radio,
.checkbox {
  margin-top: 5px;
  padding-left: 25px;

  &.inline {
    margin-bottom: 5px;
  }
}