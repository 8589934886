.subsite-wide {
  .footer-row {
    margin-right: 0;
    margin-left: 0;
    margin-top: @section-spacing-small;

    @media (min-width: @screen-md-min) {
      margin-top: @section-spacing;
    }

    .footer-container {
      background: @sw-color-background;
      background: var(--sw-color-background, @sw-color-background);

      .footer__logo {
        margin-bottom: 0;

        @media (min-width: @screen-sm-min) {
          margin-bottom: 20px;
        }

        @media (min-width: @screen-md-min) {
          margin-bottom: @spacing-medium;
        }

        img {
          height: 54px;
        }
      }
    }

    .sub-footer-layout-b {
      color: @sw-color-text-dark;
      padding: 0;
      margin-top: 0;

      @media (min-width: @screen-md-min) {
        padding: 0 @padding-small @padding-medium;
      }

      .col-sm-4:after {
        background: @sw-color-grey-light;
        background: var(--sw-color-grey, @sw-color-grey-light);
      }

      .text-spot-a-wrapper {
        ul.link-list li a,
        &.content-rte a {
          color: @sw-color-link;
          color: var(--sw-color-primary, @sw-color-link);

          &:hover {
            border-bottom: 1px solid @sw-color-link;
            border-bottom: 1px solid var(--sw-color-primary, @sw-color-link);
          }
        }
      }
    }

    .sub-footer-layout-b__header {
      font-weight: @sw-font-black;
    }

    p,
    a {
      font-size: @sw-font-base;
    }

    a {
      color: @sw-color-link;
      color: var(--sw-color-primary, @sw-color-link);
    }
  }

  @media (max-width: @screen-lg-min) {
    body.cta-links--fixed {
      .sub-footer-layout-b {
        padding-bottom: 80px;
      }
    }
  }
}
