﻿@import "../styles.less";


.information-spot-a {
  background: @color-brand-info-600;
  padding: 10px;
  margin: 15px 0;

  @media(min-width:@screen-md) {
    padding: 30px;
  }

  .clearfix();

  .top {
    .clearfix();

    .image {
      text-align: center;

      @media(min-width:@screen-md) {
        float: left;
        margin-right: 20px;
      }

      img {
        margin-bottom: 10px;

        @media(min-width:@screen-md) {
          margin-bottom: 0;
        }
      }
    }

    .primary-text {
      .font-bold();
      .font-size(@type-size-xl);
      color: @color-text-dark;
    }
  }

  .secondary-text {
    .font-size(@type-size-xl);
    margin-top: 10px;
  }

  .links {
    margin: 20px 0 0;
  }
}
