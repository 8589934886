﻿@import "../styles.less";

@highlight: @color-brand-300;

.europe-map-container {
  #gradient > .radial(@color-brand-info-100; @color-brand-info-300);
  #gradient > .vertical(@start-color: @hero-bg-gradient-start; @end-color: @hero-bg-gradient-end);
  .reset-filter();
  background-repeat: repeat-x;
  position: relative;
  min-width: 720px;
  min-height: 505px;

  .help {
  }

  .select {
    background: @color-text-light;
    position: absolute;
    width: 210px;
    top: 10px;
    left: 10px;
    z-index: 1;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        border-bottom: 1px solid @color-brand-300;

        &:first-child {
          border-top: 1px solid @color-brand-300;
        }

        a {
          display: block;
          padding: 5px 5px 5px 15px;
          position: relative;

          &:hover {
            text-decoration: none;

            &:before {
              position: absolute;
              top: 50%;
              font-size: 0.8em;
              margin-top: -0.6em;
              left: 0;
              font-family: @left-menu-icon-font;
              speak: none;
              -webkit-font-smoothing: antialiased;
              content: @left-menu-icon-closed;
              color: @left-menu-icon-color;
            }
          }

          &.members {
            background: @color-brand-info-400;
          }

          &.euro {
            background: @highlight;
            color: @color-text-light;

            &:before {
              color: @color-text-light;
            }
          }

          &.candidate {
            background: @highlight;
            color: @color-text-light;

            &:before {
              color: @color-text-light;
            }
          }

          &.schengen {
            background: @highlight;
            color: @color-text-light;

            &:before {
              color: @color-text-light;
            }
          }

          &.efta {
            background: @highlight;
            color: @color-text-light;

            &:before {
              color: @color-text-light;
            }
          }

          &.eea {
            background: @highlight;
            color: @color-text-light;

            &:before {
              color: @color-text-light;
            }
          }
        }
      }
    }
  }

  .info {
    position: absolute;
    bottom: 10px;
    left: 10px;
    overflow: hidden;
    overflow-y: auto;
    background: @color-background-main;
    border: 1px solid @color-brand-300;
    border-width: 1px 0;
    padding: 5px;
    z-index: 1;
    .font-size(@type-size-md);

    div {
      display: none;
      width: 200px;
      height: 180px;

      span {
        display: inline-block;
        margin-left: 5px;
        font-weight: 700;
      }

      table {
        tr {
          td {
            text-align: right;

            &:first-child {
              text-align: left;
            }
          }
        }
      }

      dt {
        &:before {
          content: "\220E";
          margin-right: 5px;
        }

        &.candidates {
          &:before {
            color: @highlight !important;
          }
        }

        &.potentials {
          &:before {
            color: darken(@highlight, 20%) !important;
          }
        }
      }

      dd {
        margin-bottom: 10px;
      }
    }
  }


  &.euro {
    g {
      &.euro {
        fill: @highlight !important;

        &:hover {
          fill: lighten(@highlight, 10%) !important;
        }
      }
    }
  }

  &.candidate {
    g {
      &.candidate {
        fill: @highlight !important;

        &:hover {
          fill: lighten(@highlight, 10%) !important;
        }
      }

      &.potential-candidate {
        fill: darken(@highlight, 20%) !important;

        &:hover {
          fill: lighten(darken(@highlight, 10%), 10%) !important;
        }
      }
    }
  }

  &.schengen {
    g {
      &.schengen {
        fill: @highlight !important;

        &:hover {
          fill: lighten(@highlight, 10%) !important;
        }
      }
    }
  }

  &.efta {
    g {
      &.efta {
        fill: @highlight !important;

        &:hover {
          fill: lighten(@highlight, 10%) !important;
        }
      }
    }
  }

  &.eea {
    g {
      &.eea {
        fill: @highlight !important;

        &:hover {
          fill: lighten(@highlight, 10%) !important;
        }
      }
    }
  }


  .map {
    text-align: right;
    position: relative;
    min-width: 720px;
    min-height: 505px;

    &:after {
      content: "";
      background: url(../../images/EUO/swirl4.png) no-repeat 50% 0%;
      opacity: 0.5;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 0;
    }
    
    svg {
      width: 465px;
      height: 500px;
      position: relative;
      z-index: 1;

      g {
        fill: @color-contrast-200;

        &:hover {
          fill: @color-contrast-300;
        }


        &.members {
          fill: @color-brand-info-200;

          &:hover {
            fill: @color-brand-info-100;
          }
        }
      }

      .borders {
        g {
          fill: @color-text-light;
        }
      }

      .country-box {
        stroke: @color-brand-info-300;
      }
    }
  }
}
