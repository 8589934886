﻿@import "../../Global/Components/agenda.less";

.agenda-info {
  margin: 0 38px 20px;
}

.download {
  /*margin-top: 30px;
  margin-bottom: 30px;*/
  &--agenda {
    .download__container {
      padding: 0 37px;
    }
    /******* Custom styling for an external element ******/
    .download__container__docBtns__btn {
      @media (max-width: @screen-md) {
        margin-bottom: 30px;
      }
    }

    .btn-add-all-documents-to-basket {
      margin-left: 25px;
      display: inline-block;
      position: relative;
      top: 5px;

      @media (max-width: @screen-md) {
        display: block;
        position: static;
        margin-left: 10px;
        margin-bottom: 15px;
      }
    }

    .btn-add-all-documents-to-basket__container {
      &__link {
        background-color: transparent !important;
        display: inline-block;
        color: @color-primary-dark;
        min-width: inherit;
        padding: 0;
        padding-left: 22px;
        float: none;
        margin-left: 10px;
        .font-regular();
        .font-size(@type-size-lg);
        font-size: 15px;
        font-size: 1.5rem;
        line-height: 18px;

        &:hover:before {
          background-color: @color-brand-300;
        }

        &:hover:after {
          color: @color-text-light;
        }
        //Circle
        &:before {
          content: "";
          color: @color-brand-300;
          background-color: @color-text-light;
          display: block;
          width: 43px;
          height: 43px;
          border-radius: 50%;
          position: absolute;
          top: -15px;
          left: -10px;
          text-align: center;
        }
        //Icon
        &:after {
          content: "\e62d";
          font-family: @left-menu-icon-font;
          .font-size(2.8);
          display: block;
          width: 43px;
          height: 43px;
          border-radius: 50%;
          position: absolute;
          top: -10px;
          left: -10px;
          color: @color-brand-300;
          text-align: center;
        }
      }
    }
    /******* END Custom styling for an external element ******/
  }
}

.service-buttons {
  margin-bottom: 30px;

  &__container {
    .make-row();
    margin: 0;
    padding: 0 23px;

    &__docBtns {
      padding: 0 23px;
      margin-left: -7px;

      &__btn {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }

        @media only screen and (max-width: @screen-sm-max) {
          margin-left: 0;
          width: 100%;
          display: block;
          margin-bottom: 15px;
        }

        &:hover {
          text-decoration: none;

          [class*="__docWork"] {
            text-decoration: none;
            background-color: @color-primary-function-bg;
            color: #fff;
          }
        }

        &__docWork {
          color: @color-primary-function-bg;
          background-color: #fff;
          border-radius: 50%;
          width: 43px;
          height: 43px;
          display: inline-block;
          position: relative;
          text-align: center;

          @media only screen and (max-width: @screen-sm-max) {
            display: block;
            float: left;
          }

          .icon-pdf {
            left: 4px;
          }

          i {
            font-size: 28px;
            position: relative;
            top: 7px;
          }
        }

        &__text {
          display: inline-block;
          color: @color-text;
          margin-left: 10px;
          .font-alt();
          .font-size(1.5);

          @media only screen and (max-width: @screen-sm-max) {
            display: table;
            position: relative;
            top: 12px;
            left: 13px;
          }
        }
      }
    }
  }
}
