﻿@import "../styles.less";

.site-wide-message-spot-b {
  position: relative;
  background-color: #d97490;
  background-color: green;

  @media only screen and (max-width: @screen-sm) {
    margin: -30px 0 30px;
  }

  div {
    //border-top: 1px solid #fff;
    &:first-child {
      border: 0;
    }
  }

  &__text {
    .font-alt();
    .font-size(1.6);
    color: #fff;
    text-align: center;
    @media (min-width: @screen-sm) {
      display: table;
    }
    margin: 0 38px;
    padding: 15px 40px 15px 0;

    i {
      .less-icon-extend();
      font-size: 20px;
      margin-right: 10px;
      display: table-cell;

      &:before {
        top: 2px;
        position: relative;
        margin-right: 10px;
        //&:extend(.icon-facebook:before);
      }
    }

    p {
      padding-top:15px;
      @media (min-width: @screen-sm) {
        display: block;
        padding-top:0;
      }
      text-align: left;
    }
  }

  &__close {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 15px;
    right: 33px;
    .less-icon-extend();
    font-size: 20px;
    color: #fff;
    border: 0;
    cursor: pointer;

    &:before {
      &:extend(.icon-ft_close:before);
    }
  }
}
