.subsite-wide {
  // Hide real select boxes which have seen styled.
  select[data-ss-uuid] {
    display: none;
    pointer-events: none;
  }

  // fallback styling for select in case js isnt loading
  select.custom-select {
    border: 2px solid var(--sw-color-grey, @sw-color-grey-lightest);
    background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuOTk5MDIzIDEuODc1TDYuOTk5MDIgOC4xMjVMMTIuOTk5IDEuODc1IiBzdHJva2U9IiMyQjJCMkIiIHN0cm9rZS13aWR0aD0iMiIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=")
      95% 52% no-repeat var(--sw-color-grey, @sw-color-grey-lightest);
    background-size: 20px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &::-ms-expand {
      display: none; /* remove default arrow on ie10 and ie11 */
    }

    &.input--error {
      + .style-select {
        font-weight: @sw-font-regular;
        outline: @focus-style;
        outline-color: @sw-color-red;
        outline-offset: @focus-offset;

        + .input-error-message {
          margin-top: 10px;
          padding-left: @spacing-small;
          font-weight: @sw-font-regular;
          color: @sw-color-red;
        }
      }
    }
  }

  .style-select {
    z-index: 2;
    display: block;
    position: relative;
    width: 100%; // Default width

    &:hover,
    &:focus,
    &:focus-within {
      z-index: 3;
    }

    // Set reasonable defaults on this element and its children
    &,
    * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
      text-align: left;
      cursor: pointer;
    }

    .ss-selected-option {
      width: 100%;
      padding: 13px @spacing-small;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      background-color: @sw-color-grey-lightest;
      background-color: var(--sw-color-grey, @sw-color-grey-lightest);
      border: 0;
      border: 2px solid @sw-color-grey-lightest;
      border: 2px solid var(--sw-color-grey, @sw-color-grey-lightest);
      cursor: pointer;
      font-size: @sw-font-md;
      min-height: 46px;

      // The down arrow
      .ss-icon {
        transform: translateY(-50%);
        position: absolute;
        top: 50%;
        right: 15px;
        width: 15px;
        height: 15px;
        pointer-events: none;

        svg {
          fill: @sw-color-grey-darker;
          width: 10px;
          height: 15px;
          transform: rotate(90deg);
          transition: all 250ms ease;
        }
      }
    }

    .ss-dropdown {
      overflow: hidden;
      display: none;
      position: absolute;
      top: 55px;
      width: 100%;
      background-color: @sw-color-light;
      width: 100%;
      min-width: 100% !important;
      margin: 0;
      font-size: @sw-font-md;
      box-shadow: 0px 5px 12px 2px rgba(0, 0, 0, 0.3);

      .ss-option {
        width: 100%;
        padding: 5px @spacing-small;
        background-color: @sw-color-grey-light;
    		background-color: var(--sw-color-grey, @sw-color-grey-light);

         // added to accomodate icon in dropdown
         min-height: 50px;
         display: flex;
         align-items: center;

        + .ss-option {
          border-top: 2px solid @sw-color-grey-lightest;
          border-top: 2px solid var(--sw-color-grey, @sw-color-grey-lightest);
        }

        &[id^="option-0"] {
          display: none;
        }

        // We don't use :hover as that's only for mouse
        // and we want keyboard highlighting too
        &.highlighted,
        &.ticked.highlighted {
          background-color: @sw-color-primary;
          background-color: var(--sw-color-primary, @sw-color-primary);
          color: @sw-color-light;
        }

        &.disabled {
          color: #d0d0d0;
        }

        &.ticked {
          color: @sw-color-primary;
          color: var(--sw-color-primary, @sw-color-primary);
          background-color: @sw-color-light;
          cursor: initial;
          pointer-events: none;
          position: relative;

          &:before {
            content: "";
            border-left: 6px solid @sw-color-primary;
            border-left: 6px solid var(--sw-color-primary, @sw-color-primary);
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
          }
        }
      }
    }

    &.open {
      .ss-dropdown {
        display: block;
      }
      .ss-selected-option {
        .ss-icon {
          svg {
            transform: rotate(-90deg);
          }
        }
      }
    }

    &.custom-select-filter {
      .ss-selected-option {
        .ss-icon {
          svg {
            fill: none;
            transform: rotate(0deg);
          }
        }
      }

      &.open {
        .ss-selected-option {
          .ss-icon {
            svg {
              transform: rotate(0deg);
            }
          }
        }
      }
    }
  }

  .link-cards__filter {
    // fallback styling for select in case js isnt loading - filter specific
    select.custom-select {
      border: 2px solid @sw-color-grey-darker;
      background-color: @sw-color-light;
      background-color: var(--sw-color-grey, @sw-color-grey-light);

      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE1IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMy41IDFMNy41IDcuMjVMMS41IDEiIHN0cm9rZT0iI0E2MTkyRSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==")
        95% 52% no-repeat @sw-color-light;
      background-size: 20px;

      &::-ms-expand {
        display: none; /* remove default arrow on ie10 and ie11 */
      }
    }

    .style-select {
      .ss-selected-option {
        background-color: @sw-color-light;
        background-color: var(--sw-color-grey, @sw-color-grey-light);
        border: 2px solid @sw-color-grey-darker;

        .ss-icon {
          right: 20px;
          width: 20px;
          height: 20px;

          svg {
            fill: @sw-color-red;
            width: 20px;
            height: 20px;
          }
        }
      }

      &.open {
        .ss-selected-option {
          color: #bfbfbf;
        }
      }
    }
  }
}
