@import "../Global/ft-icons.less";

[class^="icon-"], [class*=" icon-"], .less-icon-extender {
  &:before {
    font-family: "ft-icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.less-icon-extend {
  &:extend(.less-icon-extender:before);
}

.icon-ft_share:before {
  content: "\e900";
}

.icon-ft_close:before {
  content: "\e661";
}

.icon-ft_leftcircle:before {
  content: "\e65d";
}

.icon-ft_rightcircle:before {
  content: "\e65e";
}

.icon-ft_minuscircle:before {
  content: "\e65f";
}

.icon-ft_pluscircle:before {
  content: "\e660";
}

.icon-ft_abonner:before {
  content: "\e62a";
}

.icon-ft_accordionminus:before {
  content: "\e62b";
}

.icon-ft_accordionplus:before {
  content: "\e62c";
}

.icon-ft_basket:before {
  content: "\e62d";
}

.icon-ft_basketminus:before {
  content: "\e62e";
}

.icon-ft_basketplus:before {
  content: "\e62f";
}

.icon-ft_bullit_left:before {
  content: "\e630";
}

.icon-ft_bullit_right:before {
  content: "\e631";
}

.icon-ft_bullit_down:before {
  content: "\e901";
}

.icon-ft_bullit_up:before {
  content: "\e65c";
}

.icon-ft_calendar:before {
  content: "\e632";
}

.icon-ft_casemenu:before {
  content: "\e633";
}

.icon-ft_chat:before {
  content: "\e634";
}

.icon-ft_circle-basket:before {
  content: "\e635";
}

.icon-ft_circle-checkmark:before {
  content: "\e636";
}

.icon-ft_circle-del:before {
  content: "\e637";
}

.icon-ft_circle-download:before {
  content: "\e638";
}

.icon-ft_circle-fb:before {
  content: "\e639";
}

.icon-ft_circle-link:before {
  content: "\e63a";
}

.icon-ft_circle-mail:before {
  content: "\e63b";
}

.icon-ft_circle-mobil:before {
  content: "\e63c";
}

.icon-ft_circle-phone:before {
  content: "\e63d";
}

.icon-ft_circle-print:before {
  content: "\e63e";
}

.icon-ft_circle-rss:before {
  content: "\e63f";
}

.icon-ft_circle-send:before {
  content: "\e640";
}

.icon-ft_circle-tw:before {
  content: "\e641";
}

.icon-ft_cut:before {
  content: "\e642";
}

.icon-ft_del:before {
  content: "\e643";
}
.icon-ft_del-problem:before {
  content: "\e643";
}
.icon-ft_del-problem:after {
    content: "";
    width: 45px;
    height: 6px;
    display: block;
    top: 10px;
    left: -9px;
    transform: rotate3d(0,0,1,45deg);
    background-color: @color-tertiary-result-absent;
    position: absolute;
    border: 2px solid white;
    &.active {
      background-color: @color-primary-function;
    }
}

.icon-ft_dialog:before {
  content: "\e644";
}

.icon-ft_document:before {
  content: "\e645";
}

.icon-ft_download:before {
  content: "\e646";
}

.icon-ft_filter:before {
  content: "\e647";
}

.icon-ft_info:before {
  content: "\e648";
}

.icon-ft_lexicon:before {
  content: "\e649";
}

.icon-ft_mail:before {
  content: "\e64a";
}

.icon-ft_mail-problem:before {
  content: "\e64a";
}

.icon-ft_mail-problem:after {
  content: "";
  width: 40px;
  height: 3px;
  display: block;
  top: 8px;
  left: -4px;
  transform: rotate3d(0,0,1,-45deg);
  background-color: @color-tertiary-result-absent;
  position: absolute;
  border: 1px solid white;
  &.active {
    background-color: @color-primary-function;
  }
}

.icon-ft_menu:before {
  content: "\e64b";
}

.icon-ft_mobil:before {
  content: "\e64c";
}

.icon-ft_news:before {
  content: "\e64d";
}

.icon-ft_paragraph:before {
  content: "\e64e";
}

.icon-ft_person:before {
  content: "\e64f";
}

.icon-ft_phone:before {
  content: "\e650";
}

.icon-ft_print:before {
  content: "\e651";
}

.icon-ft_printbakke:before {
  content: "\e652";
}

.icon-ft_printbakkeproblem:before {
  content: "\e653";
}

.icon-ft_publikationminus:before {
  content: "\e654";
}

.icon-ft_publikationplus:before {
  content: "\e655";
}

.icon-ft_question:before {
  content: "\e656";
}

.icon-ft_read-more:before {
  content: "\e657";
}

.icon-ft_search:before {
  content: "\e658";
}

.icon-ft_send:before {
  content: "\e659";
}

.icon-ft_trash:before {
  content: "\e65a";
}

.icon-ft_video:before {
  content: "\e65b";
}

.icon-googleplus:before {
  content: "\e627";
}

.icon-facebook:before {
  content: "\e628";
}

.icon-twitter:before {
  content: "\e629";
}

.icon-spinner:before {
  content: "\e1b2";
}

.icon-close:before {
  content: "\e36b";
}

.icon-checkmark:before {
  content: "\e372";
}

.icon-minus:before {
  content: "\e375";
}

.icon-feed:before {
  content: "\e4a0";
}

.icon-feed2:before {
  content: "\e4a1";
}

.icon-aaben:before {
  content: "\e600";
}

.icon-video:before {
  content: "\e601";
}

.icon-undervisning:before {
  content: "\e602";
}

.icon-spoergsmaalogsvar:before {
  content: "\e603";
}

.icon-spoergsmaal:before {
  content: "\e604";
}

.icon-sort-op:before {
  content: "\e605";
}

.icon-sort-ned:before {
  content: "\e606";
}

.icon-soeg:before {
  content: "\e607";
}

.icon-send:before {
  content: "\e608";
}

.icon-print:before {
  content: "\e609";
}

.icon-pil-venstre:before {
  content: "\e60a";
}

.icon-pil-spinkel-venstre:before {
  content: "\e60b";
}

.icon-pil-spinkel-op:before {
  content: "\e60c";
}

.icon-pil-spinkel-ned:before {
  content: "\e60d";
}

.icon-pil-spinkel-hoejre:before {
  content: "\e60e";
}

.icon-pil-op:before {
  content: "\e60f";
}

.icon-pil-ned:before {
  content: "\e610";
}

.icon-pil-hoejre:before {
  content: "\e611";
}

.icon-pil-cirkel-venstre:before {
  content: "\e612";
}

.icon-pil-cirkel-op:before {
  content: "\e613";
}

.icon-pil-cirkel-ned:before {
  content: "\e614";
}

.icon-pil-cirkel-hoejre:before {
  content: "\e615";
}

.icon-pdf:before {
  content: "\e616";
}

.icon-nyheder:before {
  content: "\e617";
}

.icon-nulstil:before {
  content: "\e618";
}

.icon-menu:before {
  content: "\e619";
}

.icon-materialer:before {
  content: "\e61a";
}

.icon-mail:before {
  content: "\e61b";
}

.icon-luk:before {
  content: "\e61c";
}

.icon-leksikon:before {
  content: "\e61d";
}

.icon-kontakt:before {
  content: "\e61e";
}

.icon-kalender:before {
  content: "\e61f";
}

.icon-ft-logo:before {
  content: "\e620";
}

.icon-english:before {
  content: "\e621";
}

.icon-emner:before {
  content: "\e622";
}

.icon-del:before {
  content: "\e623";
}

.icon-cookies:before {
  content: "\e624";
}

.icon-chat:before {
  content: "\e625";
}

.icon-arbejdsomraade:before {
  content: "\e626";
}
