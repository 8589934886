@import "../styles.less";

.wrapper {
  &.service-menu {
    background: @primaer-servicemenu-a-background;

    .striped-col-brand {
      background-image: @color-brand-200-stripes;
      background-color: @primaer-servicemenu-a-bg-fix-background;
      height: 60px;
    }
    //overflow: hidden;
    ul.navbar-nav {
      li {
        a {
          position: relative;
          color: @primaer-servicemenu-a-text;

          &:hover {
            span {
              text-decoration: underline;
            }
          }

          span[class^="icon-"] {
            &:before {
              display: inline-block;
              .font-size(2.15);
              position: relative;
              top: 3px;
              padding-right: 8px;

              &:hover {
                text-decoration: none;
              }
            }
          }

          span.icon-ft-logo {
            background: url('../../Images/EUO/english-logo-seal.png')no-repeat center;
            display: inline-block;
            width: 25px;          
            position: relative;
            top: 3px;
            right: 6px;
            background-size: 100%;

            &:before {
              opacity: 0;
            }
          }



          @media(max-width:@screen-md) {
            padding: 13px 10px;
            .font-size(@type-size-xs);
          }
        }
      }
    }

    .navbar-form {
      margin-top: 13px;
      padding: 0;

      .input-group {
        .font-regular();

        .form-control {
          border-bottom-left-radius: 3px;
          border-top-left-radius: 3px;
        }

        button {
          z-index: 2;
          background: @primaer-servicemenu-a-navbar-form-button-background;
          border-color: @primaer-servicemenu-a-navbar-form-button-border-color;
          color: @primaer-servicemenu-a-navbar-form-button-color;

          span[class^="icon-"] {
            &:before {
              color: @primaer-servicemenu-a-navbar-form-button-icon-color;
              font-size: 19px;
              line-height: 1px;
              font-weight: 400;
              top: 3px;
              position: relative;
            }
          }
        }
      }
    }

    @media(min-width:@screen-sm) {
      height: 60px;
    }
  }
}
