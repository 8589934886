﻿@import "../../Global/Components/ftWebTvFrontPage.less";

.ftWebTvFrontPage {

  .frontColumn {
    &.tvcol {
      //Removing padding from parent
      padding: 0;
      border-right: 1px solid @color-tablecell-divider;
      background: white;
      -webkit-transition: width 1s ease-in-out;
      -moz-transition: width 1s ease-in-out;
      -o-transition: width 1s ease-in-out;
      transition: width 1s ease-in-out;

      @media (min-width: @container-tablet) {
        width: 100%;
      }

      @media (min-width: @container-small-desktop) {
        width: 25%;
      }

      h2 {
        display: table;
        color: @color-primary-dark;
      }

      .boxColumn {
        .toVideoBox {
          padding: 15px 0;
          display: table;
          width: 100%;

		  // Fixes BUG-35979
		  h3 a:before,
      h6 a:before {
			  display: inline-block;
			  font-family: 'ft-icons';
        &:extend(.icon-ft_bullit_right:before);
        font-size: 11px;
			  margin-right: 7px;
		  }
		  // End bugfix bug-35979

          p {
            margin: 0;
            .font-size(1.4);
            color: @color-primary-dark;
          }

          &:first-child {
            border-top: 0;
            border-top: 3px solid @color-primary-function-bg;
          }
        }
      }

      .column-content {
        padding: 0 20px;

        @media (max-width: 1021px) {
          padding: 0 38px;
        }
      }

      .webtvFrontPageColLink {
        border-top: 0;

        li {
          padding: 17px 0;

          a {
            font-family: SceneAlt, serif;
            font-weight: 400;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 1.6rem;
            line-height: 25px;

            &:after {
              display: inline-block;
              &:extend(.less-icon-extend);
              &:extend(.icon-ft_bullit_right:before);
              font-size: 11px;
              margin: 0 0 0 7px;
            }
          }
        }

        &:before {
          display: block;
          content: "";
          border-bottom: 3px solid @color-primary-function-bg;
        }
      }

      .ColumnMinHeight {
        width: 100%;
        height: auto !important;
      }

      #r1, #r2, #r3, #r4 {
        @media (min-width: @container-tablet) {
          display: table;
        }
      }
    }
  }
}

#r1, #r3, #r4 {
  @media only screen and (max-width: @screen-xs-max) {
    height: auto !important;
  }
}
#r2 {
  @media only screen and (max-width: @container-small-desktop) {
    height: auto !important;
  }
}

.dateHr {
  color: @color-brand-200;
  font-weight: 400;
}
