﻿@import "../../Global/Components/informations-spot-d.less";

.informationspot-d {
  margin: 0;

  &.panel-group {
    margin-top: 0;
    margin-bottom: 30px;

    .two-col-spot &, .three-col-spot & {
      margin-bottom: 0;
    }

    &.is-siblings-first {
      margin-bottom: 0;
    }

    &.is-siblings-last {
      margin-top: -30px;
      margin-bottom: 30px;
    }

    &.is-single-accordion {
      margin-bottom: 30px;
    }

    .panel-middle {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;

      .panel-heading {
        h2.panel-title {
          .font-size(2);
          //Overwriting another (generic) rule
          a {
            font-size: inherit;
          }
        }
      }
    }
  }

  .panel-body {
    margin: 0 38px 20px;
    color: @color-primary-dark;

    p {
      margin: 0 0 10px !important;
    }

    ul li {
      list-style-type: disc;
    }
  }

  h2 {
    .font-size(2);
  }

  h3 {
    .font-size(1.8);
  }

  h4 {
    .font-size(1.4);
  }

  h5 {
    .font-size(1.3);
  }

  h6 {
    .font-size(1.2);
  }
}
