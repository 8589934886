﻿@import "../../Global/Components/linklist-spot.less";

.panel-group.link-list-spot-d {
  margin-top: 0;
  margin-bottom: 30px;
  .panel-middle {
    .panel-heading {
      h2.panel-title {
        font-size: 2rem;
        font-family: Asap;
        font-family: SceneAlt, serif;
        font-weight: 400;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        line-height: 30px;
        line-height: 3rem;
        //Overwriting another (generic) rule
        a {
          font-size: inherit;
        }
      }
    }
  }
}
