﻿/*
 * Flexible Tabs
 */
.flexible-tabs {
	padding: 0 !important;
	display: inline-block;
}

.flexible-tabs {
	display: block;
}

.flexible-tabs:after {
	content: '.';
	display: block;
	clear: both;
	height: 0;
	visibility: hidden;
}

.flexible-tabs li {
	float: left;
	width: 33.333%;
*width: 33.2%;
	_width: 33.25%;
	padding: 0 !important;
	border-bottom: 2px solid #d3d3c7;
}

.flexible-tabs a {
	display: block;
	background: url(../../images/global/legacy/flexible-tabs-background.png) repeat-x 0 0 !important;
	padding: 0 0 0 14px !important;
	color: #fff !important;
	line-height: 27px;
	margin: 0 0 3px 3px;
}

.flexible-tabs .tab-1 a {
	margin-left: 0;
}

.flexible-tabs .tab-3 a {
	margin-right: 0;
}

.flexible-tabs li.active {
	border-bottom: 0;
	background: url(../../images/global/legacy/flexible-tabs-dot.gif) repeat-x 0 100%;
}

.flexible-tabs li.active a {
	border: solid #d3d3c7;
	border-width: 2px 2px 0 2px;
	background: #ecece7 !important;
	color: #464646 !important;
	margin-bottom: 0 !important;
	padding-bottom: 3px !important;
	margin-bottom: 0;
}

.flexible-tabs li.tabs-selected {
	border-bottom: 0;
	background: url(../../images/global/legacy/flexible-tabs-dot.gif) repeat-x 0 100%;
}

.flexible-tabs li.tabs-selected a {
	border: solid #d3d3c7;
	border-width: 2px 2px 0 2px;
	background: #ecece7 !important;
	color: #464646 !important;
	margin-bottom: 0 !important;
	padding-bottom: 3px !important;
	margin-bottom: 0;
}

.flexible-tabs .emptyTab {
	min-height: 30px;
}

.flexible-tabs-container {
	//background: url(../../images/global/legacy/tab-content-gradient.png) no-repeat 0 0;
	padding-left: 2px;
}

.flexible-tabs-inner {
	//background: url(../../images/global/legacy/tab-content-gradient.png) no-repeat 100% 0;
	padding-right: 2px;
}

.tab-hidden {
	position: absolute;
	left: -9999px;
}
/* Scroll */
.flexible-tabs-container .scroll-up {
	//background: url(../../images/global/legacy/flexible-tabs-scroll.png) no-repeat 50% 0;
}

.flexible-tabs-container .scroll-down {
	//background: url(../../images/global/legacy/flexible-tabs-scroll.png) no-repeat 50% 100%;
}