﻿@import "../styles.less";

#ui-datepicker-div {
  display: none !important;
  visibility: hidden !important;
}

.scfForm {
  .font-regular();
  .scfTitleBorder {
    font-weight: initial;
    .font-size(@type-size-h1);
    .font-headline();
  }
  .scfIntroBorder {
  }

  .scfIntroBorder, .scfFooterBorder, .scfSubmitButtonBorder {
    margin-bottom: 25px;
  }

  fieldset {
    font-weight: 400;
  }

  .scfSectionBorderAsFieldSet {
    border: none;
    margin: 25px 0 0 0;
    padding: 0;

    .scfSectionLegend {
      margin: 0 0 15px 0;
      padding: 0 0 10px 0;
      .font-alt();
      font-weight: 400;
      font-size: 16px;
    }
  }

  .scfDropListBorder, .scfEmailBorder, .scfMultipleLineTextBorder, .scfSingleLineTextBorder, .scfPasswordBorder, .scfNumberBorder, .scfDatePickerBorder, .scfDateBorder, .scfRadioButtonListBorder, .scfListBoxBorder, .scfCheckBoxListBorder, .scfFileUploadBorder, .scfDateSelectorBorder, .scfCreditCardBorder, .scfConfirmPasswordBorder, .scfCaptchaBorder, .scfTelephoneBorder, .scfSmsTelephoneBorder {
    float: left;
  }

  .scfDropListLabel, .scfEmailLabel, .scfMultipleLineTextLabel, .scfSingleLineTextLabel, .scfPasswordLabel, .scfNumberLabel, .scfDatePickerLabel, .scfDateLabel, .scfRadioButtonListLabel, .scfCheckBoxListLabel, .scfListBoxLabel, .scfFileUploadLabel, .scfDateSelectorLabel, .scfCreditCardLabel, .scfConfirmPasswordLabel, .scfCaptchaLabel, .scfTelephoneLabel, .scfSmsTelephoneLabel {
    font-weight: 400;

    @media(max-width:@screen-sm) {
      width: 100%;
      float: none;
    }
  }

  select {
    padding: 2px 0;
  }



  label {
    font-weight: 400;
  }

  .scfRadioButtonList, .scfCheckBoxList {
    label {
      margin-left: 5px;
    }
  }

  .scfEmailGeneralPanel, .scfMultipleLineGeneralPanel, .scfSingleLineGeneralPanel, .scfPasswordGeneralPanel, .scfNumberGeneralPanel, .scfDatePickerGeneralPanel, .scfDateGeneralPanel, .scfRadioButtonListGeneralPanel, .scfCheckBoxListGeneralPanel, .scfFileUploadGeneralPanel, .scfDateSelectorGeneralPanel, .scfCreditCardGeneralPanel, .scfConfirmPasswordGeneralPanel, .scfCaptchaGeneralPanel, .scfTelephoneGeneralPanel, .scfSmsTelephoneGeneralPanel, .scfListBoxGeneralPanel, .scfDropListGeneralPanel {
    width: 70%;

    @media(max-width:@screen-sm) {
      width: 100%;
      float: none;
    }
  }

  .scfCheckBoxListGeneralPanel {
    margin-left: 30%;
  }

  .scfCheckbox {
    label {
      margin-left: 5px;
    }
  }

  input, select, textarea {
  }

  input[type="submit"] {
    .btn-right();
    padding: 10px 50px 10px 50px;
  }

  .scfDateSelectorShortLabelDay, .scfDateSelectorShortLabelMonth, .scfDateSelectorShortLabelYear {
    width: 33.33%;
  }

  .scfDateSelectorDay, .scfDateSelectorMonth, .scfDateSelectorYear {
    width: 33.33%;
  }

  .scfFileUpload {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .scfFileUploadLabel {
    .btn-right();
    padding: 10px 50px 10px 50px;
    width: auto;
    max-width: 70%;

    @media(min-width:481px) {
      margin-left: 30% !important;
    }
  }
}
