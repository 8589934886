@import "../../styles.less";

//
// Navs
// --------------------------------------------------


// Base class
// --------------------------------------------------

.nav {
  margin-bottom: 0;
  padding-left: 0; // Override default ul/ol
  list-style: none;
  &:extend(.clearfix all);

  li {
    position: relative;
    display: block;

    a {
      position: relative;
      display: block;
      padding: @nav-link-padding;
      &:hover,
      &:focus {
        text-decoration: none;
        //background-color: @nav-link-hover-bg;
      }
    }

    // Disabled state sets text to gray and nukes hover/tab effects
    &.disabled > a {
      color: @nav-disabled-link-color;

      &:hover,
      &:focus {
        color: @nav-disabled-link-hover-color;
        text-decoration: none;
        background-color: transparent;
        cursor: not-allowed;
      }
    }
  }

  // Open dropdowns
  .open > a {
    &,
    &:hover,
    &:focus {
      background-color: @nav-link-hover-bg;
      border-color: @link-color;
    }
  }

  // Nav dividers (deprecated with v3.0.1)
  //
  // This should have been removed in v3 with the dropping of `.nav-list`, but
  // we missed it. We don't currently support this anywhere, but in the interest
  // of maintaining backward compatibility in case you use it, it's deprecated.
  .nav-divider {
    .nav-divider();
  }

  // Prevent IE8 from misplacing imgs
  //
  // See https://github.com/h5bp/html5-boilerplate/issues/984#issuecomment-3985989
  > li > a > img {
    max-width: none;
  }
}


// Tabs
// -------------------------

// Give the tabs something to sit on
.nav-tabs {
  border-bottom: @tabs-inactive-border solid @tabs-inactive-border-color;
  > li {
    float: left;
    // Make the list-items overlay the bottom border
    margin-bottom: 0;

    // Actual tabs (as links)
    > a {
      .font-bold();
      .font-size(@tabs-font-size);
      padding: @tabs-tab-padding;
      background-color: @tabs-inactive-bg;
      margin-right: 2px;
      line-height: @line-height-base;
      border: @tabs-inactive-border solid @tabs-inactive-border-color;
      border-radius: @tabs-border-radius @tabs-border-radius 0 0;
      border-bottom: @tabs-inactive-bottom-border;
      &:hover {
        border-color: @nav-tabs-link-hover-border-color @nav-tabs-link-hover-border-color @nav-tabs-border-color;
      }
    }

    // Active state, and its :hover to override normal :hover
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: @tabs-active-link-color;
        background-color: @tabs-active-bg;
        border: @tabs-active-border solid @tabs-active-border-color;
        border-bottom: @tabs-active-bottom-border;
        cursor: default;
      }
    }
  }
  // pulling this in mainly for less shorthand
  &.nav-justified {
    .nav-justified();
    .nav-tabs-justified();
  }
}

.tabs-right > .nav-tabs,
.tabs-left > .nav-tabs {
  border-bottom: 0;
}

.tabs-left > .nav-tabs > li,
.tabs-right > .nav-tabs > li {
  float: none;
}

.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}

.tabs-left > .nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}

.tabs-left > .nav-tabs > li > a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
     -moz-border-radius: 4px 0 0 4px;
          border-radius: 4px 0 0 4px;
}

.tabs-left > .nav-tabs > li > a:hover,
.tabs-left > .nav-tabs > li > a:focus {
  //border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.tabs-left > .nav-tabs .active > a,
.tabs-left > .nav-tabs .active > a:hover,
.tabs-left > .nav-tabs .active > a:focus {
  //border-color: #ddd transparent #ddd #ddd;
  //*border-right-color: #ffffff;
}

.tabs-right > .nav-tabs {
  float: right;
  margin-left: 19px;
  //border-left: 1px solid #ddd;
}

.tabs-right > .nav-tabs > li > a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
     -moz-border-radius: 0 4px 4px 0;
          border-radius: 0 4px 4px 0;
}

.tabs-right > .nav-tabs > li > a:hover,
.tabs-right > .nav-tabs > li > a:focus {
  //border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.tabs-right > .nav-tabs .active > a,
.tabs-right > .nav-tabs .active > a:hover,
.tabs-right > .nav-tabs .active > a:focus {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}

// Pills
// -------------------------
.nav-pills:not(.wizard-steps) {
  > li {
    float: left;

    // Links rendered as pills
    > a {
      border-radius: @nav-pills-border-radius;
    }
    + li {
      margin-left: 2px;
    }

    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        color: @nav-pills-active-link-hover-color;
        background-color: @nav-pills-active-link-hover-bg;
      }
    }
  }

  &.nav-wizard {
    margin-top: 40px;
    
    > li {
      position: relative;
      overflow: visible;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;

      &:first-child {
        border-left: 0;
        a {
          border-radius:@nav-pills-border-radius 0 0 @nav-pills-border-radius;
        }

        &:before {
          display: none;
        }
      }

      &:last-child {
        border-right: 0;

        a {
          border-radius:0 @nav-pills-border-radius @nav-pills-border-radius 0;
        }
      }

      a {
        .font-bold();
        width: 140px;
        border-radius: 0;
        background-color: @nav-wizard-bg;
        cursor: default;
      }

      &:before,
      &:after {
        position: absolute;
        content: '';
        top: 0px;
        right: -20px;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 20px 0 20px 20px;
        border-color: transparent transparent transparent @nav-wizard-bg;
        z-index: 2;
      }

      &:before {
        left: -20px;
        border-color: @nav-wizard-bg @nav-wizard-bg @nav-wizard-bg transparent;
      }

      &:hover {
        &:after {
          border-color: transparent transparent transparent @nav-wizard-bg;
        }

        &:before {
          border-color: @nav-wizard-bg @nav-wizard-bg @nav-wizard-bg transparent;
        }
        
        a {
          background-color: @nav-wizard-bg;
          color: @nav-wizard-color;
        }
      }

      &.active {
        &:after {
          border-color: transparent transparent transparent @nav-wizard-active-bg;
        }

        &:before {
          border-color: @nav-wizard-active-bg @nav-wizard-active-bg @nav-wizard-active-bg transparent;
        }

        a {
          background-color: @nav-wizard-active-bg;
          color: @nav-wizard-active-color;
        }
      }
    }
  }
}


// Stacked pills
.nav-stacked {
  > li {
    float: none;
    + li {
      margin-top: 2px;
      margin-left: 0; // no need for this gap between nav items
    }
  }
}


// Nav variations
// --------------------------------------------------

// Justified nav links
// -------------------------

.nav-justified {
  width: 100%;

  > li {
    float: none;
     > a {
      text-align: center;
      margin-bottom: 5px;
    }
  }

  > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
  }

  @media (min-width: @screen-sm-min) {
    > li {
      display: table-cell;
      width: 1%;
      > a {
        margin-bottom: 0;
      }
    }
  }
}

// Move borders to anchors instead of bottom of list
//
// Mixin for adding on top the shared `.nav-justified` styles for our tabs
.nav-tabs-justified {
  border-bottom: 0;

  > li > a {
    // Override margin from .nav-tabs
    margin-right: 0;
    border-radius: @border-radius-base;
  }

  > .active > a,
  > .active > a:hover,
  > .active > a:focus {
    border: 1px solid @nav-tabs-justified-link-border-color;
  }

  @media (min-width: @screen-sm-min) {
    > li > a {
      border-bottom: 1px solid @nav-tabs-justified-link-border-color;
      border-radius: @border-radius-base @border-radius-base 0 0;
    }
    > .active > a,
    > .active > a:hover,
    > .active > a:focus {
      border-bottom-color: @nav-tabs-justified-active-link-border-color;
    }
  }
}


// Tabbable tabs
// -------------------------

// Hide tabbable panes to start, show them when `.active`
.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    padding: @tabs-pane-padding;
    background-color: @tabs-active-bg;
    display: block;
  }

  table {
    &:extend(.linklist-spot table);   
  }
  tr {
    &:extend(.linklist-spot tr);
  }
  td {
    &:extend(.linklist-spot td);
  }
}


// Dropdowns
// -------------------------

// Specific dropdowns
.nav-tabs .dropdown-menu {
  // make dropdown border overlap tab border
  margin-top: -1px;
  // Remove the top rounded corners here since there is a hard edge above the menu
  .border-top-radius(0);
}
