﻿@import "../../Global/Components/spoergsmaal-og-svar-liste-spot-a.less";

.wrapper.q-and-a {
  background: #fff;

  .bubble {
    &:before {
      border-bottom-color: #fff;
    }

    &:after {
      border-bottom-color: #fff;
    }

    .icon {
      color: @color-text;
    }

    .text {
      a {
        color: @color-text;
      }
    }
  }
}

//Question answer spot
.question-answer-spot {
  margin-bottom: 30px;

  .js-sync-height & {
    @media screen and (max-width: 767px) {
      min-height: inherit !important;
    }
  }

  .two-col-spot .col-sm-6 &, .three-col-spot .col-sm-4 & {
    padding-bottom: 33px !important;
  }

  &__header {
    display: inline-table;
    width: 100%;
    background: transparent;
    padding: 10px 38px 10px 38px;

    .two-col-spot &, .three-col-spot & {
      padding: 0;
      margin-bottom: 10px;
    }

    &__title {
      float: none;
      .font-size(2.5);
      font-family: SceneAlt,serif;
      margin: 0;
      color: @color-primary-darker;

      .two-col-spot &, .three-col-spot & {
        .font-size(2.0);
        color: @color-primary-dark;
        float: none;
      }

      @media (min-width: 769px) {
        float: left;
      }
    }

    &__link {
      float: right;
      font-family: SceneAlt, serif;
      color: @color-brand-200;
      position: relative;
      top: 3px;

      .two-col-spot &, .three-col-spot & {
        display: inline-block;
        font-family: Arial, sans-serif;
        float: none;
        margin-bottom: 20px;
      }

      @media (max-width: 768px) {
        float: none;
        margin-top: 10px;
        margin-bottom: 5px;
        display: inline-block;
      }

      &:hover {
        cursor: pointer;
      }

      &:after {
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        .font-size(@type-size-xs);
        margin-left: 5px;
        display: inline-block;
      }
    }
  }

  &__body {
    background: @color-text-light;
    padding: 30px 38px 20px;
    margin-bottom: 0;

    h3 {
      margin-top: 0 !important;
      margin-bottom: 5px !important;
      .font-size(1.6);
    }

    .two-col-spot &, .three-col-spot & {
      padding: 0;
    }

    li {
      margin-bottom: 20px;
    }

    &__link {
      border-bottom: 1px solid @color-menu-selected-tab;
      font-family: Arial,sans-serif;
      text-decoration: none;
      .font-size(1.6);

      &:hover {
        border-bottom: 1px solid @color-brand-200;
        text-decoration: none;
        cursor: pointer;
      }
    }

    &__text {
      color: @color-primary-dark;
      margin-top: 5px;
      font-family: Arial,sans-serif;
    }
  }
}
