@media print {
  .subsite-wide {
    .cta-links,
    .slider .slider__controls,
    .slider.slider--offset .slider__slide-offset,
    .slider.slider--offset .slider__controls {
      display: none;
    }

    .link-card {
      border: 1px solid @sw-color-border-light;
      page-break-inside: avoid;
    }

    .link-cards .link-card {
      min-height: 475px;
    }

    .link-card .card__image {
      height: 230px;
    }

    .link-card .card__image img,
    .slider-mixed-card .category-card .card__image img {
      width: 100% !important;
    }

    .category-list {
      padding-top: 0;
    }

    .link-card-carousel,
    .category-list .link-card-carousel + .link-card-carousel {
      padding-bottom: @padding-large !important;
    }

    .slider .slider__track {
      scroll-snap-type: none;
      overflow-y: auto;
      overflow-x: auto;
      flex-wrap: wrap;
    }

    .slider {
      position: relative;
      width: 100%;
      max-width: 1120px;
      padding-left: 40px !important;
      padding-right: 40px !important;
      margin: 0 auto;
    }

    .slider.slider--offset .slider__slide {
      max-width: 45vw;
      width: 45vw;
      margin: 0 !important;
    }

    .slider .slider__slide img {
      width: 100% !important;
    }

    .kaltura-video__caption .caption {
      position: static;
      bottom: auto;
      left: auto;
      transform: none;
    }

    .kaltura-video__rte .description {
      padding: 15px;
    }

    .media-carousel .slider .slider__slide {
      padding-bottom: @spot-spacing;
    }

    .forum-category-page .forum-category:before {
      background-color: transparent;
    }

    .forum-category-page .forum-category .intro .intro__header {
      padding-left: 60px !important;
    }

    .forum-category-page .forum-category .forum-category-content-container {
      height: auto;
      opacity: 1;
    }

    .forum-category-page .forum-category .forum-category-content__show-more {
      display: none;
    }
  }
}
