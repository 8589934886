﻿@import "../../styles.less";

table.table-striped {
    width: 100%;
    border-collapse: separate;
    border-spacing: 1px 0;

    tr {
      background: @color-text-light;
      &:nth-child(odd) {
        background: @color-contrast-400;

        td {
          &:first-child {
            background: @color-contrast-400;
          }
        }
      }

      &:nth-child(even) {
      }

    td {
      //.font-bold();
      //.font-size(@type-size-xl);
      padding: 10px;
      &.header {
        .font-bold();
        width: 35%;
      }

      &.date {
        .font-regular();
        .font-size(@type-size-base);
      }

      &.td-80 {
        width: 80%;
      }
      &.td-20 {
        width: 20%;
      }
    }
  }
}