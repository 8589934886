﻿.booking-categories {
  &__category {
    /*&__title {
      margin: 50px 0 10px;
      font-family: "SceneAlt", serif;
      font-size: 30px;
      line-height: 40px;
    }*/
    &__abstract {
      margin-bottom: 25px;
      padding: 0 38px;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
    }

    &__action {
      margin: 0 auto 35px;
    }
  }

  &__teaser, &__offer {
    margin: 0 0 10px;
    background-color: white;

    @media only screen and (min-width: 480px) {
      height: 171px !important;
    }

    + .booking__categories {
      margin-top: 70px;
    }

    &__image {
      height: 130px;
      padding: 0;
      overflow-y: hidden;

      @media only screen and (min-width: 480px) {
        height: 171px;
        overflow: hidden;
        overflow-y: hidden !important;
        width: 33%;
        float: left;
      }

      img {
        min-width: 100%;
        height: auto;
        overflow: hidden;

        @media only screen and (min-width: 480px) and (max-width: 769px) {
          transform: scale(1.4);
        }
      }
    }

    &__description {
      padding: 30px;

      @media only screen and (min-width: 480px) {
        width: 66%;
        float: right;
      }

      &__title {
        font-family: "SceneAlt", serif;
        font-size: 20px;
        color: @link-secondary-color;

        .content-area & {
          margin: 0 0 13px;
        }
      }

      &__text {       
        margin-top: 0;
        color: @color-primary-dark;
      }

      p {        
        margin-top: 0;
        color: @color-primary-dark;
      }

      a:after {
        content: "\e631";
        font-family: "ft-icons";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        padding-left: 5px;
        position: relative;
        top: 0;
        .font-size(1);
        display: inline-block;
      }
    }
  }

  &__box {
    background-color: white;
    margin-bottom: 10px;
    padding: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .booking-categories {
    &__offer, &__teaser {
      height: 171px;
      overflow: hidden;
      margin-bottom: 10px !important;

      &__image {
        height: 171px;
        overflow-y: auto;
        overflow-x: hidden;

        img {
          max-height: 171px;
          overflow: hidden;
        }
      }
    }
  }
}
