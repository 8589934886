﻿@import "../styles.less";

.pagination-container {
  margin-bottom: 10px;

  .pagination-text-container-top {
    margin: 0 0 10px 0;
  }

  .pagination-text-container-bottom {
    margin: 10px 0 0 0;
  }

  .pagination {
    &.pagination-centered {
      background: @color-brand-info-700;
      padding: 9px;
      margin: 10px 0;
      width: 100%;

      &.alphabet {
        > li {
          > a, > span {
            padding: 6px 0;
            float: left;
            width: 28px;
            text-transform: uppercase;
          }
        }
      }

      > li {
        position: relative;

        input[type="text"] {
          width: 52px;
          .font-regular();
          .font-size(@type-size-lg);
          text-align: center;

          &::-webkit-input-placeholder {
            .font-size(@type-size-sm, @type-size-lg);
          }

          &:-moz-placeholder { /* Firefox 18- */
            .font-size(@type-size-sm, @type-size-lg);
          }

          &::-moz-placeholder { /* Firefox 19+ */
            .font-size(@type-size-sm, @type-size-lg);
          }

          &:-ms-input-placeholder {
            .font-size(@type-size-sm, @type-size-lg);
          }
        }

        > a, > span {
          background: @color-brand-info-200;
          color: @color-text-light;
          border-radius: 3px;
          border: none;
          padding: 6px 11px;
          .font-bold();
          .font-size(@type-size-md);
          margin: 0 2px 4px;
          float: none;

          @media (max-width: @screen-xs) {
            float: left;
          }

          &.active {
            background: @color-brand-200;
          }

          &:hover {
            background: @color-brand-200;
            color: @color-text-light;
          }

          &.gap {
            background: @color-contrast-300;
            color: @color-text;
            letter-spacing: 1px;
            cursor: default;
          }
        }

        &.previous, &.next {
          > a, > span {
            /*background: none;
            .font-regular();
            .font-size(@type-size-lg);
            color: @color-brand-info-200;*/
          background: none;
          color: @color-brand-info-200;
          border: none;
          padding: 6px 11px;
          .font-bold();
          .font-size(@type-size-lg);
          margin: 0 2px 1px;


            &:hover {
              color: @color-brand-200;
            }
          }

          [class*="icon-pil"] {
            position: relative;
            top: 0;

            &:before {
              .font-size(@type-size-xs);
            }
          }
        }

        &.previous {
          float: left;

          a {
            //padding-left: 0;
          }
        }

        &.next {
          float: right;

          a {
            //padding-right: 0;
          }
        }
      }
    }
  }

  .pagination-displays {
    .font-regular();
    .font-size(@type-size-md);

    .results {
      .font-bold();
    }
  }

  .pagination-steps {
    .font-regular();
    .font-size(@type-size-md);

    a {
      text-decoration: underline;
      color: @color-brand-200;
      margin-left: 10px;

      &:hover {
        text-decoration: none;
      }

      &.active {
        .font-bold();
        text-decoration: none;
        color: @color-text;
      }
    }
  }
}

@media(max-width:@screen-sm-max) {
  .pagination-container {
    .pagination-displays {
      display: block;
      width: 100%;
    }

    .pagination-steps {
      display: block;
      width: 100%;
      text-align: left;
    }
  }
}

@media(max-width:@screen-md) {
  .pagination {
    &.pagination-centered {
      > li {
        margin-bottom: 5px;

        input[type="text"] {
          display: none;
          visibility: hidden;
        }

        > a, > span {
          margin: 0 2px;
        }

        &.previous, &.next {
          a {
            background: @color-brand-info-500;

            .text {
              .text-hide();
            }
          }
        }
      }
    }
  }
}

.article-section {
  > h2 {
    margin-top: 40px;
  }
}

div + .article-navigation {
  margin-top: 20px;
}

.article-navigation.pagination {
  list-style: none;
  margin-bottom: 20px;
  //&:extend(.pagination, .pagination-container .pagination.pagination-centered);
  > li {
    height: 28px;
    line-height: 28px;
    //&:extend(.pagination-container .pagination > li, .pagination > li);
    &.previous {
      // &:extend(.pagination-container .pagination.pagination-centered > li.previous);
    }

    &.next {
      //&:extend(.pagination-container .pagination.pagination-centered > li.next);
    }

    > a {
      // &:extend(.pagination-container .pagination.pagination-centered > li.next > a);
    }

    &.centered {
      //width: 400px;
      display: inline-block;
      text-align: center;

      input[type="submit"] {
        &:extend(.pagination-container .pagination.pagination-centered > li > a);
        margin-bottom: 0;
      }

      .quick-jump {
        width: 70px;
        margin-left: 2px;

        @media(max-width:@screen-md) {
          display: inline-block;
          visibility: visible;
        }
      }
    }
  }
}

h1 + .article-navigation {
  display: none;
}
