﻿@import "../Variables/_colors.less";
@import "../Variables/_typography.less";

.publications-container {
  .filter {
    padding: 20px 18px;

    .filter-tags {
      padding: 0 17px;

      .filter-tag {
        .input-group {
          margin-right: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.publications {
  width: 100%;
  padding: 0;
  margin: 0;

  thead {
    th {
      .font-regular();
      padding: 10px 0;
    }
  }

  tbody {
    tr:nth-child(odd) {
      background-color: #fff;
    }
  }

  tr {
    border-bottom: 10px solid @color-background-main;
    background-color: #fff;
  }

  &__addtobasket {
    @media only screen and (max-width: @screen-md) {
      min-width: 0;
    }

    &:after {
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_publikationplus:before);
      position: absolute;
      top: 50%;
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      -o-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      right: 33px;
      font-size: 40px;

      @media only screen and (max-width: @screen-md) {
        left: 0;
        right: 0;
      }

      @media only screen and (max-width: @screen-xs) {
        font-size: 25px;
      }
    }
  }

  &__download {
    @media only screen and (max-width: @screen-md) {
      min-width: 0;
    }

    &:after {
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_download:before);
      position: absolute;
      top: 50%;
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      -o-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      right: 30px;
      font-size: 35px;

      @media only screen and (max-width: @screen-md) {
        left: 0;
        right: -5px; //visually centering it
      }

      @media only screen and (max-width: @screen-xs) {
        font-size: 25px;
      }
    }
  }

  &__nested {
    @media only screen and (max-width: @screen-md) {
      min-width: 0;
    }

    &:after {
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_document:before);
      position: absolute;
      top: 50%;
      -moz-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      -o-transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      right: 32px;
      font-size: 35px;

      @media only screen and (max-width: @screen-xsm) {
        font-size: 25px;
      }

      @media only screen and (max-width: @screen-md) {
        left: 0;
        right: 0;
      }
    }
  }

  address {
    padding: 20px 38px 0;
  }
}

.publications-mini-basket,
.publications-form,
.form-horizontal {
  margin: 20px 0;
  padding: 15px 38px;

  .validation-msg {
    padding-left: 0;

    span {
      display: inline-block;
      line-height: 30px;
    }
  }

  .form-group {
    label {
      text-align: left;
      display: block;
      float: none;
      .font-alt();
      .font-size(1.6);
      padding: 0 0 5px 16px;
    }

    .required {
      &:after {
        content: '';
        position: absolute;
        top: -8px;
        right: 7px;
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-left: 8px solid @color-primary-function-bg;
        border-right: 8px solid transparent;
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      &__star {
        display: none;
      }
    }

    .validation-msg {
      [style*="red"] {
        color: @color-brand-200 !important;
      }
    }
  }

  .delete-from-basket {
    display: block;
    font-size: 20px;
    text-align: center;
    color: @color-primary-function;

    &:hover {
      color: @color-primary-function-hover;
    }

    &:after {
      display: inline-block;
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_basketminus:before);
    }

    span {
      display: block;
      width: 0;
      height: 0;
      overflow: hidden;
    }
  }

  .col-sm-6, .col-sm-5 {
    .make-sm-column(8);

    .form-control {
      height: 62px;
      .font-size(1.6);
    }
  }
}

.publications-form {
  color: @color-primary-dark;

  .validation-msg {
    color: @color-brand-200 !important;
    font-size: 1.4rem;
    padding-left: 15px;
  }

  input:invalid {
    font-weight: normal;
  }

  .checkbox-wrapper {
    margin-top: 30px;

    .custom-checkbox {
      display: inline-block;
      float: left;
    }

    .publication-label-wrapper {
      display: block;

      .publication-checkbox-title {
        display: table;
        font-family: SceneAlt, serif;
        position: relative;
        margin-bottom: 30px;
        top: 2px;
        left: 5px;
      }

      .publication-checkbox-text {
        font-family: arial;
        font-size: 16px;
      }
    }
  }
}



.publications-form__button {
  &--left {
    margin-left: 23px;
  }

  &--right {
    margin-right: 23px;
  }
}
.questionandanswer-title {
  font-size: 18px !important;
}
.publications-mini-basket, .publications-confirmation {
  .clearfix();
  margin: 50px 0;
  padding: 30px 38px;
  background-color: #fff;

  .publications-checkout-c2a {
    .clearfix();

    a {
      margin: 0;
      text-align: center;
      padding: 10px 50px;
      border-radius: 0;
      .font-alt();
      .font-size(1.8);

      &:before {
        content: '';
        display: none;
        background-color: @color-primary-function-bg;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }

  .table {
    margin: 0;
    width: 100%;

    tbody {
      tr {
        background-color: #fff;
      }
    }

    .publications-col {
      padding: 10px 0;

      .publications-description {
        display: none;
      }
    }

    .publications-col-description {
      padding: 10px 0;
      width: 67%;
      color: @color-text;

      @media only screen and (max-width: @screen-sm) {
        padding-right: 10px;
      }

      .news-description {
        font-size: 14px;
        font-size: 1.4rem;
      }

      .news-title {
        font-size: 18px;
        font-size: 1.8rem;
      }

      h2 {
        margin-top: 0;
        margin-bottom: 0;
        line-height: 24px;
        line-height: 2.4rem;
      }
    }

    .publications-col-call-to-action {
      padding: 10px 0;
      width: 18%;
      min-width: 34px;
      text-align: center;
      vertical-align: middle;
      background-color: transparent;

      @media only screen and (min-width: @screen-sm-min) {
        min-width: 80px;
      }
    }

    .form-group {
      position: relative;
      margin-bottom: 0;
      display: flex;
      align-items: center;

      input, label {
        padding: 0;
        margin: 0;
      }

      input {
        background-color: @color-secondary-light-2;
      }

      .form-control {
        text-align: center;
        padding: 0;
        right: 0;
      }

      label {
        margin-left: 10px;

        @media (max-width: @screen-xs-max) {
          .responsive-invisibility();
        }
      }
    }

    input {
      width: 38px;
    }

    label {
      font-weight: normal;
      margin-left: 5px;
    }

    th {
      color: @color-primary-dark;
      text-align: left;
      padding: 0 0 15px;
      .font-alt();
      .font-size(@type-size-xxl);

      &.publications-header-amount {
        text-align: left;
      }
    }

    tr {
      border-bottom: 1px solid @color-contrast-300;
    }

    tbody {
      tr {
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}

.publications-confirmation {
  padding: 30px 38px 0;
  margin: 0 0 20px;
  color: @color-primary-dark;

  address {
    margin: 40px 0;
  }

  .table {
    tr {
      th {
        text-align: right;
      }
    }

    .publications-col-call-to-action {
      text-align: right;
    }
  }
}

.publications-header-amount {
  text-align: center;
}

.publications-paging {
  padding: 0 15px;
}

.publications-col {
  &.publications-col-image {
    width: 15%;
    min-width: 30px;
    padding-right: 10px !important;

    @media only screen and (max-width: @screen-xs) {
      vertical-align: top;
      padding-top: 15px;
    }
  }

  &.publications-col-description {
    width: 60%;
    .font-size(1.4);
    padding: 20px 20px 20px 0;
    color: @color-text;

    @media only screen and (max-width: @screen-md) {
      padding: 10px;
    }

    @media only screen and (max-width: @screen-xs) {
      vertical-align: top;
    }

    span.publications-title {
      .font-alt();
      .font-size(1.6);
      display: inline-block;
      color: @color-text;

      @media only screen and (min-width: @screen-sm-min) {
        .font-size(@type-size-xxl);
      }
    }

    a.publications-title {
      .font-alt();
      .font-size(@type-size-xxl);
      border-bottom: 1px solid transparent;
      display: inline-block;
      color: @color-primary-link;
      margin-bottom: 20px;

			&:after {
				display: inline-block;
				&:extend(.less-icon-extend);
				&:extend(.icon-ft_bullit_right:before);
				font-size: 11px;
				position: relative;
				margin-left: 5px;
				vertical-align: middle;
			}

      &:hover {
        border-bottom: 1px solid @color-primary-link-hover;
        text-decoration: none;
      }

    }

    p {
      font-size: 14px;
      font-size: 1.4rem;
    }

    .publications-description--pubinfo {
      margin-bottom: 0;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 0;
      line-height: 24px;
      line-height: 2.4rem;
    }
  }

  &.publications-col-call-to-action {
    width: 25%;
    background-color: @color-contrast-400;
    vertical-align: top;

    .input-group {
      width: 100%;
      height: 50%;
      color: white;

      &:before {
        content: '';
        display: inline-block;
        padding-top: 80px;
        vertical-align: middle;
      }

      a {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0;
        padding: 0;
        background-color: @color-contrast-400;
        border-radius: unset;
        .font-alt();
        .font-size(1.8);
        color: @color-primary-link;

        &:before {
          padding-top: 22px;
          background: @color-contrast-400;
          border-radius: unset;
          font-size: 40px;
          padding-left: 0;
          right: 15px;
        }

        span {
          position: absolute;
          top: 50%;
          -moz-transform: translate(0, -50%);
          -ms-transform: translate(0, -50%);
          -o-transform: translate(0, -50%);
          -webkit-transform: translate(0, -50%);
          transform: translate(0, -50%);
          left: 30px;

          @media only screen and (max-width: @screen-lg) {
            .font-size(1.6);
          }

          @media only screen and (max-width: @screen-md) {
            display: none;
          }
        }

        &:hover {
          color: @color-primary-link-hover;
          background-color: #d1d1d1;

          &:after {
            color: @color-primary-function;
          }
        }
      }
    }
  }
}

.publications-checkout-c2a {
  @media only screen and (max-width: @screen-md) {
    display: block;
    max-width: 90%;
    margin: auto;
    margin-top: 30px;
    text-align: center;

    a {
      width: auto;
      max-width: 100%;
      padding: 10px 25px;
      white-space: initial;
      .font-size(1.6);
    }
  }

  @media only screen and (min-width: @screen-md-min) {
    margin-top: 30px;
    float: right;
  }
}

.publications-receipt {
  padding: 40px 38px;
  background: #fff;
  margin: 0 -15px;
  color: @color-text;
}

.subscriptions .nav-pills.nav-wizard {
  color: @color-text;
  table-layout: fixed;
  width: 100%;
}

.subscriptions .wizard-steps li {
  width: 33.5%;

  @media only screen and (max-width: @screen-xs-max) {
    width: 100%;
  }
}

.news-date {
  margin-bottom: 10px;
}

.publication__navigation {
  @media (max-width: @screen-xsm) {
    .input-group {
      &.pull-left,
      &.pull-right {
        display: block;
        float: none !important;
      }

      .publications-form__button--left,
      .publications-form__button--right {
        margin: 0 23px;
        width: ~"calc(100% - 46px)";
      }

      &:nth-child(1) {
        margin-bottom: 10px;
      }
    }
  }
}
