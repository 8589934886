﻿@import "../../Global/Components/linkliste-spot-a.less";

.link-list-spot-a {
  background: #fff;
  color: @color-primary-dark;
  padding: 30px 38px;
  margin-top: 0;
  margin-bottom: 30px;

  .js-sync-height & {
    @media screen and (max-width: 767px) {
      min-height: inherit!important;
    }
  }


  h2 {
    font-size: 2rem;
    font-family: Asap;
    font-family: SceneAlt, serif;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 30px;
    line-height: 3rem;
    margin-top: 10px;
    margin-bottom: 10px;

    &:nth-child(1) {
      margin-top: 0;
    }
  }

  h4 {
    .module-headline();
  }
}

.link-list-spot-a ul .link-list-spot-a-link .icon-pil-hoejre {
  &:before {
    &:extend(.icon-ft_bullit_right:before);
  }
}

//Grid linklist spot
.grid-link-list-a {
  padding: 25px 55px;
  margin-bottom: 30px;
  margin-top: 0;
  background-color: #fff;
  .clearfix();

  ul {
    padding: 0;
  }

  .link-list-spot-a-link {
    list-style-type: none;

    @media screen and (max-width: @screen-xs) {
      margin-bottom: 10px;
    }

    a {
      &:before {
        display: inline-block;
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        font-size: 11px;
        float: left;
        margin: 4px 0 0 -15px;
      }
    }
  }
}

ul li {
  list-style-type: none;
}

ul.link-list {
  display: table;
  padding-left: 0;

  li {
    vertical-align: middle;
    list-style-type: none !important;

    &:hover {
      background-color: transparent;
    }

    span {
      padding-left: 0 !important;

      &:before {
        display: none !important;
        content: "" !important;
      }
    }

    a {
      display: inline-block;
      padding-left: 15px;
      border-bottom: none;

      &:hover {
        color: @link-hover-color;
        text-decoration: underline;
        background-color: transparent;
        border-bottom: none;
      }

      &:before {
        display: inline-block;
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        font-size: 11px;
        float: left;
        margin: 4px 0 0 -15px;
      }
      //RTE class
    }
  }
}

a.front-arrow {
  padding-left: 0;
  border-bottom: 0;

  &:hover {
    border-bottom: 0;
    text-decoration: underline;
  }

  &:after {
    content: "";
    display: none;
  }

  &:before {
    display: inline-block;
    &:extend(.less-icon-extend);
    &:extend(.icon-ft_bullit_right:before);
    font-size: 11px;
    position: relative;
    margin-left: 0;
    padding-right: 5px;
  }
  &:hover:before {
    text-decoration: none;
  }

  h1 &:after {
    font-size: 3.6rem;
  }

  h2 &:after {
    font-size: 1.6rem;
  }

  h6 &:after {
    font-size: 0.8rem;
  }
}
