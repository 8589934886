﻿@import "../../Global/Components/ftMember.less";

.ftMember {
  &__phone-value {
    border-bottom: 1px solid @color-menu-selected-tab;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid @color-primary-function;
    }

    @media (min-width: @screen-md) {
      border-bottom: none;
      pointer-events: none;
      color: @color-primary-darker;
    }
  }

  &__infolinks-value {
    border-bottom: 1px solid @color-menu-selected-tab;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid @color-primary-function;
    }
  }

  &__generic-info-container {
    margin-bottom: 10px;
  }

  .bio-image {
    width: 100%;
    height: auto;
    max-width: 220px;

    @media (max-width: @screen-xsm) {
      display: block;
      margin: 0 auto;
      margin-bottom: 30px;
    }
  }

  .person {
    margin-top: 30px;
  }
  // general styling due to webservice delivery
  strong {
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    display: inline-block;
    margin: 0 38px 5px !important;
  }

  article {
    margin: 0 38px 30px !important;

    h3, ul, strong {
      margin-left: 0 !important;
    }
  }

  .panel-group .panel-middle .panel-body p {
    margin: 0 !important;
  }

  .member-link {
    display: block;
    border-bottom: 0 !important;
  }

  .member-link:hover {
    text-decoration: underline !important;
  }

  .tab-subtitle {
    display: block !important;
  }

  .download__container__docBtns__btn {
    &:hover {
      text-decoration: none !important;
    }
  }

  .person__container__address {
    p {
      margin: 0 0 10px 0 !important;
    }
  }

  .ftMember {
    &__phone-value {
      border-bottom: 1px solid @color-menu-selected-tab;

      &:hover {
        text-decoration: none;
        border-bottom: 1px solid @color-primary-function;
      }

      @media (min-width: @screen-md) {
        border-bottom: none;
        pointer-events: none;
        color: @color-primary-darker;
      }
    }

    &__infolinks-value {
      border-bottom: 1px solid @color-menu-selected-tab;

      &:hover {
        text-decoration: none;
        border-bottom: 1px solid @color-primary-function;
      }
    }

    &__generic-info-container {
      margin-bottom: 10px;
    }

    .bio-image {
      width: 100%;
      height: auto;
      max-width: 220px;

      @media (max-width: @screen-xsm) {
        display: block;
        margin: 0 auto;
        margin-bottom: 30px;
      }
    }

    .person {
      margin-top: 30px;
    }
    // general styling due to webservice delivery
    strong {
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      display: inline-block;
      margin: 0 38px 5px !important;
    }

    article {
      margin: 0 38px 30px !important;

      h3, ul, strong {
        margin-left: 0 !important;
      }
    }

    .panel-group .panel-middle .panel-body p {
      margin: 0 !important;
    }

    .member-link {
      display: block;
    }

    .tab-subtitle {
      display: block !important;
    }

    .download__container__docBtns__btn {
      &:hover {
        text-decoration: none !important;
      }
    }

    .person__container__address {
      p {
        margin: 0 0 10px 0 !important;
      }
    }

    .person__container__contactinfo {
      .display-row {
        display: inline-flex;
        flex-direction: column;

        @media (min-width: @screen-sm) {
          flex-direction: row;
        }

        justify-content: flex-start;
        flex-basis: auto;
        margin-bottom: 15px;

        @media (min-width: @screen-sm) {
          margin-bottom: 0;
        }

        .info-label {
          word-break: keep-all;
          margin-right: 0;
          margin-bottom: 5px;
        }

        .info-value {
          display: flex;
          flex: 0 0 auto;
        }

        .display-cell {
          vertical-align: top;

          &:first-child {
            padding-right: 0;
          }
        }
      }
    }
    // Fixes BUG-36735
    .panel-collapse a:not([href^="mailto:"]) {
      &:before {
        display: inline-block;
        font-size: 11px;
        line-height: 1;
        margin-right: 6px;
        font-family: "ft-icons";
        &:extend(.icon-ft_bullit_right:before);
      }
    }
    // End bugfix bug-36735
    #ftMember__accordion__container__tab4 p {
      margin-bottom: 30px;
    }

    #ftMember__accordion__container__tab4 ul {
      margin: 30px 38px;
    }

    .ftMember__accordion__container {
      .panel-body {
        color: @color-primary-dark;
      }
    }
  }

  div.legacy-member {

    strong, h3, h4 {
      margin-left: 0 !important;
    }

    &__body {
      padding-left: 38px !important;

      p {
        margin-bottom: 30px !important;
      }
    }
  }

  .ftMember .panel-group .panel-middle .panel-body.legacy-member__body .legacy-member__secondary-content p {
    margin-bottom: 30px !important;
  }

  .person__container__contactinfo {
    .display-row {
      display: inline-flex;
      flex-direction: column;

      @media (min-width: @screen-sm) {
        flex-direction: row;
      }

      justify-content: flex-start;
      flex-basis: auto;
      margin-bottom: 15px;

      @media (min-width: @screen-sm) {
        margin-bottom: 0;
      }

      .info-label {
        word-break: keep-all;
        margin-right: 0;
        margin-bottom: 5px;
      }

      .info-value {
        display: flex;
        flex: 0 0 auto;
      }

      .display-cell {
        vertical-align: top;

        &:first-child {
          margin-right: 5px;
          padding-right: 0;
        }
      }
    }
  }
  // Fixes BUG-36735
  .panel-collapse a:not([href^="mailto:"]) {
    &:before {
      display: inline-block;
      font-size: 11px;
      line-height: 1;
      margin-right: 6px;
      font-family: "ft-icons";
      &:extend(.icon-ft_bullit_right:before);
    }
  }
  // End bugfix bug-36735
  #ftMember__accordion__container__tab4 p {
    margin-bottom: 30px;
  }

  #ftMember__accordion__container__tab4 ul {
    margin: 30px 38px;
  }

  .ftMember__accordion__container {
    .panel-body {
      color: @color-primary-dark;
    }
  }
}

div.legacy-member {

  strong, h3, h4 {
    margin-left: 0 !important;
  }

  &__body {
    padding-left: 38px !important;

    p {
      margin-bottom: 30px !important;
    }
  }
}

.ftMember .panel-group .panel-middle .panel-body.legacy-member__body .legacy-member__secondary-content p {
  margin-bottom: 30px !important;
}
