﻿.footer-row {
  margin-left: 0;
  margin-right: 0;

  @media only screen and (max-width: 964px) {
    margin-left: -15px;
    margin-right: -15px;
  }
}

.wrapper.footer-container {

    .sub-footer-layout-b__logo {
      padding-left: 10px;

      @media only screen and (max-width: @screen-sm-min) {
        padding-left: 0;
      }
    }

    .striped-col-brand .sub-footer-layout-b__logo{
      padding-left: 0;
    }
}

.wrapper.footer-container .equal-col-container {
  @media (min-width: @container-tablet) {
    display: flex;

    & > .equal-col {
      flex: 1;
    }
  }
}

.wrapper.footer-container .col-sm-4:after {
  content: "";
  width: 1px;
  height: 100%;
  background: #e5e5e5;
  display: block;
  position: absolute;
  left: 0;
  top: 7px;

  @media only screen and (max-width: @screen-sm-min) {
    background: transparent;

    .sub-footer-layout-b__header {
    }

    .sub-footer-layout-b__linklist div {
      padding-left: 15px;
    }
  }
}

.sub-footer-layout-b {
  .font-alt();
  color: #454545;
  margin-top: 50px;
  padding: 35px 23px;
  .font-size(1.5);
  line-height: 25px;

  .sub-footer-layout-b__linklist div {
    @media only screen and (max-width: @screen-sm-min) {
      padding-left: 15px;
    }
  }

  &__header {
    .font-alt();
    .font-size(2);
    color: #454545;
    margin-bottom: 25px;
    margin-top: 0;
    padding-left: 10px;
    padding-right: 0;

    @media only screen and (max-width: @screen-sm-min) {
      padding-left: 0;
    }



    .striped-col-brand & {
      padding-left: 0;
    }

    @media only screen and (max-width: @screen-sm) {
      // margin: 25px 0 15px;
      margin: 10px 0 15px;
    }

    &:before {
      padding-right: 10px;
      font-size: 25px;
      position: relative;
      top: 5px;
    }
  }

  &__contact-info {
    @media only screen and (max-width: @screen-sm-min) {
      margin-bottom: 30px;
    }
  }

  &__body {
    padding-left: 10px;

    @media only screen and (max-width: @screen-sm-min) {
      padding-left: 0;
      margin-bottom: 30px;
    }
  }

  &__chat {
    padding-left: 10px;

    @media only screen and (max-width: @screen-sm-min) {
      padding-left: 0;
    }

    img {
      .middle-row .content-area & {
        width: auto !important;
        max-width: 60px !important;
      }
    }
  }

  &__contact-info {
    .text-spot-a-wrapper {
      padding: 0;
      margin: 0!important;
    }

    p {
      margin: 0;
    }

    ul {
      padding-left: 1px;

      li {
        list-style-type: none;
      }
    }

    ul.link-list {
      display: table;
      padding-left: 0;

      li {
        vertical-align: middle;
        list-style-type: none;

        &:hover {
          background-color: transparent;
        }

        span {
          padding-left: 0 !important;

          &:before {
            display: none !important;
            content: "" !important;
          }
        }

        a {
          display: inline-block;
          padding-left: 15px;
          border-bottom: none;

          &:hover {
            color: @link-hover-color;
            text-decoration: underline;
            background-color: transparent;
          }

          &:before {
            display: inline-block;
            &:extend(.less-icon-extend);
            &:extend(.icon-ft_bullit_right:before);
            font-size: 11px;
            float: left;
            margin: 8px 0 0 -15px;
          }
        }
      }

      a.front-arrow {
        padding-left: 0;
        border-bottom: 0;

        &:hover {
          border-bottom: 0;
          text-decoration: underline;
        }

        &:before {
          content: "";
          display: none;
        }

        &:after {
          display: inline-block;
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_bullit_right:before);
          font-size: 11px;
          position: relative;
          margin-left: 5px;
        }
      }
    }
  }

  &__linklist {
    div {
      margin-bottom: 8px;
      padding-left: 30px;
    }

    a {
      display: block;
      padding-left: 15px;
      color: @color-primary-function;

      &:before {
        display: inline-block;
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        font-size: 11px;
        float: left;
        margin: 7px 0 0 -15px;
      }
    }
  }
}
