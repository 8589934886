@import "../Variables/_colors.less";

.sagsforloeb-toggle {
    //border-top: 1px solid #e2e2e2;
    border-bottom: 1px solid #e2e2e2;
    padding: 16px 0 16px 37px;
    .make-row();

    &__sagsforloeb, &__menu {
        display: inline-block;
        //margin: 10px 0;
        cursor: pointer;
        .font-alt();
        .font-size(1.8);

        &:before {
            margin-right: 5px;
            &:extend(.less-icon-extend);
        }
    }

    &__sagsforloeb {
        //margin-right: 20px;
        &:before {
            &:extend(.icon-ft_casemenu:before);
        }

        .sagsforloeb-toggled & {
            color: #bb506d;
        }
    }

    &__menu {
        //margin-right: 5px;
        &:before {
            &:extend(.icon-ft_menu:before);
        }

        .menu-toggled & {
            color: #bb506d;
        }
    }

    &.menu-toggled ~ .sagsforloeb,
    &.sagsforloeb-toggled ~ .menucontent {
        display: none;
    }
}

.sagsforloeb-vis-alt {
    padding: 10px 24px;
    list-style: none;
    display: none;
    .make-row();

    @media (max-width: @screen-sm) {
        display: block;
    }

    &__link {
        color: @link-color;
        cursor: pointer;


        &:hover {
            text-decoration: none;
        }

        &:before {
            width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            color: #FFF;
            border-radius: 28px;
            display: inline-block;
            background: @color-primary-function-bg;
            margin-right: 5px;
            transition: .3s transform;
            &:extend(.less-icon-extend);
            &:extend(.icon-ft_bullit_down:before);
        }

        &:after {
            content: attr(data-show-text);
        }

        .sagsforloeb--showAll & {

            &:before {
                transform: rotate(180deg);
            }

            &:after {
                content: attr(data-hide-text);
            }
        }
    }
}

.sagsforloeb-menu {
    border-top: 1px solid #e2e2e2;
}

.sagsforloeb, .sagsforloeb-menu {
    display: block;
    padding: 0;
    margin-bottom: 0;
    .make-row();
    .font-alt();

    .left-menu-wrapper & {
        @media (min-width: @screen-xs) {
            display: block;
        }
    }

    .content-area & {
        @media (max-width: @screen-xs-max) {
            display: block;
        }
    }

    &__step {
        position: relative;
        list-style: none;
        padding: 20px 10px 15px 70px;

        &.top {
            padding: 20px 10px 20px 23px;

            .sagsforloeb__step__heading {
                padding-left: 10px;
                font-size: 14px;
                font-size: 1.4rem;

                &:before {
                    content: "\e631";
                    position: absolute;
                    display: inline-block;
                    font-size: 0.7em;
                    vertical-align: middle;
                    left: 0px;
                    top: 5px;
                    font-family: "ft-icons";
                    speak: none;
                    font-style: normal;
                    font-weight: normal;
                    font-variant: normal;
                    text-transform: none;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
                
                a{
                  color: @color-contrast-150;
                  &:hover{
                    color: @color-brand-200;
                  }
                }
            }

            &.active {
                .sagsforloeb__step__heading {
                    a {
                        color: @color-brand-200;
                    }
                }
            }
        }


        &__heading {
            position: relative;
            margin: 0;
            line-height: 1;
            margin-right: 30px;

            &__link {
                display: block;
                font-size: 18px;
                line-height: 1.1;
                //color: @color-primary-darker; // Rremoved because it overwrite the active color (pink)
                text-overflow: ellipsis;

                &:hover {
                    color: @color-brand-200;
                }

                &.active {
                    color: @color-brand-200;
                }
            }

            &__nolink {
                display: block;
                font-size: 18px;
                line-height: 1.1;
                color: @color-primary-darker;
                text-overflow: ellipsis;
            }

            &__span {
                display: block;
                font-size: 18px;
                line-height: 1.2;
                color: @color-brand-200 !important;
                text-overflow: ellipsis;
            }
        }

        &__content {
            margin-top: 5px;

            &__list {

                &__item {

                    &--inactive a {
                        color: @gray-light !important;
                    }
                }

                &__item a {
                    color: #555;

                    &:hover {
                        color: @color-brand-200;
                    }
                }



                &__item span {
                    opacity: 0.6;
                }

                &__item--current > a, &__item--current > span {
                    color: @color-brand-200 !important;
                    opacity: 1;
                }
            }
        }
    }
}

.sagsforloeb {

    &__step {
        border-bottom: 1px solid @color-brand-info-800;

        &:not(:last-child):not(:first-child):after {
            content: '';
            position: absolute;
            width: 0;
            top: 45px;
            bottom: -11px;
            left: 37px;
            //border-right: 1px solid #666;
            border-right: 1px dashed @color-menu-selected-tab;
            z-index: 1;
        }

        &--active:after {
            border-right: 1px solid @color-contrast-175 !important;
        }

        &__expander {
            display: none;
            position: relative;
            float: right;
            font-size: 22px;
            cursor: pointer;
            z-index: 10;

            &:before {
                &:extend(.less-icon-extend);
                &:extend(.icon-ft_accordionplus:before);
            }

            &--show {
                display: block;

                @media (min-width: @screen-xs) {
                    display: none;
                }
            }
        }

        &--expanded &__expander:before {
            &:extend(.icon-ft_accordionminus:before);
        }

        & + & { // Because the first list-item is not part of the "train tracks"
            &__heading, h2 {

                &:before {
                    content: '';
                    position: absolute;
                    top: -10px;
                    left: -50px;
                    display: block;
                    width: 35px;
                    height: 35px;
                    border-radius: 35px;
                    border: 1px solid @color-menu-selected-tab;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0px;
                    left: -40px;
                    display: block;
                    width: 15px;
                    height: 15px;
                    border-radius: 15px;
                    background: @color-menu-selected-tab;
                }
            }
        }

        &--top {
            pointer-events: auto;
            opacity: 1;

            .sagsforloeb__step__heading__span {
                font-weight: 400;
                color: @color-brand-200;
            }

            .sagsforloeb__step__heading__link {
                padding-left: 10px;

                &:hover {
                    &:before {
                        color: #252525;
                    }
                }

                &:before {
                    content: "\e631";
                    display: inline-block;
                    font-size: 10px;
                    font-family: "ft-icons";
                    position: absolute;
                    vertical-align: middle;
                    left: 0px;
                    top: 5px;
                }
            }
        }

        & + &--active {
            pointer-events: auto;
            opacity: 1;

            &__heading, h2 {

                &:before {
                    border: 1px solid @color-contrast-175;
                }

                &:after {
                    background: @color-contrast-175;
                }
            }
        }

        &__content {
            display: block;
            overflow: hidden;
            /*transition: .3s max-height;*/
            margin-top: 5px;

            &__list {
                &__item--inactive {
                    pointer-events: none;
                }
            }

            &.collapsed-content {
                max-height: 0;
            }

            &--expanded {
                max-height: 100% !important;
            }

            ul {
                padding: 0;

                li {
                    list-style: none;
                    position: relative;
                    padding-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    .font-size(1.4);
                    line-height: 1.92rem;

                    @media (max-width: @screen-sm) {
                        line-height: 2.3rem;
                    }

                    &:before {
                        position: absolute;
                        display: inline-block;
                        font-size: 0.7em;
                        vertical-align: middle;
                        left: 0px;
                        top: 5px;
                        &:extend(.less-icon-extend);
                        &:extend(.icon-ft_bullit_right:before);

                        @media (max-width: @screen-sm) {
                            top: 8px;
                        }
                    }
                }
            }
        }
    }

    &-resume {
        border-bottom: 1px solid @color-brand-info-800;
        margin-left: -15px;
        margin-right: -15px;
        padding: 20px 10px 20px 23px;

        &__heading {
            position: relative;
            margin: 0;
            line-height: 1;
            margin-right: 30px;
            .font-alt();

            &__link {
                display: block;
                font-size: 18px;
                line-height: 1.1;
                color: @color-primary-darker;
                overflow: hidden;
                text-overflow: ellipsis;

                &:hover {
                    color: @color-brand-200;
                }

                &.active {
                    color: @color-brand-200;
                }
            }
        }

        @media (min-width: @screen-sm) {
           display: none;
        }
    }
}


.sidebar-offcanvas {
    .snap-drawer {
        width: 285px;
        overflow: scroll;
    }

    .secondary-navigation-menu-switcher-b {
        .leftmenu-toggle-tabs {
            margin: 0;

            .leftmenu-toggle {
                span {
                    display: inline-block;
                    overflow: hidden;
                    text-indent: -10000px;
                }
            }

            .offcanvas-menu-container {
                margin: 0;
            }

            .sagsforloeb, .sagsforloeb-menu {
                margin: 0;
            }
        }

        .leftmenu-toggle-panel {
            position: relative;

            &.left-tab {
                border-top: 1px solid @color-tertiary-result-absent;
                padding-top: 10px;
            }

            .sagsforloeb-vis-alt {
                padding: 0 0 0 8px;
                margin: 0;
            }
        }
    }
}

ul li a.btn-icon-button.icon-ft_basketplus{
  text-decoration: none!important;
  &:hover{
     text-decoration: none!important;
  }
}
.tingdok__documentdownload tbody tr td a.btn-icon-button.icon-ft_basketplus{
  text-decoration: none!important;
  &:hover{
     text-decoration: none!important;
  }
}