@import "variables.less";
@import "../SubsiteWide-Blue/colors.less";
@import "typography.less";
@import "fonts.less";
@import "layout.less";
@import "ui.less";
@import "form-elements.less";
@import "components/siteheader.less";
@import "components/intro.less";
@import "components/video.less";
@import "components/media-item.less";
@import "components/cta.less";
@import "components/categories.less";
@import "components/forum-category-page.less";
@import "components/slider.less";
@import "components/media-carousel.less";
@import "components/sitetools.less";
@import "components/factbox.less";
@import "components/information-spot-a.less";
@import "components/accordion.less";
@import "components/riddle-quiz.less";
@import "components/card.less";
@import "components/category-card.less";
@import "components/link-card.less";
@import "components/link-cards.less";
@import "components/forum-create-post.less";
@import "components/youth-parliament.less";
@import "components/forum-input-phone.less";
@import "components/forum-post.less";
@import "components/favorite-post.less";
@import "components/footer.less";
@import "components/file-modal.less";
@import "components/custom-select.less";
@import "components/listfilterdropdown.less";
@import "components/basic-captcha.less";
@import "components/infograph.less";
@import "print.less";

.subsite-wide-container {
  padding-left: @spacing-small;
  padding-right: @spacing-small;
  width: 100%;
  margin: 0 auto;

  @media (min-width: @screen-md-min) {
    max-width: 1120px;
    padding-left: @spacing-medium;
    padding-right: @spacing-medium;
  }
}
.subsite-wide-container--wide {
  &:extend(.subsite-wide-container);
  @media (min-width: @screen-md-min) {
    max-width: 1440px;
  }
}

.subsite-wide-container--narrow {
  &:extend(.subsite-wide-container);
  @media (min-width: @screen-md-min) {
    max-width: 780px;
  }
}
.section--padded-small {
  padding-top: @padding-small;
  padding-bottom: @padding-small;
}
.section--padded-large {
  padding-top: @padding-small;
  padding-bottom: @padding-small;

  @media (min-width: @screen-md-min) {
    padding-top: @padding-large;
    padding-bottom: @padding-large;
  }
}
.section--padded-top {
  padding-top: @padding-small;

  @media (min-width: @screen-md-min) {
    padding-top: @padding-large;
  }
}
.section--padded-bottom {
  padding-bottom: @padding-small;

  @media (min-width: @screen-md-min) {
    padding-bottom: @padding-large;
  }
}
.section--margin-bottom {
  margin-bottom: @section-spacing-small;

  @media (min-width: @screen-md-min) {
    margin-bottom: @section-spacing;
  }
}
.spot--margin-bottom {
  margin-bottom: @spot-spacing-small;

  @media (min-width: @screen-md-min) {
    margin-bottom: @spot-spacing;
  }
}
