.subsite-wide {
  .riddle-quiz__container {
  }

  .two-col-spot {
    .col-sm-6 {
      div.riddle-quiz__container {
        padding: @spacing-medium !important;
      }
    }
  }

  .three-col-spot {
    .col-sm-4 {
      div.riddle-quiz__container {
        padding: 32px !important;
      }
    }
  }
}
