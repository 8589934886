﻿@import "../../Global/Components/lexicon-spot.less";

.lexicon-spot {
  padding: 0;
  background-color: transparent;
  margin-top: 0;
  margin-bottom: 30px;

  .js-sync-height & {
    @media screen and (max-width: 767px) {
      min-height: inherit !important;
    }
  }

  .two-col-spot .col-sm-6 &, .three-col-spot .col-sm-4 & {
    .header {
      padding: 0!important;
      .title {
        color: @color-primary-dark;
        .font-size(2);
        float: none !important;
      }

      .link {
        .font-regular();
        float: none !important;

        &:after {
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_bullit_right:before);
          .font-size(@type-size-xs);
          margin-left: 5px;
          display: inline-block;
        }

        .icon-pil-hoejre:before {
          content: "\e631";
        }
      }
    }
  }

  &__content {
    padding: 20px 38px 30px;
    background-color: #fff;
    color: @color-primary-dark;
    .font-size(1.6);

    .two-col-spot .col-sm-6 &, .three-col-spot .col-sm-4 & {
      padding-top: 0;
    }

    dl {
      dt {
        a {
          .font-size(1.6);
          border-bottom: 1px solid @color-menu-selected-tab;
          color: @link-color;
          text-decoration: none;

          &:hover {
            border-bottom: 1px solid @link-color;
            text-decoration: none;
          }
        }
      }
    }
  }

  .header {
    padding: 10px 38px 10px 38px!important;
    display: table;
    width: 100%;


    .title {
      .font-alt();
      .font-size(2.5);
      font-weight: 400;
      margin-top: 0;
      margin-bottom: 0;
      float: left !important;
      display: block;

      @media (max-width: @screen-sm) {
        float: none !important;
      }

      i {
        display: none;
      }
    }

    .link {
      .font-alt();
      top: 3px;

      @media (max-width: @screen-sm) {
        float: none !important;
        margin-top: 10px;
        margin-bottom: 5px;
        display: inline-block;
        line-height: initial;
      }

      &:after {
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        .font-size(@type-size-xs);
        margin-left: 5px;
        display: inline-block;
      }

      a {
        top: 0;
        line-height: initial;

        i {
          display: inline-block;

          &:before {
            &:extend(.icon-ft_bullit_right:before);
          }
        }
      }
    }
  }
}
