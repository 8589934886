﻿.btn-nav-left {
  .btn-with-icon-left-v2( @color: @btn-flownav-text-color; @color-hover: @btn-flownav-text-color-hover; @background: @btn-flownav-bg; @background-hover: @btn-flownav-bg-hover; @font-size: @btn-flownav-font-size; @line-height: @btn-flownav-line-height; @icon-color: @btn-flownav-icon-color; @icon-color-hover: @btn-flownav-icon-color-hover; @icon-bg: @btn-flownav-icon-bg; @icon-bg-hover: @btn-flownav-icon-bg-hover; @icon-size: @btn-flownav-icon-font-size; );
}

.btn-nav-right {
  .btn-with-icon-right-v2( @color: @btn-flownav-text-color; @color-hover: @btn-flownav-text-color-hover; @background: @btn-flownav-bg; @background-hover: @btn-flownav-bg-hover; @font-size: @btn-flownav-font-size; @line-height: @btn-flownav-line-height; @icon-color: @btn-flownav-icon-color; @icon-color-hover: @btn-flownav-icon-color-hover; @icon-bg: @btn-flownav-icon-bg; @icon-bg-hover: @btn-flownav-icon-bg-hover; @icon-size: @btn-flownav-icon-font-size; );
}

.btn-action {
  .btn-with-icon-right( @color: @btn-action-text-color; @color-hover: @btn-action-text-color-hover; @background: @btn-action-bg; @background-hover: @btn-action-bg-hover; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @btn-action-icon-color; @icon-color-hover: @btn-action-icon-color-hover; @icon-bg: @btn-action-icon-bg; @icon-bg-hover: @btn-action-icon-bg-hover; @icon-size: @btn-action-icon-font-size; );
}

.btn-taxonomy {
  .btn-with-icon-right-v2( @color: @btn-taxonomy-text-color; @color-hover: @btn-taxonomy-text-color-hover; @background: @btn-taxonomy-bg; @background-hover: @btn-taxonomy-bg-hover; @font-size: @btn-taxonomy-font-size; @line-height: @btn-taxonomy-line-height; @icon-color: @btn-taxonomy-icon-color; @icon-color-hover: @btn-taxonomy-icon-color-hover; @icon-bg: @btn-taxonomy-icon-bg; @icon-bg-hover: @btn-taxonomy-icon-bg-hover; @icon-size: @btn-taxonomy-icon-font-size; @icon-v-offset: 4 );

  &.selected {
    //.btn-with-icon-right-v2( @color: @btn-taxonomy-text-color-hover; @color-hover: @btn-taxonomy-text-color; @background: @btn-taxonomy-bg-hover; @background-hover: @btn-taxonomy-bg; @font-size: @btn-taxonomy-font-size; @line-height: @btn-taxonomy-line-height; @icon-color: @btn-taxonomy-icon-color-hover; @icon-color-hover: @btn-taxonomy-icon-color; @icon-bg: @btn-taxonomy-icon-bg-hover; @icon-bg-hover: @btn-taxonomy-icon-bg; @icon-size: @btn-taxonomy-icon-font-size; @icon-v-offset: 4 );
    .btn-with-icon-right-v2( @color: @btn-taxonomy-text-color; @color-hover: @btn-taxonomy-text-color-hover; @background: @btn-taxonomy-bg-selected; @background-hover: @btn-taxonomy-bg-hover; @font-size: @btn-taxonomy-font-size; @line-height: @btn-taxonomy-line-height; @icon-color: @btn-taxonomy-icon-color; @icon-color-hover: @btn-taxonomy-icon-color-hover; @icon-bg: @btn-taxonomy-icon-bg-selected; @icon-bg-hover: @btn-taxonomy-icon-bg-hover; @icon-size: @btn-taxonomy-icon-font-size; @icon-v-offset: 4 );
  }
}

.btn-left {
  .btn-with-icon-left-v2(@color: @btn-action-text-color; @color-hover: @btn-action-text-color-hover; @background: @btn-action-bg; @background-hover: @btn-action-bg-hover; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @btn-action-icon-color; @icon-color-hover: @btn-action-icon-color-hover; @icon-bg: @btn-action-icon-bg; @icon-bg-hover: @btn-action-icon-bg-hover; @icon-size: @btn-action-icon-font-size;);

  &.btn-brand {
    .btn-with-icon-right-v2(@color: @color-text-light; @color-hover: @color-text-light; @background: @color-brand-200; @background-hover: @color-brand-100; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @color-text-light; @icon-color-hover: @color-text-light; @icon-bg: @color-brand-100; @icon-bg-hover: darken(@color-brand-100, 5%); @icon-size: @btn-action-icon-font-size;);
  }
}

.btn-right {
  .btn-with-icon-right-v2(@color: @btn-action-text-color; @color-hover: @btn-action-text-color-hover; @background: @btn-action-bg; @background-hover: @btn-action-bg-hover; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @btn-action-icon-color; @icon-color-hover: @btn-action-icon-color-hover; @icon-bg: @btn-action-icon-bg; @icon-bg-hover: @btn-action-icon-bg-hover; @icon-size: @btn-action-icon-font-size;);

  &.btn-brand {
    .btn-with-icon-right-v2(@color: @color-text-light; @color-hover: @color-text-light; @background: @color-brand-200; @background-hover: @color-brand-100; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: @color-text-light; @icon-color-hover: @color-text-light; @icon-bg: @color-brand-100; @icon-bg-hover: darken(@color-brand-100, 5%); @icon-size: @btn-action-icon-font-size;);
  }
}

.btn-hero {
  .btn-with-icon-right-v2(@color: #3c98c1; @color-hover: darken(#3c98c1, 10%); @background: @color-text-light; @background-hover: @color-text-light; @font-size: @btn-action-font-size; @line-height: @btn-action-line-height; @icon-color: #3c98c1; @icon-color-hover: darken(#3c98c1, 10%); @icon-bg: #cae8f5; @icon-bg-hover: #cae8f5; @icon-size: @btn-action-icon-font-size;);
  margin-right: 0;
  border-radius: 3px 3px 0 0;
  padding: 8px 38px 8px 13px;
  .font-bold();
  .font-size(1.5);
  color: #007eb5; // setting color manually because of contrast issues relative to WCAG demands
  &:before {
    border-radius: 0 3px 0 0;
    .font-size(1);
    width: 27px;
    padding-left: 8px;
    color: #007eb5; // setting color manually because of contrast issues relative to WCAG demands
  }
}
.booking-order-button {
  .btn-right();

  .content-area & {
    color: #fff;
    margin-left: 38px;
    margin-right: 38px;
  }

  .text-spot-a-wrapper & {
    color: #fff;
    margin: 15px 0 0;
  }
}

.arrow-link--after {
	color: @color-primary-link !important;
	&:after {
		display: inline-block;
		&:extend(.less-icon-extend);
		&:extend(.icon-ft_bullit_right:before);
		font-size: 11px;
		position: relative;
		margin-left: 5px;
	}
	&:hover {
		text-decoration: underline !important;
	}
}

.arrow-link--before {
	color: @color-primary-link !important;
  padding-left: 15px;
	&:before {
		display: inline-block;
		&:extend(.less-icon-extend);
		&:extend(.icon-ft_bullit_right:before);
		font-size: 11px;
		position: relative;
		margin-right: 5px;
    margin-left: -15px;
	}
	&:hover {
		text-decoration: underline !important;
	}
  &.arrow-link--before-large {
    font-size: 1.6rem;
  }
}

.link--underline {
	border-bottom: 1px solid #cacaca;
	line-height: 1.3;
	&:hover {
		text-decoration: none !important;
		color: @color-primary-link !important;
		border-bottom: 1px solid @color-primary-link !important;
	}
}
