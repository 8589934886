.subsite-wide {
  .content-action-wrapper {
    margin-left: 0;
    margin-right: 0;

    .col-sm-12 {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }
  .content-action-links {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 15px;

  .content-action {
    a {
      width: 30px;
      height: 24px;
      display: block;
      background-repeat: no-repeat;
      background-position: center;
      color: @sw-color-primary !important;
      color: var(--sw-color-primary, @sw-color-primary) !important;

      svg {
        display: inline;
        width: 30px;
        height: 24px;
        max-width: 100%;
        stroke: @sw-color-primary;
        stroke: var(--sw-color-primary, @sw-color-primary);

        * {
          stroke: @sw-color-primary !important;
          stroke: var(--sw-color-primary, @sw-color-primary) !important;
        }
      }
    }

    &#leseweb-container {
      background-color: transparent !important;
      width: 24px;
      height: 24px;
      .leseweb-icon {
        padding: 0px !important;
      }
      i {
          svg:not(.subsite-wide-service-icon) {
            display: none;
          }
        }
      }
      &:not(.leseweb-container) {
        i {
          display: none !important;
        }
      }
    }
  }
  .paf-toolbar {
    top: 145px !important;
  }
}
