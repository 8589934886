﻿@import "../styles.less";

.statussheetrefspot {
  .accordion-section-header {
    background: @color-contrast-400;
    .font-bold();
    .font-size(@type-size-md);
    margin: 20px 0 0 0;
    padding: 8px 8px;

    &:first-child {
      margin: 10px 0 0 0;
    }
  }



  .info-field {
    .clearfix();
    padding: 0 8px;
    margin: 3px 0;

    dt {
      padding-left: 0;
    }

    dd {
      padding-left: 0;

      a {
        .font-bold();
        .font-size(@type-size-md);
        color: @link-secondary-color;
      }
    }
  }
}

.document-list {
  margin: 0;
  padding: 0 8px;
  list-style: none;
  border-top: 1px solid @color-text-light;

  li {
    border-bottom: 1px solid @color-text-light;
    margin: 3px 0;

    a {
      .font-bold();
      .font-size(@type-size-md);
      color: @link-secondary-color;
    }
  }
}

