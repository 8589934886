﻿@import "../../Global/Components/pagination.less";
@import "../Variables/_colors.less";
@import "../Variables/_typography.less";

.pagination-row {
  //margin-left: 0;
  //margin-right: 0;
}

.pagination-container {
  margin: 10px 0;
  padding: 0 38px;
  text-align: center;

  .tingdok & a.back-btn {
    @media (max-width: @container-small-desktop) {
      top: auto;
    }
  }

  .tab-pane & {
    @media (max-width: @screen-sm) {
      padding: 0;
    }
  }

  .pagination-text-container-top {
    margin: 0 0 10px 0;
  }
  //.pagination-text-container-bottom {
  //  margin: 10px 0;
  //}
  .pagination {
    &.pagination-centered {
      background-color: transparent;
      padding: 9px;

      @media (max-width: @screen-sm) {
        padding: 9px 0px;
      }

      @media (max-width: @screen-xsm) {
        display: block;
        margin: 17px 0;
      }

      margin: 10px 0;
      width: auto !important;

      > li {
        position: relative;
        float: none;
        display: inline-block;
        vertical-align: middle;

        input[type="text"] {
          width: 62px;
          height: 30px;
          .font-regular();
          text-align: center;
          .font-size(@type-size-lg);
          border-color: transparent;
          margin-left: 5px;

          &::-webkit-input-placeholder {
            .font-size(@type-size-sm, @type-size-lg);
          }

          &:-moz-placeholder {
            /* Firefox 18- */
            .font-size(@type-size-sm, @type-size-lg);
          }

          &::-moz-placeholder {
            /* Firefox 19+ */
            .font-size(@type-size-sm, @type-size-lg);
          }

          &:-ms-input-placeholder {
            .font-size(@type-size-sm, @type-size-lg);
          }
        }

        > a,
        > span {
          display: block;
          margin: 0;
          width: auto !important;
          padding: 6px 8px !important;
        }

        > a {
          background-color: #fff;
          color: @color-primary-function-bg;
          border-radius: 100px;
          .font-size(1.8);
          font-weight: 400;
          min-width: 30px;
          height: 30px;
          float: none;

          .tab-spot-a__wrapper & {
            background: @color-secondary-lighter;
            color: @link-hover-color; // Den er ok at bruge her.  Det er det eneste sted.(NOT)!
          }

          &.active {
            background-color: @color-primary-function-bg;
            color: #fff;
          }

          &:hover {
            background: @color-primary-function-bg;
            color: #fff;
          }
        }

        > a.hasMore {
          color: @color-secondary-darker !important;

          &:hover {
            color: @color-primary-function-hover !important;
          }
        }

        &:not(.previous):not(.next) {
          a {
            line-height: 30px;
            line-height: 3rem;
            padding-top: 0 !important; // Forced to overwrite !important elsewhere
            padding-bottom: 0 !important; // Forced to overwrite !important elsewhere
          }
        }

        &.previous,
        &.next {
          > a,
          > span {
            background: none;
            color: @color-secondary-dark;
            border: none;
            padding: 6px 11px;
            .font-bold();
            .font-size(@type-size-lg);
            margin: 0 2px 1px;
            min-width: 30px;
            height: 30px;

            &:hover {
              color: @color-primary-function-bg;
              background: transparent;
            }
          }

          [class*="icon-pil"] {
            position: relative;
            top: 0;

            &:before {
              .font-size(3.5);
              line-height: 1px;
              position: relative;
              top: 6px;
            }
          }

          .text {
            .font-size(1.5); // Undo setting from Bootstrap
            overflow: hidden;
            text-indent: -10000px;
            display: inline-block;
            max-width: 0px; // Remove spacing between subling elements if text inside .text is more than one word
          }
        }

        &.previous {
          //float: none;
          a {
            //padding-left: 0;
            span[class*="icon-"] {
              &:before {
                &:extend(.icon-ft_leftcircle:before);
              }
            }

            @media (max-width: @screen-sm) {
              padding-left: 0px !important;
            }
          }
        }

        &.next {
          //float: none;
          a {
            //padding-right: 0;
            span[class*="icon-"] {
              &:before {
                &:extend(.icon-ft_rightcircle:before);
              }
            }

            @media (max-width: @screen-sm) {
              padding-right: 0px !important;
            }
          }
        }

        span {
          &.gap {
            background: transparent;
            color: @color-pagination-dots;
            letter-spacing: 1px;
            cursor: default;
            padding: 0 !important;
          }
        }
      }
      // PBI 35745 - Last item in pagination doesn't have margin left
      > li:not(.next):not(.previous) {
        margin-left: 5px;
      }

      &.alphabet {
        > li {
          margin: 0 0 5px 5px;

          &:first-child {
            margin: 0 0 5px 0;
          }

          > a {
            background-color: #fff;
            color: @color-primary-function-bg;
            border-radius: 100px;
            .font-size(1.8);
            line-height: 30px;
            line-height: 3rem;
            padding-top: 0 !important; // Forced to overwrite !important elsewhere
            pading-bottom: 0 !important; // Forced to overwrite !important elsewhere
            font-weight: 400;
            min-width: 30px;
            height: 30px;
            // &.all {
            //   width: auto;
            //   padding: 6px 10px;
            // }
            &.active {
              background-color: @color-primary-function-bg;
              color: #fff;
            }

            &:hover {
              background-color: @color-primary-function-bg;
              color: #fff;
            }
          }

          span {
            .font-size(1.8);
            font-weight: 400;
            min-width: 30px;
            height: 30px;

            &.gap {
              padding: 6px 8px !important;
            }
          }
        }
      }

      &.article-navigation {
        width: auto;

        @media (max-width: @screen-xsm) {
          margin-left: 1rem;
          margin-right: 1rem;
        }

        .centered.form-inline {
          margin-left: 0;
        }

        .previous,
        .next {
          float: none;
        }

        .form-group {
          input[type="text"] {
            .font-size(1.4);
          }

          .btn {
            float: right;
            border-radius: 0;
            position: relative;
            top: 0;
            background-color: @color-primary-function-bg;
            color: #fff;
            .font-size(1.8);
            padding-bottom: 0 !important;
            padding-top: 0 !important;

            &:hover {
              background-color: @color-primary-function-hover;
            }
          }
        }
      }
    }

    &.pagination--left-right {
      padding: 0 53px;
      position: relative;

      .previous,
      .next {
        position: absolute;
        top: 0;
      }

      .previous {
        left: 0;
      }

      .next {
        right: 0;
      }
    }

    &-steps {
      a {
        color: @color-text-dark;
        text-decoration: none;
        border-bottom: 1px solid transparent;

        &.active {
          color: @link-color;
          font-weight: 400;
          cursor: default;
          border-bottom: 1px solid @link-color;
        }

        &:hover {
          color: @link-color;
          border-bottom: 1px solid @link-color;
        }
      }
    }
  }
}

.ribbon-spot .medlemmersidespot-a .bx-controls-direction {
  a:hover {
    color: @color-primary-function-hover !important;
  }
}

.pagination-container .pagination.pagination-centered > li.next > a,
.pagination-container .pagination.pagination-centered > li.previous > a {
  color: @color-secondary-darker !important;

  &:hover {
    color: @color-primary-function-hover !important;
  }
}
