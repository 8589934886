﻿@import "../../Global/Components/slide-in-spot-a.less";

.slide-wrapper {
  visibility: hidden;
}

.slide-in {
  padding-left: 0 !important;

  .slide-in-canvas {
    border-radius: 0 !important;
  }

  @media (max-width: 1000px) {
    top: 45px !important;
  }

  &.slide-in-open {
    .slide-in-canvas {
      border-radius: 0 !important;
      box-shadow: 0 6px 12px 7px rgba(0, 0, 0, 0.21) !important;
    }
  }

  .slide-in-toggle {
    position: relative !important;
    top: 0 !important;
    z-index: -1 !important;
    left: -37px !important;

    a.btn-right.btn-brand {
      box-shadow: -5px 6px 12px 7px rgba(0, 0, 0, 0.21) !important;
      border-radius: 0 !important;
      display: block;
      min-width: auto !important;
      width: auto !important;
      font-size: 15px;
      padding: 8px 22px 8px 22px !important;
      margin-right: -1px !important;
      direction: rtl;

      &:before {
        content: "none";
      }
    }
  }

  .slide-in-section-header {
    font-size: 15px !important;
    font-family: SceneAlt, serif;
    color: #454545;
    margin-bottom: 5px;
  }

  .slide-in-canvas {
    border-radius: 0;
    background-color: #fff !important;
    position: static !important;
  }

  .slide-in-section {
    a {
      color: #0062ae;
      font-weight: normal;
    }

    &.has-no-icon {
      .slide-in-section-body {
        max-width: none !important;
      }
    }
  }

  .slide-in-section-icon {
    font-size: 20px !important;
    margin-right: 5px !important;

    &.icon-ft_mail:before {
      font-size: 15px !important;
    }
  }

  .slide-in-section-body {
    float: right !important;
    max-width: 150px !important;
    width: 100%;
    font-weight: normal !important;
    font-size: 14px !important;
    color: #454545 !important;
  }
}
