@import "../../Global/Components/factbox.less";

.large.tooltip-inner {
  max-width: 350px;
  width: auto;
}

.factbox {
  color: @color-primary-dark;

  &.has-no-content {
    margin-bottom: 0;
  }

  &__title {
    font-family: "SceneAlt", serif !important;
    .font-size(2.5) !important;
    margin-top: 0 !important;

    .two-col-spot &,
    .three-col-spot & {
      .font-size(2) !important;
    }
  }

  &__link {
    display: block !important;
    padding-left: 15px;
    border-bottom: none !important;

    &:hover {
      text-decoration: underline !important;
      background-color: transparent;
    }

    &:before {
      display: inline-block;
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);
      font-size: 11px;
      float: left;
      margin: 4px 0 0 -15px;
    }
  }

  &__text-container {
    padding-top: 30px;
    padding-bottom: 1px;

    .two-col-spot &,
    .three-col-spot & {
      padding: 0 !important;
    }

    .text-spot-a-wrapper {
      padding-top: 1px;
    }

    img {
      width: 100%;
      height: auto;
    }

    a:not(.front-arrow):not(.link-list):not(.factbox__link) {
      color: @color-brand-200;
      border-bottom: 1px solid @color-menu-selected-tab;
      padding-right: 0 !important;

      &:hover {
        text-decoration: none;
        border-bottom: 1px solid @color-brand-200;
      }
    }

    .rte-quote {
      .font-headline();
      .font-size(2.2);
      position: relative;
      top: 3px;
      color: @color-brand-200;
      display: block;
      margin: 20px 0;
      padding-left: 25px;

      &:before {
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }
  }
}
