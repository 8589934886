﻿.wizard-steps, nav.nav-pills.nav-wizard.wizard-steps {
  list-style: none;
  margin: 0;
  padding: 0;
  display: table;
  width: 100%;

  @media (max-width: @screen-xs-max) {
    display: block;
  }

  li {
    display: table-cell;
    position: relative;
    background: #f6f6f6;
    border: 1px solid #e8eaea;
    border-width: 0 1px;
    height: 70px;
    line-height: 1.2;
    padding: 0 37px;
    vertical-align: middle;
    color: rgba(37, 37, 37, 0.5);

	&.is-unavailable {
		color: fade(@color-secondary-dark, 40%);
	}

    @media (max-width: @screen-xs-max) {
      display: block;
      height: auto;
      padding: 20px 30px;
    }

    &:not(:last-child) {
      @media (max-width: @screen-xs-max) {
        border-bottom: 2px solid #e8eaea;
      }
    }

    &:nth-child(0) {
      z-index: 100;
    }

    &:nth-child(1) {
      z-index: 90;
    }

    &:nth-child(2) {
      z-index: 80;
    }

    &:nth-child(3) {
      z-index: 70;
    }

    &:nth-child(4) {
      z-index: 60;
    }

    &:after, &:before {
      left: 100%;
      top: 0;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;

      @media (max-width: @screen-xs-max) {
        left: 20px;
        top: 100%;
        transform: rotate(90deg);
      }
    }

    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-left-color: #f6f6f6;
      border-width: 10px;
      margin-top: 26px;

      @media (max-width: @screen-xs-max) {
        margin: 0 0 0 4px;
      }
    }

    &:before {
      border-color: rgba(246, 246, 246, 0);
      border-left-color: #e8eaea;
      border-width: 13px;
      margin-top: 23px;

      @media (max-width: @screen-xs-max) {
        margin: 0 0 0 0;
        border-width: 14px;
      }
    }

    p {
      margin: 0;
    }

    a {
      color: rgba(37,37,37,0.5);
      text-decoration: none;
      padding: 0 !important;
      .font-size(1.8);
    }

    span {
      .font-alt();
      .font-size(1.8);
    }

    &.active {
      background: #fff;

      &:after {
        border-left-color: #fff;
      }

      a {
        color: @link-color;
      }

      span {
        color: @link-color !important;
      }
    }

    &:last-child {
      &:after, &:before {
        display: none;
      }
    }
  }
}
