﻿.full-width-page {
  .wrapper.header-container {
    margin-bottom: 0 !important;
  }

  .burger-icon .icon-menu {
    position: absolute;
    left: -99999999px;
  }

  .top-searchbar {
    display: none;
  }
}

.ebook {
  height: 100vh;
  //Page reference
  .page-reference {
    .clearfix();

    .submit-button {
      display: table;
      margin: 0 auto;
      width: 100px;

      @media only screen and (min-width: @screen-md) {
        width: 11%;
        top: 11px;
        position: relative;
      }

      &__inner {
        font-family: SceneAlt,serif;
        font-size: 1.8rem;
        line-height: 2.16rem;
        color: #fff;
        width: 100%;
        padding: 9px;
        border: 0;
        background-color: @color-brand-300;

        &:hover {
          background-color: @color-primary-function-hover;
        }
      }
    }

    &__label {
      font-size: 14px;

      @media only screen and (min-width: @screen-md) {
        margin: 5px 0;
      }
    }

    &__form {
      width: 100%;
      min-height: 40px;
      margin-bottom: 20px;

      @media only screen and (min-width: @screen-md) {
        float: left;
        width: 23%;
        margin-right: 1.65%;
        margin-bottom: 0;

        &--wide {
          width: 30%;
        }
      }

      input, select {
        padding: 10px;
        min-height: 40px;
      }
    }
  }

  &.has-filter {
    .ebook__service {
      @media(min-width:@screen-md) {
        height: auto;
        transition: top 0.2s ease-in-out;
        top: 30px;
      }
    }
  }

  .ebook__service {

    @media(min-width:@screen-md) {
      transition: top 0.2s ease-in-out;
      height: 57px;
      top: 10px;
    }
  }

  .site-identity-container {
    text-align: center;
    margin-bottom: 0;
    display: table-cell;
    width: 100%;
    padding-right: 10px;
    position: relative;
    top: 9px;
  }

  &__row {
    display: table;
    width: 100%;

    @media(min-width:@screen-md) {
      display: flex;
      flex-direction: row;
      width: auto;
    }
  }

  &__header {
    @media(min-width:@screen-md) {
      background: @color-secondary-lighter;
      min-height: 83px;
      padding-top: 8px;
      padding-bottom: 10px;
    }
  }

  &__identity {
    display: none;

    @media(min-width:@screen-md) {
      display: block;
      float: left;
      background-color: transparent;
      padding-bottom: inherit;
    }

    .xs-menu {
      display: none;
    }
    //External elements
    .site-identity-container {
      margin-bottom: 0;
    }

    img {
    }

    .site-title {
      font-size: 14px !important;
      line-height: 12px !important;
      margin-bottom: 0 !important;
    }
  }

  &__filter {

    @media(max-width:991px) {
      display: table-footer-group;
    }

    @media(min-width:@screen-md) {
      flex-grow: 1;
      width: 80%;
    }

    h1 {
      text-align: center;
    }

    .validation {
      display: inline-block;
      font-family: Arial,sans-serif;
      font-size: 14px;
      line-height: 1.92rem;
      color: @color-primary-function;
      margin-top: 10px;
      margin-bottom: 0;
    }
    @media(min-width:@screen-md) {
      .guide-link {
        margin-left: 7px;
        top: 7px;
        position: relative;
      }
    }
    .icon-spoergsmaal:before {
      content: "";
      content: "\e648";
      display: inline-block;
      color: @color-primary-function;
      font-size: 16px;
      position: relative;
      top: -1px;
      // padding-right: 10px;
    }
  }

  &__filter-selection {
    padding: 7px 38px 0 38px;
    margin-bottom: 30px;
    display: none;

    @media(min-width:@screen-md) {
      margin-bottom: 5px;
      padding: 7px 15px 0 15px;
    }
  }

  &__service {
    position: relative;
    //top: 31px;
    top: 10px;
    width: 230px;

    @media(max-width:991px) {
      display: table-footer-group;
    }

    @media(min-width:@screen-md) {
      width: 270px;
    }

    .content-action-links {
      margin-top: 30px;

      @media(min-width:@screen-md) {
        padding-left: 0;
        padding-right: 0;
        margin-top: inherit;
        margin-bottom: 0 !important;
      }

      .content-action:last-of-type {
        margin-right: 10px;
      }
    }

    .share-btn {
      display: none;
    }

    .icon-ft_circle-download {
      color: @color-primary-function-bg !important;

      &:hover {
        color: @color-primary-function-hover !important;
      }
    }

    .download-btn {
      display: block !important;

      .download__container__docBtns__btn {
        background: transparent;
      }

      .download__container__docBtns__btn__docWork {
        color: #fff;
        background-color: @color-primary-function-bg;
        border-radius: 50%;
        width: 48px;
        height: 48px;
        display: inline-block;
        position: relative;
        text-align: center;

        &:hover {
          background-color: @color-primary-function-hover;
        }
      }



      .icon-ft_download {
        font-size: 28px;
        position: relative;
        top: 7px;

        &:before {
          background: transparent !important;
          color: white !important;
        }
      }
    }
  }

  &__content {
    position: relative;
  }
  &__content-links{
    .row{
      margin: 0!important;
    }
  }

  &__content-wrapper {
    height: 100vh;
    position: relative;

    iframe {

      @media(min-width:@screen-md) {
        margin-top: -10px;
      }
    }
  }

  &__error-message {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    padding: 50px;
    border: 1px solid white;
    max-width: 400px;
    width: 100%;
    text-align: center;

    &__button {
      font-family: SceneAlt,serif;
      font-size: 1.8rem;
      line-height: 2.16rem;
      color: #fff;
      width: auto;
      padding: 9px;
      border: 0;
      background-color: @color-primary-function-bg;
    }
  }

  &__info {
    display: table;
    width: 100%;
    text-align: center;

    @media(min-width:@screen-md) {
      flex-grow: 1;
      margin-top: 3px;
    }
  }

  &__title {
    font-size: 30px;
    text-align: center;
    line-height: 40px;
    margin: 0;
    margin-bottom: 15px;
    padding-top: 5px;

    @media(min-width:@screen-md) {
      margin-bottom: 0;
    }
  }

  &__pagination {
    text-align: center;
    margin-top: -5px;
  }

  &__page-display {
    display: inline-block;
    text-align: center;
    font-size: 16px;
  }

  &__fullpage-list {
    /*float: left;
    position: relative;
    top: 5px;*/
    background: #f0f2f2 !important;
    display: table;
    width: 100%;
    display: none;
    position: relative;
  }

  &__fullpage {
    display: none;

    @media(min-width:@screen-md) {
      display: inline-table;
      color: @color-primary-function;
      font-family: SceneAlt,serif;
      font-size: 16px;
      line-height: 1.92rem;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      top: 10px;
      text-decoration: none;
      z-index: 9;
      cursor: pointer;
      text-decoration: none;
      border-bottom: 1px solid transparent;

      &.is-open {
        &:after {
          display: inline-block;
          transform: rotate(180deg); /* Equal to rotateZ(45deg) */
        }
      }
    }

    &:after {
      content: "\e901";
      font-family: "ft-icons";
      line-height: 1;
    }

    &:hover {
      text-decoration: none !important;
      color: @color-primary-function-hover;
      border-bottom: 1px solid @color-primary-function-hover;
    }

    .icon-soeg {

      &:before {
        content: "\e658" !important;
      }
    }
  }
}

// Ebook search
.ebook-search {
  margin-bottom: 0 !important;

  .form-group {
    margin: 0 10px 20px!important;
  }

  .panel-group .panel {
    overflow: visible;
  }
}

// Ebook search
.ebook-result {
  padding: 0 38px 30px;
  margin-bottom: 30px;
  background: @color-contrast-400;

  &__title {
    .font-alt();
    color: @color-primary-darker;
    font-size: 25px;
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: 15px;
    padding: 0;
  }

  &__list {
    padding: 0;
  }

  &__list-item {
    margin-bottom: 10px;

    @media (min-width: @screen-md) {
      margin-bottom: 5px;
    }
  }

  &__list-link {
    display: table;
  }

  &__icon {
    width: 20px;
    height: 20px;
    float: left;
    margin-right: 5px;
  }
}
