.factbox {
  margin-bottom: 30px;
  margin-top: 0;

  @media (min-width: @screen-sm) {
    overflow: hidden;
  }

  &.has-no-content {
    margin-bottom: 0;
  }

  &__title {
    .site-euo & {
      font-weight: normal;
    }
  }

  .two-col-spot .col-sm-6 > &,
  .three-col-spot .col-sm-4 > & {
    padding: 0 !important;
  }

  &__image-container:not(.scExperienceEditor) {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;

    &__image,
    &__topimage {
      width: 100% !important;
      max-width: 100% !important;
      height: auto !important;
      display: block;
    }
  }

  .scExperienceEditor {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;

    &__image,
    &__topimage {
      width: 100% !important;
      max-width: 100% !important;
      height: auto !important;
      display: block;
    }
  }

  ul.link-list {
    margin-top: 10px;
    padding-left: 0;
    list-style: none;
  }

  a.link {
    text-decoration: none;
    color: #bb506d;
    display: block;
    margin-bottom: 0;

    &:before {
      display: inline-block;
      vertical-align: middle;
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);
      font-size: 11px;
      margin-right: 4px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__text-container {
    max-width: 945px;
    background: #fff;
    display: block;
    box-sizing: border-box;

    div.two-col-spot &,
    div.three-col-spot & {
      @media (min-width: @screen-sm) {
        margin-bottom: -1000px;
        padding-bottom: 1000px !important;
      }
    }

    .site-euo & {
      padding: 2px 23px;
    }

    .site-euo .two-col-spot & {
      padding: 0;
    }

    img {
      width: 100%;
      height: auto;
    }

    .rte-quote {
      .font-headline();
      .font-size(2.2);
      color: @color-brand-200;
      display: block;
      margin: 20px 0;
      padding-left: 25px;

      &:before {
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }
  }
}

.image-text-spot {
  margin-bottom: 30px;
  position: relative;

  &.has-no-content {
    margin-bottom: 0;
  }

  &.has-no-image {
    .two-col-spot & {
      min-height: inherit;

      @media (min-width: @screen-sm) {
        min-height: 160px;
      }
    }

    .three-col-spot & {
      min-height: inherit;

      @media (min-width: @screen-sm) {
        min-height: 100px;
      }
    }
  }

  .two-col-spot .col-sm-6 > &,
  .three-col-spot .col-sm-4 > & {
    padding: 0 !important;
  }

  &__manchet {
    display: table;
    margin-bottom: 30px;
    padding-left: 23px;
    padding-right: 23px;

    .site-ft & {
      padding-left: 38px;
      padding-right: 38px;
    }
    .site-ft .two-col-spot &,
    .site-ft .three-col-spot & {
      padding-left: 0;
      padding-right: 0;
    }
  }

  h2&__title {
    text-shadow: 0px 0px 3px #131111;
    display: inline-block;
    .font-size(2) !important;
    font-weight: bold;
    margin-top: 0 !important;
    margin-bottom: 15px !important;
    padding-left: 23px;
    padding-right: 23px;

    .site-ft & {
      padding-left: 38px;
      padding-right: 38px;
    }

    .site-ft .two-col-spot &,
    .site-ft .three-col-spot & {
      .font-size(2) !important;
      padding-left: 0;
      padding-right: 0;
    }

    .has-no-image & {
      font-weight: bold;
    }

    @media (min-width: @screen-sm) {
      .font-size(2.5) !important;
    }
  }

  &__main-link {
    display: table;
    width: 100%;
    min-height: 118px;

    .two-col-spot .has-no-image &,
    .three-col-spot .has-no-image & {
      min-height: 118px;
    }

    @media (min-width: @screen-sm) {
      min-height: 128px;
    }
  }

  &__link {
    display: inline-block !important;
    padding-left: 15px;
    border-bottom: none !important;
    margin-top: 15px;
    padding-left: 53px;

    &:hover {
      text-decoration: underline !important;
      background-color: transparent;
    }

    &:before {
      display: inline-block;
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);
      font-size: 11px;
      float: left;
      margin: 4px 0 0 -15px;
    }
  }

  &__image-container {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;

    &__image {
      width: 100% !important;
      height: 100% !important;
      display: block;
    }
  }

  &__text-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: transparent;
    padding-bottom: 1px;

    .text-spot-a-wrapper {
      padding: 0;
    }

    .site-ft .two-col-spot &,
    .site-ft .three-col-spot & {
      padding: 0 38px !important;

      @media (max-width: @screen-sm) {
        padding: 0 5px !important;
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    a:not(.front-arrow):not(.link-list):not(.factbox__link) {
      color: @color-brand-200;
      border-bottom: 1px solid @color-menu-selected-tab;
      padding-right: 0 !important;

      &:hover {
        text-decoration: none;
        border-bottom: 1px solid @color-brand-200;
      }
    }

    .rte-quote {
      .font-headline();
      .font-size(2.2);
      position: relative;
      top: 3px;
      color: @color-brand-200;
      display: block;
      margin: 20px 0;
      padding-left: 25px;

      &:before {
        content: open-quote;
      }

      &:after {
        content: close-quote;
      }
    }
  }
}
