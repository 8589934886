.forum-create-post--youth_parliament {
  .checkbox-buttons {
    @media (min-width: @screen-md-min) {
      display: flex;
      padding: 5px 10px;
    }
    
    &.checkbox--error {
      font-weight: @sw-font-regular;
      outline: @focus-style;
      outline-color: @sw-color-red;
      outline-offset: @focus-offset;
    }

    &__container {
      @media (min-width: @screen-md-min) {
        display: flex;
      }
    }
  }

  .checkbox__container {
    margin-right: @spacing-small * 2;
    cursor: pointer;

    @media (max-width: @screen-md-min) {
      width: 100%;
      margin-bottom: @padding-small;
    }
  }
  input[type="checkbox"]:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  // select dropdown with search
  .select-dropdown-container {
    .select-dropdown__control {
      .select-dropdown__value-container {
        padding: 0;
      }
    }

    .select-dropdown__option {
      &--is-focused,
      &:hover {
        background: @sw-color-primary;
        background: var(--sw-color-primary, @sw-color-primary);
      }
    }

    .select-dropdown__indicators {
      .select-dropdown__indicator-separator {
        display: none;
      }

      .select-dropdown__indicator {
        height: 36px;

        &.select-dropdown__clear-indicator {
          width: 36px;
        }

        &.select-dropdown__dropdown-indicator {
          width: 25px;
          padding: 9.5px 0 !important;

          svg {
            display: none;
          }

          &::after {
            content: '';
            border-style: solid;
            border-width: 1px 1px 0 0px;
            border-color: @sw-color-grey-darker;
            display: inline-block;
            width: 12px;
            height: 12px;
            left: auto;
            right: -6px;
            position: relative;
            vertical-align: top;
            top: 0;
            transform: rotate(135deg);
          }
        } 
      }
    }
  }

  @media (max-width: @screen-md-min) {
    .forum-input-col p {
        margin-bottom: @padding-small;
      }
  }

  .forum-create-post__personal-details {
    position: relative;
  }
  // loading
  .loading-component {
    height: 250px;
    .container {
      width: 128px;
      height: 128px;
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;
      margin: auto;
    }

    .container:nth-child(1) {
      transform: rotate(0deg);
    }

    .container:nth-child(2) {
      transform: rotate(18deg);
    }

    .container:nth-child(3) {
      transform: rotate(36deg);
    }

    .container:nth-child(4) {
      transform: rotate(54deg);
    }

    .container:nth-child(5) {
      transform: rotate(72deg);
    }

    .container:nth-child(6) {
      transform: rotate(90deg);
    }

    .container:nth-child(7) {
      transform: rotate(108deg);
    }

    .container:nth-child(8) {
      transform: rotate(126deg);
    }

    .container:nth-child(9) {
      transform: rotate(144deg);
    }

    .container:nth-child(10) {
      transform: rotate(162deg);
    }

    .container:nth-child(11) {
      transform: rotate(180deg);
    }

    .container:nth-child(12) {
      transform: rotate(198deg);
    }

    .container:nth-child(13) {
      transform: rotate(216deg);
    }

    .container:nth-child(14) {
      transform: rotate(234deg);
    }

    .container:nth-child(15) {
      transform: rotate(252deg);
    }

    .container:nth-child(16) {
      transform: rotate(270deg);
    }

    .container:nth-child(17) {
      transform: rotate(288deg);
    }

    .container:nth-child(18) {
      transform: rotate(306deg);
    }

    .container:nth-child(19) {
      transform: rotate(324deg);
    }

    .container:nth-child(20) {
      transform: rotate(342deg);
    }

    .container .object {
      position: absolute;
      top: 56px;
      left: 112px;
      background: @sw-color-text-dark;
      border-radius: 16px;
      animation: rotate 1s linear infinite;
    }

    @keyframes rotate {
      0% {
        width: 16px;
        height: 16px;
      }

      100% {
        width: 0px;
        height: 0px;
      }
    }

    .container:nth-child(1) .object {
      animation-delay: 0s;
    }

    .container:nth-child(2) .object {
      animation-delay: 0.05s;
    }

    .container:nth-child(3) .object {
      animation-delay: 0.1s;
    }

    .container:nth-child(4) .object {
      animation-delay: 0.15s;
    }

    .container:nth-child(5) .object {
      animation-delay: 0.2s;
    }

    .container:nth-child(6) .object {
      animation-delay: 0.25s;
    }

    .container:nth-child(7) .object {
      animation-delay: 0.3s;
    }

    .container:nth-child(8) .object {
      animation-delay: 0.35s;
    }

    .container:nth-child(9) .object {
      animation-delay: 0.4s;
    }

    .container:nth-child(10) .object {
      animation-delay: 0.45s;
    }

    .container:nth-child(11) .object {
      animation-delay: 0.5s;
    }

    .container:nth-child(12) .object {
      animation-delay: 0.55s;
    }

    .container:nth-child(13) .object {
      animation-delay: 0.6s;
    }

    .container:nth-child(14) .object {
      animation-delay: 0.65s;
    }

    .container:nth-child(15) .object {
      animation-delay: 0.7s;
    }

    .container:nth-child(16) .object {
      animation-delay: 0.75s;
    }

    .container:nth-child(17) .object {
      animation-delay: 0.8s;
    }

    .container:nth-child(18) .object {
      animation-delay: 0.85s;
    }

    .container:nth-child(19) .object {
      animation-delay: 0.9s;
    }

    .container:nth-child(20) .object {
      animation-delay: 0.95s;
    }
  }
}