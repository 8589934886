﻿.chosen-select {
  width: 100%;
}
//Dropdown height issue
.chosen-container-multi .chosen-drop {
  display: none;
}

.chosen-container-multi.chosen-container-active .chosen-drop {
  display: block;
}

.chosen-container-multi ul.chosen-choices.has-no-choices li.search-field-selection input {
  height: 31px;
}

.chosen-container-multi.chosen-container-active .chosen-drop .chosen-choices.has-no-choices .search-field-selection input {
  height: 34px;
}

.chosen-container-single.chosen-container-active .chosen-drop {
  display: block;
}

.chosen-container-single ul.chosen-choices.has-no-choices li.search-field-selection input {
  height: 31px;
}

.chosen-container-single.chosen-container-active .chosen-drop .chosen-choices.has-no-choices .search-field-selection input {
  height: 34px;
}

.chosen-select-deselect {
  width: 100%;
}

.chosen-select-deselect {
  width: 100%;
}

.chosen-select-deselect {
  width: 100%;
}

// Fadein the elements to prevent visible jump
.site-ft .device-desktop .ftMembersSearch .member-form, .site-ft .device-desktop .committe-search, .site-thedanishparliamentdk .device-desktop .member-form {
  visibility: hidden;
}

.chosen-container {
  display: inline-block;
  font-size: 14px;
  position: relative;
  vertical-align: middle;

  .committe-search &, .member-form &, .quick-lookup__form &, .quick-lookup-d &, .page-reference__form & {
    margin: 0;
  }

  &:not(.chosen-with-drop):focus,
  &:not(.chosen-with-drop):focus-within {
    .tab-focus();
    outline-offset: 1px;
  }
}

.chosen-container .chosen-drop {
    border: 1px solid @color-tertiary-result-absent;
    -webkit-box-shadow: 0 8px 8px rgba(0, 0, 0, .25);
    box-shadow: 0 8px 8px rgba(0, 0, 0, .25);
    margin-top: -1px;
    position: absolute;
    top: 100%;
    left: -9000px;
    z-index: 1060;
    border-top: 0;
    padding: 0 5px;

    .dokumenter-columns &, .committe-search &, .member-form &, .quick-lookup__form &, .quick-lookup-d &, .page-reference__form & {
        background: @color-secondary-lighter;
    }

    .listespot-wrapper &, .ebook .page-reference__form &, .ebook-search &, .e-book-list-session-lookup &, .calendar-search & {
        background: white;
    }
}

.chosen-container.chosen-with-drop .chosen-drop {
  left: 0;
  right: 0;
}

.chosen-container .chosen-results {
  margin: 0 0 5px 0 !important;
  max-height: 500px;
  padding: 0;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.chosen-container .chosen-results li {
  display: none;
  color: @color-secondary-darker;
  line-height: 1.42857143;
  font-weight: normal;
  list-style: none;
  margin: 0;
  padding: 5px 6px;
}

.chosen-container .chosen-results li:first-child {
  display: none;
}

.chosen-container .chosen-results li em {
  background: @color-secondary-lighter;
  font-style: normal;
}

.chosen-container .chosen-results li.group-result {
  display: list-item;
  cursor: default;
  color: @color-secondary-darker;
  font-weight: bold;
}

.chosen-container .chosen-results li.group-option {
  padding-left: 15px;
}

.chosen-container .chosen-results li.active-result {
  cursor: pointer;
  display: list-item;
}

.chosen-container .chosen-results li.highlighted {
  background-color: @color-primary-function-bg;
  background-image: none;
  color: #FFFFFF;

   .header-tabs .dokumenter-columns &, .committe-search &, .member-form &, .header-tabs .quick-lookup__form &, .quick-lookup-d &, .header-tabs .page-reference__form &, .dokumenter-columns & {
    background-color: @color-primary-function;
    color: white;
  }
}

.chosen-container .chosen-results li.highlighted em {
  background: transparent;
}

.chosen-container .chosen-results li.disabled-result {
  display: list-item;
  color: @color-secondary-darker;
}

.chosen-container .chosen-results .no-results {
  background: @color-menu-selected-bg;
  display: list-item;
}

.chosen-container .chosen-results-scroll {
  background: white;
  margin: 0 4px;
  position: absolute;
  text-align: center;
  width: 321px;
  z-index: 1;
}

.chosen-container .chosen-results-scroll span {
  display: inline-block;
  height: 1.42857143;
  text-indent: -5000px;
  width: 9px;
}

.chosen-container .chosen-results-scroll-down {
  bottom: 0;
}

.chosen-container .chosen-results-scroll-down span {
  background: url("/dist/Images/Folketinget/chosen-sprite.png") no-repeat -4px -3px;
}

.chosen-container .chosen-results-scroll-up span {
  background: url("dist/Images/Folketinget/chosen-sprite.png") no-repeat -22px -3px;
}

.chosen-container-single .chosen-single {
  background-color: #ffffff;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
  border: 1px solid @color-tertiary-result-absent;
  color: @color-secondary-darker;
  display: block;
  height: 34px;
  overflow: hidden;
  line-height: 34px;
  padding: 0 0 0 10px;
  position: relative;
  text-decoration: none;
  white-space: nowrap;

  .committe-search &, .member-form &, .page-reference__form &, .quick-lookup__form &, .quick-lookup-d &{
    background: @color-secondary-lighter;
    height: 40px;
    border: 0;
  }
  .ebook .page-reference__form &{
    background: white;

  }
  .e-book-list-session-lookup & {
    background: white;
    border: 1px solid #d3d3d3!important;
  }
}

  .chosen-container-single.chosen-collapsed .chosen-single:hover{
    cursor: pointer;
  }


.chosen-container-single .chosen-single span {
  display: block;
  margin-right: 26px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: normal;
  white-space: nowrap;

  .committe-search &, .member-form &, .page-reference__form &, .quick-lookup__form &, .quick-lookup-d & {
    line-height: 39px;
  }
}

.chosen-container-single .chosen-single abbr {
  display: block;
  font-size: 1px;
  height: 10px;
  position: absolute;
  right: 26px;
  top: 12px;
  width: 12px;
}

.chosen-container-single .chosen-single abbr:hover {
  background-position: right -11px;
}

.chosen-container-single .chosen-single.chosen-disabled .chosen-single abbr:hover {
  background-position: right 2px;
}

.chosen-container-single .chosen-single div {
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
}
.chosen-container-multi .chosen-choices div {
  display: block;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
}

.chosen-container-single .chosen-single div b {
  background: url("/dist/Images/Folketinget/chosen-sprite.png") no-repeat 2px 7px;
  display: block;
  height: 100%;
  width: 100%;
}
.chosen-container-multi .chosen-choices div b {
  background: url("/dist/Images/Folketinget/chosen-sprite.png") no-repeat 2px 7px;
  display: block;
  height: 100%;
  width: 100%;
}

.chosen-container-single .chosen-default {
  color: @color-secondary-darker;
}

.chosen-container-single .chosen-search {
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 0;
  position: relative;
  white-space: nowrap;
  z-index: 1000;

  .has-no-search & {
    display: none;
  }
  .is-disabled-search & {
    position: absolute;
    left: -99999999px;
  }
}

.chosen-container-single .chosen-search input[type="text"] {
    background: @color-secondary-lighter;
    border: 1px solid @color-tertiary-result-absent;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    margin: 1px 0;
    padding: 4px 20px 4px 4px;
    width: 100%;

    .committe-search &, .member-form & {
        background: white;
        padding: 6px 12px;
    }

    .quick-lookup__form &, .quick-lookup-d & {
        background-color: white !important;
        padding: 6px 12px;
    }

    .ebook-search & {
        padding: 6px 12px;
    }

    .header-tabs .quick-lookup__form &, .quick-lookup-d &, .page-reference__form &, .e-book-list-session-lookup & {
        background-color: white !important;
        padding: 6px 12px;
    }

    .page-reference__form &, .e-book-list-session-lookup & {
        background-color: white !important;
        padding: 6px 12px;
    }

    .page-reference__form & {
        background-color: @color-secondary-lighter !important;
        padding: 6px 12px;
    }
}

.chosen-container-single .chosen-drop {
  margin-top: -1px;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

.chosen-container-single-nosearch .chosen-search input {
  position: absolute;
  left: -9000px;
}

.chosen-container-multi .chosen-choices {
  border: 1px solid @color-tertiary-result-absent;
  cursor: text;
  height: auto !important;
  height: 1%;
  margin: 0;
  overflow: hidden;
  padding: 0 5px !important;
  position: relative;

  &:focus {
    outline: 0;
  }

  .listespot-wrapper & {
    background: white;
  }

  .dokumenter-column & {
    background: @color-secondary-lighter;
  }
}

.chosen-container-multi .chosen-choices li {
  float: left;
  list-style: none;
}

.chosen-container-multi .chosen-choices .search-field {
  background: khaki;
  margin: 0;
  padding: 0;
  white-space: nowrap;
}

.chosen-container-multi .chosen-choices .search-field input[type="text"] {
  background: transparent !important;
  border: 0 !important;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #555555;
  height: 32px;
  margin: 0;
  padding: 4px;
  outline: 0;
}

.chosen-container-multi .chosen-choices .search-field .default {
  color: #999;
}

.chosen-container-multi .chosen-choices .search-choice {
  background-clip: padding-box;
  border: 1px solid @color-tertiary-result-absent;
  cursor: default;
  line-height: 13px;
  margin: 5px 5px 5px 0;
  padding: 3px 6px 3px 5px;
  position: relative;
  max-width: 88%;

  span {
    color: @color-secondary-darker;
    display: inline-block;
    margin-right: 6px;
  }

  .dokumenter-columns & {
    background-color: white;
  }

  .listespot-wrapper & {
    background-color: @color-secondary-lighter;
  }
}

.chosen-container-multi .chosen-choices .search-choice .search-choice-close {
  display: inline-block;
  color: @color-secondary-darker;
  font-size: 18px;
  font-weight: bold;
  position: relative;
  top: 1px;
  width: 10px;
  height: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: @color-primary-function;
  }

  &:after {
    content: '\00d7';
    display: block;
    width: 10px;
    height: 10px;
  }
}

.chosen-container-multi .chosen-choices .search-choice .search-choice-close:hover {
  background-position: right -11px;
}

.chosen-container-multi .chosen-choices .search-choice-focus {
  background: #d4d4d4;
}

.chosen-container-multi .chosen-choices .search-choice-focus .search-choice-close {
  background-position: right -11px;
}

.chosen-container-multi .chosen-results {
  margin: 0 0 0 0;
  padding: 0;
}

.chosen-container-multi .chosen-drop .result-selected, .chosen-container-single .chosen-drop .result-selected {
  display: list-item;
  color: @color-primary-function;
  cursor: default;
  pointer-events: none;

  &:before {
    content: "\e372";
    display: inline-block;
    font-family: "ft-icons";
    position: relative;
    margin-right: 5px;
  }
}

.chosen-container-single .chosen-drop li.result-selected.highlighted {
  color: @color-primary-function !important;
  background: transparent;
}


.chosen-container-active .chosen-single {
  border: 1px solid @color-tertiary-result-absent;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
}

.chosen-container-active.chosen-with-drop .chosen-single {
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.chosen-container-active.chosen-with-drop .chosen-single div {
  background: transparent;
  border-left: none;
}

.chosen-container-active.chosen-with-drop .chosen-single div b {
  background-position: -16px 7px;
}
.chosen-container-multi.chosen-container-active.chosen-with-drop div b {
  background-position: -16px 7px;
}

.chosen-with-drop .chosen-choices {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
}

.chosen-container-active .chosen-choices .search-field input[type="text"] {
  color: #111 !important;
}

.chosen-container-active.chosen-with-drop .chosen-choices {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.chosen-disabled {
  cursor: default;
  opacity: 0.5 !important;
}

.chosen-disabled .chosen-single {
  cursor: default;

  &:hover {
    cursor: pointer;
  }
}

.chosen-disabled .chosen-choices .search-choice .search-choice-close {
  cursor: default;
}

.chosen-rtl {
  text-align: right;
}

.chosen-rtl .chosen-single {
  padding: 0 8px 0 0;
  overflow: visible;
}

.chosen-rtl .chosen-single span {
  margin-left: 26px;
  margin-right: 0;
  direction: rtl;
}

.chosen-rtl .chosen-single div {
  left: 7px;
  right: auto;
}

.chosen-rtl .chosen-single abbr {
  left: 26px;
  right: auto;
}

.chosen-rtl .chosen-choices .search-field input[type="text"] {
  direction: rtl;
}

.chosen-rtl .chosen-choices li {
  float: right;
}

.chosen-rtl .chosen-choices .search-choice {
  margin: 6px 5px 3px 0;
  padding: 3px 5px 3px 19px;
}

.chosen-rtl .chosen-choices .search-choice .search-choice-close {
  background-position: right top;
  left: 4px;
  right: auto;
}

.chosen-rtl.chosen-container-single .chosen-results {
  margin-bottom: 0 0 4px 4px;
  padding: 0 4px 0 0;
}

.chosen-rtl .chosen-results .group-option {
  padding-left: 0;
  padding-right: 15px;
}

.chosen-rtl.chosen-container-active.chosen-with-drop .chosen-single div {
  border-right: none;
}

.chosen-rtl .chosen-search input[type="text"] {
  background: url("/dist/Images/Folketinget/chosen-sprite.png") no-repeat -28px -20px, #ffffff;
  direction: rtl;
  padding: 4px 5px 4px 20px;
}

.search-field-selection {
  display: block;
  width: 100%;
  height: 0;
  overflow: hidden;
}

.ankiro .ankiro-searchform-form .search-field-selection input {
  border: 0 !important;
}

.ankiro .ankiro-searchform-form .chosen-with-drop .search-field-selection input {
  border: 1px solid #d3d3d3 !important;
}

.chosen-container .icon-ft_search {
  display: none;
}

.chosen-container-active .icon-ft_search {
  display: block;
}

.dokumenter-columns .tab-spot-a__content .tab-spot-a__content__pane .chosen-container-active input[type="text"] {
  background-color: white !important;
}

.default {
  background: transparent;
}

.chosen-container-active .default {
  background: #f0f2f2;
}

.default:hover {
  cursor: pointer;
}

.has-no-choices .search-field-selection {
  height: auto;
}

.chosen-container .is-search .icon-ft_search {
  display: none;
}

.chosen-choices.has-no-results {
  position: relative;
  &:before {
    display: block;
    content: "Søg";
    position: absolute;
    left: 17px;
    top: 7px;
    color: #252525;
  }
}
.site-thedanishparliamentdk .chosen-choices.has-no-results {
  position: relative;
  &:before {
    display: block;
    content: "Search";
    position: absolute;
    left: 17px;
    top: 7px;
    color: #252525;
  }
}


.tab-spot-a__content .tab-spot-a__content__pane .has-no-choices .default {
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
  padding: 6px 5px !important;
}

.tab-spot-a__content .tab-spot-a__content__pane .chosen-container-active .has-no-choices .default {
  background-color: white !important;
}




.chosen-with-drop .search-field-selection {
  height: auto;
  overflow: auto;
  position: relative;
}

.chosen-with-drop .search-field-selection input {
  width: 100% !important;
  height: 34px;
  margin-bottom: 5px;
  margin-top: 5px;
}

.search-field-selection input {
  width: 100% !important;
}

.search-field-selection input:focus {
  outline: 0 !important;
}

.chosen-with-drop .has-no-choices .search-field-selection input {
  border: 1px solid #d3d3d3 !important;
}

.chosen-with-drop .search-field-selection input:focus {
  outline: 0;
}

.chosen-with-drop .search-field-selection .icon-ft_search {
  position: absolute;
  color: @color-secondary-darker;
  right: 10px;
  top: 30%;
}

.chosen-container-single.chosen-with-drop .chosen-single {
  .quick-lookup__form &, .quick-lookup-d &, .page-reference__form &, .committe-search &, .member-form & {
    border: 1px solid @color-tertiary-result-absent;
  }
}

.chosen-with-drop .has-no-choices .search-field-selection .icon-ft_search {
  top: 12px;
}

.chosen-with-drop .search-field-selection icon-ft_search:before {
  display: block;
  content: "\e658";
  font-family: "ft-icons";
}

.chosen-container-multi .chosen-choices {
  margin: 0 !important;
}

.chosen-container .chosen-choices:hover {
  cursor: pointer;
}

.dokumenter-columns .chosen-choices {
  background-color: #f0f2f2;
}

.dokumenter-columns .chosen-container-single .chosen-single {
  background-color: #f0f2f2;
  color: #696969!important;
}



// Reset button
.chosen-container .reset-button {
  display: none;

  &:hover {
    color: @color-primary-function !important;
  }
}

.search-input::-ms-clear {
  display: none;
}

.search-input {
  font-weight: normal;
}

.chosen-with-drop {
  .reset-button {
    position: absolute;
    background: transparent;
    right: 4px;
    top: 25%;
    color: @color-secondary-darker;
    border: 0;
    outline: none;
    font-size: 18px;
    font-weight: bold;
  }
}

.chosen-with-drop.chosen-container-single {
  .reset-button {
    top: 22%;

    .header-tabs & {
      top: 24%;
    }
  }
}
.header-tabs .tab-spot-a__content .tab-spot-a__content__pane input[type="text"].search-input{
  background-color: #fff!important;
}
.content-area .filter-left-menu.calendar-search .form-group .search-input{
   background-color: @color-secondary-lighter!important;
   padding: 6px 12px;
}
