@import "./navigation.less";
@import "./breadcrumb.less";
.subsite-wide {
  .nav-is-open {
    position: fixed;
  }
  
  .site-header {
    padding-top: 100px;

    @media (min-width: @screen-lg-min) {
      padding-top: 115px;
    }

    .header-secondaryservice-menu {
      &:extend(.subsite-wide-container--wide);
    }
  }
  
  .subsite-wide-navbar {
    background-color: @sw-color-background;
    background-color: var(--sw-color-background, @sw-color-background);
    border: 0;
    border-radius: 0;
    height: 100px;

    @media (min-width: @screen-lg-min) {
      padding: @spacing-small 0;
      height: 114px;
      margin: 0;

      &.navbar-static-top {
        position: fixed;
        width: 100%;
      }

      &.drawer-is-open {
        border-bottom: 1px solid @sw-color-border-light;
      }
    }

    @media (max-width: @screen-lg-min) {
      &.navigation--toggled {
        height: 100vh;
        overflow-y: auto;
      }
    }
  }

  .subsite-wide-menu {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
    
    width: 100%;
    max-width: 100vw;
    position: relative;
    height: 100%;
  }

  .header-container {
    padding: @spacing-small 0;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media (min-width: @screen-lg-min) {
      padding: 0;
      height: 100%;
      width: 100%;
    }

    @media (max-width: @screen-lg-min) {
      &-inner {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
}
