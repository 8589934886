﻿@import "../../Global/Components/udvalgskontaktspot-a.less";

.udvalgskontaktspot-a {
  margin-top: 0;
  margin-bottom: 30px;
  padding: 25px 15px;

  h2 {
    padding-left: 24px;
    margin: 0;
  }

  .show-all {
    padding-right: 24px;

    &:after {
      content: "\e631";
    }
  }

  .contacts {
    margin-top: 20px;
  }

  &__col {
    padding: 0;
  }
}

.medlemmersidespot-a {
  &__showmore a {
    @media (max-width: @screen-sm) {
      float: left;
      padding-left: 23px;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
}
