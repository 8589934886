.subsite-wide {
  .breadcrumb-wrapper {
    &:extend(.subsite-wide-container);
    margin: 0 auto;
    margin-bottom: @section-spacing-small !important;

    nav {
      padding: 0;
    }

    .breadcrumb {
      padding: 0;

      > li + li:before {
        color: @sw-color-text-dark;
        padding-left: 8px !important;
        padding-right: 0px;
        font-size: 28px;
      }

      li {
        font-size: @sw-font-base;
        line-height: 24px;
        a {
          &[aria-current="page"] {
            font-weight: @sw-font-bold;
          }
        }
      }

      a,
      > a,
      > .active {
        color: @sw-color-text-dark;
      }
    }
  }
}
