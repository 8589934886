.subsite-wide {
  .category-card {
    .card__icon {
      width: 45px;
      height: 45px;
      margin-right: 8px;
      flex-shrink: 0;

      @media (max-width: @screen-md-min) {
        width: 30px;
        height: 30px;
      }
    }
    .card__header {
      flex-grow: 1;
      margin: 0 0 10px 0;
    }
    .card__image {
      overflow: hidden;
      transition: filter 500ms ease-in-out;
      img {
        transition: transform 500ms ease-in-out;
      }
    }

    .card__title {
      &:extend(.header--medium);
    }

    .card__hover-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: opacity 150ms linear;
      opacity: 0;
      z-index: 1;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      color: @sw-color-text-light;
      font-size: @sw-font-xl;
      line-height: 34px;
      font-weight: @sw-font-semi-bold;
      width: 100%;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
    }
    .card__hover-content-icon {
      margin-top: 5px;
      width: 25px;
      height: 15px;
      color: @sw-color-text-light;

      svg {
        width: 25px;
        height: 15px;
      }
    }
    &:hover {
      text-decoration: none;
      .card__hover-content {
        opacity: 1;
      }
      .card__image {
        filter: brightness(70%);
        img {
          transform: scale3d(1.02, 1.02, 1);
        }
        overflow: hidden;
      }
    }

    @media (max-width: @screen-sm-min) {
      text-decoration: none;
      .card__hover-content {
        opacity: 1;
      }
      .card__image {
        filter: brightness(70%);
        img {
          transform: scale3d(1.02, 1.02, 1);
        }
        overflow: hidden;
      }
    }
  }
  .slider-mixed-card {
    .category-card {
      @media (max-width: @screen-sm-min) {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .card__hover-content {
          display: none;
          bottom: 50%;
          flex-direction: row;

          &-icon {
            margin-top: 0;
            margin-left: 5px;
          }
        }

        .card__header {
          margin: 0 0;
          padding: 0 25px;
          flex-grow: 0;
          flex-direction: row;
          position: relative;
          z-index: 1;
          margin-top: -50px;

          .card__title {
            color: @sw-color-light;
            display: inline-flex;
            align-items: center;

            &:after {
              content: "";
              background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAzMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuOTk5Mzc0IDEwLjE3NjFMMjYuODE2OSAxMC4xNzYxIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTkuOTg5OSAxOC4yNDRMMjguNTk1NyAxMC4xMjJMMTkuOTg5OSAxLjk5OTk5IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
              background-repeat: no-repeat;
              background-size: 30px 20px;
              background-position: 0px 6px;
              width: 30px;
              height: 30px;
              display: inline-block;
              margin-left: 10px;
              flex-shrink: 0;
            }
          }

          .card__icon {
            width: 30px;
            height: 30px;
          }
        }

        .card__content {
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          position: absolute;
        }
      }

      .card__header {
        @media (min-width: @screen-sm-min + 1) {
          display: none;
        }
      }

      .card__content {
        @media (min-width: @screen-sm-min + 1) {
          height: 100%;
        }
      }

      .card__image {
        height: 100%;

        img {
          object-fit: cover;
          height: 100%;
        }

        .card__desktop-image {
          display: inline;
          @media (max-width: @screen-sm-min) {
            display: none;
          }
        }
        .card__mobile-image {
          display: none;
          @media (max-width: @screen-sm-min) {
            display: inline;
          }
        }
      }
    }
  }
}
