@import "../../Global/Components/tekst-spot-a.less";

.text-spot-a-wrapper {
  padding-left: 38px;
  padding-right: 38px;
  //.text-link();
  //Generic rte img
  .rte-image {
    width: 100% !important;
    height: auto !important;
  }

  &__newspage {
    background-color: #ffffff;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 0px !important;
  }
  //Custome List links
  ul.link-list {
    display: table;
    padding-left: 0;

    li a:not(.link-list):not(.booking-order-button):not(.read-more-expander) {
      border-bottom: 1px solid transparent;

      &:hover {
        text-decoration: none;
        border-bottom: 1px solid @color-brand-200;
      }
    }

    span {
      padding-left: 0 !important;

      &:before {
        display: none !important;
        content: "" !important;
      }
    }

    li {
      vertical-align: middle;
      list-style-type: none;

      &:hover {
        background-color: transparent;
      }

      a {
        display: inline-block;
        padding-left: 15px;
        border-bottom: none;

        &:hover {
          color: @link-hover-color;
          text-decoration: underline;
          background-color: transparent;
        }

        &:before {
          display: inline-block;
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_bullit_right:before);
          font-size: 11px;
          float: left;
          margin: 4px 0 0 -15px;
        }
      }

      a.front-arrow {
        padding-left: 0;

        &:before {
          display: none;
          content: "";
        }
      }
    }
  }
  //Bullets applied on regular list
  ul:not(.link-list) {
    li {
      list-style-type: disc;

      a {
        border-bottom: none !important;

        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }

  a:not(.link-list):not(.booking-order-button):not(.read-more-expander) {
    color: @color-brand-200;
    border-bottom: 1px solid @color-menu-selected-tab;
    &:not(.btn-standard) {
      padding-right: 0 !important;
    }
    &:hover {
      text-decoration: none;
      border-bottom: 1px solid @color-brand-200;
    }
  }

  a.front-arrow {
    padding-left: 0;
    border-bottom: 0 !important;

    &:hover {
      border-bottom: 0;
      text-decoration: underline !important;
    }

    &:before {
      content: "";
      display: none;
    }

    &:after {
      display: inline-block;
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);
      font-size: 11px;
      position: relative;
      margin-left: 5px;
    }
  }

  h1 a.front-arrow:after {
    font-size: 3.6rem;
  }

  h2 a.front-arrow:after {
    font-size: 1.6rem;
  }

  h6 a.front-arrow:after {
    font-size: 0.8rem;
  }

  .read-more-expander {
    color: #696969;
    background-color: transparent;
    border: 0;

    &:hover {
      background-color: transparent;
      color: @color-primary-function;

      span,
      i:before {
        background-color: transparent;
        color: @color-primary-function;
      }
    }
  }

  .caption-more {
    span {
      display: block;
      width: auto;
      overflow: visible;
      text-indent: 0;
      font-weight: 400;
      margin-bottom: 10px;
    }

    i {
      margin: 0;
    }
  }

  .caption-less {
    span {
      display: block;
      width: auto;
      overflow: visible;
      text-indent: 0;
      font-weight: 400;
      margin-bottom: 10px;
    }

    i {
      margin: 0;
    }
  }

  h1 {
    padding: 0;
  }

  h2 {
    margin-bottom: 5px !important;
    margin-top: 20px;
    .font-size(2);
    font-family: inherit;
    font-weight: 500;
    color: inherit;
    display: block;
  }

  h3 {
    margin-bottom: 5px;
    margin-top: 20px;
    .font-size(1.8);
    font-family: inherit;
    font-weight: 500;
    color: inherit;
    display: block;
  }

  h4 {
    margin-bottom: 5px !important;
    .font-size(1.4);
    font-family: inherit;
    font-weight: 500;
    margin-top: 10px;
    color: inherit;
    display: block;
  }

  h5 {
    margin-bottom: 5px !important;
    .font-size(1.3);
    margin-top: 10px;
    font-family: inherit;
    font-weight: 500;
    color: inherit;
    display: block;
  }

  h6 {
    margin-bottom: 5px !important;
    .font-size(1.2);
    margin-top: 10px;
    font-family: inherit;
    font-weight: 500;
    color: inherit;
    display: block;
  }

  &.content-rte {
    ul:not(.link-list) {
      li {
        list-style-type: disc;
      }
    }

    a:not(.link-list):not(.booking-order-button):not(.read-more-expander) {
      border-bottom: 1px solid @color-menu-selected-tab;

      &:hover {
        text-decoration: none;
        border-bottom: 1px solid @color-brand-200;
      }
    }

    ul.link-list {
      li a:not(.link-list):not(.booking-order-button):not(.read-more-expander) {
        border-bottom: 1px solid transparent;

        &:hover {
          text-decoration: none;
          border-bottom: 1px solid @color-brand-200;
        }
      }
    }
  }

  .rte-quote {
    padding-left: 25px;
  }
  //VOD iframe inside RTE

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.newspage {
  &__image-container {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;
    margin-bottom: 0px !important;
    padding-bottom: 0px;

    &__image {
      width: 100% !important;
      height: 100% !important;
      display: block;
    }
  }
}

.newspage-link {
  display: block !important;
  padding-left: 15px;
  border-bottom: none !important;

  &:hover {
    text-decoration: underline !important;
    background-color: transparent;
  }

  &:before {
    display: inline-block;
    &:extend(.less-icon-extend);
    &:extend(.icon-ft_bullit_right:before);
    font-size: 11px;
    float: left;
    margin: 4px 0 0 -15px;
  }
}

.newspage-link-container {
  margin-top: 10px;
}

.newspage-img-empty {
  width: auto !important;
  height: auto !important;
}
