﻿@media(max-width: @screen-xs-max) {
  #slide-in {
    display: none;
  }
}

.pageedit {
  #slide-in {
    display: none;
  }
}

.ie8 {
  #slide-in {
    .slide-in-toggle {
      top: 0;
      filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);

      a {
        &:before {
          filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=-3);
        }
      }
    }
  }
}

#slide-in {
  z-index: 10001;
  position: fixed;
  top: 65px;
  right: 0;
  width: 0;
  max-width: 226px;
  max-height: 100vh;
  -webkit-transition: width 400ms;
  -moz-transition: width 400ms;
  -o-transition: width 400ms;
  transition: width 400ms;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  &.slide-in-open {
    width: 226px;
    right: 0;
  }

  .slide-in-toggle {
    z-index: 1;
    position: absolute;
    top: 248px !important;
    left: -38px;
    -ms-writing-mode: lr-tb;
    writing-mode: horizontal-tb;
    -webkit-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-backface-visibility: hidden;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg);
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;

    a {
      box-shadow: -4px 4px 5px 0px rgba(0, 0, 0, 0.5);
      border-radius: 5px 5px 0 0;
      width: 118px;
      padding: 12px 50px 11px 16px;

      &:before {
        border-radius: 5px 0 0 0;
        -ms-transform: rotate(-270deg);
        -moz-transform: rotate(-270deg);
        -o-transform: rotate(-270deg);
        -webkit-transform: rotate(-270deg);
        transform: rotate(-270deg);
        width: 38px;
        left: 100%;
        margin-left: -38px;
        max-width: 38px;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        backface-visibility: hidden;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        font-size: 1.6rem;
        text-indent: -2px;
        color: darken(@color-brand-400, 28%);
        line-height: 32px !important;
      }
    }
  }

  .slide-in-canvas {
    z-index: 2;
    position: absolute;
    padding: 0 20px;
    background-color: @color-background-main;
    width: 226px;
    max-width: 226px;
    min-height: 130px;
    border-radius: 0 0 0 10px;
    box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.21);
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 50px);
    overflow: auto;

    .slide-in-section {
      .clearfix();
      padding: 15px 0;
      border-bottom: 1px solid @color-contrast-300;

      .slide-in-section-icon {
        position: relative;
        top: -3px;
        float: left;
        width: 30px;
        height: 30px;
        font-size: 3rem;
        text-align: center;
        color: @color-brand-200;
        margin-right: 10px;
        margin-bottom: 10px;
      }

      .slide-in-section-header,
      .slide-in-section-body {
        float: left;
        max-width: 145px;

        a {
          color: @link-secondary-color;
        }
      }

      .slide-in-section-header {
        .font-size(@type-size-base);
      }

      .slide-in-section-body {
        .font-bold();
        .font-size(@type-size-lg); // 1.6
        color: @color-brand-200;
      }

      .slide-in-section-footer {
        .font-size(@type-size-md);
        clear: both;
        width: 100%;
      }
    }

    .slide-in-footer {
      padding: 18px 0;

      .slide-in-footer-title {
        .font-bold();
        .font-size(@type-size-md);
        margin-bottom: 12px;
      }

      .slide-in-footer-body {
        .font-size(@type-size-md);

        address {
          margin-bottom: 0;
        }
      }
    }
  }
}
