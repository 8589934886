﻿.document-list-wrapper {
  background-color: @color-contrast-400;
  padding: 20px 25px;
  margin-bottom: 25px;

  .document-list-title {
    .font-bold();
    .font-size(@type-size-md);
    height: 30px;
    line-height: 30px;
  }

  .document-list {
    margin: 0;
    padding: 0;
    list-style: none;
    border-top: 1px solid @color-text-light;

    li {
      border-bottom: 1px solid @color-text-light;

      a {
        .font-bold();
        .font-size(@type-size-md);
        height: 30px;
        line-height: 30px;

        color: @link-secondary-color;
      }
    }
  }
}

.status-header {
  .font-regular();
  .font-size(@type-size-lg);
  margin-bottom: 25px;
}

.status-block-header {
  .font-bold();
  .font-size(@type-size-lg);
  margin-bottom: 10px;
}

.status-block-header-lg {
  .font-bold();
  .font-size(@type-size-xl);
  border-bottom: 1px solid @tabs-pane-content-border-color;
  margin-bottom: 20px;
  line-height: 30px;
}

.status-block-list {
  border-top: 1px solid @tabs-pane-content-border-color;

  dd {
    padding-top: 5px;

    a {
      .font-bold();
      .font-size(@type-size-md);
      color: @link-secondary-color;
    }
  }

  dt {
    border-bottom: 1px solid @tabs-pane-content-border-color;
    padding-bottom: 5px;
  }
}

.agenda-revision-number {
  text-transform: uppercase;
}

dl {
  &.dl-horizontal-left-aligned {
    dt, dd {
      text-align: left;
    }
  }
}

.agenda-header {
  .document-reference {
    margin-left: 15px;
    margin-bottom: 10px;
    &:last-of-type {
      margin-bottom: 20px;
    }
  }
  .reading-mark {
    // font-size: 1.8rem;
  }
  .document-reference > a {
			position:relative;
			padding-left: 0;
      font-size: 1.4rem!important;
      border-bottom: 0!important;
      &:hover {
        text-decoration: underline!important;
      }
			&:before {
				position: absolute;
				display: inline-block;
				&:extend(.less-icon-extend);
				&:extend(.icon-ft_bullit_right:before);
				font-size: 11px;
				margin: 4px 0 0 -15px;
			 }
		}
  em {
    font-style: italic ;
  }
  .panel-group {
    margin: 0 -38px;
    &:last-of-type {
        margin-bottom: -38px!important;
    }
    .panel-body {
        padding-left: 38px!important;
        padding-right: 38px!important;
    }
  }
}

.document-reference {
  margin-left: 20px;
}

.content-area {
  h3 {
    margin-bottom: 20px;
  }

  > a {
    &:extend(.panel-middle .panel-body a);

    &:hover {
      &:extend(.panel-middle .panel-body a:hover);
    }
  }

  li {
    a {
      &:extend(.panel-middle .panel-body a);

      &:hover {
        &:extend(.panel-middle .panel-body a:hover);
      }
    }
  }
}