﻿@import "../../Global/Components/meetings-in-parliament.less";

.meetings-parliament {
  color: @color-primary-dark;
  margin-bottom: 30px;
  margin-top: 0;


  &__image-container {
    width: 100%;
    height: auto;
  }

  &__two-column {
    margin-bottom: 0;
    max-width: none;

    &__left {
      @media screen and (max-width: @screen-xs) {
        padding-bottom: 1px;
      }
    }
  }

  .link:before {
    display: inline-block;
    vertical-align: middle;
    &:extend(.less-icon-extend);
    &:extend(.icon-ft_bullit_right:before);
    font-size: 11px;
    margin-right: 4px;
  }

  .link {
    &:first-child {
      margin-top: 10px;
    }
  }
}

.title {
  .meetings-parliament & {
    font-family: "SceneAlt", serif;
    .font-size(2.5);
  }
}
