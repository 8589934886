﻿.facet-menu {
  padding: 0 15px;
  margin-bottom: 25px;
  display: none;

  &__preloader {
    fill: @color-primary-function;
    width: 18px;
    height: 18px;
    position: relative;
    margin-top: 50%;
    margin-left: 50%;
    animation: rotation 2s infinite linear;
  }

  @-webkit-keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }

  &__show-more {
    position: relative;
    top: -10px;
    margin-bottom: 15px;

    a {
      background-color: transparent;
      color: @link-color;
      font-size: 14px !important;
      line-height: 16px !important;
      border-bottom: 1px solid transparent;
      padding: 0;
      display: inline-block;
      min-width: inherit;

      &:after {
        position: relative;
        top: 2px;
        margin-left: 5px;
        width: auto;
        background: transparent;
        color: @link-color !important;
        text-align: center;
        line-height: normal !important;
        font-family: "ft-icons";
        content: "\e901";
        display: inline-block;
      }

      &:hover {
        background-color: transparent !important;
        color: @link-color-hover;
        border-bottom: 1px solid @link-color-hover;

        &:before {
          color: @link-color-hover;
        }

        &:after {
          color: @link-color-hover;
        }
      }
    }
  }

  &__show-less {
    position: relative;
    top: -10px;
    margin-bottom: 15px;

    a {
      background-color: transparent;
      color: @link-color;
      font-size: 14px !important;
      line-height: 16px !important;
      border-bottom: 1px solid transparent;
      padding: 0;
      display: inline-block;
      min-width: inherit;

      &:after {
        position: relative;
        top: 2px;
        margin-left: 5px;
        width: auto;
        background: transparent;
        color: @link-color !important;
        text-align: center;
        line-height: normal !important;
        font-family: "ft-icons";
        content: "\e901";
        display: inline-block;
        transform: rotate(180deg);
      }

      &:hover {
        background-color: transparent !important;
        color: @link-color-hover;
        border-bottom: 1px solid @link-color-hover;

        &:before {
          color: @link-color-hover;
        }

        &:after {
          color: @link-color-hover;
        }
      }
    }
  }

  .informationspot-d.panel-group {
    background-color: transparent;
    margin-bottom: 25px;
    margin-top: 25px;
    width: 240px;

    @media only screen and (min-width: @screen-md) {
      width: auto;
    }

    .panel-middle {
      border-bottom: 0;
      border-top: 0;
    }

    .panel-middle .panel-heading {
      height: 45px;
      background-color: transparent;
      border-bottom: 1px solid #dddddd;

      h2.panel-title {
        padding-left: 0;
        display: inline-table;
        width: 100%;
      }

      h2.panel-title a {
        font-family: "adobe-caslon-pro", serif;
        color: @color-primary-darker !important;
      }

      h2.panel-title a:after {
        background-color: transparent;
        position: absolute;
        margin-right: 0;

        @media only screen and (min-width: @screen-md) {
          right: -16px;
        }
      }

      h2.panel-title a.is-disabled {
        pointer-events: none;

        &:after {
          display: none;
        }
      }
    }
  }

  .result-list-spot-accordion {
    &__manchet.text-spot-a-wrapper.content-rte {
      padding: 0 15px !important;
    }

   

    &__table-wrapper {
      .list-facets {
        padding-bottom: 7px;
        padding-top: 12px;
        margin: 0;
        margin-bottom: 5px;

        .custom-checkbox {
          margin-right: 7px;
          float: none;
        }

        &__items {
          margin: 5px 0;
          &.outline, 
          &:focus-within, 
          &:active {
            outline: 1px dotted #212121;
          }
          &.outline:not(*:root),
          &:focus-within, 
          &:active { // webkit
            outline-color: -webkit-focus-ring-color;
            outline-style: auto;
          }
          &--visible-wrapper {
            padding: 0;
          }

          &.is-limited {
            visibility: hidden;
            opacity: 0;
            height: 0;
            margin: 0;
          }

          &.is-limited.is-visible {
            opacity: 1;
            visibility: visible;
            height: auto;
            margin: 5px 0;
            transition: opacity 0.5s ease-in;
          }

          &.is-checked {
            label {
              color: @color-primary-function !important;
            }
          }
        }

        &__checkbox {
          label {
            position: relative;
            top: 3px;
            width: 81%;
            vertical-align: top;
            color: @color-contrast-150 !important;

            &:hover {
              color: @color-primary-function !important;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.ankiro-facets {
  .panel-left-side .list-facets.search-check-facet {
    padding: 7px 0 7px 10px !important;
  }


  &.filter-left-menu .panel-group {
    padding-right: 0 !important;
    margin-right: 0 !important;

    @media only screen and (max-width: 1024px) {
      padding-left: 0 !important;
    }
  }
}

.refresh-link-wrapper {
  display: none;

  &.end-load {
    display: table;
    margin: 0 auto;
    margin-bottom: 25px;
  }

  .refresh-link {
    .font-alt();
    .font-size(1.6);
    border-bottom: 1px solid transparent;

    &:hover {
      cursor: pointer;
      text-decoration: none;
      border-bottom: 1px solid @color-primary-function;
    }

    svg {
      fill: @color-primary-function;
      transform: scaleX(-1);
      width: 18px;
      height: 18px;
      position: relative;
      margin-right: 5px;
      top: 3px;


      @media only screen and (max-width: @screen-xs) {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        top: 2px;
      }

      @media only screen and (max-width: 370px) {
        width: 12px;
        height: 12px;
      }
    }

    &:after {
      display: none;
    }
  }
}
.submit-date-rage{
  min-width: unset;
  padding: 10px 20px 10px 20px;
  margin-left: 10px;  
    @media only screen and (max-width: @screen-md) {
      display: inline-block!important;
      width: 100%!important;
    }   
}