﻿@import "../../Global/Components/informations-spot-a.less";

.information-spot-a {
  background-color: #fff;
  color: @color-primary-dark;
  margin-top: 0;
  margin-bottom: 30px;
  padding: 0;
  padding-bottom: 30px;

  .two-col-spot &, .three-col-spot & {
    margin-bottom: 2px;
  }

  .top {
    .image {
      margin: 0;
      padding: 0;
      float: none !important;

      img {
        width: 100%;
        height: auto;
        margin-bottom: 0!important;
      }
    }

    .primary-text {
      font-weight: 400;
      .font-alt();
      .font-size(2.0)!important;
      .text-link();
      padding-left: 38px;
      padding-right: 38px;
      margin-top: 30px;
      color: @color-primary-dark;

      .two-col-spot &, .three-col-spot & {
        .font-size(2)!important;
      }
    }
  }

  .secondary-text {
    .font-size(1.6);
    .text-link();
    padding-left: 38px;
    padding-right: 38px;
  }

  .links {
    padding-left: 38px;
    padding-right: 38px;
  }

  .btn-right {
    width: 100%;
    margin-bottom: 10px;
    white-space: inherit;

    &:last-of-type {
      margin-bottom: 0;
    }

    .two-col-spot &, .three-col-spot & {
      width: 100%;
      margin-bottom: 10px;
      white-space: inherit;
    }

    @media(min-width:@screen-md) {
      width: auto;
      margin-bottom: inherit;
    }
  }
}
