﻿.search-overlay--wrapper {
  background-color: rgba(0,0,0,0.75);
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 1000000;
  color: @color-text;
  text-align: center;

  .search-overlay--inner {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    left: 50%;
    margin-top: -150px;
    margin-left: -150px;
    max-width: 300px;
    padding: 20px;
    width: 100%;
    position: absolute;
    top: 50%;
    background-color: rgba(255,255,255,1);
  }

  .icon-spin, .ajax-progress .throbber, .search .spinner i[class^="icon-"], .search-field-in-content .spinner i[class^="icon-"] {
    display: inline-block;
    -moz-animation: spin 2s infinite linear;
    -o-animation: spin 2s infinite linear;
    -webkit-animation: spin 2s infinite linear;
    animation: spin 2s infinite linear;
    line-height: 0;
    margin-bottom: 10px;
    font-size: 20px;
  }
}

.cancel {
  a {
    color: @color-brand-200;
  }
}

// Hiding the spinners from EU style
.spinner-img--ft {
  display: none;
}

.spinner-img--gray {
  display: none;
  margin: 0 auto;
}
.spinner-img--red {
  display: none;
  margin: 0 auto;
}
.spinner-img--green {
  display: none;
  margin: 0 auto;
}

// FT spinners
.site-ft {
  .spinner-img--ft {
    display: block;
    margin: 0 auto;
  }

  .spinner-img--euo {
    display: none;
    margin: 0 auto;
  }

  .spinner-img--gray {
    display: none;
    margin: 0 auto;
  }

  .spinner-img--green {
    display: none;
    margin: 0 auto;
  }
   .spinner-img--red {
    display: none;
    margin: 0 auto;
  }
}

// Theme spinners
.green {
  .spinner-img--ft {
    display: none;
    margin: 0 auto;
  }

  .spinner-img--euo {
    display: none;
    margin: 0 auto;
  }

  .spinner-img--gray {
    display: none;
    margin: 0 auto;
  }

  .spinner-img--green {
    display: block;
    margin: 0 auto;
  }
  .spinner-img--red {
    display: none;
    margin: 0 auto;
  }
}

// Theme spinners
.gray {
  .spinner-img--ft {
    display: none;
    margin: 0 auto;
  }

  .spinner-img--euo {
    display: none;
    margin: 0 auto;
  }

  .spinner-img--gray {
    display: block;
    margin: 0 auto;
  }

  .spinner-img--green {
    display: none;
    margin: 0 auto;
  }
  .spinner-img--red {
    display: none;
    margin: 0 auto;
  }
}

// Theme spinners
.red {
  .spinner-img--ft {
    display: none;
    margin: 0 auto;
  }

  .spinner-img--euo {
    display: none;
    margin: 0 auto;
  }

  .spinner-img--gray {
    display: none;
    margin: 0 auto;
  }

  .spinner-img--green {
    display: none;
    margin: 0 auto;
  }
  .spinner-img--red {
    display: block;
    margin: 0 auto;
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    -o-transform: rotate(0);
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
