.leftmenu-toggle-tabs {
  //border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  padding: 0;
  .make-row();
  margin-bottom: 0;

  .leftmenu-toggle {
    display: inline-block;
    //margin: 10px 0;
    cursor: pointer;
    padding: 16px 0 16px 0;
    .font-alt();
    .font-size(1.8);
    .make-xs-column(6);
    padding: 16px 0;
    text-align: center;

    &:before {
      margin-right: 5px;
    }

    &.in {
      color: @link-color;
      border-bottom: 3px solid @color-primary-function-bg;
    }

    &:hover {
      color: @link-color;
    }
  }
}

.leftmenu-toggle-panel {
  display: none;
  width: 100%;

  ul li a {
    //font-size: 1.4rem;
  }

  &.in {
    display: inline-block;
  }
}
