.captcha-modal {
  display: none;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  /* Modal Content/Box */
  &__content {
    position: relative;
    background-color: @sw-color-background;
    background-color: var(--sw-color-background, @sw-color-background);
    padding: @padding-large @padding-small @padding-medium;
    margin: 15% auto;
    border: 1px solid @sw-color-border-light;
    width: 90%;

    @media (min-width: @screen-md-min) {
      margin: 0;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: 600px;
      box-shadow: 0 6px 10px #adadad;
    }
  }

  &__form {
    padding: @padding-small 0 @spacing-small 0;

    &-title {
      display: block;
      margin: 10px 0;
      font-size: @sw-font-md;
    }

    .button {
      margin-top: @spacing-medium;
    }
  }

  /* The Close Button */
  .close-captcha {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 32px;
    height: 32px;
    opacity: 1;
    cursor: pointer;
    border: none;
    background: transparent;

    &:hover {
      opacity: 0.3;
    }

    &:before,
    &:after {
      position: absolute;
      left: 15px;
      top: 6px;
      content: " ";
      height: 20px;
      width: 2px;
      background-color: @sw-color-grey-darker;
    }
    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }

  .alert {
    padding: 10px 0;

    &.alert-error {
      color: @sw-color-red-dark;
    }
  }

  div.BDC_CaptchaDiv a:focus {
    outline: @focus-style !important;
    outline-offset: @focus-offset;
  }
}

.captcha-error-msg {
  display: none;
  font-size: 20px;
}

.fade-out { 
  animation: fadeOut ease 4s forwards;
  -webkit-animation: fadeOut ease 4s forwards;
  -moz-animation: fadeOut ease 4s forwards;
  -o-animation: fadeOut ease 4s forwards;
  -ms-animation: fadeOut ease 4s forwards;
}
@keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-moz-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-webkit-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-o-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}

@-ms-keyframes fadeOut {
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
}