﻿@import "../styles.less";

.cookie-warning {
  background: @color-contrast-300;
  position: fixed;
  z-index: 10000;
  width: 100%;
  padding: 10px;
  bottom: -1px;
  box-shadow: 0 6px 12px 7px rgba(0,0,0,0.21);

  @media(min-width:@screen-sm) {
    right: 1em;
    padding: 20px;
    width: 540px;
  }


  h2 {
    .font-bold();
    .font-size(@type-size-base);
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      display: block;

      @media(min-width:@screen-sm) {
        display: table-cell;
      }

      a {
        @media(max-width:@screen-sm) {
          width: 100%;
        }
      }

      vertical-align: middle;

      .cookies-accept, .cookies-reject {
        margin-right: 0;
        margin-bottom: 10px;

        @media(min-width:@screen-sm) {
          margin-right: 10px;
          margin-bottom: 0;
        }
      }

      .cookie-info {
        .font-regular();
        .font-size(@type-size-md);
        color: @color-brand-300;
        display: inline-block;


        @media only screen and (min-width: @screen-sm) {
          width: 85px;
        }

        &:after {
          &:extend(.less-icon-extend);
          &:extend(.icon-pil-spinkel-hoejre:before);
          display: inline-block;
          font-size: 8px;
          margin-left: 3px;
        }
      }
    }
  }
}

.cookie-warning {
    @media screen and (max-width: @screen-sm) {
        padding: 20px 38px;
    }

    h2 {
        margin-top: 0;
        .font-alt();
    }

    .cookies-accept, .cookies-reject {
        &.btn-right {
            .font-size(1.8);
            padding: 10px 20px;
        }
    }

    ul li a.cookie-info {
        .font-size(1.6);
        color: @link-color;
        width: 100%;

        &:after {
            content: "\e631";
            font-family: "ft-icons";
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            vertical-align: middle;
        }
    }

    .cookie-info__li {
        display: block;
        width: 100%;
        margin-top: 10px;
    }
}
