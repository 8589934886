﻿@import "../../Global/Components/kvikopslag-spot-a.less";

#quick-lookup .input-group {
  float: none !important;
  text-align: center;
  width: 100%;
}

.panel-left-side {
  .panel-body {
    .input-group.pull-right {
      float: none !important;
      text-align: center;
      width: 100%;

      .btn-right {
        width: 80%;
        margin: auto;
      }
    }
  }
}
