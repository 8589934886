﻿@import "../../Global/Components/committeecontactspot.less";

.committeecontactspot {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 0;
  margin-bottom: 30px;
  padding: 0 15px;
  background: transparent;

  .committee-top-panel {
    background: transparent;
    padding-bottom: 10px;

    h2 {
      padding-left: 24px;
      margin: 0;
      font-weight: normal;
      font-family: SceneAlt, serif;
      .font-size(2.5);
      margin-top: 20px;
      color: @color-primary-darker;
    }
  }

  .committee-bottom-panel {
    margin-left: -15px;
    margin-right: -15px;
    background: @color-secondary-lightest;
    padding-bottom: 25px;
    padding-top: 10px;

    .committee-bottom-container {
      margin-left: -15px;
      margin-right: -15px;
    }
  }

  .show-all {
    font-family: SceneAlt, serif;
    padding-right: 24px;
    padding-left: 25px;
    display: inline-table;
    margin-top: 10px;


    @media screen and (min-width: @container-tablet) {
      margin-top: 25px;
      float: right;
    }

    &:after {
      content: "\e631";
    }
  }

  .contacts {
    margin-top: 20px;
    padding-left: 38px;
    display: table;
    width: 100%;

    @media screen and (max-width: @screen-xs) {
      padding-right: 38px;
    }

    li {
      @media screen and (max-width: @screen-xs) {
        width: 100%;
      }
    }
  }

  .additional-contact-info {
    display: table;
    padding-left: 38px;
    color: @color-primary-dark;

    @media screen and (max-width: @screen-xs) {
      padding-right: 38px;
      padding-left: 38px;
    }

    .additional {
      color: @color-primary-dark;
    }
  }

  &__col {
    padding: 0;
  }
}
