﻿@import "../../styles.less";

//
// Breadcrumbs
// --------------------------------------------------
.breadcrumb {
  padding: @breadcrumb-padding-top @breadcrumb-padding-right @breadcrumb-padding-bottom @breadcrumb-padding-left;
  margin-bottom: @line-height-computed;
  list-style: none;
  background-color: @breadcrumb-bg;
  border-radius: @border-radius-base;
  font-size: 13px;

  .site-euo & {
    padding-top: 30px !important;
  }

  @media(max-width:@screen-xs-max) {
    padding: 10px 0 0 0;
  }

  > li {
    display: inline-block;

    + li:before {
      content: "@{breadcrumb-separator}\00a0"; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0 5px;
      color: @breadcrumb-color;
    }
  }

  a,
  > a,
  > .active {
    color: @color-brand-200;
  }
}
