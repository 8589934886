﻿@import "../styles.less";

.wrapper {
  &.footer-container {
    background: @sub-footer-layout-a-background;

    .footer__logo {
      margin-bottom: 15px;
      max-width: 100px;
      img {
        height: 40px;
      }
    }
    .striped-col-brand {
      //.striped-col-brand(@height: auto, @stripeSize: 2px);
    }

    .container {
      position: relative;
    }

    .col-sm-4 {
      &:after {
        content: "";
        width: 1px;
        height: 200px;
        background: #e5e5e5;
        display: block;
        position: absolute;
        left: 0;
        top: 7px;
      }

      &.striped-col-brand:after {
        width: 0;
      }
    }

    .bg-fix {
      &:extend(.middle-row .bg-fix);
      background: @sub-footer-layout-a-bg-fix-background;
      //.striped-col-brand(@height: auto, @stripeSize: 2px);
      background-image: @sub-footer-layout-a-bg-fix-background-image;
    }

    .contact-spot {
      color: @sub-footer-layout-a-text-color;
      padding: 30px 0 0;

      @media (min-width: @screen-md) {
        padding: 30px 20px;
      }

      h4 {
        .font-bold();
      }

      a {
        color: @sub-footer-layout-a-text-color;
      }
    }

    .most-visited-spot {
      color: @sub-footer-layout-a-text-color;
      padding: 30px 0 0;

      @media (min-width: @screen-md) {
        padding: 30px 0 0;
      }

      h4 {
        .font-bold();
      }

      dl {
        .clearfix();
        border-bottom: 1px solid
          lighten(@sub-footer-layout-a-dl-border-color, 15%);

        dt {
          @media (min-width: @screen-md) {
            width: 29%;
            float: left;
          }

          .font-regular();
          .font-size(@type-size-xl*0.889);
          padding: 9px 0;
          border-top: 1px solid
            lighten(@sub-footer-layout-a-dl-border-color, 15%);
        }

        dd {
          padding: 9px 0;

          @media (min-width: @screen-md) {
            width: 70%;
            float: left;
          }

          .font-bold();
          .font-size(@type-size-xl*0.889);
          color: @sub-footer-layout-a-text-color;
          border-top: 1px solid
            lighten(@sub-footer-layout-a-dl-border-color, 15%);

          a {
            .font-bold();
            color: @sub-footer-layout-a-text-color;
          }
        }
      }
    }

    .link-list-spot {
      color: @sub-footer-layout-a-text-color;
      padding: 30px 0;

      h4 {
        .font-bold();
        margin-bottom: 0;
        padding-bottom: 10px;
        border-bottom: 1px solid
          lighten(@sub-footer-layout-a-dl-border-color, 15%);
      }

      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          padding: 10px 0;
          border-bottom: 1px solid
            lighten(@sub-footer-layout-a-dl-border-color, 15%);

          a {
            color: @sub-footer-layout-a-text-color;

            span[class^="icon-"] {
              display: inline-block;

              &:before {
                display: inline-block;
                .font-size(@type-size-xxl);
                padding-right: 10px;

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}
