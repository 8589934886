﻿/*.tab-hidden {
    position: absolute;
    left: -9999px;
    visibility: hidden;
}*/

.tabs-hide {
  //display: none;
  position: absolute;
  left: -9999px;
  visibility: hidden;
}
/* Tabbed sog */
#tabnav {
  //height: 54px;
  padding: 0;
  margin: 10px 0 0 0;

  li {

    &,
    #dokumenter &,
    #statsrevisorerne &,
    #folketinget &,
    #demokrati & {
      display: inline-block;
      list-style-type: none;
      border-bottom: 0;
    }
  }


  a {
    //float: left;
    line-height: 1.2em;
    padding: 12px 10px 0px 10px;
    margin-right: 4px;
    min-height: 36px;
    min-width: 120px;
    text-decoration: none;
    color: @color-contrast-150;

    &.tab1 {
      background: url(/dist/Images/Global/legacy/tab_back.gif) left top repeat-x;
      width: 10%;
    }

    &.tab2 {
      background: url(/dist/Images/Global/legacy/tab_back_people.gif) right top no-repeat;
    }

    &.tab3 {
      background: url(/dist/Images/Global/legacy/tab_back_file.gif) right top no-repeat;
      width: 12%;
    }

    &.tab4 {
      background: url(/dist/Images/Global/legacy/tab_back_documenter.gif) right top no-repeat;
      width: 14%;
    }
  }
}

#tabnav li.active,
#generel #tabnav li.tabs-selected,
#folketinget #tabnav li.tabs-selected,
#dokumenter #tabnav li.active,
#statsrevisorerne #tabnav li.active,
#folketinget #tabnav li.active,
#demokrati #tabnav li.active {
  display: inline;
  list-style-type: none;
  border-bottom: 0;
}


#tabnav li.active a.tab1 {
  border-left: 2px solid #d3d3c7;
  border-top: 2px solid #d3d3c7;
  border-right: 2px solid #d3d3c7;
  background-color: #ebebe7;
  background-image: none;
  font-weight: bold;
  color: #000;
  padding-bottom: 4px;
}

#tabnav li.active a.tab2 {
  border-left: 2px solid #d3d3c7;
  border-top: 2px solid #d3d3c7;
  border-right: 2px solid #d3d3c7;
  background: #ebebe7 url(/dist/Images/Global/legacy/tab_back_people_hover.gif) right top no-repeat;
  font-weight: bold;
  color: #000;
  padding-bottom: 4px;
}

#tabnav li.active a.tab3 {
  border-left: 2px solid #d3d3c7;
  border-top: 2px solid #d3d3c7;
  border-right: 2px solid #d3d3c7;
  background: #ebebe7 url(/dist/Images/Global/legacy/tab_back_file_hover.gif) right top no-repeat;
  font-weight: bold;
  color: #000;
  padding-bottom: 4px;
}

#tabnav li.active a.tab4 {
  border-left: 2px solid #d3d3c7;
  border-top: 2px solid #d3d3c7;
  border-right: 2px solid #d3d3c7;
  background: #ebebe7 url(/dist/Images/Global/legacy/tab_back_documenter_hover.gif) right top no-repeat;
  font-weight: bold;
  color: #000;
  padding-bottom: 4px;
}

.tabContainer {
  background: #fff url(/dist/Images/Global/legacy/tab_border_generel.gif) left top no-repeat;
  //border-bottom: 1px solid #cacac1;
  padding-bottom: 30px;

  .ftWebTvCanalFolketinget & {
    padding: 15px 38px;

    @media (min-width: @screen-lg-min) {
      padding: 30px 38px;
    }
  }
}

.tabContent {
  background: #fff url(/dist/Images/Global/legacy/tab_gradient_generel.gif) right top no-repeat;
  margin-left: 2px;
}

.tabContent h2 {
  padding: 30px 55px 22px 0px;
}
