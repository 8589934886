@import "../styles.less";

[class^="social-"],
[class*=" social-"] {
  display: inline-block;
}

.social-facebook {
  background-position: 0 0;
  width: 22px;
  height: 22px;
}

.social-twitter {
  background-position: 0 22px;
  width: 22px;
  height: 22px;
}
.site-euo,
.site-euretsforbehold,
.site-euundervisning,
.site-euoenglish,
.site-euoenglish {
  .content-action-wrapper {
    display: inline-table;
    float: right;
    padding-top: 30px !important;
  }
  .breadcrumb-wrapper {
    display: inline-table;
  }
}
.content-action-links {
  float: right;
  list-style: none;

  .popup-content {
    display: none;
  }

  .content-action {
    float: left;
    margin-left: 10px;

    @media (max-width: @screen-xs) {
      margin-left: 5px;
    }

    &.add-to-basket {
      .popover {
        min-width: 210px;
      }
    }

    a {
      width: 48px;
      height: 48px;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      @media (max-width: @screen-xs) {
        width: 38px;
        height: 38px;
      }

      svg {
        max-width: 100%;
        stroke: @color-primary-function-bg;

        &.subsite-wide-service-icon {
          display: none;
        }

        * {
          stroke: @color-primary-function-bg !important;
        }
      }

      .cls-1 {
        stroke: @color-primary-function-bg !important;
      }

      &:hover {
        text-decoration: none;

        i {
          color: @text-color;
        }

        svg {
          stroke: @color-primary-function;

          * {
            stroke: @color-primary-function !important;
          }
        }

        .cls-1 {
          stroke: @color-primary-function !important;
        }
      }

      i {
        .font-size(@type-size-xl);
        color: @color-brand-200;
        display: block;
      }
    }

    .overview-print {
      display: none;
    }

    .data-print {
      display: none;
    }

    .popover {
      min-width: 175px;

      .popover-content {
        ul {
          padding: 0;
          list-style: none;

          li {
            border-bottom: 1px solid @color-contrast-200;
            margin-bottom: 5px;

            &:last-child {
              margin-bottom: 0;
            }

            a {
              vertical-align: top;
              width: auto;
              height: auto;

              i {
                margin-right: 10px;
              }

              svg {
                width: 20px;
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
}
