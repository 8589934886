﻿@import "../styles.less";

.udvalgskontaktspot-a {
  background: @color-contrast-400;
  padding: 15px;
  margin-bottom: 20px;

  h2 {
    .font-bold();
    .font-size(@type-size-base);
    color: @color-text;
  }

  .show-all {
    color: @color-brand-200;
    display: block;
    text-align: right;    

    &:after {
      &:extend(.less-icon-extend);
      &:extend(.icon-pil-hoejre:before);
      .font-size(@type-size-xs);
      margin-left: 5px;
      display: inline-block;
    }
  }

  .contacts {
    list-style: none;
    margin-top: 10px;
    color: @color-text;

    @media screen and (max-width: @screen-xs) {
      padding: 10px;
    }

    li {
      margin-bottom: 15px;
      float: left;
      width: 33%;

      @media screen and (max-width: @screen-xs) {
        float: none;
        width: auto;
      }

      @media screen and (max-width: @screen-sm) {
        width: 50%;
      }

      .contacts-entity {
        text-transform: capitalize;
      }

      a {
        color: @color-brand-200;
        display: block;
        .font-bold();

        &:before {
          &:extend(.less-icon-extend);
          &:extend(.icon-pil-hoejre:before);
          .font-size(@type-size-xs);
          margin-right: 2px;
          display: inline-block;
        }

        @media screen and (max-width: @screen-sm) {
          white-space: nowrap;
        }
      }
    }
  }
}