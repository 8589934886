/*------------------------------------*\
		ftWebTvVoD
    Created by kvr on 20-06-2016
\*------------------------------------*/

.ftWebTvVoD {
  .column-title {
    padding-left: 28px;
  }
}
