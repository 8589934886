﻿@import "../../Global/Components/quick-lookup-c.less";

#quick-lookup {

  .header {
    position: relative;

    h2 {
      margin-top: 0;

      .font-alt();
      .font-size(2.5);
      color: @color-primary-dark;
    }
  }

  .quick-lookup-c__help {
    i {
      display: block;
    }
  }

  .quick-lookup__subheader {
    margin: 30px 0;
  }

  .form-group {
    margin-bottom: 20px;

    label {
      .font-alt();
      .font-size(1.6);
      color: @color-primary-dark;
    }

    select,
    input {
      padding: 10px;
      min-height: 40px;

      .font-regular;
      .font-size(1.4);
      color: @color-secondary-darker;

      .placeholder(@color-secondary-dark);
    }
  }

  .quick-lookup-c__c2a {
    margin: auto;
  }
}