@import "../../Global/Components/sekundaer-servicemenu-a.less";

.content-action-links {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 50px;

  @media (max-width: @screen-xs) {
    padding-left: 0;
    padding-right: 0;
  }

  .content-action {
    position: relative;

    a {
      i {
        font-size: 48px;
        margin-right: 0;

        @media (max-width: @screen-xs) {
          font-size: 38px;
        }

        &:before {
          background: white;
          border-radius: 23px;
        }

        &.icon-send,
        &.icon-mail {
          &:before {
            &:extend(.icon-ft_circle-mail:before);
            color: @color-primary-function-bg;
          }

          &:hover {
            &:before {
              color: @color-primary-function-hover;
            }
          }
        }

        &.icon-print {
          &:before {
            &:extend(.icon-ft_circle-print:before);
            color: @color-primary-function-bg;
          }

          &:hover {
            &:before {
              color: @color-primary-function-hover;
            }
          }
        }

        &.icon-del {
          &:before {
            &:extend(.icon-ft_circle-del:before);
            color: @color-primary-function-bg;
          }

          &:hover {
            &:before {
              color: @color-primary-function-hover;
            }
          }
        }

        &.icon-basket {
          &:before {
            &:extend(.icon-ft_circle-basket:before);
            color: @color-primary-function-bg;
          }

          &:hover {
            &:before {
              color: @color-primary-function-hover;
            }
          }
        }

        &.icon-ft_circle-rss {
          &:before {
            &:extend(.icon-ft_circle-rss:before);
            color: @color-primary-function-bg;
          }

          &:hover {
            &:before {
              color: @color-primary-function-hover;
            }
          }
        }

        &.icon-ft_circle-download {
          &:before {
            &:extend(.icon-ft_circle-download:before);
            color: @color-primary-function-bg;
          }

          &:hover {
            &:before {
              color: @color-primary-function-hover;
            }
          }
        }
      }
    }

    .popover {
      left: -120px !important;
      background-color: #fff;
      border-radius: 0;
      border: 0;
      padding: 9px 14px;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);

      .popover-title {
        display: none;
      }

      &.bottom {
        > .arrow {
          left: 144px !important;
          border-bottom-color: #fff;

          &:after {
            border-bottom-color: #fff;
          }
        }
      }

      .popover-content {
        padding: 0;

        i {
          display: inline-block;
          font-size: 15px;
        }

        ul li {
          border-bottom: 0;

          a:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .document-count {
      position: absolute;
      top: -5px;
      right: -5px;
      width: 22px;
      height: 22px;
      background: white;
      color: @color-primary-dark;
      box-shadow: 0 3px 12px 2px rgba(0, 0, 0, 0.21);
      border-radius: 16px;
      display: inline-block;
      text-align: center;
      padding-top: 2px;
      padding-right: 1px;
      font-size: 12px;
    }
  }
}
