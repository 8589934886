﻿@import "../../Global/Components/informations-spot-e.less";

.informations-spot-e { 
  // max-height: none !important;
  // overflow: visible !important;
  // visibility: visible !important;
  // margin-bottom: 30px;
  .pageedit & {
    max-height: none !important;
    overflow: visible !important;
    visibility: visible !important;
  }

  &__navigation__steps, &__navigation__prev a, &__navigation__next a {
    color: @color-secondary-darker;
  }

  .js-sync-height & {
    @media screen and (max-width: 767px) {
      min-height: inherit !important;
    }
  }

  &.has-bottom {
    margin-bottom: 50px;
  }

  @media only screen and (max-width: @screen-xs) {
    margin-bottom: 30px;

    &.has-carousel {
      margin-bottom: 50px;
    }
  }

  &.no-bottom.has-carousel {
    margin-bottom: 30px;
  }

  &.no-bottom.no-carousel {
    margin-bottom: 0;
  }

  &__header {
    &__title {
      margin: 0;
      float: none;
      margin-bottom: 7px;

      @media (min-width: 769px) {
        float: left;
        width: auto;
        margin-bottom: 0;
      }
    }

    &__link {
      font-family: SceneAlt, serif;
      float: none;
      text-align: left;
      padding-left: 0;
      margin-bottom: 10px;

      @media (min-width: 769px) {
        margin-bottom: 0;
        padding-top: 11px;
        float: right;
        width: auto;
      }
    }
  }

  &__images__image-group__headline {
    width: auto;
  }
}