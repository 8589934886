﻿@import "../../Global/Components/telbogTable.less";

.telbogTable.members-groups {
  color: @color-primary-dark;
  border-collapse: separate;
}

//Fixes issue where member images ended up different sized on iphone5
.ftMembersOnLeave,
.ftMembersEnterForMember {
  .telbogTable {

    thead tr th {
      @media(max-width:@screen-xs) {
        padding: 10px;
      }
    }

    tbody tr {

      td {
        vertical-align: top;

        @media(max-width:@screen-xs) {
          padding: 10px;
        }

        .cellPic {
          height: 100px;

          img {
            max-height: 100px;
          }
        }
      }
    }

  }
}
