//@import "../../Global/Components/videolist-spot.less";
.video-list-spot-header {
  .clearfix();
  padding: 0 38px;
  margin-bottom: 10px;

  .video-list-spot-row & {
    margin-top: 20px;
  }

  .video-list-spot-header-title {
    float: left;
    .font-alt();
    .font-size(2.5);
    margin: 0;

    @media (max-width: @screen-sm) {
      float: none;
    }
  }

  .video-list-spot-header-link {
    float: right;
    //padding-right: 25px;
    @media (max-width: @screen-sm) {
      float: none;
      display: inline-block;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    a {
      .font-alt();
      color: @color-brand-200;
      display: block;

      &:after {
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        .font-size(@type-size-xs);
        margin-left: 5px;
        display: inline-block;
      }
    }
  }
}

.video-list-spot {
  background-color: #ffffff;
  padding: 0;
  margin-bottom: 30px;



  .video-list-spot-body {
    .clearfix();

    .video-list-player {
      float: left;     
      width: 60%;
      position: relative;     

      @media screen and (max-width: @screen-xs) {
        float: none;
      }

      object, embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .video-list-listcontainer {
      float: left;
      width: 403px;
      width: 40%;

      @media screen and (max-width: @screen-xs) {
        width: 100%;
        float: none;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        .font-size(@type-size-lg);
        border-bottom: 1px solid @color-contrast-300;

        li {
          cursor: pointer;
          position: relative;
          padding: 8px 35px;
          border-top: 1px solid @color-contrast-300;
          min-height: 48px;

          .date {
            .font-regular();
          }

          .title {
            max-width: 250px;
            color: @color-brand-200;
          }

          &.active,
          &:hover {
            background-color: @color-menu-selected-bg;

            &:before {
              position: absolute;
              left: 10px;
              top: 50%;
              margin-top: -7px;
              &:extend(.less-icon-extend);
              &:extend(.icon-ft_bullit_left:before);
              color: @color-text;
              font-size: 14px;
            }

            .date, .title {
              color: @color-text;
            }
          }

          &:hover {
            .title {
              color: @color-brand-200;
            }
          }

          &.active {
            background-color: @color-secondary-light-2;
          }
        }
      }
    }
  }
}

.content-area > .video-list-spot-row.row {
  margin-left: -15px;
  margin-right: -15px;
}
