.booking {
  &__categories {
    &__action {
      margin: 0 auto 35px;
    }
  }

  &__category {
    margin: 0 0 10px;
    background-color: white;

    + .booking__categories {
      margin-top: 70px;
    }

    &__image {
      height: 130px;
      padding: 0;
      overflow-y: hidden;

      img {
        min-width: 100%;
        height: auto;
        overflow: hidden;
      }
    }

    &__description {
      padding: 40px;
    }

    &__title {
      font-family: "SceneAlt", serif;
      font-size: 22px;
      color: @link-secondary-color;

      .content-area & {
        margin: 0 0 13px;
      }
    }

    &__teaser {
      font-family: "SceneAlt", serif;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &__box {
    background-color: white;
    margin-bottom: 10px;
    padding: 40px;
  }
}

@media only screen and (min-width: 768px) {
  .booking {
    &__category {
      height: 171px;

      &__image {
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;

        img {
          max-height: 171px;
          overflow: hidden;
        }
      }
    }
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

  .booking-ft .filter-adjacent + .filter-adjacent {
    border-top: 40px @color-secondary-light solid !important;
  }

  .form-group-date-inline {
    width: 100%;
  }

  .btn-nav-right {
    margin-right: 0;
  }

  .form-group-date > input {
    width: 90%;
  }
}

.form-group-inline {
  input {
    background-color: @datepicker-in-fieldset-background-color;
  }

  .site-ft & {
    input {
      border: 0;
    }
  }
}

.site-ft .filter {
  border-top: 0;

  .filter-title, .filter-tags {
    padding: 0 20px;
  }
}

.fieldset {
  background-color: #fff;
  padding: 40px;

  .site-ft &, .site-thedanishparliamentdk & {
    padding: 0;

    .filter {
      padding: 20px 40px;
      border: 0;

      @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
        padding: 20px;
      }

      .datepicker {
        border: 0;
        background-color: #fff;

        &::placeholder {
          color: @color-secondary-darker;
        }
      }
    }

    .form-control {
      background-color: #fff;
    }
  }
 

  .form-control {
    + label {
      margin-left: 10px;
    }

    &--dark {
      background-color: #f0f0f0;
    }

    &--small {
      width: 60px;
      text-align: center;
    }
  }
}

.booking-ft {
  fieldset {
    font-weight: 400;
  }

  legend {
    .font-size(1.8);
    border-color: @color-tablecell-divider;
    font-weight: 400;
    padding-bottom: 5px;
  }
}

.heading {
  &--sub {
    font-family: "SceneAlt", serif;
    font-size: 30px;
    color: #252525;
    padding: 0 38px;
  }

  &--sub-small {
    .font-size(2);
  }

  &--info {
    background-color: #fff;
    padding: 23px;

    fieldset {
      font-weight: 400;

      legend {
        .font-size(1.8);
        border-color: @color-tablecell-divider;
        font-weight: 400;
        padding-bottom: 5px;
      }

      td {
        padding-bottom: 5px;
      }
    }
  }
}

.wizard-steps {
  li {
    span {
      .booking-ft & {
        line-height: 70px;
        font-size: 18px;
      }

      @media (max-width: @screen-sm) {
        line-height: 1.3;
      }
    }

    &.active span {
      color: @color-primary-dark;
    }

    @media (min-width: @screen-sm) and (max-width: @container-small-desktop) {
      padding: 0;
      text-align: center;
    }
  }
}

.bHeader {
  margin-left: 10px;
  padding-bottom: 0px !important;
  margin-bottom: 10px !important;
  line-height: 2em !important;
}

#folketinget .blogin_top {
  background: url("/dist/Images/Global/legacy/booking/booking_login_top_gradient.png") repeat-x left bottom;
  margin: 13px 0 14px;
  border-top: 1px solid #E8EAEA;
  border-bottom: 1px solid #E8EAEA;
  padding: 5px 12px;
  color: #5a5935;
  font-size: 12px;
  text-align: right;
}

#dokumenter .blogin_top {
  background: url("/dist/Images/Global/legacy/booking/booking_steps_filter_type_en.png") repeat-x left bottom;
  margin: 13px 0 14px;
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  padding: 5px 12px;
  color: #000000;
  font-size: 12px;
  text-align: right;
}

.blogin_top a {
  background-image: url("../../images/arrow.gif");
  background-position: 0 4px;
  background-repeat: no-repeat;
  padding-left: 10px;
}

.blogin_top a:hover {
  text-decoration: underline !important;
}

#folketinget .blogin_top a {
  text-decoration: none;
  color: #5A5935;
}

#dokumenter .blogin_top a {
  text-decoration: none;
  color: #5A5935;
}

#folketinget .btop_button {
  background: url("/dist/Images/Global/legacy/booking/btop_button.png") no-repeat 0 0;
  border: 1px solid #e2e2b9;
  font-size: 12px;
  color: #5a5935;
  width: 80px;
  cursor: pointer;
  padding-right: 20px;
  height: 24px;
  vertical-align: middle;
}

#dokumenter .btop_button {
  background: url("/dist/Images/Global/legacy/booking/btop_button.png") no-repeat 0 0;
  border: 1px solid #cccccc;
  font-size: 12px;
  color: #5a5a5a;
  width: 80px;
  cursor: pointer;
  padding-right: 20px;
  height: 24px;
  vertical-align: middle;
}

.btop_input, .b_input {
  border-top: 1px solid #bdbdb1;
  border-right: 1px solid #d2d4c7;
  border-bottom: 1px solid #d2d4c7;
  border-left: 1px solid #bdbdb1;
  font-size: 12px;
  color: #464646;
  padding: 2px;
  vertical-align: middle;
  margin-right: 15px;
}

.bt_error {
  margin: 5px 0 5px 0;
}

.bt_error .red {
  color: #b5014d;
}

.btop_input_error {
  border: 1px solid #b5014d;
}

.booking_col {
  float: left;
  margin-left: 1.08%;
  width: 25.35%;
}

.booking_front_box {
  border: 2px solid #e2e2b9;
  border-bottom-color: #afad00;
}

.booking_box_content {
  color: #5a5935;
  padding: 10px;
  border-top: 1px solid #E8EAEA;
  background: url("/dist/Images/Global/legacy/booking/booking_box_content.png") repeat-x left bottom;
  min-height: 90px;
}

.booking_front_box_extra {
  margin-top: 10px;
}

.booking_front_box_extra .booking_box_content {
  border-top: none;
}

.booking_box_content h3 {
  font-weight: bold;
  font-size: 12px;
  color: #5a5935;
  padding-bottom: 4px;
}

.booking_box_content p {
  padding-bottom: 7px;
}

.booking_box_content a {
  background: url("/images/arrow.gif") no-repeat 0 4px;
  display: block;
  padding-left: 10px;
  text-decoration: none;
  text-indent: inherit;
  color: #5A5935;
}

.booking_box_content a:hover {
  text-decoration: underline;
}

a.b_download_menu {
  background: url("/dist/Images/Global/legacy/booking/b_download_menu.png") no-repeat 0 1px;
  padding-left: 15px;
  color: #5A5935;
  text-decoration: none;
}

a.b_download_menu:hover {
  color: #5A5935;
  text-decoration: underline;
}

.booking_box_content_left {
  float: left;
  width: 30%;
  overflow: hidden;
  margin-right: 3%;
}

.booking_box_content_right {
  float: left;
  width: 65%;
}

.booking_front_box_header_btn {
  background: url("/dist/Images/Global/legacy/booking/booking_front_box_header_btn.png") no-repeat right top;
  display: block;
  height: 107px;
  width: 100%;
  color: #7b7b32;
  font-size: 27px;
  font-family: 'SceneStdRegular';
  text-decoration: none;
  border-bottom: 1px solid #e2e2b9;
}

.booking_front_box_header_btn:hover {
  background: url("/dist/Images/Global/legacy/booking/booking_front_box_header_btn_hover.png") no-repeat right top;
  color: #fff;
}

.booking_front_box_header_btn span {
  vertical-align: middle;
  text-align: left;
  line-height: 1.1em;
  padding-right: 80px;
  padding-left: 15px;
}

.booking_front_box_header_btn {
  display: table;
  overflow: hidden;
  *position: relative;
}

.booking_front_box_header_btn span {
  display: table-cell;
  vertical-align: middle;
  *position: absolute;
  *top: 50%;
}

.booking_front_box_header_btn span em {
  display: block;
  font-style: normal;
  *position: relative;
  *top: -50%;
}

.booking_front_box_header_descr {
  background: #f5f5e0 url("/dist/Images/Global/legacy/booking/booking_front_box_header_descr.png") repeat-x left bottom;
  border-top: 1px solid #fff;
  padding: 10px 15px;
  font-size: 12px;
  color: #62621b;
  min-height: 80px;
}

.manchet a.back {
  background: url("/dist/Images/Global/legacy/booking/icon_back.png") no-repeat 0 3px;
  padding-left: 10px;
  color: Green;
}

#folketinget .booking_steps_btn {
  border-top: 1px solid #E8EAEA;
  border-bottom: 1px solid #E8EAEA;
  padding: 14px 0 15px 0px;
}

.tabs-nav {
  overflow: hidden !important;
  display: block !important;
  width: 660px !important;
}

.booking_steps_btn li, .booking_steps_btn li span {
  color: #5a5935;
  font-size: 14px;
  background: none !important;
  overflow: hidden !important;
}

.booking_steps_btn li {
  display: inline-block !important;
  width: 155px;
  overflow: hidden !important;
  margin: 0 0 0 7px;
}

.booking_steps_btn li.disabled span {
  
}

.booking_steps_btn li.disabled a:hover {
  text-decoration: none;
}

#folketinget .booking_substep_section {
  margin-bottom: 10px;
  min-height: 90px;
  color: #5a5935;
  position: relative;
}

.booking_steps_filter_date_cnt label,
.booking_steps_filter_periode label {
  font-weight: bold;
  margin-bottom: 10px;
}

.booking_steps_filter_type {
  padding: 40px;
  float: left;
  width: 40%;
  color: #5a5935;
  background-color: #fff;
  height: 171px;
}

.booking_steps_filter_periode .booking_steps_filter_type_line {
  clear: both;
  margin-bottom: 10px;
}

.rightBox.sp.b_bsk_box {
  display: none;
}


.booking_steps_filter_date {
  float: left;
  width: 50%;
  color: #5a5935;
  background-color: #fff;
  padding-right: 15px;
}

.booking_steps_filter_periode {
  padding-left: 15px;
  float: left;
  width: 50%;
  color: #5a5935;
  background-color: #fff;
}

.booking_steps_filter_type h2, .booking_steps_filter_date h2, .booking_steps_filter_periode h2 {
  background: none !important;
}

#folketinget .booking_steps_filter_type_line {
  border-top: 1px solid #E8EAEA;
  padding: 5px 0;
  clear: both;
  margin-bottom: 5px;
}

#folketinget .booking_steps_filter_date h2, .booking_steps_filter_periode h2 {
  border-bottom: 1px solid #E8EAEA;
}

.booking_steps_filter_periode .booking_steps_filter_type_line {
  border: none;
}

.booking_steps_nr_pers {
  margin-bottom: 40px !important;
}

.booking_steps_nr_pers label {
  font-size: 16px;
}

.booking_steps_nr_pers .b_input {
  width: 60px;
  border: 0;
  background-color: @color-contrast-400 !important;
  .font-size(1.6);
}

.booking_steps_btn--small {
  margin: 30px 0;
  clear: both;

  button.button {
    min-width: 0;
    padding: 10px 25px;
    .font-size(1.8);
  }
}

.booking_steps_btn--next {
  background-color: #fff;
  text-align: right;
  clear: both;
  margin-top: 30px;

  &.no-margin {
    margin-top: 0;
  }
}

.booking_steps_bottom_btn.skip_btn {
  margin-top: 0;
}

.booking_steps_bottom_btn.skip_btn div {
  font-size: 14px;
  color: @color-text;
  line-height: 41px;
}

.booking_steps_bottom_btn .alignleft {
  float: left;
  padding-left: 38px;
}

.booking_steps_school_type {
  margin-bottom: 40px;
}

.booking_steps_school_type select {
  padding: 6px 12px;
  background-color: @color-contrast-400;
  border: none;
  color: #464646;
}


#folketinget .rightBox.b_bsk_box {
  background: #fafaf0 url("/dist/Images/Global/legacy/booking/b_bsk_box.png") no-repeat right top;
  margin-bottom: 10px;
}

.b_bsk_box .b_bsk_line {
  padding-bottom: 5px;
}

.b_bsk_box_check {
  border-top: 1px solid #E8EAEA;
}

.b_bsk_box_check_item_remove {
  display: block;
  background: url("/dist/Images/Global/legacy/booking/b_bsk_box_check_item_remove.png");
  width: 14px;
  height: 14px;
  text-indent: -2000px;
  float: right;
}

.b_bsk_box_check_item {
  padding: 3px 0;
  clear: both;
}

.rightBox.blogin {
  margin-bottom: 10px;
}

.rightBox.blogin label {
  display: block;
  padding-bottom: 5px;
}

.rightBox.blogin .btop_input {
  width: 175px;
  margin-bottom: 5px;
}

.rightBox.blogin .button {
  margin-top: 5px;
}

.rightBox.blogin .bf_psw {
  float: right;
  font-size: 11px;
  margin-right: 35px;
  color: #807f01;
  margin-top: 5px;
}

.booking_step_descr_cnt {
  float: left;
  width: 60%;
  margin-left: 2%;
  padding: 15px 0;
}

.booking_step_descr_cnt.booking_type0,
.booking_step_descr_cnt.booking_type1,
.booking_step_descr_cnt.booking_type2,
.booking_step_descr_cnt.booking_type3,
.booking_step_descr_cnt.booking_type4,
.booking_step_descr_cnt.booking_type5,
.booking_step_descr_cnt.booking_type6,
.booking_step_descr_cnt.booking_type7 {
  display: none;
}

.booking_step_descr_cnt.booking_type1 {
  display: block;
}

.booking_step_descr_cnt h2 {
  background: none !important;
  padding: 10px 0 10px 0;
}

.booking_step_descr_cnt h3 {
  font-size: 14px;
  font-weight: bold;
}

.booking_step_descr_cnt_left {
  float: left;
  margin-right: 2%;
  width: 20%;
}

.booking_step_descr_cnt_right {
  float: left;
  width: 77%;
}

.booking_step_results {
  color: #5a5935;
}

.booking_step_descr_cnt_right h2 {
  font-size: 18px;
  font-weight: bold;
  color: #5a5935;
  background: none !important;
  padding: 0 0 10px 0;
}

.booking_step_descr_cnt_right p {
  padding-bottom: 10px;
}

.booking_step_descr_cnt_right p strong {
  font-weight: bold;
}

.booking_substep_section a {
  text-decoration: none;
  color: @color-primary-link;
}

.booking_substep_section a:hover {
  text-decoration: underline;
}

.booking_remarks {
  border: 1px solid @color-primary-function-bg;
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px;
}

.booking_substep_section .read_more {
  background: url("/images/arrow.gif") no-repeat scroll 0 4px transparent;
  color: #5A5935;
  display: block;
  padding-left: 10px;
  margin-top: 10px;
  text-decoration: none;
}

#folketinget .booking_time_day {
  .clearfix();
  font-size: 14px;
  line-height: 50px;
  margin-bottom: 20px;

  &.digits {
    font-family: SceneAlt, serif;
    .font-size(1.6);
    line-height: 24px;
    margin-bottom: 5px;
  }
}

.booking_time_slide {
  background: transparent !important;
}

.booking_time_slide table {
  width: 100%;
  border: none;
  background: transparent !important;
  position: relative;
  top: -2px;
}

.booking_step_time {
  position: relative;
  width: 100%;
  background-color: #fff;
}

#folketinget .booking_time_slides {
  position: absolute;
  top: 0;
  left: 150px;
  height: 100%;
  width: 80%;
}

#dokumenter .booking_time_slides {
  position: absolute;
  top: 0;
  left: 180px;
  height: 365px;
  width: 70%;
}

.booking_time_slide {
  width: 100%;
}

.booking_time_slide table {
  border-collapse: collapse;
}

#folketinget .booking_time_slide table th {
  border-left: 1px solid @color-tablecell-divider;
  padding: 0 7px 0;
  height: 37px;
  width: 20% !important;
  font-weight: 400;
  text-align: left;
  .font-size(1.4);
}

.booking_time_slide table td {
  border-left: 1px solid @color-tablecell-divider;
  padding: 0 7px;
  height: 50px;
  position: relative;
}

#folketinget .booking_time_header {
  background: #fff;
  height: 35px;
  border-bottom: 1px solid @color-tablecell-divider;
}


#folketinget .btime_box {
  position: relative;
  width: 46%;
  border: 1px solid @color-menu-selected-tab;
  margin-bottom: 10px;
  margin-right: 10px;
  height: inherit; // FIX: IE11 can't apply pos: absolute stretch when applied to a table cell (this inherits height from parent)
  float: left;
  .font-size(1.3);

  @media (min-width: @screen-sm) {
    width: 100px;    
  }

  a {
    padding: 7px;
    display: inline-block;

    &:hover {
      &:before {
        content: '';
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 31px 40px 0;
        border-color: transparent @color-contrast-300 transparent transparent;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }

    &:before {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 31px 40px 0;
      border-color: transparent @color-contrast-400 transparent transparent;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

#folketinget .btime_box .inner {
  color: @color-text;
}

.inner .center {
  line-height: 29px;
}

#folketinget .btime_box a, .btime_box a:hover {
  color: @color-text;
  .font-size(1.3);
  display: block;
  text-decoration: none;
  outline: none;
}

#folketinget .btime_box_disable {
  &:before {
    border-color: transparent transparent @color-tertiary-result-against transparent;
  }
}

#folketinget .btime_box_unavailable {
  &:before {
    border-color: transparent transparent @color-tertiary-result-against transparent;
  }
}

#folketinget .btime_box_unavailable .inner {
  color: @color-text;
}

#folketinget .btime_box_available.selected {
  a {
    &:before {
      content: '';
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 31px 40px 0;
      border-color: transparent @color-tertiary-result-for transparent transparent;
      display: inline-block;
      vertical-align: middle;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

#folketinget .btime_box_popup {
  display: none;
  width: 200px;
  min-height: 175px;
  height: auto;
  padding: 10px 25px 10px 25px;
  position: absolute;
  z-index: 10000;
  top: -130px;
  left: -80px;
  background: white;
  border-radius: 0;
  border: none;
  box-shadow: 4px 3px 7px fade(black, 30%);
  font-weight: normal;
  color: @color-primary-dark;
}

#folketinget .btime_box_popup strong {
  font-weight: bold;
}

#folketinget .btime_box_popup_arrow {
  //Overwrite styles from global
  background: none;
  bottom: auto;
  height: auto;
  width: auto;
  //Styles the arrow
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: white transparent transparent transparent;
}


#folketinget .booking_time_slides_nav .brt_prev {
  width: 20px;
  height: 27px;
  display: block;
  position: absolute;
  top: 6px;
  left: 120px;
  z-index: 1000000;

  span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  &:before {
    &:extend(.less-icon-extend);
    &:extend(.icon-ft_bullit_left:before);
    display: inline-block;
    color: @color-primary-function;
  }
}

#dokumenter .booking_time_slides_nav .brt_prev {
  width: 20px;
  height: 27px;
  display: block;
  position: absolute;
  top: 6px;
  left: 150px;
  z-index: 1000000;

  span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  &:before {
    &:extend(.less-icon-extend);
    &:extend(.icon-ft_bullit_left:before);
  }
}

.booking_time_slides_nav .brt_next {
  width: 20px;
  height: 27px;
  display: block;
  position: absolute;
  top: 6px;
  right: 20px;
  z-index: 1000000;

  span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  &:before {
    &:extend(.less-icon-extend);
    &:extend(.icon-ft_bullit_right:before);
    display: inline-block;
    color: @color-primary-function;
  }
}

.booking_time_slides_nav .brt_prev.disabled {
  opacity: 0.5;
  cursor: default;
}

.booking_time_slides_nav .brt_next.disabled {
  opacity: 0.5;
  cursor: default;
}

.btime_box_popup {
  position: absolute;
  z-index: 10000;
  top: -130px;
  left: -80px;
  background: #fff url("/dist/Images/Global/legacy/booking/btime_box_popup.png") repeat-x left bottom;
  border: 1px solid #E8EAEA;
  padding: 10px;
  width: 200px;
  height: 110px;
  color: #5a5935;
  font-weight: normal;
  -moz-box-shadow: 4px 3px 7px #505050;
  -webkit-box-shadow: 4px 3px 7px #505050;
  box-shadow: 4px 3px 7px #505050;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: none;
}

.btime_box_popup strong {
  font-weight: bold;
}

.btime_box_popup_arrow {
  background: url("/dist/Images/Global/legacy/booking/btime_box_popup_arrow.png") no-repeat scroll 0 0 transparent;
  bottom: -14px;
  height: 14px;
  left: 45%;
  position: absolute;
  width: 23px;
}

#folketinget .booking_substep_title {
  font-size: 19px !important;
  font-weight: normal !important;
  color: #5c5936 !important;
  padding: 15px !important;
  border-bottom: 1px solid #E8EAEA !important;
  clear: both;
  margin-top: 22px !important;
  background: transparent !important;
}

.booking_substep_title.nobboorder {
  border-bottom: none !important;
  padding: 15px 38px;
}

#calendarResult {
  .error {
    font-size: 1.4rem;
    font-family: Arial, sans-serif;
    color: @link-color;
    margin-left: -40px !important;
    margin-top: -30px;
  }
}

.booking_steps_reviewform {
  padding: 15px;

  #numberOfParticipants {
    height: 26px;
  }

  .error {
    font-size: 1.4rem;
    font-family: Arial, sans-serif;
    margin-left: 0;
    color: @link-color;
  }

  .custom-radio {
    margin-right: 10px;
  }

  legend {
    display: none;
  }
}

.booking_steps_reviewform legend, span legend {
  font-size: 14px;
  font-weight: bold;
}

.booking_steps_reviewform fieldset {
  padding-bottom: 15px;
}

.booking_steps_reviewform hr {
  border: none;
  border-bottom: 1px solid #E8EAEA;
  margin: 5px 0 15px;
}

.booking_steps_reviewform label {
  display: block;
  padding-bottom: 3px;
  padding-top: 10px;
}

.booking_steps_reviewform .validation_text {
  color: #b5014d;
  font-size: 11px;
  font-weight: bold;
  padding-bottom: 2px;
}

.booking_steps_reviewform .required_red, .error {
  color: #b5014d;
  margin-left: 38px;
  margin-top: 5px;
  font-weight: 400;

  .chooseDate & {
    margin-left: 0;
    font-weight: 400;
  }
}

#calendarContainer {
  .heading--sub {
    .booking_steps_reviewform {
      padding: 10px 0;
    }

    .error {
      display: inline-block;
      font-weight: 400;
      .font-size(1.6);
      margin-left: 0;
    }
  }

  #errorSelectDate {
    margin-left: 0 !important;
    margin-top: 15px;
    display: block;
  }
}

.b_large_input, .b_medium_input, .b_small_input {
  border-top: 1px solid #bdbdb1;
  border-right: 1px solid #d2d4c7;
  border-bottom: 1px solid #d2d4c7;
  border-left: 1px solid #bdbdb1;
  font-size: 12px;
  color: #464646;
  padding: 2px;
  vertical-align: middle;
  margin-right: 5px;
  font-size: 12px;
}

.b_large_input {
  width: 310px;
}

.b_medium_input {
  width: 165px;
}

.b_small_input {
  width: 65px;
}

.booking_steps_reviewform textarea {
  height: 110px;
  width: 100%;
  resize: none;
  overflow: auto;
  font-size: 12px;
  font-family: inherit;
}

.booking_substep_title_review {
  font-size: 14px !important;
  font-weight: normal !important;
  color: #5c5936 !important;
  padding: 10px 15px !important;
  border-bottom: 1px solid #E8EAEA !important;
  clear: both;
  margin-top: 10px !important;
  background: transparent !important;
}

.booking_substep_section {
  padding-left: 23px;
}

.booking_substep_section_review {
  padding: 15px;
  color: #5a5935;
  border-bottom: 1px solid #d0cf94;
}

.booking_substep_section_review strong {
  font-weight: bold;
}

.booking_substep_section_review.nobboorder {
  border-bottom: none;
  padding-left: 38px;
}

.booking_substep_section_review p {
  padding-bottom: 10px;
}

.booking_substep_section_review_rest {
  margin-top: 25px;
}

.booking_substep_section_review_rest_descr strong {
  font-weight: bold;
}

.booking_substep_section_review_rest_title {
  float: left;
  width: 25%;
  margin-right: 2%;
}

.booking_substep_section_review_rest_descr {
  width: 100%;

  @media only screen and (min-width: 768px) {
    float: left;
    width: 72%;
  }
}

.booking_substep_section_review_rest_descr_cnt_left {
  float: left;
  margin-right: 1%;
  overflow: hidden;
  width: 19%;
}

.booking_substep_section_review_rest_descr_cnt_right {
  float: left;
  width: 77%;
}

.booking_substep_section_review_rest_descr_cnt_right p {
  padding-bottom: 10px;
}

#folketinget .button.buttonback {
  background: url("/dist/Images/Global/legacy/booking/btn_back_green_end.png") no-repeat scroll right 1px transparent;
  padding-right: 4px;
}

#folketinget .button.buttonback span {
  background: url("/dist/Images/Global/legacy/booking/btn_back_green.png") no-repeat scroll 0 0 transparent;
  padding-left: 20px;
}

#dokumenter .button.buttonback {
  background: url("/dist/Images/Global/legacy/booking/btn_back_red_end.png") no-repeat scroll right 1px;
  padding-right: 4px;
}

#dokumenter .button.buttonback span {
  background: url("/dist/Images/Global/legacy/booking/btn_back_red.png") no-repeat scroll 0 0;
  padding-left: 20px !important;
}

.ie8 .buttonback {
  margin-top: -1px;
}

.ie .buttonback {
  padding: 0 15px 0 0;
}

.booking-ft .button {
  color: #ffffff;
  display: inline-block;
  border: 0;
  border-radius: 0;
  min-width: 165px;
  padding: 10px 50px 10px 50px;
  font-family: SceneAlt, serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2.16rem;
}

#changeTab2 {
  width: 100%;
  margin-bottom: 5px;

  @media (min-width: @screen-sm) {
    width: auto;
  }
}

#backtoone, #backtotwo, .buttonback {
  width: 100%;
  margin-bottom: 5px;

  @media (min-width: @screen-sm) {
    width: auto;
  }
}

#changetoTab3, .buttonnext {
  width: 100%;
  margin-bottom: 5px;

  @media (min-width: @screen-sm) {
    width: auto;
    float: right;
  }
}

.print-btn, .mypage-btn, .logout-btn {
  width: 100%;
  margin-bottom: 5px;

  @media (min-width: @screen-sm) {
    width: auto;
  }
}


.buttonback span {
  display: block;
  white-space: nowrap;
  height: 22px;
  font-size: 12px;
  font-family: Arial;
  padding: 1px 0px 0 15px;
  line-height: 1.3em !important;
}

.ie8 .buttonback span {
  margin-top: 1px;
}

.buttonback :hover span {
  text-decoration: underline !important;
}

.buttonback:hover span {
  text-decoration: underline !important;
}

.booking_substep_section h3 {
  font-size: 16px;
  font-weight: normal;
  padding-bottom: 15px;
}

.booking_substep_section h4 {
  font-size: 14px;
  font-weight: bold;
}

.brow .booking_substep_section_review_rest_descr {
  padding-bottom: 20px;
}

.brow .menu_chk {
  float: left;
  vertical-align: middle;
  margin: 0 10px 15px 30px;
}

.brow .label_menu_chk {
  vertical-align: middle;
  font-size: 16px;
  float: left;
  margin: 0 0 15px 0;
  padding: 0;
}

.brow label {
  font-weight: 400;
}

.overlay_popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
  display: none;
}

.overlay_popup .overlay_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.75;
  filter: alpha(opacity=75);
}

.overlay_cnt {
  width: 100px;
  height: 100px;
  margin-left: -50px;
  margin-top: -50px;
  left: 50%;
  top: 50%;
  position: fixed;
  z-index: 20000;
  text-align: center;
}

.visit_type {
  display: none;
}

#dokumenter .booking_steps_bottom_btn {
  border-top: 1px solid #cacaca !important;
  border-bottom: 1px solid #cacaca !important;
  background: url("/dist/Images/Global/legacy/booking/booking_steps_search_en.png") repeat-x left bottom !important;
  height: 20px;
}

#dokumenter .booking_substep_title {
  font-size: 19px !important;
  font-weight: normal !important;
  padding: 15px !important;
  border-bottom: 1px solid #cacaca !important;
  clear: both;
  margin-top: 22px !important;
  background: transparent !important;
}

#dokumenter .booking_substep_section {
  background: #fffffe url("/dist/Images/Global/legacy/booking/booking_steps_filter_type_en.png") repeat-x 0 0;
  margin-bottom: 10px;
  min-height: 90px;
  color: #5a5a5a;
  position: relative;
}

#dokumenter .booking_time_slide table th {
  border-left: 1px solid #d1d1d1;
  padding: 0px 7px 0px;
  height: 37px;
  width: 20% !important;
}

#dokumenter .booking_time_day {
  border-bottom: 1px solid #cacaca;
  padding: 15px 10px 15px 10px;
  font-size: 13px;
}


#dokumenter .booking_time_header {
  background: #fafaee url("/dist/Images/Global/legacy/booking/booking_time_header_en.png") repeat-x left bottom;
  border-bottom: 1px solid #cacaca;
  height: 35px;
}


#dokumenter .btime_box {
  padding-left: 5px;
  height: 40px;
  color: #fff;
  .font-size(1.4);
  font-weight: 400;
}

#dokumenter .btime_box .inner {
  height: 37px;
  padding-top: 3px;
  position: relative;
}

#dokumenter .btime_box a, .btime_box a:hover {
  color: #fff;
  display: block;
  text-decoration: none;
  outline: none;
  .font-size(1.4);
}

#dokumenter .btime_box_disable {
  background: url("/dist/Images/Global/legacy/booking/btime_box_disable_l_en.png") no-repeat 0 0;
}

#dokumenter .btime_box_disable .inner {
  background: url("/dist/Images/Global/legacy/booking/btime_box_disable_r_en.png") no-repeat right top;
}

#dokumenter .btime_box_unavailable {
  background: none;
}

#dokumenter .btime_box_unavailable .inner {
  background: url("/dist/Images/Global/legacy/booking/btime_box_unavailable_en.png") no-repeat right top;
  font-size: 11px;
  color: #5a5935;
  font-weight: normal;
}

#dokumenter .btime_box_available.selected {
  background: url("/dist/Images/Global/legacy/booking/btime_box_selected_l_en.png") no-repeat 0 0;
}

#dokumenter .btime_box_available.selected .inner {
  background: url("/dist/Images/Global/legacy/booking/btime_box_selected_r_en.png") no-repeat right top;
}

#dokumenter .btime_box_popup {
  position: absolute;
  z-index: 10000;
  top: -130px;
  left: -80px;
  background: #fff url("/dist/Images/Global/legacy/booking/btime_box_popup_en.png") repeat-x left bottom;
  border: 1px solid #E8EAEA;
  padding: 10px;
  width: 200px;
  height: 110px;
  color: #5a5935;
  font-weight: normal;
  -moz-box-shadow: 4px 3px 7px #505050;
  -webkit-box-shadow: 4px 3px 7px #505050;
  box-shadow: 4px 3px 7px #505050;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  display: none;
}

#dokumenter .btime_box_popup strong {
  font-weight: bold;
}

#dokumenter .btime_box_popup_arrow {
  background: url("/dist/Images/Global/legacy/booking/btime_box_popup_arrow_en.png") no-repeat scroll 0 0 transparent;
  bottom: -14px;
  height: 14px;
  left: 45%;
  position: absolute;
  width: 23px;
}

#dokumenter .booking_steps_btn {
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  padding: 14px 0 15px 0px;
}

#dokumenter .booking_steps_filter_type_line {
  border-top: 1px solid #cacaca;
}

.booking_steps_filter_type_line {
  .custom-radio {
    margin-right: 10px;
  }
}

#dokumenter .booking_steps_filter_date h2, .booking_steps_filter_periode h2 {
  border-bottom: 1px solid #cacaca;
}

#dokumenter .booking_calendar_container .titleCal, .booking_calendar_container .calendar, .booking_calendar_container .calendar table, .booking_calendar_container .calendar thead .year, .booking_calendar_container .calendar thead .title {
  color: #ffffff !important;
}

#dokumenter .booking_steps_btn li, #dokumenter .booking_steps_btn li span {
  color: #5a5a5a;
}

#dokumenter .booking_steps_btn.step1_btn {
  background: url("/dist/Images/Global/legacy/booking/booking_step1_btn_en.png") no-repeat 0 0;
}

#dokumenter .booking_steps_btn.step2_btn {
  background: url("/dist/Images/Global/legacy/booking/booking_step2_btn_en.png") no-repeat 0 0;
}

#dokumenter .booking_steps_btn.step3_btn {
  background: url("/dist/Images/Global/legacy/booking/booking_step3_btn_en.png") no-repeat 0 0;
}

#dokumenter .booking_steps_btn.step4_btn {
  background: url("/dist/Images/Global/legacy/booking/booking_step4_btn_en.png") no-repeat 0 0;
}

#dokumenter .b_bsk_box_check {
  border-top: 1px solid #cacaca;
}

span .error {
  display: block;
  padding-bottom: 3px;
  padding-top: 10px;
}

/*Changes for mypage booking here*/
.bookingWrap {
  position: relative;
  background-color: #faFaf0;
  padding: 15px;
  border-top: 1px solid #cece92;
}

.bookingWrapContainer {
  border-bottom: 1px solid #cece92;
  margin-bottom: 20px;
}


.bookingBox li {
  margin-bottom: 5px;
}

.bookingBox li span {
  font-weight: bold;
}

.bookingActions {
  position: absolute;
  right: 10px;
  top: 10px;
}

.bookingActions a {
  display: block;
  text-align: right;
  line-height: 23px;
}

.bookingActions a.closeRedBtn {
  padding-right: 25px;
  position: relative;
}

.bookingActions a.editRedBtn {
  padding-right: 25px;
  position: relative;
}

.bookingActions a.emailRedBtn {
  padding-right: 25px;
  position: relative;
}

.greenBox {
  background: #A6A549;
  padding: 5px;
  text-align: center;
  color: #fff;
  border: 1px solid #83811D;
}

.inputBox {
  float: left;
  width: auto;
}

.brow {
  clear: both;
}

.brow .error {
  float: left;
  margin: 32px 0 0 10px;
}

#calendarContainer {
  width: 100%;
  position: relative;
  display: block;
  clear: both;
  min-height: 80px;
}

#loadingCalendar {
  display: none;
  background-color: #7d7d7d;
  width: 100%;
  height: 100%;
  min-height: 100px;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 100000;
}

#loadingCalendar img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

#loadingCalendar div {
  width: 200px;
  height: 80px;
  z-index: 100001;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  margin: auto;
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
}

.autocomplete {
  width: 500px !important;
}

.autocomplete div {
  border-bottom: 1px solid #cccccc;
}

.autocomplete div:last-child {
  border-bottom: 0px solid #ffffff;
}

#my_booking span h2 {
  background-color: transparent !important;
  background-image: none !important;
  margin-left: 0px !important;
  padding-left: 0px !important;
}
