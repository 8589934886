/**
 * FOUC (Flash of Unstyled Content)
 * This will hide elements that must not flash before user has loaded the whole page.
 * Javascript will disable this again, so you must apply both "prevent-fouc" and "js-prevent-fouc" to elements that should be hidden
 *
 * @author Anders pedersen <alp@pentia.dk>
 * @template <div class="prevent-fouc js-prevent-fouc">Content that should be hidden</div>
 */

.prevent-fouc {
	display: none !important;
}
