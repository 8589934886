.subsite-wide {
  background-color: @sw-color-background;
  background-color: var(--sw-color-background, @sw-color-background);
  overflow: auto !important;

  body {
    background-color: @sw-color-background;
    background-color: var(--sw-color-background, @sw-color-background);
    overflow: initial !important;
    color: @sw-color-text-dark;
    font-size: @sw-font-base;
    font-weight: @sw-font-regular;

    * {
      font-family: "Founders Grotesk" !important;

      &.unfocus {
        border: 0;
      }
    }
  }
  &.prevent-scroll {
    overflow-y: hidden !important;
  }

  // content layout
  .two-col-spot,
  .three-col-spot,
  .text-spot-a-content,
  .informationspot-d,
  .informationspot-d.panel-group,
  .riddle-quiz__container,
  .info-graph,
  .list-filter-dropdown__select-wrapper {
    &:extend(.subsite-wide-container);
    &:extend(.spot--margin-bottom);

    .col-sm-4 > div,
    .col-sm-6 > div {
      background-color: transparent;
      margin-top: 0;
      margin-bottom: 0;

      &.text-spot-a-wrapper {
        padding: 32px !important;

        @media (min-width: @screen-md) {
          padding: @spacing-medium !important;
        }
      }
    }
  }

  .two-col-spot,
  .three-col-spot {
    &.row {
      display: flex;
      flex-wrap: wrap;

      .col-sm-4,
      .col-sm-6 {
        display: flex;
        flex-direction: column;

        @media (max-width: @screen-md-min) {
          width: 100%;
        }

        > *:nth-child(1):nth-last-child(1) {
          height: 100%;
        }
      }
    }

    .text-spot-a-wrapper .text-spot-a-content.read-more {
      padding-left: 0 !important;
      padding-right: 0 !important;
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  .content-rte {
    ul:not(.link-list) {
      li {
        list-style-type: disc;
      }
    }

    ul.link-list {
      li a:not(.booking-order-button):not(.read-more-expander) {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
