﻿@import "../styles.less";

.ribbon-spot {
  .clearfix();

  .category-label {
    margin: 0 auto;
    text-align: center;

    @media (max-width: (@screen-sm - 1px)) {
      text-align: left;
    }

    @media (min-width: @screen-sm) {
      margin: 0 auto;
    }


    .icon-kalender,
    .icon-nyheder {
      font-size: 5.5rem;
      color: @color-brand-200;
    }

    .category-title {
      .font-regular();
      .font-size(@type-size-xxl);
      line-height: 1.2em;
      font-weight: bold;

      @media (min-width: @screen-sm) {
        width: 50%;
      }

      margin: 0 auto 5px;
    }

    a {
      color: @color-brand-200;
      display: block;

      &:after {
        &:extend(.less-icon-extend);
        &:extend(.icon-pil-hoejre:before);
        .font-size(@type-size-xs);
        margin-left: 5px;
        display: inline-block;
      }
    }
  }



  [class*="title-link-"] {
    display: block;
    max-width: 100%;
    overflow: hidden;
    color: @color-brand-200;
    font-weight: bold;

    &:hover {
      color: @color-brand-200;
    }
  }

  .title-link-primary {
    margin-top: 5px;
    margin-bottom: 10px;
    .font-size(@type-size-xxl);
  }

  .title-link-secondary-clipped {
    margin-top: 5px;
    .font-size(@type-size-lg);
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .date {
    .font-regular();
    .font-size(@type-size-base);
    color: @color-text;
  }

  .list-secondary {
    border: 1px solid @color-contrast-300;
    border-width: 1px 0;
    padding: 10px 0 0;

    &:first-child {
      border-width: 1px 0 0;
    }
  }
}
