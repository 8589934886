﻿
.booking-form {
	background-color: rgb(255,255,255);
	font-family: "SceneAlt", serif;
	color: @color-primary-dark;

	p {
		.font-regular();
		.font-size(@type-size-base);
	}

	&.is-hidden {
		display: none;
	}

	&.row {
		margin: 0;
	}

	&__text {
		padding: 32px 0 26px;
		margin: 0 40px;
		/*border-bottom: 1px solid rgb(239,239,239);*/
		font-size: 20px;
	}

	&__field {
		position: relative;

		.row & {
			padding: 0;
		}

		&__label {
			display: block;
			margin-top: 30px;

			&--required {
				//&:after {
				//  content:"*";
				//  color: rgb(181, 1, 77);
				//}
				& ~ .booking-form__field__input-wrap {
					position: relative;

					&:after {
						content: '';
						display: inline-block;
						width: 0;
						height: 0;
						position: absolute;
						top: -8px;
						right: -8px;
						border-top: 8px solid transparent;
						border-bottom: 8px solid transparent;
						border-left: 8px solid @color-primary-function-bg;
						border-right: 8px solid transparent;
						.rotate(-45deg);
					}
				}
			}
		}

		&__dropdown {
			width: 100%;
			height: 62px;
			padding-left: 20px;
			border: none;
			background-color: @color-secondary-lighter;
			.font-size(1.6);
		}

		&__textarea {
			width: 100%;
			height: 180px;
			margin-bottom: 25px;
			padding: 10px;
			border: none;
			background-color: @color-secondary-lighter;
			.font-size(1.6);
		}

		&__textbox {
			width: 100%;
			height: 62px;
			padding-left: 20px;
			border: none;
			background-color: @color-secondary-lighter;
			.font-size(1.6);
		}
	}

	&__error {
		display: none;
		margin-top: 10px;
		padding-left: 0;
		color: @link-color !important;
		font-family: Arial, sans-serif;
		font-size: 14px;

		@media only screen and (max-width: 991px) {
			margin-top: 10px !important;
		}

		span {
			display: none;
			color: @link-color !important;
			font-family: Arial, sans-serif;
			font-size: 14px;
		}
	}

	&__error--serverside {
		display: block;

		span {
			display: block;
		}
	}

	&__action {
		position: relative;
		//text-align: center;
		.row & {
			padding: 40px 0;
		}

		.btn-action {
			//margin: 0 auto 20px;
			margin: 10px 0;
		}
	}
}

.booking-form-message {
	background-color: white;
	margin: 0 0 10px;
	font-family: "SceneAlt", serif;

	&__text {
		margin: 0 40px;
		padding: 32px 0 26px;
		/*border-bottom: 1px solid rgb(239,239,239);*/
		font-size: 20px;
	}

	&__action {
		position: relative;
		padding: 40px 0;
		text-align: center;

		.btn-action {
			//margin: 0 auto 20px;
		}
	}

	&--login {
		text-align: left;
	}
}

//Not right but have to
#bookingInfomation table b {
	font-weight: normal;
}

.booking-form__sub-header {
	h2 {
		padding-bottom: 10px;
	}

	&.spacy {
		margin-bottom: 40px;

		h2 {
			padding-bottom: 10px;
		}
	}

	h2 {
		border-bottom: 2px solid @color-secondary-light;
		.font-alt();
		.font-size(2);
		color: @color-primary-dark;
	}
}

.booking-form__reset-link {
	padding: 0;
	margin: 10px 0 0;

	a {
		color: @link-color;
		font-family: Arial, sans-serif;
	}
}

@media only screen and (min-width: 992px) {
	.booking-form {
		&__error {
			margin-top: 63px;

			.row & {
				padding: 0 40px 0 10px;
			}
		}
	}
}
