﻿//@import "../../Global/Components/subscriptions.less";
.subscribe-content-controls {
  select.form-control:focus {
    outline: -webkit-focus-ring-color auto 5px !important;
  }
}


.panel.panel-form.subscribe-form {
  &__panel {
    &--white {
      background-color: #fff;
    }

    .panel-title {
      .font-alt();
      font-weight: 400;
    }
  }
}

.panel.panel-form {
  border: 1px solid @color-secondary-light;

  .icon-mail {
    display: none;
  }

  .printHide {
    &--mobile {
      @media(min-width: @screen-md-min) {
        display: none;
      }
    }

    &--desktop {
      display: none;

      @media(min-width: @screen-md-min) {
        display: inline-table;
      }
    }

    @media(min-width: @screen-md-min) {
      float: right;
      position: relative;
      top: 70px;
    }
  }

  .subscribed-newsletters-overview {
    padding-bottom: 25px;

    li {
      margin-top: 5px;
    }

    .c2a--subscribed-newsletter {
      display: block;
      padding-left: 15px;

      &:before {
        display: inline-block;
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        font-size: 11px;
        float: left;
        margin: 4px 0 0 -15px;
      }
    }
  }

  .panel-body {
    //padding: 30px;
    &.subscriptions-manage {
      background: #fff;
      padding: 0;
      padding-bottom: 25px;

      .subscription-header {
        padding: 20px 38px 12px;
        margin-left: 0;
        margin-right: 0;
        border-bottom: 1px solid #eeeeee;
        background: #e8eaea;

        @media(min-width: @screen-md-min) {
          padding-right: 0 !important;
        }

        .btn-header {
          padding-right: 0;

          @media(max-width: @screen-sm) {
            padding-left: 0;
          }
        }
      }

      .subscription-footer {
        padding-top: 0;
        margin-left: 0;
        margin-right: 0;

        .form-control {
          color: @color-secondary-darker;
          position: relative;
          top: 5px;

          @media(min-width: @screen-sm-min) {
            top: 0;
          }
        }
      }

      .btn-footer {
        margin-left: 0;
        margin-right: 0;
        padding: 20px 23px 12px;

        .btn-right {
          padding: 10px 30px 10px 30px !important;
        }
      }

      .update-btn {
        display: inline-table;
        border-radius: 0;
        min-width: 100px;
        width: 100px;
        padding: 6px 20px 6px 20px;
        font-family: SceneAlt, serif;
        font-weight: 400;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 18px;
        font-size: 1.8rem;
        line-height: 21.599999999999998px;
        line-height: 2.16rem;
        color: #fff;
        background: @color-primary-function-bg;

        &:hover {
          text-decoration: none;
          background: @color-primary-function-hover;
        }

        @media screen and (max-width: 767px) {
          margin-top: 15px;
        }
      }
    }

    &.subscription-steps-container {
      .subscription-type-headline {
        font-family: SceneAlt, serif;
        font-size: 2rem;
      }

      .second-var-list {
        li a {
          display: inline-flex;

          @media (min-width: @screen-xs) {
            display: table;
          }

          &:before {
            display: inline-block;
            content: "\e631";
            font-family: "ft-icons";
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-size: 11px;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            margin-right: 5px;
            position: relative;
            top: 4px;

            @media (min-width: @screen-xs) {
              position: static;
            }
          }
        }

        li a:before:hover {
          text-decoration: none;
        }
      }

      .list-text {
        display: table;
        padding-left: 4px;
        margin-bottom: -10px;

        &.single-element {
          margin-bottom: 0;
        }
      }

      .abline {
        &:focus {
          outline: -webkit-focus-ring-color auto 5px;
        }

        .list-text-container {
          margin: 0;
          padding-top: 10px;
        }
      }

      select {
        width: 100%;
        height: 34px;
        padding: 6px 12px;
        display: inline-block;
        border-radius: 0;
        font-size: 14px;
        background: #f0f2f2;
        border: 0;
        outline: 0;
        color: #696969;
        position: relative;
        transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;

        &:focus {
          outline: -webkit-focus-ring-color auto 5px;
        }

        @media(min-width: @screen-md) {
          width: 100%;
        }
      }
    }

    .panel-title.abonnement {
      margin-bottom: 5px;
      margin-top: 10px;
      .font-alt();
      .font-size(2);
      color: @color-primary-dark;
      display: block;
      padding: 0;
    }

    .panel-title {
      margin-bottom: 10px;
    }

    .header-text {
      border-bottom: 1px solid @color-secondary-light;
      padding: 10px 0 20px;
      margin-bottom: 20px;
    }

    .arrow-left {
      display: block;
      border-radius: 0;
      width: 165px;
      padding: 10px 50px 10px 50px !important;
      font-family: SceneAlt, serif;
      font-weight: 400;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 18px;
      font-size: 1.8rem;
      line-height: 2.16rem;
      color: #fff;
      background: @color-primary-function-bg;

      &:hover {
        text-decoration: none;
        background: @color-primary-function-hover;
      }
    }

    .btn-smaller.remove {
      display: none;
    }

    .subscription-title {
      padding: 0 0 8px 0;
    }

    .checkbox.inline.primary {
      //display: none;
    }

    .form-group {
      select {
        border: 0;
        background: @color-secondary-lighter;
        border: 0;
        outline: 0;
      }
    }

    .abonnement {
      &:before {
        display: none;
      }
    }

    .subscribe-content-controls {
      border-bottom: 1px solid #eeeeee;
      padding-bottom: 20px;
      margin-bottom: 20px;

      .abonnement {
        display: inline-flex;

        @media (min-width: @screen-xs) {
          display: table;
        }

        &:before {
          display: inline-block;
          content: "\e631";
          font-family: "ft-icons";
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-right: 5px;
          position: relative;
          top: 4px;

          @media (min-width: @screen-xs) {
            position: static;
          }
        }
      }
    }

    .action-btn {
      .clearfix();
    }

    .action-btn-final {
      border-top: 1px solid #e8eaea;
      padding-top: 25px;
    }

    .btn-small {
      display: inline-table;
      border-radius: 0;
      min-width: 100px;
      width: 100px;
      padding: 6px 20px 6px 20px;
      font-family: SceneAlt, serif;
      font-weight: 400;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 18px;
      font-size: 1.8rem;
      line-height: 21.599999999999998px;
      line-height: 2.16rem;
      color: #fff;
      background: @color-primary-function-bg;

      &:hover {
        text-decoration: none;
        background: @color-primary-function-hover;
      }
    }

    ul {
      padding-left: 0;

      li {
        list-style-type: none;
      }
    }

    .step-text {
      border-bottom: 1px solid #e8eaea;
      padding-bottom: 20px;
    }

    .header-cta-text {
      border-bottom: 1px solid #e8eaea;
      padding-bottom: 12px;
    }

    .final-message-cta {
      border-bottom: 1px solid #e8eaea;
      padding-bottom: 25px;
    }

    .abline {
      &:last-of-type {
        .explText {
          border-bottom-color: transparent;
        }
      }

      .explText {
        display: inline-table;
        width: 93%;
        padding-top: 0;
        padding-bottom: 17px;
        border-bottom: 1px solid #e8eaea;
        margin-bottom: 9px;
        margin-left: 30px;
        .font-size(1.4);

        &.empty {
          display: none;
        }
      }
    }

    .issueSub {
      &.second-level {
        a {
          border-radius: 0;
          min-width: 165px;
          padding: 10px 50px 10px 50px !important;
          font-family: SceneAlt, serif;
          font-weight: 400;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          font-size: 18px;
          font-size: 1.8rem;
          line-height: 21.599999999999998px;
          line-height: 2.16rem;
          color: #fff;
          background: @color-primary-function-bg;

          &:hover {
            text-decoration: none;
            background: @color-primary-function-hover;
          }

          &:before {
            display: none;
          }
        }
      }
    }

    .second-var-list, .subscription-list-wrapper {
      margin-bottom: 20px;
      border-bottom: 1px solid #e8eaea;
      padding-bottom: 20px;

      a {
        display: inline-flex;

        @media (min-width: @screen-xs) {
          display: table;
        }

        &:before {
          display: inline-block;
          content: "\e631";
          font-family: "ft-icons";
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-size: 11px;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          margin-right: 5px;
          position: relative;
          top: 4px;

          @media (min-width: @screen-xs) {
            position: static;
          }
        }
      }
    }

    .folded-message {
      padding-top: 10px;
    }
  }

  &.steps {
    background: #fff;
  }
}

.subscriptions-signup {
  &.delete-profile {
    padding-top: 0;

    label {
      color: #252525;
      font-family: Arial, sans-serif;
      font-size: 16px;
    }

    @media(min-width: @screen-md-min) {
      margin-left: 0;
    }
  }

  @media(min-width: @screen-md-min) {
    //margin-left: 23px; // commented out due to bug TFS 27188
  }

  .col-sm-9 {
    .make-sm-column(8);

    &.col-sm-offset-3 {
      .make-sm-column-offset(0);

      br {
        display: none;
      }
    }
  }

  .validation-msg {
    color: @color-brand-200 !important;
    font-size: 1.4rem;
    padding-left: 15px;

    @media(min-width: @screen-md-min) {
      padding-left: 0;
      padding-top: 0;
    }

    p {
      margin-top: 5px;

      @media(min-width: @screen-md) {
        margin-top: initial;
      }
    }
  }

  .dvStatus {
    p {
      b {
        font-weight: 400;
        color: @link-color;
      }
    }
  }

  .btn-right.delete {
    display: inline-block;
    margin-right: 10px;
  }
}

.left-menu-wrapper {
  .subscribe-menu {
    margin: 0 -15px;

    .primary {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        border-bottom: none;

        &.level1, &.level2 {
          > div {
            display: none;
          }

          ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
              border: none;

              div {
                display: block;
              }

              a {
                &:first-child {
                  border-width: 0 0 2px 0;
                }

                border: 2px solid #e8eaea;
                border-width: 0 0 2px 0;
                color: #252525;
                padding: 16px 0 16px 37px;
                .font-alt();
                .font-size(2);

                span {
                  display: none;
                  position: absolute;
                  top: 0;
                  left: 0;
                  bottom: 0;

                  i {
                    display: initial;
                    vertical-align: initial;
                    width: auto;
                    position: absolute;
                    left: 50%;

                    &:before {
                      font-size: 10px;
                      top: -3px;
                      position: relative;
                      line-height: inherit;
                      padding: inherit;
                      color: #fff;
                    }
                  }
                }

                &:hover {
                  color: @color-primary-link;
                  text-decoration: none;

                  span {
                    display: block;
                    width: 10px;
                    background-color: @color-menu-hover;
                  }
                }

                &.active {
                  color: @color-primary-link;

                  span {
                    display: block;
                    width: 25px;
                    background-color: @color-menu-hover;

                    i {
                      &:before {
                        &:extend(.icon-ft_bullit_down:before);
                        font-size: 10px;
                        top: -3px;
                        position: relative;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.secondary-validation {
  padding-left: 37px;
  color: @link-color !important;

  p {
    color: @link-color !important;

    a {
      color: @color-brand-200 !important;
    }
  }
}



.subscriptions {
  .nav-pills {
    &.nav-wizard {    
      margin-bottom: 0;
      .font-alt();

      .active {
        color: @color-text;
      }
    }
  }

  .panel-body {
    background-color: #fff;

    a {
      .font-size(1.6);
    }
  }

  .row {
    background-color: #fff;
    //border-bottom: 1px solid @color-table-hover;
    /*&:nth-child(odd) {
      background-color: @color-table-alternate-row;
    }*/
    &:last-child {

      .subscription-item:after {
        background: transparent;
      }
    }

    .subscription-item {
      padding: 15px 0;
      position: relative;

      &:after {
        content: '';
        width: 100%;
        height: 1px;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: @color-table-hover;
      }
    }
  }

  .panel.panel-form {
    .panel-title {
      padding: 0 23px;
      font-weight: 400;
      .font-alt();
      display: none;
    }

    .subscription-item {
      .clearfix();
    }

    .subscription-title {
      padding: 0;
      color: @color-text;
      font-weight: 400;
      margin-bottom: 10px;
      .font-alt();
      .font-size(2);
      width: 75%;
      display: table;
    }

    p {
      color: @color-primary-dark;
      padding: 0;
      width: 75%;
      display: table;
    }

    .issueSub {
      margin-top: 10px;

      a {
        padding: 0 23px;
      }
    }

    a.subscription-ref {
      padding: 0;
      margin-bottom: 4px;
      display: inline-block;

      &:before {
        display: inline-block;
        content: "\e631";
        font-family: "ft-icons";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-right: 5px;
        position: relative;
        top: -0.7px;
      }

      &.subscription-type {
        width: auto;

        &:before {
          display: none;
        }
      }
    }
  }

  &__panel-body {
    .panel-title {
      font-weight: 400;
      .font-alt();
    }

    .subscribe-content-controls .container:first-child {
      .subscription-title {
        margin-top: 20px;
      }
    }

    .subscription-title {
      color: @color-text;
      font-weight: 400;
      .font-alt();
      margin-top: 40px;
    }

    p {
    }

    .issueSub {
      margin-top: 10px;

      a {
        padding: 0 23px;
      }
    }

    &--step2 {
      background-color: #fff;
      padding: 15px 38px;

      .icon-mail {
        display: none;
        /*margin-right: 5px;*/
      }

      .icon-feed2 {
        display: inline-block;
        margin-right: 5px;
      }

      .form-group {
        margin-bottom: 15px;
        margin-left: -15px;
        margin-right: -15px;
      }
    }

    &--step3 {
      background-color: #fff;
      padding: 15px 38px;

      .icon-mail {
        margin-right: 5px;
      }

      .icon-feed2 {
        display: inline-block;
        margin-right: 5px;
      }

      .break {
        display: none;
      }
    }

    &--step4 {
      background-color: #fff;
      padding: 15px 38px;
    }
  }

  &-manage {
    .section {
      &--bottom {
        padding: 15px 23px;

        .subscription-title {
          color: @color-text;
          font-weight: 400;
          .font-alt();
          .font-size(1.8);
        }

        .form-group {
          margin-bottom: 15px;
          margin-left: -15px;
        }

        .col-sm-5.col-sm-offset-3 {
          .make-sm-column(8);
          .make-sm-column-offset(0);
        }
      }
    }
  }

  .folded-list-wrapper {
    padding-left: 16px;

    ul {
      margin: 10px 0;

      li {
        a {
          .font-size(1.4);
        }
      }
    }

    li {
      a {
        .font-size(1.6);
      }
    }
  }
}

.subscription-category-title {
  margin-top: 50px;
  margin-bottom: 30px;

  h2 {
    .font-alt();
    .font-size(3);
    color: @color-primary-dark;
  }
}

.subscription-item {

  .explanation-text {
    .font-size(1.4);
  }
}

.subscriptions-signup {

  @media (max-width: @screen-sm) {
    padding: 15px 38px;
  }
}

.subscriptions-login {

  @media (max-width: @screen-sm) {
    padding: 0 23px;
  }
}

.subscription-steps-container {
  padding: 15px 37px;
  color: @color-primary-dark;

  .panel.panel-form & {
    .panel-title {
      .font-size(1.6);
      .font-alt();
    }
  }

  .radio + .radio,
  .checkbox + .checkbox {
    margin-top: 0;
  }

  .radio label, .checkbox label {
    position: relative;
    top: 2px;
    .font-regular();
    display: block;
    padding-left: 10px;
    overflow: hidden;
  }

  .c2a--delete-subscription {

    @media(max-width: @screen-md) {
      padding-left: 0;
    }

    a, i {
      color: @color-brand-200;
    }
  }
}

.subscription-c2a--back {
  display: inline-block;
}

.subscription-list-wrapper,
.subscription-steps-container .second-var-list,
.folded-list-wrapper {
  li {
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }
  }
}

.issueSub.subscription-info-message {
  margin-top: 20px;
  color: @color-brand-200 !important;
}

.subscriptions__panel-body {
  .spacing-vertical {
    // Icon is et on span and not on the a tag because of complications
    .arrow-icon {
      font-size: 11px;
      display: inline-block;
      position: relative;
      top: -3px;
      margin-right: 3px;
      color: @color-brand-200;

      &:before {
        content: "\e631";
        font-family: "ft-icons";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        padding-left: 5px;
        position: relative;
        top: 3px;
      }
    }
  }
}

//login
.login-btn {
  .list-unstyled {
    .arrow-right {
      color: @link-color !important;
    }
  }
}

//Forgotpassword
.forgot-password {
  padding: 0 !important;

  input {
    display: block;
    width: 100%;
    height: 62px;
    padding: 6px 12px;
    border: 0;
    font-size: 14px;
    outline: 0;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    -webkit-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s 0.5s ease;
    -moz-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s 0.5s ease;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s 0.5s ease;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s 0.5s ease;
  }

  .forgot-btn {
    text-align: center;
  }

  #btnSendPassword {
    width: 70%;
    margin: auto;
  }
}

//Forgot password validation
.forgot-password-validation {
  color: @link-color !important;
  margin-top: 0;
  padding-left: 0;
  padding-bottom: 20px;
  position: relative;
  top: -15px;
}

//Activate profile
.activate-profile {
  margin-left: 38px;
}

.confirm-delete, .issueSubSimple {
  padding: 0 38px;

  @media (min-width: @screen-md-min) {
    padding-left: 40px;
  }
}

.confirm-delete {
  padding: 0 38px 20px;
}

.issueSubSimple {
  .inputBtnSlet {
    @media (min-width: @screen-md-min) {
      margin-right: 17px;
    }
  }
}

.subscriptions-login {
  padding-left: 23px;

  .panel-body {
    .wrong-email {
      color: @link-color !important;
    }
  }

  .login-btn {
    .make-sm-column-offset(0);

    .btn-right {
      display: block;
      width: 70%;
      margin: 0 auto;
      margin-bottom: 12px;
    }

    .list-unstyled {
      margin: 0 auto;
      display: table;
    }
  }
}

.subscribe-form {
  margin: 0 23px !important;
}

.wizard-steps__container {
  .col-sm-12 {
    padding: 0;
  }

  .wizard-steps li {
    padding: 20px 38px;
    border-right: 0;
    @media (min-width: @screen-sm-max) { // @screen-sm-max provided by Bootstrap
      text-align: left;
    }
  }

  .wizard-steps li:first-child {
    border-left: 0;

    @media (min-width: @screen-sm) {
      padding-left: 38px;
    }
  }

  .wizard-steps li:last-child {
    border-right: 0;
  }
}

.publications-form {
  margin: 0 -15px 20px;
  padding: 30px 38px;
  background: #fff;

  input, textarea {
    background-color: @color-secondary-lighter;
  }
}


//Accordion on subsciption page
.subscription-accordion__icon {
  display: inline-block;
  vertical-align: middle;
  margin: 5px 0;
  padding-right: 5px;
  color: @color-menu-hover;
  font-size: 10px;
}

.subscription-category-controls label {
  margin-right: 30px;

  @media (min-width: @screen-sm-min) {
    margin-bottom: 8px;
  }

  margin-bottom: 17px;

  .custom-checkbox {
    position: relative;
    top: -3px;
  }
}

.subscription-category-controls-container {
  display: inline-block;
  margin-bottom: 15px;
  margin-top: 5px;

  @media (min-width: @screen-sm-min) {
    margin-bottom: 8px;
  }
}

.subscription-category-controls {

  &__input-wrapper {
    display: table;

    &:focus {
      outline: -webkit-focus-ring-color auto 5px !important;
    }
  }

  a {
    margin-right: 30px;
  }

  .icon-nulstil {
    color: @link-color;
    margin-right: 5px;

    &:hover {
      text-decoration: none;
    }
  }
}

.form-group .custom-checkbox {
  position: relative;
  top: 0;
}

.checkbox-primary {
  display: inline-block;
  float: left;
}

.checkbox.secondary {
  .icon-feed2 {
    color: @link-color;
  }
}
