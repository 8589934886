.subsite-wide {
  .categories {
    .categories__category-title {
      display: flex;
      align-items: center;
    }
    .categories__category-title-icon {
      color: @sw-color-dark;
      margin-right: 10px;
      svg {
        width: 25px;
        height: 25px;
      }
    }
    .categories__item-image {
      max-width: 100%;
    }
  }

  .category-list {
    &:extend(.section--margin-bottom);

    @media (min-width: @screen-md-min) {
      padding-top: calc(@section-spacing - @padding-large);
      padding-bottom: calc(@section-spacing - @padding-large);
      background-color: @sw-color-primary-light;
      background-color: var(--sw-color-primary-light, @sw-color-primary-light);
    }

    &__title {
      &:extend(.section--padded-bottom);
      display: flex;
      align-items: center;

      h2 {
        margin-bottom: 0 !important;
      }
    }

    &__title-icon {
      width: 55px;
      height: 55px;
      margin-right: 12px;
      flex-shrink: 0;

      @media (max-width: @screen-md-min) {
        width: 30px;
        height: 30px;
      }
    }

    .link-card-carousel {
      background-color: @sw-color-primary-light;
      background-color: var(--sw-color-primary-light, @sw-color-primary-light);
      margin-top: 0;
      margin-bottom: @section-spacing-small;
      &:extend(.section--padded-large);
      padding-top: 0;
      
      &:last-of-type {
        margin-bottom: 0;
      }

      @media (max-width: @screen-md-min) {
        padding-top: 0 !important;
        position: relative;
      }

      @media (min-width: @screen-md-min) {
        background-color: transparent;
        margin-bottom: 0;

        + .link-card-carousel {
          padding-top: 0;
        }

        &:last-of-type {
          margin-bottom: 0;
        }
      }

      &__header {
        @media (max-width: @screen-md-min - 1) {
          display: none;
        }
      }

      .link-card-carousel-category {
        @media (max-width: @screen-md-min) {
          display: block !important;
        }

        .category-card-mobile {
          @media (max-width: @screen-md-min) {
            position: absolute;
            width: 100vw;
            max-width: 100vw;
            left: 0;
            top: 0;
            height: 180px;
            overflow: hidden;

            .category-card {
              height: 100%;
              min-height: 100%;
              max-height: 100%;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;

              .card__hover-content {
                display: none;
                bottom: 50%;
                flex-direction: row;

                &-icon {
                  margin-top: 0;
                  margin-left: 5px;
                }
              }

              .card__header {
                margin: 0 0;
                padding: 0 25px;
                flex-grow: 0;
                flex-direction: row;
                position: relative;
                z-index: 1;
                margin-top: -50px;

                .card__title {
                  color: @sw-color-light;
                  display: inline-flex;
                  align-items: center;

                  &:after {
                    content: "";
                    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAzMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAuOTk5Mzc0IDEwLjE3NjFMMjYuODE2OSAxMC4xNzYxIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTkuOTg5OSAxOC4yNDRMMjguNTk1NyAxMC4xMjJMMTkuOTg5OSAxLjk5OTk5IiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K");
                    background-repeat: no-repeat;
                    background-size: 30px 20px;
                    background-position: 0px 6px;
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    margin-left: 10px;
                    flex-shrink: 0;
                  }
                }

                .card__icon {
                  width: 30px;
                  height: 30px;
                }
              }

              .card__content {
                height: 100%;
                width: 100%;
                top: 0;
                left: 0;
                position: absolute;
              }
            }

            .card__header {
              @media (min-width: @screen-md-min) {
                display: none;
              }
            }

            .card__content {
              @media (min-width: @screen-md-min) {
                height: 100%;
              }
            }

            .card__image {
              height: 100%;

              img {
                object-fit: cover;
                height: 100%;
              }

              .card__desktop-image {
                display: inline;
                @media (max-width: @screen-md-min) {
                  display: none;
                }
              }
              .card__mobile-image {
                display: none;
                @media (max-width: @screen-md-min) {
                  display: inline;
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
