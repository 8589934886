﻿.gallery-collection {
  padding: 0 23px;

  &__image-gallery {
    //float: left;
    text-align: center;
    margin-bottom: 30px;

    @media only screen and (max-width: @screen-sm) {
      min-height: initial !important;
      margin-bottom: 30px;
      padding: 0;
    }

    h4 {
      font-weight: 400;
      font-size: 16px;
      margin-top: 0;
    }

    &__item {
      display: block;
      background-color: #fff;
      padding: 10px 0 0;
      overflow: hidden;
    }

    img {
      position: relative;
      left: 50%;
      -moz-transform: translate(-50%,0);
      -ms-transform: translate(-50%,0);
      -o-transform: translate(-50%,0);
      -webkit-transform: translate(-50%,0);
      transform: translate(-50%,0);
    }
  }
}

.image-gallery {
  padding: 0 23px;

  &__item {
    text-align: center;
    margin-bottom: 30px;

    @media only screen and (max-width: @screen-sm) {
      min-height: initial !important;
      margin-bottom: 30px;
      padding: 0;
    }

    h4 {
      font-weight: 400;
      font-size: 16px;
      margin-top: 0;
    }

    img {
      margin-bottom: 20px;
    }

    &__link {
      display: block;
      background-color: #fff;
      padding: 10px 0;
      overflow: hidden;
    }
  }
}

.gallery-image {
  background-color: #fff;
  padding: 30px 38px;

  h2 {
    .font-alt();
    .font-size(2.5);
    margin: 0 0 20px 0;
  }

  &__image {
    text-align: left;
    margin-bottom: 30px;
  }

  &__text {
  }

  &__download {
    &:hover {
      text-decoration: none;

      [class*="__icon"] {
        text-decoration: none;
        background-color: @color-primary-function-bg;
        color: #fff;
      }
    }

    &__icon {
      color: @color-primary-function;
      background-color: #fff;
      border-radius: 50%;
      width: 43px;
      height: 43px;
      display: inline-block;
      position: relative;
      text-align: center;

      i {
        font-size: 28px;
        position: relative;
        top: 7px;
      }
    }

    &__link {
      display: inline-block;
      color: @color-primary-link-2;
      margin-left: 10px;
      .font-alt();
      .font-size(1.5);
    }

    &__file-size {
      color: @text-color;
    }
  }
}
