﻿@import "../Global/typography.less";
.font-face("SceneAlt", "../../Fonts/Folketinget/2EB804_0_0", 400, normal);
.font-face("ft-icons", "../../Fonts/Global/ft-icons", 400, normal);

/*@font-face {
  font-family: 'SceneAlt';
  src: url('../fonts/2EB804_0_0.eot');
  src: url('../fonts/2EB804_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2EB804_0_0.woff2') format('woff2'),url('../fonts/2EB804_0_0.woff') format('woff'),url('../fonts/2EB804_0_0.ttf') format('truetype');
}*/

.font-headline(@important: "") {
  font-family: "adobe-caslon-pro", serif;
  font-weight: 400 e(@important);
  font-style: normal e(@important);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media (max-width: @screen-xsm) {
    overflow-wrap: break-word;
  }
}

.font-alt(@important: "") {
  font-family: SceneAlt, serif;
  font-weight: 400 e(@important);
  font-style: normal e(@important);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-regular(@important: "") {
  font-family: Arial, sans-serif;
  font-weight: 400 e(@important);
  font-style: normal e(@important);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-bold(@important: "") {
  font-family: Arial, sans-serif;
  font-weight: 700 e(@important);
  font-style: normal e(@important);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  .font-headline();
  .font-size(5);
  margin-bottom: 20px;
  color: @color-primary-darker;
  padding: 0 38px;

  @media (max-width: @screen-xsm) {
    width: 100%;
  }
}

h2,
h3,
h4,
h5 {
  @media (max-width: @screen-xsm) {
    overflow-wrap: break-word;
    width: 100%;
  }
}

.subfrontpage-a {
  text-align: center;

  h1 {
    display: inline-block;

    &:after {
      content: "";
      display: block;
      background: @color-primary-function-bg;
      height: 5px;
      width: 60%;
      margin: 20px auto 20px !important;
    }
  }

  .text-spot-a-wrapper {
    .read-more {
      .font-alt();
      .font-size(2);
    }
  }
}

.manchet {
  .font-alt();
  .font-size(@type-size-xxl);
  line-height: 25px;
  margin-bottom: 25px;
  padding: 0 38px;

  a {
    font-weight: 400;
    color: @link-primary-color;
  }
}

.updated-paragraph {
  margin-top: 80px;
  color: #919191;
}

.module-headline {
  .font-alt();
  .font-size(2.5);
}

.paragraph-title {
  &:extend(.manchet);
  margin-bottom: 5px;
}

.manchet + div {
  margin-bottom: 25px;
}

input:valid {
  /*border: 1px solid @color-brand-info-400;
  font-weight: normal;*/
}

input:invalid {
  border: 1px solid @validation-error-border-color;
  font-weight: bold;
}

input:required {
}

input:optional {
}

label {
  font-weight: 400;
  .font-alt();
}

::selection {
  background: @color-primary-function-bg; /* WebKit/Blink Browsers */
  color: #fff;
}

::-moz-selection {
  background: @color-primary-function-bg; /* Gecko Browsers */
  color: #fff;
}

.text-link() {
  a {
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    color: @color-primary-link;

    &.btn-right {
      color: #fff;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}

.content-area {
  a.btn-right {
    color: #fff;
  }
}

.text-right {
  text-align: right;
}

.font-scenealt {
  font-family: SceneAlt, serif !important;
}

/*.booking-order-button {
  color: #fff;
  a {
    color: #fff;
    &:hover {
      background-color: transparent;
    }
  }
}*/
/*.content-area {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      a {
        display: block;

        &:before {
          display: inline-block;
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_bullit_right:before);
          font-size: 11px;
          margin: 0 5px 0 0;
        }
      }
    }
  }
}*/
