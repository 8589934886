.checkbox,
.radio {
  padding: 0 !important;
  margin-bottom: 5px;
  display: inline-block;
  width: 100%;
}

input[type="checkbox"],
input[type="radio"] {
  opacity: 0;
}

.ankiro {
  input[type="checkbox"],
  input[type="radio"] {
    width: 22px !important;
    margin-right: 10px !important;
    display: inline-block;
  }
}

.custom-checkbox,
.custom-radio {
  position: relative;
  display: inline-block;
  width: 22px;
  height: 22px;
  border: 2px solid #7f7f7f;
  text-align: center;
  float: left;
  background: #fff;
  margin-right: 5px;

  @media (min-width: @screen-sm) {
    border: 1px solid #7f7f7f;
  }
  .icon {
    &.has-focus {
      outline: 5px auto -webkit-focus-ring-color;
      outline-offset: -2px;
    }
  }

  input,
  .icon {
    position: absolute;
    top: 0;
    left: 0;
    float: none;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  input {
    opacity: 0 !important;
    float: none !important;
    margin: 0 !important;
    z-index: 10000;

    & ~ .icon:before {
      visibility: hidden;
      vertical-align: middle;
    }

    &:checked ~ .icon:before {
      visibility: visible;
      color: @color-primary-function-bg;

      .checkbox--disabled & {
        color: #999;
      }
    }
  }
}

div.BDC_CaptchaDiv a:focus {
  outline: 3px solid #66afe9 !important;
}

.form-control.search-field {
  &:focus {
    outline: -webkit-focus-ring-color auto 5px !important;
  }
}

.custom-checkbox {
  border-radius: 2px;

  .icon {
    &:extend(.less-icon-extend);

    &:before {
      &:extend(.icon-checkmark:before);
    }
  }
}

.BDC_CaptchaDiv a:focus,
.BDC_CaptchaDiv a:active {
  outline: 3px solid #66afe9 !important;
}

.custom-radio {
  border-radius: 22px;
  border-color: #dadada;

  .icon:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -25% 0 0 -25%;
    width: 50%;
    height: 50%;
    background: @color-brand-300;
    border-radius: 22px;
  }
}

@media (min-width: @screen-md) {
  .form-horizontal .control-label {
    text-align: left;
  }
}
