﻿@import "../../Global/Components/ftMembersAddresses.less";

.ftMembersAddresses {
  color: @color-primary-dark;

  .ftMember .download__container__docBtns__btn__text {
    color: @color-contrast-150;
  }

  table {
    margin-top: 0;
  }

  table tbody tr td a:not(.ftMembersAddresses__link) {
    color: @color-brand-200 !important;
    font-size: 1.4rem;

    &:hover {
      text-decoration: underline;
    }
  }

  table tbody tr td .ministerweb ul.link-list {
    @media (max-width: 768px) {
      padding-left: 0 !important;
    }
  }


  tr {
    @media screen and (min-width: @screen-md-min) {
      padding: 0;
    }
  }

  .emailHolder {
    .email1,
    .email2,
    .email3 {
      color: @color-brand-200;
    }

    &:hover {
      .email1,
      .email2,
      .email3 {
        text-decoration: underline;
      }
    }
  }
}


