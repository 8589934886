﻿.ftMembersSearch {
  p {
    margin: 0 38px;

    &.manchet {
      margin: 0;
    }
  }

  h2 {
    margin: 0 38px;
  }

  &__searchfields {
    background-color: #fff;
    margin: 0;
    padding: 0 23px;

    h2 {
      margin: 20px 0 10px 0;
    }

    .pic {
      img {
        width: 100%;
        height: auto;
      }
    }

    .boxColumn {
      margin-top: 15px;
    }

    .boxContent {
      h2 {
        display: block;
      }

      p {
        margin: 0 0 1em 0;
      }
    }

    fieldset {
      .clearfix();

      div {
        .make-row();
        margin: 0 0 20px;
      }

      label {
        font-weight: 400;
        display: block;
        .make-xs-column(12);
        padding: 0;
        color: @color-text;
      }

      select {
        .make-xs-column(12);
        .font-size(1.4);
        padding: 0 0 0 10px; // Fixes BUG-36738
		height: 40px; // Fixes BUG-36738
        font-weight: 400;
        background-color: #f6f6f5;
        border: 0;
        color: #696969;
      }

      .datepicker {
        background-color: #f6f6f5;
      }

      .button {
        .btn-smaller();
				 .font-size(1.8);
        margin: 0;
        float: right;
      }
    }
  }

  &__maps {
    .make-row();
    margin: 0;
    background-color: #fff;
    padding: 20px 23px;

    &__denmark {
      .make-md-column(7);

      h2 {
        margin: 0;
        .font-size(2.5);
      }
    }

    &__grfr {
      .make-md-column(3);
      .make-md-column-push(2);

      h2 {
        margin: 0;
        .font-size(2.5);
      }
    }

    .mapContainer {
      padding: 15px 0 0;
      position: relative;

      #mapBr {
        position: absolute;
        top: 0;
        right: 0;
      }

      #mapDk {
        @media screen and (max-width: @screen-xsm) {
          width: 100%;
          background: none;
        }
      }
    }
  }
}
