.informations-spot-e{  
    animation: fadein 2s;
}
@keyframes fadein {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

﻿.drpPeriodSelect:focus{ 
    outline: 5px auto -webkit-focus-ring-color;   
}

// Skip to content
.skip {
  position: absolute;
  top: -1000px;
  left: -1000px;
  height: 1px;
  width: 1px;
  text-align: left;
  overflow: hidden;
  z-index: 99999;
  background: khaki;
}


a.skip:active, 
a.skip:focus, 
a.skip:hover {
  left: 0; 
  top: 0;
  width: auto; 
  height: auto; 
  overflow: visible; 
}

// This element might change id name due to 3rd party
#cdh_iFrame_88618{
  position: absolute;
  left: -99999px;
}

.has-focus:focus{
      outline: -webkit-focus-ring-color auto 5px!important;
}