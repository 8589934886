﻿.twitter-spot {

  #twitter-feed {
    margin: 0;
    padding: 0;
    max-height: 400px;
    overflow: auto;
    border: 1px solid #EEE;
    padding: 20px;

    .tweet + .tweet {
      border-top: 1px solid #EEE;
      margin-top: 10px;
      padding-top: 10px;
    }

    .tweet {
      list-style: none;

      &:after {
        display: block;
        clear: both;
      }

      .tweet-image {
        float: left;
        margin-right: 20px;
      }

      .tweet-text {
        overflow: hidden;

        .tweet-name-full {
          font-weight: bold;
        }

      }

      .tweet-media img {
        width: auto;
        height: auto;
        max-width: 300px;
        max-height: 300px;
      }

    }
    
  }

  .show-more {
    display: block;
    margin: 20px auto;
  }

}