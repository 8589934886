﻿@import "../../styles.less";

// for accordions placed in the left side
.panel-left-side {
  font-weight: normal;
  .panel-variant(@panel-default-border; @panel-left-side-text; @panel-left-side-heading-bg; @panel-left-side-border);
  // override bootstrap defaults
  &.panel {
    border: 0;
    box-shadow: none;
    background-color: transparent;
  }

  .panel-heading {
    padding: 7px 10px;

    .panel-title {
      a {
        display: block;

        &:hover {
          text-decoration: none;
        }

        .font-bold();
        .font-size(@type-size-md);

        @media (min-width: @screen-sm) {
          .font-size(@type-size-sm);
        }

        &:after {
          &:extend(.less-icon-extend);
          &:extend(.icon-luk:before);
          margin-top: 2px;
          display: block;
          float: right;
          color: @color-brand-200;
        }

        &.collapsed {
          &:after {
            &:extend(.icon-aaben:before);
          }
        }
      }
    }
  }

  .panel-body {
    padding: 10px 0;
    border: 0;
  }

  .panel-heading + .panel-collapse {
    .panel-body {
      border: 0;
    }
  }
}

// for accordions placed in the middle
.panel-middle {
  font-weight: normal;
  .panel-variant(@panel-default-border; @panel-middle-text; @panel-middle-heading-bg; @panel-middle-border);

  // override bootstrap defaults
  &.panel {
    border-right-width: 0;
    border-left-width: 0;
    box-shadow: none;
    background-color: transparent;
  }



  .panel-heading {
    padding: 0 0;

    .panel-title {
      a {
        display: inline-block;
        height: auto;
        width: 100%;
        float: left;

        &:hover {
          text-decoration: none;
        }

        .font-bold();
        font-size: 20px;
        color: @color-text;

        &:after {
          &:extend(.less-icon-extend);
          &:extend(.icon-pil-spinkel-ned:before);
          background-color: @color-contrast-400;
          width: 50px;
          // height: 50px;
          // line-height: 50px !important;
          position: absolute;
          top: 50%;
          transform: translate(0%, -50%);
          right: 0;
          text-align: center;
          display: inline-block;
          float: right;
          color: @color-text;
        }

        &.collapsed {
          color: @color-brand-200;

          &:after {
            &:extend(.icon-pil-spinkel-hoejre:before);
            color: @color-brand-200;
          }
        }

        span {
          overflow: hidden;
          display: block;
          float: left;
          line-height: 1.2;
          padding: 15px 10px 15px 0;
          font-size: 15px;

          @media (min-width: @screen-sm) {
            font-size: 16px;
            width: 90%;
          }

          .three-col-spot & {
            width: 80%;
          }
        }
      }
    }
  }

  .panel-collapse {
    clear: both;
  }

  .panel-body {
    padding: 10px 0;
    border: 0;

    a:not(.link-list, .generic-links, .link-primary, .unsorted, .case-document__link, .chosen-single){
      .font-size(1.6);
      border-bottom: 0 !important;
      color: @link-color;
      text-decoration: underline;

      &:hover {
        text-decoration: underline;
      }
    }

    .helpSearchBoxes a {
      text-decoration: none;
    }
    
    a.front-arrow {
      padding-left: 0;
      border-bottom: 0 !important;
    
      &:hover {
        text-decoration: underline;
      }
    
      &:before {
        content: "";
        display: none;
      }
    
      &:after {
        display: inline-block;
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        font-size: 11px;
        position: relative;
        margin-left: 5px;
      }
    
      h1 &:after {
        font-size: 3.6rem;
      }
    
      h2 &:after {
        font-size: 2.5rem;
      }
    
      h6 &:after {
        font-size: 0.8rem;
      }
    }
 

    th {
      a {
        color: @color-text-dark;
      }
    }
  }

  .panel-heading + .panel-collapse {
    .panel-body {
      border: 0;
    }
  }

  .panel-collapse.in {
    padding-bottom: 0;

    .bx-wrapper {
      padding: 0;
      margin: 0;

      .tab-pane {
        padding-left: 35px;
        padding-right: 35px;
      }

      .bx-controls-direction {
        a {
          top: 33px;
          font-size: 0.9em;
        }
      }

      .bx-prev {
        left: 5px;
      }

      .bx-next {
        right: -5px;
      }
    }
  }
}

#accordionFilter {
  .panel-heading {
    .panel-title {
      a {
        span {
          padding: 0;
        }
      }
    }
  }
}

.pageedit {
  .panel-collapse.collapse {
    display: block !important;
  }
}
