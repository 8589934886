﻿@import "../../Global/Components/primaer-menu-a.less";

@media (max-width: 767px) {
  .wrapper.header-container {
    border: 0;
  }
}

@media (min-width: 768px) {
  .wrapper.service-menu {
    display: none;
  }

  .wrapper.header-container {
    display: none;
  }
}

@media (max-width: 991px) {
  .wrapper.header-container {
    display: block !important;
    margin-bottom: 30px;
    height: auto;
  }

  .visible-xs.xs-menu {
    display: table-cell !important;
    vertical-align: top;
    padding-left: 10px;
  }
}

@media (min-width: 992px) {
  .wrapper.header-container {
    //display: block !important;
    margin-bottom: 30px;
    //height: auto;
  }
}

.primary-menu-container {
  background-color: #d2d2d2;

  .primary-menu {
    .search-icon{
      .site-folketingstidende .billing-page &{
        visibility: hidden;
      }
    }
    .toggle-search .text {
      width: 41px !important;
    }

    .site-identity-container {
      text-align: center;
      margin-bottom: 0;
      display: table-cell;
      width: 100%;
      padding-right: 10px;
      // Replace class name when approved by the client
      &.logo-beta {
        vertical-align: middle;
        padding-left: 20px;

        .mobile-logo-container img {
          width: 100%;
          max-width: 240px;
        }

        .hide-site-title {
          position: absolute;
          left: -9999999px;
        }

        .show-site-title {
          font-size: 19px;
          line-height: inherit;
          text-transform: inherit;
          padding-bottom: 5px;

          @media (max-width: 420px) {
            font-size: 14px;
            width: auto;
            padding: 0;            
          }
        }
      }

      .site-title {
        .font-size(@type-size-h1);
        .font-alt();
        text-transform: uppercase;
        line-height: 80px;


        @media(max-width: 480px) {
          font-size: 2.5rem;
          padding-left: 10px;
        }

        a {
          color: #252525;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }

    .toggle-menu {
      .icon-menu {
        background-color: transparent;
        font-size: 32px;
        color: #252525;
        padding: 0;
        margin: 14px 0px 0px 0px;
        width: 42px;
        border-radius: 3px;
        display: inline-block;

        &:before {
          &:extend(.icon-ft_menu:before);
          top: 10px;
          position: relative;
        }
      }

      .text {
        overflow: hidden;
        text-indent: -10000px;
        display: inline-block;
      }
    }

    .toggle-search {
      .icon-soeg {
        background-color: transparent;
        font-size: 45px;
        color: #252525;
        padding: 0;
        margin: 14px 0px 0px 0px;
        width: 42px;
        border-radius: 3px;
        display: inline-block;

        &:before {
          &:extend(.icon-ft_search:before);
          top: 3px;
        }
      }

      .text {
        overflow: hidden;
        text-indent: -10000px;
        display: inline-block;
      }
    }
  }
}
