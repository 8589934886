.subsite-wide {
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  input[type="password"],
  input[type="date"],
  input[type="search"],
  input[type="submit"],
  select,
  textarea {
    width: 100%;
    max-width: 100%;
    color: @sw-color-text-dark;
    border-radius: 0;
    box-sizing: border-box;
    font-size: 20px;
    line-height: 1;
    background-color: @sw-color-grey-lightest;
    background-color: var(--sw-color-grey, @sw-color-grey-lightest);
    border: 2px solid @sw-color-grey-lightest;
    border: 2px solid var(--sw-color-grey, @sw-color-grey-lightest);
    padding: 11.5px 15px;

    &:hover {
      border-color: @sw-color-grey-lightest;
      border-color: var(--sw-color-grey, @sw-color-grey-lightest);
      color: @sw-color-text-dark;
      background-color: @sw-color-grey-lightest;
      background-color: var(--sw-color-grey, @sw-color-grey-lightest);
    }

    &:active {
      outline: 0;
      border: 2px solid @sw-color-grey-lightest;
    }

    &.input--error {
      font-weight: @sw-font-regular;
      outline: @focus-style;
      outline-color: @sw-color-red;
      outline-offset: @focus-offset;

      + .input-error-message {
        margin-top: 10px;
        padding-left: @spacing-small;
        font-weight: @sw-font-regular;
        color: @sw-color-red;
      }
    }
  }

  [disabled="disabled"],
  [disabled],
  [disabled="true"] {
    border-color: rgba(118, 118, 118, 0.3);
    border-width: 1px;
    border-style: inset;

    &:active {
      border-color: rgba(118, 118, 118, 0.3);
      border-width: 1px;
      border-style: inset;
    }
  }

  .custom-input-search {
    position: relative;

    input {
      -webkit-appearance: initial !important;
      height: 50px;

      &:hover,
      &:focus {
        border: 2px solid black !important;
      }

      &::-webkit-search-cancel-button {
        -webkit-appearance: none;
        height: 24px;
        width: 24px;
        margin-right: 30px;
        background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
        cursor: pointer;
      }

      &:focus::-webkit-search-cancel-button {
        pointer-events: all;
      }
    }

    &-btn {
      position: absolute;
      width: 50px;
      height: calc(100% - 4px);
      top: 2px;
      right: 2px;

      input[type="submit"] {
        border: 0 !important;
        height: calc(100% - 4px);
        position: absolute;
        top: 2px;
        right: 2px;
        background-color: transparent !important;
        cursor: pointer;

        &:focus-visible,
        &:focus,
        &:active {
          outline: @focus-style;
          outline-offset: -1px !important;
        }
      }

      &:after {
        font-family: "ft-icons";
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        content: "\e658";
        width: 25px;
        height: 25px;
        display: block;
        position: absolute;
        right: 15px;
        color: @sw-color-red;
        top: 12.5px;
        font-size: 25px;
      }
    }
  }

  .radio-buttons {
    margin-top: @padding-small;
  }

  .radio-button__container {
    margin-right: @spacing-small * 2;
    cursor: pointer;

    @media (max-width: @screen-md-min) {
      width: 100%;
      margin-bottom: @padding-small;
    }

    &.radio--selected {
      font-weight: @sw-font-bold;
      color: @sw-color-primary;
      color: var(--sw-color-primary, @sw-color-primary);
    }

    &:active {
      outline: 0;
    }

    &:focus,
    &:focus-within {
      outline: 2px dashed @sw-color-grey-darker;
    }
  }

  .custom-checkbox,
  .custom-radio {
    input {
      opacity: 0 !important;

      &:focus,
      &:active {
        outline: 0;
      }
    }
  }

  .custom-radio {
    width: 20px;
    height: 20px;
    border-radius: 22px;
    border: 2px solid @sw-color-primary;
    border: 2px solid var(--sw-color-primary, @sw-color-primary);
    background: @sw-color-background;
    background: var(--sw-color-background, @sw-color-background);

    .icon:before {
      background: @sw-color-primary;
      background: var(--sw-color-primary, @sw-color-primary);
      margin: 0;
      width: 6px;
      height: 6px;
      border-radius: 20px;
      transform: translate(-50%, -50%);
      visibility: hidden;
      vertical-align: middle;
    }

    input {
      &:checked ~ .icon:before {
        color: @sw-color-primary;
        color: var(--sw-color-primary, @sw-color-primary);
        visibility: visible;
      }

      &:checked ~ .check-label {
        font-weight: @sw-font-bold;
      }
    }

    // to hide the js added markup
    > .custom-radio {
      border: 0;
      background: transparent;
      height: 18px;
      width: 18px;

      input {
        &:checked ~ .icon:before {
          color: @sw-color-primary;
          color: var(--sw-color-primary, @sw-color-primary);
          visibility: visible;
        }
      }
    }
  }

  .checkbox__container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;

    &.input--error {
      .custom-checkbox {
        outline: @focus-style;
        outline-offset: @focus-offset;
        outline-color: @sw-color-red;
      }

      + .input-error-message {
        margin-top: 10px;
        padding-left: @spacing-medium;
        font-weight: @sw-font-regular;
        color: @sw-color-red;
      }
    }

    &.disabled {
      .check-label {
        color: @sw-color-grey-dark;
      }
    }

    &.accept-terms .check-label a {
      border-bottom: 1px solid @sw-color-grey-dark;
      color: var(--sw-color-primary, @sw-color-link);

      &:hover {
        border-bottom-color: @sw-color-primary;
        border-bottom-color: var(--sw-color-primary, @sw-color-primary);
        text-decoration: none;
      }
    }

    @media (max-width: @screen-md-min) {
      &:last-child {
        margin-bottom: 0;
      }
  
      &.accept-terms {
        margin-bottom: @spacing-small * 2;
  
        &.input--error {
          margin-bottom: 0;
        }
      }
    }
  }

  .input-error-message {
    margin-top: 10px;
    padding-left: @spacing-small;
    font-weight: @sw-font-regular;
    color: @sw-color-red;
  }

  .custom-checkbox {
    border-color: @sw-color-grey-lightest;
    border-color: var(--sw-color-grey, @sw-color-grey-lightest);
    background-color: @sw-color-grey-lightest;
    background-color: var(--sw-color-grey, @sw-color-grey-lightest);
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: @spacing-small;
    margin-top: 3px;
    border-radius: 0;

    .icon:before {
      content: "";
      position: absolute;
      display: none;
      border-radius: 0;
      left: 7px;
      top: 0;
      width: 8px;
      height: 15px;
      border: solid @sw-color-primary;
      border: solid var(--sw-color-primary, @sw-color-primary);
      border-width: 0px 2px 2px 0;
      -webkit-transform: rotate(40deg);
      -ms-transform: rotate(45deg);
      transform: rotate(40deg);
      background: @sw-color-grey-lightest;
    }

    input {
      &:checked ~ .icon:before {
        color: @sw-color-primary;
        color: var(--sw-color-primary, @sw-color-primary);
        display: block;
      }
    }

    &:focus-within {
      outline: @focus-style;
      outline-offset: @focus-offset;
    }
  }

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: @sw-color-text-dark;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: @sw-color-text-dark;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: @sw-color-text-dark;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: @sw-color-text-dark;
  }
}
