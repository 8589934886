﻿.documentbasketspot-a {
  margin-top: 0;

  @media(min-width: @screen-sm) {
    margin-top: 20px;
  }

  &__link {
    .font-size(1.5);
    color: @color-menu-level1;
    padding: 0 0 15px 20px;
    display: block;

    &:before {
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_basket:before);
      display: inline-block;
      color: @color-primary-function;
      font-size: 34px;
      position: relative;
      top: 5px;
      padding-right: 10px;
    }
  }
}
