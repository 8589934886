﻿@import "../styles.less";
@import "../Variables/_colors.less";
@import "../Variables/_typography.less";

.committeemembers {
  &__info {
    padding: 0 38px;
    margin-bottom: 20px;
  }

  &__list {
    &__title {
      vertical-align: middle;
      width: 100%;
      text-align: left;
      margin: 0 0 15px 0;
      padding-left: 23px;

      @media (max-width: @screen-sm) {
        padding-left: 0;
      }
    }
  }
}
