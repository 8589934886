﻿.ftDocumentBasket {
  .make-row();
  margin: 0 0 30px;

  &__container {
    .make-sm-column(12);
    margin: 0;
    //overwriting listespot-wrapper
    .listespot-wrapper.document-basket-list {
      table {
        tbody tr {
          td {
            border-right: 0;

            a {
              color: @color-brand-200;

              &:hover {
                text-decoration: underline;
              }
            }
          }

          &:hover {
            background-color: #eeeeee !important;

            td {
              background-color: #eeeeee !important;
            }
          }

          th {
            font-family: SceneAlt, serif;
            font-weight: normal;
            font-size: 1.2rem;
            color: @color-primary-dark;
            text-align: left;
          }
        }
      }
    }

    &__basket {
      tbody tr {
        @media (max-width: @container-small-desktop) {
          padding: 20px 20px 10px 38px;
          background-color: #ffffff;

          &:first-of-type {
            display: none;
          }
        }

        .custom-checkbox {
          @media (max-width: @container-small-desktop) {
            display: inherit !important;
          }
        }

        td {
          @media (max-width: @container-small-desktop) {
            margin-bottom: 12px;
          }
        }
      }
    }

    .listespot-wrapper.document-basket-multiple {
      margin-top: 25px;

      .first-level-basket {
        h2 {
          padding-left: 38px;
          margin-top: 0;
          font-family: SceneAlt, serif;
          font-weight: normal;
          font-size: 2.5rem;
          color: @color-primary-dark;
        }

        tr:nth-child(odd) {
          background-color: transparent !important;
          i:after {
            border-color: #f7f7f7;
          }
        }

        tr {
          &:first-of-type {
            display: none;
          }

          background-color: transparent;
        }

        td {
          padding: 0;
          background-color: transparent !important;

          &:hover {
            background-color: #e8eaea !important;
          }
        }

        .first-level-links {
          margin-left: 38px;
          margin-bottom: 25px;
          position: relative;

          .checkbox-label {
            position: absolute;
            left: 32px;
            top: 3px;
            color: @color-primary-darker;
            .font-size(1.6);
            font-family: SceneAlt, serif;
            font-weight: normal;
          }

          a {
            color: @color-brand-200;
            font-size: 16px;

            @media (min-width: @container-small-desktop) {
              margin-bottom: 10px;
              display: inline-block;
            }

            &:hover {
              text-decoration: underline;
            }
          }

          .first-level-linklist {
            margin-bottom: 10px;
          }
        }
      }

      .second-level-basket {
        background-color: #f7f7f7;
        margin-bottom: 50px;

        tr {
          background: #fff;

          &:first-of-type {
            display: table-row;
            font-family: SceneAlt, serif;
            font-weight: normal;
            font-size: 1.2rem;
            color: @color-primary-dark;
          }

          th {
            font-weight: normal;
          }

          @media (max-width: @container-small-desktop) {
            padding: 20px 20px 10px 38px;

            &:first-of-type {
              display: none;
            }
          }

          &:hover {
            background-color: #eeeeee !important;
            i:after {
              border-color: #eeeeee !important;
            }
          }
        }

        td {
          padding: 10px 38px;
          border-right: 0;

          @media (max-width: @container-small-desktop) {
            margin-bottom: 12px;
          }

          &:hover {
            background-color: transparent !important;
          }

          a {
            color: @color-brand-200;
            font-size: 14px;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    h1 {
      padding: 0 23px;
    }

    &__paddindBox {
      padding: 0 23px;

      .checkbox-label {
        font-size: 16px !important;
        font-family: SceneAlt, serif;
      }

      .manchet {
        padding-left: 0;

        strong {
          font-weight: normal;
        }
      }

      .abstract {
        margin-bottom: 50px;
      }

      .simple {
        list-style: none;
        margin: 17px 0 0;
        padding: 0;

        div {
          display: inline-block;
          margin-right: 10px;
          width: 100%;

          .checkbox-label {
            position: relative;
            top: 2px;
            left: 5px;
            .font-size(1.4);
          }
        }
      }

      &--bottom {
        .checkbox-label {
          font-size: 16px !important;
          font-family: SceneAlt, serif;
        }

        .simple {
          list-style: none;
          margin: 20px 0 0;
          padding: 0;
          margin-top: 0;

          div {
            display: inline-block;
            margin-right: 10px;
            margin-bottom: 10px;
          }
        }
      }
    }

    &__docBtns {
      padding: 0 23px;
      margin-bottom: 15px;

      &__docWork {
        color: @color-primary-function-bg;
        background-color: #fff;
        border-radius: 50%;
        width: 43px;
        height: 43px;
        line-height: 43px;
        display: inline-block;
        position: relative;
        text-align: center;
        margin-right: 15px;
        font-size: 0;

        &:hover {
          text-decoration: none;
          background-color: @color-primary-function-bg;
          color: #fff;
        }

        span {
          display: inline-block;
          overflow: hidden;
          text-indent: -10000px;
          width: 0;
        }

        i {
          font-size: 28px;
          position: relative;
          top: 2px;

          &.icon-ft_mail,
          &.icon-ft_mail-problem {
            font-size: 20px;
          }
        }
      }
    }

    &__pnlGeneric {
      padding: 0 23px;
      margin-bottom: 15px;

      h2 {
        font-family: SceneAlt, serif;
        font-weight: normal;
        font-size: 2.5rem;
        color: @color-primary-dark;
        margin-top: 0;
      }

      .checkbox-label {
        position: relative;
        top: 2px;
        left: 5px;
        .font-size(1.4);
      }

      .ftDocumentBasket__container__paddindBox {
        padding: 0;
      }
    }

    &__basket {
      margin: 10px 0 0;
      width: 100%;

      th,
      td {
        padding-left: 10px;
        text-align: left;
        vertical-align: middle;

        &:first-child {
          text-align: center;
        }

        .custom-checkbox {
          float: none;
          border: 1px solid #7F7F7F;
        }
      }

      &__status {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          display: inline-block;
          margin-left: 10px;

          &:first-child {
            margin-left: 0;
          }

          i {
            font-size: 28px;
            position: relative;
            top: 4px;
            color: #d3d3d3;

            &.icon-ft_mail,
            &.icon-ft_mail-problem {
              font-size: 22px;
            }

            &.active {
              color: @color-primary-function;
              &:after {
                background-color: @color-primary-function !important;
              }
            }
          }
        }
      }
    }
  }
}
