﻿.filter {
    &.text-spot-filter {
        background-color: transparent;
        padding: 0;

        .filter-title {
            margin-bottom: 10px;
        }

        .filter-tag {
            margin-right: 0;
        }
    }

    background-color: @color-brand-info-700;
    padding: 20px;
    margin: 20px 0;

    &.filter-adjacent {
        margin: 2px 0;
    }

    .filter-title {
        .font-bold();
        .font-size(@type-size-md);
        margin-bottom: 15px;
    }

    .filter-tag {
        display: inline-block;
        margin: 0 5px 5px 0;

        &.filter-tag-all {
            margin-right: 40px;

            .btn-taxonomy {
                > span:first-child {
                    .font-bold();
                }
            }
        }
    }

    .filter-show-more,
    .filter-show-less {
        &:extend(a.link-with-arrow);

        &:hover {
            text-decoration: none;
        }

        > span {
            .font-size(@type-size-xs);
            margin-left: 5px;
        }
    }
}

.topicspot-d {
    .make-row();
    margin: 0 0 30px;

    &__container {
        .make-sm-column(12);
        margin: 0 23px;

        .filter-tags {
            margin-left: -38px;
            margin-top: 20px;
            margin-bottom: 20px;

            .filter-tag {
                display: inline-block;
                margin: 0 5px 5px 0;
            }
        }

        .filter-title {
            margin-left: -38px;
            margin-bottom: 10px;
            font-family: Asap;
            font-weight: 700;
            font-style: normal;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            font-size: 13px;
            font-size: 1.3rem;
            line-height: 15.6px;
            line-height: 1.56rem;
        }
    }
}
