/*------------------------------------*\
		member-documents
    Created by kvr on 08-06-2016
\*------------------------------------*/

.member-documents {
  padding: 35px;
  background: white;
}

.member-documents__category {
  margin-top: 40px;

  &:first-child {
    margin-top: 0;
  }
}

.member-documents__category-title {
  .font-alt();
}

.member-documents__list {
  padding-left: 0;
}

.member-documents__item {
  margin-top: 10px;
  list-style-type: none;

  &:first-child {
    margin-top: 0;
  }

  a {
    &:before {
      display: inline-block;
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);

      position: relative;
      top: -1px;

      font-size: 11px;
    }
  }
}