.subsite-wide {
  .card {
    display: flex;
    flex-direction: column;
    position: relative;
    color: @sw-color-text-dark !important;
    height: 100%;
    min-height: 425px;

    @media (min-width: @screen-md-min) {
      min-height: 390px;
    }

    .card__header {
      display: flex;
      align-items: center;
      margin: 10px 0;
    }
    .card__title {
      margin-top: 0 !important;
      margin-bottom: 3px !important;
    }

    .card__content {
      position: relative;
    }

    &__mobile-image {
      display: none;
    }
  }
}
