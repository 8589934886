﻿.header-tabs-wrapper {
  position: relative;
  padding-bottom: 0;
  margin-top: -30px !important;
  background-color: transparent !important;

  @media only screen and (min-width: @screen-md) {
    margin-top: -50px !important;
    margin-bottom: 50px !important;
  }

  .panel-middle {
    border: 0;
    overflow: visible;
    /*display: table-row;*/
  }

  .panel-heading {
    @media only screen and (min-width: @screen-md) {
      background-color: transparent;
    }
  }

  &.is-open {
    .result-list-spot-accordion__heading {
      @media only screen and (min-width: @screen-md) {
        margin-top: -50px;
        background-color: transparent;
      }
    }
  }

  .result-list-spot-accordion {
    &__heading {
      position: absolute;
      bottom: 16px;
      width: 100%;

      @media only screen and (min-width: @screen-md) {
        bottom: inherit;
      }
    }
  }
}

.mobile-nav {
  display: block;

  @media only screen and (min-width: @screen-md) {
    display: none !important;
  }

  .header-tabs__trigger a.header-tabs__trigger-link--open {
    display: none;
  }
}

.desktop-nav {
  display: none;

  @media only screen and (min-width: @screen-md) {
    display: block !important;
  }
}

.header-tabs {
  width: 100%;
  position: relative;

  &__trigger-link--close {
    @media only screen and (min-width: @screen-md) {
      display: none !important;
    }
  }

  &__trigger {
    overflow: hidden;
    width: 100%;
    display: table;
    margin: 0 auto;
    background-color: transparent !important;
    text-align: center;
    padding-left: 0 !important;

    &:hover {
      @media only screen and (min-width: @screen-md) {
        text-align: left;
        position: static;
      }
    }

    @media only screen and (min-width: @screen-md) {
      text-align: left;
      position: static;
      background-color: transparent !important;
    }
  }

  &__trigger-link {
    font-family: SceneAlt, serif !important;
    font-size: 1.6rem !important;
    line-height: 2.5rem !important;
    text-align: center;
    color: white !important;
    padding: 9px 38px;
    width: 100% !important;
    border: 0;
    min-height: 44px !important;
    height: 44px !important;
    background-color: @color-primary-function-bg;

    .mobile-nav & {
      background-color: white;
      color: @color-primary-function !important;

      &:hover {
        background-color: transparent;
      }
    }

    &--btn {
      width: auto !important;
      float: none !important;
      display: table !important;
      margin: 0 auto;
    }

    &:hover {
      text-decoration: none;
      background-color: @color-primary-function;
    }

    &:after {
      display: none !important;
    }

    &.is-intercept-search {
      background-color: transparent;
      line-height: inherit !important;
      padding: 0;
    }

    @media only screen and (min-width: @screen-md) {
      width: auto !important;
      display: inline-block;
      top: inherit;
      font-size: 1.8rem !important;
    }
  }

  &__trigger-link--close {
    display: none;

    .header-tabs__trigger-icon:before {
      display: none;
    }
  }

  &__trigger-icon {
    transform: scaleX(-1);
    display: inline-block;
    margin-right: 5px;
    width: auto !important;

    &:before {
      font-family: "ft-icons";
      &:extend(.icon-ft_search:before);
    }
  }

  &__trigger-text {
    width: auto !important;
    overflow: visible !important;
  }

  &__trigger__close-desktop {
    display: none;

    @media only screen and (min-width: @screen-md) {
      display: block;
      float: right;
      font-size: 15px;

      &:hover {
        text-decoration: none;
      }
    }

    .header-tabs__trigger-icon {
      &:before {
        display: none;
      }
    }
  }

  &__trigger__close-desktop-icon {
    margin-left: 5px;
  }

  &__nav {
    background: white;
  }

  &__nav-tabs {
    padding: 20px 0px 0 38px !important;
    background: @color-secondary-light;

    li {
      float: left;
    }

    li a {
      padding: 10px;
      color: #fff;
      font-size: 16px;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      display: block;

      @media only screen and (min-width: @screen-md) {
        font-size: 15px;
      }

      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
  }
  /**************************
     Importet elements
  **************************/
  .validation {
    display: inline-block;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1.92rem;
    color: @color-primary-function;
    margin-top: 10px;
    margin-bottom: 0;
  }

  .tab-spot-a {
    &__tabs {
      display: flex;
      overflow: auto;
    }

    &__tabs__item {
      width: auto;
      height: auto !important;
      width: auto !important;
      margin-right: 25px;
      background-color: transparent;
      white-space: nowrap;
      flex-grow: 1;

      @media only screen and (min-width: @screen-md) {
        min-width: 100px;
      }

      &.active {
        background-color: @color-secondary-light !important;
        border-bottom: 4px solid @color-primary-function-bg;

        a {
          color: @color-primary-function !important;

          &:after {
            display: none;

            @media only screen and (min-width: @screen-md) {
              display: inline-block;
              width: 8px;
              content: "";
            }
          }
        }
      }

      &__link {
        display: table !important;
        margin: 0 auto !important;
        font-size: 15px !important;
        background-color: @color-secondary-light !important;
        line-height: 18px;
        color: @color-primary-dark !important;
        line-height: initial;
        text-shadow: none;
        width: auto !important;

        &:after {
          display: inline-block;
          width: 8px;
          font-size: 11px !important;
          float: none;
          margin: 6px 0 0 8px !important;
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_bullit_right:before);

          @media only screen and (min-width: @screen-sm) {
            float: right;
          }
        }

        &:hover {
          color: @color-primary-function !important;

          &:after {
            color: @color-primary-function !important;
          }
        }
      }
    }

    &__wrapper {
      margin: 0;
    }

    &__content {
      .tab-spot-a__tabs {
        display: none !important;
      }

      .ankiro {
        margin-top: 40px;
      }

      .ankiro-searchform-header-title h1 {
        font-family: SceneAlt, serif;
        font-weight: 400;
        font-style: normal;
        font-size: 25px;
        line-height: 1.2;
        margin: 0 0 5px 0;
        padding: 0;
      }

      .btn-search {
        &:before {
        }
      }
    }

    &__content__pane {
      //overflow: hidden;
      position: relative;

      .chosen-container-multi.chosen-with-drop
        .search-field-selection
        input[type="text"].search-input {
        background-color: #fff !important;
      }

      .chosen-container-multi.chosen-with-drop
        .search-field-selection
        input[type="text"].form-control {
        background-color: #f0f2f2 !important;
      }

      &.active {
        padding: 38px;
        height: auto;
      }
    }
  }
  //Button{
  div.submit-button {
    display: table;
    margin: 30px auto !important;
    width: 100px;

    @media only screen and (min-width: @screen-md) {
      position: relative;
      float: right;
      width: 11%;
      top: 11px;
      margin: 0 !important;
    }

    &__inner {
      font-family: SceneAlt, serif;
      font-size: 1.8rem;
      line-height: 2.16rem;
      color: #fff;
      width: 100%;
      padding: 9px;
      border: 0;
      background-color: @color-brand-300;

      &:hover {
        background-color: @color-primary-function-hover;
      }
    }
  }
  //Guide link
  .guide-link {
    position: absolute;
    right: 28px;
    z-index: 2;

    &:hover {
      text-decoration: none;
    }

    .icon-spoergsmaal:before {
      content: "\e648";
      display: inline-block;
      color: @color-primary-function;
      font-family: "ft-icons";
      font-size: 16px;
      position: relative;
      top: -1px;
      padding-right: 10px;
    }
    /*&:before {
      content: "\e631";
      display: inline-block;
      color: @color-primary-function;
      font-family: "ft-icons";
      font-size: 11px;
      position: relative;
      top: -1px;
      padding-right: 10px;
    }*/
    @media only screen and (min-width: @screen-md) {
      right: 11.3%;
      top: 40px;
      margin-bottom: 10px;
    }
  }
  //Kvivk lookup
  .quick-lookup {
    .document-type .chosen-search {
      position: absolute;
      left: -99999999px;
    }

    .submit-button {
      display: table;
      margin: 0 auto;
      width: 100px;

      @media only screen and (min-width: @screen-md) {
        float: right;
        width: 11%;
        top: 11px;
        position: relative;
      }

      &__inner {
        font-family: SceneAlt, serif;
        font-size: 1.8rem;
        line-height: 2.16rem;
        color: #fff;
        width: 100%;
        padding: 9px;
        border: 0;
        background-color: @color-brand-300;

        &:hover {
          background-color: @color-primary-function-hover;
        }
      }
    }

    &__label {
      font-size: 14px;

      @media only screen and (min-width: @screen-md) {
        margin: 5px 0;
      }
    }

    &__form {
      width: 100%;
      min-height: 40px;
      margin-bottom: 20px;

      @media only screen and (min-width: @screen-md) {
        float: left;
        width: 23%;
        margin-right: 1.65%;
        margin-bottom: 0;

        &--wide {
          width: 30%;
        }
      }

      input,
      select {
        padding: 10px;
        min-height: 40px;
      }
    }
  }
  //Search
  .search-field {
    .btn-search {
      color: @color-primary-function;
      background: transparent;
      border: none;

      .icon-ft_search {
        display: block !important;

        &:before {
          &:extend(.icon-ft_search:before);
        }
      }
    }
  }
  //Law
  .law {
    &__label {
      font-size: 14px;

      @media only screen and (min-width: @screen-md) {
        margin: 5px 0;
      }
    }

    .submit-button {
      display: table;
      margin: 0 auto;
      width: 100px;

      @media only screen and (min-width: @screen-md) {
        float: right;
        width: 11%;
        top: 11px;
        position: relative;
      }

      &__inner {
        font-family: SceneAlt, serif;
        font-size: 1.8rem;
        line-height: 2.16rem;
        color: #fff;
        width: 100%;
        padding: 9px;
        border: 0;
        background-color: @color-brand-300;

        &:hover {
          background-color: @color-primary-function-hover;
        }
      }
    }

    &__form {
      width: 100%;
      min-height: 40px;
      margin-bottom: 20px;

      @media only screen and (min-width: @screen-md) {
        float: left;
        width: 23%;
        margin-right: 1.65%;
        margin-bottom: 0;

        &--wide {
          width: 42.8%;
        }
      }

      input,
      select {
        padding: 10px;
        min-height: 40px;
      }
    }
  }
  //Page reference
  .page-reference {
    .page-reference-ebook .chosen-search {
      position: absolute;
      left: -99999999px;
    }

    .submit-button {
      display: table;
      margin: 0 auto;
      width: 100px;

      @media only screen and (min-width: @screen-md) {
        float: right;
        width: 11%;
        top: 11px;
        position: relative;
      }

      &__inner {
        font-family: SceneAlt, serif;
        font-size: 1.8rem;
        line-height: 2.16rem;
        color: #fff;
        width: 100%;
        padding: 9px;
        border: 0;
        background-color: @color-brand-300;

        &:hover {
          background-color: @color-primary-function-hover;
        }
      }
    }

    &__label {
      font-size: 14px;

      @media only screen and (min-width: @screen-md) {
        margin: 5px 0;
      }
    }

    &__form {
      width: 100%;
      min-height: 40px;
      margin-bottom: 20px;

      .chosen-container-single .chosen-search input[type="text"] {
        background-color: white !important;
      }

      @media only screen and (min-width: @screen-md) {
        float: left;
        width: 23%;
        margin-right: 1.65%;
        margin-bottom: 0;

        &--wide {
          width: 30%;
        }
      }

      input,
      select {
        padding: 10px;
        min-height: 40px;
      }

      input.content-action--column-text {
        background-color: #f0f2f2 !important;
      }
    }
  }

  .dokumenter-columns {
    .ankiro {
      margin: 0 !important;

      .ankiro-searchform {
        margin: 0 !important;
      }

      .ankiro-searchform .pull-left {
        left: 13px !important;

        @media only screen and (min-width: @screen-md) {
          left: -9px !important;
        }
      }

      .ankiro-searchform-advanced {
        @media only screen and (max-width: @screen-xs) {
          position: relative;
          left: 13px;
        }
      }

      .ankiro-searchform-header-title {
        padding-bottom: 9px;
      }

      div.ankiro-searchform-form .full-width {
        @media only screen and (min-width: @screen-md) {
          padding-bottom: 0 !important;
        }
      }
    }

    .header-tabs__trigger__close-desktop {
      @media only screen and (min-width: @screen-md) {
        //margin-bottom: 13px;
      }
    }

    .ankiro-searchform-header-functions {
      @media only screen and (min-width: @screen-md) {
        padding-right: 70px !important;
        padding-bottom: 15px !important;
      }
    }

    .submit-button {
      display: table;
      margin: 0 auto;
      width: 100px;

      @media only screen and (min-width: @screen-md) {
        float: right;
        width: 11%;
        top: 11px;
        position: relative;
      }

      &__inner {
        font-family: SceneAlt, serif;
        font-size: 1.8rem;
        line-height: 2.16rem;
        color: #fff;
        width: 100%;
        padding: 9px;
        border: 0;
        background-color: @color-brand-300;

        &:hover {
          background-color: @color-primary-function-hover;
        }
      }
    }
  }
}

.e-book-list-session-lookup {
  padding: 30px 38px 15px 38px;
  background: #f6f6f5;

  .form-group {
    @media only screen and (min-width: @screen-md) {
      width: 50%;
    }
  }
}

.ebook .page-reference-ebook .chosen-search {
  position: absolute;
  left: -99999999px;
}
