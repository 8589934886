/*
 * Document
 */
#share {
  color: #fff;
  font: 11px/13px sans-serif;
  margin: 0;
  width: 791px;
  display: none;
}

#share a {
  color: #fff;
  text-decoration: none;
}

#share p {
  margin: 0 0 12px 0;
}


/*
 * Helpers
 */
#share .hidden {
  display: none;
}

#share .invisible {
  visibility: hidden;
}

/*
 * Title
 */
#share .title {
  border: 1px solid #747372;
  margin-bottom: 1px;
}

#share .title h1 {
  border: 1px solid #494947;
}

#share h1 {
  font-size: 15px;
  font-weight: normal;
  border: 1px solid #4a4a49;
  color: #fff;
  margin: 0;
  background: #414141 url(/dist/images/global/legacy/share/images/title.png) repeat-x 0 100%;
  padding: 0 14px;
  line-height: 42px;
}

#share h2 {
  font-size: 12px;
  font-weight: normal;
  margin: 0 0 12px 0;
}

#share h3 {
  font-size: 11px;
  font-weight: normal;
  margin: 0 0 20px 0;
}

/*
 * Back
 */
#share .back {
  float: right;
  display: inline;
  padding: 0 46px 0 10px;
  line-height: 34px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  background: url(/dist/images/global/legacy/share/images/back.gif) no-repeat 100% 50%;
  position: relative;
  top: -6px;
}

/*
 * Button
 */
#share .s-button {
  float: left;
  border: 1px solid #282827;
  margin-right: 4px;
  display: inline;
  background: #434241 url(/dist/images/global/legacy/share/images/button.png) repeat-x 0 100%;
  font-size: 12px;
  text-shadow: 1px 1px 2px #000000;
  filter: dropshadow(color=#000000, offx=1, offy=1);
  outline: none;
}

#share .s-button-active {
  border-color: #fff;
}

#share .s-button span {
  border: 1px solid #4d4d4c;
  float: left;
  display: inline;
  line-height: 28px;
}

#share .s-button:hover {
  opacity: .5;
  filter: Alpha(opacity=50);
}

#share .s-button:active {
  border-color: #fff;
  opacity: 1;
  filter: Alpha(opacity=100);
}


/*
 * Options
 */
#share .options {
  display: inline-block;
  overflow: hidden;
  font-size: 12px;
  text-shadow: 1px 1px 1px #000000;
  position: relative;
  top: 1px;
  z-index: 10;
}

#share .options {
  display: block;
}

#share .options a {
  float: left;
  width: 127px;
  height: 32px;
  line-height: 32px;
  background: url(/dist/images/global/legacy/share/images/share-tab.png) no-repeat 0 0;
  cursor: pointer !important;
}

#share .options span {
  display: inline-block;
  padding-left: 30px;
  background-position: 9px 50%;
  background-repeat: no-repeat;
  opacity: .5;
  filter: Alpha(opacity=50);
}

#share .options span {
  display: block;
}

#share .options a.active {
  background: url(/dist/images/global/legacy/share/images/share-tab-active.png) no-repeat 0 0;
}

#share .options a.active span {
  opacity: 1;
  filter: Alpha(opacity=100);
}

#share .button-share span {
  background-image: url(/dist/images/global/legacy/share/images/tab-share.gif);
}

#share .button-rediger span {
  background-image: url(/dist/images/global/legacy/share/images/tab-rediger.gif);
}

#share .button-example span {
  background-image: url(/dist/images/global/legacy/share/images/tab-example.gif);
}

/* 
 * Tabs
 */
#share .tab {
  border: solid #fff;
  border-width: 0 1px 1px 1px;
  background: #494947 url(/dist/images/global/legacy/share/images/share-tab-border.gif) repeat-x 0 0;
  padding: 10px;
  height: 491px;
  display: inline-block;
  overflow: hidden;
}

#share .tab {
  display: block;
}

#share .tab-hidden {
  display: none;
}

/* 
 * Tab 1
 */
.share-column-left {
  float: left;
  width: 370px;
  position: relative;
}

.share-column-right {
  float: right;
  width: 370px;
}

/*
 * Tab 2
 */
#share .embed-options {
  float: right;
  width: 158px;
  color: #9b9b9a;
  height: 338px;
  position: relative;
}

/* 
 * Tab 3
 */
#share #tab-example {
  padding: 0;
  position: relative;
  overflow: visible;
}

#tab-example .details {
  position: absolute;
  top: -32px;
  right: 5px;
  line-height: 34px;
  color: #aeaeae;
  font-size: 11px;
}

#tab-example .details strong {
  font-weight: bold;
}


/*
 * Main
 */
#share .main {
  border: 1px solid #7b7a79;
  background: #2b2b29 url(/dist/images/global/legacy/share/images/background.png) repeat-x 0 100%;
  position: relative;
}

#share .main-inner {
  border: 1px solid #4c4b4a;
  padding: 8px;
  display: inline-block;
  overflow: hidden;
}

#share .main-inner {
  display: block;
}

#share .column-left {
  float: left;
  width: 350px;
}

#share .column-right {
  float: right;
  width: 350px;
  overflow: hidden;
  background: #343432;
}

#share .embed-area {
  padding: 8px 13px 0 13px;
  min-height: 247px;
  _height: 247px;
}

/*
 * Size
 */
#share .size {
  padding: 8px;
  font-size: 11px;
  background: #343432;
  border-radius: 3px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 142px;
}

#share .size .title {
  border: 0;
  position: absolute;
  top: -20px;
  left: 0;
  line-height: 20px;
  overflow: hidden;
  color: #fff;
}

#share .size a {
  display: block;
  margin-bottom: 10px;
  width: 46px;
  height: 34px;
  background: url(/dist/images/global/legacy/share/images/size.png) no-repeat 0 0;
  position: relative;
  outline: none;
  color: #858584;
}

#share .size span {
  display: block;
  position: absolute;
  left: 55px;
  top: 0;
  width: 85px;
  line-height: 34px;
  text-align: left;
}

#share .size a.active, #share .size a:hover {
  background-position: 0 -34px;
  opacity: 1;
  color: #fff;
}


#share .size a.size-medium {
  background-position: -46px 0;
}

#share .size a.size-active-medium, #share .size a.size-medium:hover {
  background-position: -46px -34px;
}

#share .size a.size-large {
  background-position: -92px 0;
}

#share .size a.size-active-large, #share .size a.size-large:hover {
  background-position: -92px -34px;
}

#share .size a.size-custom {
  background-position: -138px 0;
  margin-bottom: 0;
}

#share .size a.size-active-custom, #share .size a.size-custom:hover {
  background-position: -138px -34px;
}

/* Dimensions */
#share .dimensions {
  position: absolute;
  right: 9px;
  bottom: 9px;
  margin-top: 3px;
  opacity: .4;
  filter: Alpha(opacity=40);
  width: 80px;
  text-align: right;
  font-size: 11px;
}

#share .dimensions-hover {
  opacity: .2;
  filter: Alpha(opacity=20);
}

#share .dimensions-active {
  opacity: 1;
  filter: Alpha(opacity=100);
}

#share .dimensions input {
  background: #fff;
  border: 1px solid #e8e8e8;
  width: 40px;
  height: 11px;
  padding: 0;
  font-size: 10px;
}

#share .dimensions label {
  float: left;
  width: 34px;
  text-align: right;
  line-height: 13px;
  padding-right: 4px;
}

#share .dimension {
  display: inline-block;
  margin-bottom: 2px;
}

#share .dimension {
  display: block;
}

#share .dimension:after {
  content: '.';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}


#embedEditVideoSliderArea {
  display: inline-block;
  clear: both;
  position: relative;
  background: #343432;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

#embedEditVideoSliderArea {
  display: block !important;
}

#embedEditVideoSliderArea .title {
  border: 0;
  position: absolute;
  top: -14px;
  line-height: 14px;
}

.video-length {
  text-align: center;
  color: #aeaeae;
  line-height: 22px;
}


/*
 * Embed Code
 */

/* Accept Terms */
#share .accept {
  font-size: 12px;
  margin: 5px 10px;
}

#share .accept input {
  margin-right: 5px;
}

/* Terms */
#share .terms {
  background: #343432;
  color: #9a9a98;
  padding: 6px 12px;
  position: relative;
  height: 453px;
  width: 346px;
  border-radius: 3px;
}

#share .terms a {
  color: #ffffff !important;
}

#share .terms a:hover {
  text-decoration: underline;
}

#share .terms-inner {
  height: 453px;
  overflow: auto;
}

#share .arrow {
  display: block;
  text-indent: -9999px;
  background: url(/dist/images/global/legacy/share/images/terms-arrows.gif) no-repeat 0 0;
  width: 12px;
  height: 10px;
  position: absolute;
  right: 10px;
  top: 10px;
  overflow: hidden;
}

#share #terms-down {
  top: auto;
  bottom: 10px;
  background-position: 0 -10px;
}

/*
 * Video Area
 */
#share .video-wrap {
  width: 587px;
  height: 358px;
  float: left;
  margin-bottom: 20px;
  text-align: center;
  background: url(/dist/images/global/legacy/share/images/video-background.png) no-repeat 0 0;
  padding-top: 8px;
  position: relative;
}
/*
.sharePlayerShow { position: relative; margin-left: 0; }
.sharePlayerHide { position: relative; margin-left: -999999; }
*/

/* Controls */
.controls {
  position: absolute;
  bottom: 8px;
  left: 240px;
  width: 152px;
  height: 17px;
}

.controls a, #ctrl-time {
  height: 17px;
  line-height: 17px;
  float: left;
  display: inline;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.controls a:hover {
  opacity: .5;
  filter: Alpha(opacity=50);
}

#ctrl-play {
  background-image: url(/dist/images/global/legacy/share/images/video-play.png);
  width: 11px;
  margin-right: 15px;
}

#ctrl-play.active {
  background-position: 0 -17px;
}

#ctrl-mute {
  background-image: url(/dist/images/global/legacy/share/images/video-mute.png);
  width: 25px;
}

#ctrl-mute.active {
  background-position: 0 -17px;
}

#ctrl-time {
  background: url(/dist/images/global/legacy/share/images/video-time.png) no-repeat 0 0;
  width: 67px;
  padding-left: 20px;
}

#ctrl-time span {
  background: #fff;
  color: #000;
  font-size: 11px;
  line-height: 14px;
  float: left;
  padding: 0 3px;
}

/*
 * Edit area
 */
#share .lower-right {
  position: relative;
  height: 135px;
  overflow: hidden;
}

/* Edit Intro */
#share .edit-start {
  background: #343432;
  padding: 11px 11px 0 11px;
  min-height: 124px;
  _height: 124px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

#share .edit-start h3 {
  background: url(/dist/images/global/legacy/share/images/icon-title-edit.png) no-repeat 0 50%;
  padding-left: 25px;
  line-height: 18px;
}

#share .edit-start p {
  color: #cacb97;
  font-style: italic;
  margin-bottom: 23px;
}

#share .button-edit {
  display: block;
  float: none;
  width: 110px;
  text-align: center;
  margin: 0 auto;
  border: 1px solid #000;
}

#share .button-edit span {
  float: none;
  display: block;
  line-height: 24px;
}

#share #linkEditVideoButtonArea h3 {
  background-image: url(/dist/images/global/legacy/share/images/icon-title-share.png);
}

/* Edit */
#share .edit {
  background: #252524;
  position: relative;
}

#share .s-buttons {
  padding: 9px 10px;
}

#share .s-buttons-last {
  border-top: 1px solid #000;
}

/*
 * Small Buttons
 */
#share .s-buttons {
  display: inline-block;
  overflow: hidden;
}

#share .s-buttons {
  display: block;
}

#share .button-small {
  width: 44px; /* 82 */
  text-align: center;
  text-transform: uppercase;
  border-color: #000;
}

#share .button-small span {
  float: none;
  display: block;
  line-height: 19px;
}

#share .button-medium {
  width: 82px;
  text-align: center;
  text-transform: uppercase;
  border-color: #000;
}

#share .button-medium span {
  float: none;
  display: block;
  line-height: 19px;
}

#share .button-left {
  float: left;
}

#share .button-right {
  float: right;
  margin: 0 0 0 4px;
}

#share .button-center {
  margin: 0 auto;
  float: none;
  display: block;
}

#share .button-green {
  color: #87c443;
}

#share .button-red {
  color: #e06762;
}

/*#share .button-background-white span { text-shadow: none; background: #fff; margin: 3px; line-height: 13px; }*/
#share .button-background-white {
  width: auto;
}

#share .button-background-white span {
  padding: 2px;
}

#share .button-background-white input {
  width: 55px;
  height: 15px;
  padding: 0 4px;
  border: 0;
  text-align: center;
}

#share .button-background-white:hover {
  opacity: 1;
  filter: Alpha(opacity=100);
}

/*
 * Slider
 */
#share .slider {
  padding: 0px 10px;
}

#share .ui-slider {
  position: relative;
}

#share .ui-slider-horizontal {
  background: #5d5d5c;
  height: 5px;
  margin: 5px 0 8px 0;
}

#share .ui-slider-handle {
  display: block;
  width: 4px;
  height: 13px;
  background: #e06762;
  position: absolute;
  top: -4px;
}

#share .first-slider {
  background: #87c443;
}

#share .ui-slider-range {
  position: absolute;
  top: 0;
  background: #aeaeae;
  height: 5px;
  font-size: 1px;
  line-height: 1px;
}

/* Slider Labels */
#share #embedInTimeSlideLabel {
  color: #87c443;
}

#share #embedOutTimeSlideLabel {
  color: #e06762;
  left: auto;
  right: 10px;
}

#share #linkInTimeSlideLabel {
  color: #87c443;
}

#share .slider-label {
  position: absolute;
  top: 5px;
  left: 10px;
}

/*
 * Share Area
 */

#share .share-area {
  width: 182px;
  height: 258px;
  margin: 117px auto 0;
  font-size: 12px;
  text-shadow: 1px 1px 1px #000000;
  filter: dropshadow(color=#000000, offx=1, offy=1);
}

#share .share-area a {
  display: inline-block;
  line-height: 42px;
  padding-left: 52px;
  margin-bottom: 12px;
  background: url(/dist/images/global/legacy/share/images/share-link.png) no-repeat 0 0;
  position: relative;
}

#share .share-area a {
  display: block;
}

#share .share-area a:hover {
  opacity: .5;
  filter: Alpha(opacity=50);
}

#share .share-area .disabled {
  opacity: .5;
  filter: Alpha(opacity=50);
  cursor: default;
}

#share .share-area .disabled span {
  background-position: -28px 0;
}

#share .share-area span {
  display: block;
  width: 28px;
  height: 28px;
  background: url(/dist/images/global/legacy/share/images/share-icons.png) no-repeat 0 0;
  _background-image: url(/dist/images/global/legacy/share/images/share-icons.gif);
  position: absolute;
  left: 7px;
  top: 50%;
  margin-top: -14px;
}

#share {
  .icon-facebook {
    &:before {
      content: "";
    }
  }

  .icon-twitter {
    &:before {
      content: "";
    }

    span {
      background-position: 0 -28px;
    }

    .disabled span {
      background-position: -28px -28px;
    }
  }

  .icon-copy {
    &:before {
      content: "";
    }

    span {
      background-position: 0 -56px;
    }

    .disabled span {
      background-position: -28px -56px;
    }
  }

  .icon-embed {
    &:before {
      content: "";
    }

    span {
      background-position: 0 -84px;
    }

    .disabled span {
      background-position: -28px -84px;
    }
  }

  .icon-email {
    &:before {
      content: "";
    }

    span {
      background-position: 0 -112px;
    }

    .disabled span {
      background-position: -28px -112px;
    }
  }
}




/*
 * Form sendLinkToFriendArea
 */
#share #sendLinkToFriendArea {
  overflow: hidden;
  margin: 0;
  position: absolute;
  left: 0;
  top: 24px;
  width: 346px;
  height: 453px;
  padding: 6px 12px;
  background: #343432;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

#share #sendLinkToFriendArea fieldset {
  border: 0;
  padding: 1px 0;
  margin: 0;
}


/*
 * Form copyCodeArea
 */
#share #copyCodeArea {
  overflow: hidden;
  margin: 0;
  position: absolute;
  left: 0;
  top: 24px;
  width: 346px;
  height: 453px;
  padding: 6px 12px;
  background: #343432;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

#share #copyCodeArea fieldset {
  border: 0;
  padding: 1px 0;
  margin: 0;
}

#share #copyCodeArea fieldset input {
  cursor: pointer;
}

#share #copyCodeArea fieldset textarea {
  cursor: pointer;
}

/*
 * Form
 */
#share input, textarea {
  font-family: sans-serif;
  font-size: 11px;
  margin: 0;
  color: #252525;
}

#share fieldset ol {
  margin: 0;
  padding: 10px 0 0 0;
}

#share fieldset ol li {
  list-style: none;
  margin-bottom: 3px;
  display: inline-block;
}

#share fieldset ol li {
  display: block;
}

#share fieldset ol li:after {
  content: '.';
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

#share fieldset ol li input, fieldset ol li textarea {
  border: 1px solid #000;
  width: 200px;
  padding: 5px;
  *padding: 4px 5px;
}

#share fieldset ol label {
  float: left;
  width: 105px;
  text-align: right;
  padding-right: 18px;
  line-height: 22px;
}

#share fieldset ol li span {
  float: right;
  width: 200px;
  line-height: 22px;
  margin-right: 18px;
}

#share fieldset ol li textarea {
  height: 275px;
  resize: none;
}

#share .alertError {
  border: 1px solid #ff0000;
  background-color: #ffaaaa;
}

/*
 * Preview
 */
#share .preview-area {
  background: #282827;
  position: absolute;
  z-index: 100;
  left: 1px;
  top: 1px;
  width: 736px;
  height: 460px;
  display: none;
}

#share .preview-wrap {
  width: 100%;
  height: 460px;
  margin: 0px;
  padding: 0px;
}

#share .preview-wrap td {
  vertical-align: middle;
  text-align: center;
}

#share .preview-close {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: url(/dist/images/global/legacy/share/images/fancy-close.png) no-repeat 0 0;
  cursor: pointer;
  z-index: 1000;
}
/* #share .embeddedPlayerPreviewAreaPlaceHolder { width: 100%; height: 100%; text-align: center;}
#share .embeddedPlayerPreviewAreaPlaceHolder #embeddedPlayerPreviewArea { margin: auto; vertical-align: middle; display: block;} */

/*
 * Custom Buttons
 */
.custom-buttons {
  display: inline-block;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  padding: 0 10px 10px 10px;
}

.custom-buttons {
  display: block;
}

.custom-buttons a {
  height: 22px;
  line-height: 22px;
}

.custom-buttons a:hover {
  opacity: .5;
  filter: Alpha(opacity=50);
}

.del-button {
  float: left;
  background: url(/dist/images/global/legacy/share/images/share-rediger-del.png) no-repeat 0 0;
  width: 106px;
  padding-left: 5px;
}

.eksampel-button {
  float: right;
  background: url(/dist/images/global/legacy/share/images/share-rediger-eksempel.png) no-repeat 0 0;
  //width: 92px;
  padding-left: 20px;
}

/*
 * jScrollBar
 */
#share .jspHorizontalBar {
  display: none;
}

#share .jspContainer {
  overflow: hidden;
  position: relative;
}

#share .jspPane {
  position: absolute;
  padding-right: 20px;
  width: 312px !important;
}

#share .jspVerticalBar {
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 100%;
  border-left: 1px solid #484846;
  padding-left: 10px;
}

#share .jspHorizontalBar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 16px;
  background: red;
}

#share .jspVerticalBar *, #share .jspHorizontalBar * {
  margin: 0;
  padding: 0;
}

#share .jspCap {
  display: none;
}

#share .jspHorizontalBar .jspCap {
  float: left;
}

#share .jspTrack {
  background: none;
  position: relative;
}

#share .jspDrag {
  background: #686868;
  position: relative;
  top: 0;
  left: 0;
  cursor: pointer;
  display: block;
}

#share .jspHorizontalBar .jspTrack, #share .jspHorizontalBar .jspDrag {
  float: left;
  height: 100%;
}

#share .jspArrow {
  background: url(/dist/images/global/legacy/share/images/terms-arrows.gif) no-repeat 0 0;
  text-indent: -20000px;
  display: block;
  cursor: pointer;
}

#share .jspArrowDown {
  background-position: 0 -10px;
}

#share .jspVerticalBar .jspArrow {
  height: 10px;
  font-size: 1px;
  line-height: 1px;
}

#share .jspHorizontalBar .jspArrow {
  width: 16px;
  float: left;
  height: 100%;
}

#share .jspVerticalBar .jspArrow:focus {
  outline: none;
}

#share .jspCorner {
  background: #eeeef4;
  float: left;
  height: 100%;
}

* html .jspCorner {
  margin: 0 -3px 0 0;
}
