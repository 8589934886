﻿@import "../styles.less";

.tingdok-info {
    margin: 0 0 20px 0;
}

.link-html {
    white-space: nowrap;
}

.tingdok-pagination {
    margin-top: 20px;

    &.top {
        margin-top: 0;
        margin-bottom: 20px;
    }
}

.tingdok-list {
    margin-bottom: 20px;
}

.tingdok-answer {
    border: 1px solid @color-contrast-300;
    border-width: 1px 0 0 0;
    padding: 20px 0 0 0;
}

//General tingdok- prefix styles
.tingdok {
    .tingdok-h1 {
        margin-top: 20px;
        .font-size(3);
        font-family: inherit;
        font-weight: 400;
        //line-height: 1.1;
        color: @color-text-dark;
        display: block;

        &.text-right {
            text-align: right;
        }
    }

    .tingdok-h2 {
        margin-top: 20px;
        .font-size(2.0);
        font-family: inherit;
        font-weight: 500;
        //line-height: 1.1;
        color: inherit;
        display: block;
    }

    .tingdok-h3 {
        margin-top: 20px;
        .font-size(1.8);        
        font-family: inherit;
        font-weight: 500;
        //line-height: 1.1;
        color: inherit;
        display: block;
    }

    .tingdok-h4 {
        .font-size(1.6);
        font-family: inherit;
        font-weight: 500;
        //line-height: 1.1;
        margin-top: 10px;
        color: inherit;
        display: block;
    }

    .tingdok-h5 {
        .font-size(1.3);
        margin-top: 10px;
        font-family: inherit;
        font-weight: 500;
        //line-height: 1.1;
        color: inherit;
        display: block;
    }

    .tingdok-h6 {
        .font-size(1.2);        
        margin-top: 10px;
        font-family: inherit;
        font-weight: 500;
        //line-height: 1.1;
        color: inherit;
        display: block;
    }

    .tingdok-manchet {
        padding-left: 23px;
        padding-right: 23px;
        line-height: 25px;
        padding: 0 38px;
        .font-size(2.0);
        .font-alt();
        color: @color-text-dark;
    }

    .tingdok-moduloverskrift {
        .font-alt();
        .font-size(2.5);
        color: @color-text-dark;
    }

    .case-info-configurable-spot .tingdok-manchet {
        padding: 0;
    }

    .tingdok-normal {
        .font-size(2.0);
        margin-bottom: 3px;
    }

    .tingdok-bold {
        .font-size(1.4);
        font-weight: 700;
    }

    .tingdok-italic {
        font-style: italic;
    }

    .tingdok-frontarrow {
        display: inline-block;

        &:after {
            display: inline-block;
            &:extend(.less-icon-extend);
            &:extend(.icon-ft_bullit_right:before);
            font-size: 11px;           
            position: relative;           
            margin-left: 5px;
        }
    }

    .tingdok-backarrow {
        display: inline-block;
        //margin-left: 12px;
        &:before {
            display: inline-block;
            &:extend(.less-icon-extend);
            &:extend(.icon-ft_bullit_right:before);
            font-size: 11px;          
            position: relative;           
            margin-right: 5px;
        }
    }

    .tingdok-noarrow {
        &:after {
            display: none;
        }
    }

    .tingdok-space {
        border: none;
        .font-size(1.4);

        &:before {
            content: "\00a0";
        }
    }

    .tingdok-superscript {
        .font-size(1.05);
        position: relative;
        top: -5.25px;
    }

    .tingdok-subscript {
        .font-size(1.05);
        position: relative;
        bottom: -2.625px;
    }

    .tingdok-divider {
        border-top: 1px solid @color-tertiary-result-absent;
        margin-top: 1.4rem;
        padding-bottom: 1.4rem;
        margin-bottom: 0;
    }

    .tingdok-indent {
        text-indent: -20px;
        margin-left: 20px;
    }

    .case-info-configurable-spot .tingdok-divider {
        border-top-color: @color-tablecell-divider;
    }

    .download {
        margin-top: 30px;
        margin-bottom: 30px;
        &__container {
            .make-row();
            margin: 0;
            padding: 0 23px;

            &__meta-title {
                padding: 0 0 20px 17px;
            }

            &__meta-info {
                float: right;

                span {
                    display: block;
                }
            }

            &__docBtns {
                /*.make-sm-column(8);
        .make-sm-column-push(4);*/
                padding: 0 23px;
                margin-left: -7px;
                float: left;

                &__btn {
                    margin-left: 10px;
                    text-decoration: none !important;

                    &:first-child {
                        margin-left: 0;
                    }

                    @media only screen and (max-width: @screen-sm-max) {
                        margin-left: 0;
                        width: 100%;
                        display: block;
                        margin-bottom: 15px;
                    }

                    &:hover {
                        [class*="__docWork"] {
                            background-color: @color-primary-function-bg;
                            color: #fff;
                        }
                    }

                    &__docWork {
                        color: @color-primary-function-bg;
                        background-color: #fff;
                        border-radius: 50%;
                        width: 43px;
                        height: 43px;
                        display: inline-block;
                        position: relative;
                        text-align: center;

                        i {
                            font-size: 28px;
                            position: relative;
                            top: 7px;

                            &:before {
                                display: inline-block;
                            }
                        }
                    }

                    &__text {
                        display: inline-block;
                        color: @color-text;
                        margin-left: 10px;
                        .font-alt();
                        .font-size(1.5);
                    }
                }
            }
        }
    }


    &__committee-agenda {
        &__list {
            margin: 0;
            padding: 0 0 0 2px;

            .tingdok__documentdownload__title {
                color: @color-tablecell-divider;
            }

            .tingdok__documentdownload {
                background-color: @color-tablecell-divider;
                margin-bottom: 10px;
            }

            &__item {
                margin-bottom: 20px;
            }
        }
    }
}
