﻿@import "../Global/layout.less";

/*html, body, form, .snap-content {
  width: 100%;
  height: 100%;
}*/
html {
  background-color: @color-secondary-light;
}

body {
  .font-size(1.6);
}

.middle-row {
  height: 100%;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-padding-right {
  padding-right: 0;
}

.no-padding-right-sm {
  @media (min-width: @screen-sm-min) {
    padding-right: 0;
  }
}

.no-padding-right-xs {
  @media (max-width: @screen-sm) {
    padding-right: 0;
  }
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-left-sm {
  @media (min-width: @screen-sm-min) {
    padding-left: 0;
  }
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-right {
  margin-right: 0;
}

.spacing-vertical {
  margin-top: 20px;
  margin-bottom: 20px;
}

.page {
  margin: 0 auto;
  max-width: 1370px;
}

.main-wrapper {
  background-color: rgb(222, 222, 222);

  > .container {
    background-color: #e8eaea;
    height: 100%;
  }
}

.fake-border-background {
  padding-top: 30px;
}

.form-control {
  box-shadow: 0 0 0 0;
  border: 1px solid #f0f2f2;
}

.put-margin-left {
  margin-left: 38px;
}

.put-margin-right {
  margin-right: 38px;
}

.content-area,
.tingdok,
.content-area-main {
  > .row {
    margin-left: 0;
    margin-right: 0;
  }
}

.hide-text {
  display: inline-block;
  overflow: hidden;
  text-indent: -90000px;
  width: 0;
}

.is-hidden {
  display: none;
}

.content-area {
  margin-bottom: 30px;

  .wrapper .container .middle-row & {
    margin-bottom: 0;
  }

  @media only screen and (min-width: 769px) {
    padding-top: 50px;
  }

  padding-left: 0;
  padding-right: 0;

  .manchet + div > a {
    color: @link-secondary-color;
  }
  
  padding-left: 0;
  padding-right: 0;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
}

.container {
  @media (min-width: @screen-sm-min) {
    width: 100%;
  }

  @media (min-width: @screen-md-min) {
    max-width: @container-lg;
  }

  .row {
    &.ribbon-spot {
      margin: 0 38px 40px 0;

      .category-label {
        .icon,
        [class^="icon-"],
        [class*=" icon-"] {
          font-size: 5.5rem;
          color: @color-brand-200;
          display: none;
        }
      }
    }
  }
}

.content-rte {
  margin-top: 0 !important;
  margin-bottom: 30px;
}

.chat-wrapper,
.riddle-quiz__container {
  margin-bottom: 30px;
}

.wrapper {
  .clearfix();

  &.stripe {
    padding: 25px 0;

    &:nth-child(even) {
      background: @color-contrast-300;

      .list-secondary {
        border-color: @color-contrast-200;
      }
      /*&.subpage {
        background: @color-background-main;
      }*/
    }

    &:nth-child(odd) {
    }
  }

  .middle-row {
    position: relative;
    min-height: 490px;

    > .bg-fix {
      background-color: #f0f2f2;
      //display: none;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    .content-container,
    .content-area,
    .js-text-area {
      p {
      }

      img,
      figure {
        &.pull-left {
          margin: 0 10px 10px 0;
        }

        &.pull-right {
          margin: 0 0 10px 10px;
        }
      }

      figure {
        .clearfix();

        img {
          float: none;
        }

        figcaption {
          display: inline-block;
          background-color: #fff;
          color: @color-text;
          .font-size(@type-size-md);
          padding: 10px;
          width: 100%;

          .panel-body & {
            background-color: @color-secondary-light;
          }
        }
      }

      @media (max-width: @screen-sm) {
        img {
          width: 100% !important;
          height: auto !important;

          &.tingdok__spokesmenspot-a__container__image {
            width: 84px !important;
          }
        }
      }
    }
  }
}

.footer-container {
  margin: 0;
  width: 100%;
  float: left;
}

.load-map {
  display: none;
}

.push-25 {
  margin-left: 25px;
}

.wrapper .container .site-wide-message-spot-b {
  background-color: @color-brand-300;

  a {
    color: white;

    &:hover {
      border-bottom: 1px solid @color-primary-function-hover;
    }
  }
}

@media (max-width: @screen-xs-min) {
}

@media (min-width: @screen-sm-min) {
}
/* or @screen-sm */
@media (min-width: @screen-md-min) {
}
/* or @screen-md */
@media (min-width: @screen-lg) {
}

@media (max-width: @screen-xs-max) {
  .content-action-print {
    display: none;
  }
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .content-action-print {
    display: none;
  }
}

.content-action {
  .pageedit & {
    min-width: 50px;
  }
}
/* or @screen-lg */
.content-action-links .content-action a {
  i {
    &.icon-facebook {
      color: @color-social-facebook;
    }

    &.icon-twitter {
      color: @color-social-twitter;
    }
  }

  &:hover {
    color: @color-brand-200;

    i {
      &.icon-facebook {
        color: @color-social-facebook;
      }

      &.icon-twitter {
        color: @color-social-twitter;
      }
    }
  }
}

.stylefree-list {
  list-style: none;
  padding: 0;
}

.display-table {
  display: table;
}

.display-row {
  display: table-row;
}

.display-cell {
  display: table-cell;
  vertical-align: middle;
}

.question-form {
  &.form-horizontal {
    label {
      text-align: left;
    }
  }

  input[type="text"],
  textarea {
    width: 100%;
  }

  textarea {
    min-height: 5em;
  }

  .form-control {
    background: @color-contrast-400;
  }

  input[type="radio"].form-control {
    height: auto;
    width: auto;
    display: inline-block;
  }
}

.margin-bottom {
  margin-bottom: 25px;
}
/* Hack to frontendediting to prevent insert button overlap */
[sc-part-of="placeholder rendering"] {
  min-height: 50px;
  padding-bottom: 10px;

  &.wrapper {
    padding-bottom: 0px;
  }

  &.panel-group {
    padding-bottom: 20px;
  }
}

/* TODO: Move to Identity */
.site-identity-container {
  margin-bottom: 60px;
  // Replace class name when approved by the client
  &.logo-beta {
    .hide-site-title {
      position: absolute;
      left: -9999999px;
    }

    .site-identity .site-logo .logo {
      max-width: 250px;
      max-height: 170px;
    }

    .site-identity {
      .site-title {
        margin-top: 0 !important;
      }
    }
  }

  .site-identity {
    text-align: center;

    & > a {
      text-decoration: none;
    }

    .site-logo {
      .logo {
        width: 100%;
        height: 100%;
        max-width: 113px;
        max-height: 113px;

        .ebook & {
          max-width: inherit;
          max-height: inherit;
        }
      }
    }

    .site-title {
      .font-size(2);
      .font-alt();
      /*text-transform: uppercase;*/
      margin-top: 20px;
      color: @color-primary-darker;

      a {
        color: @color-text;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

[disabled="disabled"],
[disabled],
[disabled="true"] {
  opacity: 0.5;
}

.text-spot-a-wrapper {
  margin-top: 20px;

  &.content-rte {
    margin-bottom: 30px !important;
  }

  .js-sync-height & {
    @media screen and (max-width: 767px) {
      min-height: auto !important;
    }
  }
}

.two-col-spot {
  margin-bottom: 30px;

  .col-sm-6 {
    &:nth-child(odd) {
      padding-left: 0;
      padding-right: 0;
      border-right: 1px solid transparent;
    }

    &:nth-child(even) {
      padding-right: 0;
      padding-left: 0;
      border-left: 1px solid transparent;
    }

    > .row {
      margin: 0;
    }

    > div {
      background-color: #fff;
      margin: 0 0 2px 0 !important;
      padding: 23px 38px !important;

      h1,
      h2,
      h3,
      h4,
      h5 {
        margin-top: 0;
      }

      h3,
      h4 {
        .font-size(2);
        margin-bottom: 10px;
      }

      &.panel-group.informationspot-d,
      &.factbox,
      &.kaltura-video-container {
        padding: 0 !important;
      }
    }

    .factbox {
      margin: 0 0 2px 0 !important;
      background-color: #fff;

      h1,
      h2,
      h3,
      h4,
      h5 {
        margin-top: 0;
      }

      h3,
      h4 {
        .font-size(2);
        margin-bottom: 10px;
      }
    }

    .panel-group {
      padding: 0 !important;

      .panel-title a {
        &:after {
          position: absolute;
          right: 0;
        }
      }
    }
    /* Specif spots */
    .lexicon-spot {
      padding: 20px 38px;

      .header {
        padding: 0;

        .title {
          line-height: inherit;
        }

        .link {
          line-height: inherit;
        }
      }

      &__content {
        padding: 10px 0 20px;
      }
    }

    .exchange-rate-calculator {
      padding: 0;
      margin: 0 -15px;
      border: 0;

      h4 {
        font-weight: 400;
        .font-alt();
      }

      label {
        padding: 0;
      }

      .btn-col {
        //top: 4px;
        #calculateExchangeButton {
          width: auto;
        }
      }
    }

    .informations-spot-e {
      padding: 0 !important;

      &:after {
        display: none;
      }

      &__header {
        display: none;
      }

      &__images {
        &__image-group {
          &__blob {
            bottom: -15px;
          }

          &__image {
            //margin: 0 -38px;
          }

          &__headline {
            margin: 0 38px;
            .font-alt();
            .font-size(2);
            color: @color-text;
          }

          &__text {
            margin: 10px 38px 0;
            color: @color-text;
          }

          &__info {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            float: none;
          }

          &__link {
            margin: 15px 38px 0;
          }
        }
      }

      .bx-wrapper {
        margin-bottom: 0;
      }
    }

    .informationspot-d {
      padding: 0 !important;

      .panel {
        &:first-child {
          border-top: 0;
        }

        &:last-child {
          border-bottom: 0;
        }

        .panel-heading {
          .panel-title {
            a {
              &:after {
                position: absolute;
              }
            }
          }
        }
      }
    }
  }
}

.three-col-spot {
  margin-bottom: 30px;

  .col-sm-4 {
    padding-left: 0;
    padding-right: 0;
    border-left: 2px solid transparent;
    &:first-of-type {
      border-left: 0;
    }
    > .row {
      margin: 0;
    }

    > div {
      background-color: #fff;
      margin: 0 0 2px 0 !important;
      padding: 23px 38px !important;

      h1,
      h2,
      h3,
      h4,
      h5 {
        margin-top: 0;
      }

      h3,
      h4 {
        .font-size(2);
        .font-alt();
        margin-bottom: 10px;
      }
    }

    .panel-group {
      padding: 0 !important;

      .panel-title a {
        &:after {
          position: absolute;
          right: 0;
        }
      }
    }
    /* Specif spots */
    .lexicon-spot {
      padding: 20px 38px;

      .header {
        padding: 0;

        .title {
          line-height: inherit;
        }

        .link {
          line-height: inherit;
        }
      }

      &__content {
        padding: 10px 0 20px;
      }
    }

    .exchange-rate-calculator {
      padding: 0;
      margin: 0 -15px;
      border: 0;

      h4 {
        font-weight: 400;
        .font-alt();
      }

      label {
        padding: 0;
      }

      .btn-col {
        //top: 4px;
        #calculateExchangeButton {
          width: auto;
        }
      }
    }

    .informations-spot-e {
      padding: 0 !important;

      &:after {
        display: none;
      }

      &__header {
        display: none;
      }

      &__images {
        &__image-group {
          &__blob {
            bottom: -15px;
          }

          &__image {
            //margin: 0 -38px;
          }

          &__headline {
            margin: 0 38px;
            .font-alt();
            .font-size(2);
            color: @color-text;
          }

          &__text {
            margin: 10px 38px 0;
            color: @color-text;
          }

          &__info {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            float: none;
          }

          &__link {
            margin: 15px 38px 0;
          }
        }
      }

      .bx-wrapper {
        margin-bottom: 0;
      }
    }

    .informationspot-d {
      padding: 0 !important;

      .panel {
        &:first-child {
          border-top: 0;
        }

        &:last-child {
          border-bottom: 0;
        }

        .panel-heading {
          .panel-title {
            a {
              &:after {
                position: absolute;
              }
            }
          }
        }
      }
    }
  }
}

.dateAuthor {
  text-align: left;
  margin-top: 20px;
  margin-left: 38px;
  .font-size(1.2);
  &__infolinks-value {
    border-bottom: 1px solid @color-menu-selected-tab;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid @color-primary-function;
    }
  }
}

.author-infolinks-value {
  border-bottom: 1px solid @color-menu-selected-tab;

  &:hover {
    text-decoration: none;
    border-bottom: 1px solid @color-primary-function;
  }
}

.content-box {
  margin: 37px auto;

  &.airy {
    p {
      margin-bottom: 20px;
    }
  }
}

//custom 404, 500
.error-page {
  p {
    font-size: @sw-font-md;
    margin-bottom: 20px;
  }

  &__button {
    padding: 0;
  }
}

/* Fix for ipad menu */
.col-sm-3.left-menu-wrapper.hidden-print.hidden-xs {
  &:extend(.hidden-sm);
}

.col-sm-3.bg-fix.hidden-print.hidden-xs {
  &:extend(.hidden-sm);
}

.content-area {
  padding-top: 0;

  @media only screen and (min-width: 992px) {
    padding-top: 50px;
  }

  @media only screen and (max-width: 991px) {
    width: 100%;
  }
}

.bot-detect {
  &__content {
    margin-bottom: 10px;
  }

  &__input {
    @media (min-width: @screen-sm-min) {
      margin-left: 0;
    }
  }
}
