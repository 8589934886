﻿.breadcrumb {
  margin-bottom: 0;

  .dokumenter & {
    padding-left: 38px;
  }

  li {
    &:before {
      padding-left: 1.5px !important;
    }

    &:last-child {
      a {
        color: @color-text-dark;
        cursor: default;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.breadcrumb > li + li:before {
}
