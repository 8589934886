@import "../../Global/Components/twitterfeed.less";

.twitter-spot {
    .category-label {
        .category-title {
            .font-alt();
            .font-size(2);
            font-weight: 400;
        }
    }
}
