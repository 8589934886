﻿@import "../../Global/Components/newslist-spot-b.less";

.newslist-spot-b {
  background: #fff !important;
  color: @color-primary-dark;
  padding: 0 0 23px 0!important;
  margin-top: 0;
  margin-bottom: 30px;

  .image-container {
    width: 100%;
    height: auto;
    display: block;
    overflow: hidden;

    img {
      width: 100% !important;
      height: 100% !important;
      display: block;
    }
  }

  .ribbon-spot {
    margin-bottom: 0 !important;
    padding-top: 25px;

    .list-primary {
      margin-top: 25px;
      margin-left: 8px;

      .date {
        .font-size(1.4);
      }

      @media screen and (min-width: @screen-sm) {
        margin-top: 0;
      }
    }

    .category-label {
      text-align: left;
      padding-left: 24px;
      margin-bottom: 20px;

      .category-title {
        width: auto;
        .font-alt();
        font-weight: 400;
      }

      a {
        &:after {
          content: "\e631";
        }
      }

      .date {
        .font-size(1.4);
      }
    }

    .title-link-primary {
      font-weight: 400;
      .font-size(1.6);
			display: inline-block;
			padding-left: 15px;

			&:before {
				display: inline-block;
				&:extend(.less-icon-extend);
				&:extend(.icon-ft_bullit_right:before);
				font-size: 11px;
				float: left;
				margin: 4px 0 0 -15px;
			}
    }
  }
}

.extended-news-list {
  tbody tr:hover {
    cursor: pointer;
  }
}

//Related news spot
.related-news {
  margin-bottom: 30px;

  &__items {
    margin-top: 5px;
  }

  &__links {
    display: inline-block;
    padding-left: 15px;

    &:before {
      display: inline-block;
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);
      font-size: 11px;
      float: left;
      margin: 4px 0 0 -15px;
    }
  }
}
