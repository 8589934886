﻿@import "../../Global/Components/tab-spot-a.less";

.tab-spot-a__content {
  color: @color-primary-dark;

  .tab-spot-a__content__pane {
    input[type="text"],
    input[type="password"],
      input[type="number"],
      select {
      background-color: @color-secondary-lighter !important;
    
    &::placeholder {
        color: #454545;
          opacity: 1;
          /* Firefox */
        }
        
        &::-ms-input-placeholder {
          /* Edge 12 -18 */
        color: #454545;
        }
    }
  }
}

.tab-spot-a__wrapper {
  margin-bottom: 30px;
}

.tab-spot-a__subheader {
  margin-bottom: 38px;
}

.content-area .tab-spot-a__tabs__item__link {
  line-height: 22px !important;
  color: #555;
  font-size: 15px;
  width: auto;
  display: table;
  margin: 0 auto;

  @media only screen and (min-width: @screen-lg) {
    font-size: 20px;
  }

  &:after {
    display: inline-block;
    width: 8px;
    font-size: 12px;
    float: none;
    margin: 4px 0 0 5px;
    &:extend(.less-icon-extend);
    &:extend(.icon-ft_bullit_right:before);

    @media only screen and (min-width: @screen-sm) {
      float: right;
      font-size: 16px;
    }
  }

  &:hover {
    color: @color-primary-function-bg;
  }
}

.content-area {
  .booking-order-button {
    margin-bottom: 20px;
  }
}

.tab-spot-a__tabs {
  display: flex;
  overflow: auto;
  padding: 20px 0 0 38px !important;
}

.tab-spot-a__tabs__item {
  background: transparent !important;
  margin-right: 25px;
  white-space: nowrap;
  border-bottom: 4px solid #e8eaea;

  @media only screen and (max-width: 991px) {
    width: auto !important;

    &:hover {
      border-bottom: 4px solid @color-tertiary-result-absent;
    }
  }

  &.active {
    border-bottom: 4px solid @color-primary-function-bg;
    background: transparent !important;
    color: @color-primary-function !important;

    a {
      &:after {
          display: inline-block;
          width: 8px;
        content: "";
      }
}
  }
}

.tab-spot-a__tabs__item__link {
  padding: 10px;
  width: 100%;
  text-align: center;
}

.tab-spot-a__tabs__item.active a.tab-spot-a__tabs__item__link {
  color: @link-color;
}

.tab_content.tabs-container h2 {
  padding-left: 0;
  padding-bottom: 10px;
}
