.subsite-wide {
  .media-item {
    &:extend(.subsite-wide-container);
  }

  &__title,
  &__rte,
  &__content-rte {
    &:extend(.subsite-wide-container);
  }
}
  .media-item {
    position: relative;

    + .media-item__content-rte {
      margin-top: 20px !important;
    }

    @media (max-width: @screen-md) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &__wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
    }

    &__placeholder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      iframe {
        z-index: 1;
        position: relative;
      }
    }

    &__inner {
      position: relative;
      width: 100%;
      height: 100%;
    }

    &__image,
    .kWidgetCentered,
    img {
      max-height: 100%;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .kWidgetPlayBtn {
      background-image: url("data:image/svg+xml,%3Csvg width='75' height='75' viewBox='0 0 75 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.5664 74.8001C58.0053 74.8001 74.5743 58.2446 74.5743 37.8223C74.5743 17.4001 58.0053 0.844543 37.5664 0.844543C17.1276 0.844543 0.558594 17.4001 0.558594 37.8223C0.558594 58.2446 17.1276 74.8001 37.5664 74.8001Z' fill='white' fill-opacity='0.9'/%3E%3Cpath d='M30.8379 51.2683V24.3754L51.024 37.8219L30.8379 51.2683Z' fill='black'/%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: center;
      height: 75px;
      width: 75px;
      transition: transform 150ms ease-in-out, opacity 150ms ease-in-out,
        box-shadow 150ms ease-in-out;
      border-radius: 50%;
      &:hover {
        background-image: url("data:image/svg+xml,%3Csvg width='75' height='75' viewBox='0 0 75 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.5664 74.8001C58.0053 74.8001 74.5743 58.2446 74.5743 37.8223C74.5743 17.4001 58.0053 0.844543 37.5664 0.844543C17.1276 0.844543 0.558594 17.4001 0.558594 37.8223C0.558594 58.2446 17.1276 74.8001 37.5664 74.8001Z' fill='white' fill-opacity='0.9'/%3E%3Cpath d='M30.8379 51.2683V24.3754L51.024 37.8219L30.8379 51.2683Z' fill='black'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        height: 75px;
        width: 75px;
        opacity: 0.9;
        transform: scale3d(1.05, 1.05, 1);
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
      }

      @media (max-width: @screen-md-min) {
        background-image: url("data:image/svg+xml,%3Csvg width='45' height='45' viewBox='0 0 75 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.5664 74.8001C58.0053 74.8001 74.5743 58.2446 74.5743 37.8223C74.5743 17.4001 58.0053 0.844543 37.5664 0.844543C17.1276 0.844543 0.558594 17.4001 0.558594 37.8223C0.558594 58.2446 17.1276 74.8001 37.5664 74.8001Z' fill='white' fill-opacity='0.9'/%3E%3Cpath d='M30.8379 51.2683V24.3754L51.024 37.8219L30.8379 51.2683Z' fill='black'/%3E%3C/svg%3E%0A");
        height: 45px;
        width: 45px;

        &:hover {
          background-image: url("data:image/svg+xml,%3Csvg width='45' height='45' viewBox='0 0 75 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.5664 74.8001C58.0053 74.8001 74.5743 58.2446 74.5743 37.8223C74.5743 17.4001 58.0053 0.844543 37.5664 0.844543C17.1276 0.844543 0.558594 17.4001 0.558594 37.8223C0.558594 58.2446 17.1276 74.8001 37.5664 74.8001Z' fill='white' fill-opacity='0.9'/%3E%3Cpath d='M30.8379 51.2683V24.3754L51.024 37.8219L30.8379 51.2683Z' fill='black'/%3E%3C/svg%3E%0A");
          height: 45px;
          width: 45px;
        }
      }

      &:focus-within {
        outline-color: @sw-color-grey-lightest;
      }
    }

    &__rte {
      position: relative;
      width: 100%;

      .caption {
        background-color: @sw-color-primary-light;
        background-color: var(--sw-color-primary-light, @sw-color-primary-light);
        width: calc(100% - 30px);
        padding: @spacing-small;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0%);
  
        @media (min-width: @screen-sm-min) {
          width: calc(100% - 180px);
          padding: @padding-small;
        }
  
        @media (max-width: @screen-sm-min) {
          font-size: 15px;
        }
      }
  
      .playerPlayed {
        display: none;
        z-index: -1;
      }
    }

    &__content-rte {
      ul:not(.link-list) {
        li {
          list-style-type: disc;
        }
      }

      a:not(.link-list):not(.read-more-expander) {
        color: @sw-color-primary;
        color: var(--sw-color-primary, @sw-color-primary);
        border-bottom: 1px solid @sw-color-grey-dark;

        &:hover {
          text-decoration: none;
          border-bottom: 1px solid @sw-color-primary;
          border-bottom: 1px solid var(--sw-color-primary, @sw-color-primary);
        }
      }

      ul.link-list {
        li a:not(.read-more-expander) {
          color: @sw-color-primary;
          color: var(--sw-color-primary, @sw-color-primary);
          border-bottom: 1px solid transparent;

          &:hover {
            text-decoration: none;
            border-bottom: 1px solid @sw-color-primary;
            border-bottom: 1px solid var(--sw-color-primary, @sw-color-primary);
          }
        }
      }

      a.front-arrow {
        padding-left: 0;
        border-bottom: 0 !important;

        &:hover {
          border-bottom: 0;
          text-decoration: underline !important;
        }

        &:before {
          content: "";
          display: none;
        }

        &:after {
          display: inline-block;
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_bullit_right:before);
          font-size: 11px;
          position: relative;
          margin-left: 5px;
        }
      }
    }
  }

.image-spot-edit__field-edit {
    width: 150px;
    height: 100px;
}
