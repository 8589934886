﻿@import "../../styles.less";

/*!
 * Datepicker for Bootstrap
 *
 * Copyright 2012 Stefan Petre
 * Improvements by Andrew Rowls
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 */
.form-control {
  &.datepicker {
    padding: 6px 12px;
  }
}
.datepicker {
  .font-size(@datepicker-font-size);
  font-family: SceneAlt, serif !important;
  padding: 0;
  border-radius: 0;
  direction: ltr;

  &-inline {
    width: 220px;
  }

  &&-rtl {
    direction: rtl;
    table tr td span {
      float: right;
    }
  }

  &.datepicker-dropdown {
    top: 0;
    left: 0;
    border: none !important;
    background: #f7f7f7 !important;
    font-family: SceneAlt, serif !important;

    &:before {
      content: "";
      display: inline-block;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 7px solid #ccc;
      border-top: 0;
      border-bottom-color: rgba(0, 0, 0, 0.2);
      position: absolute;
    }
    &:after {
      content: "";
      display: inline-block;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #f7f7f7;
      border-top: 0;
      position: absolute;
    }
    &.datepicker-orient-left:before {
      left: 6px;
    }
    &.datepicker-orient-left:after {
      left: 7px;
    }
    &.datepicker-orient-right:before {
      right: 6px;
    }
    &.datepicker-orient-right:after {
      right: 7px;
    }
    &.datepicker-orient-top:before {
      top: -7px;
    }
    &.datepicker-orient-top:after {
      top: -6px;
    }
    &.datepicker-orient-bottom:before {
      bottom: -7px;
      border-bottom: 0;
      border-top: 7px solid #999;
    }
    &.datepicker-orient-bottom:after {
      bottom: -6px;
      border-bottom: 0;
      border-top: 6px solid #f7f7f7;
    }
  }
  > div {
    display: none;
  }
  &.days div.datepicker-days {
    display: block;
  }
  &.months div.datepicker-months {
    display: block;
  }
  &.years div.datepicker-years {
    display: block;
  }
  table {
    background: #f7f7f7 !important;
    margin: 1px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: SceneAlt, serif !important;
    min-width: 310px !important;

    @media only screen and (max-width: 1130px) and (min-width: 1000px) {
      min-width: 300px !important;
    }

    .datepicker-switch,
    .next,
    .prev,
    .cw,
    .dow,
    tr td {
      background: #f7f7f7 !important;
    }

    tr {
      color: #696969 !important;
      font-size: 1.4rem !important;

      td,
      th {
        text-align: center;
        width: 30px;
        height: 30px;
        border-radius: 0;
        background-color: #f7f7f7 !important;
        color: @datepicker-day-color;
        border: none;
      }

      th {
        .font-bold();
        font-family: SceneAlt, serif !important;
        color: @datepicker-month-color;
        background-color: #f7f7f7;

        &.dow {
          .font-regular();
          font-weight: normal !important;
          opacity: 1 !important;
          font-family: SceneAlt, serif !important;
          color: @datepicker-day-names-color;
          background-color: @datepicker-day-names-bg;
        }
      }

      td {
        border: @datepicker-day-border-size solid @datepicker-day-border-color;
        .font-bold();
        font-family: SceneAlt, serif !important;

        &:last-child {
          background-color: @datepicker-day-sunday-bg;
          color: @datepicker-day-sunday-color;

          &.day {
            background-color: @datepicker-day-sunday-bg;
            color: @datepicker-day-sunday-color;
          }
        }
      }
    }

    thead {
      tr:first-of-type {
        border-bottom: 1px solid #696969 !important;
      }
    }
  }
  // Inline display inside a table presents some problems with
  // border and background colors.
  .table-striped & table tr {
    td,
    th {
      background-color: transparent;
    }
  }
  table tr td {
    &.day {
      font-family: SceneAlt, serif !important;
      color: @datepicker-day-color;
      border: none !important;
      height: 40px !important;
      width: 20px !important;
    }

    &.day:hover {
      color: @datepicker-day-hover-color !important;
      cursor: pointer;
      background: radial-gradient(
        circle at center,
        #e8eaea 0%,
        #e8eaea 45%,
        #f7f7f7 50%
      ) !important;
    }
    &.day.focused {
      background: @datepicker-day-focused-bg;
      color: @datepicker-day-focused-color;
      cursor: pointer;
    }

    &.day.active {
      background: radial-gradient(
        circle at center,
        @color-brand-300 0%,
        @color-brand-300 45%,
        #f7f7f7 50%
      ) !important;
      border-radius: 50% !important;
      height: 40px !important;
      width: 20px !important;
      font-family: SceneAlt, serif !important;

      &:hover,
      &.focused {
        background: radial-gradient(
          circle at center,
          @color-brand-200 0%,
          @color-brand-200 45%,
          #f7f7f7 50%
        ) !important;
        color: #fff !important;
      }
    }
    &.old,
    &.new {
      .font-regular();
      background-color: @datepicker-day-in-past-bg;
      color: @datepicker-day-in-past-color;
    }
    &.disabled,
    &.disabled:hover {
      background: none;
      color: @datepicker-day-disabled-color;
      cursor: default;
    }
    &.range,
    &.range:hover,
    &.range.disabled,
    &.range.disabled:hover {
      background: @gray-lighter;
      border-radius: 0;
    }
    &.range.today,
    &.range.today:hover,
    &.range.today.disabled,
    &.range.today.disabled:hover {
      @today-bg: mix(orange, @gray-lighter, 50%);
      .button-variant(#000, @today-bg, darken(@today-bg, 20%));
      border-radius: 0;
    }
    &.selected,
    &.selected:hover,
    &.selected.disabled,
    &.selected.disabled:hover {
      .button-variant(#fff, @gray-light, @gray);
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
    &.active,
    &.active:hover,
    &.active.disabled,
    &.active.disabled:hover {
      .button-variant(@datepicker-day-active-color, @datepicker-day-active-bg, @btn-primary-border);
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
    }
    span {
      display: block;
      width: 23%;
      height: 54px;
      line-height: 54px;
      float: left;
      margin: 1%;
      cursor: pointer;
      border-radius: 4px;
      &:hover {
        background: @gray-lighter;
      }
      &.disabled,
      &.disabled:hover {
        background: none;
        color: @btn-link-disabled-color;
        cursor: default;
      }
      &.active,
      &.active:hover,
      &.active.disabled,
      &.active.disabled:hover {
        .button-variant(@btn-primary-color, @btn-primary-bg, @btn-primary-border);
        text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
      }
      &.old,
      &.new {
        color: @btn-link-disabled-color;
      }
    }
  }

  th.datepicker-switch {
    width: 145px;
  }

  thead tr:first-child th,
  tfoot tr th {
    cursor: pointer;
    &:hover {
      background: @gray-lighter;
    }
  }

  // Basic styling for calendar-week cells
  .cw {
    font-size: 10px;
    width: 12px;
    padding: 0 2px 0 5px;
    vertical-align: middle;
  }
  thead tr:first-child th.cw {
    cursor: default;
    background-color: transparent;
  }

  .prev {
    border-top: 1px solid #f7f7f7 !important;
    &:before {
      content: "\e60e" !important;
    }
  }

  .next {
    &:before {
      content: "\e60e" !important;
    }
  }
}

// ----
.datepicker {
  table {
    tr td.day {
      border: none !important;
      height: 40px !important;
      width: 20px !important;
      font-family: SceneAlt, serif !important;

      &:hover {
        color: @color-brand-200 !important;
        background: radial-gradient(
          circle at center,
          #e8eaea 0%,
          #e8eaea 45%,
          #f7f7f7 50%
        ) !important;
      }

      &.active {
        background: radial-gradient(
          circle at center,
          @color-brand-300 0%,
          @color-brand-300 45%,
          #f7f7f7 50%
        ) !important;
        border-radius: 50% !important;
        height: 40px !important;
        width: 20px !important;
        font-family: SceneAlt, serif !important;

        &:hover,
        &.focused {
          background: radial-gradient(
            circle at center,
            @color-brand-200 0%,
            @color-brand-200 45%,
            #f7f7f7 50%
          ) !important;
          color: #fff !important;
        }
      }

      &.today.disabled,
      &.today.disabled:hover {
        opacity: 0.3 !important;
        pointer-events: none !important;
      }

      &.today {
        color: @color-brand-200 !important;
        background: radial-gradient(circle at center, #f7f7f7 40%, @color-brand-200 45%, #f7f7f7 50%) !important;

        &:hover {
          color: @color-brand-200 !important;
          background: radial-gradient(
            circle at center,
            #e8eaea 0%,
            #e8eaea 45%,
            #f7f7f7 50%
          ) !important;
        }

        &.active {
          background: radial-gradient(
            circle at center,
            @color-brand-300 0%,
            @color-brand-300 45%,
            #f7f7f7 50%
          ) !important;
          color: #ffffff !important;
  
          &:hover,
          &.focused {
            background: radial-gradient(
              circle at center,
              @color-brand-200 0%,
              @color-brand-200 45%,
              #f7f7f7 50%
            ) !important;
            color: #fff !important;
          }
        }
      }

      &.old,
      &.new,
      &.disabled,
      &.cw {
        &:hover {
          color: #252525 !important;
          background: #f7f7f7 !important;
        }
      }

      &.day {
        &.new,
        &.disabled,
        &.old {
          opacity: 0.3 !important;
          pointer-events: none;
        }
      }
    }
    tr td.day,
    tr td .month,
    tr td .year {
      &.focused {
        color: @color-brand-200 !important;
        background: radial-gradient(
          circle at center,
          #e8eaea 0%,
          #e8eaea 45%,
          #f7f7f7 50%
        ) !important;
        &.disabled {
          color: #fff !important;
          background: radial-gradient(
            circle at center,
            #252525 0%,
            #252525 45%,
            #f7f7f7 50%
          ) !important;
          opacity: 0.2 !important;
        }
      }
    }

    tr th:not(.prev):not(.next) {
      color: #696969 !important;
      font-size: 1.4rem !important;
    }

    tr:first-of-type th.cw {
    }

    .cw {
      border: none !important;
      height: 40px !important;
      width: 20px !important;
      font-size: 1rem !important;
      font-weight: normal !important;
      color: #696969 !important;
      padding: 0px !important;
      font-family: SceneAlt, serif !important;
    }

    .dow {
      font-weight: normal !important;
      color: #696969 !important;
      opacity: 1 !important;
      font-family: SceneAlt, serif !important;
    }
    tr td {
      border: none !important;
    }
    tr .month,
    tr .year,
    tr .year.old,
    tr .year.new {
      background: #f7f7f7 !important;
    }
    tr .month,
    tr .year {
      &.new,
      &.disabled,
      &.old {
        opacity: 0.3 !important;
        pointer-events: none;
      }
    }

    tr td .month,
    tr td .year {
      &:hover {
        color: @color-brand-300 !important;
        background: radial-gradient(
          circle at center,
          #e8eaea 0%,
          #e8eaea 45%,
          #f7f7f7 50%
        ) !important;
      }
      &.active {
        background: radial-gradient(
          circle at center,
          @color-brand-350 0%,
          @color-brand-350 45%,
          #f7f7f7 50%
        ) !important;
        border-radius: 50% !important;
        font-family: SceneAlt, serif !important;
        color: #fff !important;

        &:hover,
        &.focused {
          background: radial-gradient(
            circle at center,
            @color-brand-200 0%,
            @color-brand-200 45%,
            #f7f7f7 50%
          ) !important;
          color: #fff !important;
        }
      }
      &.disabled.focused {
        background: radial-gradient(
          circle at center,
          #252525 0%,
          #252525 45%,
          #f7f7f7 50%
        ) !important;
        color: #fff !important;
        opacity: 0.2 !important;
      }
    }
  }

  .prev {
    border-top: 1px solid #f7f7f7 !important;
    &:before {
      content: "\e60e";
    }
  }

  .next {
    &:before {
      content: "\e60e";
    }
  }
}

// ----

.input-group {
  &.date {
    .input-group-addon i {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }
}
.input-daterange {
  input {
    text-align: center;
  }
  input:first-child {
    border-radius: 3px 0 0 3px;
  }
  input:last-child {
    border-radius: 0 3px 3px 0;
  }
  .input-group-addon {
    width: auto;
    min-width: 16px;
    padding: 4px 5px;
    font-weight: normal;
    line-height: @line-height-base;
    text-align: center;
    text-shadow: 0 1px 0 #fff;
    vertical-align: middle;
    background-color: @input-group-addon-bg;
    border: solid @input-group-addon-border-color;
    border-width: 1px 0;
    margin-left: -5px;
    margin-right: -5px;
  }
}

//For chamberoview page
.chamber-overview-page {
  .datepicker-dropdown {
    background: #f7f7f7 !important;
    border: none !important;
    font-family: SceneAlt, serif !important;

    &:before,
    &:after {
      display: none !important;
    }

    &.datepicker-orient-top:after {
      display: none !important;
    }
  }

  .datepicker {
    table {
      background: #f7f7f7 !important;
      font-family: SceneAlt, serif !important;
      min-width: 310px !important;

      @media only screen and (max-width: 1130px) and (min-width: 1000px) {
        min-width: 300px !important;
      }

      .datepicker-switch,
      .next,
      .prev,
      .cw,
      .dow,
      tr td {
        background: #f7f7f7 !important;
        font-family: SceneAlt, serif !important;
      }

      thead {
        tr:first-of-type {
          border-bottom: 1px solid #696969 !important;
        }
      }

      tr td.day {
        border: none !important;
        height: 40px !important;
        width: 20px !important;
        font-family: SceneAlt, serif !important;

        &:hover {
          color: #a6192e !important;
          background: radial-gradient(
            circle at center,
            #e8eaea 0%,
            #e8eaea 45%,
            #f7f7f7 50%
          ) !important;
        }

        &.active {
          background: radial-gradient(
            circle at center,
            #bb4752 0%,
            #bb4752 45%,
            #f7f7f7 50%
          ) !important;
          border-radius: 50% !important;
          height: 40px !important;
          width: 20px !important;
          font-family: SceneAlt, serif !important;

          &:hover,
          &.focused {
            background: radial-gradient(
              circle at center,
              #901628 0%,
              #901628 45%,
              #f7f7f7 50%
            ) !important;
            color: #fff !important;
          }
        }

        &.old,
        &.new,
        &.disabled,
        &.cw {
          &:hover {
            color: #252525 !important;
            background: #f7f7f7 !important;
          }
        }

        &.day {
          border-radius: 50% !important;
          &.new,
          &.disabled,
          &.old {
            opacity: 0.3 !important;
            pointer-events: none;
          }
        }
      }
      tr td.day,
      tr td .month,
      tr td .year {
        &.focused {
          color: #a6192e !important;
          background: radial-gradient(
            circle at center,
            #e8eaea 0%,
            #e8eaea 45%,
            #f7f7f7 50%
          ) !important;
          &.disabled {
            color: #fff !important;
            background: radial-gradient(
              circle at center,
              #252525 0%,
              #252525 45%,
              #f7f7f7 50%
            ) !important;
            opacity: 0.2 !important;
          }
        }
      }

      tr th:not(.prev):not(.next) {
        color: #696969 !important;
        font-size: 1.4rem !important;
      }

      tr:first-of-type th.cw {
      }

      .cw {
        border: none !important;
        height: 40px !important;
        width: 20px !important;
        font-size: 1rem !important;
        font-weight: normal !important;
        color: #696969 !important;
        padding: 0px !important;
        font-family: SceneAlt, serif !important;
      }

      .dow {
        font-weight: normal !important;
        color: #696969 !important;
        opacity: 1 !important;
        font-family: SceneAlt, serif !important;
      }
      tr td {
        border: none !important;
      }
      tr .month,
      tr .year,
      tr .year.old,
      tr .year.new {
        background: #f7f7f7 !important;
      }
      tr .month,
      tr .year {
        &.new,
        &.disabled,
        &.old {
          opacity: 0.3 !important;
          pointer-events: none;
        }
      }

      tr td .month,
      tr td .year {
        &:hover {
          color: #a6192e !important;
          background: radial-gradient(
            circle at center,
            #e8eaea 0%,
            #e8eaea 45%,
            #f7f7f7 50%
          ) !important;
        }
        &.active {
          background: radial-gradient(
            circle at center,
            #bb4752 0%,
            #bb4752 45%,
            #f7f7f7 50%
          ) !important;
          border-radius: 50% !important;
          font-family: SceneAlt, serif !important;
          color: #fff !important;

          &:hover,
          &.focused {
            background: radial-gradient(
              circle at center,
              #901628 0%,
              #901628 45%,
              #f7f7f7 50%
            ) !important;
            color: #fff !important;
          }
        }
        &.disabled.focused {
          background: radial-gradient(
            circle at center,
            #252525 0%,
            #252525 45%,
            #f7f7f7 50%
          ) !important;
          color: #fff !important;
          opacity: 0.2 !important;
        }
      }
    }

    .prev {
      border-top: 1px solid #f7f7f7 !important;
      &:before {
        content: "\e60e";
      }
    }

    .next {
      &:before {
        content: "\e60e";
      }
    }
  }
}

.data-visualization-route {
  .datepicker {
    table {
      tr td.day,
      tr td .month,
      tr td .year {
        &.focused {
          color: #a6192e !important;
          background: radial-gradient(
            circle at center,
            #e8eaea 0%,
            #e8eaea 35%,
            #f7f7f7 40%
          ) !important;
          &.disabled {
            color: #fff !important;
            background: radial-gradient(
              circle at center,
              #252525 0%,
              #252525 35%,
              #f7f7f7 40%
            ) !important;
            opacity: 0.2 !important;
          }
        }
      }
      tr td.day {
        &:hover {
          background: radial-gradient(
            circle at center,
            #e8eaea 0%,
            #e8eaea 35%,
            #f7f7f7 40%
          ) !important;
        }

        &.active {
          background: radial-gradient(
            circle at center,
            #bb4752 0%,
            #bb4752 45%,
            #f7f7f7 50%
          ) !important;

          &:hover,
          &.focused {
            background: radial-gradient(
              circle at center,
              #901628 0%,
              #901628 35%,
              #f7f7f7 40%
            ) !important;
            color: #fff !important;
          }
        }
        &.disabled.focused {
          color: #fff !important;
          background: radial-gradient(
            circle at center,
            #252525 0%,
            #252525 35%,
            #f7f7f7 40%
          ) !important;
          opacity: 0.2 !important;
        }
      }
      tr td .month,
      tr td .year {
        &:hover {
          color: #a6192e !important;
          background: radial-gradient(
            circle at center,
            #e8eaea 0%,
            #e8eaea 40%,
            #f7f7f7 45%
          ) !important;
          @media only screen and (min-width: 1024px) {
            background: radial-gradient(
              circle at center,
              #e8eaea 0%,
              #e8eaea 30%,
              #f7f7f7 35%
            ) !important;
          }
        }
        &.active {
          background: radial-gradient(
            circle at center,
            #bb4752 0%,
            #bb4752 45%,
            #f7f7f7 50%
          ) !important;
          border-radius: 50% !important;
          font-family: SceneAlt, serif !important;
          color: #fff !important;
          @media only screen and (min-width: 1024px) {
            background: radial-gradient(
              circle at center,
              #bb4752 0%,
              #bb4752 30%,
              #f7f7f7 35%
            ) !important;
          }

          &:hover {
            background: radial-gradient(
              circle at center,
              #901628 0%,
              #901628 40%,
              #f7f7f7 45%
            ) !important;
            color: #fff !important;
            @media only screen and (min-width: 1024px) {
              background: radial-gradient(
                circle at center,
                #901628 0%,
                #901628 30%,
                #f7f7f7 35%
              ) !important;
            }
          }
        }
        &.disabled.focused {
          background: radial-gradient(
            circle at center,
            #252525 0%,
            #252525 40%,
            #f7f7f7 45%
          ) !important;
          color: #fff !important;
          opacity: 0.2 !important;
          @media only screen and (min-width: 1024px) {
            background: radial-gradient(
              circle at center,
              #252525 0%,
              #252525 30%,
              #f7f7f7 35%
            ) !important;
          }
        }
      }
    }
  }
}
