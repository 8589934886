﻿.ftMember {
  .person {
    &__container {
      .make-row();
      margin: 0;
      padding: 0 23px;

      h1 {
        padding: 0;
        margin: 0 0 40px;
      }

      img {
        border-radius: 50%;
      }

      &__functionparty {
        .font-alt();
        .font-size(2);
        display: inline-block;
        margin-bottom: 15px;
      }

      &__contactinfo {
        .display-cell {
          &:first-child {
            padding-right: 5px;
          }
        }

        &__spacer {
          .clearfix();
          border: none;
          border-top: 0;
          margin: 4px 0;
        }
      }
    }
  }

  .download {
    margin-top: 30px;
    margin-bottom: 30px;

    &__container {
      .make-row();
      margin: 0;
      padding: 0 23px;

      &__docBtns {
        .make-sm-column(8);
        .make-sm-column-push(4);
        padding: 0 23px;
        margin-left: -7px;

        &__btn {
          margin-left: 10px;

          &:first-child {
            margin-left: 0;
          }

          @media only screen and (max-width: @screen-sm-max) {
            margin-left: 0;
            width: 100%;
            display: block;
            margin-bottom: 15px;
          }

          &:hover {
            [class*="__docWork"] {
              text-decoration: none;
              background-color: @color-primary-function-bg;
              color: #fff;
            }
          }

          &__docWork {
            color: @color-primary-function-bg;
            background-color: #fff;
            border-radius: 50%;
            width: 43px;
            height: 43px;
            display: inline-block;
            position: relative;
            text-align: center;

            i {
              font-size: 28px;
              position: relative;
              top: 7px;
            }
          }

          &__text {
            display: inline-block;
            color: @color-text;
            margin-left: 10px;
            .font-alt();
            .font-size(1.5);
          }
        }
      }
    }
  }

  [data-style="servicemenu"] + .popover {
    background-color: #fff;
  }

  [data-style="servicemenu"] + .popover.bottom > .arrow {
    border-bottom-color: #fff;

    &:after {
      border-bottom-color: #fff;
    }
  }

  &__popup-content {
    display: none;
    padding: 15px;
  }

  &__accordion {
    &__container {
      .panel-body {
        h1, h2, h3, h4, h5, h6 {
          margin: 0 38px 10px;
        }

        h3 {
          .font-size(2);
          margin-bottom: 20px;
        }

        b, strong {
          .font-size(1.8);
          margin-bottom: 5px;
          display: inline-block;
          font-weight: 400;
        }

        ul {
          margin: 0 38px 10px 48px;
        }
      }
    }
  }

  #ftMember__accordion__container__tab2 {
    p {
      margin-bottom: 30px;
    }
  }

  #ftMember__accordion__container__tab3, #ftMember__accordion__container__tab2 {
    ul {
      list-style: none;
      margin: 0 38px 30px;
      padding: 0;

      li {
        margin: 10px 0;
      }
    }

    h2 {
      margin-bottom: 5px;
    }

    p {
      margin-bottom: 30px;
    }
  }

  #ftMember__accordion__container__tab4 {
    ul {
      list-style: none;
      margin: 0 38px 30px;
      padding: 0;

      li {
        margin: 10px 0;
      }
    }

    p {
      b, strong {
        margin-top: 20px;
      }
    }
  }

  .dateAuthor {
    text-align: left;
    margin-top: 20px;
    margin-left: 38px;
    .font-size(1.2);
  }

  .about {
    margin: 20px 38px;
  }
}
