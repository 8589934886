.bx-controls {
  .has-no-nav & {
    display: none;
  }
}

.bx-wrapper {
  padding: 30px 0 0;
  -ms-touch-action: pan-y;
  touch-action: pan-y;

  .has-no-nav &{
    margin-bottom: 0;
  }

  .committee-calendar-wrapper & {
    max-width: 100% !important;
  }

  img {
    display: block !important;
  }

  .bx-viewport {
    -moz-box-shadow: 0 0 0;
    -webkit-box-shadow: 0 0 0;
    box-shadow: 0 0 0;
    border: 0;
    background: transparent;

    .committee-calendar-wrapper & {
      background: white;
    }
  }

  .bx-loading {
    display: none;
    background-image: none;
    background: none;
  }

  .bx-controls {
    &:extend(.hidden-xs);
  }

  .bx-controls-direction {
    a {
      position: absolute;
      &:extend(.less-icon-extend);
      top: 80px;
      margin-top: -5px;
      text-indent: 0;
      color: @color-brand-200;
      .font-size(@type-size-xl);
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }

      &.bx-prev {
        left: -25px;

        &:before {
          &:extend(.icon-ft_bullit_left:before);
        }
      }

      &.bx-next {
        right: -30px;

        &:before {
          &:extend(.icon-ft_bullit_right:before);
        }
      }
    }
  }

  .slide {
    cursor: pointer;

    &.no-link {
      cursor: default;
    }

    .committee-calendar-wrapper & {
      border-right: 1px solid @color-secondary-light;
    }


    .slide-title {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: @color-brand-200;
      margin-top: 15px;
      .font-size(@type-size-xl);
      font-weight: bold;
    }

    .slide-description {
      display: block;
      margin: 10px 0 0 0;
      .font-size(@type-size-base);
    }
  }

  .information-page-spot-a & {
    .slide {
      img {
        max-width: 245px;
        max-height: 123px;
      }

      .slide-title {
        max-width: 245px;
      }
    }
  }

  .information-page-spot-b &,
  .information-page-spot-c & {
    .slide {
      img {
        max-width: 370px;
        max-height: 186px;
      }

      .slide-title {
        max-width: 370px;
      }
    }
  }
}

.ribbon-spot {
  margin: 20px 0;

  .bx-wrapper {
    .bx-viewport {
      left: 0;
    }

    .bx-controls-direction {
      div {
        top: 30px;
        display: inline-block;
        height: 120px;
        background: fade(@color-text-light, 30%);
        margin: 0;
        width: 28px;

        &:hover {
          background: fade(@color-text-light, 50%);
        }

        &.bx-prev {
          left: 0;
        }

        &.bx-next {
          right: 0;
        }

        &:before {
          display: table-cell;
          width: 28px;
          text-align: center;
          vertical-align: middle;
        }
      }
    }
  }

  .information-page-spot-a {
    @media only screen and (min-width: @screen-md) {
      padding-right: 0;
    }

    .bx-controls-direction {
      a {
        height: 123px;

        &:before {
          height: 123px;
        }
      }
    }
  }

  .information-page-spot-b {


    @media (min-width: @screen-md) {
      margin-bottom: -15px;
    }

    @media only screen and (min-width: @screen-md) {
      padding: 0;
    }

    &.slider {
      margin-bottom: 20px;
    }

    .bx-wrapper {
    }

    .bx-controls-direction {
      a {
        height: 158px;

        &:before {
          height: 158px;
        }
      }
    }

    .category-label {
      margin: 0;
      //height: 55px;
      //display: table-cell;
      text-align: right;

      @media (max-width: @screen-sm) {
        text-align: left;
      }

      vertical-align: middle;

      div[class^="icon-"] {
        display: table-cell;
        vertical-align: middle;
        line-height: 0;

        &:before {
          margin-right: 20px;
          top: -5px;
          position: relative;
        }
      }

      a {
        position: relative;
        top: 9px;

        @media (max-width: @screen-sm) {
          position: static;
        }

        &:after {
          display: inline-block;
        }
      }

      .category-title {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        text-align: left;
        margin: 0;
        padding-left: 38px;
        .module-headline();
      }
    }
  }

  .information-page-spot-c {

    @media (min-width: @screen-md) {
      margin-bottom: -15px;
    }

    @media only screen and (min-width: @screen-md) {
      padding: 0;
    }

    .bx-wrapper {
      .bx-viewport {
        width: 90% !important;
        margin: 0 auto !important;

        .slide {
          a {
            margin-bottom: 10px;
          }
        }
      }
    }

    .bx-controls-direction {
      a {
        height: 30px;
        top: 50%;
        margin-top: -15px;

        &:before {
          height: 30px;
        }

        &.bx-prev {
          &:before {
            text-align: left;
          }
        }

        &.bx-next {
          &:before {
            text-align: right;
          }
        }
      }
    }

    .category-label {
      margin: 0;
      //height: 55px;
      //display: table-cell;
      text-align: right;

      @media (max-width: @screen-sm) {
        text-align: left;
      }

      vertical-align: middle;

      div[class^="icon-"] {
        display: table-cell;
        vertical-align: middle;
        line-height: 0;

        &:before {
          margin-right: 20px;
          top: -5px;
          position: relative;
        }
      }

      a {
        position: relative;
        top: 9px;

        @media (max-width: @screen-sm) {
          position: static;
        }

        &:after {
          display: inline-block;
        }
      }

      .category-title {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        text-align: left;
        margin: 0;
        padding-left: 38px;
        .module-headline();
      }
    }
  }

  &.medlemmersidespot-a-row {
    .container & {
      margin: 0;
    }
  }

  .medlemmersidespot-a {
    margin-top: 20px !important;
    margin-bottom: 30px;
    padding: 0;

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
      margin-bottom: 90px;
    }

    .row {
      margin: 0;

      .col-sm-12 {
        padding: 0;
      }
    }

    &__member-title {
      font-size: 1.8rem;
      color: @color-text;
    }

    &__showmore {
      text-align: right;
      float: right;
      padding-right: 38px;

      .committee-calendar-wrapper & {
        padding-right: 0;
      }

      @media (max-width: @screen-sm) {
        float: none;
      }


      a {
        font-family: "SceneAlt", serif;

        &:after {
          content: "\e631";
          font-family: "ft-icons";
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          padding-left: 5px;
          position: relative;
          top: 0;
          .font-size(1);
          display: inline-block;
        }
      }
    }

    .category-label {
      margin: 0;
      text-align: right;
      padding-left: 38px;
      float: left;

      @media (max-width: @screen-sm) {
        text-align: left;
        float: none;
      }

      a {
        position: relative;
        top: 19px;

        @media (max-width: @screen-sm) {
          position: static;
        }

        &:after {
          display: inline-block;
        }
      }

      .category-title {
        display: table-cell;
        vertical-align: middle;
        width: 100%;
        text-align: left;
        margin: 0;
        .font-alt();
        .font-size(2.5);
        font-weight: 400;
      }
    }

    .bx-wrapper {
      padding: 10px 0 0;

      @media (max-width: @screen-sm) {
        margin: 0 auto !important;
        margin-bottom: 0;
      }

      .slide {
        background-color: #fff;
        text-align: center;
        padding: 30px 0;
        width: 100%;

        @media (max-width: @screen-sm) {
          padding: 30px 38px;
        }

        .img-wrapper {
          width: 100%;
          background-color: transparent;

          @media (max-width: @screen-sm) {
            width: auto;

            img {
              width: 70% !important;
            }
          }

          img {
            display: block;
            margin: 0 auto;
            width: 216px;
            height: 216px;
            border-radius: 50%;
          }
        }

        .slide-title {
          margin-top: 10px;
          font-weight: 400;
          .font-size(2);
          .font-alt();
        }

        span.slide-title {
          color: @color-text;
        }

        .slide__border {
          height: 5px;
          background: @color-primary-function-bg;
          position: relative;
          bottom: -30px;
          width: 80%;
          -moz-transform: translatex(-50%);
          -ms-transform: translatex(-50%);
          -o-transform: translatex(-50%);
          -webkit-transform: translatex(-50%);
          transform: translatex(-50%);
          left: 50%;

          @media (max-width: @screen-sm) {
            width: 105%;
          }
        }
      }
    }

    .bx-controls-direction {
      position: relative;

      a {
        width: 53px;
        height: 53px;
        //top: 10px;
        background: transparent;
        color: @color-secondary-darker;

        &:hover {
          background: transparent;
          color: @color-primary-dark;
        }

        &:before {
          font-size: 40px;
          width: 53px;
          height: 53px;
        }

        &.bx-prev {
          left: 32px;

          &:before {
            &:extend(.icon-ft_leftcircle:before);
          }
        }

        &.bx-next {
          right: 28px;

          &:before {
            &:extend(.icon-ft_rightcircle:before);
          }
        }
      }
    }

    .bx-pager {
      bottom: -49px;
      padding: 0 60px;

      &.bx-default-pager {
        a {
          background: #b5b6b6;
          border: 1px solid #696969;

          &.active {
            background: #696969;
            border: 1px solid #696969;
            width: 12px;
            height: 12px;
            border-radius: 6px;
          }
        }
      }
    }
  }

  .committee-calendar {
    position: relative;
    height: 284px;
    overflow: hidden;

    &__weekday {
      position: absolute;
      top: 38px;
      left: 40px;
      .font-size(1.4);
      font-family: "SceneAlt", serif;
      color: @color-primary-dark;
    }

    &__schedule {
      position: absolute;
      top: 57px;
      left: 40px;
      width: 82%;
      text-align: left;
      .font-size(2);
      font-family: "SceneAlt", serif;

      &:after {
        content: "";
        display: block;
        width: 235px;
        height: 1px;
        background-color: @color-secondary-light;
        position: absolute;
        bottom: -17px;
        left: 0;
      }

      div {
        font-size: 14px;
        display: inline-block;
        color: @color-primary-dark;
      }
    }

    &__date {
      padding-right: 17px;
      color: @color-primary-dark;
      height: 27px;
      display: inline-block;
    }

    &__time-wrapper {
      display: inline-block;
    }

    &__time {
      position: relative;
      padding-left: 17px;
      color: @color-primary-dark;
      font-size: 20px;

      &:before {
        content: "";
        display: block;
        width: 1px;
        height: 25px;
        background-color: @color-secondary-light;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &__location {
      font-size: 14px;
      display: block;
      color: @color-primary-dark;
    }

    &__attention {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 0;
      text-align: center;

      &:before {
        content: "\A";
        border-style: solid;
        border-width: 0 85px 85px 0;
        border-color: transparent @color-primary-function-bg transparent transparent;
        position: absolute;
        right: 0;
        top: 0;
      }

      &__text {
        position: absolute;
        top: 9px;
        right: -4px;
        z-index: 1;
        transform: rotate(45deg);
        .font-size(1.8);
        font-family: "SceneAlt", serif;
        color: white;
        white-space: nowrap;
        text-align: center;
        padding: 10px;
      }
    }

    &__title-link {
      position: absolute;
      top: 145px;
      left: 40px;
      display: block;
      width: 82%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .font-size(@type-size-xxl);
      font-family: "SceneAlt", serif;
      color: @color-brand-200;
      font-weight: normal;
      text-align: left;
    }

    &__title-empty-link {
      position: absolute;
      top: 145px;
      left: 40px;
      display: block;
      width: 82%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      .font-size(@type-size-xxl);
      font-family: "SceneAlt", serif;
      //color: @color-brand-200;
      font-weight: normal;
      text-align: left;

      &:hover {
        cursor: default;
      }
    }

    &__description {
      position: absolute;
      top: 185px;
      left: 40px;
      right: 40px;
      .font-size(1.4);
      text-align: left;
      color: @color-primary-dark;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    &__border {
      position: absolute !important;
      bottom: 0 !important;
      width: 80% !important;
    }
  }
}

.committee-calendar-wrapper {
  &.is-minimal {
    .bx-controls {
      display: none;
    }
  }

  .category-label {
    @media only screen and (max-width: @screen-sm) {
      margin-bottom: 10px !important;
      float: none;
    }
  }

  .medlemmersidespot-a {
    .category-title {
      font-family: SceneAlt, serif;
    }

    &__showmore-wrapper {
      display: table;
      padding-right: 38px;
      float: right;

      @media only screen and (max-width: @screen-sm) {
        float: none;
        margin-bottom: 0;
        padding-left: 38px;
      }

      .seperator {
        @media only screen and (max-width: 768px) {
          display: none !important;
        }

        display: inline-block;
        width: 45px;
        height: 22px;
        border-right: 1px solid #b4b4b4;
        float: left;
        position: relative;
        right: 21px;
      }


      &__primary {
        position: relative;
        text-align: left;
      }

      p, a {
        margin-bottom: 10px;
        font-family: SceneAlt, serif;

        @media only screen and (min-width: 769px) {
          margin-bottom: 0;
        }
      }

      a {
        font-size: 16px;
        font-family: SceneAlt, serif;
        margin-top: 0;

        @media only screen and (max-width: @screen-sm) {
          padding-left: 0;
        }
      }
    }

    &__showmore-link {
      @media only screen and (min-width: 769px) {
        float: left;
      }
    }
  }
}
