//@import "../../Global/Components/direktivlistespot.less";
@import "../Variables/_colors.less";
@import "../Variables/_typography.less";

.site-ft .row.search-result-container {
  margin: 0 15px;
}

@media only screen and (max-width: 992px) {
  .telbogTable.ankiro-results.news th {
    display: none;
  }
}

.list-striped {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    padding: 10px;

    &:nth-child(odd) {
      background: @color-contrast-400;
    }
  }
}

.ankiro-results td.cellPic {
  padding: 10px 20px !important;
  text-align: center;
}

.ankiro-results {
  &.news {
    ul {
      padding-left: 12px !important;

      li {
        list-style-position: outside;
        list-style-type: disc;
      }
      //HACK: Chrome hack because list-style-position: inside has been used which creates crossbrowsing issue
      @media screen and (-webkit-min-device-pixel-ratio: 0) {
        li {
          margin-left: 3px;
        }
      }
    }
  }
}

.listespot-wrapper {
  margin: 0 -15px;
  tbody {
    .highlighted {
      a {
        &:hover {
          text-decoration: underline;
          > * {
            text-decoration: underline;
          }
        }
        &:before {
          &:extend(.less-icon-extend);
          color: @color-primary-link;
          display: inline-block;
          font-size: 11px;
          float: left;
          margin: 4px 6px 0 0;
          content: "\e631";
        }
      }
    }
  }
  .column-documents {
    &__icon {
      max-width: 30px;
      max-height: 30px;
      margin-bottom: 10px;

      @media only screen and (min-width: @screen-sm) {
        margin-bottom: 18px;
      }
    }
    a.icon-ft_basketplus {
      &:hover {
        text-decoration: none !important;
      }
    }

    &.highlighted {
      min-width: 150px;
    }
  }
  // Fixes: BUG-36726 and BUG-36712
  .tingdok__documentdownload {
    @media only screen and (max-width: @screen-xs) {
      tbody td {
        display: block;

        &:first-child {
          padding-left: 0 !important; // Forced to use !important to overwrite another important attribute
        }

        a {
          padding-bottom: 0 !important;
        }
      }

      .btn-icon-button-list {
        margin: 10px 0;

        li {
          margin-left: 0;
        }
      }
    }

    @media only screen and (max-width: @screen-sm) {
      border-collapse: collapse;
    }
  }
  // End bugfix Bug-36726 and Bug-36712
  // Fixes: BUG-36726
  .tingdok__questionandanswerlistspot-a & {
    @media only screen and (max-width: @screen-xs) {
      > table tr td {
        display: block;

        &:first-child a {
          padding-bottom: 0;
        }

        &:last-child {
          padding-left: 38px;
        }
      }
    }
  }
  // End bugfix Bug-36726
  // Fixes BUG-36772
  .tingdok__votinglistspot-a & {
    tbody tr td:first-child {
      a {
        color: @color-primary-link;
        //Reverted due to PBI: 25147
      }
    }
    .voting-table {
      tr[onclick] {
        td:first-child {
          display: flex;
          &:before {
            display: inline-block;
            &:extend(.less-icon-extend);
            &:extend(.icon-ft_bullit_right:before);
            font-size: 11px;
            position: relative;
            margin-right: 5px;
            margin-top: 3px;
          }
        }
      }
    }

    @media only screen and (max-width: @screen-md) {
      &:not(.standard-table) {
        table {
          tr {
            padding: 25px 0;
          }

          td:before {
            display: none;
          }
        }
      }
    }
  }

  .tingdok__vote & {
    tbody tr td:first-child {
      a {
        color: @color-primary-link;
        //Reverted due to PBI: 25147
      }
    }

    @media only screen and (max-width: @screen-md) {
      &:not(.standard-table) {
        table {
          tr {
            padding: 25px 0;
          }

          td:before {
            width: 45%;
            text-align: left;
            font-family: SceneAlt, serif;
            display: table-cell;
            padding: 0;
            padding-left: 0 !important;
            content: attr(data-title);
          }
        }
      }
    }
  }
  // End bugfix Bug-36772
  .tab-spot-a__wrapper & {
    margin: 0;
  }

  .cellPic {
    padding-left: 10px !important;

    img {
      border-radius: 50%;
      max-width: 84px;
    }
  }

  .listespot-wrapper {
    margin: 0;
  }

  table {
    border-collapse: collapse;
    min-width: 100%;

    .has-url {
      &:hover {
        cursor: pointer;
      }
    }

    @media (max-width: @screen-sm-max) {
      border-collapse: separate;
    }

    .font-size(1.4);
    color: @color-text;
  }

  thead {
    white-space: nowrap;
    font-weight: 400;

    a {
      .font-size(1.4) !important;
      font-weight: 400;
      color: @color-text;

      &.filter {
        border: 0;
        margin: 0;
        padding: 0;
        background-color: transparent;
      }
    }

    tr,
    th {
      background: #fff;

      .panel-body & {
        background-color: #fff;
      }
    }

    th {
      font-weight: 400;
      .font-alt() !important;
      .font-size(1.4) !important;

      a {
        padding: 0 15px 0 0;
        display: block;
        position: relative;
        color: @text-color;
        border-bottom: none !important;

        &:hover {
          color: @link-color-hover;
          border-bottom: none !important;
        }

        .panel-body & {
          .font-size(1.4) !important;
        }

        &:after {
          .font-size(@type-size-xs);
          display: inline-block;
          &:extend(.less-icon-extend);
          position: absolute;
          top: 3px;
          right: 0px;
        }

        &.asc {
          color: @link-color !important;
            border-bottom: none @link-color !important;

          &:after {
            &:extend(.icon-ft_bullit_up:before);
          }
        }

        &.desc {
          color: @link-color !important;
            border-bottom: none !important;

          &:after {
            &:extend(.icon-ft_bullit_down:before);
          }
        }
      }
    }
  }

  tr,
  th,
  td {
    vertical-align: top;
    text-align: left;
  }

  td[data-title="Titel"] {
    .lexicon & {
      a {
        .font-size(1.6);
        color: @color-brand-200;
      }
    }
  }

  th,
  td {
    padding: 15px 10px 15px 10px;

    &:first-child {
      padding-left: 38px;

      @media (max-width: 992px) {
        padding-left: 38px !important;
      }
    }

    &:last-child {
      padding-right: 38px;
    }
  }

  &.listespot-wrapper--with-buttons {
    th,
    td {
      .btn-icon-button-list {
        width: 100%;

        @media (min-width: 768px) {
          width: 188px;
        }
      }

      &.btn-icon-button-list__td {
        width: auto;

        @media (min-width: 768px) {
          width: 208px;
        }
      }
    }

    .btn-right.icon-pil-hoejre {
      width: 100%;
      background-color: #c6c6c6;
      color: #fff;
      border: 1px solid #fff;
      border-width: 1px 0;
      padding: 12px 10px 12px 15px;
      text-align: left;

      &:first-child {
        border-top: 0;
      }
    }
  }

  .questionandanswer-row {
    &:hover {
      cursor: pointer !important;
    }
  }

  tbody {
    .font-regular();

    tr.hover,
    tr:not(.none-hover-row):hover,
    tr:not(.none-hover-row):focus {
      background-color: @color-menu-selected-bg !important;

      td {
        background-color: @color-menu-selected-bg !important;
        .js-row-click & {
          cursor: pointer;
        }
      }
    }

    table:not(.tingdok__documentdownload) & {
      tr:nth-child(odd) {
        &.hover,
        &:hover {
          background-color: @color-menu-selected-bg !important;

          td {
            background-color: @color-menu-selected-bg !important;
          }
        }
      }

      tr {
        &.hover,
        &:hover {
          td + td {
            @media only screen and (min-width: @screen-md) {
              background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAIAAAAW4yFwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABNJREFUeNpiuHz5MhMDAwNAgAEADuYCfDXdYB4AAAAASUVORK5CYII=");
              background-repeat: no-repeat;
              background-size: 1px 80%;
              background-position: 0px 50%;
            }
          }
        }
      }
    }

    tr {
      td[data-title="Videolink"] a {
        color: @color-primary-link;

        &:hover {
          text-decoration: underline;
        }

        &:before {
          &:extend(.less-icon-extend);
          &:extend(.icon-ft_video:before);
          display: inline-block;
          margin-right: 3px;
        }
      }

      td {
        .font-size(1.4);
        background-color: #fff;

        + td {
          @media only screen and (min-width: @screen-md) {
            background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAACCAIAAAAW4yFwAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAABNJREFUeNpiuHz5MhMDAwNAgAEADuYCfDXdYB4AAAAASUVORK5CYII=");
          }

          background-repeat: no-repeat;
          background-size: 1px 80%;
          background-position: 0px 50%;
        }

        a {
          color: #454545;
          .font-size(1.4) !important;
          border-bottom: 0 !important;

          .result-list-spot-accordion.singelcolumn & {
            color: @color-primary-link !important;
            text-decoration: none !important;
          }
          &:hover {
            text-decoration: none;
          }

          &.column-documents__link {
            color: #454545 !important;
          }
        }

        &.highlighted {
          * {
            color: @color-brand-200 !important;
            .font-size(1.6) !important;
          }
        }

        &:first-child {
          a {
            display: inline-block;
            width: 100%;
            min-width: 160px;

            @media (min-width: 768px) {
              padding-bottom: inherit;
            }

            &.search-result-link {
              .font-size(1.6) !important;
            }
          }
        }
      }

      &.listespot-wrapper__data-item {
        .has-url:not(.highlighted) {
          a {
            color: #454545 !important;
            text-decoration: none;
          }
        }

        .highlighted {
          a {
            text-decoration: none !important;

            &:hover {
              text-decoration: underline !important;
            }
          }
        }
      }

      td[data-title="Teaser"] a {
        color: @color-primary-link;
      }

      &:nth-child(odd) {
        background-color: @color-table-alternate-row;

        td {
          background-color: @color-table-alternate-row;

          &:first-child {
            background-color: @color-table-alternate-row;
          }
        }
      }
    }
  }

  .accordion-table {
    tbody tr td a:first-child {
      display: inline;
    }

    .btn-icon-button-list__td {
      width: auto;
      float: right;
      padding-right: 10px;

      .btn-icon-button-list {
        width: auto;
        float: right;
      }
    }
  }

  &.tingdok-q-a {
    tbody {
      tr {
        &.odd {
          background: @color-brand-info-700;

          td {
            &:first-child {
              background: @color-brand-info-600;
            }
          }
        }

        &.even {
          background: @color-contrast-400;

          td {
            &:first-child {
              background: @color-contrast-300;
            }
          }
        }

        &.answer {
          &.odd {
            background: darken(@color-brand-info-700, 2%);

            td {
              &:first-child {
                background: darken(@color-brand-info-600, 2%);
              }
            }
          }

          &.even {
            background: darken(@color-contrast-400, 2%);

            td {
              &:first-child {
                background: darken(@color-contrast-300, 2%);
              }
            }
          }

          @media only screen and (max-width: @screen-md) {
            margin-top: -5px;
            margin-left: 20px;
          }

          td {
            font-style: italic;

            &:first-child {
              @media only screen and (min-width: @screen-md) {
                padding-left: 25px;
              }
            }
          }
        }
      }
    }
  }

  &.grey {
    tbody {
      tr:not(.none-hover-row) {
        &:nth-child(odd) {
          td {
            &:first-child {
              background: @color-table-alternate-row;

              &:hover:not(.none-hover-row) {
                background: @color-menu-selected-bg;
              }
            }
          }
        }

        &:nth-child(even) {
          td {
            &:first-child {
              background-color: @color-textinput;

              &:hover {
                background: @color-menu-selected-bg;
              }
            }
          }
        }

        &.has-link {
          &:nth-child(odd) {
            td {
              &:first-child {
                background: @color-table-alternate-row;

                &:hover {
                  background: transparent !important;
                }
              }
            }
          }
        }
      }

      td {
        h4 {
          a {
            .font-regular();
            .font-size(1.6);
            color: @color-brand-200;
          }
        }
      }
    }
  }

  @media only screen and (max-width: @screen-md) {
    /* Force table to not be like tables anymore */
    &:not(.standard-table) {
      table td {
        display: block;
        padding: 10px 38px 10px 38px;

        .btn-icon-button-list {
          margin: 0;
        }
      }

      table table,
      table thead,
      table tbody,
      table th,
      table td,
      table tr {
        display: block;
      }
      /* Hide table headers (but not display: none;, for accessibility) */
      /* For accessibility, to not confuse screen readers and tab navigation, we do display: none; */
      table thead tr {
        // position: absolute;
        // top: -9999px;
        // left: -9999px;
        display: none;
      }

      table tr {
        &:nth-child(odd) {
          background: @color-table-alternate-row;

          td {
            border: none;
            width: 100%;

            &:first-child {
              background: @color-table-alternate-row;
            }
          }
        }

        &:nth-child(even) {
          td {
            border: none;
            width: 100%;
          }
        }
      }

      table td {
        /* Behave  like a "row" */
        white-space: normal;
        text-align: left;
        /*test*/
        display: table;
        width: 100%;

        > span {
          display: table-cell;
        }
      }

      table {
        &.hide-labels {
          td {
            padding: 10px 38px !important;

            &:before {
              display: none;
            }
          }
        }
      }

      table td:before,
      table td span:not(.custom-checkbox):not(.icon) {
        padding-top: 5px;
        padding-bottom: 5px;
      }
      /*
  Label the data
  */
    }
  }

  .two-col-spot &,
  .three-col-spot & {
    /* Force table to not be like tables anymore */
    &:not(.standard-table) {
      table td {
        display: block;
        padding: 10px 38px 10px 38px;

        .btn-icon-button-list {
          margin: 0;
        }
      }

      table table,
      table thead,
      table tbody,
      table th,
      table td,
      table tr {
        display: block;
      }
      /* Hide table headers (but not display: none;, for accessibility) */
      table thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
      }

      table tr {
        &:nth-child(odd) {
          background: @color-table-alternate-row;

          td {
            border: none;
            width: 100%;

            &:first-child {
              background: @color-table-alternate-row;
            }
          }
        }

        &:nth-child(even) {
          td {
            border: none;
            width: 100%;
          }
        }
      }

      table td {
        /* Behave  like a "row" */
        white-space: normal;
        text-align: left;
        width: 100%;

        > span {
          display: table-cell;
        }
      }

      table {
        &.hide-labels {
          td {
            padding: 10px 38px !important;

            &:before {
              display: none;
            }
          }
        }
      }

      table td:before,
      table td span {
        padding-top: 5px;
        padding-bottom: 5px;
      }
      /*
  Label the data
  */
    }
  }

  .questionandanswer,
  .news {
    tr td:before {
      display: none;
    }

    .publications-col-description {
      width: auto;

      a {
        color: @color-primary-dark !important;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0;
        font-size: 16px !important;
      }
      h2 a {
        margin-top: 0;
        margin-bottom: 0;
      }
      &.has-url {
        a {
          color: @color-primary-function !important;

          &:before {
            display: inline-block;
            &:extend(.less-icon-extend);
            &:extend(.icon-ft_bullit_right:before);
            font-size: 11px;
            margin: 2px 8px 0 0px;
            float: left;
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .contentLineShow a {
      padding-bottom: 0;
    }
  }

  &.ankiro {
    p {
      //<strong> should be removed from the markup instead
      strong {
        font-weight: normal;
      }
    }
  }
  &.lexicon-table-container {
    tbody tr td[data-title="Titel"] {
      a {
        .font-size(1.6);
        color: @lexicon-td-1-color !important;
				display: flex;
				&:before {
					margin-top: 4px;
				}
      }
    }
    tbody tr td[data-title="Teaser"] {
      a {
        .font-size(1.4);
        color: @lexicon-td-2-color !important;
      }
    }
  }
}

.caseattheeucourtlistspot-wrapper {
  .listespot-wrapper {
    margin: 0;
  }
}

tr.memberSearchHover td {
  .listespot-wrapper tbody & {
    background-color: @color-menu-selected-bg !important;
    cursor: pointer;
  }
}

.document-case-wrapper {
  .column-documents[data-title="Kontakt"],
  .column-documents[data-title="Fornavn"],
  .column-documents[data-title="Efternavn"] {
    a {
      display: flex;
      &:hover {
        > * {
          text-decoration: underline;
        }
      }
      &:before {
        display: inline-block;
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        font-size: 11px;
        position: relative;
        margin-right: 5px;
        margin-top: 3px;
      }
    }
  }
}
