@import "../../Global/Components/sekundaer-menu-a.less";


.middle-row {
  .bg-fix {
    position: absolute;
    top: 0;
    bottom: 0;
    background-image: none;
    //�.striped-col-brand(@color: @left-menu-wrapper-stripe-color-1; @backgroundColor: @left-menu-wrapper-stripe-color-2; @height: auto; 2px);
    content: '';
  }
}
.breadcrumb-wrapper{
  margin-right: 0;
}

.left-menu-wrapper {
  z-index: 1;
  list-style: none;
  padding-top: 50px;

  .secondary-menu-container {
    margin: 20px -15px 40px -15px;
    position: relative;

    ul li.level1 > ul {
      margin-top: 20px;

      > li:first-child {
        //border-top: 0;
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      > li {
        &:first-child {
          border-top: 1px solid @color-contrast-200;
        }
      }

      ul li {
        &:last-child {
          border-bottom: 0;
        }
      }

      li {
        border-bottom: 1px solid @color-contrast-200;

        &:hover, &:focus {
          > div {
            a, i:before {
              text-decoration: none;
              color: @sekundaer-menu-a-header-menu-a-hover;
            }
          }
        }

        a {
          -ms-word-break: break-word;
          word-break: break-word;
          display: inline-block;
          width: 100%;
          color: @sekundaer-menu-a-header-menu-a;
        }

        div {
          display: table;
          width: 100%;
          position: relative;

          i {
            display: table-cell;
            vertical-align: middle;
            width: 15%;
            position: relative;

            &:before {
              &:extend(.less-icon-extend);
              .font-size(2.2);
              padding: 10px;
              color: @sekundaer-menu-a-header-menu-a-icon;
            }
          }
        }

        ul {
          display: none;
        }

        &.has-children {
          > div i {
            &:before {
              &:extend(.icon-pil-cirkel-hoejre:before);
            }
          }
        }

        &.active {
          padding-bottom: 0;
          padding-top: 0;

          > ul {
            display: block;
          }

          > div a {
            color: @sekundaer-menu-a-header-menu-a-selected;
            .font-alt();
          }

          &.has-children {
            > div i {
              &:before {
                color: @sekundaer-menu-a-header-menu-a-selected;
                &:extend(.icon-pil-cirkel-ned:before);
              }
            }
          }
        }

        &.level1 {
          border-top: 0;

          &.to-frontpage {
            border-bottom: 0;

            div {
              a {
                .font-size(@type-size-lg);
                padding: 10px 0px 10px 5px;
                color: @sekundaer-menu-a-header-menu-a;

                &:hover, &:focus {
                  text-decoration: none;
                  color: @sekundaer-menu-a-header-menu-a-hover;
                }
              }
            }
          }

          > div {
            border-bottom: 1px solid #d2d2c6;

            a {
              .font-alt();
              .font-size(@type-size-xl);
              color: @sekundaer-menu-a-header-menu-a-selected;
              padding: 0 0 0 5px;
              display: block;
            }

            i {
              display: none;
            }
          }
        }

        &.level2 {
          div {
          }

          a {
            .font-alt();
            .font-size(@type-size-lg);
            padding: 10px 0px 10px 5px;
          }
        }

        &.level3 {
          a {
            .font-alt();
            .font-size(@type-size-lg);
            padding: 10px 0px 10px 5px;
          }
        }

        &.level4 {
          a {
            .font-alt();
            .font-size(@type-size-base);
            padding: 10px 0px 10px 10px;
          }
        }

        &.level5 {
          a {
            .font-alt();
            .font-size(@type-size-md);
            padding: 10px 0px 10px 15px;
          }
        }

        &.level6 {
          a {
            .font-alt();
            .font-size(@type-size-md);
            padding: 10px 0px 10px 20px;
          }
        }

        &.level7 {
          a {
            .font-alt();
            .font-size(@type-size-md);
            padding: 10px 0px 10px 25px;
          }
        }
      }
    }
  }
}
