.sc-forms {
  max-width: 1260px;
  margin: 20px 0;
  padding: 30px 38px;
  background: #fff;

  p {
    margin: 0 0 20px;
  }

  a {
    color: @color-brand-200;
    display: block;

    &:after {
      &:extend(.less-icon-extend);
      &:extend(.icon-pil-hoejre:before);
      .font-size(@type-size-xs);
      margin-left: 5px;
      display: inline-block;
    }
  }

  &__form-group {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    position: relative;

    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }

  &__input-wrapper {
    position: relative;
    width: 100%;

    @media (min-width: 991px) {
      width: 70%;
      float: left;
    }
  }

  &__validation-wrapper {
    width: 100%;
    position: relative;
    top: 6px;
    font-size: 14px;
    color: #a6192e;
    float: left;
    margin: 0;
  }

  &__label {
    padding: 3px 0px;
    margin-bottom: 5px;
    float: left;
    clear: both;
    display: block;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    &--checkbox-list,
    &--radio-list,
    &--checkbox-item,
    &--radio-item {
      float: none;
      margin-bottom: 0;
    }

    .custom-radio {
      float: right;
      margin-left: 10px;
    }

    /* Visually hide label, but still accessible
    https://www.w3.org/WAI/tutorials/forms/labels/ */
    &--visually-hidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
  }

  &__upload-label {
    margin-left: 0 !important;
    padding: 10px 25px;
    min-width: 0;
    background-color: @color-primary-function-bg;
    color: white;

    &:hover {
      background: @color-primary-function-hover;
    }
  }

  &__upload-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &__input {
    display: block !important;
    box-shadow: 0 0 0 0;
    border: 1px solid #f0f2f2;
    height: 62px;
    width: 100%;
    font-size: 16px;
    line-height: 45px;
    padding: 6px 12px;
    color: #555;
    background-color: #fff;
    background-image: none;
    background: #f0f2f2;
    border: 0;
    color: #555;
    font-family: arial, serif;
    padding-left: 20px;
  }

  .scfFileUploadLabel {
    display: block;
    width: auto;
    height: auto;
    line-height: inherit;
    min-width: 0;
    max-width: 70%;
    color: #ffffff !important;
    margin-left: 0% !important;
    padding: 10px 25px;
    border-color: #005495;
    font-family: SceneAlt, serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    cursor: pointer;
    letter-spacing: 1px;
    outline: none;
    position: relative;
    border: 0;
    text-decoration: none;
    margin-right: 5px;
    border-radius: 0;
    font-size: 18px;
    background-color: #2375bb;

    &:hover {
      background-color: #0062ae;
    }

    border-color: #003157;
  }

  form {
    padding: 0;
    width: 100%;

    @media (min-width: 767px) {
      max-width: 70%;
    }
  }

  input[type="submit"] {
    display: inline-block;
    position: relative;
    border: 0;
    margin-right: 5px;
    min-width: 165px;
    height: auto;
    font-family: SceneAlt, serif;
    font-size: 18px;
    line-height: 22px;
    color: #fff;
    background-color: @color-primary-function-bg;
    padding: 10px 50px 10px 50px;
    text-align: center;
    width: 100%;
    position: relative;
    text-decoration: none;

    &:hover {
      background-color: @color-primary-function-hover;
      text-decoration: none;
    }

    @media (min-width: 767px) {
      width: auto;
      float: left;
    }
  }

  input:invalid {
    font-weight: 400 !important;
  }

  textarea {
    resize: none;
    padding: 20px;
    min-height: 128px;
  }
  /*Need to assign the style to the span because input does't support psuedo*/
  *[data-val-required] + .sc-forms__mandetory-container {
    content: "";
    position: absolute;
    top: 22px;
    right: -8px;
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid @color-primary-function-bg;
    border-right: 8px solid transparent;
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  .scfFileUploadBorder {
    display: table;
  }

  .scfFileUpload {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .text-spot-a-wrapper {
    padding-left: 0;
    padding-right: 0;

    p {
      margin-bottom: 15px;
    }
  }

  // custom checkbox + radio buttons
  // when label wraps around input field
  &__label-wrapper {
    display: grid;
    grid-template-columns: 1.4em auto;
    gap: 0.25em;
    margin-bottom: 0;

    input[type="checkbox"],
    input[type="radio"] {
      opacity: 1;
      margin: 0;
      -webkit-appearance: none;
      appearance: none;
      /* For iOS < 15 to remove gradient background */
      background-color: #fff;
      color: currentColor;
      width: 22px;
      height: 22px;
      border: 2px solid #7f7f7f;
      transform: translateY(-0.075em);

      display: grid;
      place-content: center;

      @media (min-width: @screen-sm) {
        border: 1px solid #7f7f7f;
      }

      &::before {
        content: "";
      }

      &:focus {
        outline: 5px auto -webkit-focus-ring-color;
        outline-offset: 0;
      }
    }

    input[type="checkbox"] {
      border-radius: 2px;

      &::before {
        position: absolute;
        top: 2px;
        left: 2px;
        width: 22px;
        height: 22px;
      }

      &:checked {
        &:extend(.less-icon-extend);

        &:before {
          &:extend(.icon-checkmark:before);
          color: @color-primary-function-bg;
        }
      }
    }

    input[type="radio"] {
      border-radius: 22px;

      &::before {
        content: "";
        width: 10px;
        height: 10px;
      }

      &:checked {
        &:before {
          content: "";
          background: @color-brand-300;
          border-radius: 22px;
        }
      }
    }
  }
}
