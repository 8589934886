﻿@import "../../Global/Components/cookiewarning.less";

// Banner
#CybotCookiebotDialogBodyContent {
  padding-left: 6px !important;
}
#CybotCookiebotDialog {
  background: #eaeaea !important;
}
#CybotCookiebotDialogDetail,
#CybotCookiebotDialogDetailFooter {
  background: #eaeaea !important;
}
#CybotCookiebotDialogPoweredbyLink {
  display: none !important;
}
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
#CybotCookiebotDialogBodyLevelButtonLevelOptinDeclineAll {
  font-size: 1.6rem !important;
  line-height: 2.16rem !important;
  padding: 10px 20px !important;
  min-width: unset !important;
  width: auto !important;
  font-family: SceneAlt, serif !important;
  font-weight: 400 !important;
  font-style: normal !important;

  .red & {
    background-color: #bb4752 !important;
    border-color: #bb4752 !important;
    color: #fff !important;
  }

  .green & {
    background-color: #008567 !important;
    border-color: #008567 !important;
    color: #fff !important;
  }

  .gray & {
    background-color: #6b6b6b !important;
    border-color: #6b6b6b !important;
    color: #fff !important;
  }

  .blue & {
    background-color: #2375bb !important;
    border-color: #2375bb !important;
    color: #fff !important;
  }

  cursor: pointer !important;
  display: inline-block !important;
  letter-spacing: 1px !important;
  outline: none !important;
  position: relative !important;
  border: 0 !important;
  text-decoration: none !important;
  text-align: center !important;
  vertical-align: middle !important;
  white-space: nowrap !important;
  height: auto !important;

  &:focus {
    outline: thin dotted !important;
    outline: 5px auto -webkit-focus-ring-color !important;
    outline-offset: 0px;
  }
}

.CybotCookiebotDialogBodyLevelButtonWrapper .custom-checkbox,
.CybotCookiebotDialogBodyLevelButtonWrapper .custom-radio {
  width: 17px !important;
  height: 17px !important;
}
#CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelectionWrapper {
  display: flex !important;
  flex-wrap: wrap !important;
  float: none !important;
  position: relative !important;
  left: -7px !important;
  justify-content: flex-start !important;
}
#CybotCookiebotDialogBodyLevelButtonsTable {
  width: 100% !important;
}
#CybotCookiebotDialogBodyLevelButtons {
  margin-right: 0px !important;
  float: none !important;
  width: 100% !important;
  margin-left: 5px !important;
}
#CybotCookiebotDialogBodyContentTitle {
  font-family: SceneAlt, serif !important;
}
#CybotCookiebotDialogBodyContentText {
  font-family: SceneAlt, serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  -webkit-font-smoothing: antialiased !important;
}
#CybotCookiebotDialogBodyLevelButtonsTable {
  width: 100% !important;
}
#CybotCookiebotDialogBodyLevelButtonsSelectPane {
  border-radius: unset !important;
}
#CybotCookiebotDialogDetailBodyContentTab:first-child {
  border-radius: unset !important;
}
#CybotCookiebotDialogDetailBody {
  max-width: 1252px !important;
}
#CybotCookiebotDialogBody {
  max-width: 1260px !important;
}

// Page
.CookieDeclarationType {
  margin-bottom: 50px !important;
  padding: 0 !important;
  border: 0 !important;
  color: #454545 !important;
}
.CookieDeclarationTypeHeader {
  font-family: SceneAlt, serif !important;
  font-size: 2rem !important;
  line-height: 2.4rem !important;
  margin-bottom: 15px !important;
}
.CookieDeclarationTable tr td {
  font-size: 1.4rem !important;
  line-height: 1.68rem !important;
  padding: 15px 10px 15px 10px !important;
  border-bottom: 0 !important;
}
.CookieDeclarationTableCell a {
  display: block;
}
.CookieDeclarationTable tr td:first-of-type {
  padding-left: 38px !important;
}
.CookieDeclarationTableHeader {
  padding: 15px 10px 15px 10px !important;
  font-size: 1.2rem !important;
  font-family: SceneAlt, serif !important;
  font-weight: 400 !important;
  border-bottom: 0 !important;
}
.CookieDeclarationTableHeader:first-of-type {
  padding-left: 38px !important;
}
.CookieDeclarationTable tr:nth-child(odd) td {
  background-color: #f7f7f7;
}
.CookieDeclaration p:not(.CookieDeclarationIntro),
.CookieDeclaration div:not(#CookieDeclarationUserStatusPanel) > div,
.CookieDeclaration #CookieDeclarationChangeConsent,
.CookieDeclaration #CookieDeclarationDoNotSell {
  padding: 0 38px !important;
}
.CookieDeclarationTable {
  border-collapse: unset !important;
  background: white !important;
}
.CookieDeclarationIntro {
  padding: 30px 38px !important;
  background: white;
}

.CookieDeclaration a {
  &:before {
    content: "";
    display: none;
  }

  &:after {
    display: inline-block;
    &:extend(.less-icon-extend);
    &:extend(.icon-ft_bullit_right:before);
    font-size: 11px;
    position: relative;
    margin-left: 5px;
  }
}
