﻿@import "../styles.less";

.linklist-title {
    display: inline-block;
    width: 100%;
    .font-bold();
    .font-size(@type-size-xxl);
    color: @color-text;
}

.linklist-spot {
    .table-striped {
        tbody {
            tr {
                &:hover {
                    td, td:first-child {
                        //cursor: pointer;
                        background-color: darken(@table-bg-accent, 10%);
                    }
                }
            }
        }
    }
}


