.subsite-wide {
  .forum-create-post {
    .phone-input {
      display: flex;
      position: relative;

      &__country-code {
        flex-basis: auto;
        flex-grow: 1;
        flex-shrink: 1;
        margin-right: 5px;
        padding-left: 30px !important;
      }

      &__number {
        flex-basis: 100%;
        flex-shrink: 1;
        margin-left: 5px;
      }

      span {
        font-size: @sw-font-md;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
      }
    }
  }
}
