﻿@import "../styles.less";

.wrapper {
  .logo-beta {
    .site-identity {
      padding-bottom: 14px!important;   
      .site-ft &{
        padding-bottom: 0!important;  
      }
    }
  }

  &.header-container {
    background: @color-contrast-300;

    @media(max-width: @screen-xs-max) {
      .site-identity-container {
        display: table-cell;
        width: 100%;
        padding-right: 10px;
      }

      .xs-menu {
        display: table-cell !important;
        vertical-align: top;
        padding-left: 10px;
      }

      border-top: 10px solid @color-brand-300;

      .toggle-menu, .toggle-search {
        text-align: center;
        cursor: pointer;

        .icon-menu, .icon-soeg {
          display: inline-block;
          background: @color-brand-300;
          color: @color-text-light;
          .font-size(@type-size-xxl);
          padding: 5px 11px;
          margin: 14px 0px 0px 0px;
          width: 42px;
          border-radius: 3px;
        }

        .icon-soeg {
          &:before {
            position: relative;
            top: 2px;
          }
        }

        .text {
          display: block;
          color: @color-brand-300;
          width: 41px;
        }
      }
    }

    .logo-beta {
      .site-identity {
        padding-bottom: 14px!important;
        .site-logo {
          .desktop-logo {
            display: none !important;

            @media(min-width: @screen-sm) {
              display: block !important;
            }
          }
        }

        .site-title {
          &.desktop-title {
            display: none !important;

            @media(min-width: @screen-sm) {
              display: table !important;
            }
          }
        }
      }
    }

    .site-identity {
      //border-top: 2px solid @color-brand-200;
      margin-top: 2em;
      padding-top: 1em;

      @media(max-width:@screen-xs-max) {
        padding-top: 0em;
      }

      .clearfix();
      display: block;

      @media(max-width: @screen-xs-max) {
        width: 100%;
        margin: 0.3em 0 1em 0;
      }


      .site-title {
        .font-regular();
        .font-size(@type-size-site-title);
        //text-transform: uppercase;
        display: table-row;
        vertical-align: top;
        margin: 0 auto;
        margin-top: -15px;

        @media(max-width:@screen-xs-max) {
          vertical-align: middle;
          padding-top: 5px;
        }

        a {
          display: block;
          margin-top: 15px;
          color: @primaer-menu-a-header-menu-a;

          @media(max-width:861px) {
            .font-size(@type-size-sm);
          }

          @media(max-width:@screen-xs-max) {
            .font-size(@type-size-base);
          }

          &:hover {
            text-decoration: none;
            color: @primaer-menu-a-header-menu-a-hover;
          }
        }

        @media(min-width:@screen-sm) {
          //width: 65%;
        }
      }

      .site-logo {
        display: table-row;
        vertical-align: top;

        @media(max-width:@screen-xs-max) {
          vertical-align: middle;
          padding-top: 5px;
        }

        text-align: center;

        a {
          width: 100%;
          height: 70px;
          display: block;

          @media(max-width:@screen-xs-max) {
            width: 100%;
            height: 55px;
          }
        }

        img {
          width: auto;
          height: auto;
          margin-left: -37px;
          max-width: 213px;
          position: relative;
          left: 5px;

          @media(min-width:@screen-sm) {
            position: static;
            margin-left: auto;
            max-width: 250px;
            max-height: 170px;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .header-menu {
      @media(min-width:@screen-sm) {
        height: 200px;
      }

      position: relative;

      a {
        color: @primaer-menu-a-header-menu-a;

        h4 {
          .font-size(@type-size-sm*2);
          overflow: hidden;
          -ms-text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          text-overflow: ellipsis;
        }

        p {
          .font-size(@type-size-sm);
        }

        border-bottom: 2px solid transparent;

        &.selected {
          border-bottom: 2px solid @primaer-menu-a-header-menu-a-selected;
          text-decoration: none;

          h4 {
            color: @primaer-menu-a-header-menu-a-selected;
          }
        }

        &:hover {
          border-bottom: 2px solid @primaer-menu-a-header-menu-a-hover;
          text-decoration: none;

          h4 {
            color: @primaer-menu-a-header-menu-a-hover;
          }
        }

        display: block;
        width: 100%;

        @media(min-width:@screen-sm) {
          position: absolute;
          bottom: 0;
          height: 110px;
        }
      }
    }


    @media(min-width:@screen-sm) {
      height: 200px;
    }
  }
}
