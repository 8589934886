﻿@import "../styles.less";

.tab-spot-a-wrapper {
}
.tab-spot-a__tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  .clearfix();

  &__item {
    display: block;
    float: left;
    background-color: @color-secondary-lighter;

    &:hover {
      background-color: @color-tertiary-result-absent;
    }

    &.active {
      background-color: #fff;
    }
    /* one item */
    &:first-child:nth-last-child(1) {
      width: 100%;
    }
    /* two items */
    &:first-child:nth-last-child(2),
    &:first-child:nth-last-child(2) ~ li {
      width: 50%;
    }
    /* three items */
    &:first-child:nth-last-child(3),
    &:first-child:nth-last-child(3) ~ li {
      width: 33.3333%;
    }
    /* four items */
    &:first-child:nth-last-child(4),
    &:first-child:nth-last-child(4) ~ li {
      width: 25%;
    }
    /* five items */
    &:first-child:nth-last-child(5),
    &:first-child:nth-last-child(5) ~ li {
      width: 20%;
    }

    &__link {
      .font-size(2);
      .font-alt();

      .content-area & {
        color: @color-primary-function;
      }

      line-height: 80px;
      text-align: center;
      display: block;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.tab-spot-a__content {
  .clearfix();

  &__pane {
    background-color: #fff;
    padding: 38px;
    display: none;

    .pageedit & {
      display: block !important;
    }

    .clearfix();

    &.active {
      display: block;
      background-color: #fff;
      padding: 38px;
    }

    .text-spot-a-wrapper {
      padding-left: 0;
      padding-right: 0;

      .read-more-expander {
        background-color: #fff;
        border: 0;
      }
    }
  }
  .panel-group .panel-middle .panel-body p {
    margin-left: 0;
  }
  [class*=col] p {
    padding-left:0;
  }
}
