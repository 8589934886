﻿@import "../Variables/_colors.less";
@import "../Variables/_typography.less";

.CalendarEventList {
  .clearfix();

  dt {
    div {
      background: @calendar-events-datebox-background-blue;
      height: 75px;
      color: @calendar-events-datebox-color;
      .font-size(3.5);
      text-align: center;
      padding: 4px 0 0 0;

      &.Blue {
        background: @calendar-events-datebox-background-blue;
      }

      span {
        .font-size(@type-size-md);
        display: block;
        padding-top: 2px;
        text-transform: capitalize;
      }
    }
  }

  h2 {
    .font-size(@type-size-xxl);
    margin: 0 0 2px 0;

    a {
      color: @calendar-events-headline-main;
    }
  }

  h4 {
    margin: 0 0 10px 0;
    color: @calendar-events-headline-type;
    text-transform: uppercase;
    .font-size(@type-size-md);
  }

  h5 {
    color: @calendar-events-headline-date;
    .font-size(@type-size-lg);
    margin: 0 0 10px 0;
  }

  p {
    margin-bottom: 2px;
    .font-size(@type-size-md);
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      margin: 0 0 3px 0;
      padding: 0;

      strong {
        display: inline;
        .font-size(@type-size-base);
      }
    }
  }
}

#NewsAndEventList {
  margin-top: 20px;
}

.EventListHeader {
  text-transform: uppercase;
  .font-size(@type-size-base);
  margin: 0;
  padding: 0 0 5px 0;
  font-weight: bold;
}

.NoResults {
  text-transform: uppercase;
  .font-size(@type-size-base);
  margin: 0;
  padding: 20px 0 0 0;
  border-top: 1px solid @calendar-events-no-results;
}

.EventsFilterControl {
  .ErrorList {
    margin: 0;
    padding: 0;
    list-style: none;

    span {
      line-height: 1.6em;
    }
  }

  label {
    font-size: @font-size-base;
  }
}

#CalendarHelpText {
  position: absolute;
  display: none;
  background: @calendar-help-background;
  color: @calendar-help-text;
  border: 1px solid @calendar-help-border;
  padding: 3px;
  .font-size(@type-size-base);
  z-index: 1000;
  top: 120px;
  left: 459px;
}

#MeetingCalendar {
  @media (max-width: @screen-sm) {
    display: none;
  }

  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
  background-color: #fff;
  padding: 20px;
  position: relative;

  * {
    -ms-user-select: none; /* IE 10+ */
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    user-select: none;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 20px;

      caption {
        text-align: center;
        padding-bottom: 8px;
        .font-alt();
        .font-size(1.6);
        color: @color-text;

        a {
          color: @calendar-month-headline;
        }
      }

      &.Last {
        margin-right: 0;
      }
    }
  }

  table {
    .font-size(@type-size-xs);
    .font-bold();
    width: 100%;

    thead {
      tr {
        height: auto;

        th {
          padding: 6px 4px;
          margin: 0;
          text-align: center;
          color: @color-text;
          .font-size(1.2);
          font-weight: 700;
          opacity: 0.4;
        }
      }
    }

    tbody {
      tr {
        &.LastRow {
          td {
            border-bottom: 0;
            border-top: none;
          }
        }

        td {
          padding: 5px 5px 3px 5px;
          margin: 0;
          text-align: center;
          border-width: 1px 1px 1px 0;
          cursor: pointer;
          .font-size(1.2);
          font-weight: 400;
          color: @color-text;

          span {
            display: block;
            width: 23px;
            height: 23px;
            border-radius: 50%;
            line-height: 23px;
            text-align: center;
          }

          &.Highlight {
            span {
              color: @calendar-day-highlight-color;
              background: @calendar-day-selected-background;
            }
          }

          &.Highlight-2 {
            span {
              color: @text-color;
              border: 1px solid @calendar-day-selected-background;
            }
          }

          &:hover {
            span {
              background: @calendar-day-hover-background;
              color: @calendar-day-hover-color;
            }
          }

          &.Selected {
            span {
              color: @calendar-day-selected-color;
              background: @calendar-day-selected-background;
            }
          }

          &.Greyed {
            span {
              background: @calendar-day-greyed-background;
              color: @calendar-day-greyed-color;
              cursor: default;
            }
          }

          &.Unselectable {
            span {
              color: @calendar-day-unselectable-color;
              background: @calendar-day-unselectable-background;
              cursor: default;
            }
          }

          &.NotSelectable {
            span {
              color: @calendar-day-notselectable-color;
              background: @calendar-day-notselectable-background;
            }
          }
        }
      }
    }

    @media screen and (-webkit-min-device-pixel-ratio:0) {
      td {
        padding: 6px 4px 4px 4px;
      }
    }

    noindex:-o-prefocus, td {
      padding: 6px 4px 4px 4px;
    }

    .Last {
      color: @text-color;
      border-right: 0;
    }
  }
}

#cases-filter {
  .search-field {
    @media (max-width: @screen-sm) {
      width: 95%;
    }
  }

  .input-group.pull-right {
    @media (max-width: @screen-sm) {
      width: 95%;
    }
  }
}

.yearcalendar {
  &__header {
    margin-top: 40px;
    margin-bottom: 15px;

    @media (max-width: @screen-sm) {
      margin-left: 23px !important;
    }
  }

  &__title {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    text-align: left;
    margin: 0;
    padding-left: 23px;
    .module-headline();

    @media (max-width: @screen-sm) {
      padding-left: 0;
    }
  }

  &__link {
    &:after {
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_bullit_right:before);
      .font-size(@type-size-xs);
      margin-left: 5px;
      display: inline-block;

      @media (max-width: @screen-sm) {
        padding-left: 0;
      }
    }

    &__container {
      font-family: SceneAlt,serif;
      text-align: right;
      padding-right: 23px;
      position: relative;
      top: 9px;

      @media (max-width: @screen-sm) {
        position: inherit;
        padding-right: 0;
        text-align: left;
      }
    }
  }
}

.calendar-body {
  .clearfix();
}

#YearCalendar {
  @media (max-width: @screen-sm) {
    //display: none;
    padding: 20px 0;
  }

  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
  background-color: #fff;
  padding: 20px;
  position: relative;

  * {
    -ms-user-select: none; /* IE 10+ */
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    user-select: none;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 20px;

      caption {
        text-align: center;
        padding-bottom: 8px;
        .font-alt();
        .font-size(1.6);
        color: @color-text;

        a {
          color: @calendar-month-headline;
        }
      }

      &.Last {
        margin-right: 0;
      }
    }
  }

  table {
    .font-size(@type-size-xs);
    .font-bold();
    width: 100%;

    thead {
      tr {
        height: auto;

        th {
          padding: 6px 4px;
          margin: 0;
          text-align: center;
          color: @color-text;
          .font-size(1.2);
          font-weight: 700;
          opacity: 0.4;
        }
      }
    }

    tbody {
      tr {
        &.LastRow {
          td {
            border-bottom: 0;
            border-top: none;
          }
        }

        td {
          padding: 5px 5px 3px 5px;
          margin: 0;
          text-align: center;
          border-width: 1px 1px 1px 0;
          cursor: pointer;
          .font-size(1.2);
          font-weight: 400;
          color: @color-text;
          position: relative;

          @media (min-width: @container-tablet) and (max-width:@container-large-desktop) {
            .font-size(1.1);
          }

          span {
            display: block;
            width: 23px;
            height: 23px;
            border-radius: 50%;
            line-height: 25px;
            text-align: center;
            position: relative;
            left: 50%;
            -moz-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);

            @media (min-width: @container-tablet) and (max-width:@container-large-desktop) {
              width: 19px;
              height: 19px;
            }
          }

          &.state1 {
            span {
              color: @text-color;
              border: 1px solid @calendar-day-selected-background;
            }

            .yearcalendar__day-target {
              color: @color-text;

              &:focus {
                outline: 0;
                box-shadow: 0 3px 10px 4px @color-primary-function;
              }
            }
          }

          &.state2 {
            span {
              color: @calendar-day-highlight-color;
              background: @calendar-day-selected-background;
            }

            .yearcalendar__day-target {
              color: #fff;

              &:focus {
                outline: 0;
                box-shadow: 0 3px 10px 4px @color-primary-function;
              }
            }
          }

          &.state3 {
            span {
              background: @calendar-day-hover-background;
              color: @calendar-day-hover-color;
            }

            .yearcalendar__day-target {
              color: @color-text;

              &:focus {
                outline: 0;
                box-shadow: 0 3px 10px 4px @color-primary-function;
              }
            }
          }

          &.Selected {
            span {
              color: @calendar-day-selected-color;
              background: @calendar-day-selected-background;
            }
          }

          &.Greyed {
            span {
              background: @calendar-day-greyed-background;
              color: @calendar-day-greyed-color;
              cursor: default;
            }
          }

          &.Unselectable {
            span {
              color: @calendar-day-unselectable-color;
              background: @calendar-day-unselectable-background;
              cursor: default;
            }
          }

          &.NotSelectable {
            span {
              color: @calendar-day-notselectable-color;
              background: @calendar-day-notselectable-background;
            }
          }
        }
      }
    }

    @media screen and (-webkit-min-device-pixel-ratio:0) {
      td {
        padding: 6px 4px 4px 4px;
      }
    }

    noindex:-o-prefocus, td {
      padding: 6px 4px 4px 4px;
    }

    .Last {
      color: @text-color;
      border-right: 0;
    }
  }

  .yearcalendar {
    &__meeting-info {
      display: none;
    }


    &__day {
      position: absolute;
      top: 50%;
      left: 50%;
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      height: 9px;
      line-height: 11px;

      &-target {
        position: absolute;
        //top: 0;
        right: 0;
        bottom: 0;
        //left: 0;
        top: 50%;
        left: 50%;
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
      }
    }
  }

  .popover {
    border-radius: 0;
    padding: 0;
    min-width: 200px;
    border: 1px solid @calendar-day-selected-background;

    &.top {
      > .arrow {
        &:after {
          border-top-color: @calendar-day-selected-background;
        }
      }
    }

    .popover-title {
      display: none;
    }

    .popover-content {
      padding: 0;

      &__container {
        max-height: 300px;
        overflow: auto;
      }
    }

    .yearcalendar {
      &__meeting-info {
        display: block;
        padding: 9px 14px;
        height: 100px;
        border-top: 1px solid #98002f;

        &:first-child {
          border-top: @color-brand-200;
        }

        &.state1 {
          color: @text-color;
          //border: 1px solid @calendar-day-selected-background;
        }

        &.state2 {
          color: @calendar-day-highlight-color;
          background: @calendar-day-selected-background;

          a {
            color: #fff;

            &:before {
              &:extend(.less-icon-extend);
              &:extend(.icon-ft_bullit_right:before);
              .font-size(@type-size-xs);
              margin-right: 5px;
              display: inline-block;
            }
          }
        }

        &.state3 {
          background: @calendar-day-hover-background;
          color: @calendar-day-hover-color;
        }
      }
    }
  }
}

//News calendar
.form-group-date-inline {
  width: 219px;

  .icon-kalender {
    .font-size(@type-size-xxl * 1.1);
    color: @color-brand-200;
  }
}

.form-group-inline {
  width: 100%;
  margin-bottom: 10px;

  @media screen and (min-width: 750px) {
    width: 454px;
    float: left;
  }
}

.NoScript {
  display: block;
  margin-left: 8px;
}

.calendarevent {
  .calendareventrichtext {
    margin: 10px 0 20px;

    a {
      color: @color-brand-200;
    }

    ul {
      li {
        a {
          color: @color-brand-200;
        }
      }
    }
  }
}

.datepicker {
  table tr {
    th {
      background: #ffffff;
      color: @color-brand-300;
      font-weight: normal;
      .font-size(1.6);

      &.dow {
        background: #ffffff;
        color: @color-primary-dark;
        font-weight: 700;
        opacity: 0.4;
        .font-size(1.4);
      }
    }

    td {
      &.old.day, &.new.day {
        background: @color-secondary-lighter;
        color: @color-primary-darker;
        opacity: 0.7;
        .font-size(1.4);

        &:hover, &.active, &:hover:last-child {
          background: @color-brand-300;
          border-color: #ffffff;
          color: #ffffff;
        }

        &:last-child {
          background: @color-secondary-lighter;
          color: @color-primary-darker;
        }
      }

      &.day, &.day:last-child {
        background: @color-secondary-light;
        color: @color-primary-darker;
        font-weight: normal;
        .font-size(1.4);
      }

      &:hover,
      &.active,
      &.active.active,
      &.active:hover.active,
      &.day:hover {
        background: @color-brand-300;
        border-color: #ffffff;
        color: #ffffff;
      }

      &:hover {
        background: @color-contrast-300;
      }

      &:last-child {
      }
    }

    .month, .year {
      background: @color-secondary-light;
      border-radius: 0;
      color: @color-primary-darker;
      font-weight: normal;
      .font-size(1.6);
      line-height: 54px;

      &.active {
        background: @color-secondary-light;
        color: @color-primary-darker;
      }
    }

    .month:hover,
    .month.active.active,
    .month.active.active:hover,
    .year:hover,
    .year.old:hover,
    .year.new:hover,
    .year.active.active,
    .year.active.active:hover {
      background: @color-secondary-light;
      color: @color-brand-300;
    }

    .year {
      &.old, &.new {
        color: @color-primary-darker;
        opacity: 0.7;
      }
    }
  }

  thead tr:first-child th:hover {
    background: #ffffff;
    color: @color-primary-function-hover;
  }

  .prev,
  .next {
    font-size: 0;
    padding-top: 8px;

    &:before {
      font-family: "ft-icons";
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      color: @color-brand-300;
      .font-size(1.4);
    }

    &:hover {
      &:before {
        color: @color-primary-function-hover;
      }
    }
  }

  .prev {
    -ms-transform: rotate(180deg); /* IE 9 */
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);

    &:before {
      content: "\e631";
    }
  }

  .next {
    &:before {
      content: "\e631";
    }
  }
}


//BUG: 40117
@media (max-width: @container-tablet) {
  .filter-adjacent {
    padding: 20px 38px !important;

    .form-group-date-inline {
      width: 100% !important;
    }

    .form-group-date > input {
      width: 87%;
    }

    a, input[type=submit] {
      max-width: 100%;
      width: 100%;
      margin-top: 13px;
      margin-right: 0;
    }
  }
}
//END BUG: 40117
