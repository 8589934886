.subsite-wide {
  .favorite-post {
    display: inline-flex;

    &__count {
      font-size: 20px;
      line-height: 25px;
      padding-right: 5px;
    }

    &__svg {
      background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjciIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNyAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTI0LjA3MjMgMi45ODkxNkMyMy40NjE0IDIuMzU4NTQgMjIuNzM2MSAxLjg1ODI5IDIxLjkzNzggMS41MTY5OEMyMS4xMzk2IDEuMTc1NjcgMjAuMjgzOSAxIDE5LjQxOTkgMUMxOC41NTU4IDEgMTcuNzAwMSAxLjE3NTY3IDE2LjkwMTkgMS41MTY5OEMxNi4xMDM2IDEuODU4MjkgMTUuMzc4MyAyLjM1ODU0IDE0Ljc2NzQgMi45ODkxNkwxMy40OTk3IDQuMjk3MzJMMTIuMjMxOSAyLjk4OTE2QzEwLjk5OCAxLjcxNTk1IDkuMzI0NTEgMS4wMDA2NiA3LjU3OTUxIDEuMDAwNjZDNS44MzQ1MiAxLjAwMDY2IDQuMTYwOTkgMS43MTU5NSAyLjkyNzA5IDIuOTg5MTZDMS42OTMyIDQuMjYyMzggMSA1Ljk4OTIzIDEgNy43ODk4M0MxIDkuNTkwNDQgMS42OTMyIDExLjMxNzMgMi45MjcwOSAxMi41OTA1TDQuMTk0ODUgMTMuODk4N0wxMy40OTk3IDIzLjVMMjIuODA0NSAxMy44OTg3TDI0LjA3MjMgMTIuNTkwNUMyNC42ODM0IDExLjk2MDIgMjUuMTY4MiAxMS4yMTE4IDI1LjQ5OSAxMC4zODgxQzI1LjgyOTggOS41NjQzNSAyNiA4LjY4MTQ2IDI2IDcuNzg5ODNDMjYgNi44OTgyMSAyNS44Mjk4IDYuMDE1MzIgMjUuNDk5IDUuMTkxNkMyNS4xNjgyIDQuMzY3ODkgMjQuNjgzNCAzLjYxOTQ5IDI0LjA3MjMgMi45ODkxNloiIHN0cm9rZT0iIzJCMkIyQiIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      width: 27px;
      height: 25px;
      cursor: pointer;
      object-fit: cover;
      transition: background-color 150ms ease-in-out;
      background-color: transparent;
      border: none;
      padding: 0;
      transform: scale(1);

      &:focus {
        outline: none;
      }

      &:focus-visible {
        outline: 2px dashed;
      }
    }
    &.post--liked {
      .favorite-post__svg {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjUiIGhlaWdodD0iMjMiIHZpZXdCb3g9IjAgMCAyNSAyMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTIzLjA3MjMgMS45ODkxNkMyMi40NjE0IDEuMzU4NTQgMjEuNzM2MSAwLjg1ODI4NiAyMC45Mzc4IDAuNTE2OTc5QzIwLjEzOTYgMC4xNzU2NzIgMTkuMjgzOSAwIDE4LjQxOTkgMEMxNy41NTU4IDAgMTYuNzAwMSAwLjE3NTY3MiAxNS45MDE5IDAuNTE2OTc5QzE1LjEwMzYgMC44NTgyODYgMTQuMzc4MyAxLjM1ODU0IDEzLjc2NzQgMS45ODkxNkwxMi40OTk3IDMuMjk3MzJMMTEuMjMxOSAxLjk4OTE2QzkuOTk4MDMgMC43MTU5NDYgOC4zMjQ1IDAuMDAwNjU5OTUzIDYuNTc5NTEgMC4wMDA2NTk5NjZDNC44MzQ1MiAwLjAwMDY1OTk4IDMuMTYwOTkgMC43MTU5NDYgMS45MjcwOSAxLjk4OTE2QzAuNjkzMTk2IDMuMjYyMzggMS4zMDAxMmUtMDggNC45ODkyMyAwIDYuNzg5ODNDLTEuMzAwMTJlLTA4IDguNTkwNDQgMC42OTMxOTYgMTAuMzE3MyAxLjkyNzA5IDExLjU5MDVMMy4xOTQ4NSAxMi44OTg3TDEyLjQ5OTcgMjIuNUwyMS44MDQ1IDEyLjg5ODdMMjMuMDcyMyAxMS41OTA1QzIzLjY4MzQgMTAuOTYwMiAyNC4xNjgyIDEwLjIxMTggMjQuNDk5IDkuMzg4MDdDMjQuODI5OCA4LjU2NDM1IDI1IDcuNjgxNDYgMjUgNi43ODk4M0MyNSA1Ljg5ODIxIDI0LjgyOTggNS4wMTUzMiAyNC40OTkgNC4xOTE2QzI0LjE2ODIgMy4zNjc4OSAyMy42ODM0IDIuNjE5NDkgMjMuMDcyMyAxLjk4OTE2WiIgZmlsbD0iI0E2MTkyRSIvPgo8L3N2Zz4K");
      }
    }
  }

  .validate-btn-loading {
    pointer-events: none;
  }

  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 60px;
    margin-left: 20px;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 0px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: @sw-color-primary;
    background: var(--sw-color-primary, @sw-color-primary);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 5px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 5px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 22px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 46px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }

    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }

    100% {
      transform: translate(24px, 0);
    }
  }
}
