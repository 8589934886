﻿
//FT custome style since this files load on the page
.site-ft {
  .negotiation {
    &__header {
      position: relative;

      @media screen and (max-width: @screen-sm) {
        position: relative;
        left: 15px;
      }

      &--players {
        position: absolute;
        top: 0;
        right: 15px;
        padding-right: 0;

        @media screen and (max-width: @screen-sm) {
          position: static;
          display: table-header-group;
        }

        ul li {
          @media screen and (max-width: @screen-sm) {
            margin-left: 0;
            margin-bottom: 10px;
          }
        }
      }

      &--info {
        @media screen and (max-width: @screen-sm) {
          display: table-footer-group;
        }
      }
    }
  }
}

.negotiation {

  &__show-all-videos {
    color: @color-primary-function;
    display: inline-block;
    font-size: 16px;
    margin-bottom: 25px;

    &:after {
      &:extend(.less-icon-extend);
      &:extend(.icon-ft_video:before);
      display: inline-block;
      margin-left: 10px;
    }
  }

  &__item {
    border-top: 1px solid @color-tablecell-divider;
    padding: 20px 0;

    &:not(.speakType1):not(.speakType3) {
      .is-only-priority & {
        display: none;
      }
    }
  }

  &__time {
    color: @color-secondary-darker;
    margin-bottom: 5px;
  }

  &__title {
    .font-size(1.4);
    margin-bottom: 5px;
  }

  &__name {
    .font-size(1.6);
    margin-bottom: 5px;
  }

  &__bottom {
    margin-top: 15px;

    @media screen and (max-width: @container-tablet) {
      padding: 0 15px;
    }

    .is-all-closed & {
      display: none;
    }
  }


  &__controls {
    list-style: none;
    margin: 0;
    padding: 0;
    float: right;

    @media screen and (max-width: @screen-sm) {
      float: none;
    }

    &__li {
      display: inline-block;
      margin-left: 20px;

      &:first-child {
        @media screen and (max-width: @container-tablet) {
          margin-left: 0;
        }
      }

      &:last-child {
        @media screen and (max-width: @container-tablet) {
          float: right;
          margin-right: 15px;
        }
      }
    }
  }

  &__control {
    .content-area li & {
      color: @color-primary-function;
    }

    &:after {
      &:extend(.less-icon-extend);
      display: inline-block;
      margin-left: 10px;
    }

    &--see-video {
      &:after {
        &:extend(.icon-ft_video:before);
      }
    }

    &--toggle-text {
      &:after {
        &:extend(.icon-ft_accordionplus:before);
      }

      &.is-open {
        &:after {
          &:extend(.icon-ft_accordionminus:before);
        }
      }
    }
  }
}

.video-wrapper-fluid {
  position: relative;
  //padding-bottom: 56.25%; /* 16:9 */
  //padding-bottom: 75%; /* 4:3 */
  //padding-bottom: 66.66%; /* 3:2 */
  //padding-bottom: 62.5%; /* 8:5 */
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  background-size: cover;

  @media (min-width: @screen-sm) {
    padding-top: 38px;
  }

  @media (min-width: @screen-md) {
    padding-top: 70px;
  }

  .device-mobile &{
    @media (min-width: @screen-md) {
      padding-top: 38px;
    }
  }

  object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  &-expander {
    //background: transparent url('../../Images/Global/svg/expand2.svg') no-repeat right top;
    display: inline-table;
    color: #fff;
    height: 14px;
    overflow: hidden;
    float: right;
    font-size: 13px;
    position: relative;
    top: 1px;

    &:after {
      content: "\e631\e631";
      color: white;
      font-family: "ft-icons";
      padding-left: 5px;
    }

    &:hover {
      text-decoration: none;
    }

    &--expanded {
      transform: rotate(180deg);
    }
  }
}
