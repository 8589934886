.subsite-wide {
  .informationspot-d {
    &:extend(.subsite-wide-container);
    &:extend(.section--margin-bottom);

    &.panel-group {
      background-color: @sw-color-background;
      background-color: var(--sw-color-background, @sw-color-background);

      >.panel-middle {
        &:first-child {
          border-top: 1px solid var(--sw-color-grey, #ddd);
        }
        &:last-child {
          border-bottom: 1px solid var(--sw-color-grey, #ddd);
        }
        .panel-middle:last-child {
          border-bottom: none;
        }
      }

      &:focus-within {
        outline: 0;
        outline-offset: 0px;
      }

      a,
      a.link-list {
        color: var(--sw-color-primary, @sw-color-link);
      }

      .panel {
        &.panel-middle {
          background-color: @sw-color-background;
          background-color: var(--sw-color-background, @sw-color-background);
          color: @sw-color-text-dark;
          border-radius: 0;
          border-bottom: 1px solid @sw-color-grey-light;
          border-bottom: 1px solid var(--sw-color-grey, @sw-color-grey-light);

          .panel-heading {
            .panel-title {
              a {
                color: @sw-color-text-dark;
                span {
                  font-weight: @sw-font-semi-bold;
                  color: @sw-color-text-dark;
                }

                &:after {
                  background-color: @sw-color-background;
                  background-color: var(--sw-color-background, @sw-color-background);
                  color: var(--sw-color-primary, @sw-color-primary);
                  position: absolute;
                  right: 0;
                  height: 42px;
                  width: 42px;
                  top: 27px;
                  line-height: 42px !important;
                }

                &:focus-visible,
                &:focus {
                  outline: 0;
                  outline-offset: 0;
                }
              }
            }
          }

          .panel-body {
            font-size: @sw-font-base;
            color: @sw-color-text-dark;
          }
        }

        &:focus-within {
          outline: @focus-style;
          outline-offset: 0;
        }

        &:focus-visible,
        &:focus {
          outline: 0;
          outline-offset: 0;
        }
      }
    }
  }
}
