﻿.meetings-parliament {

  &__image-container {
    width: 945px;
    height: 150px;
    display: block;
    overflow: hidden;

    &__image {
      width: 100% !important;
      height: 100% !important;
      display: block;
    }
  }

  &__two-column {
    max-width: 945px;
    display: table;
    margin-bottom: 100px;

    &__left,
    &__right {
      width: 50%;
      display: table-cell;
      background: #fff;
      padding: 20px 35px 30px;

      @media (max-width: @screen-phone) {
        width: 100%;
        display: block;

        &__left {
          border-right: none;
        }

        &__right {
          border-left: none;
        }
      }
    }

    &__left {
      border-right: 1px solid #e8eaea;
    }

    &__right {
      border-left: 1px solid #e8eaea;
    }
  }

  .link {
    text-decoration: none;    
    display: block;

    &:last-of-type {
      margin-bottom: 20px;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &__full-width {
    max-width: 945px;
    background: #fff;
    display: block;
    box-sizing: border-box;
    padding: 20px 35px 30px;
  }
}
