.subsite-wide {
  .media-carousel {
    &:extend(.subsite-wide-container);
    &:extend(.spot--margin-bottom);

    h2 {
      margin-top: 0;
    }

    .slider {
      .slider__slide {
        position: relative;
        flex: 0 0 90%;
        width: 95%;
        margin-right: 10px;

        @media (min-width: @screen-md-min) {
          margin-right: 30px;
        }

        .kaltura-video-container, .media-item__container{
          width: 100%;
          margin-bottom: 0;

          .social-video {
            margin-bottom: 0;
          }
        }
      }

      &.slider--no-controls {
        .slider__track {
          justify-content: center;
        }
        .slider__btn {
          display: block !important;
        }
      }

      .slider__controls {
        margin-top: @padding-small;
      }
      
      .kaltura-video, .media-item {
        padding: 0;

        &__title,
        &__rte {
          display: block;
          padding-right: 0;
          padding-left: 0;
        }
        &__rte {
          margin: 0;
          padding: 0;
          width: 100%;

          &.kaltura-video__caption,  &.media-item__caption {
            .caption {
              width: calc(100% - 30px);

              @media (min-width: @screen-sm-min) {
                width: calc(100% - 80px);
                padding: @spacing-small;
              }

              @media (min-width: @screen-lg-min) {
                padding: @padding-small;
              }
            }
          }
        }
      }
    }
  }
}
