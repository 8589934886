.subsite-wide {
  .forum-create-post {
    &:extend(.section--margin-bottom);

    &__container {
      &:extend(.subsite-wide-container--narrow);
    }

    &__tabs-container {
      position: relative;
      margin-bottom: @spacing-medium;
      &:before {
        content: "";
        background-color: @sw-color-primary-lighter;
        background-color: var(--sw-color-primary-lighter, @sw-color-primary-lighter);
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 50%;

        @media (max-width: @screen-md-min) {
          width: 100%;
        }
      }
    }

    &__tabs-inner {
      &:extend(.subsite-wide-container--narrow);
    }

    &__tabs {
      background-color: @sw-color-primary-lighter;
      background-color: var(--sw-color-primary-lighter, @sw-color-primary-lighter);
      position: relative;
      display: flex;
      align-items: center;
      height: 80px;
    }

    &__tab {
      padding: 10px @padding-small;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: @sw-font-md;

      @media (max-width: @screen-md-min) {
        flex-grow: 1;
        flex-basis: 100%;
        width: 100%;
        padding: 10px @spacing-small;
        display: none;
      }

      &.tab--active {
        border-bottom: 2px solid @sw-color-primary;
        border-bottom: var(--sw-color-primary, @sw-color-primary);
        font-weight: @sw-font-bold;

        @media (max-width: @screen-md-min) {
          display: inline-flex;
          border-bottom: 0;
          font-weight: @sw-font-regular;
        }
      }
    }

    &__content {
      &:extend(.subsite-wide-container--narrow);
      font-size: @sw-font-md;

      &--loading {
        pointer-events: none;
      }
    }

    &__input-group {
      margin-bottom: @section-spacing;

      @media (max-width: @screen-md-min) {
        margin-bottom:  @section-spacing-small;
      }
    }

    &__buttons {
      overflow: hidden;
      padding: 4px;

      .button {
        width: auto;
      }
    }

    .forum-input-row {
      margin-bottom: @spacing-small * 2;

      @media (max-width: @screen-md-min) {
        margin-bottom: 0;
      }
    }

    @media (max-width: @screen-md-min) {
      .forum-input-col {
        margin-bottom: @spacing-small;
      }
    }

    &__personal-details {
      @media (max-width: @screen-md-min) {
        .forum-input-row:first-of-type {
          margin-bottom: 0;
        }

        .forum-input-row.mb {
          margin-bottom: 10px;
        }
      }
    }

    &__file-upload {
      display: flex;
      flex-wrap: wrap;
      margin-left: -@spacing-small;
      margin-right: -@spacing-small;
      margin-top: @spacing-small * 2;

      .cropped-image {
        position: relative;
        padding-left: @spacing-small * 2;
        padding-right: @spacing-small;

        .img-preview {
          max-width: 250px;

          @media (min-width: @screen-md-min) {
            max-width: 300px;
          }
        }
      }

      // clear image selection
      .clear-img-preview {
        position: absolute;
        width: 24px;
        height: 24px;
        left: 15px;
        top: -15px;
        background-color: @sw-color-grey-darker;
        border-radius: 24px;
        border: 0;

        &:before,
        &:after {
          position: absolute;
          left: 11px;
          top: 4px;
          content: " ";
          height: 15px;
          width: 2px;
          background-color: @sw-color-light;
        }

        &:before {
          transform: rotate(45deg);
        }

        &:after {
          transform: rotate(-45deg);
        }
      }

      p {
        flex-basis: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: @spacing-small;
        font-size: @sw-font-md;
      }

      .custom-file-upload {
        flex-basis: auto;
        margin-left: 15px;
        margin-right: 15px;
        border: none;
        width: auto;
        background: transparent;
        padding: 0;
        margin-top: 5px;

        &:focus-visible {
          .file-upload {
            border-color: @sw-color-grey-dark;
          }
        }
        &--image {
          margin-bottom: 5px;
        }

        &.input--error {
          .file-upload {
            outline: @focus-style;
            outline-color: @sw-color-red;
            outline-offset: @focus-offset;
          }

          + .input-error-message {
            margin-top: 10px;
            padding-left: @spacing-small;
            font-weight: @sw-font-regular;
            color: @sw-color-red;
          }
        }
      }

      .input-error-message {
        order: 3;
      }

      .file-upload {
        border: 2px dashed @sw-color-grey-darker;
        width: 88px;
        height: 88px;
        display: inline-flex;
        cursor: pointer;
        position: relative;
        align-items: flex-end;
        justify-content: center;

        input[type="file"] {
          opacity: 0;
          position: absolute;
          height: 100%;
          width: 100%;

          &:active {
            outline: 0;
            border: 2px dashed @sw-color-grey-darker;
          }
        }

        span {
          padding-bottom: 6px;
        }

        &:after {
          content: "";
          background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGQ9Ik0xMCAyLjEwMjU0VjE3Ljg5NzQiIHN0cm9rZT0iIzJCMkIyQiIgc3Ryb2tlLXdpZHRoPSIyLjI1NjQxIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjxwYXRoIGQ9Ik0yLjEwMjU0IDEwSDE3Ljg5NzQiIHN0cm9rZT0iIzJCMkIyQiIgc3Ryb2tlLXdpZHRoPSIyLjI1NjQxIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4NCjwvc3ZnPg0K");
          width: 20px;
          height: 20px;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          transition: 0.3s ease;
        }

        &:hover {
          &:after {
            transform: translate(-50%, -50%) rotate(90deg);
          }
        }
      }

      label.file-upload:focus-within {
        outline: @focus-style;
        outline-offset: 2px;
        border-color: @sw-color-grey-dark;
      }
    }
    .submit-btn {
      display: flex;
      justify-content: center;
      &--loading {
        pointer-events: none;
      }
    }

    .lds-ellipsis {
      display: inline-block;
      position: relative;
      width: 60px;
      margin-left: 20px;
    }

    .lds-ellipsis div {
      position: absolute;
      top: 0px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: @sw-color-primary;
      background: var(--sw-color-primary, @sw-color-primary);
      animation-timing-function: cubic-bezier(0, 1, 1, 0);
    }

    .lds-ellipsis div:nth-child(1) {
      left: 5px;
      animation: lds-ellipsis1 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(2) {
      left: 5px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(3) {
      left: 22px;
      animation: lds-ellipsis2 0.6s infinite;
    }

    .lds-ellipsis div:nth-child(4) {
      left: 46px;
      animation: lds-ellipsis3 0.6s infinite;
    }

    @keyframes lds-ellipsis1 {
      0% {
        transform: scale(0);
      }

      100% {
        transform: scale(1);
      }
    }

    @keyframes lds-ellipsis3 {
      0% {
        transform: scale(1);
      }

      100% {
        transform: scale(0);
      }
    }

    @keyframes lds-ellipsis2 {
      0% {
        transform: translate(0, 0);
      }

      100% {
        transform: translate(24px, 0);
      }
    }

    &-status {
      &:extend(.subsite-wide-container);
    }
  }
}
