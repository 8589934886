﻿.mobile-top {
  display: none;
}
@media only screen and (max-width:768px) {
  .mobile-top {
    display: block;
    text-align: center;
    background: #dbdbdb;
    height: 80px;
    padding: 20px;

    &__title {
      .font-size(@type-size-h1);
      .font-alt();
      text-transform: uppercase;
    }

    .icon-ft_menu {
      font-size: 32px;
    }

    .icon-ft_search {
      font-size: 45px;
    }
  }
}
