﻿@import "../styles.less";

.lexicon-spot {
  background: @color-brand-400;
  padding: 25px 20px;
  margin-bottom: 20px;

  .two-col-spot .col-sm-6 &, .three-col-spot .col-sm-4 & {
    padding: 20px 28px;
  }

  .header {

    .title {
      .font-bold();
      .font-size(@type-size-xxl);
      color: @color-text-dark;
      margin-bottom: 0;
      margin-top: 0;
      display: inline-block;

      .site-euo & {
        font-size: 20px;
        font-weight: normal;
        @media (min-width: @screen-sm-min) {
          font-size: 24px;
        }
      }

      @media only screen and (min-width: @screen-sm) {
        margin-bottom: 17px;
      }

      .two-col-spot &, .three-col-spot & {
        margin-bottom: 0;
        float: none !important;
        display: block;
      }

      i {
        color: @color-brand-300;
        .font-size(2.5);

        &:before {
          position: relative;
          top: 3px;
        }
      }
    }

    a {
      .make-sm-column(4);
      padding-left: 0;
      padding-right: 0;
      color: @color-brand-200;
      display: block;
      position: relative;
      margin-bottom: 20px;
      margin-top: 10px;

      @media only screen and (min-width: @screen-sm) {
        margin: 0;
      }


      .two-col-spot &, .three-col-spot & {
        margin-bottom: 20px;
        margin-top: 10px;

        @media only screen and (min-width: @screen-sm) {
          float: none !important;
          display: block;
        }
      }

      @media only screen and (min-width: @screen-sm) {
        width: auto;
        float: right;
        display: inline-block;
      }


      &:after {
        &:extend(.less-icon-extend);
        &:extend(.icon-pil-hoejre:before);
        .font-size(@type-size-xs);
        margin-left: 5px;
        display: inline-block;
      }
    }
  }

  dl {
    margin: 10px 0 0 0;

    dt {
      margin-top: 15px;
      margin-bottom: 5px;

      &:first-child {
        margin-top: 0;
      }

      a {
        .font-regular();
        .font-size(2.3);
        color: @color-brand-300;
      }
    }

    dd {
      .font-regular();
      .font-size(1.6);
    }
  }
}

.two-col-spot, .three-col-spot {
  .lexicon-spot {
  }
}
