﻿@import "../../Global/Components/informationsside-spot-a.less";

.text-spot-a-wrapper {
  .booking-order-button{
    border-bottom: none;
    &:hover{
      border-bottom: none;
      color: #fff;
    }
  }
    .caption-more {
        i {
            &:before {
                font-size: 35px;
                &:extend(.icon-ft_pluscircle:before);
            }

            color: @color-secondary-dark;

            &:hover {
                color: @color-primary-function;
            }
        }
    }

    .caption-less {
        i {
            &:before {
                font-size: 35px;
                &:extend(.icon-ft_minuscircle:before);
            }

            color: @color-secondary-dark;

            &:hover {
                color: @color-primary-function;
            }
        }
    }
}
