﻿@import "../../Global/Components/booking-form.less";
@import "../../Global/Components/booking-categories.less";



.twitter {
  padding: 23px 38px 43px 38px; 
  background: white;
  margin-bottom: 30px;

  .two-col-spot &, .three-col-spot & {
    margin-bottom: 2px;
  }

  &__wrapper {
    @media (min-width: @screen-sm) {
      max-width: 470px;
      margin: 0 auto;
    }
  }
}

.facebook {
  padding: 23px 38px 43px 38px; 
  background: white;
  margin-bottom: 30px;

  .two-col-spot &, .three-col-spot & {
    margin-bottom: 2px;  
  }

  &__wrapper {
    @media (min-width: @screen-sm) {
      max-width: 470px;
      margin: 0 auto;
    }
  }
}

.twitter__title, .facebook__title {
  float: left;
}

.twitter__header, .facebook__header {
  .clearfix();
  position: relative;
  margin-bottom: 20px; 
  background-color: transparent !important;
}

.twitter__body {
  @media (min-width: @screen-sm) {
    max-width: 470px;
    margin: 0 auto;
  }

  iframe {
    border-left: 1px solid #e1e8ec !important;
  }
}

.twitter__btn {
  display: block;
  margin-bottom: 10px;

  @media (min-width: @screen-sm) {
    right: 0;
    top: 0;
    position: absolute;
  }
}

.twitter__title {
  font-size: 0;
  margin: 0;
}

.twitter__title-link {
  font-size: 20px;
  margin: 0 auto;
  display: block;
  .font-size(2);
  .font-alt();

  &:hover {
    display: inline-block;
  }

  &:after {
    display: inline-block;
    font-size: 15px;
    &:extend(.less-icon-extend);
    &:extend(.icon-ft_bullit_right:before);
  }
}

.facebook__title {
  font-size: 0;
  margin: 0;
}

.facebook__body {
  text-align: center;

  @media (min-width: @screen-sm) {
    max-width: 470px;
    margin: 0 auto;
  }
}


.facebook__title-link {
  .font-size(2);
  .font-alt();
  margin: 0 auto;
  display: block;

  &:hover {
    display: inline-block;
  }

  &:after {
    display: inline-block;
    font-size: 15px;
    &:extend(.less-icon-extend);
    &:extend(.icon-ft_bullit_right:before);
  }
}

.twitter__link, .facebook__link {
  img {
    display: none;
  }
}
