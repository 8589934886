﻿@import "../styles.less";

.link-list-spot-a {
  background: @color-contrast-300;
  padding: 30px;
  margin: 15px 0;

  .two-col-spot &, .three-col-spot & {
    padding: 5px 23px 20px;
  }

  h4 {
    .font-bold();
    .font-size(@type-size-lg);
    color: @color-text;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    color: @color-brand-200;

    .link-list-spot-a-link {
      [class^="icon-"] {
        .font-size(@type-size-xs);
        margin-right: 10px;
      }

      a {
        .font-regular();
        .font-size(@type-size-lg);
        color: @color-brand-200;
				&:before {
					display: inline-block;
					&:extend(.less-icon-extend);
					&:extend(.icon-ft_bullit_right:before);
					font-size: 11px;
					position: relative;
					margin-right: 5px;
				}

      }
    }
  }
}
