﻿@import "../styles.less";

.filter-left-menu {
  .intro,
  .link-filters-navigation,
  .reset-filters-link {
    .font-size(@type-size-md);
  }

  .link-filters-navigation,
  .reset-filters-link {
    color: @color-brand-200;

    &:hover {
      text-decoration: none;
    }

    i[class^="icon-"] {
      color: @color-brand-200;
      margin-right: 5px;
    }
  }

  .panel-group {
    margin-top: 15px;

    .panel-collapse {
      border-bottom: 1px solid @color-contrast-200;
    }
  }

  .form-group-date {
    > input {
      @media only screen and (max-width: @screen-sm) {
        width: 80%;
      }
    }
  }

  .link-filters-navigation {
    display: block;
    margin-bottom: 20px;

    .offcanvas-menu-container & {
      margin-top: 20px;
    }
  }

  .offcanvas-menu-container & {
    margin: 0 15px;
    width: 236px;

    .panel-body {
      .list-facets {
        margin: 0 0 0 10px;

        li {
          margin: 0;
          padding: 15px 0;
        }
      }

      a {
        display: inline;
      }
    }
  }
}

.active-filters,
.case-selections {
  margin: 0;
  padding: 0;

  list-style: none;
  .clearfix();

  li {
    float: left;
    margin-right: 10px;
    margin-bottom: 10px;

    .active-filter,
    .case-selection {
      border-radius: @border-radius-base;
      background-color: @color-contrast-300;
      display: inline-block;
      padding: 4px 10px;

      &:hover {
        text-decoration: none;
        cursor: pointer;
      }

      .active-filter-title {
        .font-bold();
        .font-size(@type-size-md);
      }
      .active-filter-amount {
        .font-regular();
        .font-size(@type-size-md);
        margin-left: 3px;
      }

      .icon-nulstil {
        color: @color-brand-200;
        margin-left: 5px;
        position: relative;
        top: 1px;
      }
    }

    .case-selection {
      .case-selection-indicator {
        position: relative;
        top: 1px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        display: inline-block;
        background-color: @color-brand-info-300;
        border:2px solid @color-text;
        margin-right: 3px;
      }

      &.case-selection-active {
        .font-bold();
        .font-size(@type-size-lg);
        background-color: @color-brand-info-600;
        color: @color-text;
      }
      &.case-selection-inactive {
        .font-regular();
        .font-size(@type-size-lg);
        background-color: @color-contrast-300;
        color: lighten(@color-contrast-100, 10%);

        .case-selection-indicator {
          background-color: transparent;
          border:2px solid lighten(@color-contrast-100, 10%);
        }
      }
    }
  }
}

.list-facets {
  list-style: none;
  padding:0;
  margin:0 0 0 10px;

  li {
    margin: 5px 0;
    .font-size(1.4);
    .font-alt();

    &:first-child {
      margin-top:0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: @color-brand-200;
      margin-right: 5px;
    }
  }
}