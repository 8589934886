﻿/* Overlay maps */
.mapContainer {
  padding: 15px 12px;
}

.ftMembersSearch {
  .map {

    .svg-container {
      width: 65%;
      height: 0;
      padding-top: 76%;
      position: relative;
    }

    .svg {
      position: absolute;
      top: 0;
      left: 0;

      a:focus path {
        fill: @color-primary-function-hover;
      }
    }

    .land:hover {
      fill: @color-primary-function-hover;
    }

    .land {
      fill: #6b6b6b;
      fill-opacity: 1;
      stroke: white;
      stroke-opacity: 1;
      stroke-width: 2;
    }

    a:focus > .land, .land:focus {
      fill: @color-primary-function-hover;
    }

    &__offshore {
      position: absolute;
      right: -53%;
      top: 0;
      border: 1px solid #c3c3c3;
      float: right;
      width: 77px;
      height: auto;

      a:hover {
        .land {
          cursor: pointer;
          fill: @color-primary-function-hover;
        }
      }
    }

    &__offshore-container {
      margin-bottom: 30px;
    }

    &__dk {
      overflow: visible !important;
      width: 75%;
      height: auto;
    }

    &__br {
      position: absolute;
      right: 80px;
      overflow: visible !important;
      width: 50px;
      height: 50px;
      border: 1px solid #c3c3c3;

      @media(min-width: @screen-sm) {
        right: 100px;
      }
    }

    &__gr {
      display: block;
      margin: 15px;
      overflow: visible !important;
      width: 60%;
      height: auto;
    }

    &__fr {
      display: block;
      margin: 15px;
      overflow: visible !important;
      width: 60%;
      height: auto;
    }
    /*
    &__fr {
      display: table;
      position: absolute !important;
      top: 0 !important;
      left: unset !important;
      border: 1px solid #c3c3c3;
      overflow: visible !important;
      width: 75%;
      height: auto;
    }*/
  }
}


#folketinget .mapBorder {
  border: 1px solid #afad09;
  margin-bottom: 25px;
}

#mapGr {
  position: relative;
  height: 189px;
  background: url(../../images/global/legacy/maps/map_gr.gif) 0 0 no-repeat;
}

#mapGr a {
  position: absolute;
  left: 0;
  top: 0;
  width: 147px;
  height: 189px;
}

#mapGr a:hover {
  background: url(../../images/global/legacy/maps/map_gr_over.gif) 0 0 no-repeat;
}

#mapGr a span {
  display: none;
}

#mapFr {
  position: relative;
  height: 189px;
  background: url(../../images/global/legacy/maps/map_fr.gif) 0 0 no-repeat;
}

#mapFr a {
  position: absolute;
  left: 0;
  top: 0;
  width: 147px;
  height: 189px;
}

#mapFr a:hover {
  background: url(../../images/global/legacy/maps/map_fr_over.gif) 0 0 no-repeat;
}

#mapFr a span {
  display: none;
}

#mapBr {
  position: relative;
  background: url(../../images/global/legacy/maps/map_br.gif) 0 0 no-repeat;
  float: right;
  width: 77px;
  height: 78px;
}

#mapBr a {
  position: absolute;
  left: 0;
  top: 0;
  width: 77px;
  height: 78px;
}

#mapBr a:hover {
  background: url(../../images/global/legacy/maps/map_Br_over.gif) 0 0 no-repeat;
}

#mapBr a span {
  display: none;
}

#mapDk {
  position: relative;
  background: url(../../images/global/legacy/maps/map_dk.gif) 0 0 no-repeat;
  width: 411px;
  margin-bottom: 20px;

  @media(min-width: @screen-sm-min) {
    height: 491px;
    margin-bottom: 0;
  }

  float: left;
}
/*#mapDk map#Map area{
border: 1px solid red;
}*/
#mapDk a.nord {
  position: absolute;
  width: 250px;
  height: 189px;
  top: 0px;
  left: 15px;
}

#mapDk .nord:hover {
  background: url(../../images/global/legacy/maps/map_dk_nord_over.gif) 0 0 no-repeat;
}

#mapDk a.ost {
  position: absolute;
  width: 196px;
  height: 171px;
  top: 152px;
  left: 108px;
}

#mapDk a.ost:hover {
  background: url(../../images/global/legacy/maps/map_dk_ost_over.gif) 0 0 no-repeat;
}

#mapDk a.vest {
  position: absolute;
  width: 153px;
  height: 164px;
  top: 144px;
  left: 3px;
}

#mapDk a.vest:hover {
  background: url(../../images/global/legacy/maps/map_dk_vest_over.gif) 0 0 no-repeat;
}

#mapDk a.syd {
  position: absolute;
  width: 177px;
  height: 178px;
  top: 278px;
  left: 0px;
}

#mapDk a.syd:hover {
  background: url(../../images/global/legacy/maps/map_dk_syd_over.gif) 0 0 no-repeat;
}

#mapDk a.fyn {
  position: absolute;
  width: 114px;
  height: 143px;
  top: 326px;
  left: 140px;
}

#mapDk a.fyn:hover {
  background: url(../../images/global/legacy/maps/map_dk_fyn_over.gif) 0 0 no-repeat;
}

#mapDk a.sja {
  position: absolute;
  width: 157px;
  height: 225px;
  top: 266px;
  left: 244px;
}

#mapDk a.sja:hover {
  background: url(../../images/global/legacy/maps/map_dk_sja_over.gif) 0 0 no-repeat;
}

#mapDk a.nords {
  position: absolute;
  width: 72px;
  height: 66px;
  top: 248px;
  left: 317px;
}

#mapDk a.nords:hover {
  background: url(../../images/global/legacy/maps/map_dk_nords_over.gif) 0 0 no-repeat;
}

#mapDk a.stork {
  position: absolute;
  width: 44px;
  height: 38px;
  top: 295px;
  left: 346px;
}

#mapDk a.stork:hover {
  background: url(../../images/global/legacy/maps/map_dk_stork_over.gif) 0 0 no-repeat;
}

#mapDk a.kobe {
  position: absolute;
  width: 39px;
  height: 28px;
  top: 307px;
  left: 372px;
}

#mapDk a.kobe:hover {
  background: url(../../images/global/legacy/maps/map_dk_kobe_over.gif) 0 0 no-repeat;
}
