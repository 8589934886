﻿.dokumenter {

  h1, .manchet {
    padding: 0 38px;
  }

  .tab-spot-a__wrapper & {

    h1, .manchet {
      padding: 0;
    }
  }

  .dokumenter-columns {
    margin-top: 30px;
    h1, h2, #quick-lookup .header {
      .font-alt();
      font-size: 25px;
      line-height: 1.2;
      margin: 0 0 5px 0;
      padding: 0;
    }

    .ankiro-searchform {
      padding: 0;
      margin: 0 0 20px 0;

      &.horizontal-search {
        .panel-body .searchoptions-buttons.advance {
          bottom: 26px;
        }
      }

      .ankiro-searchform-header {
        display: table;
        width: 100%;

        .ankiro-searchform-header-title {
          display: block;
        }
      }

      .ankiro-searchform-form {

        .search-field {

          .input-group-btn {
            width: 60px;

            button {
              background: transparent;
              border: none;
            }
          }

          input[type=text] {
            background: #f0f2f2;
          }
        }

        .input-group {
          padding: 0;

          @media (min-width: @container-sm) {
            margin-top: 40px;
          }

          &.search-field {
            margin-top: 0;

            .input-group-btn {
              right: 14px;
            }
          }

          &.ankiro-search-options-row {
            margin-top: 0;
            width: 100%;
            height: 60px;
            padding: 20px 38px 15px 27px;
          }

          label {
            padding: 0;
          }
        }
      }

      .panel-body {

        .advanced-fields-panel {
          position: relative;
          //margin-top: 30px;
          /*display: block;
          float: none;
          width: auto;*/
          .input-group.full-width {
            .clearfix();
            padding: 20px 38px 25px;
            display: block;
            // Prevent too large content and scrollbar
            @media (max-width: @screen-sm) {
              padding: 20px 23px 25px 38px;
            }

            margin: 0;
          }
        }

        .searchoptions-buttons {
          display: block;
          float: none;
          width: auto;

          @media (min-width: 964px) {
            float: right;
          }

          &.advance {
            bottom: 18px;
          }
        }

        select[multiple] {
          background: #f0f2f2;
          border: none;
        }
      }
    }

    .text-spot-a-wrapper {
      padding: 0;
      margin: 0;

      .booking-order-button {
        &:hover {
          border-bottom: none;
          color: #fff;
        }
      }

      ul {
        padding: 0;

        li {
          position: relative;
          list-style: none;
          padding-left: 12px;

          a {
            &:before {
              position: absolute;
              left: 0;
              top: 5px;
              font-size: 11px;
              &:extend(.less-icon-extend);
              &:extend(.icon-ft_bullit_right:before);
            }
          }
        }
      }
    }

    #quick-lookup {
      input[type=text], select {
        background: #f0f2f2;
        border: none;
      }

      input[type=submit] {
        &:extend(.btn-right);
        .font-size(1.8);
        padding: 10px 20px;
        margin: 0;
        min-width: auto;
        width: 100%;

        @media only screen and (min-width: @screen-sm) {
          width: auto;
          padding: 10px 45px;
        }
      }

      & + hr {
        display: none;
      }

      @media only screen and (min-width: @screen-sm) {
        width: 50%;
        float: left;
        margin: 0 30px 0 0;
      }
    }
  }

  .document-list__topic {
    margin-top: 38px !important;

    &:first-of-type {
      margin-top: 0 !important;
    }
  }

  .document-list__topic-title {
    display: block;
    margin: 0 0 5px;
    .font-alt;
    .font-size(1.8)!important;
    color: @color-primary-dark;
  }

  .document-list__topic-list {
    margin-top: 10px;

    li {
      margin-top: 5px;
    }
  }
}
