﻿@import "../../Global/Components/statusblade.less";


.document-list-wrapper {
  background-color: #fff;

  .document-list {
    li {
      border-bottom-color: #eaeeee;
    }
  }
}
