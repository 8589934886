﻿@import "../../Global/Components/gallery.less";

.gallery-image {
  h2 {
    color: @color-primary-dark;
  }

  &__text {
    p {
      color: @color-primary-dark;
    }
  }
}
