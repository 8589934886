﻿@import "../../Global/Components/webformsformarketers.less";

//customHfield
.customHfield {
  position: absolute;
  left: -9999999px;
}

/****Custome WFFM****/
.scfForm.customHform input[type="submit"] {
  @media (max-width: @screen-xs) {
    width: 100% !important;
  }
}

.site-ft .scfSubmitSummary {
  margin: 0 0 30px 38px;
}

.scfForm.customHform [id*="_fieldContainer"] {
  display: table;
  padding: 30px 38px 1px !important;
  width: 100%;

  .scfSectionBorderAsFieldSet {
    margin: 0;
  }

  .scfSingleLineGeneralPanel {
    display: block;
    margin-bottom: 0;

    @media screen and (max-width: @screen-sm) {
      width: 100%;
    }
    /*input {
      display: inline-block;
      border-radius: 0;
      height: 40px;
      color: @color-contrast-150;
      padding: 10px;
      .font-regular();
      .font-size(1.4);
      border: 1px solid @color-secondary-lighter;
      background-color: @color-secondary-lightest;
    }*/
  }
}
/****END Custome WFFM****/

.scfForm {
  [id*="_fieldContainer"] {
    background: #fff;
    padding: 30px 0 1px;
  }

  .scfSingleLineTextUsefulInfo {
    display: none;
  }

  .scfFileUploadLabel {
    color: @color-brand-50 !important;
    margin-left: 0% !important;
    padding: 10px 25px;
    min-width: 0;

    .red &, .green &, .gray & {
      color: @color-text-light !important;
    }
  }

  .scfSectionContent .scfFileUploadBorder {
    float: left;
    margin: 2rem 0 0;
  }

  .scfTitleBorder {
    font-family: initial;
  }

  .scfIntroBorder, .scfFooterBorder, .scfSubmitButtonBorder {
    /*margin-left: 38px;
		margin-right: 38px;*/
    padding-left: 38px;
    padding-right: 38px;
    margin-bottom: 0;
    padding-bottom: 25px;
  }

  .scfIntroBorder {
    font-family: Arial, sans-serif;
  }

  .scfFooterBorder, .scfSubmitButtonBorder {
    background-color: #fff;
    margin-top: -1px;
  }

  fieldset {
    legend {
      border-color: @color-menu-selected-tab;
    }
  }

  .scfSectionBorderAsFieldSet {
    margin: 0 38px 15px;

    .scfSectionLegend {
    }
  }

  .scfValidationSummary {
    display: none;
  }

  .scfValidatorRequired {
    .font-regular();
    .font-size(1.4);
    line-height: 1.92rem;
    clear: both;
    color: @color-brand-200;
    display: block;
    float: none;
    margin: 5px 0 0;
    display: inline-block;

    @media (min-width: @container-small-desktop) {
      box-sizing: border-box;
      clear: none;
      float: left;
      margin: 0 15px 0;
      width: 25%;
    }
    // IMPORTANT: The content of validation text in included in this before element
    &:before {
      content: attr(title);
    }
  }

  .scfEmailGeneralPanel, .scfMultipleLineGeneralPanel, .scfSingleLineGeneralPanel, .scfPasswordGeneralPanel, .scfNumberGeneralPanel, .scfDatePickerGeneralPanel, .scfDateGeneralPanel, .scfRadioButtonListGeneralPanel, .scfCheckBoxListGeneralPanel, .scfFileUploadGeneralPanel, .scfDateSelectorGeneralPanel, .scfCreditCardGeneralPanel, .scfConfirmPasswordGeneralPanel, .scfCaptchaGeneralPanel, .scfTelephoneGeneralPanel, .scfSmsTelephoneGeneralPanel, .scfListBoxGeneralPanel, .scfDropListGeneralPanel {
    position: relative;

    .scfValidatorRequired {
      display: none;
    }

    .scfValidator {
      color: @color-brand-200;
      float: none;
      font-family: 'Arial', sans-serif;
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 18px;
      line-height: 1.8rem;
      margin-top: 5px;
    }

    @media (min-width: @container-sm) {
      // Only display first error as several errors will destroy styling.
      // We're a bit limited using Webforms for Marketers plugin where we don't have access to DOM
      .scfValidator:nth-child(1) {
        position: absolute;
        display: block;
        left: 102%;
        margin: 0;
        top: 10px;
        width: 30%;
      }
    }
  }

  .scfDateSelectorDay, .scfDateSelectorMonth, .scfDateSelectorYear, .scfListBox, .scfDropList, .scfEmailTextBox, .scfSingleLineTextBox, .scfPasswordTextBox, .scfNumberTextBox, .scfDatePickerTextBox, .scfDateTextBox, .scfRadioButtonList, .scfListBox, .scfCheckBoxList, .scfFileUpload, .scfCreditCardTextBox, .scfCreditCardType, .scfConfirmPasswordTextBox, .scfTelephoneTextBox, .scfSmsTelephoneTextBox {
    height: 60px;
  }

  .scfDropListLabel, .scfEmailLabel, .scfMultipleLineTextLabel, .scfSingleLineTextLabel, .scfPasswordLabel, .scfNumberLabel, .scfDatePickerLabel, .scfDateLabel, .scfListBoxLabel, .scfDateSelectorLabel, .scfCreditCardLabel, .scfConfirmPasswordLabel, .scfCaptchaLabel, .scfTelephoneLabel, .scfSmsTelephoneLabel {
    height: 60px;
    line-height: 60px;
    display: block;
    width: 100%;
  }

  .scfDatePickerGeneralPanel {
    position: relative;

    .ui-icon {
      background-image: none;
      width: 30px;
      height: 20px;
      position: absolute;
      top: 50%;
      right: 20px;
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      cursor: pointer;

      &:before {
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_calendar:before);
        text-indent: 0;
        width: 20px;
        height: 20px;
        display: block;
        font-size: 20px;
      }
    }
  }

  fieldset {
    font-family: "SceneAlt", serif;
  }

  .scfSectionContent {
    label {
      color: @color-primary-dark;
      display: block;
      height: auto;
      line-height: inherit;
    }

    .scfCheckBoxListLabel {
      display: block;
      margin-bottom: 0.5rem;
      width: 100%;
    }

    > div {
      margin-bottom: 2rem;

      input:not([type='checkbox']),
      input:not([type='radio']),
      textarea,
      select {
        background: @color-secondary-lighter;
        border: 0;
        color: @color-contrast-150;
        font-family: arial, serif;
        font-size: 16px;
        font-size: 1.6rem;
        padding-left: 20px;
        max-width: 100%;
        width: 100%;

        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: #696969;
        }

        &::-moz-placeholder { /* Firefox 19+ */
          color: #696969;
        }

        &:-ms-input-placeholder { /* IE 10+ */
          color: #696969;
        }

        &:-moz-placeholder { /* Firefox 18- */
          color: #696969;
        }

        &:focus {
          &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            color: transparent;
          }

          &::-moz-placeholder { /* Firefox 19+ */
            color: transparent;
          }

          &:-ms-input-placeholder { /* IE 10+ */
            color: transparent;
          }

          &:-moz-placeholder { /* Firefox 18- */
            color: transparent;
          }
        }
      }

      textarea {
        padding: 20px;
      }
    }

    .scfCheckBoxListGeneralPanel {
      margin-left: 0;

      table.scfCheckBoxList {
        width: 100%;
      }
    }
  }

  .form-field__required {
    position: relative;
    padding: 0;

    &:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      position: absolute;
      top: -8px;
      right: -8px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid @color-brand-300;
      border-right: 8px solid transparent;
      .rotate(-45deg);
    }
  }

  .form-field__required + .scfRequired {
    display: none;
  }
}
