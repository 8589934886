﻿@import "styles.less";

.font-face("Asap", "../../Fonts/Global/Asap-Regular", 400, normal);
.font-face("Asap", "../../Fonts/Global/Asap-Bold", 700, normal);
.font-face("Asap", "../../Fonts/Global/Asap-Italic", 400, italic);
.font-face("Asap", "../../Fonts/Global/Asap-BoldItalic", 700, italic);
.font-face("ft-icons", "../../Fonts/Global/ft-icons", 400, normal);

.font-alt(@important: "") {
  font-family: Asap;
  font-weight: 400 e(@important);
  font-style: normal e(@important);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-regular(@important: "") {
  font-family: Asap;
  font-weight: 400 e(@important);
  font-style: normal e(@important);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-headline(@important: "") {
  font-family: Asap;
  font-weight: 400 e(@important);
  font-style: normal e(@important);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-bold(@important: "") {
  font-family: Asap;
  font-weight: 700 e(@important);
  font-style: normal e(@important);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  .font-regular();
  .font-size(@type-size-h1);
  margin-bottom: 20px;
}

.manchet {
  .font-regular();
  .font-size(@type-size-xl);
  margin-bottom: 25px;

  a {
    .font-bold();
    color: @link-primary-color;
  }
}

.paragraph-title {
  &:extend(.manchet);
  margin-bottom:5px;
}

.manchet + div {
  margin-bottom: 25px;
}

input:invalid {
  border: 1px solid @validation-error-border-color;
  font-weight: bold;
}