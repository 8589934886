﻿@import "../../Global/Components/alertbanner.less";

.site-wide-message-spot-b {
  z-index: 9999;
  box-shadow: 0 0px 12px 7px rgba(0, 0, 0, 0.21);

  a {
    color: @color-brand-150;
    border-bottom: 1px solid @color-menu-selected-tab;

    &:hover {
      border-bottom: 1px solid @color-brand-150;
      text-decoration: none;
    }
  }

  &__text {
    text-align: left;
    .font-size(1.8);

    h2 {
      .font-size(2.5);
    }

    h3 {
      .font-size(2.0);
    }

    p {
      .font-size(1.8);
    }
  }
}
