﻿@import "../styles.less";

.category-label {
  margin: 75px auto 0;
  text-align: center;

  .icon-arbejdsomraade {
    font-size: 5.5rem;
    color: @color-brand-200;

    @media (min-width: @screen-sm) {
      margin-bottom: 15px;
    }
  }

  .category-title {
    .font-regular();
    .font-size(@type-size-xl);
    line-height: 1.2em;
    font-weight: bold;

    .site-euo & {
      font-size: 20px;
      font-weight: normal!important;

      @media (min-width: @screen-sm) {
        font-size: 24px !important;
      }
    }

    @media (min-width: @screen-sm) {
      width: 50%;
    }

    margin: 0 auto 10px;
  }

  a {
    color: @color-brand-200;
    display: block;

    &:after {
      &:extend(.less-icon-extend);
      &:extend(.icon-pil-hoejre:before);
      .font-size(@type-size-xs);
      margin-left: 5px;
    }
  }
}

.carousel {
  img,
  .slide-title,
  .slide-description {
    display: none;
  }

  img {
    width: 100%;
  }
}

.pageedit {
  .category-label {
    margin-top: 0;
  }

  .bx-wrapper {
    float: left;
    max-width: 240px;
    display: inline-block;
    margin-right: 5px;
    padding-bottom: 0;
    margin-bottom: 10px;
  }

  .default-image {
    img {
      max-width: 240px;
      max-height: 120px;
      display: block;
    }
  }

  .helper-text {
    text-align: left;
    .font-bold();
    .font-size(@type-size-xl);
    color: @color-text;
    margin: 20px 0;
    border-bottom: 1px solid @color-text;
  }
}
