.member-detailed-vote-result {
  background: #fff !important;

  .mobile__container {
    .member__img-container {
      .member__img {
        width: 80px !important;
        height: 80px !important;
      }
    }
    .member__info--content {
      .member__info {
        p {
          padding-left: 0 !important;
        }
      }
    }
  }
}
