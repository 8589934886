﻿// PBI 984 - Kvikopslagspot A (http://tfs.pentia.dk/Folketinget/EUO/_workitems#_a=edit&id=984)
@import "../styles.less";
#quick-lookup {
  .header {
    .font-bold();
    .font-size(@type-size-xl);
    margin-bottom: 20px;
    margin-top: 0;
  }

  .form-group {
    margin-bottom: 10px;

    select, input {
      .font-regular();
      .font-size(@type-size-md);
    }

    label {
      .font-regular();
      .font-size(@type-size-md);
      font-weight: normal;
    }
  }

  .input-group {
    margin-top: 20px;
  }
}

.offcanvas-menu-container {
  #quick-lookup {
    margin-top: 20px;
  }
}

.left-menu-wrapper {
  .simulated-panel-title {
    padding: 7px 10px;
    .font-bold();
    .font-size(1.2);
    color: @color-text;
    float: left;
    margin: 0;
  }

  select {
    option {
      .font-size(1.4);
      margin: 0;
      padding: 0;
      &.highlighted {
        background: @color-brand-info-500;
        color: @color-text;
      .font-size(1.4);
      margin: 0;
      padding: 0;
      }
    }
  }
}
