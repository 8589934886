.subsite-wide {
  .link-card {
    justify-content: flex-start;
    background: @sw-color-light;
    background: var(--sw-color-background, @sw-color-light);
    padding-bottom: 55px;
    transition: transform 250ms ease-in-out;
    overflow: hidden;

    .card__category {
      position: absolute;
      top: 0;
      left: 0;
      width: 45px;
      height: 45px;
      background-color: #db9f39;
      padding: 5px;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        max-height: 30px;
      }
    }

    .card__image {
      overflow: hidden;
      transition: filter 500ms ease-in-out;
      position: relative;
      height: 180px;

      img {
        max-width: 100%;
        height: auto;
        transition: transform 500ms ease-in-out;
        transform: none;
      }

      .linkcard-img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      &--video {
        .kaltura-video-thumb {
          padding: 0;
          background-color: transparent;
        }

        .kaltura-video-thumb-wrapper {
          margin-bottom: 0;

          background-color: transparent;
          width: calc(100% + 1px);

          img {
            position: absolute;
          }
        }

        .card__image-play-button {
          position: absolute;
          bottom: 10px;
          right: 10px;
          background-image: url("data:image/svg+xml,%3Csvg width='45' height='45' viewBox='0 0 75 75' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.5664 74.8001C58.0053 74.8001 74.5743 58.2446 74.5743 37.8223C74.5743 17.4001 58.0053 0.844543 37.5664 0.844543C17.1276 0.844543 0.558594 17.4001 0.558594 37.8223C0.558594 58.2446 17.1276 74.8001 37.5664 74.8001Z' fill='white' fill-opacity='0.9'/%3E%3Cpath d='M30.8379 51.2683V24.3754L51.024 37.8219L30.8379 51.2683Z' fill='black'/%3E%3C/svg%3E%0A");
          background-repeat: no-repeat;
          background-position: center;
          height: 45px;
          width: 45px;
          border-radius: 50%;
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.3);
        }
      }
    }

    .card__content {
      padding: @padding-small @spacing-small @spacing-small;
      margin-top: @padding-large;
    }

    .card__image + .card__content {
      margin-top: 0;

      .card__teaser {
        max-height: 78px;
      }
    }

    .card__title {
      margin-bottom: 10px !important;
      font-size: @sw-font-base;

      span {
        display: block;

        &:first-child {
          margin-bottom: 5px;
        }
      }
    }

    .card__teaser {
      font-size: 22px;
      line-height: 26px;
      font-weight: @sw-font-bold;
      max-height: 130px;
      overflow: hidden;

      text-overflow: ellipsis;
      display: -webkit-box !important;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      white-space: normal;
    }

    .card__image + .card__content {
      .card__teaser {
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }

    .card__actions {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 55px;

      .favorite-post,
      .pinned {
        position: absolute;
        bottom: @spacing-small;
      }

      .favorite-post {
        display: inline-flex;
        right: @spacing-small;
      }

      .pinned {
        left: @spacing-small;
      }
    }

    &:focus,
    &:active,
    &:focus-within {
      outline: 0;
      border: none;
    }

    &:focus-visible {
      outline: @focus-style;
      outline-offset: 2px;
    }

    &-carousel {
      &:extend(.spot--margin-bottom);
      &:extend(.section--padded-large);
      background-color: @sw-color-primary-lighter;
      background-color: var(--sw-color-primary-lighter, @sw-color-primary-lighter);
      margin-top: @spot-spacing-small;

      @media (min-width: @screen-md-min) {
        margin-top: @spot-spacing;
      }

      &__header {
        @media (max-width: @screen-sm-min) {
          display: block;
        }
      }
    }

    &:hover { 
      transform: scale3d(1.02, 1.02, 1);
      text-decoration: none;

      .card__image {
        filter: brightness(70%);
        overflow: hidden;
        width: calc(100% + 1px);

        img,
        .linkcard-img {
          transform: scale3d(1.02, 1.02, 1);
          width: calc(100% + 1px);
        }
      }

      .favorite-post__svg {
        transform: scale(1);
      }

      .kaltura-video-thumb-wrapper {
        width: 100%;
      }
    }
  }
}
