.chat-wrapper {
	.chat-container {
		color: @color-primary-dark;
		margin-top: 0 !important;
		padding: 30px 38px !important;

		h2,
		.dokumenter .dokumenter-columns h2 {
			font-family: "SceneAlt", serif;
			font-size: 25px;
			font-size: 2.5rem;
			margin-top: 0;
		}

		a {
			border-bottom: 1px solid @color-menu-selected-tab;
			text-decoration: none;

			&:hover {
				border-bottom: 1px solid @color-primary-link;
			}
		}
	}
}

.chat-wrapper--double {
	.chat-container {
		h2 {
			font-size: 20px !important;
			font-size: 2rem !important;
		}
	}
}
