﻿@import "../Global/environment-banner.less";

.pageedit {
  &:before {
    content: "" !important;
    display: none !important;
  }

  &:after {
    content: "" !important;
    display: none !important;
  }
}
