.vod-container {
  //overflow: hidden;
  @media only screen and (min-width: @screen-sm) {
    margin: 0 38px 0 0;
  }

  background-color: #fff;
  color: @color-primary-dark;

  &.is-expanded {
    padding-right: 0;
  }

  &.ftWebTvVoDForCommittee {
    .chooseDate {
      @media only screen and (min-width: @screen-sm) {
        margin-top: 40px;
      }
    }
  }

  &.ftWebTvCanalFolketinget {
    padding: 0;
    background: none;

    .ftWebTvCanalFolketinget__page-title {
      padding-top: 0;
      .font-size(2.5);
      .font-alt();
    }

    #videoTabs ul#tabnav li {
      &:hover {
        background: @color-tertiary-result-absent;
      }

      a {
        color: @color-contrast-150;
      }

      &.active {
        background: white;

        a {
          cursor: default;
        }
      }
    }

    .tabContent {
      margin-left: 0;
    }

    #broadcastContainer {
      margin: 0;
      padding-bottom: 0;
      border-bottom: none;
    }

    .broadcastNav {
      .font-alt();
    }

    .broadcastWeek {
      border-color: @color-contrast-200;

      @media (min-width: @screen-lg-min) {
        .font-size(1.6);
      }
    }

    .upcomingDays {
      a.prev,
      a.next {
        color: @color-brand-200;

        &:hover {
          color: @color-brand-200;
          text-decoration: underline;
        }

        @media (min-width: @screen-lg-min) {
          .font-size(1.6);

          &:before {
            top: 5px;
          }
        }
      }

      a.prev {
        @media (min-width: @screen-lg-min) {
          margin-right: 20px;
        }
      }
    }

    .broadcastDay {
      display: block;
      margin: 60px 0 20px;
      .font-size(1.8);

      &:first-of-type {
        margin: 30px 0 20px;
      }
    }

    .broadcastItem {
      margin-bottom: 20px;

      p {
        .font-size(1.6);
      }
    }
  }

  #infoText {
    margin: 20px 38px 0 38px;
  }

  .twoColumns {
    .column-title {
      padding-left: 28px;
    }

    &.noBorder {
      padding-left: 1px;
    }

    &.ftWebTvLive__info {
      @media only screen and (max-width: @screen-xs-max) {
        padding: 0 38px;
      }
      // Fixes BUG-35979
      .broadcastItem a:before {
        display: inline-block;
        font-family: "ft-icons";
        &:extend(.icon-ft_bullit_right:before);
        font-size: 11px;
        margin-right: 7px;
      }
      // End bugfix bug-35979
    }

    #thePlayer {
      .dark_player {
        .font-alt();
        .clearfix();

        .title_panel {
          background-color: white;
          padding-left: 38px;
          padding-right: 38px;
        }

        .title_panel {
          padding: 16px 38px;
          .font-size(2.5);
        }

        .livePlayer {
          height: 408px;

          @media (max-width: @screen-md-max) {
            height: 385px;
          }

          @media (max-width: @screen-sm-max) {
            height: 330px;
          }

          @media (max-width: @screen-xs-max) {
            height: 300px;
          }
        }

        .dark_player_cntr_share,
        .dark_player_cntr_cut {
          display: inline-block;
          flex-basis: 16.66665%;
          flex-grow: 0;

          a {
            &:before {
              margin-right: 5px;
              &:extend(.less-icon-extend);
              &:extend(.icon-ft_del:before);
            }
          }
        }
      }
    }

    .chooseDate {
      .font-alt();

      &:first-of-type {
        margin-bottom: 20px;
      }

      &__fieldset {
        margin-bottom: 20px;
      }

      @media (min-width: @screen-lg-min) {
        display: inline-block;
        width: 49%;
        margin-left: 1%;

        &:first-of-type {
          margin-bottom: 0;
          margin-left: 0;
          margin-right: 1%;
        }
      }

      label {
        .font-size(1.6);
        display: block;
        color: #454545;
      }

      .datepicker {
        max-width: 100%;
        padding: 10px;
        background: @color-secondary-lighter;
        border: none;
        .font-size(1.4);
        .font-regular();
        color: @color-secondary-darker;
        width: ~"calc(100% - 20px)";
      }

      [id*="trigger"] {
        display: none;
      }

      &__btn {
        text-align: center;
        margin-top: 20px;
      }

      .icon-kalender {
        line-height: unset !important;
        margin: 0 0 0 5px;

        &:before {
          position: relative;
          top: 2px;
        }
      }
    }

    .btn-normal__container {
      text-align: center;
      margin-top: 10px;
    }

    .chooseDate-button,
    .btn-normal {
      .reset-button-to-ft();
      .btn-right();
    }

    .btn-normal {
      @media (max-width: @screen-md) {
        margin: 0 auto 5px;
      }
    }

    .scroll-wrap {
      .scroll-container {
        .video-item {
          h3 {
            b {
              font-weight: normal;
            }
          }
        }

        .video-box,
        .video-item,
        .video-item-ns {
          background-color: #fff !important;

          &:nth-child(odd) {
            background-color: #f6f7f7 !important;
          }
        }

        .video-item-referat {
          margin: 0 !important;
          background-color: #fff !important;

          &:nth-child(odd) {
            background-color: #f6f7f7 !important;
          }

          .video-bio {
            font-weight: 400 !important;
            padding: 0 38px;

            .number {
            }

            .bio {
            }

            .name {
            }

            .share-link {
            }
          }
        }

        .video-item,
        .video-item-ns {
          padding: 18px 18px;
        }

        .video-item-ns {
          padding: 10px 0;
        }
      }

      .scroll-up {
        display: block;
        text-align: center;
      }

      .scroll-down {
        display: block;
        text-align: center;
      }
    }

    .tvSort {
      div {
        margin-top: 20px;

        &:first-child {
          margin-top: 0;
        }
      }

      select {
        padding: 10px;
        background: @color-secondary-lighter;
        border: none;
        .font-size(1.4);
        font-weight: 400;
        color: @color-secondary-darker;
      }
    }

    #videoItemContainer {
      margin: 0 10px 30px;
      padding-bottom: 15px;
      border-bottom: 3px solid @color-primary-function-bg;

      .videoItem {
        margin-bottom: 10px;
        display: inline-block;
        float: none;

        .videoItemVideo {
          display: table-cell;
          vertical-align: top;
          margin-right: 10px;
          border-bottom: 10px solid transparent;
          border-right: 10px solid transparent;

          @media (min-width: @screen-xs) and (max-width: @screen-sm) {
            display: table;
            float: left;
            width: 140px;
          }
        }

        .videoItemVideo__info {
          display: table-cell;
          vertical-align: top;
          margin-right: 10px;
          .font-size(1.2);
          border-bottom: 10px solid transparent;

          @media (max-width: @screen-xs) {
            width: 100%;
          }
          a {
            display: inline-block;
            padding-left: 15px;

            &:before {
              display: inline-block;
              &:extend(.less-icon-extend);
              &:extend(.icon-ft_bullit_right:before);
              font-size: 9px;
              float: left;
              margin: 3px 0 0 -10px;
            }
          }
        }

        .playing {
          text-transform: uppercase;
          background-color: #a5a5a5;
          color: #fff;
          padding: 5px 10px;
          //border-radius: 3px;
          margin: 5px 0 0 0;
          .font-size(1.1);
          display: inline-block;
        }
      }
    }
  }

  #broadcastContainer {
    position: relative;
    margin: 0 15px 0 10px;
    padding-bottom: 15px;
    border-bottom: 3px solid @color-primary-function-bg;

    .canceled {
      text-transform: uppercase;
      font-weight: 400;
      font-size: 10px;
      display: inline-block;
      background-color: @color-primary-function-bg;
      color: @tooltip-bg;
      padding: 0px 5px;
      position: relative;
      top: -3px;
    }
  }

  .broadcastWeek {
    .font-alt;
    .font-size(1.2);
    border-bottom: 1px solid @color-secondary-dark;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }

  .broadcastDay {
    .font-size(1.6);
    margin: 30px 0 15px;
  }

  .broadcastItem {
    .font-size(1.6);

    h6 {
      margin: 0;
      .font-size(1.6);
    }

    p {
      .font-size(1.4);
    }
  }

  .upcomingDays {
    position: absolute;
    right: 0;
    top: 0;
    background-image: none;
    border-bottom: none;
    //width: 188px;
    background-color: transparent;

    a.prev {
      &:before {
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_left:before);
        position: relative;
        padding-right: 10px;
        float: left;
        top: 3px;
        font-size: 9px;
      }

      margin-right: 10px;
      padding-left: 20px;
      float: left;
      position: relative;
      .font-size(1.2);
      .font-alt();
      color: @color-brand-200;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    a.next {
      &:before {
        &:extend(.less-icon-extend);
        &:extend(.icon-ft_bullit_right:before);
        position: relative;
        padding-left: 10px;
        float: right;
        top: 3px;
        font-size: 9px;
      }

      position: relative;
      color: @color-primary-function;
      text-decoration: none;
      float: right;
      //padding-right: 20px;
      .font-size(1.2);
      .font-alt();

      &:hover {
        text-decoration: underline;
      }
    }
  }

  #videoTabs {
    .clearfix();
    padding: 0;

    ul#tabnav {
      //float: left;
      padding: 0;
      margin: 30px 0 0 0;

      &.tabnav--lg {
        .tabLov {
          .font-size(2);
        }
      }

      li {
        list-style: none;
        display: inline-block;
        border: 1px solid #ededed;
        border-width: 1px 1px 0 0;
        color: @color-contrast-150;
        background: @color-secondary-lighter;

        &:first-child {
          border-width: 1px 0 0 0;
        }

        padding: 0;

        &:hover {
          background: @color-tertiary-result-absent;
        }

        &.active {
          background-color: #f6f7f7;

          a {
            color: @color-primary-function;
            text-decoration: underline;
          }
        }

        a {
          display: inline-block;
          margin: 0;
          padding: 20px 38px;
          text-align: center;
          .font-alt();
        }
      }
    }

    .flexible-tabs-container {
      clear: both;
      margin: 0;
      border: 0 !important;
      padding: 0 !important;

      .video-item-agenda {
        p {
          margin: 0 0 5px;
        }

        b {
          font-weight: normal;
        }
      }
    }

    .flexible-tabs-inner {
      .video-item-content {
        p {
          margin: 0 0 5px;
        }
      }

      .TekstIndryk {
        .font-size(1.6);
        line-height: 1.25;
      }

      br {
        display: none;
      }

      h2.day {
        padding: 20px 38px 0;
      }

      h2 {
        display: block;
      }

      p {
        margin: 0 38px 5px;
        .font-size(1.4);

        b {
          font-weight: normal;
        }

        &:first-child {
          .font-size(1.6);
          line-height: 1.25;
        }
      }
    }
  }

  .sharelink {
    position: absolute;
    top: 10px;
    right: 10px;
    //background-color: #fff;
    //border: 1px solid #e0e0e0;
    width: 18px;
    height: 18px;
    font-size: 13px;
    text-align: center;
    border-radius: 3px;
    color: @color-primary-function;

    &:hover {
      text-decoration: none;
    }
  }

  .message {
    width: 80%;
    text-align: center;
    font-size: 16pt;
    margin: 44px 10%;

    a {
      margin: 15px 0;
    }
  }

  a:focus {
    outline: -webkit-focus-ring-color auto 5px;
    outline-offset: 2px !important;
  }
}

.modal.vod_video-tools__modal {
  width: 90vw;
  max-width: 1440px;
  top: 50%;
  left: 50%;
  margin-left: 0;
  transform: translate(-50%, -50%);
  bottom: auto;
  right: auto;
  height: 90dvh;
  z-index: 10500;

  @media (max-width: @screen-md) {
    height: 100dvh;
    width: 100vw;
    max-width: 100%;
    top: 0;
    left: 0;
    transform: none;
    margin-left: 0;
    margin-top: 0;
}

  .modal-dialog,
  .modal-content,
  .modal-body {
    height: 100%;
  }

  .modal-dialog {
    margin: 0;
  }

  .modal-content {
    background-color: transparent;
    padding: 35px;

    @media (max-width: @screen-md) {
      background-color: #f2f2f2;
      padding: 0px;
    }
  }

  .modal-body {
    padding: 0px;
  }

  .modal-close {
    width: 30px;
    height: 30px;
    position: absolute;
    top: -35px;
    right: -35px;

    @media (max-width: @screen-md) {
      top: 10px;
      right: 10px;

      .close {
        color: #000;
      }
    }
  }

  span.icon-ft_close {
    font-size: 30px;
    line-height: 30px;
    color: #fff;
    cursor: pointer;

    @media (max-width: @screen-md) {
      color: #000;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.modal-backdrop, .loading-backdrop {
  background-color: #353535;
  z-index: 10400;
}

.modal-backdrop.in, .loading-backdrop.in {
  opacity: 0.9;
}

body.modal-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.video-popup {
  display: inline-table;
  float: right;
  margin-right: 8px;

  @media (max-width: @screen-sm) {
    display: none;
  }
}

.available-on-mobile {
  background: @color-secondary-dark;
  bottom: 0;
  color: #fff;
  display: none;
  height: 100%;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;

  html.modernizr_no-flash.modernizr_video & {
    display: table;
  }

  &:active,
  &:hover {
    text-decoration: none;

    span.link {
      text-decoration: underline;
    }
  }

  h4 {
    font-size: 2rem;
    font-size: 20px;
    margin: 0;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 1.6rem;
  }

  span.link {
    color: @color-primary-link;
    text-decoration: none;
  }
}
.tvsearch {
  &:focus {
    outline: -webkit-focus-ring-color auto 5px !important;
  }
}
.available-on-mobile__vertical-align {
  display: table-cell;
  padding: 0 2rem;
  vertical-align: middle;
}

.available-on-mobile__fake-link {
  color: @color-primary-link;
}

.vod__channel-video {
  width: 100%;
  height: 0;
  margin-bottom: 0;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  background-size: cover;

  @media (min-width: @screen-sm) {
    padding-top: 32px;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

//Social video
.social-video {
  width: 100%;
  margin-bottom: 30px;
  .video-list-spot {
    margin-bottom: 0 !important;
  }
  .tab-spot-a__wrapper & {
    margin-bottom: 0;
  }
  #sendLinkToFriendArea {
    display: block !important;
  }
  div.two-col-spot &,
  div.three-col-spot & {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    margin: 0 0 2px 0 !important;
  }

  div.two-col-spot .col-sm-6 &,
  div.three-col-spot .col-sm-4 & {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .video-list-spot .video-list-spot-body .video-list-player {
    float: none;
    width: 100%;
    display: table;
    padding-bottom: inherit;
  }

  &__title {
    padding: 0 38px;
    .font-alt();
    .font-size(2.5);
    line-height: 50px;
    color: @color-primary-darker;
    margin-bottom: 0;

    .two-col-spot &,
    .three-col-spot & {
      .font-size(2);
      color: @color-primary-dark;
      margin-bottom: 20px !important;
      padding-top: 23px !important;
    }
  }

  &__rte {
    display: block;
    padding-top: 30px;
    padding-bottom: 30px;
    background: white;
    color: @color-primary-dark;

    .two-col-spot &,
    .three-col-spot & {
      padding: 20px 38px;
    }
  }

  .video-tools__accept,
  .video-tools-edit__accept {
    color: white;
    margin-bottom: 20px !important;
    margin-top: 0 !important;
    font-size: 14px !important;
    margin-left: 5px;
    padding: 0 !important;

    @media (min-width: @screen-sm) {
      font-size: 16px;
    }
  }

  .video-tools__disclaimer,
  .video-tools-edit__disclaimer  {
    width: 100%;
    background: #252525;
    color: white;
    display: none;
    padding: 25px 15px 15px;

    &.is-shown {
      display: block !important;
    }

    &.is-hidden {
      display: none;
      /*visibility: hidden;
      opacity: 0;
      height: 0;*/
    }

    .custom-checkbox {
      border: 2px solid @color-primary-function-bg;
      background: none;
      display: table-cell;

      .icon:before {
        font-size: 13px;
        position: relative;
        top: -3px;
      }
    }

    p {
      color: @color-menu-selected-tab;
      font-size: 12px;
      margin: 0 !important;
      padding: 0 !important;

      @media (min-width: @screen-sm) {
        font-size: 14px;
      }
    }

    a {
      color: white;
      border-bottom: 1px solid white;

      &:hover {
        text-decoration: none;
        border-bottom: 1px solid @color-primary-function-bg;
      }
    }
  }

  .vclose {
    display: none;
  }
}
